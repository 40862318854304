import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router'
import styles from './GroupChooseExisting.module.css'
import globalStyles from '../../utils/globalStyles.module.css'
import MessageModal from '../../components/MessageModal'
import SelectSingleDropDown from '../../components/SelectSingleDropDown'
import WorkFileTreeFolderDropDown from '../../components/WorkFileTreeFolderDropDown'
import ButtonWithIcon from '../../components/ButtonWithIcon'
import OneFJefFooter from '../../components/OneFJefFooter'
import classes from 'classnames'

function GroupChooseExisting(props) {
  let { workFolders, paramWorkFolderId, groupId, groups, groupOptions } = props //groupTypeDescription
  const navigate = useNavigate()
  const params = useParams()

  const [group, setGroup] = useState()
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [workFolderId, setWorkFolderId] = useState()
  const [showAssignedFolderWarning, setShowAssignedFolderWarning] = useState(false)
  const [groupChosen, setGroupChosen] = useState()

  useEffect(() => {
    setWorkFolderId(paramWorkFolderId)
    setGroupChosen(groupId)
  }, [paramWorkFolderId])

  useEffect(() => {
    const workFolder = workFolders && workFolders.length > 0 && workFolders.filter(m => m.group && m.group.groupId === groupId)[0]
    if (workFolder) {
      setGroup(workFolder.group)
      setWorkFolderId(workFolder.group.workFolderId)
    }
  }, [groupId, workFolders])

  const handleSubmit = () => {
    let hasError = false
    //Check to see if another group is already assigned to the workFolderId given here.
    const existAssignedFolder = group && group.groupId && groups && groups.length > 0 && groups.filter(m => m.workFolderId === workFolderId && m.groupId !== group.groupId)[0]
    if (existAssignedFolder) {
      setShowAssignedFolderWarning(existAssignedFolder.groupName)
    } else if (!hasError) {
      submitAddOrUpdateGroup()
    }
  }

  const submitAddOrUpdateGroup = () => {
    const { addOrUpdateGroup, deleteGroupWorkFolderAssignment, groups, personId} = props
    const group = groups && groups.length > 0 && groups.filter(m => m.groupId === groupChosen)[0]
    if (group) {
      addOrUpdateGroup({
        groupId: group.groupId,
        personId,
        groupName: group.groupName,
        languageId: group.languageId,
        internalId: group.interalId,
        description: group.description,
        workFolderId,
      }, '/myWorks')
    } else {
      deleteGroupWorkFolderAssignment(personId, workFolderId, '/myWorks')
    }
    setSubmitSuccess(true)
    setShowAssignedFolderWarning(false)
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainDiv}>
        <form method="post" encType="multipart/form-data" id="my-awesome-dropzone" className={styles.form}>
          <div className={globalStyles.pageTitle}>
            Choose Existing Group
          </div>
          <div className={styles.background}>
            <div className={styles.inputControls}>
              <div>
                <WorkFileTreeFolderDropDown workFolders={workFolders}
                                            label={'Document folder'}
                                            setWorkFolderId={setWorkFolderId}
                                            workFolderId={paramWorkFolderId}
                                            labelClass={styles.textWhite}/>
              </div>
              <div className={styles.row}>
                <div className={styles.spaceForOr}>OR</div>
                <div className={styles.link} onClick={() => navigate('/myWorks')}>Return to documents to create another folder</div>
              </div>
            </div>
            <hr style={{marginTop: '20px'}}/>
            <div>
              <div className={styles.selectList}>
                <SelectSingleDropDown
                  id={`groupList`}
                  label={'Existing group'}
                  value={groupChosen || ''}
                  options={groupOptions}
                  height={`medium-short`}
                  labelClass={styles.textWhite}
                  onChange={(event) => setGroupChosen(event.target.value)}/>
              </div>
              <div className={styles.row}>
                <div className={classes(styles.spaceForOr, styles.leftSpace)}>OR</div>
                <div className={styles.link} onClick={() => navigate(`/groupAddNewByWorkFolder/${params.workFolderId}`)}>Create a new group</div>
              </div>
            </div>
            <div className={styles.hint}>
              To delete a group assignment: <br/>choose the first, blank record of the group list '- -' and SUBMIT.
            </div>
          </div>
          <div className={styles.buttonsCenter}>
            <div className={styles.buttonPosition}>
              <span className={styles.cancelButton} onClick={() => navigate(-1)}>
                Cancel
              </span>
              <ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={handleSubmit} submitSuccess={submitSuccess}/>
            </div>
          </div>
          <OneFJefFooter/>
        </form>
      </div>
      <MessageModal show={showAssignedFolderWarning} handleClose={() => setShowAssignedFolderWarning(false)} heading={`Group Document Folder`}
                    explain={`Another group is assigned to this folder:<br/><strong>${showAssignedFolderWarning}</strong><br/><br/>Do you want to unassign this group in order to add the new group? This group will not be assigned to any folder.`}
                    onClick={submitAddOrUpdateGroup} isConfirmType/>
    </div>
  )
}

export default GroupChooseExisting
