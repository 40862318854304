import React, { useState, useEffect } from 'react'
import styles from './EditReviewView.module.css'
import * as editorService from '../../services/editor-dom'
import SentenceEdits from '../../components/SentenceEdits'
import ToggleBoard from '../../components/ToggleBoard'
import Checkbox from '../../components/Checkbox'
import TextDisplay from '../../components/TextDisplay'
import AuthoringEditor from '../../components/AuthoringEditor'
import MessageModal from '../../components/MessageModal'
import backgroundColors from "../../utils/backgroundColors"
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router'

let newId = ''

/*
    TRANSLATION:  If this is a translation of the native language of this work, the following will happen:
    1. isTranslation prop will be set to true.
    2. Clicking on a sentence will show it in the left panel (th emobile version needs to be opened by up clicking on "current sentence edit" at the top left).
        a.  If the sentence has NOT been translated, the api for the translator (bing or google) will be hit and a translation will be waiting
        b.  If it has been translated, the previous api translation would have been saved to be presented again if a change needs to be made without having to make the api call again.
        c.  The user can then choose the translation which will then go into the single editor.  Or the user can make their own translation.
    4. The left panel setting for going previous and next will be set by force so that the user can continue to translate each sentence one at a time.

    EDITING NATIVE LANGUAGE
    1. When leaving the current sentence
        a. The previous sentence will be checked for change.
            i. If a different sentence with its Id is detected, that sentence only is sent to the webapi
            ii. On the server-side, if it is the author, the sentence will be applied directly to the chapterText and new chapterText will be sent background
                  If it is the editor, the editSegment record will be saved and the editSegments record as a whole will be sent back (to include any other editors' edits completed since the last refresh)

    VIEWS
    1. The main view (left)
    2. The right side will contain a comparison full-view of the author and the editor (but only one of those users at a time, of course).
         a. The author will see her original text plus any of her current edits (not yet committed) in blue - text or icons.
             i. The edits kept in place during the session (before commit) will be available to be reversed.
             ii. The author needs to click on the submit button in order to commit the edit Details.
                 If a commit was not done in a session, those edits will still be displayed.  That gives the author a chance to reverse them easily.
         b. The editor will see all of their own edits (text or icons) but no one else's.  They have the chance to reverse them out.
         c. The editor will see ALL edits in the author's tab if they care to see what others have done.
         d. Any user in any other user's full-view can click on an icon or a sentence in order to agree, disagree, or obnoxious-vote.
         e. Any edits that belong to the current user will find in the pop-down tool options that they can reverse the edit (delete the edit segment record).

    TOP TOOLS
    1. Mobile
        a. Only three icons and the drop-down caret will be displayed. (Plus the floppy disk for saving for the user)
        b. The pop-down menu will have the edit modes, search, and bookmark

    Ids
    1. The writer side has its Ids changed from <id> to <id>~tabView so that the functions through the process will highlight the right-side and not the left.  But 
				those two ID types need to be managed throughout the processes.
*/

function EditReviewView(props) {
	const {
		personId, 
		workSummaries,
		isDraftView, 
		toggleDraftView, 
		isTranslation, 
		getTranslation,
	} = props;  //bookmarkOptions, bookmarks, editorColors, editReview={}

  const navigate = useNavigate()
	const isMobile = useMediaQuery({ query: '(max-width: 870px)' })

	const [isOpenSlideOut, setIsOpenSlideOut] = useState(false);
	// const [arraySearchTextFound, setArraySearchTextFound] = useState([])
  // const [pointerBookmark, setPointerBookmark] = useState(-1) //Need to start with -1 since it is incremented by one before being sent to the bookmark tool and it needs to start as 0
  // const [bookmarkChosen, setBookmarkChosen] = useState('') //this is an hrefI
	// const [localHomeworkSubmitDate, setLocalHomeworkSubmitDate] = useState()
	// const [isShowingPenspringDistribute, setIsShowingPenspringDistribute] = useState(false)
	// const [localDistributeSubmitDate, setLocalDistributeSubmitDate] = useState()
  const [editTrackChanges, setEditTrackChanges] = useState(false)
  const [keepCommentOn, setKeepCommentOn] = useState(false)
	const [sidePanel, setSidePanel] = useState('268px')
	const [moveSentences, setMoveSentences] = useState(false)
	const [changeStyle, setChangeStyle] = useState(false)
	const [addParagraphBreak, setAddParagraphBreak] = useState(false)
	const [addParagraphSentence, setAddParagraphSentence] = useState(false)
	const [addSentence, setAddSentence] = useState(false)
	const [deleteSentence, setDeleteSentence] = useState(false)
	const [addList, setAddList] = useState(false)
	const [changeList, setChangeList] = useState(false)
	const [addListItem, setAddListItem] = useState(false)
	const [deleteListItem, setDeleteListItem] = useState(false)
	const [reorderListItems, setReorderListItems] = useState(false)
	const [deleteParagraphBreak, setDeleteParagraphBreak] = useState(false)
	const [showEditorFullText, setShowEditorFullText] = useState(false)
  const [showEditIcons, setShowEditIcons] = useState(true) //Have this on by default. This is not a setting we save to the database. We are forcing the user to turn it off on every session just to make sure that they see that there are edits to see before we have them turn it off.
  const [moveTranslationToEditor, setMoveTranslationToEditor] = useState(false)
  const [goToNextSentence, setGoToNextSentence] = useState(false)
	const [currentElement, setCurrentElement] = useState()
	const [previousElement, setPreviousElement] = useState({elementId: '', backgroundColor: ''}) //This is to return the background color to what it used to be.
	//const [isInitNewId, setIsInitNewId] = useState(false)
	const [isInitEdits, setIsInitEdits] = useState('') //This is for the case of DELETEPARAGRAPH when the editor is deleting their own paragraph in SentenceEdits and needs to recall the setSegmentsWithEdits
	const [moveSequence, setMoveSequence] = useState(1)
	const [changeStyleSequence, setChangeStyleSequence] = useState(1)
	const [convertAddListSequence, setConvertAddListSequence] = useState(0) //This needs to start an 0 because it is different than the other sentence-selection types. This edit has options to let the user select by highlighting with the cursor or to add a new list with a target from scratch without converting text.
  const [localTranslation, setLocalTranslation] = useState(props.translatedSentence)
  const [showClickInstruction, setShowClickInstruction] = useState()
  const [hideClickInstruction, setHideClickInstruction] = useState(false)
	const [chosenSegment, setChosenSegment] = useState([{ id: '0', type: '', addListItemSequence: '' }])
	const [saveWorkSpaceTime, setSaveWorkSpaceTime] = useState(new Date())
	const [showAddListDecision, setShowAddListDecision] = useState(0)
	const [addListTarget, setAddListTarget] = useState()
	const [openListModal, setOpenListModal] = useState(false)
	const [currentEditorDiv, setCurrentEditorDiv] = useState()
	const [globalChosenTab, setGlobalChosenTab] = useState()
	const [globalIsAuthor, setGlobalIsAuthor] = useState()
	const [globalWorkSummary, setGlobalWorkSummary] = useState()
	const [globalTabsData, setGlobalTabsData] = useState()
	const [globalEdits, setGlobalEdits]	= useState()
	const [globalHasListStructure, setGlobalHasListStructure] = useState()

	useEffect(() => {
		if (!globalWorkSummary && workSummaries?.length > 0) {
			setGlobalWorkSummary(workSummaries[0])
			const workOwner = workSummaries[0].workOwners.filter(m => m.personId === personId)[0]
			setGlobalIsAuthor(!!workOwner?.personId)
			setGlobalEdits(workSummaries[0].editSegments)
			setGlobalTabsData(editorService.getTabsData(workSummaries[0], workSummaries[0].editSegments, props.editorColors, props.editorName, isMobile, !!workOwner?.personId, personId))
		}
		if (!currentEditorDiv && workSummaries?.length === 1 && workSummaries[0].workTypeCode !== 'DISCUSSION') { //We want to proactively set the currentEditorDivId if there is just one and it isn't DISCUSSION
			const newEditorDivId = editorService.getEditorDivId(workSummaries[0].chapterId_current)
			setCurrentEditorDiv(newEditorDivId)
			setGlobalHasListStructure(editorService.getHasListStructure(newEditorDivId))
		}
	}, [personId, workSummaries])

	useEffect(() => {
		if (globalWorkSummary) {
			setGlobalEdits(workSummaries.find(w => w.workId === globalWorkSummary?.workId)?.editSegments)
		}
	}, [globalWorkSummary, workSummaries.find(w => w.workId === globalWorkSummary?.workId)?.editSegments?.length])

	useEffect(() => {
		if (currentEditorDiv) {
			const newWorkSummary = editorService.getWorkSummaryFromEditorDiv(currentEditorDiv, workSummaries)
			if (newWorkSummary) {
				setGlobalWorkSummary(newWorkSummary)
				const workOwner = newWorkSummary.workOwners.filter(m => m.personId === personId)[0]
				setGlobalIsAuthor(!!workOwner?.personId)
				setGlobalEdits(newWorkSummary.editSegments)
				setGlobalTabsData(editorService.getTabsData(newWorkSummary, newWorkSummary.editSegments, props.editorColors, props.editorName, isMobile, !!workOwner?.personId, personId))
				setGlobalHasListStructure(editorService.getHasListStructure(currentEditorDiv))
			}
		}
	}, [currentEditorDiv])

	useEffect(() => {
		setLocalTranslation(props.translatedSentence)
		if (currentElement && props.personConfig.goToNextSentence) {
			setTimeout(() => {
				// const editorDivTemp = document.getElementById('editorDiv')
				// if (editorDivTemp) editorDivTemp.focus()
				const highlightSpan = document.getElementById(currentElement.id)
				const highlightSpanTabView = document.getElementById(currentElement.id + '~tabView')
				if (highlightSpan) highlightSpan.style.backgroundColor = 'yellow'
				if (highlightSpanTabView) highlightSpanTabView.style.backgroundColor = 'yellow'
			}, 300)
		}
	}, [props.translatedSentence])


	useEffect(() => {
		localStorage.setItem('personConfig', JSON.stringify(props.personConfig))
    setKeepCommentOn(props.personConfig.keepCommentOn)
    setEditTrackChanges(props.personConfig.editDifferenceView)
		setSidePanel(props.personConfig.showSidePanel)
    setShowEditorFullText(props.personConfig.showEditorFullText)
    setMoveTranslationToEditor(props.personConfig.moveTranslationToEditor)
    setGoToNextSentence(props.personConfig.goToNextSentence)
	}, [props.personConfig])

	useEffect(() => {
		if (workSummaries?.length === 1) {
			setCurrentEditorDiv(editorService.getEditorDivId(workSummaries[0].chapterId))
		}
	}, [workSummaries])

  useEffect(() => {
		window.scrollTo(0, 0);
    let localHideClickInstruction = localStorage.getItem('hideClickInstruction')
    if (localHideClickInstruction) setHideClickInstruction(true)
  },[])

	const handleSetChosenSegment = (element) => {
		if (element) {
			let newChosen = (chosenSegment && chosenSegment.length > 0 && [...chosenSegment]) || []
			let cleanSpanId = element.id.indexOf('~tabView') > -1 ? element.id.substring(0, element.id.indexOf('~tabView')) : element.id
			const lastChosen = newChosen.length > 0 ? newChosen[newChosen.length - 1] : {}
			//Don't allow duplicates
			const matchLast = Number(lastChosen.id) === Number(cleanSpanId) 
				&& lastChosen.type === element.dataset.type 
				&& ((!element.dataset.addListItemSequence && !element.dataset.addListItemSequence) || lastChosen.addListItemSequence === element.dataset.addListItemSequence)
				&& ((!element.dataset.subsequence && !element.dataset.subsequence) || lastChosen.subSequence === element.dataset.subsequence)

			if (!matchLast) {
				let item = {
					id: cleanSpanId,
					type: element.dataset.type,
					addListItemSequence: element.dataset.addListItemSequence || '',
					subSequence: element.dataset.subsequence //This is for the ADDPARAGRAPHSENTENCE edit type. I know ... it is similar to addListItemSequence and not specifically named as addListItemSequence.
				}
				setChosenSegment(newChosen.concat(item).filter(m => m && m.id && m.id !== 'root')) //cut out the blank entries
			}
		}
		setBackgroundOfAllTextEdits(editorService.getEditorDivIdFromElementId(element.id))
	}

	const setBackgroundOfAllTextEdits = (editorDivId) => {
		const workSummary = editorService.getWorkSummaryFromEditorDiv(editorDivId, workSummaries)
		if (workSummary) {
			const textEdits = workSummary.editSegments?.length > 0 && workSummary.editSegments.filter(m => m.type === 'TEXT')
			textEdits?.length > 0 && textEdits.forEach(m => {
				const editorDivEdit = document.querySelector(`span[id="${m.elementId}"][data-type]`)
				if (editorDivEdit) editorDivEdit.style.backgroundColor = backgroundColors.editPending
				const tabViewdit = document.querySelector(`span[id="${m.elementId}~tabView"][data-type]`)
				if (tabViewdit) editorDivEdit.style.backgroundColor = backgroundColors.editPending
			})
		}
	}

  const handleHideClickInstruction = () => {
    localStorage.setItem('hideClickInstruction', 'true')
    setHideClickInstruction(true)
  }

	const unhighlightMoveIcons = (editSegment) => {
		if (editSegment) {
			const editorDivId = editorService.getEditorDivIdFromElementId(editSegment.elementId)
			if (editorDivId) {
				const workSummary = editorService.getWorkSummaryFromEditorDiv(editorDivId, workSummaries)
				if (workSummary) {
					let editMatch = workSummary.editSegments?.length > 0 && workSummary.editSegments.filter(m => m.type === 'MOVE')
					editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
						editorService.unshowMoveIcons(edit)
					})	
				}
			}
		}
	}

	const unhighlightChangeStyleIcons = (editSegment) => {
		if (editSegment) {
			const editorDivId = editorService.getEditorDivIdFromElementId(editSegment.elementId)
			if (editorDivId) {
				const workSummary = editorService.getWorkSummaryFromEditorDiv(editorDivId, workSummaries)
				if (workSummary) {
					let editMatch = workSummary.editSegments?.length > 0 && workSummary.editSegments.filter(m => m.type === 'CHANGESTYLE')
					editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
						editorService.unshowChangeStyleIcons(edit)
					})
				}
			}
		}
	}

	const unhighlightAddParagraphIcons = (editSegment) => {
		if (editSegment) {
			const editorDivId = editorService.getEditorDivIdFromElementId(editSegment.elementId)
			if (editorDivId) {
				const workSummary = editorService.getWorkSummaryFromEditorDiv(editorDivId, workSummaries)
				if (workSummary) {
					let editMatch = workSummary.editSegments?.length > 0 && workSummary.editSegments.filter(m => m.type === 'ADDPARAGRAPH')
					editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
						editorService.unshowAddParagraphIcons(edit)
					})
				}
			}
		}
	}

	const unhighlightDeleteParagraphIcons = (elementId, clearLast) => {
		editorService.unshowDeleteParagraphIcons(clearLast ? '' : elementId)
	}

	const handleSetChosenAddListEdit = () => {
		let elements = document.querySelectorAll(`[data-type="ADDLIST"]`)
		elements && elements.length > 0 && elements.forEach(m => {
			m.style.backgroundColor = backgroundColors.normal
			if (m.nodeName === 'IMG') {
				m.height = 22
			}
		})
	}

	const handleSetChosenAddListItemEdit = () => {
		let elements = document.querySelectorAll(`[data-type="ADDLISTITEM"]`)
		elements && elements.length > 0 && elements.forEach(m => {
			m.style.backgroundColor = backgroundColors.normal
			if (m.nodeName === 'IMG') {
				m.height = 22
			}
		})
	}

	const unhighlightDeleteListIcons = (elementId, clearLast) => {
		editorService.unshowDeleteListItemIcons(clearLast ? '' : elementId)
	}

	const handleSetChosenReorderListItemsEdit = (elementId, clearLast) => {
		editorService.unshowReorderListItemsIcons(clearLast ? '' : elementId)
	}

	const handleSetShowEditorFullText = (value) => {
		setShowEditorFullText(value)
		props.updatePersonConfig(personId, `ShowEditorFullText`, value)
	}

	const handleSetShowEditIcons = (value) => {
		setShowEditIcons(value) //This is just following the convention of having a middleman function in between the state setting function. Just in case we want to use it more specifically for other things before setting the state.
	}

  const handleSetMoveTranslationToEditor = (value) => {
    setMoveTranslationToEditor(value)
    props.updatePersonConfig(personId, `MoveTranslationToEditor`, value)
  }

  const handleSetGoToNextSentence = (value) => {
    setGoToNextSentence(value)
    props.updatePersonConfig(personId, `GoToNextSentence`, value)
  }

  const handleSetGlobalChosenTab = (newTab_personId) => {
		setGlobalChosenTab(newTab_personId)
	}

	//let tabsFunction = isDraftView ? handleDraftTabs : handleSetGlobalChosenTab  //eslint-disable-line
	let tabNav = isDraftView ? toggleDraftView : () => {}
	let navText = isDraftView && `Close Drafts`

  // const handleSaveNewBookmark = (newBookmarkName) => {
  //     const {personId, workSummary, saveNewBookmark, editReview} = props;
  //     saveNewBookmark(personId, props.chapterId, workSummary.languageId_current, editReview.sentenceChosen, newBookmarkName);
  // }
	//
  // const handleDeleteBookmark = () => {
  //   const {personId, workSummary, deleteBookmark} = props;
  //   deleteBookmark(personId, props.chapterId, workSummary.languageId_current, bookmarkChosen); //bookmarkChosen is the hrefId
  //   setIsShowingDeleteModal(false)
	//   setBookmarkChosen('');
  // }

	const handleSetEditTrackChanges = (value) => {
		setEditTrackChanges(value)
		props.updatePersonConfig(personId, `EditDifferenceView`, !props.personConfig.editDifferenceView)
	}

  const handleSetKeepCommentOn = (value) => {
    setKeepCommentOn(value)
    props.updatePersonConfig(personId, `KeepCommentOn`, !props.personConfig.keepCommentOn)
  }

	const handleClearAllEditTypes = (notType) => {
		const skipRecall = true
		if (addParagraphSentence && notType !== 'ADDPARAGRAPHSENTENCE') handleSetAddParagraphSentence(false, skipRecall)
		if (addSentence && notType !== 'ADDSENTENCE') handleSetAddSentence(false, skipRecall)
		if (reorderListItems && notType !== 'REORDERLISTITEMS') handleSetReorderListItems(false, skipRecall)
		if (deleteListItem && notType !== 'DELETELISTITEM') handleSetDeleteListItem(false, skipRecall)
		if (addList && notType !== 'ADDLIST') handleSetAddList(false, skipRecall)
		if (changeList && notType !== 'CHANGELIST') handleSetChangeList(false, skipRecall)
		if (addListItem && notType !== 'ADDLISTITEM') handleSetAddListItem(false, skipRecall)
		if (deleteSentence && notType !== 'DELETESENTENCE') handleSetDeleteSentence(false, skipRecall)
		if (addParagraphBreak && notType !== 'ADDPARAGRAPHBREAK') handleSetAddParagraphBreak(false, skipRecall)
		if (deleteParagraphBreak && notType !== 'DELETEPARAGRAPHBREAK') handleSetDeleteParagraphBreak(false, skipRecall)
		if (moveSentences && notType !== 'MOVE') handleSetMoveSentences(false, skipRecall)
		if (changeStyle && notType !== 'CHANGESTYLE') handleSetChangeStyle(false, skipRecall)
	}

	const handleSetMoveSentences = (value, skipRecall) => {
		if (!skipRecall) handleClearAllEditTypes('MOVE')
		if (!value) {
			editorService.removeMoveEditIconsAll()
			setIsInitEdits('FORCE') //Just in case a move was not entirely completed and we need to get the orphan icons out of the text.
		}
		setMoveSentences(value)
	}

	const handleSetChangeStyle = (value, skipRecall) => {
		if (!skipRecall) handleClearAllEditTypes('CHANGESTYLE')
		if (!value) {
			editorService.removeChangeStyleEditIconsAll()
			setIsInitEdits('FORCE') //Just in case a move was not entirely completed and we need to get the orphan icons out of the text.
		}
		setChangeStyle(value)
	}

	const handleSetAddParagraphSentence = (value, skipRecall) => {
		if (!skipRecall) handleClearAllEditTypes('ADDPARAGRAPHSENTENCE')
		setAddParagraphSentence(value)
	}

	const handleSetAddSentence = (value, skipRecall) => {
		if (!skipRecall) handleClearAllEditTypes('ADDSENTENCE')
		setAddSentence(value)
	}

	const handleSetDeleteSentence = (value, skipRecall) => {
		if (!skipRecall) handleClearAllEditTypes('DELETESENTENCE')
		setCurrentElement(null) //We don't want to assume that the current sentence is the one we want to start with as far as deleting is concerned.
		setDeleteSentence(value)
	}

	const handleSetAddList = (value, skipRecall) => {
		if (!skipRecall) handleClearAllEditTypes('ADDLIST')
		if (!value) {
			editorService.removeAddListIconsAll()
		}
		setAddList(value)
	}

	const handleSetChangeList = (value, skipRecall) => {
		if (!skipRecall) handleClearAllEditTypes('CHANGELIST')
		setChangeList(value)
	}

	const handleSetAddListItem = (value, skipRecall) => {
		if (!skipRecall) handleClearAllEditTypes('ADDLISTITEM')
		setAddListItem(value)
	}

	const handleSetDeleteListItem = (value, skipRecall) => {
		if (!skipRecall) handleClearAllEditTypes('DELETELISTITEM')
		setDeleteListItem(value)
	}

	const handleSetReorderListItems = (value, skipRecall) => {
		if (!skipRecall) handleClearAllEditTypes('REORDERLISTITEMS')
		setReorderListItems(value)
	}

	const handleSetAddParagraphBreak = (value, skipRecall) => {
		if (!skipRecall) handleClearAllEditTypes('ADDPARAGRAPH')
		setAddParagraphBreak(value)
	}

	const handleSetDeleteParagraphBreak = (value, skipRecall) => {
		if (!skipRecall) handleClearAllEditTypes('DELETEPARAGRAPH')
		setDeleteParagraphBreak(value)
	}

	const handleSetSidePanel = (value) => {
		setSidePanel(value ? '268px' : '0px')
		props.updatePersonConfig(personId, `ShowSidePanel`, !props.personConfig.showSidePanel)
	}

	// const handleMissingBookmarkClose = () => setIsShowingMissingBookmarkModal(false)
	// const handleDeleteClose = () => setIsShowingDeleteModal(false)
	// const handleDeleteOpen = () => {
  //       if (!bookmarkChosen || bookmarkChosen === "- -") {
  //           setIsShowingMissingBookmarkModal(true)
  //           return;
  //       }
  //       setIsShowingDeleteModal(true)
  //   }

	// const handlePenspringHomeworkOpen = () => setIsShowingPenspringHomework(true)
	// const handlePenspringHomeworkClose = () => setIsShowingPenspringHomework(false)
	// const handlePenspringHomework = () => {
	// 		const {personId, workSummary, setPenspringHomeworkSubmitted} = props;
	// 		setPenspringHomeworkSubmitted(personId, workSummary.workId);
	// 		//saveByButtonPress();
	// 		handlePenspringHomeworkClose();
	// 		setLocalHomeworkSubmitDate(new Date())
	// }

	// const handlePenspringDistributeOpen = () => setIsShowingPenspringDistribute(true)
	// const handlePenspringDistributeClose = () => setIsShowingPenspringDistribute(false)
	// const handlePenspringDistribute = () => {
	// 		const {personId, workSummary, setPenspringDistributeSubmitted} = props;
	// 		setPenspringDistributeSubmitted(personId, workSummary.workId);
	// 		handlePenspringDistributeClose();
	// 		setLocalDistributeSubmitDate(new Date())
	// }

	const handleSetCurrentElement = (element) => {
    //Be sure that the element is not a sub-span without an id which belongs to a penspring-span-segment with an id. Get the parent span with the id.
    let loop = 0
    while (element && element.nodeName === 'SPAN' && !element.id && loop < 7) {
      element = element.parentElement
      loop++
    }
    if (!element || (element.nodeName === 'SPAN' && !element.id)) return

    //replace element with the editorDiv version and take out tabView
    if (element && element.id && element.id.indexOf('tabView') > -1) {
			if (element.type === 'ADDPARAGRAPHSENTENCE') {
				element = document.querySelector(`${element.nodeName}[id="${element.id.replace('~tabView', '')}"][data-type="${element.dataset.type}"][data-subsequence="${element.dataset.subSequence}"]`)
			} else {
				//If this is mobile, then the editorDiv is not visible so that this element would not be found and set to null in that case. We still need it to be a valid element so that the img icon will send data we need to the side panel.
				const elementId = isMobile ? element.id : element.id.replace('~tabView', '') 
      	element = document.querySelector(`${element.nodeName}[id="${elementId}"][data-type="${element.dataset.type}"]`)
			}
    }
		//1. If there is a previous element, set the background color back to what it was in the editor's window
		//2. For this newly chosen element
		//    a. Save it's current background color and the element Id
		//    b. Set the background color to yellow
		if (previousElement && previousElement.elementId) {
			let prev = document.querySelector(`[id="${previousElement.elementId + '~tabView'}"][data-type="TEXT"]`)
			if (prev) prev.style.backgroundColor = previousElement.backgroundColor
			prev = document.querySelector(`[id="${previousElement.elementId}"][data-type="TEXT"]`)
			if (prev) prev.style.backgroundColor = previousElement.backgroundColor
		}
		if (element && element.dataset && (element.dataset.type === 'TEXT' || element.dataset.type === 'REORDERLISTITEMS')) {
      const query = element.dataset.type === 'REORDERLISTITEMS' ? `[id="${element.id}"][data-type="REORDERLISTITEMS"]` : `[id="${element.id}"][data-type="TEXT"]`
			const elementSelected = document.querySelector(query)
			if (elementSelected) {
        if (Number(element.id) !== Number(previousElement.elementId)) {
          setPreviousElement({
            elementId: element.id,
            backgroundColor: elementSelected.style.backgroundColor,
            type: elementSelected.dataset && elementSelected.dataset.type
          })
        }
				//Now set the tabView version of the elementSelected to be highlighted
				const query = element.dataset.type === 'REORDERLISTITEMS' ? `[id="${element.id}~tabView"][data-type="REORDERLISTITEMS"]` : `[id="${element.id}~tabView"][data-type="TEXT"]`
				const tabViewElementSelected = document.querySelector(query)
				if (tabViewElementSelected) tabViewElementSelected.style.backgroundColor = backgroundColors.currentFocus
			}
		}
    if (element) {
      setCurrentElement(element)
			//localStorage.setItem(`scrollCurrentElementId-${workSummary.workId}`, element && element.id) //Help ToDo: I'm not sure what this is doing here. It seems that the item name should just be the scrollCurrentElementId without the additioal id on it. Ohterwise, you are going to get many entries and who knows how to get the latest scrollCurrentElementId?
    }
    return element
	}

	return (
		<div id={'mainPage'} className={styles.editReviewPage}>
			<div className={styles.rowEditReview}>
				{globalWorkSummary && globalWorkSummary.workId && 
					<SentenceEdits
						addListItem={addListItem} setAddListItem={handleSetAddListItem}
						addParagraphBreak={addParagraphBreak} setAddParagraphBreak={!globalIsAuthor && handleSetAddParagraphBreak}
						addParagraphSentence={addParagraphSentence} setAddParagraphSentence={!globalIsAuthor && handleSetAddParagraphSentence}
						addSentence={addSentence} setAddSentence={!globalIsAuthor && handleSetAddSentence}
						changeStyle={changeStyle} setChangeStyle={!globalIsAuthor && handleSetChangeStyle}
						chosenTab={globalChosenTab}
						currentElement={currentElement}
						deleteListItem={deleteListItem} setDeleteListItem={handleSetDeleteListItem}
						deleteParagraphBreak={deleteParagraphBreak} setDeleteParagraphBreak={!globalIsAuthor && handleSetDeleteParagraphBreak}
						deleteSentence={deleteSentence} setDeleteSentence={handleSetDeleteSentence}
						editTrackChanges={editTrackChanges} setEditTrackChanges={handleSetEditTrackChanges}
						edits={globalEdits || globalWorkSummary?.editSegments}
						getWorkEditReviewFilled={() => props.getWorkEditReview(personId, workSummaries[0].workId)}
						getTranslation={getTranslation}
						globalChosenTab={globalChosenTab}
						goToNextSentence={goToNextSentence} setGoToNextSentence={handleSetGoToNextSentence}
						handleSetChosenAddListEdit={handleSetChosenAddListEdit}
						handleSetChosenAddListItemEdit={handleSetChosenAddListItemEdit}
						handleSetChosenReorderListItemsEdit={handleSetChosenReorderListItemsEdit}
						handleSetChosenSegment={handleSetChosenSegment}
						handleSetCurrentElement={handleSetCurrentElement}
						handleSetGlobalChosenTab={handleSetGlobalChosenTab}
						isAuthor={globalIsAuthor}
						isMobile={isMobile}
						isOpenSlideOut={isOpenSlideOut}
						isTranslation={isTranslation}
						keepCommentOn={keepCommentOn} setKeepCommentOn={handleSetKeepCommentOn}
						localTranslation={localTranslation}
						moveSentences={moveSentences} setMoveSentences={!globalIsAuthor && handleSetMoveSentences}
						moveTranslationToEditor={moveTranslationToEditor} setMoveTranslationToEditor={handleSetMoveTranslationToEditor}
						reorderListItems={reorderListItems} setReorderListItems={handleSetReorderListItems}
						segments={globalWorkSummary?.workSegments}
						setIsInitEdits={setIsInitEdits}
						setIsOpenSlideOut={setIsOpenSlideOut}
						setLocalTranslation={setLocalTranslation}
						setSaveWorkSpaceTime={setSaveWorkSpaceTime}
						showEditIcons={showEditIcons}
						showEditorFullText={showEditorFullText} setShowEditorFullText={handleSetShowEditorFullText}
						tabsData={globalTabsData}
						unhighlightAddParagraphIcons={unhighlightAddParagraphIcons}
						unhighlightChangeStyleIcons={unhighlightChangeStyleIcons}
						unhighlightDeleteListIcons={unhighlightDeleteListIcons}
						unhighlightDeleteParagraphIcons={unhighlightDeleteParagraphIcons}
						unhighlightMoveIcons={unhighlightMoveIcons}
						workSummary={globalWorkSummary}
						{...props} />
				}
				<div className={styles.column}>
					{workSummaries?.length > 0 && workSummaries[0]?.workTypeCode === 'DISCUSSION' &&
						<div className={styles.topSpace}>
							<div className={styles.row}>
								<div className={styles.groupDiscussion}>Group Discussion</div>
							</div>
							<div className={styles.discussionTitle}>{workSummaries[0].title}</div>
						</div>
					}
					{workSummaries?.length > 0 && workSummaries.map((workSummary, index) => {
						const editOptions = editorService.getEditLabelOptions(workSummary.editSegments) 
						const isAuthor = workSummary.workOwners.filter(m => m.personId === personId)[0] || false

						return (
							<AuthoringEditor 
								addList={addList}
								addListItem={addListItem}
								addListTarget={addListTarget}
								addParagraphBreak={addParagraphBreak}
								addParagraphSentence={addParagraphSentence}
								addSentence={addSentence}
								authorPersonId={workSummary && workSummary.authorPersonId}
								changeList={changeList}
								changeStyle={changeStyle}
								chapterId={workSummary.chapterId_current}
								chosenSegment={chosenSegment}
								clearAllEditTypes={handleClearAllEditTypes}
								convertAddListSequence={convertAddListSequence}
								currentEditorDiv={currentEditorDiv}
								currentElement={currentElement}
								deleteListItem={deleteListItem}
								deleteParagraphBreak={deleteParagraphBreak}
								deleteSentence={deleteSentence}
								editOptions={editOptions}
								editorDivId={`editorDiv${editorService.getPrefixChapterId(workSummary.chapterId_current)}`}
								edits={workSummary.editSegments}
								editTrackChanges={editTrackChanges} 
								getWorkEditReviewFilled={() => props.getWorkEditReview(personId, workSummaries[0].workId)}
								globalChosenTab={globalChosenTab}
								goToNextSentence={goToNextSentence} 
								handleSetGlobalChosenTab={handleSetGlobalChosenTab}
								handleSetChosenSegment={handleSetChosenSegment}
								handleSetCurrentElement={handleSetCurrentElement}
								isAuthor={isAuthor}
								keyIndex={index}
								keepCommentOn={keepCommentOn} 
								localTranslation={localTranslation}
								moveSentences={moveSentences}
								moveTranslationToEditor={moveTranslationToEditor}
								navText={navText}
								openListModal={openListModal}
								prefixChapterId={editorService.getPrefixChapterId(workSummary.chapterId_current)}
								reorderListItems={reorderListItems}
								saveWorkSpaceTime={saveWorkSpaceTime}
								segments={workSummary.workSegments}
								setAddList={handleSetAddList}
								setAddListItem={handleSetAddListItem}
								setAddListTarget={setAddListTarget}
								setAddParagraphBreak={handleSetAddParagraphBreak}
								setAddParagraphSentence={handleSetAddParagraphSentence}
								setAddSentence={handleSetAddSentence}
								setChangeList={handleSetChangeList}
								setChangeStyle={handleSetChangeStyle}
								setChangeStyleSequence={setChangeStyleSequence}
								setChosenAddListEdit={handleSetChosenAddListEdit}
								setChosenAddListItemEdit={handleSetChosenAddListItemEdit}
								setChosenAddParagraphEdit={unhighlightAddParagraphIcons}
								setChosenDeleteListItemEdit={unhighlightDeleteListIcons}
								setChosenDeleteParagraphEdit={unhighlightDeleteParagraphIcons}
								setChosenMoveEdit={unhighlightMoveIcons}
								setChosenReorderListItemsEdit={handleSetChosenReorderListItemsEdit}
								setConvertAddListSequence={setConvertAddListSequence}
								setCurrentEditorDiv={setCurrentEditorDiv}
								setDeleteListItem={handleSetDeleteListItem}
								setDeleteParagraphBreak={handleSetDeleteParagraphBreak}
								setDeleteSentence={handleSetDeleteSentence}
								setEditTrackChanges={handleSetEditTrackChanges}
								setGoToNextSentence={handleSetGoToNextSentence}
								setIsInitEdits={setIsInitEdits}
								setIsOpenSlideOut={setIsOpenSlideOut}
								setKeepCommentOn={handleSetKeepCommentOn}
								setMoveSentences={handleSetMoveSentences}
								setMoveSequence={setMoveSequence}
								setMoveTranslationToEditor={handleSetMoveTranslationToEditor}
								setOpenListModal={setOpenListModal}
								setReorderListItems={handleSetReorderListItems}
								setSaveWorkSpaceTime={setSaveWorkSpaceTime}
								setShowAddListDecision={setShowAddListDecision}
								setShowClickInstruction={setShowClickInstruction}
								setShowEditIcons={setShowEditIcons}
								setShowEditorFullText={handleSetShowEditorFullText}
								setTabSelected={handleSetGlobalChosenTab}
								showAddListDecision={showAddListDecision}
								showEditIcons={showEditIcons}
								showEditorFullText={showEditorFullText}
								tabNav={tabNav}
								workSummary={workSummary}
								{...props} />
						)
					})}
				</div>
			</div>
      {!isMobile && 
				<div className={styles.rowSpaceToggleBoard}>
					<ToggleBoard
						// This chapterId works only if there is a single chapter coming in and it is not a discussion board.
						addList={addList} setAddList={handleSetAddList}
						addListItem={addListItem} setAddListItem={handleSetAddListItem}
						addParagraphBreak={addParagraphBreak} setAddParagraphBreak={!globalIsAuthor && handleSetAddParagraphBreak}
						addParagraphSentence={addParagraphSentence} setAddParagraphSentence={!globalIsAuthor && handleSetAddParagraphSentence}
						addSentence={addSentence} setAddSentence={!globalIsAuthor && handleSetAddSentence}
						changeList={changeList} setChangeList={handleSetChangeList}
						changeStyle={changeStyle} setChangeStyle={handleSetChangeStyle}
						chapterId={props.chapterId}
						convertAddListSequence={convertAddListSequence}
						currentElement={currentElement}
						deleteListItem={deleteListItem} setDeleteListItem={handleSetDeleteListItem}
						deleteParagraphBreak={deleteParagraphBreak} setDeleteParagraphBreak={!globalIsAuthor && handleSetDeleteParagraphBreak}
						deleteSentence={deleteSentence} setDeleteSentence={handleSetDeleteSentence}
						editTrackChanges={editTrackChanges} setEditTrackChanges={handleSetEditTrackChanges}
						goToNextSentence={goToNextSentence} setGoToNextSentence={handleSetGoToNextSentence}
						hasListStructure={globalHasListStructure}
						isTranslation={isTranslation}
						keepCommentOn={keepCommentOn} setKeepCommentOn={handleSetKeepCommentOn}
						moveSentences={moveSentences} setMoveSentences={handleSetMoveSentences}
						moveSequence={moveSequence} changeStyleSequence={changeStyleSequence} 
						moveTranslationToEditor={moveTranslationToEditor} setMoveTranslationToEditor={handleSetMoveTranslationToEditor}
						personId={personId}
						reorderListItems={reorderListItems} setReorderListItems={handleSetReorderListItems}
						setShowClickInstruction={setShowClickInstruction}/>
						showEditIcons={showEditIcons} setShowEditIcons={handleSetShowEditIcons}
						showEditorFullText={showEditorFullText} setShowEditorFullText={handleSetShowEditorFullText}
						sidePanel={sidePanel} setSidePanel={handleSetSidePanel}
				</div>
			} 	
      <MessageModal 
				show={showClickInstruction && !hideClickInstruction} 
				handleClose={() => setShowClickInstruction(false)} 
				heading={`After Choosing an Edit Type...`}
				explainJsx={
					<div>
						Click on a sentence where you want to make the edit.<br/><br/>
						<Checkbox
							label={`Got it! Don't show this message again`}
							position={`before`}
							checked={hideClickInstruction}
							onClick={handleHideClickInstruction} />
					</div>
				}
				onClick={() => setShowClickInstruction(false)} />
    </div>
	)
}

export default EditReviewView;

//Scoring an assignment
		//score: '', part of the score function that isn't working due to the cursor location management or EditorDiv.
		//import InputText from '../../components/InputText';  part of the score function that isn't working due to the cursor location management or EditorDiv.

		//Due to the restoreSelection in the EditorDiv, this became a big problem as it was not yet possible to keep the user control in the editor control.  Not good. Not worth losing the coordinated cursor in editorDiv
		// onBlurScore = (event) => {
		// 		const {setGradebookScoreByPenspring, personId, workSummary } = props;
		// 		setGradebookScoreByPenspring(personId, workSummary.studentAssignmentResponseId, event.target.value);
		// }
		//
		// handleScore = (event) => {
		// 		setState({ score: event.target.value, isScoreUpdateOnly: true });
		// 		event.stopPropagation();
		// 		event.preventDefault();
		// }
		//
		// {/*!workSummary.isHomework
		// 		? ''
		// 		: workSummary.canEditScore || workSummary.accessRoleName === 'Facilitator' || true
		// 				? <div className={styles.inputBox}>
		// 							<InputText size={"super-short"}
		// 									label={`score  (${workSummary.totalPoints})`}
		// 									value={score || (workSummary && workSummary.score) || ''}
		// 									numberOnly={true}
		// 									onChange={(event) => handleScore(event)}
		// 									onBlur={(event) => onBlurScore(event)} />
		// 					</div>
		// 				: <TextDisplay label={`Score (${workSummary.totalPoints})`} text={workSummary.score || '- -'} />
		// */}



//<div className={styles.lineHeight}>Save<br/><div className={styles.smallText}><span>auto: </span><DateMoment date={saveWorkSpaceTime} format={'h:mm:ss a'} className={styles.buttonTimeDisplay}/></div></div>
//I took out the auto save message from the button since I was having trouble with chapterText bleeding over into the next document while making use of ComponentWillUnmount.  There were errors.
