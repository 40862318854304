import toast, { ErrorIcon, CheckmarkIcon } from "react-hot-toast";
import { QueryClient } from "react-query";
import ButtonWithIcon from '../components/ButtonWithIcon'
import Icon from '../components/Icon'
import styles from './queryClient.module.css'

export function createInfoToastAuto(message, displayDuration = 3000) {
  const closeHandler = (t) => {
    toast.dismiss(t.id);
  }

  toast(
    (t) => (
      <div className="row">
        <div className="col-auto my-auto">
          <i className="bi bi-info-circle-fill"></i>
        </div>
        <div className="col my-auto border rounded p-1 text-white" dangerouslySetInnerHTML={{ __html: message }} />
        <div className="col-auto my-auto">
          <div className={styles.rowRight}>
            <span className={styles.cancelButton} onClick={() => {}}>
              <Icon pathName={'circle_pause'} premium={true}/>
              <div className={styles.pause}>pause</div>
            </span>
            <div className={styles.buttonHeight}>
              <ButtonWithIcon
                icon={'checkmark0'}
                label={'OK'}
                onClick={() => closeHandler(t)}
                addClassName={styles.buttonWidth} smaller />
            </div>
          </div>
        </div>
      </div>
    ),
    {
      duration: displayDuration,
      style: {
        maxWidth: "75em",
      },
    }
  )
}

export function createConfirmToastAuto(message, onYes) {
  const closeHandler = (t) => {
    toast.dismiss(t.id);
  }

  toast(
    (t) => (
      <div className="row" style={{borderRadius: '0px 8px 0px 8px'}}>
        <div className="col-auto my-auto">
          <i className="bi bi-question-circle-fill"></i>
        </div>
        <div className="col my-auto border rounded p-1 text-white" dangerouslySetInnerHTML={{ __html: message }} />
        <div className={styles.rowRight}>
          <span className={styles.cancelButton} onClick={() => closeHandler(t)}>
            No
          </span>
          <ButtonWithIcon label={'Yes'} icon={'checkmark0'} onClick={() => { onYes(); closeHandler(t); }} />
        </div>
      </div>
    ),
    {
      duration: Infinity,
      style: {
        maxWidth: "75em",
      },
    }
  )
}

export function createInfoToast(children, onClose = ()=>{}) {
  const closeHandler = (t) => {
    toast.dismiss(t.id);
    onClose();
  }
  toast(
    (t) => (
      <div className="row">
        <div className="col-auto my-auto">
          <i className="bi bi-info-circle-fill"></i>
        </div>
        <div className="col my-auto">{children}</div>
        <div className="col-auto my-auto">
          <button
            onClick={() => closeHandler(t)}
            className="btn btn-outline-secondary py-1"
          >
            X
          </button>
        </div>
      </div>
    ),
    {
      duration: Infinity,
      style: {
        maxWidth: "75em",
      },
    }
  )
}

export function createErrorToast(message) {
  toast(
    (t) => (
      <div className="row">
        <div className="col-auto my-auto">
          <ErrorIcon />
        </div>
        <div className="col my-auto"> {message}</div>
        <div className="col-auto my-auto">
          <button
            onClick={() => toast.dismiss(t.id)}
            className="btn btn-outline-secondary py-1"
          >
            X
          </button>
        </div>
      </div>
    ),
    {
      duration: Infinity,
      style: {
        maxWidth: "75em",
      },
    }
  );
}

export function createErrorToastHtml(message, isSuccess) {
  toast(
    (t) => (
      <div className="row">
        <div className="col-auto my-auto">
          {isSuccess ? <CheckmarkIcon /> : <ErrorIcon />}
        </div>
        <div className="col my-auto" dangerouslySetInnerHTML={{ __html: message }} />
        <div className="col-auto my-auto">
          <button
            onClick={() => toast.dismiss(t.id)}
            className="btn btn-outline-secondary py-1"
          >
            X
          </button>
        </div>
      </div>
    ),
    {
      duration: Infinity,
      style: {
        maxWidth: "75em",
      },
    }
  );
}

export function createSuccessToast(message) {
  toast(
    (t) => (
      <div className="row">
        <div className="col-auto my-auto">
          <CheckmarkIcon />
        </div>
        <div className="col my-auto"> {message}</div>
        <div className="col-auto my-auto">
          <button
            onClick={() => toast.dismiss(t.id)}
            className="btn btn-outline-secondary py-1"
          >
            X
          </button>
        </div>
      </div>
    ),
    {
      duration: Infinity,
      style: {
        maxWidth: "75em",
      },
    }
  );
}

const addErrorAsToast = async (error) => {
  return
  const message = getErrorMessage(error);

  toast(
    (t) => (
      <div className="row">
        <div className="col-auto my-auto">
          <ErrorIcon />
        </div>
        <div className="col my-auto">
          <div className="white-space">{message}</div>
          <div>
            <a href="https://snow.kualibuild.com/app/651eeebc32976c013a4c4739/run"
              target="_blank"
              rel="noreferrer">Report Bug</a>
          </div>
        </div>
        <div className="col-auto my-auto">
          <button
            onClick={() => toast.dismiss(t.id)}
            className="btn btn-outline-secondary py-1"
          >
            <i className="bi bi-x"></i>
          </button>
        </div>
      </div>
    ),
    {
      duration: Infinity,
    }
  );
};


function getErrorMessage(error) {
  if (error?.response?.status === 422) {
    const serializationMessages = error.response.data.detail.map((d) => `${d.type} - ${d.loc[1]}`)
    return `Deserialization error on request:\n${serializationMessages.join('\n')}`
  }
  if (typeof error === "string") {
    return error
  }
  if (error.response?.data.detail) {
    if (typeof error.response?.data.detail === "string") {
      return error.response?.data.detail
    }
    else return JSON.stringify(error.response?.data.detail)
  }
  //consol e.log(error)
  return "Error With Request"
}


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: addErrorAsToast,
      retry: 0,
    },
    mutations: {
      onError: addErrorAsToast,
      retry: 0,
    },
  },
});

export const getQueryClient = () => {
  return queryClient;
};

