import React, {Component} from 'react';
import GroupWorkAssignView from '../views/GroupWorkAssignView';
import { connect } from 'react-redux';
import * as actionPeerGroup from '../actions/peer-group.js';
import * as actionGroupWorkAssignAccess from '../actions/group-work-assign-access.js';
import { selectMe, selectGroups, selectWorkSummary, selectGroupWorkAssignAccess } from '../store.js';

const mapStateToProps = (state, props) => {
    const {groupChosen, masterWorkId, peerGroupId} = props.params;
    let me = selectMe(state);
    let group = selectGroups(state) && selectGroups(state).filter(m => m.groupId === groupChosen)[0];
    let peerGroup = (peerGroupId && group && group.peerGroups && group.peerGroups.filter(({peerGroup}) => peerGroup.peerGroupId === peerGroupId)[0]) || [];

    let subGroupCountOptions = []
    let maxLength = group && group.members && group.members.length ? group.members.length : 100;
    for(let i = 1; i <= maxLength; i++) {
        let option = { id: i, label: i};
        subGroupCountOptions = subGroupCountOptions ? subGroupCountOptions.concat(option) : [option];
    }

    return {
        personId: me.personId,
        masterWorkId,
        groupSummary: group,
        workSummary: selectWorkSummary(state, masterWorkId),
        subGroupCountOptions,
        accessAssigned: selectGroupWorkAssignAccess(state),
        peerGroupId: peerGroup && peerGroup.peerGroup && peerGroup.peerGroup.peerGroupId,
        subGroupCount: peerGroup && peerGroup.peerGroup && peerGroup.peerGroup.subGroupCount,
        peerGroupName: peerGroup && peerGroup.peerGroup && peerGroup.peerGroup.peerGroupName,
    }
};

const bindActionsToDispatch = dispatch => ({
    addOrUpdatePeerGroup: (peerGroup, subGroups) => dispatch(actionPeerGroup.addOrUpdatePeerGroup(peerGroup, subGroups)),
    //deletePeerGroup: (personId, peerGroupId) => dispatch(actionPeerGroup.updatePeerGroup(personId, peerGroupId)),  //Error updatePeerGroup does not exist in action
    addUpdateGroupWorkAssignAccess: (accessAssigned) => dispatch(actionGroupWorkAssignAccess.addUpdateGroupWorkAssignAccess(accessAssigned)),
    initGroupWorkAssignAccess: (personId, groupId, masterWorkId) => dispatch(actionGroupWorkAssignAccess.init(personId, groupId, masterWorkId)),
    copyPeerGroupGroupWorkAssignAccess: (personId, masterWorkId, previousPeerGroupId) => dispatch(actionGroupWorkAssignAccess.copyPeerGroupGroupWorkAssignAccess(personId, masterWorkId, previousPeerGroupId)),
});

const mergeAllProps = (store, actions) => ({
    ...store, ...actions,
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
  mergeAllProps
);

class Container extends Component {
    componentDidMount() {
        const {initGroupWorkAssignAccess, personId, groupSummary, masterWorkId} = this.props;
        initGroupWorkAssignAccess(personId, groupSummary.groupId, masterWorkId);
    }

    render() {
        const {groupSummary, workSummary, accessAssigned} = this.props;
        return workSummary && groupSummary && accessAssigned && accessAssigned.personId ? <GroupWorkAssignView {...this.props} /> : null;
    }
}

export default storeConnector(Container);
