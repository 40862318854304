import React, {useState, useEffect} from 'react';
import styles from './EditorInviteContactList.module.css';
import Checkbox from '../../components/Checkbox'
import InputText from '../../components/InputText'
import ContactSummaryLine from '../../components/ContactSummaryLine'

function EditorInviteContactList(props) {
	const {
		contacts = [],
		onClick,
	} = props;
	
	const [filterContactName, setFilterContactName] = useState('')
	const [contactsFiltered, setContactsFiltered] = useState()

	useEffect(() => {
	  let filtered = contacts && contacts.length > 0 ? [...contacts] : []
	  if (filterContactName) filtered = filtered && filtered.length > 0 && filtered.filter(m => m.firstName.toLowerCase().indexOf(filterContactName.toLowerCase()) > -1 || m.lastName.toLowerCase().indexOf(filterContactName.toLowerCase()) > -1)
	  setContactsFiltered(filtered)
	}, [contacts, filterContactName])


	return (
		<div className={styles.mainDiv}>
			<div style={{minWidth: '250px', width: '100%', marginBottom: '10px'}}>
				<InputText
					size={"medium"}
					name={"filterContactName"}
					label={`Filter contact name`}
					value={filterContactName}
					onChange={(event) => setFilterContactName(event.target.value)} />
			</div>
			<div className={styles.works}>
				{contactsFiltered && contactsFiltered.length > 0 && contactsFiltered.map((w, i) =>
					<div className={styles.row} key={i + 'summary'}>
						<Checkbox
							label={``}
							checked={false}
							onClick={() => onClick(w)}/>
						<div onClick={() => onClick(w)}>
							<ContactSummaryLine contact={w} nameLength={150} keyIndex={i + 'line'} LighterBackground/>
						</div>
					</div>
				)}
				{!(contacts && contacts.length > 0) && 
					<div className={styles.noContacts}>There are not any contacts found.</div>
				}
			</div>
		</div>
	);
}

export default EditorInviteContactList;
