import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom'
import MyProfileView from '../views/MyProfileView';
import { connect } from 'react-redux';
import * as actionMyProfile from '../actions/my-profile.js';
import {selectMe, selectMyProfile, selectUsernameToVerify} from '../store.js';
import * as actionEditorInvite from "../actions/editor-invite-response";

const mapStateToProps = (state, props) => {
  let me = selectMe(state);

  return {
    personId: me.personId,
    langCode: me.langCode,
    userProfile: selectMyProfile(state),
    usernameToVerify: selectUsernameToVerify(state),
  }
};

const bindActionsToDispatch = dispatch => ({
  getMyProfile: (personId) => dispatch(actionMyProfile.getMyProfile(personId)),
  setMyProfileNew: (user, isNew) => dispatch(actionMyProfile.setMyProfileNew(user, isNew)),
  updateMyProfile: (user) => dispatch(actionMyProfile.updateMyProfile(user)),
  verifyUsername: (personId, username) => dispatch(actionEditorInvite.verifyUsername(personId, username)),
  //removeProfilePicture: (personId, profilePictureId) => dispatch(actionMyProfile.removeProfilePicture(personId, profilePictureId)),
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
);

function Container(props) {
  const {personId, getMyProfile} = props;
  const params = useParams()

  useEffect(() => {
    if (!!personId) {
      getMyProfile(personId);
    }
  }, [personId])

  return <MyProfileView {...props} {...params}/>;
}

export default storeConnector(Container);
