import React, {useState, useEffect} from 'react';
import styles from './Accordion.module.css';
import Collapsible from "react-collapsible";
import Icon from '../../components/Icon';
import styled from "styled-components";

const Wrap = styled.div`
  /**
   * Overwrite the contentInner padding + border
   * to ensure zero height.
   */
  .Collapsible {
    background-color: #2485a2;	
	  border-radius: 4px 4px 0 0;
  }
  .Collapsible__contentInner {
    background-color: white;
    padding: 0;
    border: 0;
  }
	
	margin-bottom: 3px;
`;

function Accordion(props) {
	const {children, title, forceClose, forceOpen, toggleClick, open} = props;
	const [accordionOpen, setAccordionOpen] = useState(true)

	useEffect(() => {
		if (!forceOpen) {
      setAccordionOpen(false)
    }
	},[forceClose])

  useEffect(() => {
    if (forceOpen && !forceClose) {
      //setAccordionOpen(true)
      const collapsible = document.getElementById('collapsible')
      if (collapsible) collapsible.click()
    }
  },[forceOpen])

	return (
		<Wrap>
			<Collapsible id='collapsible' trigger={<div className={styles.rowWhiteLink} onClick={() => {
        setAccordionOpen(!accordionOpen)
        toggleClick && toggleClick()
      }}>
				<Icon pathName={'chevron_right'} premium={true} fillColor={'white'} className={accordionOpen ? styles.chevronDown : styles.chevronRight}/>
				{title}</div>} open={accordionOpen || open}>
				{children}
			</Collapsible>
		</Wrap>
	)
}

export default Accordion