import React from 'react';
import styles from './EditorNameDateSentence.module.css';
import classes from "classnames";
import DateMoment from '../DateMoment/DateMoment'
//import DiffWord from '../DiffWord/DiffWord'
import * as editorService from '../../services/editor-dom'
let jsdiff = require('diff');

function EditorNameDateSentence(props) {
  const { 
    currentElement, 
    differenceEditText, 
    edit, 
    editorColor, 
    index, 
    isHistory, 
    isTranslation, 
    originalSentenceText, 
    personConfig, 
    personId, 
    segment, 
    segments,
    setChosenTab,
    tabsData, 
    unhighlightChangeStyleIcons, 
    unhighlightMoveIcons, 
  } = props;

  let segmentText = segments.filter(segment => segment.elementId === Number(edit.elementId))[0]
  segmentText = segmentText?.text

  const getDisplay = (edit) => {
    const editorColorWithoutSymbol = editorColor && editorColor.replace('#', '')

    if (edit.type === 'TEXT' && segmentText) {
      if (!isTranslation && personConfig.editDifferenceView && originalSentenceText && differenceEditText) {
        return (
          <div key={index}>
            <div key={index} className={styles.editTypeLabel}>A sentence change</div>
            {setDiffWord(segmentText, edit.text)}
          </div>
        )
      } else {
        return (
          <div key={index} className={styles.column}>
            <div key={index} className={styles.editTypeLabel}>A sentence change</div>
            <span dangerouslySetInnerHTML={{ __html: differenceEditText }} />
          </div>
        )
      }
		} else if (edit.type === 'DELETEPARAGRAPH') {
			return (
        <span key={index} className={styles.editTypeLabel}>
          <img src={`/inline/paragraph-minus-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Delete Paragraph Break
        </span>
      )
		} else if (edit.type === 'DELETESENTENCE') {
			return (
        <span key={index} className={styles.editTypeLabel}>
          <img src={`/inline/sentence-minus-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Delete Sentence
          <div dangerouslySetInnerHTML={{ __html: segmentText}} className={styles.blackText}/>
        </span>
      )
    } else if (edit.type === 'ADDPARAGRAPHSENTENCE') {
      return (
        <span key={index} className={styles.editTypeLabel}>
          <img src={`/inline/paragraph-sentence-plus-${editorColorWithoutSymbol}.png`} alt={' '} height={22} /> Add Paragraph and Sentence
          <div dangerouslySetInnerHTML={{ __html: differenceEditText }} className={styles.blackText} />
        </span>
      )
    } else if (edit.type === 'ADDSENTENCE') {
      return (
        <span key={index} className={styles.editTypeLabel}>
          <img src={`/inline/sentence-plus-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Add Sentence
          <div dangerouslySetInnerHTML={{ __html: differenceEditText }} className={styles.blackText} />
        </span>
      )
		} else if (edit.type === 'ADDPARAGRAPH') {
			return (
        <span key={index} className={styles.editTypeLabel}>
          <img src={`/inline/paragraph-plus-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Add Paragraph Break
        </span>
      )
    } else if (edit.type === 'ADDLIST') {
      //show the first listItem text
      //Be ready to show the sentences highlighted (for the author only)
      const addListSpans = document.querySelectorAll(`span[id][data-type="ADDLIST"][data-edit-segment-id="${edit.editSegmentId}"]`)
      let displayText = ""
      for(let i = 0; i < addListSpans.length; i++) {
        if (!displayText && addListSpans[i].innerHTML) displayText = addListSpans[i].innerHTML
      }

      return (
        <div key={index}>
          <div className={styles.row}>
            <span key={index} className={styles.editTypeLabel}>
              <img src={`/inline/add-list-${editorColorWithoutSymbol}.png`} alt={' '} height={22} /> Add New List
              <div dangerouslySetInnerHTML={{ __html: displayText.replace(/\u00a0/g, "").replace(/\xA0/g, '').replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ') + ' ' }} className={styles.blackText} />
            </span>
          </div>
          {props.isAuthor && 
            <div className={styles.rowSpace}>
              <div onClick={() => {
                setChosenTab(edit.personId)
                setTimeout(() => editorService.showAddList(edit.editSegmentId), 500)
              }}
                  className={styles.linkSpace}>See list items</div>
            </div>
          }
        </div>
      )
    } else if (edit.type === 'CHANGELIST') {
      //Show the first listItem text with an ellipses
      const listElement = document.querySelector(`ol[id="${edit.elementId}"], ul[id="${edit.elementId}"]`)
      let displayText = ""
      let firstSpan = listElement?.firstChild
      let loop = 0
      while (firstSpan && !displayText && !(firstSpan.nodeName === 'SPAN' && firstSpan.id && !isNaN(firstSpan.id) && firstSpan.innerHTML.length > 0) && loop < 10) {
        firstSpan = firstSpan.firstChild
        loop++
      }
      if (firstSpan.nodeName === 'SPAN' && firstSpan.id && !isNaN(firstSpan.id) && firstSpan.innerHTML.length > 0) displayText = firstSpan.innerHTML + '...'

      return (
        <div key={index}>
          <div className={styles.row}>
            <span key={index} className={styles.editTypeLabel}>
              <img src={`/inline/change-list-type-${editorColorWithoutSymbol}.png`} alt={' '} height={22} /> Change List Type
              <div className={styles.row}>
                <div className={styles.whiteLabelWithLeft}>Change to:</div>
                <div className={styles.blackText}>
                  {editorService.getListTypeName(edit.listType)}
                </div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: displayText.replace(/\u00a0/g, "").replace(/\xA0/g, '').replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ') + ' ' }} className={styles.blackTextSpace} />
            </span>
          </div>
        </div>
      )
		} else if (edit.type === 'ADDLISTITEM') {
      //We need the entire edits records because the author is not going to have the spans on her view here. We need to reach into the edits records to find this particular edit to get the text.
      //const editAddListItem = edits.filter(m => m.editSegmentId === Number(currentElement.dataset.editSegmentId))[0]
      //We need to include any segmentsArray sentences that are also included in this edit.
      let displayText = edit && edit.text.replace(/\u00a0/g, "").replace(/\xA0/g, '').replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ') + ' '
      if (edit.segmentsArray) {
        const segmentsArray = edit.segmentsArray.split(',')
        segmentsArray?.length > 0 && segmentsArray.forEach(id => {
          const span = document.querySelector(`span[id="${id}"][data-type="TEXT"]`)
          if (span) displayText += ' ' + span.innerHTML.replace(/\u00a0/g, "").replace(/\xA0/g, '').replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ') + ' '
        })
      }
      return (
        <span key={index} className={styles.editTypeLabel}>
          <img src={`/inline/list-item-plus-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Add List Item
          <div dangerouslySetInnerHTML={{ __html: displayText.replace(/\u00a0/g, "").replace(/\xA0/g, '').replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ') + ' ' }} className={styles.blackText} />
        </span>
      )
		} else if (edit.type === 'DELETELISTITEM') {
			const listItemText = editorService.getListItemText(currentElement, segments)
			return (
        <span key={index} className={styles.editTypeLabel}>
          <img src={`/inline/list-item-minus-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Delete List Item
          <div dangerouslySetInnerHTML={{ __html: listItemText }} className={styles.blackText} />
        </span>
      )
		} else if (edit.type === 'REORDERLISTITEMS') {
			return (
        <span key={index} className={styles.editTypeLabel}>
          <img src={`/inline/reorder-list-${editorColorWithoutSymbol}.svg`} alt={' '} height={22} /> Re-order List Items
        </span>
      )
		} else if (edit.type === 'ADDTAB') {
			return (
        <div key={index}>
          <span style={{width: '36pt', display: 'inline-block', marginRight: '5px', color: 'white', borderRadius: '3px', background: `${editorService.getEditorGradient(personId, tabsData)}`}}>
            &nbsp;tab
          </span>
          <span className={styles.editTypeLabel}>Add Tab</span>
        </div>
      )
		} else if (edit.type === 'DELETETAB') {
			return (
        <div key={index}>
          <span style={{width: '36pt', display: 'inline-block', marginRight: '5px', color: 'white', borderRadius: '3px', background: `${editorService.getEditorGradient(personId, tabsData, 'reverse')}`}}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;tab
          </span>
          <span className={styles.editTypeLabel}>Delete Tab</span>
        </div>
      )
    } else if (edit.type === 'BLOCKLEFT') {
      return (
        <div key={index}>
          <img src={`/inline/block-left-${editorColorWithoutSymbol}.png`} alt={'block left'} height={15} />
          <span className={styles.editTypeLabel}> Paragraph Block from Left</span>
          <div dangerouslySetInnerHTML={{ __html: editorService.getPartialFirstSpan(edit.elementId) }} />
        </div>
      )
    } else if (edit.type === 'BLOCKRIGHT') {
      return (
        <div key={index}>
          <img src={`/inline/block-right-${editorColorWithoutSymbol}.png`} alt={'block right'} height={15} />
          <span className={styles.editTypeLabel}> Paragraph Block from Right</span>
          <div dangerouslySetInnerHTML={{ __html: editorService.getPartialFirstSpan(edit.elementId) }} />
        </div>
      )
    } else if (edit.type === 'TEXTALIGN') {
      return (
        <div key={index}>
          <img src={`/inline/text-align-${editorColorWithoutSymbol}.png`} alt={'text align'} height={15} />
          <span className={styles.editTypeLabel}> Paragraph Text Align: {edit.styleEdit}</span>
          <div dangerouslySetInnerHTML={{ __html: editorService.getPartialFirstSpan(edit.elementId) }} />
        </div>
      )
    } else if (edit.type === 'TEXTINDENT') {
      return (
        <div key={index}>
          <img src={`/inline/text-indent-${editorColorWithoutSymbol}.png`} alt={'text indent'} height={15} />
          <span className={styles.editTypeLabel}> Paragraph Text Indent</span>
          <div dangerouslySetInnerHTML={{ __html: editorService.getPartialFirstSpan(edit.elementId) }} />
        </div>
      )
		} else if (edit.type === 'LISTLEVELMINUS') {
			return (
        <div key={index}>
          <span style={{width: '36pt', display: 'inline-block', marginRight: '5px', color: 'white', borderRadius: '3px', background: `${editorService.getEditorGradient(personId, tabsData, 'reverse')}`}}>
            &nbsp;&nbsp;&nbsp;level
          </span>
          <span className={styles.editTypeLabel}> Move list item to the left</span>
        </div>
      )
		} else if (edit.type === 'LISTLEVELPLUS') {
			return (
        <div key={index}>
          <span style={{width: '36pt', display: 'inline-block', marginRight: '5px', color: 'white', borderRadius: '3px', background: `${editorService.getEditorGradient(personId, tabsData)}`}}>
            &nbsp;level
          </span>
          <span className={styles.editTypeLabel}> Move list item to the right</span>
        </div>
      )
		} else if (edit.type === 'MOVE') {
			let segmentsArray = edit.segmentsArray.split(',')
			let sentenceCount = segmentsArray.length
			return (
        <div key={index}>
          <div className={styles.row}>
            <img src={`/inline/move-start-${editorColorWithoutSymbol}.svg`} alt={'move'} height={15} />
            <div className={classes(styles.space, styles.editTypeLabel)}>{`Move ${sentenceCount} sentence${sentenceCount > 1 ? 's' : ''}`}</div>
          </div>
          <div className={styles.rowSpace}>
            <div onClick={() => {
              unhighlightMoveIcons(edit)
              editorService.showTarget(segmentsArray[segmentsArray.length - 1], edit.moveToElementId, edit.editSegmentId)
              props.setIsOpenSlideOut(false)
            }} className={styles.linkSpace}>See target</div>
            <div onClick={() => {
              unhighlightMoveIcons(edit)
              editorService.showMoveSentences(segmentsArray, edit.editSegmentId)
              props.setIsOpenSlideOut(false)
            }} className={styles.linkSpace}>{`See sentence${sentenceCount > 1 ? 's' : ''}`}</div>
          </div>
        </div>
      )
    } else if (edit.type === 'CHANGESTYLE') {
      if (edit.changeStyleEntireDoc) {
        return (
          <div key={index}>
            <div className={styles.row}>
              <img src={`/inline/change-style-start-${editorColorWithoutSymbol}.png`} alt={'move'} height={15} />
              <div className={classes(styles.space, styles.editTypeLabel)}>Change Style of entire document</div>
            </div>
            <div className={styles.rowSpace}>
            </div>
            <div>{showChangeStyleDifferences(edit.textStyleChangeEntries)}</div>
          </div>
        )
      } else {
        //The ChangeStyle edit can have a start, middle and end. The middle actually holds all of the spans involved, so we have cut out the changeStylePartialSpan from the edits sorte.
        const segmentsArray = edit.segmentsArray?.length > 0 && edit.segmentsArray.split(',')
        const sentenceCount = segmentsArray.length
        let displayText = ''
        if (edit.changeStyleEntireDoc) {
          displayText = 'Change style for the entire document'
        } else if (segmentsArray?.length > 0) {
          displayText =  `Change Style of ${sentenceCount} sentence${sentenceCount > 1 ? 's' : ''}`
        }
        return (
          <div key={index}>
            <div className={styles.row}>
              <img src={`/inline/change-style-start-${editorColorWithoutSymbol}.png`} alt={'move'} height={15} />
              <div className={classes(styles.space, styles.editTypeLabel)}>{displayText}</div>
            </div>
            <div className={styles.rowSpace}>
              <div onClick={() => {
                unhighlightChangeStyleIcons(edit)
                editorService.showChangeStyle(segmentsArray, edit.editSegmentId)
                props.setIsOpenSlideOut(false)
              }} className={styles.linkSpace}>{`See sentence${sentenceCount > 1 ? 's' : ''}`}</div>
            </div>
            <div>{showChangeStyleDifferences(edit.textStyleChangeEntries)}</div>
          </div>
        )
      }
		} else {
      return differenceEditText
		}
	}

  const formatDisplayLabel = (code) => {
    const words = code.replace(/([A-Z])/g, ' $1').trim();
    return words.charAt(0).toUpperCase() + words.slice(1);
  }

  const showChangeStyleDifferences = (entries) => {
    return (
      <div>
        {entries?.length > 0 && entries.map(m => 
          <div className={styles.row}>
            <div className={styles.settingText}>
              {formatDisplayLabel(m.code)}
            </div>
            <div className={styles.valueColumn}>{`${m.value} ${m.value !== 'smaller' && (m.code === 'fontSize' || m.code === 'marginBottom') ? 'px' : m.code === 'textIndent' ? ' characters' : ''}`}</div>
          </div>
        )}
      </div>
    )    
  }

  const setDiffWord = (inputA, inputB) => {
    const regex = "/<(.|\n)*?>/";
    inputA = inputA && inputA.replace(regex, "")
      .replace(/<br>/g, "")
      //.replace(/<[^>]*>/g, ' ')
      .replace(/\s{2,}/g, ' ')
      .replace(/&#xa0;/g, ' ')
      .replace(/&nbsp;/g, ' ')
      .trim();

    inputB = inputB && inputB.replace(regex, "")
      .replace(/<br>/g, "")
      //.replace(/<[^>]*>/g, ' ')
      .replace(/\s{2,}/g, ' ')
      .replace(/&#xa0;/g, ' ')
      .replace(/&nbsp;/g, ' ')
      .trim();

    const diff = jsdiff.diffWords(inputA, inputB); //fnMap[props.type]
    const result = diff && diff.length > 0 && diff.map((part, index) => {
      if (part.added) {
        return <ins key={index}>{part.value}</ins>
      }
      if (part.removed) {
        return <del key={index}>{part.value}</del>
      }
      return <span key={index}>{part.value}</span>
    });
    return result
  }

	return (
    <div className={styles.container}>
			<div>
        {isTranslation && segment.translatedAuthor && edit.type === 'TEXT' &&
          <div className={styles.bottomSpace}>
            <div className={styles.row}>
              <span className={styles.translatedAuthor}>TRANSLATED DECISION BY AUTHOR </span>
              <DateMoment date={segment.translatedAuthorDate} dateTextClass={styles.date}/>
            </div>
            <span dangerouslySetInnerHTML={{__html: getDisplay(edit)}} />
            <hr/>
          </div>
        }
        <span className={styles.authorDateLine}>
            <div className={styles.colorBox} style={{backgroundColor: editorColor}} />
          {`${edit.firstName} ${edit.lastName} `}
          <DateMoment date={edit.entryDate} dateTextClass={styles.date}/>
          {/*{edit.isComment && <span className={styles.isComment}> Comment </span>}*/}
          {/*{!edit.pendingFlag && edit.authorAccepted && <span className={styles.isAccepted}> Accepted </span>}*/}
          {/*{!edit.pendingFlag && !edit.authorAccepted && <span className={styles.isProcessed}> Processed </span>}*/}
        </span>
        <div className={classes(styles.editText, (edit.isComment ? styles.isCommentText : ''))} id={edit.personId}>
          {getDisplay(edit)}
          {/*{!isTranslation && personConfig.editDifferenceView && originalSentenceText && differenceEditText &&*/}
          {/*  <div className={styles.diffText}><hr/>*/}
          {/*    {setDiffWord(originalSentenceText, differenceEditText)}*/}
          {/*  </div>*/}
          {/*}*/}
          {edit.comment &&
            <div>
              <div className={styles.textSmallGray}>{edit.firstName}'s comment:</div>
              <div className={styles.editorComment}>{edit.comment}</div>
            </div>
          }
        </div>
			</div>
			{isHistory &&
				<div className={styles.historyDate}>
					<div className={styles.rejectAccept}>{edit.acceptedDate ? 'Accepted: ' : 'Rejected: '}</div>
					<DateMoment date={edit.acceptedDate ? edit.acceptedDate : edit.rejectedDate} dateTextClass={styles.date}/>
				</div>
			}
		</div>
  )
}

export default EditorNameDateSentence