import React from 'react';
import {Link} from 'react-router-dom';
import styles from './MyContactsView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import ContactFilter from '../../components/ContactFilter';
import DateMoment from '../../components/DateMoment';
import FlexTabularScroll from '../../components/FlexTabularScroll';
import InvitesPending from '../../components/InvitesPending';
import Icon from '../../components/Icon';
import Accordion from '../../components/Accordion';
import OneFJefFooter from '../../components/OneFJefFooter';
import classes from 'classnames';

function GiveAccessToEditorsView(props) {
	const {personId, works=[], contactFilter=[], className="", updateFilterByField, clearFilters, setContactCurrentSelected, contactSummaries=[],
                    workSummary, editorInvitePending, deleteInvite, acceptInvite, resendInvite, filterScratch, savedFilterIdCurrent, contactFilterOptions,
                    updateSavedSearch, updateFilterDefaultFlag, deleteSavedSearch, chooseSavedSearch, saveNewSavedSearch} = props

	let data = []
	let headings = [{
			label: '',
			cellColor: 'white'
		},
		{
			verticalText: true,
			label: <div className={styles.narrowLine}>Assigned documents</div>
		},
		{
			verticalText: true,
			label: <div className={styles.narrowLine}>Total edits</div>
		},
		{
			verticalText: true,
			label: <div className={styles.narrowLine}>Author accepted</div>
		},
		{
			verticalText: true,
			label: <div className={styles.narrowLine}>Edits pending</div>
		},
		{
			verticalText: true,
			label: <div className={styles.narrowLine}>Vote up</div>
		},
		{
			verticalText: true,
			label: <div className={styles.narrowLine}>Vote down</div>
		},
		{
			verticalText: true,
			label: <div className={styles.narrowLine}>Vote troll</div>
		},
		{
			verticalText: true,
			label: <div className={styles.narrowLine}>Votes cast</div>
		}
	]

	contactSummaries.forEach((contact, i) => {
		let clickFunction = () => {}
		let row = [{
				cellColor: 'background',
				clickFunction: () => {},
				value: <div onClick={() => {}} className={styles.editorName}>
								 {`${contact.firstName} ${contact.lastName}`}
								 <span className={styles.editorScore}>{contact.editorScore}</span>
							 </div>
			},
			{
				value: contact.worksAssigned
			},
			{
				value: contact.totalEdits
			},
			{
				value: contact.authorAcceptedEdits
			},
			{
				value: contact.editsPending
			},
			{
				value: contact.voteUpCount
			},
			{
				value: contact.voteDownCount
			},
			{
				value: contact.trollCount
			},
			{
				value: contact.votesCast
			}
	]

		data.push(row)
	})

	return (
      <div className={styles.container}>
        <div className={styles.titleLine}>
            <span className={globalStyles.pageTitle}>My Contacts</span>
        </div>
        {contactSummaries.length > 10 &&
          <Accordion title={'Search'}>
            {/*expanded={false} filterScratch={filterScratch} filterOptions={contactFilterOptions} savedFilterIdCurrent={savedFilterIdCurrent}*/}
            {/*      updateSavedSearch={updateSavedSearch} deleteSavedSearch={deleteSavedSearch} chooseSavedSearch={chooseSavedSearch}*/}
            {/*      updateFilterByField={updateFilterByField} updateFilterDefaultFlag={updateFilterDefaultFlag} personId={personId}*/}
            {/*      clearFilters={clearFilters}>*/}
              <ContactFilter personId={personId} contactFilter={filterScratch} className={styles.contactFilter} updateFilterByField={updateFilterByField}
                  clearFilters={clearFilters} showAccessIcon={false} saveNewSavedSearch={saveNewSavedSearch} savedSearchOptions={contactFilterOptions}/>
          </Accordion>
        }
        <hr />
        <div>
						<div className={classes(styles.row, styles.moreLeft, styles.newEditor)}>
							<Icon pathName={'user'} premium={true} superscript={'plus'} supFillColor={'green'} className={styles.icon} fillColor={'black'}/>
							<Link to={'/editorInviteOptions'} >Invite a new editor</Link>
						</div>
            {editorInvitePending && editorInvitePending.length > 0 &&
                <InvitesPending invites={editorInvitePending} personId={personId} deleteInvite={deleteInvite} acceptInvite={acceptInvite}
                    resendInvite={resendInvite}/>
            }
        </div>
        <hr />
        {!contactSummaries || contactSummaries.length === 0 ? <span className={styles.noListMessage}>{`empty list`}<br/><br/></span> : ''}
	      <div className={styles.borderBackground}>
		      <div className={styles.scroll}>
		        <FlexTabularScroll headings={headings} data={data} noCount={true}/>
		      </div>
	      </div>
        <OneFJefFooter />
      </div>
    )
}

export default GiveAccessToEditorsView