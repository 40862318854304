import React, {useState} from 'react';
import styles from './DraftReviewView.module.css';
import * as editorService from '../../services/editor-dom'
import SentenceEdits from '../../components/SentenceEdits';
import AuthoringDrafts from '../../components/AuthoringDrafts';
import MediaQuery from 'react-responsive'
import backgroundColors from "../../utils/backgroundColors";

let newId = ''

function DraftReviewView(props) {
	const {editLanguageId, workSummary, isDraftView, toggleDraftView, isTranslation, getTranslation, translatedSentence,
    edits, draftReview } = props;  //bookmarkOptions, bookmarks, editorColors, editReview={}

	const [chosenTab, setChosenTab] = useState();
	const [showEditorFullText, setShowEditorFullText] = useState(false)
	const [currentElement, setCurrentElement] = useState()
	const [isInitEdits, setIsInitEdits] = useState('') //This is for the case of DELETEPARAGRAPH when the editor is deleting their own paragraph in SentenceEdits and needs to recall the setSegmentsWithEdits

	const unhighlightMoveIcons = (editSegmentId, clearLast) => {
		let editMatch = edits && edits.length > 0 && edits.filter(m => m.type === 'MOVE')
		editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
			editorService.unshowMoveIcons(edit)
		})
	}

	const unhighlightAddParagraphIcons = (elementId, clearLast) => {
		let editMatch = edits && edits.length > 0 && edits.filter(m => m.type === 'ADDPARAGRAPH')
		editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
			editorService.unshowAddParagraphIcons(edit)
		})
	}

	const unhighlightDeleteParagraphIcons = (elementId, clearLast) => {
		editorService.unshowDeleteParagraphIcons(clearLast ? '' : elementId)
	}

	const handleSetChosenAddParagraphSentenceEdit = (elementId, clearLast) => {
		let editMatch = edits && edits.length > 0 && edits.filter(m => m.type === 'ADDPARAGRAPHSENTENCE')
		editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
			editorService.unshowAddParagraphSentenceIcons(edit)
		})
	}

	const unhighlightAddSentenceIcons = (elementId, clearLast) => {
		let editMatch = edits && edits.length > 0 && edits.filter(m => m.type === 'ADDSENTENCE')
		editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
			editorService.unshowAddSentenceIcons(edit)
		})
	}

	const unhighlightDeleteSentenceIcons = (elementId, clearLast) => {
		let editMatch = edits && edits.length > 0 && edits.filter(m => m.type === 'DELETESENTENCE')
		editMatch && editMatch.length > 0 && editMatch.forEach(edit => {
			editorService.unshowDeleteSentenceIcons(edit)
		})
	}

	const handleSetChosenAddListItemEdit = (elementId, clearLast) => {
		let elements = document.querySelectorAll(`[data-type="ADDLISTITEM"]`)
		elements && elements.length > 0 && elements.forEach(m => {
			m.style.backgroundColor = backgroundColors.normal
			if (m.nodeName === 'IMG') {
				m.height = 22
			}
		})
	}

	const unhighlightDeleteListIcons = (elementId, clearLast) => {
		editorService.unshowDeleteListItemIcons(clearLast ? '' : elementId)
	}

	const handleSetChosenReorderListItemsEdit = (elementId, clearLast) => {
		editorService.unshowReorderListItemsIcons(clearLast ? '' : elementId)

	}

	const initIdFromSegments = (arr) => {
		let maxValue = 0;
		for(let i=0;i<arr.length;i++){
			if(arr[i].elementId > maxValue){
				maxValue = arr[i].elementId;
			}
		}
		return ++maxValue;
	}

	const getNextId = () => {
		return newId++
	}

	const handleEditorTabChosen = (event, id, viewSide) => { //Help ToDo: This is a different function but the same name as the one found in EditReviewView.
    event.stopPropagation()
    event.preventDefault()
    setCurrentElement(null)
    if (viewSide === 'LEFT') {
      props.getDraftReview({workId: workSummary.workId, chapterId: workSummary.chapterId_current, leftChosenTab: id, rightChosenTab: draftReview.rightChosenTab, languageId: editLanguageId})
    } else if (viewSide === 'RIGHT') {
      props.getDraftReview({workId: workSummary.workId, chapterId: workSummary.chapterId_current, leftChosenTab: draftReview.leftChosenTab, rightChosenTab: id, languageId: editLanguageId})
    }
	}

	let tabNav = isDraftView ? toggleDraftView : () => {}
	let navText = isDraftView && `Close Drafts`

	const handleSetCurrentElement = element => {
    let elementId = element.id && element.id.indexOf('tabView') > -1 ? element.id.replace('~tabView', '') : element.id
		setCurrentElement(document.querySelector(`[id="${elementId}"][data-type="${element.dataset.type}"]`))
	}

	return (
		<div id={'mainPage'} className={styles.editReviewPage}>
	    <div className={styles.rowEditReview}>
		    <MediaQuery minWidth={1224}>
			    <div className={styles.sidePanel}>
		        <SentenceEdits
              isDraftReview
              edits={currentElement && currentElement.id.indexOf('tabView') > -1 ? draftReview.rightEdits : draftReview.leftEdits}
			        isTranslation={isTranslation}
			        translatedSentence={translatedSentence}
			        getTranslation={getTranslation}
			        setTabSelected={handleEditorTabChosen}
			        chosenTab={chosenTab}
			        currentElement={currentElement}
			        handleSetCurrentElement={handleSetCurrentElement}
			        setShowEditorFullText={setShowEditorFullText}
			        setIsInitEdits={setIsInitEdits}
			        unhighlightMoveIcons={unhighlightMoveIcons}
			        unhighlightAddParagraphIcons={unhighlightAddParagraphIcons}
			        unhighlightDeleteParagraphIcons={unhighlightDeleteParagraphIcons}
							handleSetChosenAddParagraphSentenceEdit={handleSetChosenAddParagraphSentenceEdit}
			        unhighlightAddSentenceIcons={unhighlightAddSentenceIcons}
			        unhighlightDeleteSentenceIcons={unhighlightDeleteSentenceIcons}
			        handleSetChosenAddListItemEdit={handleSetChosenAddListItemEdit}
			        unhighlightDeleteListIcons={unhighlightDeleteListIcons}
			        handleSetChosenReorderListItemsEdit={handleSetChosenReorderListItemsEdit}
		          getNextId={getNextId}
			        {...props} />
			    </div>
		    </MediaQuery>
		    <div className={styles.editorPanel}>
		      <AuthoringDrafts currentElement={currentElement}
                           handleSetCurrentElement={handleSetCurrentElement}
                           getNextId={getNextId}
                           chosenTab={chosenTab}
                           setIsInitEdits={setIsInitEdits}
                           tabNav={tabNav}
                           navText={navText}
                           isTranslation={isTranslation}
                           translatedSentence={translatedSentence}
                           getTranslation={getTranslation}
                           handleEditorTabChosen={handleEditorTabChosen}
                           {...props} />
		    </div>
			</div>
    </div>
	)
}

export default DraftReviewView;