import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import styles from './AssignmentDashboardView.module.css';
import classes from 'classnames';
import globalStyles from '../../utils/globalStyles.module.css';
import GroupTools from '../../components/GroupTools';
import Icon from '../../components/Icon';
import SelectSingleDropDown from '../../components/SelectSingleDropDown';
import MessageModal from '../../components/MessageModal';
import EditTable from '../../components/EditTable';
import StatusLegend from '../../components/StatusLegend';
import numberFormat from '../../utils/numberFormat.js';
import OneFJefFooter from '../../components/OneFJefFooter';

function AssignmentDashboardView(props) {
  const {personId, initGroupEditReport, groupSummary, setGroupCurrentSelected, deleteGroup, headTitle, subHeadTitle, headings, data,  statusLegend,
    setWorkCurrentSelected, updatePersonConfig, personConfig, currentGroupId} = props;

  const navigate = useNavigate()

  const [isShowingSubMenu, setIsShowingSubMenu] = useState(false)
  const [timerId, setTimerId] = useState()
  const [member_personId, setMember_personId] = useState()
  const [peerGroupId, setPeerGroupId] = useState()
  const [isShowingModal_deleteWork, setIsShowingModal_deleteWork] = useState(false)
  const [isShowingModal_noMemberChosen, setIsShowingModal_noMemberChosen] = useState(false)
  const [isShowingModal_noPeerGroupChosen, setIsShowingModal_noPeerGroupChosen] = useState(false)
  const [isShowingModal_deletePeerGroup, setIsShowingModal_deletePeerGroup] = useState(false)
  const [isShowingModal_deleteMember, setIsShowingModal_deleteMember] = useState(false)

  useEffect(() => {
      setTimerId(setInterval(() => initGroupEditReport(personId, currentGroupId), 15000))
      return () => clearInterval(timerId);
  }, [])


    const handleSendMemberUpdate = () => {
        member_personId && navigate("/groupMemberUpdate/" + member_personId);
    }

    const handleToggleSubMenu = () => {
        setIsShowingSubMenu(!isShowingSubMenu)
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (nextProps.data !== props.data || nextProps.groupSummary !== props.groupSummary) {
    //         return true;
    //     }
    //     return false;
    // }

    const sendToWorkAssign = () => {
        const {groupSummary} = props;
        navigate('/groupWorkAssign/' + groupSummary.groupId + '/' + groupSummary.masterWorkId);
    }

  const handlePathLink = (pathLink) => {
    const {initWorkWithAssignmentWorkId, updateGroupWorkStatus, personId, groupSummary} = props;
    if (pathLink.indexOf('EDITREVIEW!!') > -1) {
        let workId  = pathLink.substring(12);
        initWorkWithAssignmentWorkId(personId, workId);
    } else if (pathLink.indexOf('STATUSUPDATE!!') > -1) {
        let workId = pathLink.substring(pathLink.indexOf('^^') + 2);
        let groupWorkStatusName = pathLink.substring(pathLink.indexOf('!!') + 2, pathLink.indexOf('^^'));
        let newStatus = groupWorkStatusName === "SUBMITTED" || groupWorkStatusName === "ACCEPTED" ? "REVIEWRESPONDED" : "SUBMITTED";
        updateGroupWorkStatus(personId, groupSummary.groupId, workId, newStatus);
    } else {
        pathLink && navigate(pathLink);
    }
  }

  const handleDeleteMember = () => {
      const {personId, groupId, removeMember} = props;
      removeMember(personId, groupId, member_personId);
      handleDeleteMemberClose();
  }

  const handleDeleteWork = () => {
      const {personId, groupSummary, deleteWork} = props;
      deleteWork(personId, groupSummary.masterWorkId);
      handleDeleteWorkClose();
  }

  const onChangeMemberWork = (event) => {
      const {setGroupCurrentSelected, personId, groupSummary} = props;
      setGroupCurrentSelected(personId, groupSummary.groupId, groupSummary.masterWorkId, event.target.value, 'STAY');
  }

  const onChangePeerGroup = (event) => {
      setPeerGroupId(event.target.value)
  }

  const onChangeMember = (event) => {
      setMember_personId(event.target.value)
  }

  const onChangeWork = (event) => {
      const {groupInit, setWorkCurrentSelected, setGroupCurrentSelected, personId, groupSummary} = props;
      setGroupCurrentSelected(personId, groupSummary.groupId, event.target.value, groupSummary.memberWorkId, 'STAY');
      setWorkCurrentSelected(personId, event.target.value, '', '', 'STAY');
      groupInit(personId);
  }

  const handleMemberNotChosenOpen = () => setIsShowingModal_noMemberChosen(true)
  const handleMemberNotChosenClose = () => setIsShowingModal_noMemberChosen(false)

  const handleDeleteMemberClose = () => setIsShowingModal_deleteMember(false)
  const handleDeleteMemberOpen = () => {
      if (!member_personId) {
          handleMemberNotChosenOpen();
          return;
      }
      setIsShowingModal_deleteMember(true)
  }

  const handleDeleteWorkClose = () => setIsShowingModal_deleteWork(false)
  const handleDeleteWorkOpen = () => setIsShowingModal_deleteWork(true)

  const handlePeerGroupNotChosenOpen = () => setIsShowingModal_noPeerGroupChosen(true)
  const handlePeerGroupNotChosenClose = () => setIsShowingModal_noPeerGroupChosen(false)

  const handleDeletePeerGroupOpen = () => setIsShowingModal_deletePeerGroup(true)
  const handleDeletePeerGroupClose = () => setIsShowingModal_deletePeerGroup(false)
  const handleDeletePeerGroupSubmit = () => {
      if (!peerGroupId) {
          handlePeerGroupNotChosenOpen();
          return;
      }
      setIsShowingModal_deletePeerGroup(true)
  }

    return (
      <div className={styles.container}>
          <div className={globalStyles.pageTitle}>
              Assignment Dashboard
          </div>
          <div className={styles.subTitle}>
              {groupSummary.groupName}
          </div>
          <div className={styles.subTitle}>
              <GroupTools personId={personId} summary={groupSummary} currentTool={'assignmentDashboard'}
                  setGroupCurrentSelected={setGroupCurrentSelected} deleteGroup={deleteGroup}
                  isOwner={personId === groupSummary.ownerPersonId} className={styles.tools}
                  updatePersonConfig={updatePersonConfig} personConfig={personConfig} />
          </div>
          <hr />
          <div className={styles.menuItem}>
              <Link to={`/workAddNew/${groupSummary && groupSummary.groupId}`} className={styles.addNew}>
                  {groupSummary.groupTypeName === 'FACILITATORLEARNER' ? 'Add new assignment' : 'Add new document'}
              </Link>
          </div>
          <hr />
          <div className={styles.menuItem}>
              <Link to={`/groupMemberAdd`} className={styles.addNew}>
                  {groupSummary.groupTypeName === 'FACILITATORLEARNER' ? 'Add new learner' : 'Add new member'}
              </Link>
          </div>
          <hr />
          <table className={styles.tableDisplay}>
            <tbody>
              <tr>
                  <td className={styles.tableLabel}>
                      <span className={styles.tableLabel}>{groupSummary.groupTypeName === 'FACILITATORLEARNER' ? 'Assignments' : 'Documents'}</span>
                  </td>
                  <td className={styles.tableLabel}>
                      <span className={styles.count}>{numberFormat(groupSummary.workSummaries.length || 0)}</span>
                  </td>
                  <td colSpan={3}>
                      <div className={styles.rowTight}>
                          <div>
                              <SelectSingleDropDown
                                  id={`works`}
                                  label={``}
                                  value={groupSummary.masterWorkId}
                                  options={groupSummary.workSummaries}
                                  className={styles.singleDropDown}
                                  noBlank={true}
                                  error={''}
                                  height={`medium`}
                                  onChange={onChangeWork} />
                          </div>
                          <a onClick={() => setWorkCurrentSelected(personId, groupSummary.masterWorkId, '', '', "/workSettings")} className={styles.linkStyle}>
                              <Icon pathName={`pencil0`} premium={true} className={styles.image}/>
                          </a>
                          <a onClick={handleDeleteWorkOpen} className={styles.linkStyle}>
                              <Icon pathName={`trash2`} premium={true} className={styles.image}/>
                          </a>
                          {!!groupSummary.workSummaries &&
                              <a onClick={sendToWorkAssign} className={styles.rowTight}>
                                  <Icon pathName={`document0`} premium={true} className={styles.imageDocument}/>
                                  <Icon pathName={`user_plus0`} premium={true} className={styles.imageOverlay}/>
                              </a>
                          }
                      </div>
                  </td>
              </tr>
              <tr>
                  <td className={styles.tableLabel}>
                      <span className={styles.tableLabel}>{groupSummary.groupTypeName === 'FACILITATORLEARNER' ? 'Learners' : 'Editors'}</span>
                  </td>
                  <td className={styles.tableLabel}>
                      <span className={styles.count}>{numberFormat(groupSummary.members.length || 0)}</span>
                  </td>
                  <td>
                      <div className={styles.rowTight}>
                          <div>
                              <SelectSingleDropDown
                                  id={`members`}
                                  label={``}
                                  value={member_personId}
                                  options={groupSummary.members}
                                  className={styles.singleDropDown}
                                  error={''}
                                  height={`medium`}
                                  onChange={onChangeMember}/>
                          </div>
                          <a onClick={handleSendMemberUpdate} className={classes(styles.linkStyle, member_personId ? styles.fullOpacity : styles.lowOpacity)}>
                              <Icon pathName={`pencil0`} premium={true} className={styles.image}/>
                          </a>
                          <a onClick={handleDeleteMemberOpen} className={styles.linkStyle}>
                              <Icon pathName={`trash2`} premium={true} className={styles.image}/>
                          </a>
                      </div>
                  </td>
              </tr>
          </tbody>
      </table>
      <hr />
      {data && data.length > 0 &&
          <div>
              <div className={styles.column}>
                  <div>
                      <div className={styles.headTitle}>{headTitle}</div>
                      <div className={styles.subHeadTitle}>{subHeadTitle}</div>
                  </div>
                  <StatusLegend opened={isShowingSubMenu} toggleOpen={handleToggleSubMenu} subjectBody={statusLegend}
                      headerText={'status legend'} className={styles.statusLegend}/>
              </div>
              <EditTable labelClass={styles.tableLabelClass} headings={headings}
                  data={data} noCount={true} firstColumnClass={styles.firstColumnClass}
                  sendToReport={handlePathLink}/>
          </div>
      }
      {(!data || data.length === 0) &&
          <div className={styles.noAccess}>
              Access to this assignment has not yet been granted.<br />
              You can grant access
              <a onClick={sendToWorkAssign} className={styles.linkStyle}>
                  here.
              </a>
              <a onClick={sendToWorkAssign} className={classes(styles.moreLeftMargin, styles.linkStyle)}>
                  <Icon pathName={`user_plus0`} premium={true} className={styles.image}/>
              </a>
              Or if you just granted access, the new assignments are being copied out to the learners.
              Please refresh this page in a few moments.
          </div>
      }
      <MessageModal show={isShowingModal_deleteMember} handleClose={handleDeleteMemberClose} heading={`Remove this member from this group?`}
         explain={`Are you sure you want to delete this member from this group?`} isConfirmType={true}
         onClick={handleDeleteMember} />
      <MessageModal show={isShowingModal_noMemberChosen} handleClose={handleMemberNotChosenClose} heading={`No member chosen`}
         explain={`Please choose a member before requesting to delete a member.`} isConfirmType={false}
         onClick={handleMemberNotChosenClose} />
      <MessageModal show={isShowingModal_deleteWork} handleClose={handleDeleteWorkClose} heading={`Remove this document from this group?`}
         explain={`Are you sure you want to delete this document from this group?`} isConfirmType={true}
         onClick={handleDeleteWork} />
      <MessageModal show={isShowingModal_deletePeerGroup} handleClose={handleDeletePeerGroupClose} heading={`Delete this peer group?`}
         explain={`Are you sure you want to delete this peer group?`} isConfirmType={true}
         onClick={handleDeletePeerGroupSubmit} />
      <MessageModal show={isShowingModal_noPeerGroupChosen} handleClose={handlePeerGroupNotChosenClose} heading={`No peer group chosen`}
         explain={`Please choose a peer group before requesting to delete a peer group.`} isConfirmType={false}
         onClick={handlePeerGroupNotChosenClose} />
      <OneFJefFooter />
  </div>
  )
}

export default AssignmentDashboardView;
