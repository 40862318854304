import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import styles from './DraftSettingsView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
//import WorkSummary from '../../components/WorkSummary';
import Checkbox from '../../components/Checkbox';
import MessageModal from '../../components/MessageModal';
import InputText from '../../components/InputText';
import classes from 'classnames';
import DateMoment from '../../components/DateMoment';
import Icon from '../../components/Icon';
import Accordion from '../../components/Accordion';

function DraftSettingsView(props) {
  const {
    workSummary, setWorkCurrentSelected, personId, deleteWork, draftSettings, updateChapterDueDate,
    updateChapterComment, updatePersonConfig, personConfig
  } = props;

  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [isShowingModal, setIsShowingModal] = useState(false)

  const handleNameUpdate = (event) => {
    setName(event.target.value)
  }

  const handleDraftChosen = (draftComparisonId, event) => {
    const {toggleDraftSettingChosen, personId} = props;
    toggleDraftSettingChosen(personId, draftComparisonId, event.target.checked)
  }

  const handleDelete = (draftComparisonId, name) => {
    const {deleteDraftSetting, personId} = props;
    setIsShowingModal(false)
    deleteDraftSetting(personId, draftComparisonId);
  }

  const handleDeleteClose = () => setIsShowingModal(false)
  const handleDeleteOpen = () => setIsShowingModal(true)

  const handleSubmit = () => {
    const {addDraftSetting, personId, workId, chapterId, languageId} = props;
    let isValid = true;
    let isFromCurrent = true;
    let isFromDataRecovery = false;

    if (!name) {
      setNameError("Please enter a draft name")
      isValid = false;
    }

    if (isValid) {
      addDraftSetting(personId, workId, chapterId, languageId, name, isFromCurrent, isFromDataRecovery);
      setNameError("")
      setName("")
    }
  }

  return (
    <div className={styles.container}>
      <div className={globalStyles.pageTitle}>
        {'Draft Settings'}
      </div>
      <Accordion title={workSummary.title}>
        {/*isCurrentTitle={workSummary.isCurrentWork} expanded={false} className={styles.accordionTitle} onTitleClick={setWorkCurrentSelected}*/}
        {/*      showAssignWorkToEditor={false} personId={personId} workSummary={workSummary} setWorkCurrentSelected={setWorkCurrentSelected}*/}
        {/*      deleteWork={deleteWork} updatePersonConfig={updatePersonConfig} personConfig={personConfig}>*/}
        {/* <WorkSummary summary={workSummary} className={styles.workSummary} personId={personId} isHeaderDisplay={false}
                     showTitle={false}
                     setWorkCurrentSelected={setWorkCurrentSelected} deleteWork={deleteWork}
                     updateChapterDueDate={updateChapterDueDate} updateChapterComment={updateChapterComment}
                     updatePersonConfig={updatePersonConfig} personConfig={personConfig}/> */}
      </Accordion>
      <hr/>
      <Link to={`/editReview/isdraft`} className={styles.viewDrafts}>
        View Draft Comparisons
      </Link>
      <hr/>
      {draftSettings && draftSettings.length > 0 &&
        draftSettings.map((m, i) => {
          return (
            <div key={i}>
              <div className={styles.draftRow}>
                {!m.isCurrent &&
                  <a className={styles.linkStyle} onClick={handleDeleteOpen}>
                    <Icon pathName={`garbage_bin`} className={styles.delete}/>
                  </a>
                }
                <MessageModal show={isShowingModal} key={i} handleClose={handleDeleteClose}
                              heading={`Delete this Draft?`}
                              explain={`Are you sure you want to delete this draft, ` + m.name + `?`}
                              isConfirmType={true}
                              onClick={() => handleDelete(m.draftComparisonId, m.name)}/>
                <Checkbox
                  id={m.draftComparisonId}
                  label={m.name}
                  labelClass={styles.checkboxLabel}
                  checked={m.isChosen}
                  onClick={(event) => handleDraftChosen(m.draftComparisonId, event)}
                  onChange={(event) => handleDraftChosen(m.draftComparisonId, event)}
                  checkboxClass={classes(styles.checkbox, (m.isCurrent ? styles.noDeleteImage : ''))}/>
              </div>
              <DateMoment date={m.draftDate} className={styles.dateFormat}/>
            </div>
          )
        })
      }
      <div>
        <InputText
          label={`Draft Name`}
          labelClass={styles.inputText}
          value={name}
          size={"medium"}
          name={"searchText"}
          placeholder={""}
          onChange={handleNameUpdate}/>
        <div className={styles.errorName}>{nameError}</div>
      </div>
      <div className={styles.buttonDiv}>
        <a onClick={handleSubmit} className={styles.buttonStyle}>{`Save`}</a>
      </div>
    </div>
  );
}

export default DraftSettingsView