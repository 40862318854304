import React from 'react'
import { useMediaQuery } from 'react-responsive';
import LoginDesktopView from './LoginDesktopView'
import LoginMobileView from './LoginMobileView'
import styles from './LoginView.module.css'

function LoginView(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 927px)' })

  return (
    <div>
      <LoginDesktopView {...props} />
      {/* {isMobile
        ? <LoginMobileView {...props} />
        : <LoginDesktopView {...props} />
      } */}
      <div className={styles.policies}>
        Our policies: 
        <a href="/privacy" target="parent" className={styles.dataLinks}>Privacy</a> |
        <a href="/service" target="parent" className={styles.dataLinks}>Service</a> |
        <a href="/dataDeletion" target="parent" className={styles.dataLinks}>Data Deletion</a>
      </div>
    </div>
  )
}

export default LoginView;
