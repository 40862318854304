import React from 'react';
import styles from './ToggleSwitch.module.css';

function ToggleSwitch(props) {
  const {value, setValue, onImage, offImage} = props;

  return (
    <div className={styles.container}>
      {setValue &&
        <div className={styles.rowToggle} onClick={() => setValue(!value)}>
          <img src={value ? onImage : offImage} alt={'toggle'} className={styles.toggle}/>
        </div>
      }
    </div>
  )
}

export default ToggleSwitch;
