import * as types from './actionTypes.js';
import * as loggedIn from './logged-in';
import { apiHost } from '../api_host.js';

export function loginError(error) {
	return { error, type: types.LOGGED_FAILED };
}

export function setLoggedSuccessfully(person) {
	return dispatch => dispatch({ type: types.LOGGED_SUCCESSFULLY, payload: person })
}

export function setLoggedIn(isLoggedIn) {
	return dispatch => dispatch({ type: types.LOGGED_IN_SET, payload: isLoggedIn })
}

export function logout() {
	return { type: types.LOGGED_OUT };
}

export function loginRequest(email, password) {
	const user = { email: email, password: password };
	return { user, type: types.LOGIN_ATTEMPT };
}

export function forgotPassword(emailAddress, phone) {
	emailAddress = emailAddress ? emailAddress : 'empty';
	phone = phone ? phone : 'empty';

	return dispatch =>
		fetch(`${apiHost}ebi/person/penspring/password/reset/${emailAddress}/${phone}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
}

export function forgotUsername(emailAddress, phone) {
	emailAddress = emailAddress ? emailAddress : 'empty';
	phone = phone ? phone : 'empty';

	return dispatch =>
		fetch(`${apiHost}ebi/person/penspring/username/reminder/${emailAddress}/${phone}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
}


export function setResetPasswordResponse(password, runFunction) {
	return dispatch =>
		fetch(`${apiHost}ebi/person/resetPassword/${password}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					throw new Error('Invalid Login');
				}
			})
			.then(response => {
				dispatch({ type: types.PASSWORD_RESET_COMPLETE, payload: response });
				if (response === 'Ok') runFunction()
			})
}

export function login(userData, inviteResponse, salta, sendTo = '/directNextByWork', authLogin) { //Only sendTo if this is a salta function to get to a specific document as a student or teacher.
	return dispatch =>
		fetch(`${apiHost}ebi/person/login/penspring`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify(userData),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json();
				} else {
					throw new Error('Invalid Login');
				}
			})
			.then(response => {
				if (response.loginPerson.fullName === "MATCHING EMAIL ADDRESS FOUND") {
					dispatch({ type: types.LOGIN_MATCHING_RECORD, payload: "MATCHING EMAIL ADDRESS FOUND" });
				} else if (response.loginPerson.fullName === "NOTVALID") {
					dispatch({ type: types.LOGGED_FAILED });
				} else if (salta === 'salta') {
					localStorage.setItem("authToken", JSON.stringify(response.token).replace(/"/g, ''))
					window.location = sendTo ? sendTo : '/myWorks';
				} else {
					authLogin && authLogin(response.loginPerson);
					localStorage.setItem("authToken", JSON.stringify(response.token).replace(/"/g, ''))
					localStorage.setItem("person", JSON.stringify(response.loginPerson))
					dispatch(initRecords(response.loginPerson, sendTo, inviteResponse));
				}
			}
		)
		.catch(() => {
			dispatch({ type: types.LOGGED_FAILED });
		})
}

export function initRecords(loginPerson, sendTo = '', inviteResponse = '') {
	return dispatch => {
		let personId = loginPerson.personId;
		dispatch({ type: types.LOGGED_SUCCESSFULLY, payload: loginPerson });
		dispatch(loggedIn.setLoggedIn(true));
		if (sendTo) window.location = sendTo;
	}
}
