import React, { useState, useEffect, useRef } from 'react';
import styles from './SentenceEdits.module.css';
import * as editorService from '../../services/editor-dom'
import * as editListStructure from "../../services/edit-list-structure";
import MessageModal from '../MessageModal';
import OriginalTextAndComment from '../OriginalTextAndComment';
import CommentAndTranslationSaveInfo from '../CommentAndTranslationSaveInfo';
import EditHistorySegment from '../EditHistorySegment';
import EditorNameDateSentence from '../EditorNameDateSentence';
import EditActionOptions from '../EditActionOptions';
import WorkNameDisplay from '../WorkNameDisplay';
import Icon from '../Icon';
import {doSort} from '../../utils/sort.js';
import backgroundColors from '../../utils/backgroundColors.js'
import { useMediaQuery } from 'react-responsive';
import JoinLinkGroup from '../../components/JoinLinkGroup'
import { createConfirmToastAuto } from '../../services/queryClient.js'

let previousCurrentElement
let isFirstElement = true
let deleteEditSegmentId = ''

function SentenceEdits(props) {
  const {
    addOrUpdateEdit,
    addOrUpdateSegments,
    currentElement,
    editLanguageId,
    edits = [],
    editSegmentHistory,
    getWorkEditReviewFilled,
    handleSetCurrentElement,
    isAuthor,
    isDraftReview,
    isMobile,
    isOpenSlideOut,
    isTranslation,
    localTranslation,
    personConfig,
    personId,
    responseEdit,
    segments,
    //setChangeCounts,
    setLocalTranslation,
    setSaveWorkSpaceTime,
    setShowEditorFullText,
    tabsData,
    workSummary,
  } = props;

  let originalSentenceText = ''
  const segment = currentElement && segments && segments.length > 0 && segments.filter(m => Number(m.elementId) === Number(currentElement.id) && m.type === 'TEXT')[0]
  const existTranslation = currentElement && edits?.length > 0 && edits.filter(m => m.personId === personId && m.elementId === Number(currentElement.id) && m.type === 'TEXT')[0]
  const [currentChapterId, setCurrentChapterId] = useState()
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] = useState(false)
  const [isShowAcceptConfirm, setIsShowAcceptConfirm] = useState(false)
  const [isShowTranslationBlankError, setIsShowTranslationBlankError] = useState(false)
  const [isShowCommentMessage, setIsShowCommentMessage] = useState(false)
  const [isShowingLegend, setIsShowingLegend] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(0)
  const [keepHistoryOn, setKeepHistoryOn] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [keepAutoNextOn, setKeepAutoNextOn] = useState(false)
  const [keepEditDifferenceOn, setKeepEditDifferenceOn] = useState(false)
  const [editsSorted, setEditsSorted] = useState([])
  const [comment, setComment] = useState('')
  const [translation, setTranslation] = useState()
  const [isShowAllEditsInSeries, setIsShowAllEditsInSeries] = useState(true)

  const containerRef = useRef(null);

  useEffect(() => {
    //Get the chapterId from the elementId
    //1. Get the uniqueChapterIds from the segment records
    //2. Get the first four digits of the element Id (I know that the original prefix is five characters, but we are allowing growth of the elementId numbers to potentially increase that fifth digit)
    //3. Compare the first four digits of the uniqueChapterIds to the elementId prefix to find the chapterId.
    //4. Set the currentChapterId
    let uniqueChapterIds = segments?.length > 0 ? [...new Set(segments.map(m => m.chapterId))] : []
    const prefixCurrentElement = currentElement?.length > 0 ? currentElement.id.substring(0, 4) : ''
    if (prefixCurrentElement) {
      uniqueChapterIds?.length > 0 && uniqueChapterIds.forEach(chapterId => {
        if (editorService.getPrefixChapterId(chapterId, false, 4) === Number(prefixCurrentElement)) 
          setCurrentChapterId(chapterId)
      })
    }
  }, [currentElement])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!isFirstElement && !!containerRef.current && !containerRef.current.contains(event.target)) {
        props.setIsOpenSlideOut(false)
      }
      isFirstElement = false
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') { // ESC key
        props.setIsOpenSlideOut(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [props.isOpenSlideOut]);


  useEffect(() => {
    if (personConfig.historySentenceView) {
      setKeepHistoryOn(true);
    }
    if (personConfig.nextSentenceAuto) {
      setKeepAutoNextOn(true);
    }
    if (personConfig.editDifferenceView) {
      setKeepEditDifferenceOn(true);
    }
  }, [personConfig]);

  useEffect(() => {
    //if (isMobile && !isOpenSlideOut && currentElement && currentElement.nodeName === 'SPAN') return
    if (currentElement) { //!isEditsInit && edits?.length > 0 &&
      let currentElementId = currentElement.id
        ? currentElement.id.replace('~tabView', '')
        : currentElement.dataset && currentElement.dataset.spanId
          ? currentElement.dataset.spanId.replace('~tabView', '')
          : ''
      let sorted
      if (currentElement?.dataset && (currentElement.dataset.type === 'MOVE' || currentElement.dataset.type === 'TEXT') && currentElement.dataset.editInSeries) {
        sorted = edits?.length > 0 && edits.filter(m => m.editInSeries === Number(currentElement.dataset.editInSeries))
      } else {
        sorted = edits?.length > 0 && edits.filter(m => (Number(m.elementId) === Number(currentElementId) || Number(m.elementId) === Number(currentElement.dataset.spanId))
          && (m.type === currentElement.dataset.type || (!m.type && !currentElement.dataset.type))
          && !m.changeStylePartialSpan) //don't include the changeStylePartialSpan because there can be a start, middle and end where the start and ends can be partial. We just want the middle which will have all of the spans involved or it might be marked as changeStyleEntireDoc
      }
      if (sorted && sorted.length > 0) {
        //if (sorted[0].type === 'ADDPARAGRAPHSENTENCE') {
          if (sorted[0].editInSeries) {
            sorted = edits?.filter(m => Number(m.editInSeries) === Number(sorted[0].editInSeries))
            setEditsSorted(sorted)
          } else {
            setEditsSorted(doSort(sorted, { sortField: 'subSequence', isAsc: true, isNumber: true}))
          }
        // } else if (sorted[0].type === 'ADDLISTITEM') {
        //     setEditsSorted(doSort(sorted, { sortField: 'addListItemSequence', isAsc: true, isNumber: true }))
        // } else if (sorted[0].type === 'DELETESENTENCE' && isShowAllEditsInSeries) {
        //   sorted = edits?.filter(m => Number(m.editInSeries) === Number(sorted[0].editInSeries))          
        //   setEditsSorted(sorted)
        // } else {
        //   setEditsSorted(doSort(sorted, { sortField: 'firstName', isAsc: true, isNumber: false}))
        // }
      } else {
        setEditsSorted([])
      }
    }
  }, [edits, currentElement, isShowAllEditsInSeries])

  useEffect(() => {
    //if (isMobile && !isOpenSlideOut && currentElement && currentElement.nodeName === 'SPAN') return
    if (isTranslation && currentElement) {
      setTranslation('')
      if (!existTranslation) {
        const savedCursorPosition = editorService.saveCursorLocation(document.getElementById('editorDiv'))
        props.getTranslation(personId, workSummary.workId, isTranslation.languageId, currentElement.id, currentChapterId, currentElement.innerHTML.replace('&nbsp;', ''))
        setTimeout(() => editorService.restoreCursorLocation(document.getElementById('editorDiv'), savedCursorPosition), 300)
      } else {
        setLocalTranslation('')
        setTranslation(currentElement.innerHTML)
      }
      currentElement.style.backgroundColor = backgroundColors.currentFocus
      let nextSpanTabView = document.querySelector(`span[id="${currentElement.id}~tabView"][data-type="TEXT"]`)
      if (nextSpanTabView) nextSpanTabView.style.backgroundColor = backgroundColors.currentFocus
    }
  }, [currentElement])

  useEffect(() => {
    if (personConfig.moveTranslationToEditor && !existTranslation) setTranslation(localTranslation)
  },[localTranslation])

  useEffect(() => {
    //if (isMobile && !isOpenSlideOut && currentElement && currentElement.nodeName === 'SPAN') return
    if (currentElement && (!previousCurrentElement || !(Number(previousCurrentElement.id) === Number(currentElement.id) && previousCurrentElement.dataset && currentElement.dataset && previousCurrentElement.dataset.type === currentElement.dataset.type))) {
      previousCurrentElement = currentElement
      // let hasUserEdit = currentElement && edits?.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElement.id) && m.personId === personId && m.type === currentElement.dataset.type)[0];
      // newEditText = hasUserEdit ? hasUserEdit.text : (isTranslation ? '' : currentElement && currentElement.innerHTML);
      // //setComment('')  //This is calling a setState in a useEffect
      let currentElementId
      if (currentElement && currentElement.dataset && (currentElement.dataset.type === 'MOVE' || currentElement.dataset.type === 'CHANGESTYLE')) {
        let editSegment = edits?.length > 0 && edits.filter(m => m.editSegmentId === Number(currentElement.dataset.editSegmentId))[0]
        if (editSegment) currentElementId = editSegment.elementId
      } else {
        currentElementId = currentElement.id
      }
      let edit = edits?.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElementId) && m.type === currentElement.dataset.type)[0]
      setComment(edit && edit.comment)
    }
  }, [segments, edits, currentElement])

  useEffect(() => {
    setTimeout(setTabViewSentenceHighlight, 10)
  }, [editSegmentHistory])

  const setTabViewSentenceHighlight = () => {
    if (currentElement) {
      let elementTabView = document.querySelector(`span[id="${currentElement.id + '~tabView'}"][data-type="TEXT"]`)
      if (elementTabView) elementTabView.style.backgroundColor = backgroundColors.currentFocus
    }
  }

  const retranslate = () => {
    props.getTranslation(personId, workSummary.workId, isTranslation.languageId, currentElement.id, currentChapterId, currentElement.innerHTML.replace('&nbsp;',''))
  } 

  const handleAcceptTranslation = () => {
    setTranslation(localTranslation)
  }

  const sendResponseEdit = (edit, responseType, listItemElementIds, addListItemNextParentElementId, runFunction) => {
    if (currentElement.dataset.type === 'MOVE') {
      props.unhighlightMoveIcons(edit)
    } else if (currentElement.dataset.type === 'CHANGESTYLE') {
        props.unhighlightChangeStyleIcons(edit)
    } else if (currentElement.dataset.type === 'ADDPARAGRAPH') {
      props.unhighlightAddParagraphIcons(edit)
    } else if (currentElement.dataset.type === 'DELETEPARAGRAPH') {
      props.unhighlightDeleteParagraphIcons(currentElement.dataset.spanId)
    }
    if (responseType === 'RejectEdit' || responseType === 'DeleteEdit') {
      if (edit && currentElement.dataset.type === 'MOVE') editorService.removeMoveIcons(edit) //I added currentElement.dataset.type === 'MOVE' since just removing Move Icons for any type of edit didn't make sense unless there is some problem with orphan move icons around.
      if (edit && currentElement.dataset.type === 'CHANGESTYLE') editorService.removeChangeStyleIcons(edit) //I added currentElement.dataset.type === 'CHANGESTYLE' since just removing ChangeStyle Icons for any type of edit didn't make sense unless there is some problem with orphan changeStyle icons around.
    }   
    runFunction = runFunction ? runFunction : () => {
      setTimeout(() => props.getWorkEditReviewFilled(), 500)
    }
    responseEdit(edit, responseType, "", listItemElementIds, runFunction, addListItemNextParentElementId)
  }

  const getEditHistoryCount = () => {
    let segment = currentElement && segments && segments.length > 0 && segments.filter(m => Number(m.elementId) === Number(currentElement.id))[0]
    return segment && segment.editHistoryCount ? segment.editHistoryCount : 0
  }

  const handleAcceptEdit = (editSegment) => {
    if (editSegment.type === 'DELETEPARAGRAPH') {
      let nextElement = currentElement.nextElementSibling ? currentElement.nextElementSibling : currentElement.previousElementSibling ? currentElement.previousElementSibling : currentElement.parentElement
      handleSetCurrentElement(nextElement, currentChapterId)
      props.unhighlightDeleteParagraphIcons(editSegment.elementId)
      editorService.authorAcceptDeleteParagraph(currentElement)
      let saveSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, props.editLanguageId)
      addOrUpdateSegments(personId, saveSegments)
      const runFunction = () => {
        setTimeout(() => getWorkEditReviewFilled(), 500)
      }
      sendResponseEdit(editSegment, 'AcceptEdit', null, null, runFunction)
      setSaveWorkSpaceTime(new Date())
      //setChangeCounts(0)

    } else if (editSegment.type === 'ADDPARAGRAPH') {
      editorService.authorAcceptAddParagraph(currentElement, currentChapterId)
      let saveSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, props.editLanguageId)
      addOrUpdateSegments(personId, saveSegments)
      const runFunction = () => {
        setTimeout(() => getWorkEditReviewFilled(), 500)
      }
      sendResponseEdit(editSegment, 'AcceptEdit', null, null, runFunction)
      setSaveWorkSpaceTime(new Date())
      //setChangeCounts(0)

    } else if (editSegment.type === 'ADDPARAGRAPHSENTENCE') {
      //The new segments are created directly into the database in this case. So we aren't going to try to save them to the interface and then do a persistent DB update.
      sendResponseEdit(editSegment, 'AcceptEdit')
      setSaveWorkSpaceTime(new Date())
      //setChangeCounts(0)

    } else if (editSegment.type === 'ADDSENTENCE') {
      let newSpan = editorService.authorAcceptAddSentence(currentElement, editSegment)
      handleSetCurrentElement(newSpan, currentChapterId)
      let saveSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, editLanguageId)
      addOrUpdateSegments(personId, saveSegments)
      const runFunction = () => {
        setTimeout(() => getWorkEditReviewFilled(), 500)
      }
      sendResponseEdit(editSegment, 'AcceptEdit', null, null, runFunction)
      setSaveWorkSpaceTime(new Date())
      //setChangeCounts(0)

    } else if (editSegment.type === 'DELETESENTENCE') {
      let nextElement = currentElement.nextElementSibling ? currentElement.nextElementSibling : currentElement.previousElementSibling ? currentElement.previousElementSibling : currentElement.parentElement
      handleSetCurrentElement(nextElement, currentChapterId)
      editorService.authorAcceptDeleteSentence(currentElement)
      let saveSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, editLanguageId)
      addOrUpdateSegments(personId, saveSegments)
      const runFunction = () => {
        setTimeout(() => getWorkEditReviewFilled(), 500)
      }
      sendResponseEdit(editSegment, 'AcceptEdit', null, null, runFunction)
      setSaveWorkSpaceTime(new Date())
      //setChangeCounts(0)

    } else if (editSegment.type === 'ADDLISTITEM') {
      //The new segments are created directly into the database in this case. So we aren't going to try to save them to the interface and then do a persistent DB update. That is too complex and potentially dirty.
      const anchorElement = document.querySelector(`span[id="${editSegment.elementId}"][data-type="TEXT"]`)
      const addListItemNextParentElementId = editorService.getAddListItemNextParentElement(anchorElement)
      sendResponseEdit(editSegment, 'AcceptEdit', null, addListItemNextParentElementId)
      setSaveWorkSpaceTime(new Date())
      //setChangeCounts(0)

    } else if (editSegment.type === 'CHANGELIST') {
      const parentListElement = document.querySelector(`ol[id="${editSegment.elementId}"], ul[id="${editSegment.elementId}"]`)
      editorService.changeListType(editSegment.listType, parentListElement, personId, props.editorName, currentChapterId, editLanguageId, true) //isAuthor = true
      let saveSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, editLanguageId)
      addOrUpdateSegments(personId, saveSegments)
      const runFunction = () => {
        setTimeout(() => getWorkEditReviewFilled(), 500)
      }
      sendResponseEdit(editSegment, 'AcceptEdit', null, null, runFunction)
      setSaveWorkSpaceTime(new Date())
      //setChangeCounts(0)

    } else if (editSegment.type === 'DELETELISTITEM') {
      let nextElement = currentElement.nextElementSibling ? currentElement.nextElementSibling : currentElement.previousElementSibling ? currentElement.previousElementSibling : currentElement.parentElement
      handleSetCurrentElement(nextElement.firstChild, currentChapterId)
      props.unhighlightDeleteListIcons(editSegment.elementId)
      const listItemElementIds = editorService.authorAcceptDeleteListItem(currentElement, props.chapterListLevels)
      let saveSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, editLanguageId)
      addOrUpdateSegments(personId, saveSegments)
      const runFunction = () => setTimeout(() => getWorkEditReviewFilled(), 500)
      sendResponseEdit(editSegment, 'AcceptEdit', listItemElementIds, null, runFunction)
      setSaveWorkSpaceTime(new Date())
      //setChangeCounts(0)

    } else if (editSegment.type === 'REORDERLISTITEMS') {
      editorService.removeReorderListIcons(editSegment)
      let listItem = document.getElementById(editSegment.elementId)
      if (listItem) {
        editorService.setAuthorReorderListItems(listItem, editSegment)
        let saveSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, editLanguageId)
        addOrUpdateSegments(personId, saveSegments)
        const runFunction = () => setTimeout(() => getWorkEditReviewFilled(), 500)
        sendResponseEdit(editSegment, 'AcceptEdit', null, null, runFunction)
        setSaveWorkSpaceTime(new Date()) 
        //setChangeCounts(0)
      }

    } else if (editSegment.type === 'MOVE') {
      let newSegments = editorService.moveSentencesInSegments([...segments], editSegment)
      addOrUpdateSegments(personId, newSegments)
      const runFunction = () => setTimeout(() => getWorkEditReviewFilled(), 500)
      sendResponseEdit(editSegment, 'AcceptEdit', null, null, runFunction)
      setSaveWorkSpaceTime(new Date())
      //setChangeCounts(0)

    } else if (editSegment.type === 'CHANGESTYLE') {
      let tempSegments
      const prefixChapterId = editorService.getPrefixChapterId(currentChapterId)
      if (editSegment.changeStyleEntireDoc) {
        editorService.updateChapterWithTextStyle_DOM(editSegment.textStyleChangeEntries, `editorDiv${prefixChapterId}`)
        tempSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, editLanguageId)
      } else {
        const doNotCreateIcons = true //This is an approval so we want the text style changes but not the icons. 
        const isAuthorUser = true //Because we are accepting the edits and want it to be reflected on the screen.
        //The tabsData and chosenTab are not available here since that is now located in AuthoringEditor and not as high as EditReviewView
        editorService.showChangeStyleDisplayAndIcons(`editorDiv${prefixChapterId}`, edits, tabsData, personId, props.globalChosenTab, doNotCreateIcons, props.showEditIcons, currentChapterId)
        tempSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, editLanguageId)
      }
      addOrUpdateSegments(personId, tempSegments)
      const runFunction = () => setTimeout(() => getWorkEditReviewFilled(), 500)
      sendResponseEdit(editSegment, 'AcceptEdit', null, null, runFunction)
      setSaveWorkSpaceTime(new Date())
      //setChangeCounts(0)

    } else if (editSegment.type === 'ADDTAB') {
      //Adding a ADDTAB, sets the text-indent property to 36pt. Additional ADDTAB edits increment the margin-left which leads to a block indent. This is WORD convention.
      //There may be more than one ADDTAB for a given paragraph. Other ADDTAB edits should not be automatically deleted as "other" edits but "additional" and "incremental" edits.
      let paragraphElement = document.querySelector(`p[id="${editSegment.elementId}"]`)
      editorService.insertAuthorsAcceptedTab(paragraphElement)
      let saveSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, editLanguageId)
      addOrUpdateSegments(personId, saveSegments)
      const runFunction = () => setTimeout(() => getWorkEditReviewFilled(), 500)
      sendResponseEdit(editSegment, 'AcceptEdit', null, null, runFunction)
      setSaveWorkSpaceTime(new Date())
      //setChangeCounts(0)

    } else if (editSegment.type === 'BLOCKLEFT' || editSegment.type === 'BLOCKRIGHT' || editSegment.type === 'TEXTALIGN' || editSegment.type === 'TEXTINDENT') {
      editorService.acceptParagraphSingleStyles(editSegment)
      let saveSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, editLanguageId)
      addOrUpdateSegments(personId, saveSegments)
      const runFunction = () => setTimeout(() => getWorkEditReviewFilled(), 500)
      sendResponseEdit(editSegment, 'AcceptEdit', null, null, runFunction)
      setSaveWorkSpaceTime(new Date())
      //setChangeCounts(0)

    } else if (editSegment.type === 'DELETETAB') {
      let spanElement = document.querySelectorAll(`[id="${editSegment.elementId}"][data-type="TEXT"]`)[0]
      handleSetCurrentElement(spanElement, currentChapterId)
      //spanElement.remove()
      sendResponseEdit(editSegment, 'AcceptEdit')
      //props.saveEditorDivSegmentsPersistent()

    } else if (editSegment.type === 'LISTLEVELMINUS' || editSegment.type === 'LISTLEVELPLUS') {
      let element = document.querySelector(`span[id="${editSegment.elementId}"][data-type="TEXT"]`)
      handleSetCurrentElement(element, currentChapterId)
      let elementListItem = element.parentElement
      editListStructure.setListLevel({
        currentElement: element,
        elementListItem,
        direction: editSegment.type === 'LISTLEVELMINUS' ? 'MINUS' : 'PLUS',
        chapterId: currentChapterId,
        edits,
        chapterListLevels: props.chapterListLevels,
        listLevelGeneral: props.listLevelGeneral,
        addChapterListLevels: props.addChapterListLevels,
      })
      setTimeout(() => {
        let saveSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, editLanguageId)
        addOrUpdateSegments(personId, saveSegments)
        const runFunction = () => setTimeout(() => getWorkEditReviewFilled(), 500)
        sendResponseEdit(editSegment, 'AcceptEdit', null, null, runFunction)
        setSaveWorkSpaceTime(new Date())
        //setChangeCounts(0)
      }, 1000)


    } else {
      if (!!editSegment.text) { //This could be accepting a comment-only edit - so let's not blank out the text
        handleSetCurrentElement(currentElement, currentChapterId)
        currentElement.innerHTML = editSegment.text
      }
      if (isAuthor) {
        currentElement.style.backgroundColor = 'white'
      }

      //Set the editor full text view, too, if it is the author or the tabview is the same as the editor
      //This could be accepting a comment-only edit - so let's not blank out the text
      if ((isAuthor || props.globalChosenTab === editSegment.personId) && !!editSegment.text) { 
        let editorElement = document.querySelectorAll(`[id="${editSegment.elementId + '~tabView'}"][data-type="TEXT"]`)[0]
        if (editorElement) editorElement.innerHTML = editSegment.text
      } 

      if (isAuthor) {
        sendResponseEdit(editSegment, 'AcceptEdit')
      } else {
        //This must be the editor accepting or copying someone else's edit in order to refine it further.
        addOrUpdateEdit({
          personId: personId,
          firstName: props.editorName && props.editorName.firstName,
          lastName: props.editorName && props.editorName.lastName,
          chapterId: currentChapterId,
          languageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current,
          elementId: currentElement.id,
          editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
          type: editSegment.type,
          text: editSegment.text,
          authorTextSnapshot: editSegment.authorTextSnapshot,
          comment: editSegment.comment,
        })
      }
    }
    //No, don't do this sendResponseEdit since it ends up deleting the edit along the way so that the accept of the edit can collide with the null record that was deleted.
    //sendResponseEdit(editSegment, 'VoteUp') //There is a VoteUp for the author as well in addition to the AcceptEdit
  }

  const handleSaveTranslation = () => {
    const {personId, isTranslation} = props;
    if (isTranslation && !translation) {
      handleBlankOpen();
    } else {
      if (isAuthor) {
        if (segment) {
          let segment = segments && segments.length > 0 && segments.filter(m => m.elementId === Number(currentElement.id) && m.type === currentElement.dataset.type)[0]
          let edit = edits?.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElement.id) && m.type === currentElement.dataset.type)[0]
          if (segment) {
            props.addOrUpdateCommentOrSentence(personId, comment, workSummary.workId, segment.chapterId, segment.elementId, editLanguageId, isAuthor, (edit && edit.editSegmentId) || 0, 'TEXT', translation)
          }
          const addSpace = translation && translation.lastIndexOf('&nbsp;') === translation.length - 6 ? '' : '&nbsp;'
          currentElement.innerHTML = translation + addSpace
        }
      } else {
        let existEdit = edits?.length > 0 && edits.filter(m => m.elementId === Number(currentElement.id) && m.personId === props.personId && m.type === currentElement.dataset.type)[0]
        addOrUpdateEdit({
          isAuthor,
          personId: personId,
          chapterId: currentChapterId,
          elementId: currentElement.id,
          languageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current,
          editLanguageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current,
          editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
          firstName: props.editorName && props.editorName.firstName,
          lastName: props.editorName && props.editorName.lastName,
          type: 'TEXT',
          text: translation,
          authorTextSnapshot: segment.text,
          comment: comment ? comment : existEdit && existEdit.comment ? comment : '',
        })
        const addSpace = translation && translation.lastIndexOf('&nbsp;') === translation.length - 6 ? '' : '&nbsp;'
        currentElement.innerHTML = translation + addSpace
      }

      if (isTranslation && personConfig.goToNextSentence) {
        let nextSpan = editorService.useTabToNextSentence(currentElement, props.chapterListLevels, currentChapterId)
        if (nextSpan) {
          handleSetCurrentElement(nextSpan, currentChapterId)
          nextSpan.style.backgroundColor = backgroundColors.currentFocus
          let nextSpanTabView = document.querySelector(`span[id="${nextSpan.id}~tabView"][data-type='TEXT']`)
          if (nextSpanTabView) nextSpanTabView.style.backgroundColor = backgroundColors.currentFocus
        }
      }
      setTranslation('')
      setIsShowCommentMessage(false)
    }
  }

  const handleAllDeletes = () => {
    setIsShowDeleteConfirm(false)
    if (currentElement.dataset.type === 'ADDPARAGRAPHSENTENCE' && editsSorted && editsSorted.length > 0) {
      let firstEdit = editsSorted[0]
      const runFunction = () => setTimeout(() => getWorkEditReviewFilled(), 500)
      props.deleteAllAddParagraphSentence(editsSorted, runFunction)

    } else if (currentElement.dataset.type === 'DELETESENTENCE' && editsSorted && editsSorted.length > 0) {
      editsSorted?.forEach(m => {
        responseEdit(m, isAuthor ? 'RejectEdit' : 'DeleteEdit')
      });
      setTimeout(() => getWorkEditReviewFilled(), 500)
      setIsShowAllEditsInSeries(false)
    } else {
      editsSorted?.forEach(m => {
        sendResponseEdit(m, isAuthor ? 'RejectEdit' : 'DeleteEdit')
      });
    }
  }

  const handleAllAccept = () => {
  setIsShowAcceptConfirm(false)
    if (editsSorted && editsSorted.length > 0) {
      let firstEdit = editsSorted[0]
      const runFunction = () => setTimeout(() => getWorkEditReviewFilled(), 500)
      if (editsSorted[0].type === 'ADDPARAGRAPHSENTENCE') {
        props.acceptAllAddParagraphSentence(editsSorted, runFunction)
      } else if (editsSorted[0].type === 'ADDLISTITEM') {
        const anchorElement = document.querySelector(`span[id="${editsSorted[0].elementId}"][data-type="TEXT"]`)
        const addListItemNextParentElementId = editorService.getAddListItemNextParentElement(anchorElement)
        props.acceptAllAddListItem(editsSorted, addListItemNextParentElementId, runFunction)
      } else if (editsSorted[0].type === 'DELETESENTENCE') {
        editsSorted?.forEach(m => responseEdit(m, 'AcceptEdit'));
        runFunction()
        setIsShowAllEditsInSeries(false)
      } else if (editsSorted[0].editInSeries) {  //So far tihs would be an editInSeries for a select/paste move which might include partial sentences chosen at the begiining and end, and potentially a paste in the middle of a target sentence.
        //1. Process all of the edits from the editInIseries except for the MOVE
        //2. Get the tempSegments as they are, so far.
        //3. Process the MOVE edit part of the editInSeries while sending the tempSegments and receiving back the adjusted tempSegments
        //4. Save the completed tempSegments to the database
        //5. Recall the workSegments and the editSegments from the database again.

        //1. Process all of the edits from the editInIseries except for the MOVE
        editsSorted.filter(edit => edit.type !== 'MOVE').forEach(edit => {
          const element = document.querySelector(`span[id="${edit.elementId}"][data-type]`)
          if (element) {
            element.innerHTML = edit.text
            element.style.backgroundColor = 'white'
          }
          sendResponseEdit(edit, 'AcceptEdit')
        })
        //2. Get the tempSegments as they are, so far.
        let tempSegments = editorService.gatherSegmentsToSave(segments, currentChapterId, editLanguageId)
        //3. Process the MOVE edit part of the editInSeries while sending the tempSegments and receiving back the adjusted tempSegments
        editsSorted.filter(edit => edit.type === 'MOVE').forEach(edit => {
          tempSegments = editorService.moveSentencesInSegments([...tempSegments], edit)
          sendResponseEdit(edit, 'AcceptEdit')
        })
        //4. Save the completed tempSegments to the database
        addOrUpdateSegments(personId, tempSegments)
        setSaveWorkSpaceTime(new Date())
        //setChangeCounts(0)
        //5. Recall the workSegments and the editSegments from the database again.
        setTimeout(() => props.getWorkEditReviewFilled(), 500)
      }
    }
  }

  const handleFullTextView = (event, tabPersonId) => {
    event.stopPropagation()
    event.preventDefault()
    setShowEditorFullText(true)
    props.handleSetGlobalChosenTab(tabPersonId)
    setTimeout(() => {
      const tabViewElement = document.querySelector(`${currentElement.nodeName}[id="${currentElement.id}~tabView"][data-type]`)
      if (tabViewElement) tabViewElement.style.backgroundColor = backgroundColors.currentFocus
    }, 1000)
  }

  const handleBlankClose = () => {
    setIsShowTranslationBlankError(false);
  }
  const handleBlankOpen = () => {
    setIsShowTranslationBlankError(true);
  }
  const handleCommentSave = () => {
    let existEdit = edits?.length > 0 && edits.filter(m => m.elementId === Number(currentElement.id) && m.personId === props.personId && m.type === currentElement.dataset.type)[0]
    if (!existEdit && !comment) return //Don't send a new edit when the comment is blank.
    if (isAuthor) {
      props.addOrUpdateCommentOrSentence(personId, comment, workSummary.workId, currentChapterId, Number(currentElement.id), editLanguageId, isAuthor, (existEdit && existEdit.editSegmentId) || 0, currentElement.dataset.type);
    } else {
      let existSegment = segments && segments.length > 0 && segments.filter(m => m.elementId === Number(currentElement.id))[0]

      //We still need to send the edit although the text doesn't look like it changed because the edit exists already so it needs to be deleted from EditSegment db table because the editor might have changed the edit back.
      addOrUpdateEdit({
        editSegmentId: (existEdit && existEdit.editSegmentId) || 0,
        personId: props.personId,
        chapterId: currentChapterId,
        elementId: Number(currentElement.id),
        languageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        firstName: props.editorName && props.editorName.firstName,
        lastName: props.editorName && props.editorName.lastName,
        type: currentElement.dataset.type,
        text: editorService.isTextEqual(currentElement.innerHTML, existSegment.text) ? '' : currentElement.innerHTML, //This could be a comment only
        authorTextSnapshot: segment.text,
        comment,
      })

    }
    currentElement.style.backgroundColor = backgroundColors.editPending
    setIsShowCommentMessage(false)
    setComment('')
  }

  const handleCommentOpen = () => {
    setIsShowCommentMessage(!isShowCommentMessage)
    if (isAuthor) {
      let segment = segments.filter(m => Number(m.elementId) === Number(currentElement.id))[0]
      if (segment) {
        setComment(segment.comment || '')
      }
    } else {
      let hasUserEdit = currentElement && edits?.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElement.id) && m.personId === personId && m.type === currentElement.dataset.type)[0]
      if (hasUserEdit) setComment(hasUserEdit.comment || '')
    }
  }

  const handleEditIconLegendClose = () => {
    setIsShowingLegend(false);
  }

  const handleEditIconLegendOpen = () => {
    setIsShowingLegend(true)
  }

  const getEditsCount = () => {
    let editCount = currentElement && edits?.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElement.id))
    return editCount ? editCount.length : 0
  }

  const isEditorOwner = () => {
    if (editsSorted?.length > 0) {
      return editsSorted[0].personId === personId
    }
  }

  const getEditorColor = (personId, withoutSymbol) => {
    if (tabsData?.length > 0) {
      let editorColor = (tabsData.filter(m => m.id === personId)[0] && tabsData.filter(m => m.id === personId)[0].editorColor) || '#ff6600'
      if (editorColor && withoutSymbol) editorColor = editorColor.replace('#', '')
      return editorColor
    }
  }

  const getVoteUpCount = (edit) => {
    return edit?.editVotes?.filter(m => m.voteUpFlag) && edit?.editVotes?.filter(m => m.voteUpFlag).length > 0 ? edit?.editVotes?.filter(m => m.voteUpFlag).length : ''
  }

  const getVoteDownCount = (edit) => {
    return edit?.editVotes?.filter(m => m.voteDownFlag) && edit?.editVotes?.filter(m => m.voteDownFlag).length > 0 ? edit?.editVotes?.filter(m => m.voteDownFlag).length : ''
  }

  const getVoteTrollCount = (edit) => {
    return edit?.editVotes?.filter(m => m.voteTrollFlag) && edit?.editVotes?.filter(m => m.voteTrollFlag).length > 0 ? edit?.editVotes?.filter(m => m.voteTrollFlag).length : ''
  }

  const confirmDeleteEdit = (editSegmentId) => {
    deleteEditSegmentId = editSegmentId
    createConfirmToastAuto('<div>Are you sure you want to delete this edit?</div>', handleDeleteEdit)
  }

  const handleDeleteEdit = () => {
    const editSegmentId = deleteEditSegmentId;
    let segment = segments.filter(m => Number(m.elementId) === Number(currentElement.id) && m.type === currentElement.dataset.type)[0]
    let editSegment = edits?.filter(m => m.editSegmentId === editSegmentId)[0]
    if (currentElement.dataset.type === 'DELETEPARAGRAPH') {  //ToDo what about the full text editor view:
      props.unhighlightDeleteParagraphIcons(currentElement.dataset.spanId)
      currentElement.remove()
    } else if (currentElement.dataset.type === 'ADDPARAGRAPH') {  //ToDo what about the full text editor view:
      props.unhighlightAddParagraphIcons(editSegment)
      currentElement.remove()
    } else if (currentElement.dataset.type === 'MOVE') {
      props.unhighlightMoveIcons(editSegment)
      currentElement.remove()
    } else if (currentElement.dataset.type === 'CHANGESTYLE') {
      props.unhighlightChangeStyleIcons(editSegment)
      currentElement.remove()
    } else if (currentElement.dataset.type === 'ADDTAB'
      || currentElement.dataset.type === 'DELETETAB'
      || currentElement.dataset.type === 'LISTLEVELMINUS'
      || currentElement.dataset.type === 'LISTLEVELPLUS') {
      currentElement.remove()
    } else {
      if (segment) {
        currentElement.innerHTML = segment.text
        let otherEdits = edits?.length > 0 && edits.filter(m => m.elementId === currentElement.id && m.personId !== personId)
        if (!otherEdits || otherEdits.length === 0) {
          currentElement.style.backgroundColor = 'white'
        }
      }
    }
    sendResponseEdit(editSegment, 'DeleteEdit')
    props.setIsInitEdits('FORCE')
    deleteEditSegmentId = ''
  }

  const handleSetShowHistory = () => {
    if(currentElement && !showHistory) props.getEditSegmentHistory(personId, currentChapterId, currentElement.id, editLanguageId)
      setShowHistory(!showHistory)
  }

  const cleanDifferentEditText = (differenceEditText) => {
    let regex = "/<(.|\n)*?>/";
    differenceEditText = differenceEditText && differenceEditText.replace(regex, "")
      .replace(/<br>/g, "")
      //.replace(/<[^>]*>/g, ' ')
      .replace(/\s{2,}/g, ' ')
      .replace(/&nbsp;/g, ' ')
      .replace(/&#xa0;/g, ' ')
      .trim()

    return differenceEditText
  }

  const countDeleteSentencesInSeries = (edit) => {
    if (currentElement && edit && edit.editInSeries && edit.editInSeries !== "0") {
      const editsDeleteSentences = edits?.filter(m => edit.type === m.type && Number(m.editInSeries) === Number(edit.editInSeries))
      return editsDeleteSentences?.length
    }
  }

  if (currentElement && currentElement.id) {
    const elementId = currentElement.id.indexOf('tabView') ? currentElement.id.replace('~tabView', '') : currentElement.id
    const segment = segments && segments.length > 0 && segments.filter(m => m.elementId === Number(elementId) && m.type === 'TEXT')[0]
    const text = segment ? segment.text : ''
    if (text) originalSentenceText = editorService.cleanText(text)
  }

  return (
    <div className={!isMobile ? styles.sidePanel : isOpenSlideOut ? styles.slideOutOpen : styles.slideOut} ref={containerRef}>
      <WorkNameDisplay 
        workSummary={workSummary} 
        showLanguageOptions 
        editLanguageId={editLanguageId} 
        personId={personId} 
        isAuthor={isAuthor} 
        chapterId={currentChapterId}
        isMobile={isMobile}
        isTranslation={isTranslation}/>
      {workSummary.originatingEditorPersonId === personId &&
        <JoinLinkGroup 
          personId={personId}
          toggleGroupJoinLink={props.toggleGroupJoinLink} 
          groupId={workSummary.groupId} 
          groupOpenJoinLink={workSummary.groupOpenJoinLink} 
          runFunction={() => props.getWorkEditReview(personId, workSummary.workId)} />
      }
      <div className={styles.topText}>
        {/* {!isDraftReview && !isMobile &&
          <div className={styles.row} onClick={() => setShowInstructions(true)}>
            <Icon pathName={'document0'} premium={true} fillColor={'white'}/>
            <div className={styles.instructions}>instructions</div>
          </div>
        } */}
        <div className={styles.rowCenter}>
          <div>{`edits: ${getEditsCount()}`}</div>
          <span className={styles.editsHistory}
                onClick={() => getEditHistoryCount() ? handleSetShowHistory(!showHistory) : {}}>
            {`history: ${getEditHistoryCount()}  ${showHistory ? '(on)' : '(off)'}`}
          </span>
        </div>
      </div>
      {props.isMobile &&
        <button className={styles.slideOutToggle} onClick={() => props.setIsOpenSlideOut(false)}>
          Close X
        </button>
      }
      <div className={styles.children}>
        {!isDraftReview &&
          <CommentAndTranslationSaveInfo 
            currentElement={currentElement}
            handleCommentOpen={handleCommentOpen}
            isTranslation={isTranslation}
            saveTranslation={handleSaveTranslation}
            translation={translation}
            setTranslation={setTranslation}
            personConfig={personConfig}
            handleEditIconLegendOpen={handleEditIconLegendOpen}
            isShowCommentMessage={isShowCommentMessage}
            setIsShowCommentMessage={setIsShowCommentMessage}
            comment={comment}
            setComment={setComment}
            keepCommentOn={props.personConfig.keepCommentOn}
            segment={segments?.filter(m => Number(m.elementId) === Number(currentElement && currentElement.id))[0]}
            handleCommentSave={handleCommentSave}/>
        }
        <OriginalTextAndComment 
          workSummary={workSummary}
          originalSentenceText={originalSentenceText}
          authorComment={segment && segment.comment}
          flexOriginalTextHeight={props.flexOriginalTextHeight}/>

        {(isAuthor //|| (!isAuthor && isEditorOwner() //There is already an Undo All option for the editor. We don't need Reject all to show up.
            && getEditsCount() > 1 && !isDraftReview
            && currentElement && currentElement.dataset
            && (currentElement.dataset.type === 'ADDPARAGRAPHSENTENCE' 
                || currentElement.dataset.type === 'ADDLIST'
                || currentElement.dataset.type === 'ADDLISTITEM'
                || (currentElement.dataset.type === 'TEXT' && currentElement.dataset.editInSeries)
                || (currentElement.dataset.type === 'MOVE' && currentElement.dataset.editInSeries))) &&
          <div className={styles.row}>
            <a className={styles.deleteAllButton} onClick={() => setIsShowDeleteConfirm(true)}>Reject all</a> 
            {isAuthor && currentElement && currentElement.dataset 
              && (currentElement.dataset.type === 'ADDPARAGRAPHSENTENCE' 
                || currentElement.dataset.type === 'ADDLIST'
                || currentElement.dataset.type === 'ADDLISTITEM' 
                || (currentElement.dataset.type === 'TEXT' && currentElement.dataset.editInSeries)
                || (currentElement.dataset.type === 'MOVE' && currentElement.dataset.editInSeries)) ?
              <div className={styles.row}>
                <div className={styles.divider}>|</div>
                <a className={styles.acceptAllButton} onClick={() => setIsShowAcceptConfirm(true)}>Accept all</a> 
              </div> : ''
            }
          </div>
        }
        {countDeleteSentencesInSeries(editsSorted && editsSorted[0]) && !isShowAllEditsInSeries ? 
          <div className={styles.row}>
            <div className={styles.acceptAllButton}>There are {countDeleteSentencesInSeries(editsSorted && editsSorted[0])} edits sentences in series:</div>
            <div className={styles.linkAction} onClick={() => setIsShowAllEditsInSeries(!isShowAllEditsInSeries)}>
              {isShowAllEditsInSeries ? 'Hide others' : 'Show all'}
            </div>
          </div> : ''
        }
        {countDeleteSentencesInSeries(editsSorted && editsSorted[0]) && isShowAllEditsInSeries ?
          <div className={styles.row}>
            <a className={styles.deleteAllButton} onClick={() => setIsShowDeleteConfirm(true)}>
              {isAuthor ? '' : 'Undo all'}
            </a>
            {isAuthor && currentElement.dataset.type === 'DELETESENTENCE' &&
              <div className={styles.row}>
                <div className={styles.divider}>|</div>
                <a className={styles.acceptAllButton} onClick={() => setIsShowAcceptConfirm(true)}>
                  Accept all
                </a>
              </div>
            }
          </div> : ''
        }
        {isTranslation && !isDraftReview && currentElement ?
          <div className={styles.editDisplay}>
            {localTranslation && <span className={styles.translateTitle}>Machine translation (not perfect)</span>}
            <span className={styles.editText} dangerouslySetInnerHTML={{__html: localTranslation}}/>
            <div className={styles.row}>
              {localTranslation &&
                <a onClick={handleAcceptTranslation} title={'Accept translation'}>
                  <Icon pathName={`thumbs_up0`} premium={true} className={styles.choiceIcons}/>
                </a>
              }
              <div className={styles.retranslate} onClick={retranslate}>retranslate</div>
            </div>
            <hr style={{width: '280px'}}/>
          </div> : ''
        }

        <div className={styles.editDetails}>
          {currentElement && editsSorted.map((m, i) => {
              let differenceEditText = cleanDifferentEditText(m.text);
              let editorColor = getEditorColor(m.personId)

              return (
                <div className={styles.editDisplay} key={i}>
                  <EditorNameDateSentence 
                    edit={m}
                    kindexey={`sentence${i}`}
                    edits={edits}
                    segment={segment}
                    segments={segments}
                    editorColor={editorColor}
                    personConfig={personConfig}
                    originalSentenceText={originalSentenceText}
                    differenceEditText={differenceEditText}
                    unhighlightMoveIcons={props.unhighlightMoveIcons}
                    unhighlightChangeStyleIcons={props.unhighlightChangeStyleIcons}
                    personId={m.personId}
                    tabsData={tabsData}
                    currentElement={currentElement}
                    isTranslation={isTranslation}
                    isAuthor={isAuthor} 
                    {...props}/>

                  <EditActionOptions 
                    edit={m}
                    index={`action${i}`}
                    personId={personId}
                    handleAcceptEdit={handleAcceptEdit}
                    isAuthor={isAuthor}
                    sendResponseEdit={sendResponseEdit}
                    getVoteUpCount={getVoteUpCount}
                    getVoteDownCount={getVoteDownCount}
                    getVoteTrollCount={getVoteTrollCount}
                    confirmDeleteEdit={confirmDeleteEdit}
                    handleFullTextView={handleFullTextView}
                    deleteIndex={deleteIndex}
                    isDraftReview={isDraftReview}
                    isTranslation={isTranslation}
                    setTranslation={setTranslation}/>
                </div>
              )
            }
          )}
        </div>
        <EditHistorySegment 
          visible={showHistory}
          personId={personId}
          history={editSegmentHistory}
          isAuthor={isAuthor}
          cleanDifferentEditText={cleanDifferentEditText}
          getEditorColor={getEditorColor}
          originalSentenceText={originalSentenceText}
          editsSorted={editsSorted}
          sendResponseEdit={sendResponseEdit}
          editLanguageId={editLanguageId}
          {...props} />
      </div>
      <MessageModal 
        show={isShowingLegend} 
        key={'showLegend'} 
        handleClose={handleEditIconLegendClose} 
        heading={`Edit Icon Options`} 
        showEditIconLegend={true}
        onClick={handleEditIconLegendClose} 
        isAuthor={isAuthor}/>
      <MessageModal 
        show={isShowDeleteConfirm} 
        key={'deleteAll'} 
        handleClose={() => setIsShowDeleteConfirm(false)} 
        heading={`Reject All Edits and Comments?`}
        explain={`Are you sure you want to reject all of the edits and comments as shown in the list?`}
        isConfirmType={true}
        onClick={handleAllDeletes}/>
      <MessageModal 
        show={isShowAcceptConfirm} 
        key={'acceptAll'} 
        handleClose={() => setIsShowAcceptConfirm(false)} 
        heading={`Accept All Edits and Comments?`}
        explain={`Are you sure you want to accept all of the edits and comments as shown in the list?`}
        isConfirmType={true}
        onClick={handleAllAccept} />
      <MessageModal 
        show={isShowTranslationBlankError} 
        key={'empty'} 
        handleClose={handleBlankClose} 
        heading={`Translation is blank or empty?`}
        explain={`It is not allowed to save a blank translation of a sentence.  Please check your entry and try again"`}
        isConfirmType={false}
        onClick={handleBlankClose}/>
    </div>
  )
}

export default SentenceEdits
