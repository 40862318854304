import React, { useEffect, useState } from 'react';
import styles from './ButtonWithIcon.module.css';
import Icon from '../Icon';
import Checkmark from '../../assets/Checkmark.svg'
import LoadingAlt from '../../assets/busyCursor.gif'
import GoogleDrive from '../../assets/GoogleDriveLogo.png'
import classes from 'classnames'

function ButtonWithIcons(props) {
	const {
		label = "",
		id,
		disabled = false,
		onClick,
		addClassName,
		keyIndex,
		icon,
		isPremiumIcon = true,
		title,
		submitSuccess,
		resetOnError,
		smaller,
	} = props

	const [submitted, setSubmitted] = useState(false)

	useEffect(() => {
		if (submitSuccess) setSubmitted(true)
	}, [submitSuccess])

	useEffect(() => {
		if (resetOnError) setSubmitted(false)
	}, [resetOnError])

	const handleOnClick = (event) => {
		if (event.key === 'Enter' || event.key === ' ') {
			onClick()
		}
	}

	return (
		<div tabIndex={0}
					className={classes(smaller ? styles.smallerButton : styles.button, addClassName, disabled ? styles.lowOpacity : '')}
					id={id} name={id} key={keyIndex}
					onClick={disabled || submitted ? () => { } : onClick}
					onKeyUp={disabled || submitted ? () => { } : (event) => handleOnClick(event)}
					title={title}>
			<div tabIndex={0} className={styles.labelWidth} onKeyUp={disabled || submitted ? () => { } : (event) => handleOnClick(event)}>{label}</div>
			<div tabIndex={0} className={smaller ? styles.smallerFarRight : styles.farRight} onKeyUp={disabled || submitted ? () => { } : (event) => handleOnClick(event)}>
				{submitted
					? <img src={LoadingAlt} alt={''} className={styles.busyGif} />
					: icon === 'checkmark0'
						? <img src={Checkmark} height={smaller ? 15 : 18} className={styles.iconCheckmark} />
						: icon === 'googleDrive'
							? <img src={GoogleDrive} alt={''} className={styles.googleDrive} />
							: submitted
								? <img src={LoadingAlt} alt={''} className={styles.busyGif} />
								: <Icon pathName={icon} premium={isPremiumIcon} height={smaller ? 15 : 18} className={styles.icon} fillColor={'#0f6078'}/>
				}
			</div>
		</div>
	)
};

export default ButtonWithIcons;