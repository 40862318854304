import React, {Component} from 'react';
import GroupMemberUpdateView from '../views/GroupMemberUpdateView';
import { connect } from 'react-redux';
import * as actionGroups from '../actions/groups.js';
import { selectMe, selectGroups, selectGroupIdCurrent } from '../store.js';

// takes values from the redux store and maps them to props
const mapStateToProps = (state, props) => {
    const {memberPersonId} = props.params;
    let group = selectGroups(state) && selectGroups(state).filter(m => m.groupId === selectGroupIdCurrent(state))[0];
    let member = group && group.members && group.members.length > 0 && group.members.filter(m => m.personId === memberPersonId)[0]

    return {
        personId: selectMe(state) && selectMe(state).personId,
        group,
        member,
    }
};

// binds the result of action creators to redux dispatch, wrapped in callable functions
const bindActionsToDispatch = dispatch => ({
    updateGroupMember: (personId, groupId, member) => dispatch(actionGroups.updateGroupMember(personId, groupId, member)),
});

const storeConnector = connect(
    mapStateToProps,
    bindActionsToDispatch,
);

class Container extends Component {
    render() {
        return !!this.props.member ? <GroupMemberUpdateView {...this.props} /> : null;
    }
}

export default storeConnector(Container);
