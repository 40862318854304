import React, {useState, useEffect, useRef} from 'react';
import styles from './Loading.module.css';
import classes from 'classnames';

function Loading(props) {
    const {isLoading, loadingText, className} = props;

    const [error, setError] = useState('');
    const [indicator, setIndicator] = useState('...');
    const [timerId, setTimerId] = useState(0);
    const [styleIndex, setStyleIndex] = useState(0);
    const [styleArray, setStyleArray] = useState([styles.first, styles.second, styles.third, styles.fourth]);
    const [direction, setDirection] = useState('increasing');

    const loadingThing = useRef(null);

    useEffect(() => {
        setTimerId(setInterval(() => showProgress(), 1000));
    }, []);

    useEffect(() => {
        !props.isLoading && clearInterval(timerId);
        return () => clearInterval(timerId);
    }, [props.isLoading]);

    const showProgress = () => {
        let nextIndex = styleIndex;
        nextIndex = direction === 'increasing' ? ++nextIndex : --nextIndex;
        if (nextIndex >= styleArray.length) {
            setDirection('decreasing');
            setStyleIndex(styleArray.length);
        } else if (nextIndex <= 0) {
            setDirection('increasing');
            setStyleIndex(0);
        } else {
            setStyleIndex(nextIndex);
        }
        setIndicator(indicator + '.');
        if (styleArray && styleArray.length > 0 && styleIndex >= 0 && styleIndex <= styleArray.length && styleArray[styleIndex])
            loadingThing.current.className = styleArray[styleIndex];
    }

    return (
        <div>
            {isLoading &&
                <div className={classes(styles.container, styles.label, className)}>
                    {error && "An Error Occurred"}
                    <span ref={loadingThing}>{loadingText ? loadingText : 'Loading'}</span>
                    <span>{indicator}</span>
                </div>
            }
        </div>
    );
}

export default Loading;