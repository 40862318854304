import React, {useEffect} from 'react';
import LoginView from '../views/LoginView';
import { connect } from 'react-redux';
import * as loginUser from '../actions/login.js';
import { selectMe } from '../store.js';

const mapStateToProps = state => {
		let me = selectMe(state);

    return {
        loginData: me,
        langCode: me.langCode,
    }
};

const bindActionsToDispatch = dispatch => ({
    login: (user) => dispatch(loginUser.login(user)),
    logout: () => dispatch(loginUser.logout()),
});

const mergeAllProps = (store, actions) => ({
    ...store, ...actions,
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
  mergeAllProps
);

function Container(props) {
	const {langCode, loginData, logout} = props;

	useEffect(() => {
    logout();
		window.localStorage.removeItem('person')
		window.localStorage.removeItem('isLoggingIn')
		window.localStorage.removeItem('works_mine')
		window.localStorage.removeItem('workId_current')
		window.localStorage.removeItem('authToken')
		window.localStorage.removeItem('works_others')
		window.localStorage.removeItem('works')
		window.localStorage.removeItem('personConfig')
		window.localStorage.removeItem('chapterId_current')
		window.localStorage.removeItem('languageId_current')
		window.localStorage.removeItem('groupId_current')
		window.localStorage.removeItem('recentWork')
		window.localStorage.removeItem('groups')
		window.localStorage.removeItem('contacts')
  }, [])

  return <LoginView {...props} showLoginControl={true}/>
}

export default storeConnector(Container);
