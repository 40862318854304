import React, {Component} from 'react';
import FileFolderAddUpdateView from '../views/FileFolderAddUpdateView';
import { connect } from 'react-redux';
import * as actionWorks from '../actions/works.js';
import {doSort} from '../utils/sort.js';

import { selectMe, selectWorkFileTree } from '../store.js';

let workFolder = null;
let fileFolderList = null;

const findWorkFolder = (workFolderId, fileTreeExplorer) => {
		if (!workFolder && fileTreeExplorer && fileTreeExplorer.length > 0) {
				fileTreeExplorer.forEach(m => {
						if (m.workFolderId) {
								if (m.workFolderId === workFolderId) {
										workFolder = m;
								}
						}
						if (m.subContents && m.subContents.length > 0) findWorkFolder(workFolderId, m.subContents)
				})
		}
}

const fillFileFolderList = (fileTreeExplorer) => {
		if (fileTreeExplorer && fileTreeExplorer.length > 0) {
				fileTreeExplorer.forEach(m => {
						if (m.workFolderId) {
								let option = [{id: m.folderWorkId, label: m.folderName }];
								fileFolderList = findWorkFolder ? findWorkFolder.concat(option) : option;
						}
						if (m.subContents && m.subContents.length > 0) fillFileFolderList(m.subContents)
				})
		}
}


// takes values from the redux store and maps them to props
const mapStateToProps = (state, props) => {
    let me = selectMe(state);
		let parentWorkFolderId = props.params && props.params.parentWorkFolderId;
		let workFolderId = props.params && props.params.workFolderId;
		let fileTreeExplorer = selectWorkFileTree(state);
		let workFolder = workFolderId && findWorkFolder(workFolderId, fileTreeExplorer);

		fileFolderList = fillFileFolderList(fileTreeExplorer);
		fileFolderList = doSort(fileFolderList, {sortField: 'folderName', isAsc: true, isNumber: false});

    return {
        personId: me.personId,
				parentWorkFolderId,
				workFolderId,
				workFolder,
				mineOrOthers: props.params && props.params.mineOrOthers,
    }
};

// binds the result of action creators to redux dispatch, wrapped in callable functions
const bindActionsToDispatch = dispatch => ({
    addOrUpdateFolder: (workFolder) => dispatch(actionWorks.addOrUpdateFolder(workFolder)),
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
);

class Container extends Component {

  render() {
    return <FileFolderAddUpdateView {...this.props} />
  }
}

export default storeConnector(Container);
