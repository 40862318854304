import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import GiveAccessToWorksView from '../views/GiveAccessToWorksView'
import {connect} from 'react-redux'
import * as actionContacts from '../actions/contacts.js'
import * as actionWorkFilter from '../actions/work-filter.js'
import * as actionWorks from '../actions/works.js'
import * as actionWorkEditReport from '../actions/work-edit-review.js'
import * as actionEditorAssign from '../actions/editor-assign'
import * as actionPersonConfig from '../actions/person-config.js'

import {
	selectMe,
	selectLanguages,
	selectFetchingRecord,
	selectEditorInvitePending,
	selectPersonConfig,
	selectWorkEditorAccess,
	selectWorks,
} from '../store.js'
import * as actionLanguage from "../actions/language-list";

//ToDO:  In case this route is called without access, make sure that the current PersonId owns this document in order to give access.

// takes values from the redux store and maps them to props
const mapStateToProps = state => {
	//1. Filter the contacts list, if any filters are chosen.
	//2. Loop through the contacts records, marking the currently chosen contact (so that it might be designated with some background color in a list later).
	// contact.languageNames = "English" //ToDO Get this from the personProfile record from the database.
	// contact.projectName = "" //ToDo - start tracking documents grouped by projectName.
	// contact.assignedCount = fromWorks.selectEditorAssignCountByPersonId(state.works, contact.personId)
	//let workFilterList = selectWorkFilter(state)
	let me = selectMe(state)
	// let workFilterOptions = workFilterList && workFilterList.length > 0 && workFilterList.filter(m => !m.scratchFlag)
	// workFilterOptions = workFilterOptions && workFilterOptions.length > 0
	//     && workFilterOptions.map(m => ({id: m.workFilterId, label: m.savedSearchName.length > 25 ? m.savedSearchName.substring(0,25) + '...' : m.savedSearchName}))
	// let filterScratch = workFilterList && workFilterList.length > 0 && workFilterList.filter(m => m.scratchFlag)[0]
	//
	// if (works && filterScratch.searchText) {
	//     works = works.filter(w => w.title && w.title.toLowerCase().indexOf(filterScratch.searchText.toLowerCase()) > -1)
	// }
	//
	// if (works && !filterScratch.mine) {
	//     works = works.filter(w => w.authors && !w.authors.includes(me.personId))
	// }
	//
	// if (works && !filterScratch.others) {
	//     works = works.filter(w => w.authors && w.authors.includes(me.personId))
	// }
	//
	// if (works && !filterScratch.active) {
	//     works = works.filter(w => !w.active)
	// }
	//
	// if (works && !filterScratch.completed) {
	//     works = works.filter(w => !w.completed)
	// }
	//
	// if (works && filterScratch.dueDateFrom && filterScratch.dueDateTo) {
	//     works = works.filter(w => w.dueDate >= filterScratch.dueDateFrom && w.dueDate <= filterScratch.dueDateTo)
	// } else if (works && filterScratch.dueDateFrom) {
	//     works = works.filter(w => w.dueDate >= filterScratch.dueDateFrom)
	// } else if (works && filterScratch.dueDateTo) {
	//     works = works.filter(w => w.dueDate <= filterScratch.dueDateTo)
	// }

	return {
		// workFilterOptions,
		// filterScratch,
		// savedFilterIdCurrent: filterScratch && filterScratch.savedFilterIdCurrent,
		workEditorAccess: selectWorkEditorAccess(state),
		workSummaries: selectWorks(state),
		personId: me.personId,
		owner_personId: me.personId,
		languageOptions: selectLanguages(state),
		fetchingRecord: selectFetchingRecord(state),
		editorInvitePending: selectEditorInvitePending(state),
		personConfig: selectPersonConfig(state),
	}
}

// binds the result of action creators to redux dispatch, wrapped in callable functions
const bindActionsToDispatch = dispatch => ({
	getWorkEditorAccess: (personId, workSearch, id) => dispatch(actionWorkEditReport.getWorkEditorAccess(personId, workSearch, id)),
	init: (personId) => dispatch(actionWorkFilter.init(personId)),
	setEditorAssign: (isAdd, workId, personId, owner_personId, chapters, languages, directChapterId, runFunction) => dispatch(actionEditorAssign.setEditorAssign(isAdd, workId, personId, owner_personId, chapters, languages, directChapterId, runFunction)),
	setWorkCurrentSelected: (personId, workId, chapterId, languageId, goToPage) => dispatch(actionWorks.setWorkCurrentSelected(personId, workId, chapterId, languageId, goToPage)),
	setContactCurrentSelected: (personId, contactPersonId, href) => dispatch(actionContacts.setContactCurrentSelected(personId, contactPersonId, href)),
	clearFilters: (personId) => dispatch(actionWorkFilter.clearFilters(personId)),
	saveNewSavedSearch: (personId, savedSearchName) => dispatch(actionWorkFilter.saveNewSavedSearch(personId, savedSearchName)),
	updateSavedSearch: (personId, workFilterId) => dispatch(actionWorkFilter.updateSavedSearch(personId, workFilterId)),
	deleteSavedSearch: (personId, workFilterId) => dispatch(actionWorkFilter.deleteSavedSearch(personId, workFilterId)),
	chooseSavedSearch: (personId, workFilterId) => dispatch(actionWorkFilter.chooseSavedSearch(personId, workFilterId)),
	updateFilterByField: (personId, field, value) => dispatch(actionWorkFilter.updateFilterByField(personId, field, value)),
	updateFilterDefaultFlag: (personId, savedFilterIdCurrent, setValue) => dispatch(actionWorkFilter.updateFilterDefaultFlag(personId, savedFilterIdCurrent, setValue)),
	updatePersonConfig: (personId, field, value) => dispatch(actionPersonConfig.updatePersonConfig(personId, field, value)),
  getLanguages: () => dispatch(actionLanguage.getLanguages()),
})

const mergeAllProps = (store, actions) => ({
	...store, ...actions,
})

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
	mergeAllProps
)

function Container(props) {
	const params = useParams()

	useEffect(() => {
		if (props.owner_personId) {
			props.getWorkEditorAccess(props.owner_personId, params && params.workSearch, params && params.id)
      props.getLanguages()
		}
	}, [props.owner_personId])

	return <GiveAccessToWorksView {...props} params={params}/>
}

export default storeConnector(Container)
