import * as types from './actionTypes.js';
import { apiHost } from '../api_host.js';
import { initRecords } from "./login";
import { guidEmpty } from '../utils/GuidValidate'

export const getMyProfile = (personId) => {
  return dispatch =>
    fetch(`${apiHost}ebi/myProfile/` + personId, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({ type: types.MY_PROFILE_INIT, payload: response });
      })
  //.catch(error => { console.l og('request failed', error); });
}

export const setMyProfile = (personId, field, value) => {
  return dispatch => {
    dispatch({ type: types.MY_PROFILE_UPDATE, payload: { field, value } });
    fetch(`${apiHost}ebi/myProfile/${personId}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify({ personId, field, value }),
    })
  }
}

export const setMyProfileNew = (user) => {
  if (user.inviteCode === 'empty') user.inviteCode = guidEmpty;
  // user.TranslateLanguageIds = []
  // user.TranslateLanguageNames = []
  // user.GenreIds = []
  // user.GenreNames = []
  // user.ProfilePictures = []
  // user.languageId = 1;

  return dispatch => {
    fetch(`${apiHost}ebi/myProfile/new/penspring`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify(user),
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          dispatch({ type: types.LOGGED_FAILED })
        }
      })
      .then(response => {
        if (response && response.loginPerson) {
          if (response.loginPerson.fullName === "MATCHING EMAIL ADDRESS FOUND") {
            dispatch({
              type: types.LOGIN_MATCHING_RECORD,
              payload: "MATCHING EMAIL ADDRESS FOUND"
            });
          } else if (response.loginPerson.fullName === "NOTVALID") {
            dispatch({ type: types.LOGGED_FAILED });
          } else {
            // //if there is a personId already in localStorage, then send this user to myWorks page. Otherwise, send to workAddNew
            // const existPerson = localStorage.getItem("person");
            // if (existPerson) {
            dispatch(initRecords(response, user.inviteCode ? '/myWorks' : '/workAddNew'));
            // } else {
            //   dispatch(initRecords(response, '/workAddNew'));
            // }
            localStorage.setItem("authToken", JSON.stringify(response.token).replace(/"/g, ''))
            localStorage.setItem("person", JSON.stringify(response.loginPerson))
          }
        }
      })
      .catch(response => {
        console.error('ERROR: actions/my-profile')
        dispatch({ type: types.LOGGED_FAILED })
      })
  }
}

export const updateMyProfile = (user) => {
  return dispatch =>
    fetch(`${apiHost}ebi/myProfile`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify(user),

    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({ type: types.MY_PROFILE_INIT, payload: response });
      })
  //.catch(error => { console.l og('request failed', error); });
}
