import * as types from '../actions/actionTypes'

export default function (state = [], action) {
	switch (action.type) {
		case types.GROUP_FILE_TREE_INIT: {
			return action.payload
		}
		// case types.GROUP_FILE_TREE_TOGGLE_EXPANDED: {  //This is mutating state and I can't figure out why since I was doing a clean copy of state.
		// 	let groupFolderId = action.payload
		// 	let newState = (state && state.length > 0 && [...state].map(m => m)) || []
		// 	let secondState = newState && newState.length > 0 && [...newState].map(m => {
		// 		if (m.groupFolderId === groupFolderId) m.isExpanded = !m.isExpanded
		// 		if (m.subFolders && m.subFolders.length > 0) m.subFolders = [...findAndSetExpandedFolder(groupFolderId, m.subFolders)]
		// 		return m
		// 	})
		// 	return secondState
		// }
		default:
			return state
	}
}

const findAndSetExpandedFolder = (groupFolderId, workFolders) => {
	return workFolders && workFolders.length > 0 && workFolders.map(m => {
		if (m.groupFolderId === groupFolderId) m.isExpanded = !m.isExpanded
		if (m.subFolders && m.subFolders.length > 0) m.subFolders = [...findAndSetExpandedFolder(groupFolderId, [...m.subFolders])]
		return m
	})
}

export const selectGroupFileTree = (state) => state
