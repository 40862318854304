import React, { useRef } from 'react'
import styles from './FileUploadModalWithCrop.module.css'
import globalStyles from '../../utils/globalStyles.module.css'
import InputFile from '../InputFile'
import classes from 'classnames'
import { useMediaQuery } from "react-responsive"
//import {ModalContainer, ModalDialog} from '../react-modal-dialog/lib/index.js'

function FileUploadModalWithCrop(props) {
  const {
    file,
    handleCancelFile, 
    handleClose,
    handleInputFile,
    isOpen,
    submitFileUpload,
    title,
  } = props

  const containerRef = useRef(null)
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background} ref={containerRef}>
          <form method="post" encType="multipart/form-data" className={styles.form}>
            <div className={globalStyles.pageTitle}>
              {title}
            </div>
            <InputFile label={`Include a picture`} isCamera={false} onChange={handleInputFile} isResize={true} />
            <div className={styles.buttonDiv}>
              <a onClick={() => { handleClose(); handleCancelFile(); }} className={styles.cancelLink}>Cancel</a>
              {file &&
                <a onClick={submitFileUpload} className={styles.buttonStyle}>Save</a>
              }
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FileUploadModalWithCrop