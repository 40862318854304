import React from 'react';
import styles from './PointsDisplay.module.css';
import classes from 'classnames';
import TextDisplay from '../TextDisplay'

function PointsDisplay({ className, correctControls, label='Points', pointsPossible, score,  }) {
	return (
    <div className={className}>
      <TextDisplay label={'Points'} text={correctControls
        ? <div className={styles.row}>
          {correctControls}
          <div className={classes(styles.littleTop, styles.row)}>
            <div className={styles.plainText}>out of</div>
            <div>{pointsPossible}</div>
          </div>
        </div>
        : <div className={(styles.littleTop, styles.row)}>
          <div>{Math.round(score * 10) / 10 || '0'}</div>
          <div className={styles.plainText}>out of</div>
          <div>{pointsPossible}</div>
        </div>
      } />
    </div>
	)
};


export default PointsDisplay