import React from 'react';
import styles from './DownloadButton.module.css';
import DropDownFloatCustom from '../DropDownFloatCustom'
import ButtonWithIcon from '../ButtonWithIcon'
import Download from "../../assets/Edit Icons/download.png";

const DownloadButton = ({ 
	setOpenOriginatingAuthorSend, 
	handleDownloadChoice,
	downloadOptions, 
	workSummary,
	personId
}) => {
	return (
		<DropDownFloatCustom key={'Download'}
			iconAvatar={<img src={Download} className={styles.logo} alt={'edit'} title={'Download'} />}
			onSelect={handleDownloadChoice}
			listOptions={downloadOptions}>
			{workSummary.originatingEditorPersonId === personId &&
				<div className={styles.topDivSpacing}>
					<div className={styles.originatingEditor}>Originating Editor:</div>
					<div className={styles.instructions}>Are you ready to send this to the author?</div>
					<div className={styles.buttonSpacing}>
						<ButtonWithIcon icon={'arrow_right'}
							isPremiumIcon={false}
							onClick={() => setOpenOriginatingAuthorSend(true)}
							label={`Next`}
							submitSuccess={false}
							smaller />
					</div>
					<hr />
				</div>
			}
		</DropDownFloatCustom>
	)
};


export default DownloadButton