import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import DraftReviewView from '../views/DraftReviewView'
import {connect} from 'react-redux'
import * as actionWorks from '../actions/works.js'
import * as actionPersonConfig from '../actions/person-config.js'
import * as actionContributorReport from '../actions/contributor-report.js'
import { guidEmpty } from '../utils/GuidValidate.js'

import {
  selectMe,
  selectWorkEditReview,
  selectDraftReview,
  selectPersonConfig,
  selectColorsEditor,
  selectLanguageIdCurrent,
  //selectLanguages,
  selectListLevelGeneral,
} from '../store.js'
import * as actionWorkEditReview from "../actions/work-edit-review";


const mapStateToProps = (state) => {
  const personId = selectMe(state).personId
  let workSummary = selectWorkEditReview(state)
  let draftReview = selectDraftReview(state)
  const editorColors = selectColorsEditor(state)

  const downloadOptions = [
    {
      value: 'Docx',
      span: <span style={{fontSize: '12px'}}>Word &reg;</span>,
      title: 'Word',
    },
    {
      value: "Html",
      span: <span style={{fontSize: '12px'}}>Html</span>,
      title: 'Html',
    },
    {
      value: "Text",
      span: <span style={{fontSize: '12px'}}>Text</span>,
      title: 'Text',
    },
    {
      value: "Odt",
      span: <span style={{fontSize: '12px'}}>Open Office (Odt)</span>,
      title: 'Open Office (Odt)',
    },
    {
      value: "Bmp",
      span: <span style={{fontSize: '12px'}}>Bmp</span>,
      title: 'Bmp',
    },
    {
      value: "Doc",
      span: <span style={{fontSize: '12px'}}>Doc</span>,
      title: 'Doc',
    },
    {
      value: "Epub",
      span: <span style={{fontSize: '12px'}}>Epub</span>,
      title: 'Epub',
    },
    {
      value: "Jpeg",
      span: <span style={{fontSize: '12px'}}>Jpeg</span>,
      title: 'Jpeg',
    },
    {
      value: "Markdown",
      span: <span style={{fontSize: '12px'}}>Markdown</span>,
      title: 'Markdown',
    },
    {
      value: "Mhtml",
      span: <span style={{fontSize: '12px'}}>Mhtml</span>,
      title: 'Mhtml',
    },
    {
      value: "Mobi",
      span: <span style={{fontSize: '12px'}}>Mobi</span>,
      title: 'Mobi',
    },
    {
      value: "OpenXps",
      span: <span style={{fontSize: '12px'}}>OpenXps</span>,
      title: 'OpenXps',
    },
    {
      value: "Pdf",
      span: <span style={{fontSize: '12px'}}>Pdf</span>,
      title: 'Pdf',
    },
    {
      value: "Png",
      span: <span style={{fontSize: '12px'}}>Png</span>,
      title: 'Png',
    },
    {
      value: "Rtf",
      span: <span style={{fontSize: '12px'}}>Rtf</span>,
      title: 'Rtf',
    },
    {
      value: "Tiff",
      span: <span style={{fontSize: '12px'}}>Tiff</span>,
      title: 'Tiff',
    },
    {
      value: "WordML",
      span: <span style={{fontSize: '12px'}}>WordML</span>,
      title: 'WordML',
    },
    {
      value: "Xps",
      span: <span style={{fontSize: '12px'}}>Xps</span>,
      title: 'Xps',
    },
  ]

  const setEditLabel = (edit) => {
    if (edit.type === 'TEXT') {
      return 'Sentence edit'
    } else if (edit.type === 'MOVE') {
      return 'Move sentences'
    } else if (edit.type === 'ADDPARAGRAPH') {
      return 'Add paragraph break'
    } else if (edit.type === 'DELETEPARAGRAPH') {
      return 'Delete paragraph break'
    } else if (edit.type === 'ADDSENTENCE') {
      return 'Add sentence'
    } else if (edit.type === 'DELETESENTENCE') {
      return 'Delete sentence'
    } else if (edit.type === 'ADDLIST') {
      return 'Add list'
    } else if (edit.type === 'ADDLISTITEM') {
      return 'Add list item'
    } else if (edit.type === 'DELETELISTITEM') {
      return 'Delete list item'
    } else if (edit.type === 'REORDERLISTITEMS') {
      return 'Re-order list items'
    } else if (edit.type === 'ADDTAB') {
      return 'Add tab'
    } else if (edit.type === 'DELETETAB') {
      return 'Delete tab'
    } else if (edit.type === 'LISTLEVELMINUS') {
      return 'Move list item left'
    } else if (edit.type === 'LISTLEVELPLUS') {
      return 'Move list item right'
    }
  }

  return {
    //isLMSTransfer: props.params && props.params.isLMSTransfer === 'LMStransfer',
    personId,
    downloadOptions,
    authorPersonId: workSummary && workSummary.authorPersonId,
    listLevelGeneral: selectListLevelGeneral(state),
    languageId: selectLanguageIdCurrent(state),
    workSummary,
    draftReview,
    tabsData: draftReview && draftReview.editorTabsData,
    chapterId: workSummary ? workSummary.chapterId_current && workSummary.chapterId_current !== guidEmpty ? workSummary.chapterId_current : workSummary.chapterOptions && workSummary.chapterOptions[0].chapterId : guidEmpty,
    personConfig: selectPersonConfig(state),
    editorColors,
  }
}

// binds the result of action creators to redux dispatch, wrapped in callable functions
const bindActionsToDispatch = dispatch => ({
  updatePersonConfig: (personId, field, value) => dispatch(actionPersonConfig.updatePersonConfig(personId, field, value)),
  addChapterListLevels: (chapterListLevels, chapterId) => dispatch(actionWorks.addChapterListLevels(chapterListLevels, chapterId)),
  getListLevelGeneral: () => dispatch(actionWorks.getListLevelGeneral()),
  downloadWork: (wordConversion) => dispatch(actionWorks.downloadWork(wordConversion)),
  deleteFile: (personId, workId, conversionType, languageId) => dispatch(actionWorks.deleteFile(personId, workId, conversionType, languageId)),
  getWorkEditReview: (personId, workId) => dispatch(actionWorkEditReview.getWorkEditReview(personId, workId)),
  getDraftReview: (draftRequest) => dispatch(actionContributorReport.getDraftReview(draftRequest)),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

let hasCalledChapterListLevels = false

function Container(props) {
  const {
    personId,
    getWorkEditReview,
    getDraftReview,
    languageId,
    getListLevelGeneral
  } = props
  const {workId} = useParams()


  useEffect(() => {
    if (personId && workId) {
      getListLevelGeneral()
      getWorkEditReview(personId, workId)
      getDraftReview({workId})
      if (!hasCalledChapterListLevels) {
        hasCalledChapterListLevels = true
      }
    }
  }, [personId, workId])


  return <DraftReviewView {...props} />
}

export default storeConnector(Container)
