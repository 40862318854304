import React from 'react'
import styles from './WorkAddHasChapters.module.css'
import WorkListPenspringDrive from '../WorkListPenspringDrive'
import PSLogo from '../../assets/logos/homescreen48.png'
import PenspringSmall from '../../assets/penspring_medium_noreg.png'
import InputText from '../InputText'
import Required from '../Required'
import WorkSummaryLine from '../WorkSummaryLine'
import { guidEmpty } from '../../utils/GuidValidate'

export default (props) => {
	const { 
		work, 
		handleNewChapter, 
		setWork, 
		filePenspring, 
		penspringDriveOpen, 
		setPenspringDriveOpen, 
		chapterNbrError, 
		chapterNameError, 
		chapters,
		isMobile } = props

	const resetWork = () => {
		const newChapter = work?.newChapter
		const newWork = {newChapter}
		Promise.all([setWork(newWork)]).then(setPenspringDriveOpen(!penspringDriveOpen))
	}

	return (
		<div>
			<div className={styles.rowWrap}>
				<div className={styles.titleRequired}>
					This document has chapters
					<Required
						setIf={true}
						className={styles.requiredPosition}
						setWhen={work?.newChapter?.chapterNbr && work?.newChapter?.name && (chapters === 'NEWPENSPRING' || (chapters === 'EXISTINGPENSPRING' && filePenspring && filePenspring.workId && filePenspring.workId !== guidEmpty))} />
				</div>
				<div className={styles.tinyInstructions}>Split your document into separate files for each chapter in order to give access to different editors or to hide specific chapters.</div>
			</div>
			<div>
				<div className={styles.showHasChapters}>
					<InputText
						numberOnly
						maxLength={5}
						value={work?.newChapter?.chapterNbr || ''}
						size={"super-short"}
						name={"chapterNbr"}
						label={`Chapter sequence`}
						inputClassName={styles.inputText}
						required={true}
						whenFilled={work?.newChapter?.chapterNbr}
						error={chapterNbrError}
						onChange={(event) => handleNewChapter(event)} />

					<InputText
						value={work?.newChapter?.name || ''}
						size={"medium-long"}
						name={"name"}
						label={`Name`}
						inputClassName={styles.inputText}
						required={true}
						whenFilled={work?.newChapter?.name}
						error={chapterNameError}
						onChange={(event) => handleNewChapter(event)} />
				</div>
				<div className={styles.smallInstructions}>(You do not have to start with the first chapter)</div>
				{chapters !== 'NEWPENSPRING' &&
					<div>
						<div className={styles.penspringDrive}>
							<div className={isMobile ? styles.rowGoogleMobile : styles.rowGoogle} onClick={resetWork}>
								<img src={PSLogo} height={25} />
								<div className={styles.googleText}>
									Click here to choose a
									<div className={styles.existingPenspring}>
										<img src={PenspringSmall} height={20} className={styles.penspringBackground} style={{position: 'relative', top: '3px'}}/> 
									</div>
									file 
								</div>
							</div>
							{filePenspring && filePenspring.workId && filePenspring.workId !== guidEmpty && 
								<div style={{ marginTop: '10px' }}>
									<WorkSummaryLine work={work}
										isWorkAddView={true} //This is for the Mine subfolder display on WorkAddView to choose a file (which will override showEditLink, by the way)
										showEditLink //Because this is on the Myworks and if it is on mobile, then show the pencil at the beginning for a short cut.
										onClick={() => setWork()} //This is to blank it out and start again.
										personId={props.personId}
										nameLength={150}
										darkerBackground={true}
										darkerLabel
										includeDates
										chosenWork={filePenspring}
										thisWorkFolderId={''}
										showExplorerOptions
										noFocusColor={!(true)}
										showDocumentDetails={true}
										// moveWorkToFolder={moveWorkToFolder}
										// isMoveWorkId={isMoveWorkId}
										// deleteWorkFromFolder={deleteWorkFromFolder}
										mineOrOthers={'Mine'}
										hasParentFolderGroup={false}
										{...props} />
								</div>
							}
							<div className={penspringDriveOpen ? '' : styles.hide}>
								<WorkListPenspringDrive openList={penspringDriveOpen} setWork={setWork} work={filePenspring} {...props} />
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	)
}
