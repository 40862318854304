import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import styles from './GroupWorkAssignView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import InputText from '../../components/InputText';
import SelectSingleDropDown from '../../components/SelectSingleDropDown';
import TextDisplay from '../../components/TextDisplay';
import Checkbox from '../../components/Checkbox';
import Icon from '../../components/Icon';
import MessageModal from '../../components/MessageModal';
import OneFJefFooter from '../../components/OneFJefFooter';
import classes from 'classnames';
import * as guid from '../../utils/GuidValidate.js';

function GroupWorkAssignView(props) {
  let {groupSummary, workSummary, subGroupCountOptions} = props;
  const navigate = useNavigate()

  const [localData, setLocalData] = useState(false)
  const [peerGroupOpen, setPeerGroupOpen] = useState(false)
  const [peerGroupName, setPeerGroupName] = useState(props.peerGroupName)
  const [copyPreviousPeerGroupId, setCopyPreviousPeerGroupId] = useState('')
  const [subGroupCountError, setSubGroupCountError] = useState('')
  const [notAssigned, setNotAssigned] = useState([])
  const [accessAssigned, setAccessAssigned] = useState(props.accessAssigned ? props.accessAssigned : [])
  const [unevenCount, setUnevenCount] = useState(0)
  const [memberToBeAssigned, setMemberToBeAssigned] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [hasRemainderMessage, setHasRemainderMessage] = useState('')
  const [isShowingPeerGroupInfo, setIsShowingPeerGroupInfo] = useState(false)
  const [unassignedMembers, setUnassignedMembers] = useState()
  const [parentPeerGroupName, setParentPeerGroupName] = useState()  //I think is a state thing.


  //ToDo I don't know how this is supposed to function now with react 18
  // componentDidUpdate(prevProps, prevState) {
  //     if (prevProps.accessAssigned !== props.accessAssigned) {
  //         setAccessAssigned(props.accessAssigned )
  //         getUnassignedOptions(props.accessAssigned.peerGroups)
  //     }
  //     if (prevState.accessAssigned !== accessAssigned) {
  //         setAccessAssigned(accessAssigned )
  //     }
  // }

  const handleCompareChanges = () => {  //ToDo this seems to be a function that wasn't created yet.
  }

  const handleCopyPreviousPeerGroup = () => {
    const {copyPeerGroupGroupWorkAssignAccess, personId, accessAssigned} = props;
    if (copyPreviousPeerGroupId) copyPeerGroupGroupWorkAssignAccess(personId, accessAssigned.masterWorkId, copyPreviousPeerGroupId)
  }

  const setAllAccess = () => {
    //workAccess is not turned off, really, if it is turned on already. It is placed in lock mode so that it cannot be changed.
    //originalWorkAccess comes from the database so we can determine if the workAccess given so far might just be local on this page and can be backed out before going back to the database.
    let newAccessAssigned = Object.assign({}, accessAssigned);
    newAccessAssigned.peerGroups = newAccessAssigned.peerGroups.map((p, i) => {
      p.members = p.members.map((m, ind) => {
        if (!m.originalWorkAccess) {
          m.isLockedAccess = false;
          m.workAccess = m.workAccess;  //ToDo what is supposed to happen here?
        } else {
          m.isLockedAccess = m.workAccess ? !m.isLockedAccess : false;
          m.workAccess = true; //Force this to stay checked
        }
        return m;
      })
      return p;
    });
    setAccessAssigned(newAccessAssigned)
  }

  const handleAccessChange = (subGroupIndex, memberIndex) => {
    //workAccess is not turned off, really, if it is turned on already. It is placed in lock mode so that it cannot be changed.
    //originalWorkAccess comes from the database so we can determine if the workAccess given so far might just be local on this page and can be backed out before going back to the database.
    let newAccessAssigned = Object.assign({}, accessAssigned);
    newAccessAssigned.peerGroups = newAccessAssigned.peerGroups.map((p, i) => {
      if (i === subGroupIndex) {
        p.members = p.members.map((m, ind) => {
          if (ind === memberIndex) {
            if (!m.originalWorkAccess) {
              m.isLockedAccess = false;
              m.workAccess = m.workAccess;  //ToDo what is supposed to happen here?
            } else {
              m.isLockedAccess = m.workAccess ? !m.isLockedAccess : false;
              m.workAccess = true; //Force this to stay checked
            }
          }
          return m;
        })
      }
      return p;
    });
    setAccessAssigned(newAccessAssigned)
  }

  const resetData = () => {
    const {accessAssigned} = props;
    let newAccessAssigned = Object.assign({}, accessAssigned)  //ToDo I'm not so sure this is accurate. I assumed it was a conversion from state.
    setAccessAssigned(newAccessAssigned)
  }

  const handlePrevPeerGroupChange = (event) => {
    setCopyPreviousPeerGroupId(event.target.value)
  }

  const handleCellClick = (personId, workId) => {
    //Find the cell that matches the personId and workId (id and headingId, respectively)
    //  and toggle the value from whatever it currently is.
    let newData = localData.map(row => row.map((cell, index) =>
      cell.id === personId && cell.headingId === workId
        ? {
          ...cell,
          id: cell.id,
          headingId: cell.headingId,
          value: !cell.boolValue ? createCheckmarkIcon(cell.id, cell.headingId) : createNoAccessIcon(cell.id, cell.headingId),
          boolValue: !cell.boolValue,

        }
        : cell));
    setLocalData(newData)
    handleCompareChanges(newData);
  }

  const createCheckmarkIcon = () => {
  }  //ToDo I don't know where this is supposed to be coming from. This is a place holder.
  const createNoAccessIcon = () => {
  }//ToDo I don't know where this is supposed to be coming from. This is a place holder.


  const handleParentPeerGroupNameChange = (event) => {
    setAccessAssigned({...accessAssigned, parentPeerGroupName: event.target.value})
  }

  const togglePeerGroupOpen = () => {
    setPeerGroupOpen(!peerGroupOpen)
  }

  const handleNameChange = (event) => {
    setPeerGroupName(event.target.value)
  }

  const handleMemberToBeAssigned = (event) => {
    setMemberToBeAssigned(event.target.value)
  }

  const handleAddUnassigned = (groupSequence) => {
    const {groupSummary} = props;
    let subGroups = !!accessAssigned.peerGroups && accessAssigned.peerGroups.map(g => {
      if (g.sequence === groupSequence) {
        g.members.unshift(groupSummary.members.filter(p => p.personId === memberToBeAssigned.personId)[0])
      }
      return g;
    });

    setAccessAssigned({...accessAssigned, peerGroups: subGroups})
    getUnassignedOptions(subGroups);
  }

  const getUnassignedOptions = (subGroups) => {
    //We pass in the subGroups here right after the remove member so we can be sure to have the latest data and we don't have to wait
    const {groupSummary} = props;

    let members = Object.assign([], groupSummary.members);
    if (members && members.length > 0) {
      subGroups.length > 0 && subGroups.forEach(m => {
        m.members.length > 0 && m.members.forEach(p => {
          members = members.filter(b => b.personId !== p.personId);
        });
      });
    }
    setUnassignedMembers(members)
    setMemberToBeAssigned(members[0])
  }

  const handleRemoveMember = (groupSequence, memberPersonId) => {
    //Go to the groupSequence and then splice out the memberPersonId
    let subGroups = !!accessAssigned.peerGroups && accessAssigned.peerGroups.map(m => {
      if (m.sequence === groupSequence && m.members.length > 0) {
        return {...m, members: m.members.filter(p => p.personId !== memberPersonId)};
      } else {
        return m;
      }
    });
    setAccessAssigned({...accessAssigned, peerGroups: subGroups})
    getUnassignedOptions(subGroups);
  }

  const shuffleMemberList = () => {
    let array = accessAssigned.allMembers;
    let currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  const handleSubGroupCreate = () => {
    let subGroups = [];
    let subGroup = {
      name: 1,
      sequence: 1,
      members: [],
    };
    let memberCount = !!accessAssigned.allMembers && accessAssigned.allMembers.length;
    let unevenCount = memberCount % accessAssigned.subGroupCount;
    let newGroupCount = 0;
    let remainderDistributeIndex = 0;
    let randomMembers = shuffleMemberList();

    for (let i = 0; i < memberCount; i++) {
      //There is a good chance that the groups won't come out even.  Assign all of the extras at the end that exceed the exact division of members.
      if (newGroupCount === Math.floor(accessAssigned.membersPerGroup) && subGroups.length < accessAssigned.subGroupCount - 1) {
        newGroupCount = 0;
        subGroups = subGroups ? subGroups.concat(subGroup) : [subGroup];
        subGroup = {
          name: subGroups.length + 1,
          sequence: subGroups.length + 1,
          members: [],
        }
      }
      //If the loop index, i, is now greater than the membersPerGroup multiplied by the subGroupCount,
      //Then add each remainder member into the groups, starting from the beginning, 1.
      if (i >= Math.floor(accessAssigned.membersPerGroup) * accessAssigned.subGroupCount) {
        subGroups = subGroups.map((m, index) => {
          if (index === remainderDistributeIndex) {
            m.members.push(randomMembers[i])
          }
          return m;
        });
        remainderDistributeIndex++;
      } else {
        subGroup.members = !!subGroup.members ? subGroup.members.concat(randomMembers[i]) : [randomMembers[i]];
      }
      newGroupCount++;
    }
    //We need to pick up the last one after the circulation of the final group.
    subGroups = subGroups ? subGroups.concat(subGroup) : [subGroup];
    setAccessAssigned({...accessAssigned, peerGroups: subGroups})
    setUnevenCount(unevenCount)
  }


  const handleSubGroupNameChange = (sequence, event) => {
    let newAssigned = Object.assign({}, accessAssigned);
    newAssigned.peerGroups = !!accessAssigned.peerGroups && accessAssigned.peerGroups.map(m => {
      if (m.sequence === sequence) {
        m.name = event.target.value;
      }
      return m;
    })
    setAccessAssigned(newAssigned)
  }

  const handleSubGroupCountChange = (event) => {
    const {groupSummary} = props;
    if (!event.target.value) return;
    //1. Get the member memberCount
    //2. Divide the members by the numbers of groups chosen.
    //3. Get the remainder
    //4. Set the groups but keep the remainder in mind.
    let memberCount = groupSummary.members && groupSummary.members.length;
    let groupCount = event.target.value;
    let membersPerGroup = memberCount / groupCount;
    let hasRemainder = memberCount % groupCount;
    let hasRemainderMessage = '';
    if (hasRemainder) {
      let theLastGroups = groupCount - hasRemainder + 1;
      hasRemainderMessage = Math.floor(membersPerGroup) === 1
        ? theLastGroups === 1
          ? 'The last group will only have ' + Math.floor(membersPerGroup) + ' member'
          : 'The last ' + theLastGroups + ' groups will only have ' + Math.floor(membersPerGroup) + ' member'
        : theLastGroups === 1
          ? 'The last group will only have ' + Math.floor(membersPerGroup) + ' members'
          : 'The last ' + theLastGroups + ' groups will only have ' + Math.floor(membersPerGroup) + ' members';
      membersPerGroup = Math.floor(++membersPerGroup);
    }
    setHasRemainderMessage(hasRemainderMessage)
    setAccessAssigned({...accessAssigned, membersPerGroup, subGroupCount: event.target.value})
  }

  const handleMembersPerGroupChange = (event) => {
    const {groupSummary} = props;
    if (!event.target.value) return;
    //1. Get the member memberCount
    //2. Divide the members by the members per group.
    //3. Get the remainder
    //4. Set the groups but keep the remainder in mind.
    let memberCount = groupSummary.members && groupSummary.members.length;
    let membersPerGroup = event.target.value;
    let groupCount = memberCount / membersPerGroup;
    let hasRemainder = memberCount % membersPerGroup;
    let hasRemainderMessage = '';
    if (hasRemainder) {
      hasRemainderMessage = hasRemainder === 1
        ? 'The last group will have ' + hasRemainder + ' member'
        : 'The last group will have ' + hasRemainder + ' members';
      groupCount = Math.floor(++groupCount);
    }
    setHasRemainderMessage(hasRemainderMessage)
    setAccessAssigned({...accessAssigned, membersPerGroup: event.target.value, subGroupCount: groupCount})
  }

  const handleSubmit = () => {
    const {addOrUpdatePeerGroup, personId, peerGroupId, groupSummary} = props;
    let isValid = true;

    if (!accessAssigned.subGroupCount && !accessAssigned.membersPerGroup) {
      setSubGroupCountError("Please choose the number of sub groups that you want.")
      isValid = false;
    }
    let sendPeerGroupId = peerGroupId ? peerGroupId : guid.emptyGuid();
    isValid && addOrUpdatePeerGroup({
      groupId: groupSummary.groupId,
      personId,
      peerGroupId: sendPeerGroupId,
      parentPeerGroupName,
      subGroupCount: accessAssigned.subGroupCount
    }, accessAssigned);
    isValid && setIsSubmitted(true)
  }

  const processForm = (stayOrFinish) => {
    const {addUpdateGroupWorkAssignAccess} = props;

    addUpdateGroupWorkAssignAccess(accessAssigned);
    if (stayOrFinish === "FINISH") {
      navigate("/assignmentDashboard/" + accessAssigned.groupId + "/" + accessAssigned.masterWorkId);
    }
  }

  const handlePeerGroupInfoClose = () => setIsShowingPeerGroupInfo(false)
  const handlePeerGroupInfoOpen = () => setIsShowingPeerGroupInfo(true)


  return (
    <div className={styles.container}>
      <div className={globalStyles.pageTitle}>
        {groupSummary.groupTypeName === "FACILITATORLEARNER"
          ? 'Grant Access to Learners'
          : 'Grant Access to Members'
        }
      </div>
      <div className={styles.subTitle}>
        <span className={styles.subLabel}>group:</span>
        {groupSummary.groupName}
      </div>
      <div className={styles.subTitle}>
        <span className={styles.subLabel}>assignment:</span>
        {workSummary.workName}
      </div>
      <div className={styles.containerName}>
        <TextDisplay label={'Group members'} text={groupSummary && groupSummary.members && groupSummary.members.length}
                     className={styles.textDisplay}/>

        <div className={classes(styles.row, styles.clickable)}>
          <a onClick={togglePeerGroupOpen}><Icon pathName={'group_work'} premium={true}/></a>
          <span className={styles.text} onClick={togglePeerGroupOpen}>Split the class into peer groups?</span>
          <a onClick={togglePeerGroupOpen} className={styles.caretPosition}>
            <Icon pathName={'chevron_down'} className={peerGroupOpen ? styles.chevronUp : styles.chevronDown}/>
          </a>
          <a onClick={handlePeerGroupInfoOpen}><Icon pathName={`info0`} className={styles.infoImage}/></a>
        </div>

        {peerGroupOpen &&
          <div>
            <div className={styles.row}>
              <div>
                <SelectSingleDropDown
                  id={`copyPreviousPeerGroupId`}
                  label={`(optional) Copy peer group from previous assignment`}
                  value={copyPreviousPeerGroupId}
                  options={accessAssigned.otherWorkPeerGroups}
                  className={styles.singleDropDown}
                  height={`medium`}
                  onChange={handlePrevPeerGroupChange}/>
              </div>
              <a onClick={handleCopyPreviousPeerGroup}>
                <Icon pathName={`checkmark`} className={classes(styles.farLeft, styles.imageTopMargin)}/>
              </a>
            </div>
            <div className={styles.row}>
              <div>
                <SelectSingleDropDown
                  id={`subGroupCount`}
                  label={`Number of groups`}
                  value={accessAssigned.subGroupCount}
                  options={subGroupCountOptions}
                  className={styles.singleDropDown}
                  error={subGroupCountError}
                  height={`medium-short`}
                  onChange={handleSubGroupCountChange}/>
              </div>
              <span className={styles.bigText}>- OR -</span>
              <div>
                <SelectSingleDropDown
                  id={`membersPerGroup`}
                  label={`Members per group`}
                  value={accessAssigned.membersPerGroup}
                  options={subGroupCountOptions}
                  className={styles.singleDropDown}
                  error={subGroupCountError}
                  height={`medium-short`}
                  onChange={handleMembersPerGroupChange}/>
              </div>
            </div>
            <div className={styles.textWarning}>{hasRemainderMessage}</div>
            <div className={styles.parentGroup}>
              <InputText
                value={accessAssigned.parentPeerGroupName || ''}
                size={"medium-long"}
                name={'parentPeerGroupName'}
                label={"(optional) Give this peer group setup a name"}
                onChange={handleParentPeerGroupNameChange}/>
            </div>
            <div onClick={handleSubGroupCreate} className={styles.button}>
              {accessAssigned && !!accessAssigned.peerGroups && accessAssigned.peerGroups.length > 0 ? `Reset Groups` : `Make Groups`}
            </div>
          </div>
        }
      </div>
      <hr/>
      {unassignedMembers && unassignedMembers.length > 0 &&
        <div className={styles.unassignedMembers}>
          {unassignedMembers.length === 1
            ? 'There is one unassigned member'
            : `There are ${unassignedMembers.length} unassigned members`}
        </div>
      }
      <div className={classes(styles.row)}>
        <button className={styles.button} onClick={(event) => processForm("STAY", event)}>
          Save & Stay
        </button>
        <button className={styles.button} onClick={(event) => processForm("FINISH", event)}>
          Save & Finish
        </button>
        <a className={styles.resetButton} onClick={resetData}>reset</a>
      </div>
      {accessAssigned &&
        accessAssigned && !!accessAssigned.peerGroups && accessAssigned.peerGroups.map((m, i) =>
          <div key={i}>
            <div className={styles.setAll}>
              <a onClick={setAllAccess}>set all</a>
            </div>
            {accessAssigned.peerGroups.length > 1 &&
              <InputText
                value={m.name}
                size={"medium"}
                name={m.sequence}
                inputClassName={styles.subGroupInput}
                label={"Sub group name"}
                onChange={(event) => handleSubGroupNameChange(m.sequence, event)}/>
            }
            {unassignedMembers && unassignedMembers.length > 0 &&
              <div className={classes(styles.row)}>
                <div>
                  <SelectSingleDropDown
                    id={`unassignedMembers`}
                    label={`Unassigned members`}
                    value={memberToBeAssigned}
                    options={unassignedMembers}
                    className={classes(styles.singleDropDown, styles.moreLeft)}
                    noBlank={true}
                    height={`medium`}
                    onChange={handleMemberToBeAssigned}/>
                </div>
                <a onClick={() => handleAddUnassigned(m.sequence)}>
                  <Icon pathName={`checkmark`} className={styles.imageTopMargin}/>
                </a>
              </div>
            }
            {m.members && m.members.length > 0 && m.members.map((d, ind) =>
              <div key={ind} className={classes(styles.row, styles.leftMargin)}>
                {accessAssigned.peerGroups.length > 1 &&
                  <a onClick={() => handleRemoveMember(m.sequence, d.personId)}>
                    <Icon pathName={`move`} className={styles.image} premium={true}/>
                  </a>
                }
                <Checkbox
                  id={`memberAccess`}
                  label={``}
                  checked={d.workAccess}
                  className={styles.checkbox}
                  onClick={() => handleAccessChange(i, ind)}/>
                {d.workAccess && d.isLockedAccess &&
                  <Icon pathName={`locked0`} premium={true} fillColor={'maroon'} className={styles.locked}/>}
                <span className={styles.member} onClick={() => handleAccessChange(i, ind)}>
                                {d.firstName + ' ' + d.lastName}
                            </span>
              </div>
            )}
          </div>
        )
      }
      <OneFJefFooter/>
      <MessageModal show={isShowingPeerGroupInfo} handleClose={handlePeerGroupInfoClose}
                    heading={`How to use peer groups with an assignment`} showPeerGroupInfo={true}
                    explain={`Peer groups will allow members of each sub group to view and edit each other's assignments.<br/><br/>
                If you only want one assignment per peer group, grant access to one student. That student will become the
                one person who can finalize the document by accepting the others' edits.<br/><br/>
                Or, if you want every student to have their own copy to turn in, give access to everyone.  They will each
                become the author of their own assignment and the others can contribute by making edits`}
                    onClick={handlePeerGroupInfoClose}/>
    </div>
  )
}

export default GroupWorkAssignView