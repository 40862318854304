import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import styles from './GroupEditReportView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import EditTable from '../../components/EditTable';
import ReportFilter from '../../components/ReportFilter';
import SelectSingleDropDown from '../../components/SelectSingleDropDown';
import Accordion from '../../components/Accordion';
// import TabPage from '../../components/TabPage';
// import Icon from '../../components/Icon';
import OneFJefFooter from '../../components/OneFJefFooter';
//import EditorEditList from '../../components/EditorEditList';

function GroupEditReportView(props) {
  const {personId, updateFilterByField, clearFilters, filterScratch, savedFilterIdCurrent,
    updateSavedSearch, updateFilterDefaultFlag, deleteSavedSearch, chooseSavedSearch, saveNewSavedSearch, workOptions,
    nativeLanguageOptions, translateLanguageOptions, editorOptions, sectionOptions, filterOptions,
    editTypeOptions} = props;
  const {editTypeCount} = props.params;
  let {reportTable} = props;

  const navigate = useNavigate()

  const [tabsData, setTabsData] = useState()
  const [reportChoice, setReportChoice] = useState()


  const handleEditTypeChange = (event) => {
      let pathName = props.location.pathname;
      pathName = pathName.indexOf('/editCount') > -1 ? pathName.substring(0, pathName.indexOf('/editCount')) : pathName;
      navigate(pathName + '/editCount/' + event.target.value);
  }

  const handleReportChange = (event) => {
      const {reportOptGroup} = props;
      setReportChoice(event.target.value)

      let pathLink = '';
      reportOptGroup && reportOptGroup.length > 0 && reportOptGroup.forEach(r => {
          r.options && r.options.length > 0 && r.options.forEach(o => {
              if (o.id === event.target.value) {
                  pathLink = o.pathLink;
                  setTabsData({...tabsData, chosenTab: r.editOrTranslate})
              }
          })
      })
      if (pathLink) {
          navigate(`/report/` + pathLink);
      }
  }

  const handlePathLink = (pathLink) => {
      pathLink && navigate(pathLink);
  }

  return (
      <div className={styles.container}>
          <div className={globalStyles.pageTitle}>
              {'Group Edit Report'}
          </div>
          <Accordion title={'filterScratch'}>
            {/*expanded={false} filterScratch={filterScratch} filterOptions={filterOptions} savedFilterIdCurrent={savedFilterIdCurrent}*/}
            {/*      updateSavedSearch={updateSavedSearch} deleteSavedSearch={deleteSavedSearch} chooseSavedSearch={chooseSavedSearch}*/}
            {/*      updateFilterByField={updateFilterByField} updateFilterDefaultFlag={updateFilterDefaultFlag} personId={personId}*/}
            {/*      clearFilters={clearFilters}>*/}
              <ReportFilter personId={personId} reportFilter={filterScratch} updateFilterByField={updateFilterByField}
                  clearFilters={clearFilters} saveNewSavedSearch={saveNewSavedSearch} workOptions={workOptions}
                  nativeLanguageOptions={nativeLanguageOptions} translateLanguageOptions={translateLanguageOptions}
                  editorOptions={editorOptions} sectionOptions={sectionOptions} savedSearchOptions={filterOptions}/>
          </Accordion>
          {editTypeOptions && editTypeOptions.length > 0 &&
              <div>
                  <SelectSingleDropDown
                      value={editTypeCount}
                      label={`Edit Type Count`}
                      options={editTypeOptions}
                      height={`medium`}
                      noBlank={true}
                      className={styles.singleDropDown}
                      onChange={handleEditTypeChange} />
              </div>
          }
          {(!editTypeOptions || editTypeOptions.length === 0) &&
              <div className={styles.marginSpace}>
              </div>
          }
          <EditTable labelClass={styles.tableLabelClass} headings={reportTable.headings}
              data={reportTable.data} noCount={true} firstColumnClass={styles.firstColumnClass}
              sendToReport={handlePathLink}/>
          <br/>
          <br/>
          <OneFJefFooter />
    </div>
  )
}
export default GroupEditReportView

// <EditorEditList returnToSummary={handleFlip}
//                              personId={personId}
//                              workId={workSummary.workId}
//                              chapterId={workSummary.chapterId_current}
//                              languageId={workSummary.languageId}
//                              authorPersonId={authorPersonId}
//                              editDetailsPerson={editDetailsPerson}
//                              editDetails={editDetails}
//                              setEditDetail={setEditDetail}
//                              setAcceptedEdit={setAcceptedEdit}
//                              setEditVoteBack={setEditVoteBack}
//                              deleteEditDetail={deleteEditDetail}
//                              restoreEditDetail={restoreEditDetail}
//                              getOriginalSentence={getOriginalSentence}
//                              updateFilter={handleFilterKeyValue}
//                              localFilter={localFilter}
//                              setEditorTabSelected={setEditorTabSelected}
//                              setVisitedHrefId={setVisitedHrefId}
//                              getTabsEditors={getTabsEditors}/>

