import * as types from './actionTypes';
import {apiHost} from '../api_host.js';
import getPersonIdCurrent from '../services/personId-current.js';

export const getContacts = (personId) => {
  return dispatch => {
    let contacts = localStorage.getItem('contacts')
    if (contacts) dispatch({ type: types.CONTACTS_INIT, payload: JSON.parse(contacts) })
    return fetch(`${apiHost}ebi/person/${personId}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials' : 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        localStorage.setItem('contacts', JSON.stringify(response))
        dispatch({ type: types.CONTACTS_INIT, payload: response })
      })
  }
}


function setPersonIdCurrent( personId_current ) {
    return { type: types.CONTACT_CURRENT_SET_SELECTED, payload: personId_current };
}

export const initPersonIdCurrent = (personId) => dispatch =>
    getPersonIdCurrent(personId).then( n => {
        dispatch( setPersonIdCurrent(n.contactPersonId))
    });

export const setContactCurrentSelected = (personId, contactPersonId, goToPage) => {
    return dispatch =>
      fetch(`${apiHost}ebi/recentContact`, {
        method: 'put',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials' : 'true',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
            "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
            "Authorization": "Bearer " + localStorage.getItem("authToken"),
          },
          body: JSON.stringify({
              personId,
              contactPersonId,
          }),
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
            dispatch({ type: types.CONTACT_CURRENT_SET_SELECTED, payload: contactPersonId });
            if (goToPage) {
              window.location = goToPage;
            }
        }
      })
}


export const checkContactMembers = (contactMatches) => {
  return dispatch =>
    fetch(`${apiHost}ebi/checkContactMembers`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify(contactMatches),
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({ type: types.CONTACT_MEMBERS_INIT, payload: response })
      })
}
