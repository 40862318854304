const fontSizeOptions = [
  {
    value: 6,
    span: <span style={{fontSize: '6px'}}>6 px</span>
  },
  {
    value: 8,
    span: <span style={{fontSize: '8px'}}>8 px</span>
  },
  {
    value: 9,
    span: <span style={{fontSize: '9px'}}>9 px</span>
  },
  {
    value: 10,
    span: <span style={{fontSize: '10px'}}>10 px</span>
  },
  {
    value: 11,
    span: <span style={{fontSize: '11px'}}>11 px</span>
  },
  {
    value: 12,
    span: <span style={{fontSize: '12px'}}>12 px</span>
  },
  {
    value: 14,
    span: <span style={{fontSize: '14px'}}>14 px</span>
  },
  {
    value: 16,
    span: <span style={{fontSize: '16px'}}>16 px</span>
  },
  {
    value: 18,
    span: <span style={{fontSize: '18px'}}>18 px</span>
  },
  {
    value: 24,
    span: <span style={{fontSize: '24px'}}>24 px</span>
  },
  {
    value: 30,
    span: <span style={{fontSize: '30px'}}>30 px</span>
  },
  {
    value: 36,
    span: <span style={{fontSize: '36px'}}>36 px</span>
  },
  {
    value: 48,
    span: <span style={{fontSize: '48px'}}>48 px</span>
  },
  {
    value: 60,
    span: <span style={{fontSize: '60px'}}>60 px</span>
  },
  {
    value: 72,
    span: <span style={{fontSize: '72px'}}>72 px</span>
  },
]

export default fontSizeOptions