import React from 'react'
import styles from './AssessmentTrueFalse.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import RadioGroupToggle from '../../RadioGroupToggle'
import Icon from '../../Icon'
import ImageDisplay from '../../ImageDisplay'
import AudioDisplay from '../../AudioDisplay'
import QuestionLabel from '../../QuestionLabel'
import PointsDisplay from '../../PointsDisplay'
import classes from 'classnames'

function AssessmentTrueFalse(props) {
  const {
    assessmentCorrect,
    assignmentId,
    bigTextDisplay,
    className = "",
    correctControls,
    initialValue,
    nameKey,
    onClick,
    personId,
    question,
    removeQuestionFileOpen,
    removeQuestionRecordingOpen,
    removeSolutionFileOpen,
    removeSolutionRecordingOpen,
    score
  } = props

  const sendAssessmentAnswer = (value, personId) => {
    if (onClick) onClick(personId, question.assessmentQuestionId, value, assignmentId)
  }

  let correct = (assessmentCorrect?.length > 0 && assessmentCorrect.filter(m => m.assessmentQuestionId === question.assessmentQuestionId)[0]) || {}

  return (
    <div className={classes(className, styles.container)}>
      <QuestionLabel label={'True or False'} />
      <div className={classes(styles.row, styles.questionLine)}>
        {correct && correct.assessmentLearnerAnswerId
          ? correct.isCorrect
            ? <Icon pathName={'checkmark0'} fillColor={'green'} premium={true}
              className={styles.icon} />
            : <Icon pathName={'cross_circle'} fillColor={'red'} premium={true}
              className={styles.icon} />
          : ''
        }
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.sequence)}>{question.sequence}.
        </div>
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.question)}>{question.questionText}</div>
      </div>
      <PointsDisplay className={styles.littleRight} correctControls={correctControls} pointsPossible={question.pointsPossible} score={score} />
      {question.questionRecordingFileUrl &&
        <AudioDisplay src={question.questionRecordingFileUrl} preload={'auto'}
          controls="controls" className={styles.audioLeftQuestion}
          isSmall={true} isOwner={question.isOwner}
          deleteFunction={(event) => removeQuestionRecordingOpen(event, question.assessmentQuestionId, question.questionRecordingFileUploadId)} />
      }
      {question.questionFileUploadId &&
        <ImageDisplay linkText={''} url={question.questionFileUrl}
          isOwner={question.isOwner}
          deleteFunction={() => removeQuestionFileOpen(question.assessmentQuestionId, question.questionFileUploadId)} />
      }
      <div className={styles.radioLeft}>
        <RadioGroupToggle
          name={nameKey}
          data={[
            {
              label: 'True',
              id: "true",
              correction: correct.isSubmitted
                ? correct.correctAnswer === "true"
                  ? "correct"
                  : correct.isCorrect
                    ? ""
                    : "wrong"
                : ''
            },
            {
              label: 'False',
              id: "false",
              correction: correct.isSubmitted
                ? correct.correctAnswer === "false"
                  ? "correct"
                  : correct.isCorrect
                    ? ""
                    : "wrong"
                : ''
            },
          ]}
          horizontal={true}
          titleClass={bigTextDisplay ? globalStyles.bigText : ''}
          labelClass={bigTextDisplay ? globalStyles.bigText : ''}
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.radio)}
          initialValue={initialValue || (question.learnerAnswer && question.learnerAnswer.learnerAnswer) || question.learnerAnswer} //This learnerAnswer could come from the object learnerAnswer or the corrected version 'learnerAnswer' as a string.
          personId={personId}
          onClick={onClick ? sendAssessmentAnswer : () => {
          }} />
      </div>
      {(question.solutionText || question.solutionFileUrl || question.solutionRecordingFileUrl) && (question.isOwner || (correct && correct.assessmentId)) &&
        <div className={styles.muchLeft}>
          <div className={styles.row}>
            <div className={styles.headerLabel}>Solution:</div>
            {!(correct && correct.assessmentId) &&
              <div className={globalStyles.instructions}>
                (After the quiz is corrected, this explanation or picture will be displayed)
              </div>
            }
          </div>
          <div className={styles.solutionText}>{question.solutionText}</div>
          {question.solutionRecordingFileUrl &&
            <AudioDisplay src={question.solutionRecordingFileUrl}
              preload={'auto'} controls="controls"
              className={styles.audioLeftQuestion}
              isSmall={true} isOwner={question.isOwner}
              deleteFunction={() => removeSolutionRecordingOpen(question.assessmentQuestionId, question.solutionRecordingFileUploadId)} />
          }
          {question.solutionFileUrl && question.solutionFileUploadId &&
            <ImageDisplay linkText={''} url={question.solutionFileUrl}
              isOwner={question.isOwner}
              deleteFunction={() => removeSolutionFileOpen(question.assessmentQuestionId, question.solutionFileUploadId)} />
          }
        </div>
      }
    </div>
  )
}


export default AssessmentTrueFalse