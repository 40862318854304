import React from 'react';
import styles from './DocumentStats.module.css';
import classes from 'classnames'
import DateMoment from '../DateMoment'

function DocumentStats(props) {
	const {
    darkerLabel,
    includeDates,
    includeEditors,
    isTranslation,
    labelWhite,
    maroonBackground,
    personId,
    work,
	} = props

	return (
    <div className={styles.rowTight}>
      <div title={'Word count'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>words</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.wordCount || work.workWordCount || '0'}</div>
      </div>
      <div title={'Sentence count'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>sentences</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.sentenceCount || work.workSentenceCount || '0'}</div>
      </div>
      {isTranslation &&
        <div title={'Translated sentences'} className={styles.row}>
          <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>translated</div>
          <div
            className={darkerLabel ? styles.countDark : styles.countWhite}>{(work.editsProcessed + work.editsPending) || '0'}</div>
        </div>
      }
      {isTranslation &&
        <div title={'Pending translated sentences'} className={styles.row}>
          <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>pending</div>
          <div
            className={darkerLabel ? styles.countDark : styles.countWhite}>{(work.sentenceCount - (work.editsProcessed + work.editsPending)) || '0'}</div>
        </div>
      }
      {!isTranslation &&
        <div title={'Edits processed'} className={styles.row}>
          <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>edits</div>
          <div
            className={darkerLabel ? styles.countDark : styles.countWhite}>{work.editsProcessed || work.editsPending || '0'}</div>
        </div>
      }
      {!isTranslation &&
        <div title={'Edits pending'} className={styles.row}>
          <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>pending</div>
          <div className={darkerLabel ? styles.countDark : styles.countWhite}>{work.editsPending || '0'}</div>
        </div>
      }
      {includeEditors &&
        <div title={'Editors'} className={classes(styles.row, styles.editors)}>
          <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>editors assigned</div>
          <div
            className={darkerLabel ? styles.countDark : styles.countWhite}>{(work.editorAssign && work.editorAssign.length) || '0'}</div>
        </div>
      }
      {work.activeDate && includeDates &&
        <div title={'Active date'} className={classes(styles.row, styles.editors)}>
          <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>active</div>
          <div className={darkerLabel ? styles.countDark : styles.countWhite}>
            {!work.activeDate ? '- -' :
              <DateMoment date={work.activeDate} label={''} format={'D MMM YYYY'} className={styles.date}
                dateTextClass={styles.dateTextClass} />}
          </div>
        </div>
      }
      {work.dueDate && includeDates &&
        <div title={'Due date'} className={classes(styles.row, styles.editors)}>
          <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>due</div>
          <div className={darkerLabel ? styles.countDark : styles.countWhite}>
            {!work.dueDate ? '- -' :
              <DateMoment date={work.dueDate} label={''} format={'D MMM YYYY'} className={styles.date}
                dateTextClass={styles.dateTextClass} />}
          </div>
        </div>
      }
      {work.originatingEditorPerson && work.originatingEditorPerson.fname &&
        <div>
          {work.originatingEditorPerson.personId !== personId &&
            <div className={styles.row}>
              <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Author:</div>
              <div className={darkerLabel ? styles.countDark : styles.countWhite}>
                {work.workOwners && work.workOwners.length > 0 && work.workOwners[0].fname + ' ' + work.workOwners[0].lname}
              </div>
            </div>
          }
          {work.originatingEditorPerson.personId === personId &&
            <div className={styles.row}>
              <div className={styles.row}>
                <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>
                  Originated by:
                </div>
                <div className={darkerLabel ? styles.countDark : styles.countWhite}>
                  {work.originatingEditorPerson.fname + ' ' + work.originatingEditorPerson.lname}
                </div>
              </div>
              <div className={styles.row}>
                <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Author:</div>
                <div className={darkerLabel ? styles.countDark : styles.countWhite}>
                  {work.workOwners && work.workOwners.length > 0 && work.workOwners[0].fname + ' ' + work.workOwners[0].lname}
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>	)
};

export default DocumentStats;