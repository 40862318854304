import React, {useState} from 'react';
import styles from './PeerGroupAddNewView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import InputText from '../../components/InputText';
import SelectSingleDropDown from '../../components/SelectSingleDropDown';
import TextDisplay from '../../components/TextDisplay';
import Icon from '../../components/Icon';
import OneFJefFooter from '../../components/OneFJefFooter';
import classes from 'classnames';
import * as guid from '../../utils/GuidValidate.js';

function PeerGroupAddNewView(props) {
    let {summary, subGroupCountOptions} = props;

    const [peerGroupName, setPeerGroupName] = useState() //props.peerGroupName)
    const [subGroupCount, setSubGroupCount] = useState() //props.subGroupCount ? props.subGroupCount : 1)
    const [peerGroupNameError, setPeerGroupNameError] = useState('')
    const [subGroupCountError, setSubGroupCountError] = useState('')
    const [notAssigned, setNotAssigned] = useState([])
    const [assignedSubGroup, setAssignedSubGroup] = useState(props.assignedSubGroup || [])
    const [unevenCount, setUnevenCount] = useState(0)
    const [memberToBeAssigned, setMemberToBeAssigned] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [unassignedMembers, setUnassignedMembers] = useState()

    const handleNameChange = (event) => {
        setPeerGroupName(event.target.value)
    }

    const handleMemberToBeAssigned = (event) => {
        setMemberToBeAssigned(event.target.value)
    }

    const handleAddUnassigned = (groupSequence) => {
        const {summary} = props;
        let subGroups = !!assignedSubGroup && assignedSubGroup.length > 0 && assignedSubGroup.map(g => {
            if (g.sequence === groupSequence) {
                g.members.unshift(summary.members.filter(p => p.personId === memberToBeAssigned.personId)[0])
            }
            return g;
        });

        setAssignedSubGroup(subGroups)
        getUnassignedOptions(subGroups);
    }

    const getUnassignedOptions = (subGroups) => {
        //We pass in the subGroups here right after the remove member so we can be sure to have the latest data and we don't have to wait
        const {summary} = props;

        let members = Object.assign([], summary.members);
        if (members && members.length > 0) {
            subGroups.length > 0 && subGroups.forEach(m => {
                m.members.length > 0 && m.members.forEach(p => {
                    members = members.filter(b => b.personId !== p.personId);
                });
            });
        }
        setUnassignedMembers(members)
        setMemberToBeAssigned(members[0])
    }

    const handleRemoveMember = (groupSequence, memberPersonId) => {
        //Go to the groupSequence and then splice out the memberPersonId
        let subGroups = !!assignedSubGroup && assignedSubGroup.length > 0 && assignedSubGroup.map(m => {
            if (m.sequence === groupSequence && m.members.length > 0) {
                return { ...m, members: m.members.filter(p => p.personId !== memberPersonId) };
            } else {
                return m;
            }
        });
        setAssignedSubGroup(subGroups)
        getUnassignedOptions(subGroups);
    }

    const handleSubGroupCreate = () => {
        const {summary} = props;
        let subGroups = [];
        let subGroup = {
            name: 1,
            sequence: 1,
            members: [],
        };
        let memberCount = summary && summary.members && summary.members.length;
        let membersPerGroup = memberCount / subGroupCount;
        let unevenCount = memberCount % subGroupCount;
        let newGroupCount = 0;
        let remainderDistributeIndex = 0;

        for(let i = 0; i < memberCount; i++) {
            //There is a good chance that the groups won't come out even.  Assign all of the extras at the end that exceed the exact division of members.
            if (newGroupCount === Math.floor(membersPerGroup) && subGroups.length < subGroupCount-1) {
                newGroupCount = 0;
                subGroups = subGroups ? subGroups.concat(subGroup) : [subGroup];
                subGroup = {
                    name: subGroups.length + 1,
                    sequence: subGroups.length + 1,
                    members: [],
                }
            }
            //If the loop index, i, is now greater than the membersPerGroup multiplied by the subGroupCount,
            //Then add each remainder member into the groups, starting from the beginning, 1.
            if (i >= Math.floor(membersPerGroup) * subGroupCount) {
                subGroups = subGroups.map((m, index) => {
                    if (index === remainderDistributeIndex) {
                        m.members.push(summary.members[i])
                    }
                    return m;
                });
                remainderDistributeIndex++;
            } else {
                subGroup.members = !!subGroup.members ? subGroup.members.concat(summary.members[i]) : [summary.members[i]];
            }
            newGroupCount++;
        }
        //We need to pick up the last one after the circulation of the final group.
        subGroups = subGroups ? subGroups.concat(subGroup) : [subGroup];
        setAssignedSubGroup(subGroups)
        setUnevenCount(unevenCount)
    }

    const handleSubGroupNameChange = (sequence, event) => {
        let newAssigned = !!assignedSubGroup && assignedSubGroup.map(m => {
            if (m.sequence === sequence) {
                m.name = event.target.value;
            }
            return m;
        })
        setAssignedSubGroup(newAssigned)
    }

    const handleSubGroupCountChange = (event) => {
        setSubGroupCount(event.target.value)
    }

    const handleSubmit = () => {
        const {addOrUpdatePeerGroup, personId, peerGroupId, summary} = props;
        let isValid = true;

        if (!peerGroupName) {
            setPeerGroupNameError("Please enter a peer group name")
            isValid = false;
        }

        if (!subGroupCount) {
            setSubGroupCountError("Please choose the number of sub groups that you want.")
            isValid = false;
        }
        let sendPeerGroupId = peerGroupId ? peerGroupId : guid.emptyGuid();
        isValid && addOrUpdatePeerGroup({groupId: summary.groupId, personId, peerGroupId: sendPeerGroupId, peerGroupName, subGroupCount}, assignedSubGroup);
        isValid && setIsSubmitted(true)
    }

      return (
        <div className={styles.container}>
            <form method="post" encType="multipart/form-data" id="my-awesome-dropzone" className={styles.form}>
                <div className={globalStyles.pageTitle}>
                    Create New Peer Group
                </div>
                <div className={styles.subTitle}>
                    {/* {summary.groupName} */}
                </div>
                <div className={styles.containerName}>
                    <InputText
                        value={peerGroupName}
                        size={"medium-long"}
                        name={"peerGroupName"}
                        label={"Peer group name"}
                        onChange={handleNameChange}
                        error={peerGroupNameError}/>

                    <TextDisplay label={'Group members'} text={summary && summary.members && summary.members.length} className={styles.textDisplay}/>

                    <div className={styles.marginTop}>
                        <SelectSingleDropDown
                            id={`subGroupCount`}
                            label={`Number of groups desired`}
                            value={subGroupCount}
                            options={subGroupCountOptions}
                            className={styles.singleDropDown}
                            noBlank={true}
                            error={subGroupCountError}
                            height={`medium-short`}
                            onChange={handleSubGroupCountChange} />
                    </div>
                </div>
                <hr />
                <div className={styles.row}>
                    <span onClick={handleSubGroupCreate} className={styles.button}>{!!assignedSubGroup && assignedSubGroup.length > 0 ? `Update Groups` : `Make Groups`}</span>
                    {!isSubmitted && <span onClick={!!assignedSubGroup && assignedSubGroup.length > 0 ? handleSubmit : () => {}} className={classes(styles.button, !!assignedSubGroup && assignedSubGroup.length > 0 ? styles.fullOpacity : styles.lowOpacity) }>{`Finish`}</span>}
                </div>
                <hr />
                {!!unevenCount &&
                    <div className={styles.extraMembers}>
                        {unevenCount === 1
                            ? 'The first group has one more member than the other groups'
                            : `The first ${unevenCount} groups have one more member than the other groups`}
                    </div>
                }
                {unassignedMembers && unassignedMembers.length > 0 &&
                    <div className={styles.unassignedMembers}>
                        {unassignedMembers.length === 1
                            ? 'There is one unassigned member'
                            : `There are ${unassignedMembers.length} unassigned members`}
                    </div>
                }
                {assignedSubGroup &&
                        !!assignedSubGroup && assignedSubGroup.map((m, i) =>
                            <div key={i}>
                                <InputText
                                    value={m.name}
                                    size={"medium"}
                                    name={m.sequence}
                                    label={"Sub group name"}
                                    onChange={(event) => handleSubGroupNameChange(m.sequence, event)}/>
                                {unassignedMembers && unassignedMembers.length > 0 &&
                                    <div className={classes(styles.row)}>
                                        <div>
                                            <SelectSingleDropDown
                                                id={`unassignedMembers`}
                                                label={`Unassigned members`}
                                                value={memberToBeAssigned}
                                                options={unassignedMembers}
                                                className={styles.singleDropDown}
                                                noBlank={true}
                                                height={`medium`}
                                                onChange={handleMemberToBeAssigned} />
                                        </div>
                                        <a onClick={() => handleAddUnassigned(m.sequence)}>
                                            <Icon pathName={`checkmark`} className={styles.imageTopMargin}/>
                                        </a>
                                    </div>
                                }
                                {m.members && m.members.length > 0 && m.members.map((d, ind) =>
                                    <div key={ind} className={styles.row}>
                                        <a onClick={() => handleRemoveMember(m.sequence, d.personId)}>
                                            <Icon pathName={`move_sentence`} className={styles.image}/>
                                        </a>
                                        <span className={styles.member}>{d.firstName + ' ' + d.lastName}</span>
                                    </div>
                                )}
                            </div>
                        )
                }
                <OneFJefFooter />
            </form>
        </div>
    )
};

//    djsConfig={djsConfig} />
export default PeerGroupAddNewView