const backgroundGradients = [{
	rgb: '#990000',
	name: 'maroon',
	gradient: "linear-gradient(90deg, rgba(153,0,0,1) 0%, rgba(255,255,255,1) 100%)",
	reverse: "linear-gradient(270deg, rgba(153,0,0,1) 0%, rgba(255,255,255,1) 100%)",
},
	{
		rgb: '#6600ff',
		name: 'blue medium',
		gradient: "linear-gradient(90deg, rgb(102,0,255,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(102,0,255,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#33cccc',
		name: 'turquoise',
		gradient: "linear-gradient(90deg, rgb(51,204,204,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(51,204,204,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#ff6666',
		name: 'salmon',
		gradient: "linear-gradient(90deg, rgb(255,102,102,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(255,102,102,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#cc9900',
		name: 'mustard',
		gradient: "linear-gradient(90deg, rgb(204,153,0,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(204,153,0,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#00ff00',
		name: 'green light',
		gradient: "linear-gradient(90deg, rgb(0,255,0,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(0,255,0,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#ff0000',
		name: 'red',
		gradient: "linear-gradient(90deg, rgb(255,0,0,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(255,0,0,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#006600',
		name: 'green medium',
		gradient: "linear-gradient(90deg, rgb(0,102,0,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(0,102,0,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#993300',
		name: 'brown medium',
		gradient: "linear-gradient(90deg, rgb(153,51,0,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(153,51,0,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#ffff00',
		name: 'yellow',
		gradient: "linear-gradient(90deg, rgb(255,255,0,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(255,255,0,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#ff6600',
		name: 'orange',
		gradient: "linear-gradient(90deg, rgb(255,102,0,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(255,102,0,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#ff0099',
		name: 'pink hot',
		gradient: "linear-gradient(90deg, rgb(255,0,153,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(255,0,153,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#ccff00',
		name: 'green neon',
		gradient: "linear-gradient(90deg, rgb(204,255,0,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(204,255,0,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#990099',
		name: 'purple',
		gradient: "linear-gradient(90deg, rgb(153,0,153,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(153,0,153,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#6699ff',
		name: 'blue light',
		gradient: "linear-gradient(90deg, rgb(102,153,255,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(102,153,255,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#cc0066',
		name: 'pink dark',
		gradient: "linear-gradient(90deg, rgb(204,0,102,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(204,0,102,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#cc3300',
		name: 'brown light',
		gradient: "linear-gradient(90deg, rgb(204,51,0,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(204,51,0,1) 0%, rgba(255,255,255,1) 100%)",
	},
	{
		rgb: '#330099',
		name: 'blue navy',
		gradient: "linear-gradient(90deg, rgb(51,0,153,1) 0%, rgba(255,255,255,1) 100%)",
		reverse: "linear-gradient(270deg, rgb(51,0,153,1) 0%, rgba(255,255,255,1) 100%)",
	},
]

export default backgroundGradients