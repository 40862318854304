import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router'
import styles from './WorkFolderExplorerOptions.module.css';
import MessageModal from '../MessageModal';
import ToastInputText from '../ToastInputText';
import ToastDeleteFolder from '../ToastDeleteFolder';
import Icon from '../Icon';
import Target from '../../assets/Inline Icons/move-target.svg'
import {guidEmpty} from '../../utils/GuidValidate'
import classes from 'classnames'

function WorkFolderExplorerOptions(props) {
  const {
    personId,
    mineOrOthers,
    isMoveWorkId,
    moveWorkToFolder,
    isCopyWorkId,
    copyWorkToFolder,
    thisWorkFolderId,
    thisWorkFolderName,
    group,
    isOriginatingEditorFolder,
    setEditWorkFolder,
    setOpened
  } = props;

  const navigate = useNavigate()
  const iconColor = 'white'
  const [enterFolderTitle, setEnterFolderTitle] = useState(false);
  const [showDeleteFolderWarning, setShowDeleteFolderWarning] = useState(false);
  const [showDeleteGroupMemberWarning, setShowDeleteGroupMemberWarning] = useState(false);
  const [showDeleteEditorFolderWarning, setShowDeleteEditorFolderWarning] = useState(false);
  const [isShowingModal_chooseWork, setIsShowingModal_chooseWork] = useState(false);
  const [showGroupAccessMessage, setShowGroupAccessMessage] = useState(false);
  const [showFolderGroupMessage, setShowFolderGroupMessage] = useState(false);
  const [hasFolderGroup, setHasFolderGroup] = useState(false);

  useEffect(() => {
      setHasFolderGroup(findHasFolderGroupHierarchy(props.fullFileTreeExplorer, false))
  }, [props.fullFileTreeExplorer])

  const findHasFolderGroupHierarchy = (folders, foundGroupInHierarchy = false) => {
    return folders && folders.length > 0 && folders.some(folder => {
      const currentFolderHasGroup = folder.group && folder.group.groupId && folder.group.groupId !== guidEmpty;
      if (currentFolderHasGroup || foundGroupInHierarchy) {
        if (folder.workFolderId === thisWorkFolderId) return true;
        if (folder.subFolders) {
          return findHasFolderGroupHierarchy(folder.subFolders, true);
        }
      } else {
        if (folder.subFolders) {
          return findHasFolderGroupHierarchy(folder.subFolders, false);
        }
      }
    });
  };


  const submitAddWorkFolder = (folderTitle) => {
    props.addWorkFolder(personId, thisWorkFolderId, folderTitle, mineOrOthers)
    setEnterFolderTitle(false)
  }

  const submitDeleteFolder = () => {
    const checkboxElement = document.getElementById('deleteFilesAlso')
    props.deleteWorkFolder(personId, thisWorkFolderId, checkboxElement && checkboxElement.checked)
    setShowDeleteFolderWarning(false)
  }
  
  const submitDeleteGroupMember = () => {
    props.removeMemberByWorkFolderId(personId, thisWorkFolderId)
    setShowDeleteGroupMemberWarning(false)
  }
  
  const submitDeleteEditorFolder = () => {
    const authorPersonId = props.fileTreeExplorer[0].otherPersonId
    props.removeEditorByAuthor(personId, authorPersonId, thisWorkFolderId)
    setShowDeleteEditorFolderWarning(false)
  }

  const sendToEditReport = () => {
    navigate(`/workEditReport/folder/${thisWorkFolderId}`)
  }

  const sendToAccessWorks = () => {
    //if (group && group.groupName) {
    if (hasFolderGroup) {
      setShowGroupAccessMessage(true)
    } else {
      navigate(`/giveAccessToWorks/folder/${thisWorkFolderId}`)
    }
  }

  const handleChooseWorkClose = () => setIsShowingModal_chooseWork(false)

  // const hasGroupInHierarchy = () > {
  //   let hasGroup = false
  //   fileTreeExplorer && fileTreeExplorer.length > 0 && fileTreeExplorer.forEach(m => {

  //   })
  //   return hasGroup
  // }
  const displayGroupAction = () => {
    if (mineOrOthers === 'Mine') {
      if (group && group.groupName) {
        const directNavigation = props.groups?.length > 0 ? `/groupChooseExisting/${thisWorkFolderId}/${group && group.groupId}` : `/groupAddNewByWorkFolder/${guidEmpty}`
        return (
          <a onClick={mineOrOthers === 'Mine' ? () => navigate() : (directNavigation) => {}} title={'View or modify this group'} className={styles.editGroupIcon}>
            <Icon pathName={'users2'} premium={true} superscript={'pencil'} supFillColor={'white'} fillColor={'white'} />
          </a>
        )
      } else {
        const directNavigation = props.groups?.length > 0 ? `/groupChooseExisting/${thisWorkFolderId}` : `/groupAddNewByWorkFolder/${guidEmpty}`
        return (
          <div className={styles.rowRightSpace}>
            <a onClick={mineOrOthers === 'Mine' ? hasFolderGroup ? () => setShowFolderGroupMessage(true) : () => navigate(directNavigation) : () => { }} title={'Choose an existing group for this folder'}>
              <Icon pathName={'users2'} premium={true} superscript={'plus'} supFillColor={'white'} fillColor={'white'} />
            </a>
          </div>
        )
      }
    }
  }

  return (
    <div>
      <div className={styles.container}>
        {thisWorkFolderId !== guidEmpty &&
          <a onClick={() => {
                mineOrOthers === 'Mine' ? setShowDeleteFolderWarning(true) : group && group.groupId ? setShowDeleteGroupMemberWarning(true) : setShowDeleteEditorFolderWarning(true);
                //setOpened(false);
              }}
             title={'Delete this folder'} data-testid={'linkDeleteFolder'}
             className={classes(styles.addNewFolderIcon)}>
            <Icon pathName={'folder_only'} premium={true} superscript={'minus'} supFillColor={'red'}
                  fillColor={iconColor}/>
          </a>
        }
        {!isOriginatingEditorFolder && mineOrOthers === 'Mine' && thisWorkFolderId !== guidEmpty &&
          <a onClick={() => { setEditWorkFolder({ workFolderId: thisWorkFolderId, workFolderName: thisWorkFolderName }); setOpened(false)}}
            title={'Edit folder name'}
            className={classes(styles.addNewFolderIcon)}>
            <Icon pathName={'folder_only'} premium={true} superscript={'pencil'} supFillColor={'white'}
              fillColor={iconColor} />
          </a>
        }
        {!isOriginatingEditorFolder && mineOrOthers === 'Mine' && thisWorkFolderId !== guidEmpty &&
          <a onClick={() => { setEnterFolderTitle(true); setOpened(false); }}
             title={'Add new folder'} data-testid={'linkAddFolder'}
             className={classes(styles.addNewFolderIcon)}>
            <Icon pathName={'folder_only'} premium={true} superscript={'plus'} supFillColor={iconColor}
                  fillColor={iconColor}/>
          </a>
        }
        {mineOrOthers === 'Mine' &&
          <a onClick={hasFolderGroup ? () => setShowGroupAccessMessage(true) : sendToAccessWorks}
             title={`Give access to editors for this folder`}
             className={classes(styles.menuItem)}>
            <Icon pathName={'share'} premium={true} fillColor={iconColor}/>
          </a>
        }
        <a onClick={sendToEditReport}
           title={`Editor reports for this folder`}
           className={classes(styles.menuItem)}>
          <Icon pathName={'graph_report'} premium={true} fillColor={iconColor}/>
        </a>
        {!isOriginatingEditorFolder && mineOrOthers === 'Mine' && isMoveWorkId &&
          <a onClick={() => { props.toggleExpandedLocal(thisWorkFolderId, thisWorkFolderName, true); moveWorkToFolder('end', thisWorkFolderId);}}
               className={styles.menuItem}
               title={'Move document to this folder'}>
            <img src={Target} alt={'Target'} height={19} width={20}/>
          </a>
        }
        {!isOriginatingEditorFolder && mineOrOthers === 'Mine' && isCopyWorkId &&
          <a onClick={() => { props.toggleExpandedLocal(thisWorkFolderId, thisWorkFolderName, true); copyWorkToFolder('end', thisWorkFolderId); }}
            className={styles.menuItem}
            title={'Copy document to this folder'}>
            <img src={Target} alt={'Target'} height={19} width={20} />
          </a>
        }
        {displayGroupAction()}
        {!isOriginatingEditorFolder && mineOrOthers === 'Mine' &&
          <div onClick={() => { props.toggleExpandedLocal(thisWorkFolderId, thisWorkFolderName, true); navigate(`/workAddNew/folder/${thisWorkFolderId}`); }}
            title={'Add new document to this folder'} data-testid={'divAddDocument'}
            className={classes(styles.menuItem)}>
            <Icon pathName={'document0'} premium={true} superscript={'plus'} supFillColor={iconColor}
              fillColor={iconColor} />
          </div>
        }
      </div>
      <MessageModal 
        show={showDeleteGroupMemberWarning} 
        handleClose={() => setShowDeleteGroupMemberWarning(false)} 
        heading={`Remove me from this group?`}
        explain={`Are you sure you want to be removed<br/>from this group?`}
        isConfirmType={true} 
        onClick={submitDeleteGroupMember} />
      <MessageModal 
        show={showDeleteEditorFolderWarning} 
        handleClose={() => setShowDeleteEditorFolderWarning(false)} 
        heading={`Remove me as an invited editor?`}
        explain={`Are you sure you want to be removed<br/>as an invited editor from this folder?`}
        isConfirmType={true} 
        onClick={submitDeleteEditorFolder} />
      <ToastInputText 
        show={enterFolderTitle} 
        label={`Enter Folder Title`} 
        message={`New Folder`}
        onClose={() => setEnterFolderTitle(false)}
        onSubmit={submitAddWorkFolder} />
      <ToastDeleteFolder
        show={showDeleteFolderWarning}
        onClose={() => setShowDeleteFolderWarning(false)}
        onSubmit={submitDeleteFolder} />
      <MessageModal 
        show={isShowingModal_chooseWork} 
        handleClose={handleChooseWorkClose} 
        heading={`Choose a document`}
        explain={`Click on a document name and then the<br/>tools will become available for use.`}
        onClick={handleChooseWorkClose}/>
      <MessageModal 
        show={showGroupAccessMessage} 
        handleClose={() => setShowGroupAccessMessage(false)} 
        heading={`Group Document Access`}
        explain={`Access to documents in a group folder are already<br/>available to members of the group.<br/><br/>However, documents in a non-group folder must be<br/>assigned directly to an individual.`}
        onClick={() => setShowGroupAccessMessage(false)}/>
      <MessageModal 
        show={showFolderGroupMessage} 
        handleClose={() => setShowFolderGroupMessage(false)} 
        heading={`Has Group Assigned Already`}
        explain={`There is a group assigned to this folder<br/>either directly or in a parent folder. `}
        onClick={() => setShowFolderGroupMessage(false)} />
    </div>
  )
}

export default WorkFolderExplorerOptions;

