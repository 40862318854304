import React, {Component} from 'react';
import GroupTypeChoiceView from '../views/GroupTypeChoiceView';
import { connect } from 'react-redux';

import { selectMe, selectGroupTypes } from '../store.js';

// takes values from the redux store and maps them to props
const mapStateToProps = state => {
    let me = selectMe(state);

    return {
        personId: me.personId,
        groupTypes: selectGroupTypes(state),
    }
};

// binds the result of action creators to redux dispatch, wrapped in callable functions
// const bindActionsToDispatch = dispatch => ({
// });

const storeConnector = connect(
  mapStateToProps,
//  bindActionsToDispatch,
);

class Container extends Component {

  componentDidMount() {
  }

  render() {
    return <GroupTypeChoiceView {...this.props} />
  }
}

export default storeConnector(Container);
