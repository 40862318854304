import React from 'react';  //PropTypes
import styles from './EditReviewEditIconLegend.module.css';
import Icon from '../Icon';
import classes from 'classnames';

export default ({isAuthor}) => {
    return (
        <table className={styles.container}>
            <tbody>
            <tr>
                <td>
                    <Icon pathName={`comment_text`} premium={true} className={styles.image} />
                </td>
                <td>
                    <strong>Comment.</strong> This comment will belong to a sentence.  A sentence must be chosen in order to assign a comment to that sentence.
                </td>
            </tr>
            <tr>
                <td>
                    <Icon pathName={`thumbs_up0`} premium={true} className={styles.image}/>
                </td>
                <td>
                    <strong>Accept and choose this edit.</strong>  You can edit it further.  This will score this edit favorably.
                    {isAuthor &&
                        <span>{`All other edits which haven't been deleted (rejected) will be scored minimally for effort.`}</span>
                    }
                </td>
            </tr>
            <tr>
                <td>
                    <Icon pathName={`thumbs_down0`} premium={true} className={styles.image}/>
                </td>
                <td>
                    <strong>Disagree with this edit.</strong> {`But use this sparingly since this decreases the user's score.  Please, be courteous.`}
                </td>
            </tr>
            <tr>
                <td>
                    <div className={classes(styles.row, styles.moreLeft)}>
                        <Icon pathName={`blocked`} fillColor={'red'} className={styles.imageBlocked}/>
                        <Icon pathName={`user_minus0`} premium={true} className={styles.imageOverlay}/>
                    </div>
                </td>
                <td>
                    <strong>Troll!</strong> Use this tool to mark the entry as obnoxious or destructive.  The author ought to take away access for this editor.
                </td>
            </tr>
            <tr>
                <td>
                    <Icon pathName={`undo2`} premium={true} className={styles.image}/>
                </td>
                <td>
                    <strong>Undo.</strong>  {`You can delete your own edits or, if you are the author, you can delete others' edits.`}
                </td>
            </tr>
            <tr>
                <td>
                    <div className={styles.row}>
                        <Icon pathName={`document0`} premium={true} className={styles.imageDocument}/>
                        <Icon pathName={`magnifier`} premium={true} className={styles.imageMagnifier}/>
                    </div>
                </td>
                <td>
                    <strong>{`See this editor's full version.`}</strong> This will return you to the main screen and automatically choose the tab where you can read their full version with their edits marked.
                </td>
            </tr>
            {/*<tr>*/}
            {/*    <td>*/}
            {/*        <div className={styles.linkStyle}>*/}
            {/*            Restore*/}
            {/*        </div>*/}
            {/*    </td>*/}
            {/*    <td>*/}
            {/*        <strong>Restore</strong>  {`This is an option for an edit that has already been accepted or rejected.  It is showing up because your settings indicate that you want to view this sentence's history.  You can click on 'restore' in order to have a chance to accept it or edit it further.`}*/}
            {/*    </td>*/}
            {/*</tr>*/}
            </tbody>
        </table>
    )
}
