import React from 'react';
import { connect } from 'react-redux';
import AppView from '../views/AppView';
import * as actionWorks from '../actions/works.js';
import * as actionLogin from '../actions/login.js';
import * as actionChapters from '../actions/chapters.js';
import * as actionIPAddress from '../actions/ip-address.js';
import * as actionGroups from '../actions/groups.js';
import * as actionPersonConfig from '../actions/person-config.js';
import * as actionEditorInvitePending from '../actions/editor-invite-pending.js';
import { selectMe, selectWorkSummaryCurrent, selectFetchingRecord, selectGroupIdCurrent, selectGroups, selectPersonConfig,
	selectEditorInvitePending, selectWorkIdCurrent } from '../store.js';

function mapStateToProps(state) {
	const me = selectMe(state)
	const workSummary = selectWorkSummaryCurrent(state)
	const fetchingRecord = selectFetchingRecord(state)
	let groupId = selectGroupIdCurrent(state)
	let group = selectGroups(state) && selectGroups(state).length > 0 && selectGroups(state).filter(m => m.groupId === groupId)[0]

	return {
		isLoggedIn: state.loggedIn,
		redirectUrl: state.redirectUrl,
		personId: me && me.personId,
		personName: me && me.fname + ' ' + me.lname,
		firstName:  me && me.fname,
		editorScore: me && me.editorScore,
		workSummary,
		groupSummary: group,
		groupSummaries: selectGroups(state),
		isEmpty: selectWorkIdCurrent(state),
		fetchingRecord,
		personConfig: selectPersonConfig(state),
		editorInvitePending: selectEditorInvitePending(state),
	}
}

const bindActionsToDispatch = dispatch => ({
	setWorkCurrentSelected: (personId, workId, chapterId, languageId, goToPage) => dispatch(actionWorks.setWorkCurrentSelected(personId, workId, chapterId, languageId, goToPage)),
	deleteWork: (personId, workId) => dispatch(actionWorks.deleteWork(personId, workId)),
	updateChapterDueDate: (personId, workId, chapterId, languageId, dueDate) => dispatch(actionChapters.updateChapterDueDate(personId, workId, chapterId, languageId, dueDate)),
	updateChapterComment: (personId, workId, chapterId, comment) => dispatch(actionChapters.updateChapterComment(personId, workId, chapterId, comment)),
	getIpAddress: () => dispatch(actionIPAddress.getIpAddress()),
	logout: () => dispatch(actionLogin.logout()),
	setGroupCurrentSelected: (personId, groupId, masterWorkId, memberWorkId, goToPage) => dispatch(actionGroups.setGroupCurrentSelected(personId, groupId, masterWorkId, memberWorkId, goToPage)),
	deleteGroup: (personId, groupId) => dispatch(actionGroups.deleteGroup(personId, groupId)),
	updatePersonConfig: (personId, field, value)  => dispatch(actionPersonConfig.updatePersonConfig(personId, field, value)),
	deleteInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.deleteInvite(personId, friendInvitationId)),
	acceptInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.acceptInvite(personId, friendInvitationId)),
	resendInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.resendInvite(personId, friendInvitationId)),
});

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
);

function App(props) {
	return <AppView {...props} />
}

export default storeConnector(App);
