const styleWordImport = 

  `.awlist1 {
      list-style: none;
      counter-reset: awlistcounter1
    }

    .awlist1>li:before {
      content: '1.'counter(awlistcounter1) '.';
      counter-increment: awlistcounter1;
      margin-right: 10px; /* Adds space between the marker and the text */
      width: 1.5em; /* Adjusts spacing uniformly */
    }

    .awlist2 {
      list-style: none;
      counter-reset: awlistcounter2
    }

      .awlist2>li:before {
      content: '1.1.'counter(awlistcounter2) '.';
      counter-increment: awlistcounter2;
      margin-right: 10px; /* Adds space between the marker and the text */
      width: 1.5em; /* Adjusts spacing uniformly */
    }

    .awlist3 {
      list-style: none;
      counter-reset: awlistcounter3
    }

      .awlist3>li:before {
      content: '1.1.1.'counter(awlistcounter3) '.';
      counter-increment: awlistcounter3;
      margin-right: 10px; /* Adds space between the marker and the text */
      width: 1.5em; /* Adjusts spacing uniformly */
    }

    .awlist4 {
      list-style: none;
      counter-reset: awlistcounter4
    }

      .awlist4>li:before {
      content: '1.1.1.1.'counter(awlistcounter4) '.';
      counter-increment: awlistcounter4;
      margin-right: 10px; /* Adds space between the marker and the text */
      width: 1.5em; /* Adjusts spacing uniformly */
    }

    .awlist5 {
      list-style: none;
      counter-reset: awlistcounter5
    }

      .awlist5>li:before {
      content: '1.1.1.1.1.'counter(awlistcounter5) '.';
      counter-increment: awlistcounter5;
      margin-right: 10px; /* Adds space between the marker and the text */
      width: 1.5em; /* Adjusts spacing uniformly */
    }

    .awlist6 {
      list-style: none;
      counter-reset: awlistcounter6
    }

      .awlist6>li:before {
      content: '1.1.1.1.1.1.'counter(awlistcounter6) '.';
      counter-increment: awlistcounter6;
      margin-right: 10px; /* Adds space between the marker and the text */
      width: 1.5em; /* Adjusts spacing uniformly */
    }

    .awlist7 {
      list-style: none;
      counter-reset: awlistcounter7
    }

      .awlist7>li:before {
      content: '1.1.1.1.1.1.1.'counter(awlistcounter7) '.';
      counter-increment: awlistcounter7;
      margin-right: 10px; /* Adds space between the marker and the text */
      width: 1.5em; /* Adjusts spacing uniformly */
    }

    .awlist8 {
      list-style: none;
      counter-reset: awlistcounter8
    }

      .awlist8>li:before {
      content: '1.1.1.1.1.1.1.1.'counter(awlistcounter8) '.';
      counter-increment: awlistcounter8;
      margin-right: 10px; /* Adds space between the marker and the text */
      width: 1.5em; /* Adjusts spacing uniformly */
    }

    .awlist9 {
      list-style: none;
      counter-reset: awlistcounter9
    }

      .awlist9>li:before {
      content: '2.'counter(awlistcounter9) '.';
      counter-increment: awlistcounter9;
      margin-right: 10px; /* Adds space between the marker and the text */
      width: 1.5em; /* Adjusts spacing uniformly */
    }`


export default styleWordImport