import React, {Component} from 'react';
import ForgotUsernameView from '../views/LoginView/ForgotUsernameView.js';
import { connect } from 'react-redux';
import * as loginUser from '../actions/login.js';
import {selectMe} from '../store.js';

const mapStateToProps = state => {
    return {
        loginData: selectMe(state)
    }
};

const bindActionsToDispatch = dispatch => ({
    forgotUsername: (emailAddress, phone) => dispatch(loginUser.forgotUsername(emailAddress, phone)),
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
);

class Container extends Component {
    render() {
        return <ForgotUsernameView {...this.props} />
    }
}

export default storeConnector(Container);
