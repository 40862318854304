import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router';
import styles from './WorkUploadFileView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import ProgressModal from '../../components/ProgressModal';
import TextDisplay from '../../components/TextDisplay';
import DropZone from 'react-dropzone';
import {apiHost} from '../../api_host.js';
import OneFJefFooter from '../../components/OneFJefFooter';
import * as guid from '../../utils/GuidValidate.js';

function WorkUploadFileView(props) {
  let {workSummary={workName: 'test', workId: '987234', nativeLanguageId: 1}, textProcessingProgress} = props;
  const navigate = useNavigate()
  const [file, setFile] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isFileChosen, setIsFileChosen] = useState(false)
  const [isShowingProgress, setIsShowingProgress] = useState(false)
  const [timerId, setTimerId] = useState(null)

  let dropzone = null;

  const handleFileAdded = (file) => {
    setFile(file)
    setIsFileChosen(true)
  }

  const buildURL = () => {
    const {personId, workSummary, getTextProcessingProgress} = props;
    let emptyGuid = guid.emptyGuid;
    setIsShowingProgress(true)
    setTimerId(setInterval(() => getTextProcessingProgress(personId), 1000))

    //This is obsolete
    return `${apiHost}ebi/work/upload/${personId}/${workSummary.workId}/${emptyGuid}/${workSummary.nativeLanguageId}/false`;
  }

  // For a list of all possible events (there are many), see README.md!
  const eventHandlers = {
    init: dz => dropzone = dz,
    addedfile: handleFileAdded
  }

  let djsConfig = {
        addRemoveLinks: true,
        acceptedFiles: ".txt, .rtf, .html, .htm, .docx",
        autoProcessQueue: false,
        maxFiles: 1,
        paramName: "ebiFile",
    };

  let config = {
      iconFiletypes: ['txt', 'rtf', 'html', 'htm', 'docx'],
      showFiletypeIcon: false,
      postUrl: buildURL,
      method: 'post',
      paramName: "file", // The name that will be used to transfer the file
      accept: function(file, done) {
        if (file.name === "justinbieber.jpg") {
          done("Naha, you don't.");
        }
        else { done(); }
      }
      //uploadMultiple: false,
  };

  useEffect(() => {
    const {getWorkList, personId, setBlankTextProcessingProgress} = props;
    props.setBlankTextProcessingProgress(props.personId);
    return () => {
      getWorkList(personId);
      clearInterval(timerId);
      setBlankTextProcessingProgress(personId);
    }
  }, [])

  useEffect(() => {
      const {textProcessingProgress} = props;
      if (textProcessingProgress && textProcessingProgress.allDoneFlag) {
          handleProgressClose();
      }
  }, [props.textProcessingProgress])

  const handleProgressClose = () => {
      const {setBlankTextProcessingProgress, personId} = props;
      setIsShowingProgress(false)
      clearInterval(timerId);
      setBlankTextProcessingProgress(personId);
      navigate("/workNewAfterNav");
  }

  const handleSubmit = () => {
    dropzone.processQueue();
  }

  return (
    <div className={styles.container}>
        <form method="post" encType="multipart/form-data" id="my-awesome-dropzone" className={styles.form}>
            <div className={globalStyles.pageTitle}>
                {`Upload a File`}
            </div>
            <div className={styles.containerName}>
                <TextDisplay label={`Document name`} text={workSummary.workName} />
            </div>
            <hr />
            <div className={styles.explanation}>
                Click on the box below to browse for a file, or drag-and-drop a file into the box:
            </div>
            <DropZone config={config} eventHandlers={eventHandlers} djsConfig={djsConfig} className={styles.dropZone}/>
            <div className={styles.buttonDiv}>
                {isFileChosen &&
                    <a onClick={handleSubmit} className={styles.buttonStyle}>{`Upload`}</a>
                }
            </div>
        </form>
        {isShowingProgress &&
            <ProgressModal handleClose={handleProgressClose} heading={`Text Processing Progress`}
                progress={textProcessingProgress}/>
        }
        <OneFJefFooter />
    </div>
  )
}

//    djsConfig={djsConfig} />
//<span onClick={isSubmitted ? handleProgressOpen : () => {}} className={classes(styles.buttonStyle, !isSubmitted ? styles.disabled : '')}>{`Next ->`}</span>

export default WorkUploadFileView