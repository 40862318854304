import React, {useState} from 'react';
import styles from './OpenCommunityCommitted.module.css';
import MessageModal from '../../components/MessageModal';
//import WorkSummary from '../../components/WorkSummary';
import Accordion from '../../components/Accordion';

function OpenCommunityCommitted(props) {
  let {personId, setWorkCurrentSelected, updateChapterComment, openCommunityFull, uncommitOpenCommunityEntry, openCommunityEntry} = props;

  const [isShowingModal_uncommit, setIsShowingModal_uncommit] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(0)  //Is deleteIndex a props value rather than local?
  const [selectedLanguages, setSelectedLanguages] = useState()
  const [nativeLanguageEdit, setNativeLanguageEdit] = useState()

  let localOpenCommunity = openCommunityFull && openCommunityFull.length > 0 && openCommunityFull.filter(m => m.hasCommittedOpenCommunity);

  const handleUncommitClick = () => {
      uncommitOpenCommunityEntry(openCommunityEntry.personId, openCommunityEntry.openCommunityEntryId);
      handleUncommitAlertClose();
      setSelectedLanguages()
      setNativeLanguageEdit(false)
  }

  const handleUncommitAlertClose = () => setIsShowingModal_uncommit(false)
  const handleUncommitAlertOpen = () => setIsShowingModal_uncommit(true)


  return (
    <div className={styles.container}>
        {!localOpenCommunity || localOpenCommunity.length === 0 ? <span className={styles.noListMessage}>{`empty list`}<br/><br/></span> : ''}
        {localOpenCommunity && localOpenCommunity.length > 0 && localOpenCommunity.map((s, i) => {
          return (
            <Accordion title={s.title} key={i}>
                    {/* //    isCurrentTitle={s.isCurrentWork} expanded={s.isExpanded} key={i} showCommitted={true}
                    // className={styles.accordionTitle} uncommitOpenCommunityEntry={uncommitOpenCommunityEntry} openCommunityEntry={s}
                    // onTitleClick={() => setWorkCurrentSelected(personId, s.workId, s.chapterId_current, s.languageId_current, 'editReview')}> */}
                {/* <WorkSummary summary={s} className={styles.workSummary} showIcons={true} personId={personId}
                    setWorkCurrentSelected={setWorkCurrentSelected} showTitle={false} noShowCurrent={true}
                    labelCurrentClass={styles.labelCurrentClass} indexKey={i}
                    updateChapterComment={updateChapterComment}/> */}
                <MessageModal show={isShowingModal_uncommit && deleteIndex === i}
                              key={i*1000} handleClose={handleUncommitAlertClose} heading={`Discontinue Editing this Document?`}
                              explain={`Are you sure you want to discontinue editing this document?`} isConfirmType={true}
                              onClick={handleUncommitClick}/>
            </Accordion>
            );
        })}
    </div>
  );
}

export default OpenCommunityCommitted