import React, {useState} from 'react'
import { useNavigate } from 'react-router'
import globalStyles from '../../utils/globalStyles.module.css';
import styles from './TextStyleSettingView.module.css'
import MessageModal from '../../components/MessageModal'
import TextStyleSettingInputControls from '../../components/TextStyleSettingInputControls'
import TextStyleSettingGroupTable from '../../components/TextStyleSettingGroupTable'
import InputText from '../../components/InputText'
import ButtonWithIcon from '../../components/ButtonWithIcon'
import { useMediaQuery } from "react-responsive"
import { createInfoToastAuto } from '../../services/queryClient'

function TextStyleGroup(props) {
  const navigate = useNavigate()
  const { textStyleGroups, setTextStyleGroup, deleteTextStyleGroup, fontOptions, fontSizes, lineHeights, textAlign, bottomSpace, textIndent } = props
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [textStyleGroupName, setTextStyleGroupName] = useState([])
  const [deleteTextStyleGroupId, setDeleteTextStyleGroupId] = useState()
  const [editTextStyleGroupId, setEditTextStyleGroupId] = useState()
  const [settings, setSettings] = useState({})
  const [submitSuccess, setSubmitSuccess] = useState(false)
  

  const handleDeleteTextStyleGroup = () => {
    deleteTextStyleGroup(deleteTextStyleGroupId)
    setDeleteTextStyleGroupId('')
  }

  const processForm = (event) => {
    event.stopPropagation()
    event.preventDefault()
    let missingFields = ''

    if (!settings.fontFamily) missingFields += "\nFont-family"
    if (!settings.fontSize) missingFields += "\nFont-size"
    if (!settings.lineHeight) missingFields += "\nLine-height"
    if (!settings.textAlign) missingFields += "\nText-align"
    if (!settings.marginBottom) missingFields += "\nParagragh-height"
    if (!settings.textIndent) missingFields += "\nParagraph-indent"
    if (missingFields) {
      createInfoToastAuto(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`, 'red')

    } else {
      setTextStyleGroup(settings, textStyleGroupName, editTextStyleGroupId)
      setEditTextStyleGroupId()
      setTextStyleGroupName('')
      setSettings()
      setSubmitSuccess(true)
    }
  }

  const resetSettings = () => {
    setEditTextStyleGroupId()
    setTextStyleGroupName('')
    setSettings()
  }

  const handleSetEditSettings = (settings) => {
    let convertSettings = settings.entries.reduce((acc,m) => {
      let code = m.code.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())
      acc = { ...acc, [code]: m.value }
      return acc
    }, {})
    setSettings(convertSettings)
    setEditTextStyleGroupId(settings.group.textStyleGroupId)
    setTextStyleGroupName(settings.group.name)
    setSubmitSuccess(false)
  }

  const handleSetting = (event) => {
    const value = event.target.value
    const name = event.target.id
    setSettings({...settings, [name]: value})
  }

  return (
		<div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={globalStyles.pageTitle}>
          Text Style Settings
        </div>
        <div className={styles.background}>
          <TextStyleSettingGroupTable 
            isMobile={isMobile}
            hideEdit={false}
            textStyleGroups={textStyleGroups}
            handleSetEditSettings={handleSetEditSettings}
            setDeleteTextStyleGroupId={setDeleteTextStyleGroupId} />
          <hr/>
          <div className={styles.titleCustomStyle}>Add a custom text style group</div>
          <div>
            <InputText
              label={"Style settings group name"}
              value={textStyleGroupName}
              inputClassName={styles.textWhite}
              size={"medium-long"}
              name={"textStyleGroupName"}
              onChange={(event) => setTextStyleGroupName(event.target.value)} />
          </div>
          <TextStyleSettingInputControls 
            settings={settings}
            handleSetting={handleSetting}
            fontOptions={fontOptions}
            fontSizes={fontSizes}
            lineHeights={lineHeights}
            textAlign={textAlign}
            bottomSpace={bottomSpace}
            textIndent={textIndent} />

          <div className={styles.buttonsCenter}>
            <div className={styles.buttonPosition}>
              <span className={styles.cancelButton} onClick={resetSettings}>
                Clear
              </span>
              <span className={styles.cancelButton} onClick={() => navigate(-1)}>
                Cancel
              </span>
              <ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={processForm} submitSuccess={submitSuccess} />
            </div>
          </div>
        </div>
        <div className={styles.buttonRight}>
          <ButtonWithIcon label={'Done'} icon={'checkmark0'} onClick={() => navigate(-1)} submitSuccess={false} className={styles.buttonWidth} />
        </div>
      </div>
      <MessageModal 
        show={deleteTextStyleGroupId} 
        handleClose={() => setDeleteTextStyleGroupId('')}
        heading={``}
        explain={`Are you sure you want to delete this text style setting?`} 
        isConfirmType={true}
        onClick={handleDeleteTextStyleGroup} />
		</div>
	)
}

export default TextStyleGroup