import * as types from '../actions/actionTypes';
import { doSort } from '../utils/sort.js';

export default function (state = [], action) {
  switch (action.type) {
    case types.WORK_SEGMENTS_INIT:
      return [...action.payload];

    case types.WORK_COMMENT_UPDATE: {
      const { editType, comment, elementId, newSentence } = action.payload;

      const updatedState = state.map(segment => {
        if (Number(segment.elementId) === Number(elementId) && segment.type === editType) {
          return {
            ...segment,
            comment: comment,
            text: newSentence ? newSentence : segment.text
          };
        }
        return segment;
      });

      const sortedState = doSort(updatedState, { sortField: 'sequence', isAsc: true, isNumber: true });
      return sortedState;
    }

    default:
      return state;
  }
}

export const selectWorkSegments = (state) => state;
