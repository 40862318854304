import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import styles from './EditorInviteWorkView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import WorkFilter from '../../components/WorkFilter';
// import WorkSummary from '../../components/WorkSummary';
import Accordion from '../../components/Accordion';
import InvitesPending from '../../components/InvitesPending';
import MessageModal from '../../components/MessageModal';
import OneFJefFooter from '../../components/OneFJefFooter';

function EditorInviteWorkView(props) {
  const {
    personId,
    owner_personId,
    languageId,
    updateFilterByField,
    clearFilters,
    setWorkCurrentSelected,
    deleteWork,
    workSummaries,
    languageOptions,
    setWorkAssign,
    editorInviteName,
    editorInviteWorkAssign,
    updateChapterDueDate,
    updateChapterComment,
    editorInvitePending,
    deleteInvite,
    acceptInvite,
    resendInvite,
    filterScratch,
    savedFilterIdCurrent,
    workFilterOptions,
    updateSavedSearch,
    updateFilterDefaultFlag,
    deleteSavedSearch,
    chooseSavedSearch,
    updateWorkFilterByField,
    saveNewSavedSearch,
    updatePersonConfig,
    personConfig
  } = props;

  const navigate = useNavigate()
  const [isSending, setIsSending] = useState(false)
  const [isShowingModal, setIsShowingModal] = useState(false)

  const handleSend = () => {
    const {editorInviteWorkAssign} = props;
    if (!editorInviteWorkAssign || editorInviteWorkAssign.length === 0) {
      handleConfirmOpen();
    } else {
      submitSend();
    }
  }

  const submitSend = () => {
    const {sendEditorInvite, owner_personId, editorInviteName, editorInviteWorkAssign} = props;
    sendEditorInvite(owner_personId, editorInviteName, editorInviteWorkAssign, () => navigate("/giveAccessToEditors"));
    setIsSending(true)
  }

  const handleConfirmClose = () => setIsShowingModal(false)
  const handleConfirmOpen = () => setIsShowingModal(true)

  return (
    <div className={styles.container}>
      <div className={styles.titleLine}>
        <span className={globalStyles.pageTitle}>Invite New Editor to Documents</span>
      </div>
      {editorInvitePending && editorInvitePending.length > 0 &&
        <InvitesPending invites={editorInvitePending} personId={owner_personId} deleteInvite={deleteInvite}
                        acceptInvite={acceptInvite}
                        resendInvite={resendInvite}/>
      }
      <hr/>
      <div className={styles.contact}>
        <div>
          <div>
            {editorInviteName.firstName + ` ` + editorInviteName.lastName}
          </div>
          <div>
            {editorInviteName.phone}
          </div>
        </div>
        <div>
          {!isSending && <button className={styles.submitButton} onClick={handleSend}>Send</button>}
          {isSending && <span className={styles.sendingLabel}>Sending...</span>}
        </div>
      </div>
      <Accordion title={'Search options'}>
        {/*<AccordionItem expanded={false} filterScratch={filterScratch} filterOptions={workFilterOptions} savedFilterIdCurrent={savedFilterIdCurrent}*/}
        {/*        updateSavedSearch={updateSavedSearch} deleteSavedSearch={deleteSavedSearch} chooseSavedSearch={chooseSavedSearch}*/}
        {/*        updateFilterByField={updateWorkFilterByField} updateFilterDefaultFlag={updateFilterDefaultFlag} personId={owner_personId}*/}
        {/*        clearFilters={clearFilters} >*/}
        <WorkFilter personId={personId} workFilter={filterScratch} className={styles.workFilter}
                    updateFilterByField={updateFilterByField}
                    clearFilters={clearFilters} saveNewSavedSearch={saveNewSavedSearch} hideSourceStatus={true}
                    savedSearchOptions={workFilterOptions}/>
      </Accordion>

      <hr/>
      <ul className={styles.unorderedList}>
        {workSummaries && workSummaries.map((w, i) => {
          let chaptersChosen = [];
          let languagesChosen = [];
          let workAssign = editorInviteWorkAssign && editorInviteWorkAssign.length > 0 && editorInviteWorkAssign.filter(m => m && m.workId === w.workId)[0];
          if (workAssign) {
            chaptersChosen = workAssign.chapterIdList;
            languagesChosen = workAssign.languageIdList;
          }

          return (
            <Accordion title={w.title} key={i}>
              {/*<AccordionItem title={w.title} isCurrentTitle={w.isCurrentWork} expanded={w.isExpanded} key={i}*/}
              {/*        className={classes(styles.accordionTitle, w.isCurrentWork ? styles.isCurrentWork : '')}*/}
              {/*        editorAssign={workAssign} onTitleClick={() => {}} workId={w && w.workId}*/}
              {/*        personId={personId} owner_personId={owner_personId} languageId={languageId} chapterOptions={w.chapterOptions}*/}
              {/*        languageOptions={languageOptions} setEditorAssign={setWorkAssign} chaptersChosen={chaptersChosen}*/}
              {/*        languagesChosen={languagesChosen} isNewInvite={true} showAssignWorkToEditor={true}*/}
              {/*        updatePersonConfig={updatePersonConfig} personConfig={personConfig}>*/}
              {/* <WorkSummary summary={w} className={styles.workSummary} showIcons={true} personId={personId}
                           setWorkCurrentSelected={setWorkCurrentSelected} deleteWork={deleteWork} showTitle={false}
                           isHeaderDisplay={false}
                           labelCurrentClass={styles.labelCurrentClass} 
                           updateChapterDueDate={updateChapterDueDate} updateChapterComment={updateChapterComment}
                           updatePersonConfig={updatePersonConfig} personConfig={personConfig}/> */}
            </Accordion>
          );
        })}
      </ul>
      <MessageModal show={isShowingModal} handleClose={handleConfirmClose}
                    heading={`Send Invitation without Giving Access?`}
                    explain={`It appears that you have not granted access to one or more documents? Are you sure you want to send this invitation without giving them an assignment to a document`}
                    isConfirmType={true} onClick={submitSend}/>
      <OneFJefFooter/>
    </div>
  )
}

export default EditorInviteWorkView