import React from 'react';
import styles from './DateMoment.module.css';
import moment from 'moment';
import classes from "classnames";

export default ({ date, label, includeTime = true, className, dateTextClass, format, timeOnly = false, minusHours, nowrap, style }) => {
	let dateString = date;

	if (minusHours > 0) {
		let realDate = new Date(dateString);
		realDate.setHours(realDate.getHours() - minusHours);
		dateString = realDate.toString();
	}
	return (
		<div className={classes(styles.container, className)} style={style}>
			{label && <span className={classes(styles.label, (nowrap ? styles.nowrap : ''))}>{label}</span>}
			<span className={classes(dateTextClass, styles.text)}>{
				moment(dateString).year() > 1969 ?
					format
						? moment(dateString).format(format)
						: includeTime || timeOnly
							? timeOnly
								? moment(dateString).format("h:mm a")
								: moment(dateString).format("D MMM YYYY, h:mm a")
							: moment(dateString).format("D MMM YYYY")
					: ''
			}</span>
		</div>
	)
};
