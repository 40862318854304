import * as types from '../actions/actionTypes';

export default function(state = [], action) {
    switch(action.type) {
        case types.EDIT_SEGMENT_HISTORY_INIT:
            return action.payload;

        default:
            return state;
    }
}

 export const selectEditSegmentHistory = (state) => state;
