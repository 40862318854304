import React, { useState, useEffect } from 'react'
import AssessmentLearnerView from '../views/Assessment/AssessmentLearnerView'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actionAssessmentQuestions from '../actions/assessment-questions.js'
import * as actionAssessment from '../actions/assessment.js'
import * as actionWorks from '../actions/works.js'
import * as actionAssessmentCorrect from '../actions/assessment-correct.js'
//import * as actionMyVisitedPages from '../actions/my-visited-pages.js'
import {
  selectMe,
  selectAssessment,
  selectAssessmentQuestions,
  selectListLevelGeneral,
  selectQuestionTypes,
} from '../store.js'

const mapStateToProps = (state, props) => {
  let me = selectMe(state)
  let assessment = selectAssessment(state)
  let assessmentQuestions = selectAssessmentQuestions(state)
  let sequenceCount = assessmentQuestions && assessmentQuestions.length
  let sequences = []
  for (var i = 1; i <= sequenceCount; i++) {
    let option = { id: String(i), value: String(i), label: String(i) }
    sequences = sequences ? sequences.concat(option) : [option]
  }

  return {
    assessment,
    assessmentQuestions,
    isAuthor: assessment.personId === me.personId,
    listLevelGeneral: selectListLevelGeneral(state),
    personId: me.personId,
    questionTypes: selectQuestionTypes(state),
    sequences,
  }
}

const bindActionsToDispatch = dispatch => ({
  getAssessment: (personId, assessmentId) => dispatch(actionAssessment.getAssessment(personId, assessmentId)),
  assessmentQuestionsInit: (personId, studentPersonId, assessmentId, assignmentId) => dispatch(actionAssessmentQuestions.init(personId, studentPersonId, assessmentId, assignmentId)),
  addOrUpdateAssessmentAnswer: (personId, assessmentQuestionId, answer, assignmentId) => dispatch(actionAssessmentQuestions.addOrUpdateAssessmentAnswer(personId, assessmentQuestionId, answer, assignmentId)),
  correctAssessment: (personId, studentPersonId, assessmentId, assignmentId, runFunction) => dispatch(actionAssessmentCorrect.correctAssessment(personId, studentPersonId, assessmentId, assignmentId, runFunction)),
  removeLearnerAnswerFile: (personId, assessmentQuestionId, fileUploadId) => dispatch(actionAssessmentQuestions.removeLearnerAnswerFile(personId, assessmentQuestionId, fileUploadId)),
  updateAssessmentLocalAnswer: (assessmentId, assessmentQuestionId, answer) => dispatch(actionAssessmentQuestions.updateAssessmentLocalAnswer(assessmentId, assessmentQuestionId, answer)),
  createWorkInAssessment: (personId, assessmentQuestionId) => dispatch(actionAssessmentQuestions.createWorkInAssessment(personId, assessmentQuestionId)),
  getListLevelGeneral: () => dispatch(actionWorks.getListLevelGeneral()),
//  setMyVisitedPage: (personId, myVisitedPage) => dispatch(actionMyVisitedPages.setMyVisitedPage(personId, myVisitedPage)),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

function Container(props) {
  const {
    assessment,
    assessmentQuestionsInit,
    getAssessment,
    getListLevelGeneral,
    personId,
  } = props

  const params = useParams()
  const [isInit, setIsInit] = useState()

  useEffect(() => {
    if (!isInit && personId && params.assessmentId && params.assignmentId) {
      setIsInit(true)
      assessmentQuestionsInit(personId, personId, params.assessmentId, params.assignmentId)
      getAssessment(personId, params.assessmentId)
      getListLevelGeneral()
      // if (params.isRetakeQuiz !== 'notRetake' && props.assessmentQuestions?.length > 0) {
      //   setAssessmentQuestions(props.assessmentQuestions.map(question => ({
      //     ...question,
      //     learnerAnswer: ''
      //   })))
      // }
    }
    // Optional: Tracking page visits (ensure this side effect is appropriate here or may need separation)
    // props.location && props.location.pathname && setMyVisitedPage(personId, {path: props.location.pathname, description: `Student Assessment`})
  }, [isInit, personId, params.assessmentId, params.assignmentId, props.assessmentQuestions, params.isRetakeQuiz])


  return assessment && assessment.assessmentId
    ? <AssessmentLearnerView {...props} benchmarkTestId={params.benchmarkTestId} assignmentId={params.assignmentId} assessmentId={params.assessmentId} /> 
    : null
}

export default storeConnector(Container)
