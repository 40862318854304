import * as types from './actionTypes';
import {apiHost} from '../api_host.js';

export const clearTranslation = () => {
    return { type: types.TRANSLATED_SENTENCE_SET, payload: "" };
}

export const getTranslation = (personId, workId, languageId, elementId, chapterId, textToTranslate) => {
    return dispatch =>
        fetch(`${apiHost}ebi/translation`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
          },
          body: JSON.stringify({
            personId,
            workId,
            languageId,
            elementId,
            chapterId,
            textToTranslate,
            }),
          })
          .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
          })
          .then(response => {
            // let responseData = response && response.length > 0 ? JSON.parse(response) : ''
            // let finalData = responseData && responseData.length > 0 && responseData[0].translations && responseData[0].translations.length > 0 && responseData[0].translations[0].text ? responseData[0].translations[0].text : ''
            dispatch({ type: types.TRANSLATED_SENTENCE_SET, payload: response });
          })
}
