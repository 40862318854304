import React, { useState , useEffect } from 'react';
import styles from './JoinLinkGroup.module.css';
import OnToggle from "../../assets/ToggleSwitch/OnToggle.png"
import OffToggle from "../../assets/ToggleSwitch/OffToggle.png"
import Icon from '../../components/Icon'
import { guidEmpty } from '../../utils/GuidValidate'
import classes from 'classnames'

export default (props) => {
  const { personId, toggleGroupJoinLink, groupId, groupOpenJoinLink, runFunction } = props

  //if groupOpenJoinLink is a Guid (FriendInvitationId), that means that the FriendInvitation record has been toggled ON so that people can join. Otherwise the FriendInvitation record gets deleted entirely.
  const [hasGroupLink, setHasGroupLink] = useState(false)
  const [showCopied, setShowCopied] = useState(false)
  const [groupJoinInviteCode, setGroupJoinInviteCode] = useState(false)

  useEffect(() => {
    if (groupOpenJoinLink && groupOpenJoinLink !== guidEmpty) {
      setGroupJoinInviteCode(groupOpenJoinLink.substring(0,8))
      setHasGroupLink(true)
    }
  }, [groupOpenJoinLink])

  const copyJoinLink = () => {
    let link = `https://penspring.com/i/${groupJoinInviteCode}/grp`
    navigator.clipboard.writeText(link)
    setShowCopied(true)
  }

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <img src={hasGroupLink ? OnToggle : OffToggle} alt={'toggle'} className={styles.toggle} onClick={() => toggleGroupJoinLink(personId, groupId, runFunction)} />
        <div onClick={hasGroupLink ? copyJoinLink : () => { }} className={hasGroupLink ? styles.link : ''}>
          <div className={styles.row}>
            <div className={hasGroupLink ? styles.joinLinkOn : styles.joinLinkOff} onClick={() => toggleGroupJoinLink(personId, groupId, runFunction)}>Join Link</div>
            {hasGroupLink && <div className={styles.joinInstructions}>Click on this section to copy the link in your clipboard.</div>}
          </div>
          {hasGroupLink &&
            <div className={classes(styles.moreLeft, styles.row)}>
              <Icon pathName={'compare_docs'} premium={true} />
              <div className={styles.httpLinkOn}>{`https://penspring.com/i/${groupJoinInviteCode}/grp`}</div>
            </div>
          }
        </div>
      </div>
      {showCopied && hasGroupLink  && 
        <div className={styles.joinInstructions}>The link has been copied to your clipboard.</div> 
      }
    </div >

  )
};
