import * as types from '../actions/actionTypes';

export default function (state = [], action) {
    switch (action.type) {
        case types.WORK_ADD_SUMMARY_INIT: {
            return action.payload;
        }

        default:
            return state;
    }
}

export const selectWorkAddSettings = (state) => state;
