import React, {useEffect, useState} from 'react'
import styles from './AuthoringDrafts.module.css'
import MessageModal from '../../components/MessageModal'
import TabPage from '../../components/TabPage'
import DraftReviewControls from '../../components/DraftReviewControls'
import classes from "classnames";

let workDownloadReady = null  //This is necessary because the download is refreshing the page and losing local state. So we accumulate a localStorage, a global variable and a local state.
let personConfig = localStorage.getItem('personConfig')
personConfig = personConfig ? JSON.parse(personConfig) : []

function AuthoringDrafts(props) {
  const { workSummary, getNextId, draftReview={}, handleSetCurrentElement, handleEditorTabChosen, tabNav, navText, chapterListLevels, addChapterListLevels, listLevelGeneral } = props;

  const [chosenSegment, setChosenSegment] = useState(['0']);
  const [entryError, setEntryError] = useState('');
  const [showDownloadReady, setShowDownloadReady] = useState(false);  //This is necessary because the download is refreshing the page and losing local state. So we accumulate a localStorage, a global variable and a local state.
  const chosenTab = "" //No editor chosen tab set here on this review page.
  const isAuthor = true //We will let views be as if the author is the user.
  let personId = workSummary.authorPersonId

  useEffect(() => {
    workDownloadReady = localStorage.getItem('workDownloadReady')
    workDownloadReady = workDownloadReady ? JSON.parse(workDownloadReady) : null
    if (workDownloadReady) setShowDownloadReady(true)  //This is necessary because the download is refreshing the page and losing local state. So we accumulate a localStorage, a global variable and a local state.
  }, [])

  const handleDownloadChoice = (choice, event, divDisplayId='editorDiv') => {
    event.preventDefault()
    event.stopPropagation()
    let htmlString = document.getElementById(divDisplayId).innerHTML
    let wordConversion = {
      htmlString,
      personId: props.personId,
      workId: workSummary.workId,
      languageId: workSummary.languageId || 1,
      conversionType: choice
    }
    let getWorkChoice = props.downloadOptions.filter(m => m.value === choice)[0]
    localStorage.setItem('workDownloadReady', JSON.stringify(getWorkChoice))
    setShowDownloadReady(true)
    props.downloadWork(wordConversion)
  }

  const handleSetChosenSegment = (element) => {
    if (element) {
      let newChosen = (chosenSegment && chosenSegment.length > 0 && [...chosenSegment]) || []
      let cleanSpanId = element.id.indexOf('~tabView') > -1 ? element.id.substring(0, element.id.indexOf('~tabView')) : element.id
      let item = {
        id: cleanSpanId,
        type: element.dataset.type,
        addListItemSequence: element.dataset.addListItemSequence || ''
      }
      setChosenSegment(newChosen.concat(item).filter(m => m && m.id && m.id !== 'root')) //cut out the blank entries
    }
  }

  const showOuterHtml = () => {
    console.info('editorDiv: ', document.getElementById('editorDiv').innerHTML)
    //console.info('tabView: ', document.getElementById('tabView').innerHTML)
  }

  const handleMouseUp = (event, isEditorDivView) => {
    handleSetCurrentElement(event.target)
    handleSetChosenSegment(event.target)
  }

  const handleKeyDOWNEditor = (event) => {
    event.stopPropagation()
    event.preventDefault()
    return false
  }

  const closeModalDownloadDocx = () => {
    //This is necessary because the download is refreshing the page and losing local state. So we accumulate a localStorage, a global variable and a local state.
    //workDownloadReady is the conversion type which is also the file extension.
    props.deleteFile(props.personId, workSummary.workId, workDownloadReady.value, workSummary.languageId || 1) //This will trigger the WORK_DOWNLOAD_READY by setting it to blank which will close this modal dialog.
    localStorage.setItem('workDownloadReady', null)
    workDownloadReady = null
    setShowDownloadReady(false)
  }

  const scrollDocumentToMatch = (paramElement, paramElementId) => {
    let elementId = paramElementId ? paramElementId : paramElement ? paramElement.id : chosenSegment && chosenSegment.length > 0 ? chosenSegment[chosenSegment.length - 1] && chosenSegment[chosenSegment.length - 1].id : ''
    if (!elementId || elementId === '0') {
      setEntryError('Please choose a sentence before scrolling view locations.')
    } else {
      const editorElement = document.querySelectorAll(`[id="${elementId}"]`)[0]  //[data-type="TEXT"]
      const tabViewElement = document.querySelectorAll(`[id="${elementId + '~tabView'}"]`)[0] //[data-type="TEXT"]
      if (tabViewElement) tabViewElement.scrollIntoView({behavior: "smooth", block: "center"});
      if (editorElement) setTimeout(() => editorElement.scrollIntoView({behavior: "smooth", block: "center"}), 500);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.rowEditReview}>
        <div className={styles.showEditorDiv}>
          <div className={styles.toolSection}>
            <div className={classes(styles.tabPage, styles.row)}>
              <TabPage tabsData={draftReview.draftTabsData}
                       onClick={handleEditorTabChosen}
                       viewSide={'LEFT'}
                       navClose={tabNav}
                       navText={navText}
                       chosenTab={draftReview.leftChosenTab}
                       showZeroCount={true}
                       showListAfterQuantity={6}/>

              <DraftReviewControls handleDownloadChoice={handleDownloadChoice}
                                   scrollDocumentToMatch={scrollDocumentToMatch}
                                   divDisplayId={'editorDiv'}
                                   {...props} />
            </div>
          </div>
          <div className={styles.editorDivLeft}>
            <div id="editorDiv" contentEditable={'false'}
                 className={styles.editorDiv}
                 onKeyDown={handleKeyDOWNEditor}
                 onMouseUp={(event) => handleMouseUp(event, true)}
            />
          </div>
        </div>
        <div className={styles.flexShrink}>
          <div className={styles.oneSide}>
            <div className={styles.toolSection}>
              <div className={classes(styles.tabPage, styles.row)}>
                <TabPage tabsData={draftReview.draftTabsData}
                         onClick={handleEditorTabChosen}
                         viewSide={'RIGHT'}
                         navClose={tabNav}
                         navText={navText}
                         chosenTab={draftReview.rightChosenTab}
                         showZeroCount={true}
                         showListAfterQuantity={6}/>

                <DraftReviewControls handleDownloadChoice={handleDownloadChoice}
                                     scrollDocumentToMatch={scrollDocumentToMatch}
                                     divDisplayId={'tabView'}
                                     {...props} />
              </div>
            </div>
            <div className={styles.editorDivRight}>
              <div id="tabView" contentEditable={'false'}
                   className={styles.tabViewPosition}
                   onKeyDown={handleKeyDOWNEditor}
                   onMouseUp={(event) => handleMouseUp(event, false)}/>
            </div>
          </div>
        </div>
      </div>
      <MessageModal show={showDownloadReady && workDownloadReady && workSummary && workSummary.title}
                    handleClose={closeModalDownloadDocx} heading={`Download as ${workDownloadReady && workDownloadReady.title}`}
                    explain={`Microsoft&reg; Word&reg; &nbsp;&nbsp;&nbsp;&nbsp;${workSummary.title}`}
                    downloadFilePath={`../wordDocs/${workSummary.title}${workSummary && workSummary.workId && workSummary.workId.substring(0, 6)}${workSummary && workSummary.languageId || 1}.${workDownloadReady && workDownloadReady.value}`}
                    //Help ToDo: get the right downloadFiltPath with ~ and all.
                    onClick={closeModalDownloadDocx} isConfirmType/>
      <MessageModal displayTempMessage
                    explain={entryError}
                    setEntryError={setEntryError}
                    handleClose={() => setEntryError('')}
                    heading={`Entry Error!`}
                    onClick={() => setEntryError('')}/>
    </div>
  );
}

export default AuthoringDrafts

