import React from 'react';
import styles from './EditorFullTextView.module.css';
import classes from "classnames";
import TabPage from '../TabPage'

function EditorFullTextView(props) {
  const { 
    chosenTab, 
    edits, 
    handleKeyDOWNEditor, 
    handleMouseUp, 
    handleSetGlobalChosenTab, 
    isMobile,
    navText, 
    setChosenTab,
    tabNav, 
    tabsData, 
    workTypeCode,
  } = props
    return (
      <div className={styles.container}>
        <div>
          {!(tabsData && tabsData.length > 0) &&
            <div>
              <div className={styles.fullViewHeader}>{`Editors' versions`}</div>
              <div className={classes(styles.fullViewHeader, styles.noEdits)}>
                {!(edits && edits.length > 0) ? 'No edits to display' : ''}
              </div>
            </div>
          }
          <div className={classes(styles.tabPage, styles.row)}>
            <TabPage tabsData={tabsData}
                     onClick={(personId) => {handleSetGlobalChosenTab(personId); setChosenTab(personId);}}
                     navClose={tabNav}
                     navText={navText}
                     chosenTab={chosenTab}
                     showZeroCount={true}
                     showListAfterQuantity={6}/>
          </div>
        </div>
        <div>
          <div className={workTypeCode === 'DISCUSSION' ? styles.editorDivRightDiscussion : styles.editorDivRight}>
            <div className={styles.tabInstructions}>Changes cannot be made in this view</div>
            <div id="tabView" contentEditable={true}
              spellCheck={'true'}
              className={isMobile ? styles.tabViewBackground : ''}
              onKeyDown={handleKeyDOWNEditor}
              onMouseUp={(event) => handleMouseUp(event, false)}/>
          </div>
        </div>
      </div>
    )
}

export default EditorFullTextView