import React, { useState, useEffect, useRef } from 'react'
import { apiHost } from '../../../api_host.js'
import axios from 'axios'
import styles from './AssessmentPictureAudio.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import classes from 'classnames'
import TextDisplay from '../../TextDisplay'
import InputText from '../../InputText'
import Checkbox from '../../Checkbox'
import ImageDisplay from '../../ImageDisplay'
import AudioDisplay from '../../AudioDisplay'
import Icon from '../../Icon'
import FileUploadModalWithCrop from '../../FileUploadModalWithCrop'
import VoiceRecordingModal from '../../VoiceRecordingModal'
import QuestionLabel from '../../QuestionLabel'

function AssessmentPictureAudio(props) {
  const {
    assessmentCorrect,
    assignmentId,
    className = "",
    handleRemoveFileOpen,
    isAuthor,
    isStudent,
    isSubmitted,
    nameKey,
    personId,
    question = {},
    removeLearnerRecordingOpen,
    removeQuestionFileOpen,
    removeQuestionRecordingOpen,
    removeSolutionFileOpen,
    removeSolutionRecordingOpen,
    submitAction,
    submitEssayResponse,
  } = props

  let imageViewer = useRef(null)
  let pictureFile = useRef(null)
  
  const [assessmentQuestionId, setAssessmentQuestionId] = useState()
  const [correct, setCorrect] = useState()
  const [entry, setEntry] = useState({
    essayResponse: '',
    score: '',
    isCorrect: '',
  })
  const [errorScore, setErrorScore] = useState('')
  const [isShowingFileUpload, setIsShowingFileUpload] = useState(false)
  const [isShowingVoiceRecording, setIsShowingVoiceRecording] = useState(false)
  const [record, setRecord] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const [selectedRecording, setSelectedRecording] = useState()

  useEffect(() => {
    let correct = (assessmentCorrect?.length > 0 && assessmentCorrect.filter(m => m.assessmentQuestionId === question.assessmentQuestionId)[0]) || {}
    if (assessmentCorrect !== props.assessmentCorrect) {
      setCorrect(props.assessmentCorrect)
      setEntry({
        essayResponse: assessmentCorrect ? assessmentCorrect.teacherEssayResponse : assessmentCorrect ? assessmentCorrect.learnerAnswer : '',
        score: correct && correct.score,
        isCorrect: correct && correct.isCorrect,
      })
    }
  }, [assessmentCorrect])

  const handleRemoveInputRecording = () => {
    
  }

  const handleChange = ({ target }) => {
    let entry = { ...entry }
    entry['score'] = target.value === '' ? 'EMPTY' : target.value
    setEntry(entry)
    submitEssayResponse(question.assessmentQuestionId, entry)
  }

  const toggleCheckbox = () => {
    let entry = { ...entry }
    entry['isCorrect'] = !entry['isCorrect']
    setEntry(entry)
    submitEssayResponse(question.assessmentQuestionId, entry)
  }

  const handleSubmit = () => {
    var hasError = false

    if (!(entry.score === 0 || entry.score > 0)) {
      hasError = true
      setErrorScore('A score is required')
    }

    if (!hasError) {
      submitAction(question.assessmentQuestionId, entry)
    }
  }

  const handleFileUploadOpen = (assessmentQuestionId) => {
    setIsShowingFileUpload(true)
    setAssessmentQuestionId(assessmentQuestionId)
  }

  const handleFileUploadClose = () => {
    setIsShowingFileUpload(false)
    setAssessmentQuestionId('')
  }

  const handleFileUploadSubmit = () => {
    let data = new FormData()
    data.append('file', selectedFile)

    let url = `${apiHost}ebi/assessmentLearner/pictureUpload/${personId}/${assessmentQuestionId}/${assignmentId}`

    axios.post(url, data,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
          "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
          "Authorization": "Bearer " + localStorage.getItem("authToken"),
        }
      })
      .catch(function (error) {
      })
    handleFileUploadClose()
  }

  const handleRemoveInputFile = () => {
    setSelectedFile(null)
    var img = imageViewer.current
    img.src = ""
    pictureFile.current.after(img)
  }

  const handleInputFile = (file) => {
    setSelectedFile(file)
    var img = imageViewer.current
    var reader = new FileReader()
    reader.onloadend = function () {
      img.src = reader.result
    }
    reader.readAsDataURL(file)
    pictureFile.current.after(img)
  }

  const startRecording = () => setRecord(true)
  const stopRecording = () => setRecord(false)
  const onStop = (recordedBlob) => setSelectedRecording(recordedBlob)

  const handleVoiceRecordingOpen = (assessmentQuestionId) => {
    setIsShowingVoiceRecording(true)
    setAssessmentQuestionId(assessmentQuestionId)
  }
  const handleVoiceRecordingClose = () => {
    setIsShowingVoiceRecording(false)
    setAssessmentQuestionId('')
  }
  const handleVoiceRecordingSubmit = () => {
    let data = new FormData()
    data.append('audio', selectedRecording.blob)

    let url = `${apiHost}ebi/assessmentLearner/addRecording/${personId}/${assessmentQuestionId}/${assignmentId}`

    axios.post(url, data,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
          "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
          "Authorization": "Bearer " + localStorage.getItem("authToken"),
        }
      })
      .catch(function (error) {
      })
    handleVoiceRecordingClose()
  }

  return (
    <div className={classes(className, styles.container)} key={nameKey}>
      <QuestionLabel label={'Picture Audio'} />
      <div className={classes(styles.row, styles.questionLine)}>
        {correct && correct.assessmentLearnerAnswerId && !correct.pendingCorrection
          ? correct.isCorrect
            ? <Icon pathName={'checkmark0'} fillColor={'green'} premium={true}
              className={styles.icon} />
            : <Icon pathName={'cross_circle'} fillColor={'red'} premium={true}
              className={styles.icon} />
          : ''
        }
        <div className={styles.sequence}>{question.sequence}.</div>
        <div className={styles.question}>{question.questionText}</div>
      </div>
      {question.questionRecordingFileUrl &&
        <AudioDisplay src={question.questionRecordingFileUrl} preload={'auto'}
          controls="controls" className={styles.audioLeftQuestion}
          isSmall={true} isOwner={question.isOwner}
          deleteFunction={(event) => removeQuestionRecordingOpen(event, question.assessmentQuestionId, question.questionRecordingUploadId)} />
      }
      {question.questionFileUploadId &&
        <ImageDisplay linkText={''} url={question.questionFileUrl}
          isOwner={question.isOwner}
          deleteFunction={() => removeQuestionFileOpen(question.assessmentQuestionId, question.questionFileUploadId)} />
      }
      {isStudent &&
        <div className={classes(styles.row, styles.moreLeft)}
          ref={pictureFile}>
          <div className={globalStyles.instructionsBigger}>
            To answer this question, enter a 
          </div>
          <div className={styles.row}
            onClick={() => handleFileUploadOpen(question.assessmentQuestionId)}>
            <Icon pathName={'camera2'} premium={true}
              className={styles.icon} />
            <div
              className={classes(globalStyles.link, styles.littleTop, styles.moreRight)}>
              Picture and / or</div>
          </div>
          <div className={styles.row}
            onClick={() => handleVoiceRecordingOpen(question.assessmentQuestionId)}>
            <Icon pathName={'microphone'} premium={true}
              className={styles.iconPosition} />
            <div className={classes(globalStyles.link, styles.littleTop)}>Voice recording</div>
          </div>
        </div>
      }
      {(selectedRecording || (question.learnerAnswer && question.learnerAnswer.recordingFileUrl)) &&
        <AudioDisplay src={question.learnerAnswer.recordingFileUrl}
          preload={'auto'} controls="controls"
          className={styles.audioLeftQuestion}
          isSmall={true} isOwner={question.learnerAnswer.isOwner}
          deleteFunction={() => removeLearnerRecordingOpen(question.assessmentQuestionId, question.learnerAnswer.recordingFileUrl)} />
      }
      <img src={''} alt={'New'} ref={imageViewer} />
      {question.learnerAnswer && question.learnerAnswer.fileUploadId && question.learnerAnswer.fileUrl &&
        <ImageDisplay url={question.learnerAnswer.fileUrl}
          isOwner={question.learnerAnswer.isOwner}
          deleteFunction={() => handleRemoveFileOpen(question.assessmentQuestionId, question.learnerAnswer.fileUploadId)} />
      }
      {correct.teacherEssayResponse &&
        <div>
          <TextDisplay label={'Teacher response'}
            text={(correct && correct.teacherEssayResponse) ||
              <div className={styles.noAnswer}>no answer</div>}
            className={styles.staticText} />
          <TextDisplay label={'Score'}
            text={correct && correct.score}
            className={styles.staticText} />
        </div>
      }
      {!correct.teacherEssayResponse && (!isSubmitted || (isSubmitted && isAuthor)) &&
        <div className={styles.answerLeft}>
          <hr />
          <div className={styles.link}>
            The student can respond with a picture or an audio recording or both.
          </div>
          <hr />
          {isAuthor &&
            <div className={classes(styles.moreBottom, styles.row)}>
              <InputText
                id={`score`}
                name={`score`}
                size={"super-short"}
                label={correct.pointsPossible ?
                  'Score possible: ${correct.pointsPossible}' :
                  'Score'}
                value={((assessmentCorrect && assessmentCorrect.score === 0) || (correct && correct.score === 0)) ? 0 : (assessmentCorrect && assessmentCorrect.score) || (correct && correct.score) || ''}
                onChange={handleChange}
                numberOnly={true}
                required={true}
                whenFilled={assessmentCorrect && assessmentCorrect.score} />

              <div className={styles.checkbox}>
                <Checkbox
                  id={'isCorrect'}
                  label={'Mark this answer as correct'}
                  labelClass={styles.checkboxLabel}
                  checked={(correct && correct.isCorrect) || false}
                  onClick={toggleCheckbox}
                  className={styles.button} />
              </div>
            </div>
          }
          {(question.solutionText || question.solutionFileUrl || question.solutionRecordingFileUrl) && (question.isOwner || (correct && correct.assessmentId)) &&
            <div className={styles.muchLeft}>
              <div className={styles.row}>
                <div className={styles.headerLabel}>Solution</div>
                {!(correct && correct.assessmentId) &&
                  <div className={globalStyles.instructions}>
                    (After the quiz is corrected, this explanation or picture will be displayed)
                  </div>
                }
              </div>
              <div className={styles.text}>{question.solutionText}</div>
              {question.solutionRecordingFileUrl &&
                <AudioDisplay src={question.solutionRecordingFileUrl}
                  preload={'auto'} controls="controls"
                  className={styles.audioLeftQuestion}
                  isSmall={true} isOwner={question.isOwner}
                  deleteFunction={() => removeSolutionRecordingOpen(question.assessmentQuestionId, question.questionRecordingUploadId)} />
              }
              {question.solutionFileUrl && question.solutionFileUploadId &&
                <ImageDisplay linkText={''} url={question.solutionFileUrl}
                  isOwner={question.isOwner}
                  deleteFunction={() => removeSolutionFileOpen(question.assessmentQuestionId, question.solutionFileUploadId)} />
              }
            </div>
          }
        </div>
      }
      <FileUploadModalWithCrop 
        handleClose={handleFileUploadClose}
        isOpen={isShowingFileUpload}
        title={'Choose Picture'}
        personId={personId}
        submitFileUpload={handleFileUploadSubmit}
        file={selectedFile}
        handleInputFile={handleInputFile}
        acceptedFiles={".jpg, .jpeg, .tiff, .gif, .png, .bmp"}
        handleCancelFile={handleRemoveInputFile} />

      <VoiceRecordingModal handleClose={handleVoiceRecordingClose}
        isOpen={isShowingVoiceRecording}
        title={'Assessment Question'}
        label={'File for'}
        personId={personId}
        record={record}
        submitFileUpload={handleVoiceRecordingSubmit}
        recordedBlob={selectedRecording}
        handleCancelFile={handleRemoveInputRecording}
        startRecording={startRecording}
        stopRecording={stopRecording}
        onStop={onStop} />
    </div>
  )
}

export default AssessmentPictureAudio