import React, {useState, useEffect, useRef} from 'react'
// Optical Character Reader
// https://www.smashingmagazine.com/2021/06/image-text-conversion-react-tesseract-js-ocr/
import styles from './TextImageCamera.module.css'
import MessageModal from '../../components/MessageModal'
import SelectSingleDropDown from '../../components/SelectSingleDropDown'
import Icon from '../../components/Icon'
import InputText from '../../components/InputText'
import FileUploadPenspring from '../../components/FileUploadPenspring'
import ReviewTextModal from '../../components/ReviewTextModal'
import { useMediaQuery } from "react-responsive"
import Tesseract from 'tesseract.js'
import classes from 'classnames'
import { Line } from "rc-progress"
import Required from '../Required'
import * as editorService from '../../services/editor-dom'

function TextImageCamera(props) {
  const { setTextImageUpload, textImageUploads, textImageGroups, textImageGroupId, setTextImageGroupId, setDisableSubmitButton } = props
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [name, setName] = useState()
  const [imagePath, setImagePath] = useState()
  const [fileLocal, setFileLocal] = useState()
  const [progress, setProgress] = useState()
  const [entryError, setEntryError] = useState('')
  const [errorTextImageName, setErrorTextImageName] = useState([])
  const [deleteTextImageUploadId, setDeleteTextImageUploadId] = useState('') //This will actually be the textImageUploadId
  const [textToReview, setTextToReview] = useState()
  const [processedConvertedText, setProcessedConvertedText] = useState()

  const [isSmartPhone, setIsSmartPhone] = useState(false)

  const [image, setImage] = useState(null)
  
  const videoRef = useRef(null)
  const canvasRef = useRef(null)
  const audioRef = useRef(null) // Ref for the audio element

  //************** Specific function related to the Camera version of the TextImage process */

  // Detect if user is on mobile
  useEffect(() => {
    setIsSmartPhone(isSmartphone())
  }, [])

  const stopExistingStream = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject
      const tracks = stream.getTracks()
      tracks.forEach(track => track.stop()) // Stop all tracks
      videoRef.current.srcObject = null // Clear the srcObject
    }
  }

  useEffect(() => {
    if (!image) {
      stopExistingStream()

      const startWebcam = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true })
          if (videoRef.current) {
            videoRef.current.srcObject = stream
            // videoRef.current.onloadedmetadata = () => {
            //   videoRef.current.play() // Play only after the metadata is loaded
            // }          
          }
        } catch (err) {
          console.error("Error accessing the webcam: ", err)
        }
      }      

      startWebcam()
    }
  }, [image])


  const captureImage = () => {
    const video = videoRef.current
    const canvas = canvasRef.current

    if (canvas && video) {
      const context = canvas.getContext("2d")

      // Video aspect ratio and canvas dimensions
      const videoAspectRatio = video.videoWidth / video.videoHeight
      const canvasAspectRatio = canvas.width / canvas.height

      let sx, sy, sWidth, sHeight

      // If the video aspect ratio is wider than the canvas, we crop from the sides
      if (videoAspectRatio > canvasAspectRatio) {
        sHeight = video.videoHeight
        sWidth = canvasAspectRatio * sHeight
        sx = video.videoWidth - sWidth // Crop the right side of the video
        sy = 0
      } else {
        // If the video aspect ratio is taller, crop from top/bottom
        sWidth = video.videoWidth
        sHeight = sWidth / canvasAspectRatio
        sx = 0
        sy = (video.videoHeight - sHeight) / 2 // Align vertically center
      }

      // Draw the cropped portion of the video onto the canvas
      context.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, canvas.width, canvas.height)

      // Get the image data as a base64 string
      const imageData = canvas.toDataURL("image/png")
      setImage(imageData) // Save the captured image to state

      // Optionally, play a snapshot sound
      if (audioRef.current) {
        audioRef.current.play()
      }
    }
  }

  const handleTesseractConvertText = () => {
    if (image) {
      setDisableSubmitButton(true)
      Tesseract.recognize(
        image, 
        'eng',
        {
          //tessedit_preserve_interword_spaces: 1,
          tessedit_pageseg_mode: 1, 
          logger: m => setProgress(m.progress * 100)
        }
      )
        .catch(err => {
          console.error(err)
        })
        .then(result => {
          const processedText = editorService.detectParagraphs(result.data);
          setTextImageUpload(textImageGroupId, { convertedText: processedText, confidence: result.data.confidence, name });
          setProcessedConvertedText(false)
          setImage(null)
          setTimeout(() => setDisableSubmitButton(false), 1000)
        })
    } else {
      console.error('No image available for processing')
    }
  }

  //**************** Shared with TextImageUpload ******************//

  useEffect(() => {
    if (textImageUploads && textImageUploads.length > 0) {
      setTextImageGroupId(textImageUploads[0].textImageGroupId)
    }
  }, [textImageUploads])
  
  useEffect(() => {
    if (!processedConvertedText && image) {
      handleTesseractConvertText()
    }
  }, [image, processedConvertedText])
  
  const handleSetPicture = (event) => {
    const fileLocal = event.target.files[0];
    if (fileLocal) {
      setFileLocal(fileLocal)
      setProcessedConvertedText(false)
      setTimeout(() => setImagePath(URL.createObjectURL(fileLocal)), 300);
    }
  }

  useEffect(() => {
    if (!processedConvertedText && fileLocal && imagePath) {
      ocrConvertData()
    }
  }, [imagePath, processedConvertedText, fileLocal])

  const ocrConvertData = () => {
    if (imagePath) {
      setDisableSubmitButton(true)
      Tesseract.recognize(
        imagePath, 'eng',
        {
          //tessedit_preserve_interword_spaces: 1,
          tessedit_pageseg_mode: 1, 
          logger: m => setProgress(m.progress * 100)
        }
      )
        .catch(err => {
          console.error(err);
        })
        .then(result => {
          if (result?.data?.text) {
            const processedText = editorService.detectParagraphs(result.data);
            setTextImageUpload(textImageGroupId, { convertedText: processedText, confidence: result.data.confidence, name });
            setProcessedConvertedText(false)
            setFileLocal(null)
            setImagePath(null)
            setTimeout(() => setDisableSubmitButton(false), 1000)            
          }
        })
    }
  }

  const updateTextImageSequence = (event, textImageUpload) => {
    const sequence = event.target.value
    if (sequence && Number(sequence) !== textImageUpload.sequence) {
      props.updateSequenceTextImageUpload(textImageUpload.textImageUploadId, sequence)
    }
  }
    
  const handleTextImageName = (event, textImageUpload) => {
    const name = event.target.value
    props.updateNameTextImageUpload(textImageUpload.textImageUploadId, name)
    if (!name) {
      setErrorTextImageName({...errorTextImageName, [textImageUpload.textImageUploadId]: 'The name is required'})
    } else {
      setErrorTextImageName(errorTextImageName?.length > 0 ? errorTextImageName.filter(m => m !== textImageUpload.textImageUploadId) : {})
    }
  }

  const changeGroup = (event) => {
    const chosenTextImageGroupId = event.target.value
    if (chosenTextImageGroupId !== textImageGroupId) {
      props.getTextImageUploads(chosenTextImageGroupId)
      setTextImageGroupId(chosenTextImageGroupId)
    }
  }

  const handleDeleteTextImageUpload = () => {
    props.removeTextImageUpload(deleteTextImageUploadId)
    setDeleteTextImageUploadId('')
  }

	return (
		<div className={styles.container}>
      <div className={styles.inputControls}>
        <div className={styles.titleRequired}>
          Upload Text Image Photos
          <Required
            setIf={true}
            className={styles.requiredPosition}
            setWhen={textImageUploads?.length > 0} />
      </div>
        {isSmartPhone && 
          <form method="post" encType="multipart/form-data">
            <FileUploadPenspring
              captureCameraOnly={'camera'}
              label={'Take a picture from your device'}
              subLabel='Only one file is allowed at a time - bmp, jpg, png, pbm, webp'
              setFile={handleSetPicture}
              fileTypes='image/bmp,image/jpeg,image/png,image/x-portable-bitmap,image/webp' /> 
          </form>
        }
        {!isSmartPhone && 
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {!image && 
              <video
                ref={videoRef}
                autoPlay
                style={{
                  width: "300px", // Set larger width of the video feed
                  height: "388px", // Set larger height of the video feed
                  objectFit: "cover", // This scales and centers the video feed
                  objectPosition: "right center", // Align video to the left
                  border: "1px solid black",
                  overflow: "hidden", // Hide the overflow of the video
                  marginLeft: '50px'
                }}
              />
            }
            <canvas
              ref={canvasRef}
              style={{ display: "none" }}
              width={612} // Match the video width
              height={792} // Match the video height
            />
            {image && 
              <div>
                <h2>Captured Image:</h2>
                <img
                  src={image}
                  alt="Captured"
                  width={300}
                  height={388}
                />
              </div>
            }
            {!image && <button onClick={() => captureImage()}>Capture</button>}
          </div>
        }

        {textImageGroups?.length > 0 && 
          <div className={styles.groupList}>
            <SelectSingleDropDown
              label={`Existing collections of text images`}
              value={textImageGroupId}
              options={textImageGroups}
              height={`medium-short`}
              labelClass={styles.textWhite}
              className={styles.narrowList}
              includeDownArrow
              onChange={changeGroup} />
          </div>
        }
        <div>
          <Line percent={progress} strokeWidth="1" strokeColor="#ecb86a" className={styles.widthAdjust} />
        </div>
        <div className={styles.textWhite}>{progress && progress !== 100 ? Math.round(progress) + '%' : ''}&nbsp;</div>

        <table className={styles.tableStyle}>
          <thead>
            <tr>
              <th className={styles.hdr}>#</th>
              <th className={classes(styles.hdr, isMobile ? styles.textCellMobile : styles.textCell)}>Text</th>
              <th className={styles.hdr}></th>
              <th className={styles.hdr}></th>
            </tr>
          </thead>
          <tbody>
            {(!textImageUploads || !textImageUploads.length) &&
              <tr>
                <td>
                </td>
                <td className={styles.emptyCell}>
                  There are not any text images, yet.
                </td>
              </tr>
            }
            {textImageUploads?.map((m, i) => 
              <tr key={i}>
                <td>
                  <div className={styles.listTop} key={`sequence{i}`}>
                    <SelectSingleDropDown
                      label={``}
                      noBlank
                      value={m.sequence}
                      options={textImageUploads.reduce((acc, m) => acc && acc.length > 0 ? acc.concat({ id: m.sequence, label: m.sequence }) : [{ id: m.sequence, label: m.sequence }], [])}
                      height={`medium-short`}
                      className={styles.selectList}
                      onChange={(event) => updateTextImageSequence(event, m)} />
                  </div>
                </td>
                <td>
                  <div className={styles.imageName} key={`name{i}`}>
                    <InputText
                      key={i}
                      size={isMobile ? 'medium' : 'long'}
                      name={'name'}
                      label={`${m.confidence}% confidence`}
                      labelClassName={styles.labelWhite}
                      inputClassName={styles.textInput}
                      value={m.name || ''}
                      onChange={(event) => handleTextImageName(event, m)}
                      required={true}
                      whenFilled={m.name}
                      error={errorTextImageName[m.textImageUploadId]} />
                  </div>
                </td>
                <td>
                  <div onClick={() => setTextToReview(m.convertedText)} className={styles.linkWhite}>
                    {isMobile ? <div style={{marginTop: '5px'}}><Icon pathName={'magnifier'} premium={true} fillColor={'white'}/></div> : 'view'}
                  </div>
                </td>
                <td className={styles.rightCell}>
                  <div onClick={() => setDeleteTextImageUploadId(m.textImageUploadId)}>
                    <Icon pathName={'trash2'} premium={true} fillColor={'pink'}/>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <MessageModal 
        show={deleteTextImageUploadId} 
        handleClose={() => setDeleteTextImageUploadId('')}
        heading={``}
        explain={`Are you sure you want to delete this text image from this group?`} 
        isConfirmType={true}
        onClick={handleDeleteTextImageUpload} />
      <MessageModal 
        displayTempMessage
        show={entryError}
        setEntryError={setEntryError}
        explain={entryError}
        handleClose={() => setEntryError('')}
        heading={`Entry Error!`}
        onClick={() => setEntryError('')}/>
      <ReviewTextModal isOpen={!!textToReview} onClose={() => setTextToReview('')} text={textToReview} />
      <audio ref={audioRef} src="./snapshot.mp3" />
		</div>
	)
}

// Utility function to detect if the user is on a smartphone
const isSmartphone = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) {
    return true
  }

  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return true
  }

  return false // Assume desktop if not mobile
}


export default TextImageCamera