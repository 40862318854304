import React from 'react';
import styles from './ContactsAddChoice.module.css';
import { Link } from 'react-router-dom'
import Icon from '../../components/Icon'
import GoogleLogo from '../../assets/GoogleLogo.png'

export default ({ label="Invite people as editors:", groupId = '', labelClass }) => {
	return (
		<div className={styles.container}>
			<div className={labelClass || styles.addPeopleLabel}>{label}</div>
			<div className={styles.row}>
				<Link to={`/editorInviteGoogleContacts/${groupId}`} className={styles.menuItem} style={{ marginTop: '-2px' }} data-rh={'Choose from your Google contacts'}
					title={'Choose from your Google contacts'} >
					<img src={GoogleLogo} height={25}/>
				</Link>
				<Link to={`/editorInviteContactList/${groupId}`} className={styles.menuItem} data-rh={'Choose from contact list'}
					title={'Choose from contact list'} tabIndex='-1'>
					<Icon pathName={'list3'} premium={true} superscript={'plus'} supFillColor={'forestgreen'}/>
				</Link>
				<Link to={`/editorInviteNameEmail/${groupId}`} className={styles.menuItem} data-rh={'Invite individual editor'}
					title={'Invite individual editor'} tabIndex='-1'>
					<Icon pathName={'user'} premium={true} superscript={'plus'} supFillColor={'forestgreen'} larger/>
				</Link>
				<Link to={`/editorInviteByBulk/${groupId}`} className={styles.menuItem} data-rh={'Invite editors by bulk entry'}
					title={'Invite editors by bulk entry'} tabIndex='-1'>
					<Icon pathName={'users0'} premium={true} superscript={'plus'} supFillColor={'forestgreen'} larger/>
				</Link>
			</div>
		</div>
	)
};
