import React from 'react';
import {useNavigate} from 'react-router'
import styles from './GroupFileExplorerOptions.module.css';
import Icon from '../Icon';
import {guidEmpty} from '../../utils/GuidValidate'
import { createConfirmToastAuto } from '../../services/queryClient'

function GroupFileExplorerOptions(props) {
  const {
    personId,
    group={},
    setChosenGroup,
    mineOrOthers,
    deleteGroup,
    getMyGroups
  } = props;

  const navigate = useNavigate()

  const sendToGroupSetupEdit = (event) => {
    navigate(`/groupAddNew/${group.groupId}`)
  }

  const sendToGroupPeopleEdit = () => {
    navigate(`/groupContacts/${group.groupId}`)
  }

  const handleDeleteGroup = () => {
    deleteGroup(personId, group.groupId)
    setTimeout(() => getMyGroups(personId), 500)
    setChosenGroup('')
  }

  const handleShowDeleteMessage = () => {
    createConfirmToastAuto(`<div>Are you sure you want to delete this group?</div>`, handleDeleteGroup)
  }

  return (
    <div>
      <div className={styles.container}>
        {mineOrOthers === 'Mine' && group.groupId !== guidEmpty &&
          <a onClick={sendToGroupSetupEdit} className={styles.menuItem}
             title={'Edit this group setup'}>
            <Icon pathName={`pencil0`}
                  premium={true}/>
          </a>
        }
        {mineOrOthers === 'Mine' &&
          <a onClick={sendToGroupPeopleEdit} className={styles.menuItem}
             title={'Edit this group setup'}>
            <Icon pathName={`users2`}
                  premium={true}/>
          </a>
        }
        { group.groupId !== guidEmpty &&
          <a onClick={handleShowDeleteMessage} title={`Delete this group`}>
            <Icon pathName={`trash2`}
                  premium={true}/>
          </a>
        }
      </div>
    </div>
  )
}

export default GroupFileExplorerOptions;