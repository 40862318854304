import React, {useState} from 'react';
import styles from './MenuGroupPopup.module.css';
import Icon from '../Icon'

function MenuGroupPopup(props) {
	const {menu} = props;
	const [opened, setOpened] = useState(false);

	return (
		<div className={styles.container}>
			<div className={opened ? styles.show : styles.hide}>
				{menu}
			</div>
			<div className={styles.menuIcon} onClick={() => setOpened(!opened)}>
				<Icon pathName={'menu_lines'} premium={true} fillColor={'white'} className={styles.iconSize}/>
			</div>
		</div>
	)
}

export default MenuGroupPopup;