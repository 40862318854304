import React, { useState, useEffect } from 'react';
import styles from './ToggleBoardMobile.module.css'
import DropDownFloatCustom from '../DropDownFloatCustom'
import ButtonDropDown from '../ButtonDropDown'
import { createInfoToastAuto } from '../../services/queryClient'

function ToggleBoardMobile(props) {
	const { 
		addList, 
		addListItem, 
		addParagraphBreak,
		addParagraphSentence, 
		addSentence, 
		changeList, 
		changeStyle, 
		chapterId,
		clearAllEditTypes, 
		currentElement, 
		deleteListItem, 
		deleteParagraphBreak, 
		deleteSentence, 
		editTrackChanges, 
		goToNextSentence, 
		isTranslation, 
		keepCommentOn,
		keyIndex,
		moveSentences, 
		moveTranslationToEditor, 
		personId,
		reorderListItems, 
		segments,
		setAddList, 
		setAddListItem, 
		setAddParagraphBreak, 
		setAddParagraphSentence, 
		setAddSentence, 
		setChangeList,
		setChangeStyle, 
		setDeleteListItem, 
		setDeleteParagraphBreak, 
		setDeleteSentence, 
		setEditTrackChanges, 
		setGoToNextSentence, 
		setKeepCommentOn, 
		setMoveSentences, 
		setMoveTranslationToEditor, 
		setReorderListItems, 
		convertAddListSequence,
		moveSequence,
		changeStyleSequence,
		showEditIcons,
		setShowEditIcons,
	} = props

	const [choice, setChoice] = useState()
	const [isInList, setIsInList] = useState()
	const [options, setOptions] = useState()
	const [hasListStructure, setHasListStructure] = useState()
	const [isAuthor, setIsAuthor] = useState()

	useEffect(() => {
		if (chapterId && segments?.length > 0) {
			const listItems = segments.filter(m => m.type === 'LI' && m.chapterId === chapterId)
			setHasListStructure(listItems?.length > 0)
			const newIsAuthor = segments.filter(m => m.workOwners?.length > 0 && m.workOwners.indexOf(personId) > -1 && m.chapterId === chapterId)
			setIsAuthor(newIsAuthor?.length > 0)
		} else {
			setHasListStructure(false)
			setIsAuthor(false)
		}
	}, [chapterId, segments])

	useEffect(() => {
		let newOptions = [{ id: '0', label: <span className={styles.labelClear}><i>none (clear)</i></span>}]
		if (!isTranslation) newOptions.push({ id: 'ADDLIST', label: <span className={styles.label}>Add list</span> , text: 'Add list'})
		if (!isTranslation && hasListStructure) newOptions.push({id: 'ADDLISTITEM', label: <span className={styles.label}>Add list item</span>, text: 'Add list item'})
		if (!isTranslation && !isAuthor) newOptions.push({id: 'ADDPARAGRAPH', label: <span className={styles.label}>Add paragraph break</span>, text: 'Add paragraph break'})
		if (!isTranslation && !isAuthor) newOptions.push({id: 'ADDPARAGRAPHSENTENCE', label: <span className={styles.label}>Add paragraph and sentence</span>, text: 'Add paragraph and sentence'})
		if (!isTranslation && !isAuthor) newOptions.push({id: 'ADDSENTENCE', label: <span className={styles.label}>Add sentence</span>, text: 'Add sentence'})
		if (!isTranslation) newOptions.push({id: 'CHANGELIST', label: <span className={styles.label} disabled={!isInList}>Change list type</span>, text: ' disabled={!isInList}>Change list type'})
		if (!isTranslation) newOptions.push({id: 'CHANGESTYLE', label: <span className={styles.label}>Change style</span>, text: 'Change style'})
		if (!isTranslation && hasListStructure) newOptions.push({id: 'DELETELISTITEM', label: <span className={styles.label}>Delete list item</span>, text: 'Delete list item'})
		if (!isTranslation && !isAuthor) newOptions.push({id: 'DELETEPARAGRAPH', label: <span className={styles.label}>Delete paragraph break</span>, text: 'Delete paragraph break'})
		if (hasListStructure) newOptions.push({id: 'REORDERLISTITEMS', label: <span className={styles.label}>Reorder a list level</span>, text: 'Reorder a list level'})
		if (!isTranslation) newOptions.push({id: 'DELETESENTENCE', label: <span className={styles.label}>Delete sentence</span>, text: 'Delete sentence'})
		if (!isTranslation) newOptions.push({id: 'MOVESENTENCE', label: <span className={styles.label}>Move one or more sentences</span>, text: 'Move one or more sentences'})
		
		newOptions.push({ id: '', label: <span className={styles.labelBold}>Switches - on or off</span> })
		newOptions.push({ id: 'KEEPCOMMENTON', label: <div className={styles.labelRow}>{keepCommentOn ? <div className={styles.offOn}><div style={{ color: 'forestgreen' }}>ON</div> - </div> : <div className={styles.offOn}><div style={{ color: 'maroon' }}>OFF</div> - </div>}<div>Keep Comment On</div></div> })
		newOptions.push({ id: 'SHOWTRACKCHANGES', label: <div className={styles.labelRow}>{editTrackChanges ? <div className={styles.offOn}><div style={{ color: 'forestgreen' }}>ON</div> - </div> : <div className={styles.offOn}><div style={{ color: 'maroon' }}>OFF</div> -  </div>}<div>Show Track Changes</div></div> })
		newOptions.push({ id: 'SHOWEDITICONS', label: <div className={styles.labelRow}>{showEditIcons ? <div className={styles.offOn}><div style={{ color: 'forestgreen' }}>ON</div> - </div> : <div className={styles.offOn}><div style={{ color: 'maroon' }}>OFF</div> - </div>}<div>Show Edit Icons</div></div> })
		if (isTranslation) newOptions.push({ id: 'MOVETRANSLATION', label: <div className={styles.labelRow}>{moveTranslationToEditor ? <div className={styles.offOn}><div style={{ color: 'forestgreen' }}>ON</div> - </div> : <div className={styles.offOn}><div style={{ color: 'maroon' }}>OFF</div> - </div>}<div>Move Translation to Editor</div></div> })
		if (isTranslation) newOptions.push({ id: 'GOTONEXTSENTENCE', label: <div className={styles.labelRow}>{goToNextSentence ? <div className={styles.offOn}><div style={{ color: 'forestgreen' }}>ON</div> - </div> : <div className={styles.offOn}><div style={{ color: 'maroon' }}>OFF</div> - </div>}<div>Go to Next Sentence</div></div> })

		setOptions(newOptions)
	}, [hasListStructure, isAuthor, isTranslation, keepCommentOn, editTrackChanges, showEditIcons, moveTranslationToEditor, goToNextSentence])

	useEffect(() => {
		if (currentElement && currentElement.nodeName) {
			setIsInList(currentElement.nodeName === 'LI'
				|| currentElement.nodeName === 'OL'
				|| currentElement.nodeName === 'UL'
				|| currentElement?.parentElement?.nodeName === 'LI'
				|| currentElement?.parentElement?.nodeName === 'OL'
				|| currentElement?.parentElement?.nodeName === 'UL')
		}
	}, [currentElement, keepCommentOn, editTrackChanges, showEditIcons, moveTranslationToEditor, goToNextSentence])


	useEffect(() => {
		if (addList) {
			setChoice("ADDLIST")
		} else if (changeList) {
			setChoice("CHANGELIST")
		} else if (addListItem) {
			setChoice("ADDLISTITEM")
		} else if (addParagraphBreak) {
			setChoice("ADDPARAGRAPH")
		} else if (addParagraphSentence) {
			setChoice("ADDPARAGRAPHSENTENCE")
		} else if (addSentence) {
			setChoice("ADDSENTENCE")
		} else if (deleteListItem) {
			setChoice("DELETELISTITEM")
		} else if (deleteParagraphBreak) {
			setChoice("DELETEPARAGRAPH")
		} else if (deleteSentence) {
			setChoice("DELETESENTENCE")
		} else if (reorderListItems) {
			setChoice("REORDERLISTITEMS")
		} else if (moveSentences) {
			setChoice("MOVESENTENCE")
		} else if (changeStyle) {
			setChoice("CHANGESTYLE")
			//The ones below are switches which can be turned off and on so we don't want these to be chosen in the list. We just want to launch the function that toggles the setting.
			//So we set them to 0 as chosen.
		} else if (keepCommentOn) {
			setChoice("0")
		} else if (editTrackChanges) {
			setChoice("0")
		// } else if (showEditorFullText) {  //This doesn't make sense for mobile since we have to switch between editors anyway.
		// 	setChoice("0")
		} else if (moveTranslationToEditor) {
			setChoice("0")
		} else if (goToNextSentence) {
			setChoice("0")
		} else if (showEditIcons) {
			setChoice("0")
		} else {
			setChoice("0")
		}
	}, [moveSentences, editTrackChanges, addParagraphSentence, addSentence, deleteSentence, addList, changeList, addListItem, addParagraphBreak, deleteParagraphBreak, goToNextSentence,
		deleteListItem, reorderListItems, changeStyle, moveTranslationToEditor, keepCommentOn])

	const showAndRepeatMessage = (choice) => {
		//This is a trick to be able to show the message again in case it went by too fast and the user didn't realize they could click on the 'pause' button to hold the long messages
		//This way they can click on the drop down list option again to repeat the message. Otherwise, the onChange method of the select list will not let the message repeat
		//	because the choice really hasn't "changed". But this function si called by the 'onClick' of the select list.
		if (choice === "MOVESENTENCE") {
			createInfoToastAuto(
				`<div>
					<div>1. Click on the beginning sentence.</div>
					<div>2. Click on the last sentence or click on the icon at the end of the last sentence.</div>
					<div>3. Click on a target where you want to move the sentence(s).</div></div>`)
		} else if (choice === "CHANGESTYLE") {
			createInfoToastAuto(
				`<div>1. Click on the beginning sentence.
							2. Choose the last sentence (or its icon).
							3. A pop-up will give you style options.</div>`)
		}	
	}

	const handleSetChoice = (choice) => {
		if (!choice || choice == 0) {
			clearAllEditTypes()
		} else if (choice === "ADDLIST") {
			setAddList(true)
			setChoice(choice)
		} else if (choice === "CHANGELIST") {
			setChangeList(true)
			setChoice(choice)
		} else if (choice === "ADDLISTITEM") {
			setAddListItem(true)
			setChoice(choice)
		} else if (choice === "ADDPARAGRAPH") {
			setAddParagraphBreak(true)
			setChoice(choice)
		} else if (choice === "ADDPARAGRAPHSENTENCE") {
			setAddParagraphSentence(true)
			setChoice(choice)
		} else if (choice === "ADDSENTENCE") {
			setAddSentence(true)
			setChoice(choice)
		} else if (choice === "DELETELISTITEM") {
			setDeleteListItem(true)
			setChoice(choice)
		} else if (choice === "DELETEPARAGRAPH") {
			setDeleteParagraphBreak(true)
			setChoice(choice)
		} else if (choice === "DELETESENTENCE") {
			setDeleteSentence(true)
			setChoice(choice)
		} else if (choice === "REORDERLISTITEMS") {
			setReorderListItems(true)
			setChoice(choice)
		} else if (choice === "MOVESENTENCE") {
			setMoveSentences(true)
			setChoice(choice)
		} else if (choice === "CHANGESTYLE") {
			setChangeStyle(true)
			setChoice(choice)
		} else if (choice === "KEEPCOMMENTON") {
			setKeepCommentOn(!keepCommentOn)
		} else if (choice === "SHOWTRACKCHANGES") {
			setEditTrackChanges(!editTrackChanges)
		// } else if (choice === "EDITORFULLTEXT") {  //This doesn't make sense for mobile since we have to switch between editors anyway.
		// 	setShowEditorFullText(!showEditorFullText)
		} else if (choice === "MOVETRANSLATION") {
			setMoveTranslationToEditor(!moveTranslationToEditor)
		} else if (choice === "GOTONEXTSENTENCE") {
			setGoToNextSentence(!goToNextSentence)
		} else if (choice === "SHOWEDITICONS") {
			setShowEditIcons(!showEditIcons)
		}
	}

	const getChoiceLabel = () => {
		if (choice && choice !== '0') {
			let choiceOption = options.filter(m => m.id === choice)[0]
			return choiceOption ? choiceOption.text.length > 13 ? choiceOption.text.substring(0,13) + '...' : choiceOption.text : ''
		}
		return ''
	}

	return (
		<div className={styles.container} title={'Edit options and switches'} key={keyIndex}>
			<DropDownFloatCustom key={'EditOptionsSwitches'}
				iconAvatar={<ButtonDropDown label={'Options'} />}
				header={<div>Edit options and switches</div>}
				onSelect={handleSetChoice}
				onClickForMessage={showAndRepeatMessage}
				listOptions={options}
				showChoiceLabel
				choiceLabel={getChoiceLabel()} />
		</div>
	)
}

export default ToggleBoardMobile
