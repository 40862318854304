import * as types from '../actions/actionTypes';

export default function(state=[], action) {
    switch(action.type) {
        case types.FRIEND_INVITATION:
            return action.payload;

        default:
            return state;
    }
}

export const selectFriendInvitation = (state) => state;
