import React from 'react';
import styles from './GiveAccessToEditorsView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import OneFJefFooter from '../../components/OneFJefFooter';
import FlexTabularScroll from '../../components/FlexTabularScroll';
import CheckboxCheckmark from '../../assets/checkbox/CheckboxCheckmark.png'
import CheckboxPartial from '../../assets/checkbox/CheckboxPartial.png'
import CheckboxEmpty from '../../assets/checkbox/CheckboxEmpty.png'

function GiveAccessToEditorsView(props) {
  const {fileTreeExplorer={mine: { files: [] }}, contactSummaries=[] } = props

  const works =  fileTreeExplorer.mine.files || []

  let data = []
  let headings = [{
      label: '',
      cellColor: 'white'
    }]

  if (!works || works.length === 0) {
    headings.push({
      verticalText: true,
      label: <div className={styles.narrowLineRed}>No documents entered yet</div>
    })
  }

  works.forEach(s => {
    headings.push({
      verticalText: true,
      label: <div className={styles.narrowLine}>{s.workName}</div>
    })
  })

  contactSummaries.forEach((contact, i) => {
    let row = [{
      cellColor: 'background',
      clickFunction: () => {},
      value: <div onClick={() => {}} className={styles.editorName}>
              {`${contact.firstName} ${contact.lastName}`}
              <span className={styles.editorScore}>{contact.editorScore}</span>
            </div>
    }]

    works.forEach(work => {
      //If there are more than one chapter and the editor doesn't have access to at least one chapter, then her access is partial (the checkbox with the line)
      let hasAccess = false
      let hasNotAChapterAccess = false
      let accessChapters = []
      let hasOneChapter = work.chapters.length === 1

      if (hasOneChapter) {
        hasAccess = contact.editorAssign.filter(c => c.workId === work.workId)[0]
        accessChapters = contact.editorAssign.reduce((acc,m) => {
          acc = acc && acc.length > 0 ? acc.concat(m.chapterId) : [m.chapterId]
          return acc
        }, [])

      } else {
        work.chapters.forEach(chapter => {
          contact.editorAssign.forEach(assign => {
            accessChapters = contact.editorAssign.reduce((acc,m) => {
              acc = acc && acc.length > 0 ? acc.concat(m.chapterId) : [m.chapterId]
              return acc
            }, [])

            if (assign.chapterId === chapter.chapterId) {
              hasAccess = true
            } else {
              hasNotAChapterAccess = true
            }
          })
        })
      }
      let image
      let clickFunction
      if (hasAccess) {
        if (hasNotAChapterAccess) {
          image = CheckboxPartial
          clickFunction = () => props.setEditorAssign(false, work.workId, contact.personId, work.personId, accessChapters.toString(), '1')
        } else {
          image = CheckboxCheckmark
          clickFunction = () => props.setEditorAssign(false, work.workId, contact.personId, work.personId, accessChapters.toString(), '1')
        }
      } else {
        image = CheckboxEmpty
        let chapterList = work.chapters.reduce((acc, m) => acc && acc.length > 0 ? acc.concat(m.chapterId) : [m.chapterId],[])
        clickFunction = () => props.setEditorAssign(true, work.workId, contact.personId, work.personId, chapterList.toString(), '1')
      }
      row.push({
        value:  <div onClick={clickFunction} className={styles.link}>
                  <img src={image} height={18}/>
                </div>
      })
    })
    data.push(row)
  })

  return (
    <div className={styles.container}>
      <div className={globalStyles.pageTitle}>Give Access by Editor</div>
      <div className={styles.borderBackground}>
        <div className={styles.scroll}>
          <FlexTabularScroll headings={headings} data={data} noCount={true}/>
        </div>
      </div>
      <OneFJefFooter />
    </div>
  )
}

export default GiveAccessToEditorsView