import React from 'react';
import { useParams } from 'react-router-dom'
import LoginView from '../views/LoginView';
import {connect} from 'react-redux';
import * as loginUser from '../actions/login.js';
import {selectMe, selectEditorInviteGUIDResponse} from '../store.js';

// takes values from the redux store and maps them to props
const mapStateToProps = state => {
  const inviteResponse = selectEditorInviteGUIDResponse(state);

  const tempData = [{
    amt: 0,
    date: "2023-06-11T17:29:55",
    name: "6/11",
    uv: 0,
    pv: 0,
  },
    {
      amt: 0,
      date: "2023-06-12T17:29:55",
      name: "6/12",
      uv: 222,
      pv: 2,
    },
    {
      amt: 0,
      date: "2023-06-13T17:29:55",
      name: "6/13",
      uv: 255,
      pv: 11,
    },
    {
      amt: 0,
      date: "2023-06-14T17:29:55",
      name: "6/14",
      uv: 306,
      pv: 14,
    },
    {
      amt: 0,
      date: "2023-06-15T17:29:55",
      name: "6/15",
      uv: 351,
      pv: 14,
    },
    {
      amt: 0,
      date: "2023-06-16T17:29:55",
      name: "6/16",
      uv: 453,
      pv: 31,
    },
    {
      amt: 0,
      date: "2023-06-17T17:29:55",
      name: "6/17",
      uv: 757,
      pv: 55,
    },
    {
      amt: 0,
      date: "2023-06-18T17:29:55",
      name: "6/18",
      uv: 803,
      pv: 55,
    },
    {
      amt: 0,
      date: "2023-06-19T17:29:55",
      name: "6/19",
      uv: 1414,
      pv: 75,
    },
    {
      amt: 0,
      date: "2023-06-20T17:29:55",
      name: "6/20",
      uv: 1500,
      pv: 80,
    },
    {
      amt: 0,
      date: "2023-06-21T17:29:55",
      name: "6/21",
      uv: 1544,
      pv: 121,
    },
    {
      amt: 0,
      date: "2023-06-22T17:29:55",
      name: "6/22",
      uv: 1333,
      pv: 121,
    },
    {
      amt: 0,
      date: "2023-06-23T17:29:55",
      name: "6/23",
      uv: 1457,
      pv: 121,
    },
    {
      amt: 0,
      date: "2023-06-24T17:29:55",
      name: "6/24",
      uv: 1600,
      pv: 180,
    },
    {
      amt: 0,
      date: "2023-06-25T17:29:55",
      name: "6/25",
      uv: 1600,
      pv: 201,
    },
    {
      amt: 0,
      date: "2023-06-26T17:29:55",
      name: "6/26",
      uv: 1750,
      pv: 230,
    },
    {
      amt: 0,
      date: "2023-06-27T17:29:55",
      name: "6/27",
      uv: 1818,
      pv: 235,
    },
    {
      amt: 0,
      date: "2023-06-28T17:29:55",
      name: "6/28",
      uv: 2000,
      pv: 250,
    },
    {
      amt: 0,
      date: "2023-06-29T17:29:55",
      name: "6/29",
      uv: 2024,
      pv: 250,
    },
  ]

  const editReportHeadings = [
    {id: 'editors', label: 'Editors', tightText: true},
    {id: 'edits', label: 'Edits Finished', verticalText: true},
    {id: 'pendingEdits', label: 'Pending Edits', verticalText: true},
    {id: 'acceptedEdits', label: 'Accepted', verticalText: true},
    {id: 'nonAcceptedEdits', label: 'Not Accepted', verticalText: true},
    {id: 'upVotes', label: 'Up Vote', verticalText: true},
    {id: 'downVotes', label: 'Down Vote', verticalText: true},
    {id: 'trollVotes', label: 'Troll Vote', verticalText: true},
    {id: 'votesCast', label: 'Votes Cast', verticalText: true},
  ]

  const editReportData = [
    []

  ]

  return {
    loginData: selectMe(state),
    inviteResponse,
    tempData,
    editReportHeadings,
    editReportData,
  }
};

// binds the result of action creators to redux dispatch, wrapped in callable functions
const bindActionsToDispatch = dispatch => ({
  login: (userData, inviteResponse, salta, sendto, authLogin) => dispatch(loginUser.login(userData, inviteResponse, salta, sendto, authLogin)),
  logout: () => dispatch(loginUser.logout()),
});

// takes the result of mapStateToProps as store, and bindActionsToDispatch as actions
// returns the final resulting props which will be passed to the component
const mergeAllProps = (store, actions) => ({
  ...store, ...actions,
  title: "Penspring"
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
  mergeAllProps
);

function Container(props) {
  const params = useParams()

  return <LoginView {...props} {...params} />
}

export default storeConnector(Container);
