import React, { useState } from 'react';
import styles from './EditorDivFormatControls.module.css';
import EditListChoice from '../EditListChoice'
import HeadlessSelectList from '../HeadlessSelectList'
import Undo from "../../assets/Edit Icons/undo.png";
import Redo from "../../assets/Edit Icons/redo.png";
import Bold from "../../assets/Edit Icons/bold.png";
import Italics from "../../assets/Edit Icons/italics.png";
import Underline from "../../assets/Edit Icons/underline.png";
import Strikeout from "../../assets/Edit Icons/strikeout.png";
import LeftAlign from "../../assets/Edit Icons/left align.png";
import CenterAlign from "../../assets/Edit Icons/center align.png";
import RightAlign from "../../assets/Edit Icons/right align.png";
import FullJustify from "../../assets/Edit Icons/full justify.png";
import LeftAlignArrow from "../../assets/Edit Icons/LeftAlignArrow.png";
import IndentArrow from "../../assets/Edit Icons/IndentArrow.png";
import BlockIndentLeftArrow from "../../assets/Edit Icons/BlockIndentLeftArrow.png";
import BlockIndentRightArrow from "../../assets/Edit Icons/BlockIndentRightArrow.png";
import UrlLink from "../../assets/Edit Icons/url link.png";
import DocScrollMatch from "../../assets/Edit Icons/doc-scroll-match.png";
import NumberedList from "../../assets/Edit Icons/numbered list.png";
import FontName from "../../assets/Edit Icons/font name.png";
import FontSize from "../../assets/Edit Icons/font size.png";
import ColorPicker from "../../assets/Edit Icons/color picker.png";
import PaintersPallet from "../../assets/Edit Icons/PaintersPallet.png";
import Subscript from "../../assets/Edit Icons/subscript.png";
import Superscript from "../../assets/Edit Icons/superscript.png";
import EraseFormatting from "../../assets/Edit Icons/eraseFormatting.png";
import EditorSaveButton from '../EditorSaveButton'
import ColorPickerModal from '../ColorPickerModal'
import TextStyleChangeModal from '../TextStyleChangeModal'
import OriginatingEditorSendModal from '../OriginatingEditorSendModal'
import DownloadButton from '../DownloadButton'
import HeadlessSelectSymbolInsert from '../HeadlessSelectSymbolInsert'

function EditorDivFormatControls(props) {
	const {
		allSelectionData,
		changeCounts,
		currentEditorDiv,
		editChosen,
		editorDivId,
		eraseFormatting,
		fontHeaderOptions,
		fontHeaderSizes,
		handleDownloadChoice,
		handleFontColorChoice,
		handleFontNameChoice,
		handleFontSizeChoice,
		handleSetEditChosen,
		hasSelectedText,
		handleHtmlCharacterInsert,
		htmlCharacterOptions, 
		isAuthor,
		isOpenLinkEntry,
		isTranslation,
		keyIndex,
		personId,
		redo,
		saveByButtonPress,
		saveSelection,
		saveWorkSpaceTime,
		scrollDocumentToMatch,
		setAddList,
		setFormatChoice,
		setIsOpenLinkEntry,
		setParagraphAlign,
		setParagraphIndentChoice,
		tabViewElement,
		undo,
		workSummary,
	} = props

	const [openTextStyleModal, setOpenTextStyleModal] = useState(false)
	const [openOriginatingAuthorSend, setOpenOriginatingAuthorSend] = useState()

	return (
		<div className={currentEditorDiv === editorDivId || workSummary.workTypeCode !== 'DISCUSSION' ? styles.iconRow : styles.hide} key={keyIndex}>
			{workSummary.workTypeCode !== 'DISCUSSION' && 
				<DownloadButton
					setOpenOriginatingAuthorSend={props.setOpenOriginatingAuthorSend}
					handleDownloadChoice={handleDownloadChoice}
					downloadOptions={props.downloadOptions}
					workSummary={workSummary}
					personId={personId} />
			}
			<img src={Undo} className={styles.logo} alt={'undo'} title={'Undo (CTRL+Z)'} onClick={undo} />
			<img src={Redo} className={styles.logo} alt={'redo'} title={'Redo (CTRL+SHIFT+Z)'} onClick={redo} />
			{/*<div className={styles.dividerLine}>|</div>*/}
			{!isTranslation &&
				<img src={Bold} className={styles.logoSmaller} alt={'edit'} title={'Bold (CTRL+B)'} onClick={() => setFormatChoice({ formatType: 'bold', fontValue: '', allSelectionData })} />
			}
			{!isTranslation &&
				<img src={Italics} className={styles.logoSmaller} alt={'edit'} title={'Italics (CTRL+I)'} onClick={() => setFormatChoice({ formatType: 'italic', fontValue: '', allSelectionData })} />
			}
			{!isTranslation &&
				<img src={Underline} className={styles.logoSmaller} alt={'edit'} title={'Underline (CTRL+U)'} onClick={() => setFormatChoice({ formatType: 'underline', fontValue: '', allSelectionData })} />
			}
			{!isTranslation &&
				<img src={Strikeout} className={styles.logoSmaller} alt={'edit'} title={'Strikeout (CTRL+SHIFT+K)'} onClick={() => setFormatChoice({ formatType: 'strikeout', fontValue: '', allSelectionData })} />
			}
			{!isTranslation &&
				<img src={Subscript} className={styles.logoMedium} alt={'edit'} title={'Subscript (CTRL+SHIFT+B)'} onClick={() => setFormatChoice({ formatType: 'subscript', fontValue: '', allSelectionData })} />
			}
			{!isTranslation &&
				<img src={Superscript} className={styles.logoMedium} alt={'edit'} title={'Superscript (CTRL+SHIFT+U)'} onClick={() => setFormatChoice({ formatType: 'superscript', fontValue: '', allSelectionData })} />
			}
			{!isTranslation &&
				<img src={EraseFormatting} className={styles.logoSmaller} alt={'edit'} title={'Erase formatting (CTRL+SHIFT+F)'} onClick={() => eraseFormatting()} />
			}
			{!isTranslation && 
				<div className={styles.dividerLine}>|</div>
			}
			{!isTranslation && 
				<img src={PaintersPallet} className={styles.logoSmaller} alt={'edit'} title={'Change styles'} onClick={() => setOpenTextStyleModal(!openTextStyleModal)} />
			}
			<div className={styles.liftIcon}>
				<HeadlessSelectList key={'FontName'}
					header={<div className={styles.listHeader}>Font name</div>}
					iconAvatar={<img src={FontName} className={styles.logo} alt={'edit'} title={'Font name'} />}
					onSelect={handleFontNameChoice}
					listOptions={fontHeaderOptions} />
			</div>
			<div className={styles.liftIcon}>
				<HeadlessSelectList key={'FontSize'}
					header={<div className={styles.listHeader}>Font size</div>}
					iconAvatar={<img src={FontSize} className={styles.logo} alt={'edit'} title={'Font size'} />}
					onSelect={handleFontSizeChoice}
					listOptions={fontHeaderSizes} />
			</div>
			<div className={styles.colorPickerIcon}>
				<ColorPickerModal
					iconAvatar={<img src={ColorPicker} className={styles.logo} alt={'edit'} title={'Font color'} />}
					onSelect={handleFontColorChoice} 
					saveSelection={saveSelection} />
			</div>
			<div className={styles.textAlignOptions}>
				<HeadlessSelectList key={'ParagraphAlignment'}
					header={<div className={styles.listHeader}>Paragraph alignment</div>}
					iconAvatar={<img src={LeftAlignArrow} className={styles.logo} alt={'edit'} />}
					onSelect={() => {}}
					listOptions={[
						{
							id: 'left',
							label: 
								<div className={styles.rowText} onClick={() => setParagraphAlign('left')} >
									<img src={LeftAlign} className={styles.logo} alt={'edit'} title={'Paragraph left align'} />
									Left align
								</div>
						},
						{
							id: 'center',
							label: 
								<div className={styles.rowText} onClick={() => setParagraphAlign('center')} >
									<img src={CenterAlign} className={styles.logo} alt={'edit'} title={'Paragraph center align'} />
									Center align
								</div>
						},
						{
							id: 'right',
							label:
								<div className={styles.rowText} onClick={() => setParagraphAlign('right')} >
									<img src={RightAlign} className={styles.logo} alt={'edit'} title={'Paragraph right align'} />
									Right align
								</div>
						},
						{
							id: 'justify',
							label:
								<div className={styles.rowText} onClick={() => setParagraphAlign('justify')} >
									<img src={FullJustify} className={styles.logo} alt={'edit'} title={'Paragraph full justify'} />
									Justify left and right
								</div>
						},
					]} />
			</div>
			<div className={styles.liftIcon}>
				<HeadlessSelectList key={'Indent'}
					header={<div className={styles.listHeader}>Paragraph indent</div>}
					iconAvatar={<img src={IndentArrow} className={styles.logo} alt={'edit'} title={'Indent'}/>}
					onSelect={(value) => setParagraphIndentChoice('textIndent', value)}
					listOptions={Array.from({ length: 30 }, (v, i) => ({ id: i, label: `${i} characters` }))} />
			</div>
			<div className={styles.liftIcon}>
				<HeadlessSelectList key={'BlockIndentMarginLeft'}
					header={<div className={styles.listHeader}>Block left indent</div>}
					iconAvatar={<img src={BlockIndentLeftArrow} className={styles.logo} alt={`edit`} title={'Block indent left side'} />}
					onSelect={(value) => setParagraphIndentChoice('marginLeft', value)}
					listOptions={Array.from({ length: 30 }, (v, i) => ({ id: i, label: `${i} characters` }))} />
			</div>
			<div className={styles.liftIcon}>
				<HeadlessSelectList key={'BlockIndentMarginRight'}
					header={<div className={styles.listHeader}>Block right indent</div>}
					iconAvatar={<img src={BlockIndentRightArrow} className={styles.logo} alt={'edit'} title={'Block indent right side'} />}
					onSelect={(value) => setParagraphIndentChoice('marginRight', value)}
					listOptions={Array.from({ length: 30 }, (v, i) => ({ id: i, label: `${i} characters` }))} />
			</div>
			{!isTranslation && //isAuthor &&
				<img src={NumberedList} className={styles.logo} alt={'edit'} title={'numbered list'} onClick={() => setAddList(true)} />
			}
			{/*<div className={styles.dividerLine}>|</div>*/}
			{/*<img src={Images} className={styles.logo} alt={'edit'} title={'Upload image'}/>*/}
			<div className={styles.liftIcon}>
				<HeadlessSelectSymbolInsert 
					handleHtmlCharacterInsert={handleHtmlCharacterInsert}
					htmlCharacterOptions={htmlCharacterOptions}
				/>
			</div>
			<img src={UrlLink} className={styles.logoSmaller} alt={'edit'} title={'Internet link'} onClick={() => setIsOpenLinkEntry(!isOpenLinkEntry)} />
			{!isTranslation && props.personId === workSummary.authorPersonId &&
				<EditorSaveButton
					changeCounts={changeCounts}
					label={'Save'}
					saveWorkSpaceTime={saveWorkSpaceTime}
					saveByButtonPress={saveByButtonPress}
					addClassName={styles.editorSaveButton} />
			}
			{tabViewElement && tabViewElement.innerHTML.length > 100 &&
				<img src={DocScrollMatch} className={styles.docScrollMatch} alt={'edit'} title={'Scroll documents to match location'} onClick={() => scrollDocumentToMatch()} />
			}
			<div className={styles.editList}>
				<EditListChoice
					editOptions={props.editOptions}
					handleSetEditChosen={handleSetEditChosen}
					editChosen={editChosen}
					scrollDocumentToMatch={scrollDocumentToMatch} />
				{isTranslation &&
					<div className={styles.translationMode}>Translation Mode</div>
				}
			</div>
			<TextStyleChangeModal
				label='Text Style Change Edit' 
				isOpen={!!openTextStyleModal}
				onClose={() => setOpenTextStyleModal('')}
				onCancel={() => { setOpenTextStyleModal('') }}
				updateChangeStyleEdit={() => {}}
				changeStyleEditArray={[]}
				hasSelectedText={hasSelectedText}
				savedRange={allSelectionData?.savedRange}
				savedElementsForEdit={allSelectionData?.savedElementsForEdit}
				savedSpansForStyleChange={allSelectionData?.savedSpansForStyleChange}
				{...props} />
			<OriginatingEditorSendModal
				isOpen={openOriginatingAuthorSend}
				onCancel={() => setOpenOriginatingAuthorSend(false)}
				setOpenOriginatingAuthorSend={setOpenOriginatingAuthorSend}
				downloadOptions={props.downloadOptions}
				workSummary={workSummary}
				sendOriginatingEditorContents={props.sendOriginatingEditorContents}
				{...props} />

		</div>
	)
}

export default EditorDivFormatControls
