import React from 'react';
import {useNavigate} from 'react-router-dom'
import styles from './GroupFolder.module.css';
import Icon from '../Icon'

export default ({mineOrOthers, group={}, workFolderId}) => {
  const navigate = useNavigate()
  const groupNameShort = group && group.groupName ? group.groupName.length > 150 ? group.groupName.substring(0,150) + '...' : group.groupName : ''

  return !groupNameShort ? null : (
    <div className={styles.container}>
      <div className={styles.justifyRight}>
        <div className={styles.groupName} title={group && group.groupName}>
          <div className={styles.indent}>
            {mineOrOthers === 'Mine' &&
              <div onClick={group && group.groupId ? () => navigate(`/groupContacts/${group.groupId}`) : () => navigate(`/groupChooseExisting/${workFolderId}`)}
                style={{ marginTop: '4px', cursor: 'pointer' }}>
                <Icon pathName={'pencil0'} premium={true} fillColor={'black'} className={styles.imageSmaller} smaller />
              </div>
            }
            <div className={styles.smallLabel}>
              Group:
              <span className={styles.groupNameShort}>{groupNameShort || 'No group'}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
