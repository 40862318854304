import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom'
import GroupContactsView from '../views/GroupContactsView';
import {connect} from 'react-redux';
import * as actionContacts from '../actions/contacts.js';
import * as actionGroups from '../actions/groups.js';
import * as editorInviteWork from "../actions/editor-invite-work";
import * as actionEditorInvitePending from "../actions/editor-invite-pending";
import {selectContacts, selectMe, selectGroups, selectGroupContactsWorks, selectEditorInvitePending} from '../store.js';
import {guidEmpty} from '../utils/GuidValidate'

const mapStateToProps = state => {
	let me = selectMe(state);
	const groups = selectGroups(state)

	let groupList = groups && groups.length > 0 && groups.reduce((acc, m) => {
			let option = {id: m.groupId, label: m.groupName}
			return acc = acc && acc.length > 0 ? acc.concat(option) : [option]
		},[])

	return {
		personId: me.personId,
		group: selectGroupContactsWorks(state),
		contacts: selectContacts(state),
		groups,
		groupList,
		editorInvitePending: selectEditorInvitePending(state),
		bulkDelimiterOptions: [
			{id: 'tab', label: 'tab'},
			{id: 'comma', label: ', comma'},
			{id: 'semicolon', label: '; semicolon'},
			{id: 'hyphen', label: '- hyphen'},
		],
		fieldOptions: [
			{id: 'firstName', label: 'first name'},
			{id: 'lastName', label: 'last name'},
			{id: 'fullNameLastFirst', label: 'full name (last name first)'},
			{id: 'fullNameFirstFirst', label: 'full name (first name first)'},
			{id: 'memberId', label: 'internal member id'},
			{id: 'emailAddress', label: 'email address'},
			{id: 'phone', label: 'phone'},
		],
	}
}

const bindActionsToDispatch = dispatch => ({
  getGroups: (personId) => dispatch(actionGroups.getGroups(personId)),
  deleteGroup: (personId, groupId) => dispatch(actionGroups.deleteGroup(personId, groupId)),
	toggleGroupJoinLink: (personId, groupId, runFunction) => dispatch(actionGroups.toggleGroupJoinLink(personId, groupId, runFunction)),
	getContacts: (personId) => dispatch(actionContacts.getContacts(personId)),
	getGroupContactsWorks: (personId, groupId) => dispatch(actionGroups.getGroupContactsWorks(personId, groupId)),
	assignContactToGroup: (personId, contact_personId, groupId, firstName, lastName, photo, emailAddress, includeIndividualContact, isDelete) => dispatch(actionGroups.assignContactToGroup(personId, contact_personId, groupId, firstName, lastName, photo, emailAddress, includeIndividualContact, isDelete)),
	sendEditorInvite: (user_PersonId, editorInviteName, editorInviteWorkAssign, sendTo) => dispatch(editorInviteWork.sendEditorInvite(user_PersonId, editorInviteName, editorInviteWorkAssign, sendTo)),
	getInvitesPending: (personId) => dispatch(actionEditorInvitePending.getInvitesPending(personId)),
	sendFirstNotifications: (personId, groupId) => dispatch(actionEditorInvitePending.sendFirstNotifications(personId, groupId)),
	deleteInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.deleteInvite(personId, friendInvitationId)),
	acceptInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.acceptInvite(personId, friendInvitationId)),
	resendInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.resendInvite(personId, friendInvitationId)),
	setGroupMembers: (personId, groupId, members) => dispatch(actionGroups.setGroupMembers(personId, groupId, members)),
});

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
);

function Container(props) {
	const {personId, getGroups, getGroupContactsWorks, getContacts, getInvitesPending} = props
	const params = useParams()

	useEffect(() => {
		if (personId && personId !== guidEmpty) {
			getGroups(personId)
			getGroupContactsWorks(personId, params.groupId)
			getContacts(personId);
			getInvitesPending(personId)
		}
	}, [personId, params.groupId])

	return <GroupContactsView {...props} groupId={params.groupId}/>
}

export default storeConnector(Container);
