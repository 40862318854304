import React, { useState, useEffect, useRef } from 'react'
import globalStyles from '../../utils/globalStyles.module.css'
import styles from './InputDataList.module.css'
import classes from 'classnames'
import Required from '../Required'
import Icon from '../Icon'

//If multiple and buttonLabel is filled in, that is a subtle distinction between the use of a one-entry-only control and a multiple record entry
//	In multiple entry case, there is the add button as well as the table display below as well as the clear-control function onBlur or on click of Add.
//	Notice that the click of the Add button doesn't do anything since it is just the action of onBlur that does the saving of the record.

function InputDataList(props) {
  const {
    buttonIcon,
    buttonLabel,
    className,
    clearTextValue,
    disabled,
    error,
    height,
    id,
    label,
    labelClass,
    labelLeft,
    maxwidth,
    multiple,
    name,
    onChange,
    options,
    required,
    removeFunction,
    resetClearTextValue,
    selectClass,
    value,
    whenFilled,
    listAbove
  } = props

  let inputRef = useRef(null)
  let menuThing = useRef(null) //I don't see this used anywhere.
  
  const [isInit, setIsInit] = useState()
  const [textValue, setTextValue] = useState()
  const [localValue, setLocalValue] = useState()

  useEffect(() => {
    inputRef.current.addEventListener("change", getValue)
    return () => inputRef.current.removeEventListener("change", getValue)
  }, [])

  useEffect(() => {
    if (!isInit && props.value && props.value.length > 0) {
      setIsInit(true)
      setTextValue(props.value.label)
      setLocalValue(props.value)
    }
  }, [props.value])

  useEffect(() => {
    if (clearTextValue) {
      setTextValue('')
      setLocalValue('')
      resetClearTextValue()
    }
  }, [clearTextValue])


  const getValue = (event) => {
    const { onChange, options, multiple } = props
    let localValue = {...localValue}
    let option = options && options.length > 0 && options.filter(m => m.label === event.target.value)[0]
    if (option && option.id) {
      option = { id: option.id, label: option.label }
      let isDuplicate = false
      localValue && localValue.length > 0 && localValue.forEach(m => {
        if (m.id === option.id) isDuplicate = true
      })
      if (!isDuplicate) {
        localValue = localValue && localValue.length > 0 ? localValue.concat(option) : [option]
        setLocalValue(localValue)
        onChange(multiple ? localValue : localValue && localValue.length > 0 && localValue[0])
      }
      multiple && setTextValue('') //If this is a multiple record control, then erase the text and let the new record be written in the table below.
    }
  }

  const handleTextChange = (event) => setTextValue(event.target.value)

  const handleRemove = (id) => {
    let localValue = { ...localValue }
    localValue = localValue && localValue.length > 0 && localValue.filter(m => m.id !== id)
    onChange(localValue)
    removeFunction(id)
    setLocalValue(localValue)
  }

  const localClearTextValue = () => {
    setTextValue('')
    setLocalValue('')
    props.onChange([])
  }

  return (
    <div className={classes(styles.container, className)}>
      <div className={styles.row}>
        <div>
          <div className={styles.row}>
            <label htmlFor={name} className={classes(labelLeft ? styles.labelLeft : styles.labelTop, labelClass, required ? styles.lower : '')}>
              {label}
            </label>
            <Required setIf={required} setWhen={whenFilled} />
          </div>
          {multiple && listAbove &&
            <div className={styles.moreBottom}>
              {value && value.length > 0 && value.map((m, i) =>
                <div key={i} className={classes(styles.text, styles.row)} onClick={() => handleRemove(m.id)}>
                  <div className={classes(globalStyles.remove, styles.removePosition)}>remove</div>
                  <div className={styles.listTextPosition}>{m.label}</div>
                </div>
              )}
            </div>
          }
          <input type="text"
            ref={inputRef}
            list={name || id}
            value={textValue || ''}
            onChange={handleTextChange}
            autoComplete={'dontdoit'}
            className={classes(styles.editControl, styles[`size${height}`], selectClass, styles[`maxwidth${maxwidth}`])}
            disabled={disabled} />
          <datalist id={name || id} name={name || id} ref={menuThing} autoComplete={'dontdoit'} className={styles.maxWidth}>
            {options && options.length > 0 && options.map((m, i) =>
              <option key={i} id={m.id} value={m.label} className={styles.maxWidth} />
            )}
          </datalist>
          {error && <div className={styles.alertMessage}>{error}</div>}
        </div>
        {textValue && <Icon pathName={'cross'} fillColor={'maroon'} className={required ? styles.iconCrossTop : styles.iconCross} onClick={clearTextValue} />}
        {multiple && buttonLabel &&
          <div className={classes(globalStyles.link, styles.row, styles.topPosition)}>
            {buttonIcon && <Icon pathName={buttonIcon} className={styles.iconSmall} fillColor={'#105815'} />}
            <div>{buttonLabel}</div>
          </div>
        }
      </div>
      {multiple && !listAbove &&
        <div className={styles.moreBottom}>
          {value && value.length > 0 && value.map((m, i) =>
            <div key={i} className={classes(styles.text, styles.row)} onClick={() => handleRemove(m.id)}>
              <div className={classes(globalStyles.remove, styles.removePosition)}>remove</div>
              <div className={styles.listTextPosition}>{m.label}</div>
            </div>
          )}
        </div>
      }
    </div>
  )
}

export default InputDataList