import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router'
import styles from './WorkSummaryLine.module.css';
import MenuDocumentPopup from '../MenuDocumentPopup'
import WorkFileExplorerOptions from '../WorkFileExplorerOptions'
import SelectSingleDropDown from '../SelectSingleDropDown'
import classes from 'classnames'
import DocumentStats from '../DocumentStats'
import AssessmentStats from '../AssessmentStats'
import Icon from '../Icon'
import { useMediaQuery } from 'react-responsive';

function WorkSummaryLine(props) {
  const {
    //handlePenspringHomeworkOpen = () => {},
    //setGradebookScoreByPenspring = () => {},
    addClassName,
    chapterCountLinkClick,
    chosenWork,
    copyWorkToFolder,
    darkerBackground,
    darkerLabel,
    deleteWorkFromFolder,
    hasParentFolderGroup,
    hideActionIcons,
    includeDates,
    includeEditors,
    isCopyWorkId,
    isMoveWorkId,
    isWorkAddView,
    keyIndex,
    labelWhite,
    maroonBackground,
    mineOrOthers,
    moveWorkToFolder,
    nameLength = 50,
    noFocusColor,
    onClick = () => { },
    personId,
    showDocumentDetails,
    showEditLink,
    showExplorerOptions,
    showStatsOnly,
    thisWorkFolderId,
    titleWhite,
    work = {},
  } = props

  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 870px)' })
  const workNameLength = isMobile ? 30 : 60
  const [showTempControls, setShowTempControls] = useState(false)
  const [workAddViewChoice, setWorkAddViewChoice] = useState(chosenWork && chosenWork.workId)
  const [chosenChapter, setChosenChapter] = useState({})

  useEffect(() => {
    setChosenChapter(getChosenChapter(work?.workId))
  }, [work])

  useEffect(() => {
    setWorkAddViewChoice(chosenWork && chosenWork.workId)
  }, [chosenWork])

  //const [scores, setScores] = useState([]);

  // `const onBlurScore = (studentAssignmentResponseId, event) => {
  // 	setGradebookScoreByPenspring(personId, studentAssignmentResponseId, event.target.value);
  // }
  //
  // const handleScore = (studentAssignmentResponseId, event) => {
  // 	let newScores = [...scores];
  // 	newScores[studentAssignmentResponseId] = !event.target.value ? 'Empty' : event.target.value;
  // 	setScores(newScores);
  // }
  const workName = work && work.workName ? work.workName.length > nameLength ? work.workName.substring(0, nameLength) + '...' : work.workName : ''
  const isTranslation = work.languageIdAssignName && work.languageIdName !== work.languageIdAssignName

  const sendToEditReview = (event) => {
    event.stopPropagation()
    event.preventDefault()
    navigate(`/editReview/${work && work.workId}/${chosenChapter}/${work.languageIdAssign || 1}`)
  }

  const setBackgroundColor = () => {
    if (maroonBackground) {
      return styles.backgroundMaroon
    } else if (darkerBackground) {
      return styles.backgroundDarker
    } else if (showTempControls) {
      return styles.hoverColor
    }
  }

  const getChosenChapter = (workId) => {
    const storage = localStorage.getItem('chosenChapters')
    const chosenChapters = storage ? JSON.parse(storage) : {}
    if (chosenChapters && chosenChapters[workId]) {
      return chosenChapters[workId]
    }
    return work && work.chapters && work.chapters.length > 0 && work.chapters[0].chapterId
  }

  const getOptions = (work) => {
    return work.chapters.reduce((acc, m) => {
      let option = {
        id: m.chapterId,
        label: m.chapterNbr + ' - ' + m.name
      }
      return acc && acc.length > 0 ? acc.concat(option) : [option]
    }, [])
  }

  const handleSetChosenChapter = (event, workId) => {
    event.stopPropagation()
    const chapterId = event.target.value
    const storage = localStorage.getItem('chosenChapters')
    const chosenChapters = storage ? JSON.parse(storage) : {}
    chosenChapters[workId] = chapterId
    localStorage.setItem('chosenChapters', JSON.stringify(chosenChapters))
    setChosenChapter(chapterId)
  }

  const showDropDownList = (work) => {
    return (
      <div className={isMobile ? '' : styles.row}>
        {work?.chapters?.length > 1 &&
          <div>
            <SelectSingleDropDown
              id={`chapters${work.workId}`}
              label={''}
              noBlank
              zeroethLabel={work?.chapters[0].chapterNbr + ' - ' + work?.chapters[0].name}
              zeroethValue={work?.chapters[0].chapterId}
              value={chosenChapter}
              options={getOptions(work)}
              height={`medium-short`}
              labelClass={styles.textWhite}
              className={styles.selectList}
              includeDownArrowFirst
              onChange={(event) => handleSetChosenChapter(event, work.workId)} />
          </div>
        }
      </div>
    )
  }

  const showChapterCountLink = (work, workName) => {
    return (
      <div className={isMobile ? '' : styles.rowSpace}>
        {work?.chapters?.length > 1 &&
          <div className={styles.chapterCountLink} onClick={() => chapterCountLinkClick()}>
            &#x25BC; {work.chapters.length} chapters  {/*We put in a downarrow at the first because that list can be cut off on mobile or when it is longer than 290px for a long chapter name.*/}
          </div>
        }
      </div>
    )
  }

  return !(work && work.workId) ? null : (
    <div key={keyIndex} className={classes(setBackgroundColor(), addClassName)} onDoubleClick={sendToEditReview} >
      <div className={styles.container}>
        {!showStatsOnly &&
          <div title={workName.length > nameLength ? workName : null} className={classes(styles.row, titleWhite ? styles.whiteText : '')}>
            {showEditLink && !isWorkAddView &&
              <a onClick={() => work.workTypeCode === 'TESTQUIZ'
                ? work.workOwners.filter(m => m.personId === personId)[0]
                  ? navigate(`/assessmentQuestions/${work.assessmentId}`)
                  : work.isAssessmentSubmitted
                    ? navigate(`/assessmentCorrect/${work.assignmentId}/${work.assessmentId}/${personId}`)
                    : navigate(`/assessmentLearner/${work.assignmentId}/${work.assessmentId}`)
                : navigate(`/editReview/${work.workId}/${chosenChapter}/${work.languageId_current}`)}
                title={`View or edit document`}
                className={styles.pencilIcon}>
                <Icon pathName={'pencil0'} premium={true} />
              </a>
            }
            {isWorkAddView &&
              <input type='checkbox' checked={workAddViewChoice === work.workId} onChange={() => { }} onClick={() => { onClick(); setWorkAddViewChoice(workAddViewChoice === work.workId ? '' : work.workid); }} />
            }
            <div className={isMobile ? '' : styles.rowSpace} title={workName}>
              <div onClick={onClick}>{workName && workName.length > workNameLength ? workName.substring(0, workNameLength) + '...' : workName}</div>
              {!chapterCountLinkClick && showDropDownList(work, workName)}
              {chapterCountLinkClick && showChapterCountLink(work, workName)}
              {work.workTypeCode === 'TESTQUIZ' && <div className={styles.assessmentType}>ASSESSMENT</div>}
              {work.workTypeCode === 'DISCUSSION' && <div className={styles.discussionType}>DISCUSSION</div>}
            </div>
            {work.languageIdAssignName && work.languageIdName !== work.languageIdAssignName &&
              <div className={styles.smallerAndGray}>{` - (${work.languageIdName} to ${work.languageIdAssignName})`}</div>
            }
          </div>
        }
        {showExplorerOptions &&
          <div className={isMobile ? styles.absoluteRightMobile : styles.absoluteRight}>
            <MenuDocumentPopup {...props} >
              <WorkFileExplorerOptions noFocusColor={noFocusColor}
                mineOrOthers={mineOrOthers}
                moveWorkToFolder={moveWorkToFolder}
                isMoveWorkId={isMoveWorkId}
                copyWorkToFolder={copyWorkToFolder}
                isCopyWorkId={isCopyWorkId}
                thisWorkFolderId={thisWorkFolderId}
                work={work}
                personId={personId}
                deleteWorkFromFolder={deleteWorkFromFolder}
                chosenWork={chosenWork}
                hasParentFolderGroup={hasParentFolderGroup}
                {...props} />
            </MenuDocumentPopup>
          </div>
        }
      </div>
      <div>
        {showDocumentDetails && work.workTypeCode === 'TESTQUIZ' && 
          <AssessmentStats 
            darkerLabel={darkerLabel}
            includeDates={includeDates}
            includeEditors={includeEditors}
            isTranslation={isTranslation}
            labelWhite={labelWhite}
            maroonBackground={maroonBackground}
            personId={personId}
            work={work} />
        }
        {showDocumentDetails && work.workTypeCode !== 'TESTQUIZ' && work.workTypeCode !== 'DISCUSSION' && 
          <DocumentStats
            darkerLabel={darkerLabel}
            includeDates={includeDates}
            includeEditors={includeEditors}
            isTranslation={isTranslation}
            labelWhite={labelWhite}
            maroonBackground={maroonBackground}
            personId={personId}
            work={work} />
        }

      </div>
    </div>
  )
}

export default WorkSummaryLine