import React from 'react';
import styles from './DataDeletionPolicyView.module.css';
import OneFJefFooter from '../../components/OneFJefFooter'
import LogoSlogan from '../../components/LogoSlogan'

function DataDeletionPolicyView() {

	return (
		<div className={styles.container}>
      <div className={styles.mainDiv}>
				<LogoSlogan larger includeOneFJef/>
				<p className={styles.MsoNormal} style={{fontSize: '16pt', marginTop: '40px'}}><b>Data Deletion Policy</b></p>
				<br />
				<br />
				<p className={styles.MsoNormal}><b>Last Updated: October 2, 2024</b></p>

				<p clasNames={styles.MsoNormal}><b>1. Introduction</b></p>

				<p className={styles.MsoNormal}>At one-f Jef, Inc. ('we', 'our', 'us'), we are committed
					to protecting your privacy and ensuring the secure handling of your personal
					data. This Data Deletion Policy explains how we manage and process the deletion
					of data collected through our website and application ('Service'). By using our
					Service, you agree to the terms outlined in this policy.</p>

				<p className={styles.MsoNormal}><b>2. Data Deletion Request</b></p>

				<p className={styles.MsoNormal}><b>2.1 Request Submission</b></p>

				<p className={styles.MsoNormal}>You may request the deletion of your personal data by
					contacting us through the following methods:</p>

				<ul style={{ marginTop: '0in' }} type={'disc'}>
					<li className={styles.MsoNormal}><b>Email:</b> jef@penspring.com</li>
					<li className={styles.MsoNormal}><b>Address:</b> 1920 W. 100 S., Manti, UT  84642</li>
				</ul>

				<p className={styles.MsoNormal}>Your request should include sufficient information to verify
					your identity and specify the data you want to be deleted.</p>

				<p className={styles.MsoNormal}><b>2.2 Verification</b></p>

				<p className={styles.MsoNormal}>To ensure the security of your data, we may require you to
					verify your identity before processing your deletion request. This verification
					process may include, but is not limited to, confirming your identity through
					email or other authentication methods.</p>

				<p className={styles.MsoNormal}><b>3. Data Deletion Process</b></p>

				<p className={styles.MsoNormal}><b>3.1 Processing Time</b></p>

				<p className={styles.MsoNormal}>Once we receive and verify your deletion request, we will
					process it within 5 business days. The actual time for data deletion may vary
					depending on the complexity of the request and the volume of data involved.</p>

				<p className={styles.MsoNormal}><b>3.2 Data Deletion</b></p>

				<p className={styles.MsoNormal}>Upon successful processing of your deletion request, we
					will:</p>

				<ul style={{ marginTop: '0in' }} type={'disc'}>
					<li className={styles.MsoNormal}><b>Remove:</b> Delete your personal data from our active
						databases and systems.</li>
					<li className={styles.MsoNormal}><b>Anonymize:</b> If immediate deletion is not feasible,
						we may anonymize your data so it can no longer be used to identify you.</li>
				</ul>

				<p className={styles.MsoNormal}><b>3.3 Exceptions</b></p>

				<p className={styles.MsoNormal}>Certain data may not be eligible for deletion due to legal,
					regulatory, or contractual obligations. For example, we may retain data to
					comply with legal requirements or to resolve disputes. In such cases, we will
					inform you of the reason for retention and the period for which data will be
					retained.</p>

				<p className={styles.MsoNormal}><b>4. Data Retention</b></p>

				<p className={styles.MsoNormal}>We retain personal data only for as long as necessary to
					fulfill the purposes for which it was collected or as required by law. Once
					data is no longer needed, it is securely deleted or anonymized in accordance
					with this policy.</p>

				<p className={styles.MsoNormal}><b>5. Data Deletion for Inactive Accounts</b></p>

				<p className={styles.MsoNormal}>If an account remains inactive for a prolonged period (e.g.,
					2 years), we may initiate a review to determine whether the data
					should be deleted. Inactive account holders will be notified prior to deletion.</p>

				<p className={styles.MsoNormal}><b>6. Contact and Support</b></p>

				<p className={styles.MsoNormal}>If you have any questions or concerns about this Data
					Deletion Policy or need assistance with a deletion request, please contact us
					at:</p>

				<ul style={{ marginTop: '0in' }} type={'disc'}>
					<li className={styles.MsoNormal}><b>Email:</b> jef@penspring.com</li>
					<li className={styles.MsoNormal}><b>Address:</b> 1920 W. 100 S., Manti, UT  84642</li>
				</ul>

				<p className={styles.MsoNormal}><b>7. Changes to This Policy</b></p>

				<p className={styles.MsoNormal}>We may update this Data Deletion Policy from time to time.
					Any changes will be posted on this page with an updated effective date. We
					encourage you to review this policy periodically to stay informed about how we
					manage your data.</p>

				<p className={styles.MsoNormal}><b>8. Governing Law</b></p>

				<p className={styles.MsoNormal}>This Data Deletion Policy is governed by and construed in
					accordance with the laws of the state of Utah, without regard to its conflict
					of law principles.</p>

        <OneFJefFooter/>
      </div>
		</div>
	);
}

export default DataDeletionPolicyView;
