export const isGuidNotEmpty = (varText) => {
	return !varText || varText === '00000000-0000-0000-0000-000000000000' ? false : true
}

export const emptyGuid = () => {
	return '00000000-0000-0000-0000-000000000000'
}

export const guidEmpty = '00000000-0000-0000-0000-000000000000'

export const generateGuid = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = Math.random() * 16 | 0
		const v = c === 'x' ? r : (r & 0x3 | 0x8)
		return v.toString(16)
	})
}