import React from 'react';
import {Link} from 'react-router-dom';
import styles from './GroupTypeChoiceView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import Accordion from '../../components/Accordion';
import OneFJefFooter from '../../components/OneFJefFooter';

function GroupTypeChoiceView(props) {
    let {groupTypes} = props;
    groupTypes = !!groupTypes && groupTypes.filter(m => m.name === "FACILITATORLEARNER");

    return (
        <div className={styles.container}>
            <div className={globalStyles.pageTitle}>
                Create New Group
            </div>
            {groupTypes && groupTypes.length > 0 && groupTypes.map((s, i) => {
                return (
                  <Accordion title={s.description} key={i}>
                    {/* className={styles.accordionTitle} onTitleClick={`SameAsOnClick`} expanded={true}> */}
                    <Link to={`/groupAddNew/` + s.name} className={styles.button}>{`Create`}</Link>
                      {s.groupExplain && s.groupExplain.length > 0 &&
                          s.groupExplain.map((m, mIndex) =>
                             <span key={mIndex} className={styles.explanation}>{m.explanation}</span>
                      )}
                    <hr />
                  </Accordion>
                );
            })}
            <OneFJefFooter />
        </div>
    )
};

export default GroupTypeChoiceView