import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useAuth } from '../hooks/auth'
import jwtDecode from "jwt-decode"
import { connect } from 'react-redux'
import { selectMe } from '../store.js'
import * as actionLogin from '../actions/login.js'

const mapStateToProps = state => {
	return {
		me: selectMe(state),
	}
}

const bindActionsToDispatch = dispatch => ({
	initRecords: (person, sendTo) => dispatch(actionLogin.initRecords(person, sendTo)),
})

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
)

function RequireAuth(props) {
	const navigate = useNavigate()
	const auth = useAuth()

	useEffect(() => {
		const authToken = window.localStorage.getItem('authToken')

		if (!authToken) {
			navigate('/login')
			return
		}

		const { exp } = jwtDecode(authToken)
		const isValidToken = new Date().getTime() < (exp * 1000)

		if (!isValidToken) {
			navigate('/login')
			return
		}

		let person = window.localStorage.getItem('person')
		if (person) {
			person = JSON.parse(person)
			props.initRecords(person)
			auth.login(person)
		}
	}, [navigate])

	return props.children
}

export default storeConnector(RequireAuth)