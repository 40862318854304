import React, {useRef} from 'react';  //PropTypes
import styles from './AssessIncompleteModal.module.css';
import classes from 'classnames'
//import { ModalContainer, ModalDialog } from '../react-modal-dialog/lib/index.js';

function AssessIncompleteModal(props) {

  const { onClick, handleClose, heading, notAnswered, forceAllAnswers, isOpen, isMobile } = props;
  const containerRef = useRef(null);

  return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background} ref={containerRef}>
          <div className={styles.dialogHeader}>{heading}</div>
          <p className={styles.dialogExplain}>{'The following questions are not yet answered:'}</p>
          {notAnswered && notAnswered.length > -1 && notAnswered.map((m, i) =>
            <div key={i} className={styles.text}>{m}</div>
          )}
          <p className={styles.dialogExplain}>{forceAllAnswers ? 'You must answer all questions.' : 'Do you want to submit the incomplete answers anyway?'}</p>
          <div className={styles.dialogButtons}>
            <button className={forceAllAnswers ? styles.yesButton : styles.noButton} onClick={handleClose}>{forceAllAnswers ? 'OK' : 'No'}</button>
            {!forceAllAnswers && <button className={styles.yesButton} onClick={onClick}>{'Yes'}</button>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssessIncompleteModal