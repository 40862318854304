import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import EditReviewView from '../views/EditReviewView/EditReviewView.js'
import {connect} from 'react-redux'
import * as actionWorks from '../actions/works.js'
import * as actionWorkEditReview from '../actions/work-edit-review.js'
import * as actionAssessmentQuestions from '../actions/assessment-questions.js'
import * as actionTextStyles from '../actions/text-styles.js';
import * as actionEditReview from '../actions/edit-review.js'
import * as actionPersonConfig from '../actions/person-config.js'
import * as actionTranslatedSentence from '../actions/translated-sentence'
import * as fromBookmarks from '../reducers/bookmarks.js'
import * as actionBookmarks from '../actions/bookmarks.js'
import * as actionGroups from '../actions/groups.js';
import * as fromDraftComparison from '../reducers/draft-comparison.js'
import {doSort} from '../utils/sort.js'
import downloadOptions from '../data/downloadOptions'
import fontSizeOptions from '../data/fontSizeOptions'
import fontNameOptions from '../data/fontNameOptions'

import {
  selectMe,
  // selectEditSegments,
  // selectWorkSegments,
  selectAssessmentQuestion,
  selectChapterListLevels,
  selectColorsEditor,
  selectEditSegmentHistory,
  selectHtmlCharacterOptions,
  selectLeftSidePanelOpen,
  selectListLevelGeneral,
  selectPersonConfig,
  selectTextStyleFonts,
  selectTextStyleGroups,
  selectTranslatedSentence,
  selectWorkEditReview,
} from '../store.js'

const mapStateToProps = (state) => {
  const personId = selectMe(state).personId
  const editorName = {
    firstName: selectMe(state).fname,
    lastName: selectMe(state).lname
  }
  //Draft comparison is only for the author.  But we'll send in the data objects since the data will be blank from the database and it won't be a burden
  const isDraftView = fromDraftComparison.selectIsDraftView(state.draftComparison) || false
  const editorColors = selectColorsEditor(state)
  const draftComparison = fromDraftComparison.selectDraftComparisons(state.draftComparison)
  // const tabsData = isDraftView
  //     ? fromDraftComparison.selectDraftTabs(state.draftComparison)
  //     : fromEditReview.selectEditorTabs(state.editReview, personId, workSummary, 0, selectMe(state).fname, editorColors) //personId
  // const edits = selectEditSegments(state)

  const textStyleFonts = selectTextStyleFonts(state)
  let fontHeaderOptions = []    //This is for the Headerless component for font choices. Not for the TextStyleGroupModal. That one is fontOptions
  textStyleFonts?.forEach(m => {
    fontHeaderOptions.push({ id: m.name, label: <span style={{ fontFamily: m.name }}>{m.name}</span> })
  })
  fontHeaderOptions = doSort(fontHeaderOptions, { sortField: 'label', isAsc: true, isNumber: false })

  let fontHeaderSizes = []  //This is for the Headerless component for font choices. Not for the TextStyleGroupModal.  That one is fontSizes
  for (let i = 6; i <= 72; i++) {
    fontHeaderSizes.push({ id: i, label: <span style={{ fontSize: `${i}pt` }}> {i}pt</span> })
  }

  const textStyleGroups = selectTextStyleGroups(state)
  let textStyleGroupOptions = []
  textStyleGroups?.forEach(m => {
    textStyleGroupOptions.push({
      id: m.group.textStyleGroupId, label: <span style={{ fontSize: '9pt' }}>{m.group.name}</span> })
  })

  let fontOptions = []  //This is for the TextStyleGroupModal
  let optGroup = { id: '', label: '', options: [] }
  textStyleFonts?.forEach(m => {
    if (m.groupName !== optGroup.label) {
      optGroup = { id: m.groupIndex, label: m.groupName, options: [] }
      fontOptions.push(optGroup)
    }
    optGroup.options.push({ id: m.name, label: m.name })
  })

  const fontSizes = []  //This is for the TextStyleGroupModal
  for (let i = 6; i <= 72; i++) {
    fontSizes.push({ id: i, label: i + 'px' })
  }

  const lineHeights = []  //This is for the TextStyleGroupModal
  for (let i = 6; i <= 30; i++) {
    lineHeights.push({ id: i / 10, label: i / 10 })
  }

  const textAlign = [  //This is for the TextStyleGroupModal
    { id: 'left', label: 'left' },
    { id: 'center', label: 'center' },
    { id: 'right', label: 'right' },
    { id: 'justify', label: 'full-justify' },
  ]

  const bottomSpace = []  //This is for the TextStyleGroupModal
  for (let i = 0; i <= 30; i++) {
    bottomSpace.push({ id: i, label: i + 'px' })
  }

  const textIndent = []  //This is for the TextStyleGroupModal
  for (let i = 0; i <= 12; i++) {
    textIndent.push({ id: i, label: i + ' characters' })
  }

  return {
    //isLMSTransfer: props.params && props.params.isLMSTransfer === 'LMStransfer',
    //textProcessingProgress: selectTextProcessingProgress(state),
    assessmentQuestion: selectAssessmentQuestion(state),
    bookmarkOptions: fromBookmarks.selectBookmarkOptions(state.bookmarks),
    bookmarks: fromBookmarks.selectBookmarks(state.bookmarks),
    bottomSpace,
    chapterListLevels: selectChapterListLevels(state),
    downloadOptions,
    draftComparison,
    editorColors,
    editorName,
    // edits,
    editSegmentHistory: selectEditSegmentHistory(state),
    fontHeaderOptions, //For the Headerless component for the div line of 
    fontHeaderSizes,
    fontNameOptions,
    fontOptions, //These six here are for the TextStyleGroupModal
    fontSizeOptions,
    fontSizes,
    htmlCharacterOptions: selectHtmlCharacterOptions(state),
    isDraftView,
    leftSidePanelOpen: selectLeftSidePanelOpen(state),
    lineHeights,
    listLevelGeneral: selectListLevelGeneral(state),
    personConfig: selectPersonConfig(state),
    personId,
    // segments: selectWorkSegments(state),
    textAlign,
    textIndent,
    textStyleGroupOptions,
    textStyleGroups,
    thisEditorColor: 'cc3300', //orange
    translatedSentence: selectTranslatedSentence(state),
    workSummaries: selectWorkEditReview(state),
  }
}

const bindActionsToDispatch = dispatch => ({
  restoreEditSegment: (editSegmentId, languageId) => dispatch(actionEditReview.restoreEditSegment(editSegmentId, languageId)),
  responseEdit: (edit, responseType, comment, listItemElementIds, runFunction, addListItemNextParentElementId) => dispatch(actionEditReview.responseEdit(edit, responseType, comment, listItemElementIds, runFunction, addListItemNextParentElementId)),
  addOrUpdateSegments: (personId, segments, runFunction) => dispatch(actionEditReview.addOrUpdateSegments(personId, segments, runFunction)),
  updateSegmentsLocal: (segments) => dispatch(actionEditReview.updateSegmentsLocal(segments)),
  addOrUpdateEdit: (edit, runFunction) => dispatch(actionEditReview.addOrUpdateEdit(edit, runFunction)),
  addOrUpdateEditAddList: (addListEntries) => dispatch(actionEditReview.addOrUpdateEditAddList(addListEntries)),
  addOrUpdateCommentOrSentence: (personId, comment, workId, chapterId, elementId, languageId, isAuthor, editSegmentId, editType, newSentence) => dispatch(actionEditReview.addOrUpdateCommentOrSentence(personId, comment, workId, chapterId, elementId, languageId, isAuthor, editSegmentId, editType, newSentence)),
  // setDraftTabSelected: (draftComparisonId) => dispatch(actionDraftComparison.setDraftTabSelected(draftComparisonId)),
  // toggleDraftView: () => dispatch(actionDraftComparison.toggleDraftView()),
  // setBookmarks: (personId, chapterId, languageId) => dispatch(actionBookmarks.init(personId, chapterId, languageId)),
  saveNewBookmark: (personId, chapterId, languageId, hrefId, name) => dispatch(actionBookmarks.saveNewBookmark(personId, chapterId, languageId, hrefId, name)),
  // setDraftComparison: (personId, workId, chapterId, languageId) => dispatch(actionDraftComparison.init(personId, workId, chapterId, languageId)),
  // deleteBookmark: (personId, chapterId, languageId, hrefId) => dispatch(actionBookmarks.deleteBookmark(personId, chapterId, languageId, hrefId)),
  setWorkCurrentSelected: (personId, workId, chapterId, languageId, goToPage) => dispatch(actionWorks.setWorkCurrentSelected(personId, workId, chapterId, languageId, goToPage)),
  // setPenspringHomeworkSubmitted: (personId, workId) => dispatch(actionWorks.setPenspringHomeworkSubmitted(personId, workId)),
  // setPenspringDistributeSubmitted: (personId, workId) => dispatch(actionWorks.setPenspringDistributeSubmitted(personId, workId)),
  // saveLastVisitedHrefId: (personId, workId, chapterId, languageId, prevHrefId) => dispatch(actionWorks.saveLastVisitedHrefId(personId, workId, chapterId, languageId, prevHrefId)),
   getTranslation: (personId, workId, languageId, elementId, chapterId, translateText)  => dispatch(actionTranslatedSentence.getTranslation(personId, workId, languageId, elementId, chapterId, translateText)),
  // clearTranslation: () => dispatch(actionTranslatedSentence.clearTranslation()),
  updatePersonConfig: (personId, field, value) => dispatch(actionPersonConfig.updatePersonConfig(personId, field, value)),
  getPersonConfig: (personId) => dispatch(actionPersonConfig.init(personId)),
  //updatePersonConfigNotPersist: (personId)  => dispatch(actionPersonConfig.updatePersonConfigNotPersist(personId)),
  deleteWork: (personId, workId) => dispatch(actionWorks.deleteWork(personId, workId)),
  getWorkEditReview: (personId, workId) => dispatch(actionWorkEditReview.getWorkEditReview(personId, workId)),
  //  part of the score function that isn't working due to the cursor location management or EditorDiv.
  //setGradebookScoreByPenspring: (personId, studentAssignmentResponseId, score) => dispatch(actionGradebook.setGradebookScoreByPenspring(personId, studentAssignmentResponseId, score)),
  addChapterListLevels: (chapterListLevels, chapterId) => dispatch(actionWorks.addChapterListLevels(chapterListLevels, chapterId)),
  getChapterListLevels: (workId, chapterId) => dispatch(actionWorks.getChapterListLevels(workId, chapterId)),
  getListLevelGeneral: () => dispatch(actionWorks.getListLevelGeneral()),
  downloadWork: (wordConversion) => dispatch(actionWorks.downloadWork(wordConversion)),
  deleteFile: (personId, workId, conversionType, languageId) => dispatch(actionWorks.deleteFile(personId, workId, conversionType, languageId)),
  getEditSegmentHistory: (personId, chapterId, elementId, languageId, runFunction) => dispatch(actionEditReview.getEditSegmentHistory(personId, chapterId, elementId, languageId, runFunction)),
  toggleGroupJoinLink: (personId, groupId, runFunction) => dispatch(actionGroups.toggleGroupJoinLink(personId, groupId, runFunction)),
  acceptAllAddParagraphSentence: (editsAddParagraphSentence, runFunction) => dispatch(actionEditReview.acceptAllAddParagraphSentence(editsAddParagraphSentence, runFunction)),
  deleteAllAddParagraphSentence: (editsAddParagraphSentence, runFunction) => dispatch(actionEditReview.deleteAllAddParagraphSentence(editsAddParagraphSentence, runFunction)),
  acceptAllAddListItem: (editsAddListItem, addListItemNextParentElementId, runFunction) => dispatch(actionEditReview.acceptAllAddListItem(editsAddListItem, addListItemNextParentElementId, runFunction)),
  getTextStyleFonts: () => dispatch(actionTextStyles.getTextStyleFonts()),
  getTextStyleGroups: () => dispatch(actionTextStyles.getTextStyleGroups()),
  sendOriginatingEditorContents: (choseSettings) => dispatch(actionWorks.sendOriginatingEditorContents(choseSettings)),
  removeDeleteSentenceEdits: (elementIds, chapterId) => dispatch(actionWorks.removeDeleteSentenceEdits(elementIds, chapterId)),
  getHtmlCharacterEntities: () => dispatch(actionEditReview.getHtmlCharacterEntities()),
  sendTestMessageSelection: (text) => dispatch(actionWorks.sendTestMessageSelection(text)),
  addDiscussionReply: (assignmentId, languageId, discussionEntryId, title, isFirstLevelPost, runFunction) => dispatch(actionEditReview.addDiscussionReply(assignmentId, languageId, discussionEntryId, title, isFirstLevelPost, runFunction)),
  deleteDiscussionReply: (assignmentId, languageId, discussionEntryId) => dispatch(actionEditReview.deleteDiscussionReply(assignmentId, languageId, discussionEntryId)),
  voteDiscussionReply: (voteEntry) => dispatch(actionEditReview.voteDiscussionReply(voteEntry)),
  getAssessmentQuestion: (assessmentQuestionId, chapterId) => dispatch(actionAssessmentQuestions.getAssessmentQuestion(assessmentQuestionId, chapterId)),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

let hasCalledChapterListLevels = false

function Container(props) {
  const {
    personId,
    getAssessmentQuestion,
    getPersonConfig,
    getWorkEditReview,
    getChapterListLevels,
    getListLevelGeneral,
    workSummary,
    getTextStyleFonts,
    getTextStyleGroups,
    getHtmlCharacterEntities,
  } = props

  // let {edits} = props

  const params = useParams()
  let {languageId} = useParams()
  // Cut back the tabsData to just the languages that this version of the document is called for as the languageId parameter
  //tabsData = tabsData && tabsData.length > 0 && tabsData.filter(m => m.languageId === Number(languageId))

  const isTranslation = workSummary && workSummary.languageId_current !== workSummary.languageId
    ? { 
        languageId: workSummary && workSummary.languageId_current, 
        languageName: workSummary && workSummary.languageName_current 
      }
    : ''

    //languageId was coming through as 0.
    //the languageId is used to change from the native language to a translation language.
  languageId = languageId && languageId !== "undefined" ? languageId : workSummary && workSummary.languageId_current

  useEffect(() => {
    if (personId && params.workId) {
      
      getAssessmentQuestion(params.assessmentQuestionId, params.chapterId)
      getListLevelGeneral()
      getPersonConfig(personId)
      getWorkEditReview(personId, params.workId) //This has changed to handle discussion entries, test/quizzes, and general multiple homework with essays. It can return one workSummary, but now it returns many so it will be an array in every case.
      getHtmlCharacterEntities()
      if (!hasCalledChapterListLevels) {
        getChapterListLevels(params.workId)
        hasCalledChapterListLevels = true
      }
      getTextStyleFonts()
      getTextStyleGroups()
    }
    //params && params.isdraft && setDraftComparison(personId, workSummary.workId, workSummary.chapterId_current, languageId) //If draftview is set on, then this will automatically set the view parameter in the draftComparison store.  There is a toggle function to turn it off.
    //setBookmarks(personId, workSummary.chapterId_current, languageId) //WE NEED THE WORKID HERE AND NOT JUST THE CHAPTERiD_CURRENT!
  }, [personId, params.workId, params.chapterId, params.assessmentQuestionId, languageId])

  return <EditReviewView {...props} {...params} editLanguageId={languageId} isTranslation={isTranslation} chapterId={params.chapterId} />
  // edits = { edits }
}

export default storeConnector(Container)
