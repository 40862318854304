import React, {useEffect, useState} from 'react';
import styles from './LoginView.module.css';
import InputText from '../../components/InputText';
//import MessageModal from '../../components/MessageModal';
import {Link, useNavigate} from 'react-router-dom';
import LogoSlogan from "../../components/LogoSlogan"; 
import OneFJefFooter from '../../components/OneFJefFooter';
import ButtonWithIcon from '../../components/ButtonWithIcon/ButtonWithIcon';

function ResetPasswordView(props) {
    const navigate = useNavigate()
    const {loginData} = props;
    const [isUserComplete, setIsUserComplete] = useState(false);
    const [isShowingModal, setIsShowingModal] = useState(false);
    const [resetMessage, setResetMessage] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [errors, setErrors] = useState({});
    const [user, setUser] = useState({
        clave: '',
        claveConfirm: '',
    });

    useEffect(() => {
        if (!isShowingModal && !resetMessage && loginData && loginData.passwordResetComplete) {
            handleMessageOpen();
        }
    }, []);

    const changeUser = (event) => {
        const field = event.target.name;
        const newUser = Object.assign({}, user);
        newUser[field] = event.target.value;
        setUser(newUser);
    }

    const handleEnterKey = (event) => {
        event.key === "Enter" && processForm();
    }

    const processForm = (event) => {
        const {setResetPasswordResponse, params} = props;

        event && event.preventDefault();
        let errors = {};
        let hasError = false;

        if (!user.clave) {
            errors.clave = "Please enter a password.";
            hasError = true;
        } else if (user.clave !== user.claveConfirm) {
            errors.clave = "The password and confirmation do not match.";
            hasError = true;
        }

        setErrors(errors);
        setRequestSent(true);
        if (!hasError) {
            setResetPasswordResponse(user.clave, () => navigate(-1))
        }
    }

    const handleMessageClose = () => {
        setIsShowingModal(false)
        setResetMessage(true)
        setRequestSent(false)
    }

    const handleMessageOpen = () => setIsShowingModal(true)

    return (
        <section className={styles.resetPasswordContainer}>
            <div className={styles.mainDiv}>
                <LogoSlogan />
                <div className={styles.login}>
                    Reset Password
                </div>
                <div>
                    <InputText
                        size={"medium"}
                        isPasswordType={true}
                        label={`Password`}
                        name={`clave`}
                        value={user.clave || ''}
                        onChange={changeUser}
                        onEnterKey={handleEnterKey}
                        height={`medium`}
                        inputClassName={styles.inputNoBold}
                        autocomplete="nope"
                        required={true}
                        whenFilled={user.clave}
                        error={errors.clave} />
                </div>
                <div>
                    <InputText
                        size={"medium"}
                        isPasswordType={true}
                        label={`Confirm Password`}
                        name={`claveConfirm`}
                        value={user.claveConfirm || ''}
                        onChange={changeUser}
                        onEnterKey={handleEnterKey}
                        height={`medium`}
                        inputClassName={styles.inputNoBold}
                        autocomplete="nope"
                        required={true}
                        whenFilled={user.claveConfirm}
                        error={errors.claveConfirm} />
                </div>
                <div>
                    <ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={processForm} submitSuccess={false} />
                </div>
                <OneFJefFooter />
            </div>
        </section>
    );
}

export default ResetPasswordView;
