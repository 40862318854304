import React from 'react'
import styles from './ReviewTextModal.module.css'
import ButtonWithIcon from '../ButtonWithIcon'
import DOMPurify from 'dompurify'
import classes from 'classnames'

const ReviewTextModal = ({ isOpen, onClose, text, label='Text Viewer' }) => {
	const sanitizedHtml = text ? DOMPurify.sanitize(text.replace(/\n\n/g, '<br/><br/>')).replace(/\n/g, ' ') : ''

	return (
		<div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
			<div className={styles.modal}>
				<div className={styles.modalHeader}>
					<h2>{label}</h2>
					<button className={styles.closeButton} onClick={onClose}>
						&times;
					</button>
				</div>
				<div className={styles.modalContent}>
					<div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
				</div>
				<div className={styles.modalFooter}>
					<ButtonWithIcon icon={'checkmark0'} onClick={onClose} label={'Done'} submitSuccess={false} />
				</div>
			</div>
		</div>
	)
}

export default ReviewTextModal
