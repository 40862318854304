import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styles from './LoginMobileView.module.css'
import LogoSlogan from '../../components/LogoSlogan'
import LoginControls from '../../components/LoginControls'
import OneFJefFooter from '../../components/OneFJefFooter'
import VideoDisplayMobile from './VideoDisplayMobile'
import MultipleEditorsSliced from "../../assets/landing/Final/MultipleEditorsSliced.png"

import ChooseMultipleSentence from "../../assets/landing/Final/MultipleEditors_SMALL.png"
import AddDeleteEdits from "../../assets/landing/Final/EditControls_SMALL.png"
import EditReport from "../../assets/landing/Final/EditReport_SMALL.png"
import MoveSentences from "../../assets/landing/Final/MoveSentences_SMALL.png"
import AIvsCreativeWriting from "../../assets/landing/Final/AIvsCreativeWriting_SMALL.png"
import OutlineReorder from "../../assets/landing/Final/OrderedListSequence_SMALL.png"
import OutlineAddDelete from "../../assets/landing/Final/OutlineEdits_SMALL.png"
import LanguageVersions from "../../assets/landing/Final/LanguageVersions_SMALL.png"


function LoginMobileView(props) {
  const { loginData } = props
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  const slides = [
    {
      image: ChooseMultipleSentence,
    },
    {
      image: AddDeleteEdits,
    },
    {
      image: EditReport,
    },
    {
      image: MoveSentences,
    },
    {
      image: AIvsCreativeWriting,
    },
    {
      image: OutlineReorder,
    },
    {
      image: OutlineAddDelete,
    },
    {
      image: LanguageVersions,
    },
  ]

  const [slideIndex, setSlideIndex] = useState(1)
  const [isCancelTimer, setIsCancelTimer] = useState(false)
  const timeoutRef = React.useRef(null)
  let delay = 7000

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    let slideDivs = document.querySelectorAll(`[id="mySlides"]`)
    if (slideIndex > slideDivs.length) {
      setSlideIndex(1)
    } else if (slideIndex < 1) {
      setSlideIndex(slideDivs.length)
    } else {
      showSlides()
    }
    resetTimeout()
    if (!isCancelTimer) {
      timeoutRef.current = setTimeout(
        () =>
          increaseSlide(),
        delay
      )
    }

    return () => {
      resetTimeout()
    }
  }, [slideIndex])

  const plusSlides = (n) => {
    showSlides(slideIndex + n)
    setSlideIndex(slideIndex + n)
  }

  const increaseSlide = () => {
    let newSlide = slideIndex + 1
    if (newSlide > slides.length) {
      setSlideIndex(1)
    } else if (newSlide < 1) {
      setSlideIndex(slides.length)
    } else {
      setSlideIndex(newSlide)
    }
  }

  const showSlides = () => {
    let i
    let slideDivs = document.querySelectorAll(`[id="mySlides"]`)
    for (i = 0; i < slideDivs.length; i++) {
      if (slides[i])
        slideDivs[i].style.display = "none"
    }
    if (slideDivs[slideIndex - 1]) slideDivs[slideIndex - 1].style.display = "block"
  }


  return (
    <div className={styles.container}>
      <LogoSlogan larger />
      <LoginControls loginData={loginData} showLoginControl={props.showLoginControl} {...props} />
      <div class={styles.imageText}>
        <img src={MultipleEditorsSliced} alt="MultipleEditors" class={styles.backgroundImage} />
      </div>
      <div style={{marginTop: '20px', marginBottom: '3px', marginLeft: '0px'}}>
        <VideoDisplayMobile slideIndex={slideIndex} setSlideIndex={setSlideIndex} setIsCancelTimer={setIsCancelTimer} />
      </div>
      <div className={styles.slideshowContainer}>
        {slides && slides.length > 0 && slides.map((m, i) => {
          let slide = i + 1 * 1
          return (
            <div className={styles.fade} key={i} id="mySlides">
              <img src={m.image} alt="logo" style={{ width: '100%' }} />
            </div>
          )
        })}
      </div>
      <div className={styles.centerFooter}>
        <OneFJefFooter />
      </div>
      <div className={isMobile ? styles.freeSmallMobile : styles.freeSmall}>
        <div>The use of penspring is free for one author, two editors and five documents. For $10 per month, you get 1000 authors, 1000 editors and 1000 documents. For unlimited licenses and corporate site licenses, please contact us:</div>
        <div><a mailto={'jef@penspring.com'}>jef@penspring.com</a></div>
      </div>
    </div>
  )
}

export default LoginMobileView
