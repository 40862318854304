import React, {Component} from 'react';
import ResetPasswordView from '../views/LoginView/ResetPasswordView.js';
import { connect } from 'react-redux';
import * as loginUser from '../actions/login.js';
import {selectMe} from '../store.js';

// takes values from the redux store and maps them to props
const mapStateToProps = state => {
    return {
        loginData: selectMe(state)
    }
};

const bindActionsToDispatch = dispatch => ({
    setResetPasswordResponse: (password, runFunction) => dispatch(loginUser.setResetPasswordResponse(password, runFunction)),
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
);

class Container extends Component {
    render() {
        return <ResetPasswordView {...this.props} />
    }
}

export default storeConnector(Container);
