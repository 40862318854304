import React from 'react';
import styles from './ButtonDropDown.module.css';
import Icon from '../Icon';
import classes from 'classnames'

function ButtonDropDown(props) {
	const {
		label = "",
		keyIndex,
		title,
		addClassName,
	} = props

	return (
		<div className={styles.container} key={keyIndex}>
			<div tabIndex={0} className={classes(styles.button, addClassName)} title={title}>
				<div tabIndex={0} className={styles.labelOutline}>{label}</div>
				<div tabIndex={0} className={styles.pointDown} >
					<Icon pathName={'arrow_right'} premium={false} className={styles.icon} fillColor={'white'} smaller height={12}/>
				</div>
			</div>
		</div>
	)
};

export default ButtonDropDown;