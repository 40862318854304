import React, {Component} from 'react'
import GiveAccessToEditorsView from '../views/GiveAccessToEditorsView'
import { connect } from 'react-redux'
import {doSort} from '../utils/sort.js'
import * as actionContactFilter from '../actions/contact-filter.js'
import * as fromMe from '../reducers/login.js'
import * as actionChapters from '../actions/chapters.js'
import * as actionContacts from '../actions/contacts.js'
import * as actionWorks from '../actions/works.js'
import * as actionEditorAssign from '../actions/editor-assign'
import * as actionEditorInvitePending from '../actions/editor-invite-pending.js'
import * as actionWorkFileTree from "../actions/work-file-tree-sub-contents"
import * as actionPersonConfig from '../actions/person-config.js'

import { selectWorkSummaryCurrent, selectContacts, selectLanguages, selectEditorInvitePending, selectPersonConfig, selectWorkFileTree } from '../store.js'

//ToDO:  In case this route is called without access, make sure that the current PersonId owns this document in order to give access.

// takes values from the redux store and maps them to props
const mapStateToProps = state => {
    let contacts = [...selectContacts(state)]
    let me = fromMe.selectMe(state.me)

    let sortByHeadings = {
        sortField: 'firstName',
        isAsc: 'asc',
        isNumber: false //None of the options are numbers in this case
    }
    contacts = doSort(contacts, sortByHeadings)

    return {
        //workId: currentWork.workId,
        personId: me.personId,
        owner_personId: me.personId,
        //languageId: currentWork.languageId,
        workSummary: selectWorkSummaryCurrent(state),
        fileTreeExplorer: selectWorkFileTree(state),
        // contactFilterOptions,
        // filterScratch,
        // savedFilterIdCurrent: filterScratch && filterScratch.savedFilterIdCurrent,
        contactSummaries: contacts,
        // chapterOptions: currentWork && currentWork.chapterOptions,
        languageOptions: selectLanguages(state),
        editorInvitePending: selectEditorInvitePending(state),
        personConfig: selectPersonConfig(state),
    }
}

// binds the result of action creators to redux dispatch, wrapped in callable functions
const bindActionsToDispatch = dispatch => ({

    getMyWorks: (personId) => dispatch(actionWorkFileTree.getMyWorks(personId)),
    getContacts: (personId) => dispatch(actionContacts.getContacts(personId)),
    getContactFilter: (personId) => dispatch(actionContactFilter.init(personId)),
    setEditorAssign: (isAdd, workId, personId, owner_personId, chapters, languages, directChapterId) => dispatch(actionEditorAssign.setEditorAssign(isAdd, workId, personId, owner_personId, chapters, languages, directChapterId)),
    setWorkCurrentSelected: (personId, workId, chapterId, languageId, goToPage) => dispatch(actionWorks.setWorkCurrentSelected(personId, workId, chapterId, languageId, goToPage)),
    deleteWork: (personId, workId) => dispatch(actionWorks.deleteWork(personId, workId)),
    updateChapterDueDate: (personId, workId, chapterId, languageId, dueDate) => dispatch(actionChapters.updateChapterDueDate(personId, workId, chapterId, languageId, dueDate)),
    updateChapterComment: (personId, workId, chapterId, comment) => dispatch(actionChapters.updateChapterComment(personId, workId, chapterId, comment)),
    deleteInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.deleteInvite(personId, friendInvitationId)),
    acceptInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.acceptInvite(personId, friendInvitationId)),
    resendInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.resendInvite(personId, friendInvitationId)),
    saveNewSavedSearch: (personId, savedSearchName) => dispatch(actionContactFilter.saveNewSavedSearch(personId, savedSearchName)),
    updateSavedSearch: (personId, contactFilterId) => dispatch(actionContactFilter.updateSavedSearch(personId, contactFilterId)),
    deleteSavedSearch: (personId, contactFilterId) => dispatch(actionContactFilter.deleteSavedSearch(personId, contactFilterId)),
    chooseSavedSearch: (personId, contactFilterId) => dispatch(actionContactFilter.chooseSavedSearch(personId, contactFilterId)),
    updateFilterByField: (personId, field, value) => dispatch(actionContactFilter.updateFilterByField(personId, field, value)),
    updateFilterDefaultFlag: (personId, savedFilterIdCurrent, setValue) => dispatch(actionContactFilter.updateFilterDefaultFlag(personId, savedFilterIdCurrent, setValue)),
    updatePersonConfig: (personId, field, value)  => dispatch(actionPersonConfig.updatePersonConfig(personId, field, value)),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

class Container extends Component {
    componentDidMount() {
        const {owner_personId, getContacts, getMyWorks} = this.props
        getMyWorks(owner_personId)
        getContacts(owner_personId)
    }

    render() {
        if (!this.props.contactSummaries) return null
        return <GiveAccessToEditorsView {...this.props} />
    }
}

export default storeConnector(Container)
