import React, { useEffect, useState } from 'react';
import styles from './ToastDeleteFolder.module.css'
import toast from "react-hot-toast";
import ButtonWithIcon from '../ButtonWithIcon';

const ToastDeleteFolder = ({ show, onSubmit, onClose, label = 'Delete this folder?' }) => {
	const [error, setError] = useState('');

	useEffect(() => {
		let toastId = null;

		if (show) {
			toastId = toast((t) => (
				<div className="row">
					<div className="col-auto my-auto">
						<i className="bi bi-question-circle-fill"></i>
					</div>
					<div className={styles.column}>
						<div>Are you sure you want to delete this folder?</div>
						<div>Do you want any documents in this folder to be moved to the parent folder?</div>
						<div style={{ marginTop: '10px' }}>
							<input type='checkbox' id='deleteFilesAlso' /> <label htmlFor='deleteFilesAlso'>Delete the files also.</label>
						</div>
					</div>
					<div className={styles.error}>{error}</div>
					<div className={styles.rowRight}>
						<span className={styles.cancelButton} onClick={() => {closeHandler(t); onClose()}}>
							Cancel
						</span>
						<ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={() => processForm(t)} />
					</div>
				</div>
			), {
				duration: Infinity,
				style: { maxWidth: "75em" },
			});
		}

		return () => {
			if (toastId) {
				toast.dismiss(toastId);
				// setInputText(''); // Reset input text
				// setError(''); // Clear any errors
			}
		};
	}, [show, label]);

	const closeHandler = (t) => {
		toast.dismiss(t.id);
	};

	const processForm = (t) => {
		const element = document.getElementById('inputValue')
		onSubmit();
		closeHandler(t);
		onClose()
	};

	return null; // This component does not render anything itself
};

export default ToastDeleteFolder;
