const fontNameOptions = [{
  value: 'Andalé Mono',
  span: <span style={{fontFamily: 'Andalé Mono'}}>Andalé Mono</span>
},
  {
    value: 'Arial Black',
    span: <span style={{fontFamily: 'Arial Black'}}>Arial Black</span>
  },
  {
    value: 'Arial',
    span: <span style={{fontFamily: 'Arial'}}>Arial</span>
  },
  {
    value: 'Baskerville',
    span: <span style={{fontFamily: 'Baskerville'}}>Baskerville</span>
  },
  {
    value: 'Bradley Hand',
    span: <span style={{fontFamily: 'Bradley Hand'}}>Bradley Hand</span>
  },
  {
    value: 'Brush Script MT',
    span: <span style={{fontFamily: 'Brush Script MT'}}>Brush Script MT</span>
  },
  {
    value: 'Comic Sans MS',
    span: <span style={{fontFamily: 'Comic Sans MS'}}>Comic Sans MS</span>
  },
  {
    value: 'Courier',
    span: <span style={{fontFamily: 'Courier'}}>Courier</span>
  },
  {
    value: 'Georgia',
    span: <span style={{fontFamily: 'Georgia'}}>Georgia</span>
  },
  {
    value: 'Gill Sans',
    span: <span style={{fontFamily: 'Gill Sans'}}>Gill Sans</span>
  },
  {
    value: 'Helvetica',
    span: <span style={{fontFamily: 'Helvetica'}}>Helvetica</span>
  },
  {
    value: 'Impact',
    span: <span style={{fontFamily: 'Impact'}}>Impact</span>
  },
  {
    value: 'Lucida',
    span: <span style={{fontFamily: 'Lucida'}}>Lucida</span>
  },
  {
    value: 'Luminari',
    span: <span style={{fontFamily: 'Luminari'}}>Luminari</span>
  },
  {
    value: 'Monaco',
    span: <span style={{fontFamily: 'Monaco'}}>Monaco</span>
  },
  {
    value: 'Palatino',
    span: <span style={{fontFamily: 'Palatino'}}>Palatino</span>
  },
  {
    value: 'Tahoma',
    span: <span style={{fontFamily: 'Tahoma'}}>Tahoma</span>
  },
  {
    value: 'Times New Roman',
    span: <span style={{fontFamily: 'Times New Roman'}}>Times New Roman</span>
  },
  {
    value: 'Trebuchet MS',
    span: <span style={{fontFamily: 'Trebuchet MS'}}>Trebuchet MS</span>
  },
  {
    value: 'Verdana',
    span: <span style={{fontFamily: 'Verdana'}}>Verdana</span>
  },
]


export default fontNameOptions