import React, {useState} from 'react'
import styles from './InvitesPending.module.css'
import classes from 'classnames'
import DateMoment from '../DateMoment'
import MessageModal from '../../components/MessageModal'
import Icon from '../../components/Icon'
import {formatPhoneNumber} from '../../utils/numberFormat'
import {guidEmpty} from '../../utils/GuidValidate'

function InvitesPending(props) {
  const {invites, sendFirstNotifications, getInvitesPending, resendInvite, acceptInvite, personId, groupId} = props

  const [expanded, setExpanded] = useState(props.expanded)
  const [isShowingModal_delete, setIsShowingModal_delete] = useState(false)
  const [isShowingModal_resend, setIsShowingModal_resend] = useState(false)
  const [friendInvitationId, setFriendInvitationId] = useState(0)

  const handleAcceptInvite = (friendInvitationId) => {
    if (friendInvitationId) acceptInvite(personId, friendInvitationId)
  }

  const handleResend = (friendInvitationId) => {
    if (friendInvitationId) {
      resendInvite(personId, friendInvitationId)
      setTimeout(() => getInvitesPending(personId), 500)
    }
    handleResendOpen()
  }

  const handleFirstNotifications = () => {
    sendFirstNotifications(personId, groupId)
  }

  const handleDelete = () => {
    const {personId, deleteInvite} = props
    if (friendInvitationId) deleteInvite(personId, friendInvitationId)
    handleDeleteConfirmClose()
    setIsShowingModal_delete(false)
    setFriendInvitationId(0)
  }

  const handleToggle = (ev) => {
    ev.preventDefault()
    expanded ? handleCollapse() : handleExpand()
  }

  const handleExpand = () => {
    setExpanded(true)
  }

  const handleCollapse = () => {
    setExpanded(false)
  }

  const handleDeleteConfirmClose = () => setIsShowingModal_delete(false)
  const handleDeleteConfirmOpen = (friendInvitationId) => {
    setIsShowingModal_delete(true)
    setFriendInvitationId(friendInvitationId)
  }
  const handleResendClose = () => setIsShowingModal_resend(false)
  const handleResendOpen = () => {
    setIsShowingModal_resend(true)
    getInvitesPending(personId)
  }

  const getPendingCount = () => {
    const {invites} = props
    let pending = invites && invites.length > 0 && invites.filter(m => (!m.acceptedPersonId || m.acceptedPersonId === guidEmpty) && !m.declineDate)
    return (pending && pending.length) || 0
  }

  const getResponseCount = () => {
    const {invites} = props
    let pending = invites && invites.length > 0 && invites.filter(m => (m.acceptedPersonId && m.acceptedPersonId !== guidEmpty) || m.declineDate)
    return (pending && pending.length) || 0
  }

  return (
    <div className={styles.container}>
      <div className={styles.rowTop}>
        <div className={styles.rowMoreInfo} onClick={() => setExpanded(!expanded)}>
          <Icon 
            pathName={'chevron_right'} 
            premium={false}
            className={expanded && invites && getPendingCount() > 0 ? styles.lessInfo : styles.moreInfo}/>
        </div>
        <div>
          <div className={styles.pendingLabel} onClick={handleToggle}>
            <div>{`You have invitations pending: `} {getPendingCount()}</div>
          </div>
          <div className={expanded && invites && getPendingCount() > 0 ? styles.show : styles.hide}>
            <table cellPadding={5}>
              <tbody>
              {groupId &&
                <tr style={{backgroundColor: 'white'}}>
                  <td align={'right'} colSpan={6}>
                    <div className={classes(styles.actionLink, styles.pendingLabel)}
                          onClick={handleFirstNotifications}>
                      Send first notifications
                    </div>
                  </td>
                </tr>
              }
              {invites.filter(m => (!m.acceptedPersonId || m.acceptedPersonId === guidEmpty) && !m.declineDate).map((m, i) =>
                <tr key={i} style={{backgroundColor: 'transparent'}}>
                  <td>
                    <span className={styles.cellText}>{m.fname} {m.lname}</span>
                  </td>
                  <td>
                    {m.email && <span className={styles.cellText}>{m.emailAddress}</span>}
                    {m.phone &&
                      <span className={styles.cellText}>{formatPhoneNumber(m.phone)}</span>}
                  </td>
                  <td>
                    <DateMoment 
                      date={m.entryDate} format={`D MMM YYYY`}
                      dateTextClass={styles.entryDate}
                      includeTime/>
                  </td>
                  <td>
                    <span onClick={() => handleDeleteConfirmOpen(m.friendInvitationId)}
                          className={styles.cancelButton}>cancel</span>
                  </td>
                  <td>
                    <span onClick={() => handleResend(m.friendInvitationId)} className={styles.cancelButton}>{m.notifiedCount ? 'resend' : 'send'}</span>
                  </td>
                  <td>
                    <span className={classes(styles.cellText, m.notifiedCount ? styles.greenText : styles.redText)}>
                      {m.notifiedCount ? 'notified: ' + m.notifiedCount : 'not notified'}
                    </span>
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
          <div>
            <div className={styles.pendingLabel} onClick={handleToggle}>
              {`Responses from your invited editors: `} {getResponseCount()}
            </div>
            <div className={expanded && invites && getResponseCount() > 0 ? styles.show : styles.hide}>
              {expanded && invites && getResponseCount() > 0 &&
                <table className={styles.marginLeft} cellPadding={5}>
                  <tbody>
                  {invites.filter(m => (m.acceptedPersonId && m.acceptedPersonId !== guidEmpty) || m.declineDate).map((m, i) =>
                    <tr key={i} style={{backgroundColor: 'transparent'}}>
                      <td>
                        <span className={styles.cellText}>{m.fname} {m.lname}</span>
                      </td>
                      <td>
                        {m.phone && <span
                          className={styles.cellText}>{formatPhoneNumber(m.phone)}</span>}
                      </td>
                      <td>
                        <DateMoment 
                          date={m.entryDate} 
                          format={`D MMM YYYY`}
                          dateTextClass={styles.entryDate}/>
                      </td>
                      <td>
                        {(m.acceptedPersonId && m.acceptedPersonId !== guidEmpty)
                          ? <span className={styles.cellTextGreen}>Accepted</span>
                          : m.declineDate
                            ? <span className={styles.cellTextRed}>Declined</span>
                            : ''
                        }
                      </td>
                    </tr>
                  )}
                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
      </div>
      <MessageModal 
        show={isShowingModal_delete} handleClose={handleDeleteConfirmClose}
        heading={`Delete this Editor Invitation?`}
        explain={`Are you sure you want to delete this invitation?`} isConfirmType={true}
        onClick={handleDelete}/>
      <MessageModal 
        show={isShowingModal_resend} handleClose={handleResendClose} heading={`Editor Invitation`}
        explain={`The invitation has been resent to this editor.`}
        onClick={handleResendClose}/>
    </div>
  )
}

export default InvitesPending