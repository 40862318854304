import React from 'react';
import PeerGroupAddNewView from '../views/PeerGroupAddNewView';
import { connect } from 'react-redux';
import * as actionPeerGroup from '../actions/peer-group.js';
import { selectMe, selectGroups } from '../store.js';

const mapStateToProps = (state, props) => {
    let me = selectMe(state);

    // let subGroupCountOptions = []
    // let maxLength = group && group.members && group.members.length ? group.members.length : 100;
    // for(var i = 1; i <= maxLength; i++) {
    //     let option = { id: i, label: i};
    //     subGroupCountOptions = subGroupCountOptions ? subGroupCountOptions.concat(option) : [option];
    // }

    return {
        personId: me.personId,
        // summary: group,
        // subGroupCountOptions,
        // assignedSubGroup: peerGroup && peerGroup.subGroups,
        // peerGroupId: peerGroup && peerGroup.peerGroup && peerGroup.peerGroup.peerGroupId,
        // subGroupCount: peerGroup && peerGroup.peerGroup && peerGroup.peerGroup.subGroupCount,
        // peerGroupName: peerGroup && peerGroup.peerGroup && peerGroup.peerGroup.peerGroupName,
    }
};

const bindActionsToDispatch = dispatch => ({
    addOrUpdatePeerGroup: (peerGroup, subGroups) => dispatch(actionPeerGroup.addOrUpdatePeerGroup(peerGroup, subGroups)),
    //deletePeerGroup: (personId, peerGroupId) => dispatch(actionPeerGroup.updatePeerGroup(personId, peerGroupId)),  //Error: updatePeerGroup is not found in action.
});

const mergeAllProps = (store, actions) => ({
    ...store, ...actions,
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
  mergeAllProps
);

function Container(props) {
  // const { groupChosen, peerGroupId } = props.params;
  //let group = selectGroups(state) && selectGroups(state).filter(m => m.groupId === groupChosen)[0];
  //let peerGroup = (peerGroupId && group && group.peerGroups && group.peerGroups.filter(({ peerGroup }) => peerGroup.peerGroupId === peerGroupId)[0]) || [];

  return <PeerGroupAddNewView {...props} />
}

export default storeConnector(Container);
