import React, { useEffect, useState } from 'react'
import AssessmentCorrectView from '../views/Assessment/AssessmentCorrectView'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as actionAssessmentCorrect from '../actions/assessment-correct'
import * as actionAssessmentQuestions from '../actions/assessment-questions.js'
import * as actionAssessment from '../actions/assessment.js'
import * as actionWorks from '../actions/works.js'
//import * as actionMyVisitedPages from '../actions/my-visited-pages.js'
import {
  selectMe,
  selectAssessment,
  selectAssessmentCorrect,
  selectAssessmentQuestions,
  selectListLevelGeneral,
  //selectStudents, 
} from '../store.js'

const mapStateToProps = (state, props) => {
  let me = selectMe(state)
  let assessmentQuestions = selectAssessmentQuestions(state)
  let assessmentCorrect = selectAssessmentCorrect(state)
  // let students = selectStudents(state)
  // let student = studentPersonId && students && students.length > 0 && students.filter(m => m.personId === studentPersonId)[0]
  // students = assessmentCorrect && assessmentCorrect.studentsCompleted && assessmentCorrect.studentsCompleted.length > 0
  // 		? students.length > 0 && students.filter(m => {
  // 					let foundStudent = false
  // 					assessmentCorrect.studentsCompleted && assessmentCorrect.studentsCompleted.length > 0 && assessmentCorrect.studentsCompleted.forEach(s => {
  // 							if (m.id === s.id) foundStudent = true
  // 					})
  // 					return foundStudent
  // 			})
  // 		: []
  let assessment = selectAssessment(state)
  let correctionTypeFilter = [{
    label: 'All',
    id: 'all',
  },
  {
    label: 'Pending essays',
    id: 'pendingEssays',
  },
  {
    label: 'Wrong answers',
    id: 'wrongAnswers',
  },
  {
    label: 'Correct answer',
    id: 'correctAnswers',
  },
  ]

  let questionTypeFilter = [{
    label: 'All',
    id: 'all',
  },
  {
    label: 'Essays',
    id: 'ESSAY',
  },
  {
    label: 'Multiple Choice',
    id: 'MULTIPLECHOICE',
  },
  {
    label: 'Multiple Answer',
    id: 'MULTIPLEANSWER',
  },
  {
    label: 'True or False',
    id: 'TRUEFALSE',
  },
  ]

  console.log('assessmentCorrect',assessmentCorrect)
  return {
    assessment,
    assessmentCorrect: assessmentCorrect,
    assessmentQuestions,
    correctionTypeFilter,
    isAuthor: assessment.personId === me.personId,
    langCode: me.langCode,
    listLevelGeneral: selectListLevelGeneral(state),
    personId: me.personId,
    questionTypeFilter,
  }
}

const bindActionsToDispatch = dispatch => ({
  getAssessment: (personId, assessmentId) => dispatch(actionAssessment.getAssessment(personId, assessmentId)),
  assessmentQuestionsInit: (personId, studentPersonId, assessmentId, assignmentId) => dispatch(actionAssessmentQuestions.init(personId, studentPersonId, assessmentId, assignmentId)),
  getCorrectedAssessment: (personId, studentPersonId, assessmentId, assignmentId) => dispatch(actionAssessmentCorrect.getCorrectedAssessment(personId, studentPersonId, assessmentId, assignmentId)),
  retakeTest: (personId, assignmentId, assessmentId, runFunction) => dispatch(actionAssessmentCorrect.retakeTest(personId, assignmentId, assessmentId, runFunction)),
  teacherEssayResponse: (personId, teacherResponse) => dispatch(actionAssessmentCorrect.teacherEssayResponse(personId, teacherResponse)),
  //setPenspringTransfer: (personId, work) => dispatch(actionPenspringTransfer.setPenspringTransfer(personId, work)),
  updateTeacherAssessmentLearnerAnswer: (personId, studentPersonId, assessmentQuestionId, score, assignmentId) => dispatch(actionAssessmentQuestions.updateTeacherAssessmentLearnerAnswer(personId, studentPersonId, assessmentQuestionId, score, assignmentId)),
  setLocalScore: (assessmentQuestionId, score) => dispatch(actionAssessmentCorrect.setLocalScore(assessmentQuestionId, score)),
  clearAssessmentQuestion: () => dispatch(actionAssessmentQuestions.clearAssessmentQuestion()),
  clearAssessmentCorrect: () => dispatch(actionAssessmentCorrect.clearAssessmentCorrect()),
  getListLevelGeneral: () => dispatch(actionWorks.getListLevelGeneral()),
  //    setMyVisitedPage: (personId, myVisitedPage) => dispatch(actionMyVisitedPages.setMyVisitedPage(personId, myVisitedPage)),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

function Container(props) {
  const {
    assessmentCorrect,
    assessmentQuestionsInit,
    getAssessment,
    getCorrectedAssessment,
    getListLevelGeneral,
    //langCode, 
    personId,
  } = props

  const params = useParams()
  const [studentFullName, setStudentFullName] = useState()

  useEffect(() => {
    //getPageLangs(personId, langCode, 'AssessmentCorrectView')
    getAssessment(personId, params.assessmentId)
    getCorrectedAssessment(personId, params.studentPersonId, params.assessmentId, params.assignmentId)
    assessmentQuestionsInit(personId, params.studentPersonId, params.assessmentId, params.assignmentId)
    getListLevelGeneral()
    //props.location && props.location.pathname && setMyVisitedPage(personId, { path: props.location.pathname, description: `Correct Assessment (${studentFullName})` })
  }, [personId, params.studentPersonId, params.assessmentId, params.assignmentId])

  useEffect(() => {
    if (!studentFullName && params.studentPersonId && assessmentCorrect?.studentsCompleted?.length > 0) {
      const student = assessmentCorrect?.studentsCompleted.filter(m => m.id === params.studentPersonId)[0]
      if (student) setStudentFullName(student.label)
    }
  }, [params.studentPersonId, assessmentCorrect])

  return <AssessmentCorrectView {...props}
    assessmentId={params.assessmentId}
    assignmentId={params.assignmentId}
    studentFullName={studentFullName}
    studentPersonId={params.studentPersonId} />
}

export default storeConnector(Container)
