import * as types from '../actions/actionTypes';

export default function(state = "", action) {
    switch(action.type) {
        case types.IS_ACTIVE_INVITE:
            return action.payload ? action.payload : state;
        default:
            return state;
    }
}

export const selectEditorInviteIsActive = (state) => state;
