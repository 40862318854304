import React, { useState, useEffect, useRef } from 'react'
import styles from './OriginatingEditorSendModal.module.css'
import PenspringSmall from '../../assets/penspring_medium_noreg.png'
import ButtonWithIcon from '../ButtonWithIcon'
import Checkbox from '../Checkbox'
import RadioGroup from '../RadioGroup'
import { useMediaQuery } from "react-responsive"
import { createInfoToastAuto } from '../../services/queryClient'
import classes from 'classnames'
import SelectSingleDropDown from '../SelectSingleDropDown/SelectSingleDropDown'
import { formatPhoneNumber } from '../../utils/numberFormat'
import * as editorService from '../../services/editor-dom'

const OriginatingEditorSendModal = (props) => {
  const {
    isOpen,
    onCancel,
    downloadOptions,
    workSummary,
    sendOriginatingEditorContents,
    setOpenOriginatingAuthorSend } = props

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [choseEmail, setChoseEmail] = useState(false)
  const [chosePhone, setChosePhone] = useState(false)
  const [choseAttachment, setChoseAttachment] = useState('docx')
  const [choseContentType, setChoseContentType] = useState('')
  const [emailAddress, setEmailAddress] = useState()
  const [phone, setPhone] = useState()
  const [editDisplay, setEditDisplay] = useState('')

  const containerRef = useRef(null);

  useEffect(() => {
    return () => clearState()
  }, [])

  useEffect(() => {
    if (props.edits && !editDisplay) setEditDisplay(editorService.displayEditStatistics(props.edits, props.workSummary?.workWordCount))
  }, [props.edits, props.workSummary])

  const clearState = () => {
    setChoseEmail(false)
    setChosePhone(false)
    setChoseAttachment('docx')
    setChoseContentType('')
  }

  useEffect(() => {
    if (workSummary?.workOwners?.length > 0 && workSummary.workOwners[0].emailAddress) {
      if (validateEmailAddress(workSummary.workOwners[0].emailAddress)) {
        setEmailAddress(workSummary.workOwners[0].emailAddress)
      }
    }
    if (workSummary?.workOwners?.length > 0 && formatPhoneNumber(workSummary.workOwners[0].phone)) {
      if (formatPhoneNumber(workSummary.workOwners[0].phone).length === 14) { //14 characters is formatted: (801) 318-7907
        setPhone(formatPhoneNumber(workSummary.workOwners[0].phone))
      }
    }
    
  }, [workSummary])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && (!containerRef.current.contains(event.target))) {
        setOpenOriginatingAuthorSend(false)
      }
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && containerRef.current) { // ESC key
        setOpenOriginatingAuthorSend(false)
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [isOpen]);

  const validateEmailAddress = (emailAddress) => {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
    return re.test(emailAddress)
  }

  const processForm = (event) => {
    event.stopPropagation()
    event.preventDefault()
    let missingFields = ''

    if (!choseEmail && !chosePhone) missingFields += "\nChoose to send by phone or email."
    if (choseEmail && !choseContentType) missingFields += "\nChoose email content."

    if (missingFields) {
      createInfoToastAuto(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`)
    } else {
      let htmlString = document.getElementById('editorDiv').innerHTML
      //Take out the ending paragraphs and spans which are empty for the purpose of convenience for the editor to move around and put in a sentence in the middle of no-man's land lower in the document. But unnecessary and in the way when downloading a document.
      htmlString = editorService.removeExtraSpaceLines(htmlString)
      htmlString = editorService.removePenspringEditImages(htmlString)

      setSubmitSuccess(true)
      sendOriginatingEditorContents({ 
        workId: workSummary.workId, 
        chapterId: workSummary.chapterId_current,
        htmlString, 
        choseEmail, 
        chosePhone, 
        choseAttachment: choseEmail && choseContentType === "EMAILATTACHMENT" ? choseAttachment : "", 
        choseContentType})
      setSubmitSuccess(false)
      clearState()
      onCancel()
    }
  }

  return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background} ref={containerRef}>
          <div className={styles.titleWhite}>
            Originating Editor
          </div>
          <div className={styles.subHeader}>
            <div className={styles.row}>
              <div className={styles.penspringText}>
                The text and a
              </div>
              <div className={styles.existingPenspring}>
                <img src={PenspringSmall} height={15} />
              </div>
              <div className={styles.penspringText}>
                link will be sent to the author.
              </div>
            </div>
            <div className={styles.textWrapCenter}>
              The author can choose to accept your text as it is or return 
              to choose specific edits and make other changes, as needed.
            </div>
          </div>
          <hr />
          <div className={styles.centered}>
            <div className={styles.leftAligned} title={!emailAddress ? 'The email address does not appear to be valid.' : ''}>
              <div className={styles.errorText}>{!emailAddress ? 'The email address does not appear to be valid.' : ''}</div>
              <Checkbox
                label={`Send an email: ${emailAddress}`}
                checked={choseEmail}
                disabled={!emailAddress}
                labelClass={styles.textWhite}
                onClick={() => setChoseEmail(!choseEmail) } />
              <RadioGroup
                data={[
                  { id: 'PLAINTEXT', label: 'Send plain text' },
                  { id: 'EMAILATTACHMENT', 
                    label: <div className={styles.row}>
                              Send an attachment: 
                              <div className={styles.listPosition}>
                                <SelectSingleDropDown
                                  label={``}
                                  noBlank
                                  value={choseAttachment}
                                  disabled={!emailAddress}
                                  options={downloadOptions || []}
                                  height={`medium-short`}
                                  onChange={(event) => { 
                                    setChoseAttachment(event.target.value); 
                                    setChoseContentType('EMAILATTACHMENT');
                                    if (!choseEmail) setChoseEmail(true);}
                                  } />
                              </div>
                            </div> }]}
                name={`choseContentType`}
                initialValue={choseContentType}
                horizontal={false}
                className={styles.subRadioGroup}
                disabled={!emailAddress}
                labelClass={styles.textWhite}
                onClick={value => { setChoseContentType(value); if (!choseEmail) setChoseEmail(true); }} />
            </div>
            <div className={styles.leftAligned} title={!phone ? 'The phone number does not appear to be valid.' : ''}>
              <div className={styles.errorText}>{!phone ? 'The phone number does not appear to be valid.' : ''}</div>
              <Checkbox
                label={`Send a text: ${phone}`}
                checked={chosePhone}
                disabled={!phone}
                labelClass={styles.textWhite}
                onClick={() => setChosePhone(!chosePhone)} />
              <div className={styles.penspringText}>
                <div className={styles.rowIndent}>
                  Plain text will be limited to 400 words
                </div>
                <div className={styles.rowIndent}>
                  <div className={styles.penspringText}>A</div>
                  <div className={styles.existingPenspring}>
                    <img src={PenspringSmall} height={15} />
                  </div>
                  <div className={styles.penspringText}>link will be included</div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.buttonsCenter}>
            <div className={styles.buttonPosition}>
              <span className={styles.cancelButton} onClick={() => { clearState(); onCancel() }}>
                Cancel
              </span>
              <ButtonWithIcon label={'Send'} icon={'chat_bubbles'} onClick={processForm} submitSuccess={submitSuccess} />
            </div>
          </div>
          <div className={styles.editReport}>
            <div className={styles.headerReport}>EDIT STATISTICS</div>
            <div className={styles.insideReport} dangerouslySetInnerHTML={{ __html: editDisplay }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OriginatingEditorSendModal
