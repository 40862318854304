import React, {useState} from 'react';
import globalStyles from '../../utils/globalStyles.module.css';
import styles from './OpenCommunityView.module.css';
import OpenCommunityToSubmit from '../../components/OpenCommunityToSubmit';
import OpenCommunitySubmitted from '../../components/OpenCommunitySubmitted';
import OpenCommunityToVolunteer from '../../components/OpenCommunityToVolunteer';
import OpenCommunityCommitted from '../../components/OpenCommunityCommitted';
import OneFJefFooter from '../../components/OneFJefFooter';
import Accordion from '../../components/Accordion';

function OpenCommunityView(props) {
    const {personId, workOptions, workList, saveOpenCommunityEntry, modifyEntry, declineIdleOptions, genreOptions, languageOptions,
        editSeverityOptions, workSummaries, editorsCountOptions, openCommunityToVol, openCommunityFull, removeOpenCommunityEntry, tabEntryCounts,
        commitOpenCommunityEntry, uncommitOpenCommunityEntry, updateChapterComment, wordCountOptions, setWorkCurrentSelected,
        //work filter related functions
        updateFilterByField_work, updateFilterDefaultFlag_work, clearFilters_work, saveNewSavedSearch_work,
        updateSavedSearch_work, deleteSavedSearch_work, chooseSavedSearch_work,
        workFilterScratch, savedWorkFilterIdCurrent, workFilterOptions,
        //open community filter related functions
        updateFilterByField_openCommunity, updateFilterDefaultFlag_openCommunity, clearFilters_openCommunity,
        saveNewSavedSearch_openCommunity, updateSavedSearch_openCommunity, deleteSavedSearch_openCommunity,
        chooseSavedSearch_openCommunity, openCommunityFilterScratch, savedOpenCommunityFilterIdCurrent,
        openCommunityFilterOptions, } = props;

    const [modifyOpenCommunityEntryId, setModifyOpenCommunityEntryId] = useState(0)
    const [chapter, setChapter] = useState({})
    const [tabsData, setTabsData] = useState({
        chosenTab: "ToVolunteer",
        tabs: props.tabs,
    })

    const handleTabChange = (tabId) => {
        setTabsData({...tabsData, chosenTab: tabId})
    }

    const handleSetToModifyRecord = (openCommunityEntryId) => {
        setTabsData({...tabsData, chosenTab: "ToSubmit"})
        setModifyOpenCommunityEntryId(openCommunityEntryId)
    }

    const changeChapter = (event) => {
        const field = event.target.name
        const chapter = Object.assign({}, chapter)
        chapter[field] = event.target.value
        setChapter(chapter)
    }

    const handleEnterKey = (event) => {
        event.key === "Enter" && processForm();
    }

    const processForm = (isFileUpload, event) => {
        const {addOrUpdateChapter, personId, workId} = props;
        event && event.preventDefault();
        let errors = {};
        let hasError = false;
        let isUpdate = isFileUpload === "UPDATE" ? true : false;
        isFileUpload = isFileUpload === "UPDATE" ? false : isFileUpload;

        if (!chapter.name) {
            errors.name = "Chapter name is required.";
            hasError = true;
        }

        if (!hasError) {
            addOrUpdateChapter(personId, workId, chapter, isFileUpload, isUpdate);
        }
    }

    return (
        <section className={styles.container}>
            <div className={styles.titleLine}>
                <span className={globalStyles.pageTitle}>
                    {'Open Community'}
                </span>
            </div>
            <Accordion title={`To Volunteer`}>
                {/* //count={tabEntryCounts[0]} isOpenCommunity={true}> */}
                <OpenCommunityToVolunteer personId={personId} openCommunityToVol={openCommunityToVol} updateFilterByField={updateFilterByField_openCommunity}
                    clearFilters={clearFilters_openCommunity} filterScratch={openCommunityFilterScratch} savedFilterIdCurrent={savedOpenCommunityFilterIdCurrent}
                    openCommunityFilterOptions={openCommunityFilterOptions} updateSavedSearch={updateSavedSearch_openCommunity}
                    updateFilterDefaultFlag={updateFilterDefaultFlag_openCommunity} deleteSavedSearch={deleteSavedSearch_openCommunity}
                    chooseSavedSearch={chooseSavedSearch_openCommunity} saveNewSavedSearch={saveNewSavedSearch_openCommunity}
                    uncommitOpenCommunityEntry={uncommitOpenCommunityEntry} commitOpenCommunityEntry={commitOpenCommunityEntry}
                    genreOptions={genreOptions} languageOptions={languageOptions}
                    wordCountOptions={wordCountOptions} editSeverityOptions={editSeverityOptions}/>
            </Accordion >
            <Accordion title={`Committed`}>
                {/* //count={tabEntryCounts[1]} isOpenCommunity={true}> */}
                <OpenCommunityCommitted personId={personId} showCommitted={true} openCommunityFull={openCommunityFull}
                    uncommitOpenCommunityEntry={uncommitOpenCommunityEntry} updateChapterComment={updateChapterComment}
                    setWorkCurrentSelected={setWorkCurrentSelected}/>
            </Accordion >
            <Accordion title={`To Submit`}>
                {/* //count={tabEntryCounts[2]} isOpenCommunity={true}> */}
                <OpenCommunityToSubmit workOptions={workOptions} workList={workList} saveOpenCommunityEntry={saveOpenCommunityEntry}
                    modifyEntry={modifyEntry} declineIdleOptions={declineIdleOptions} genreOptions={genreOptions} personId={personId}
                    languageOptions={languageOptions} workSummaries={workSummaries}
                    editSeverityOptions={editSeverityOptions} editorsCountOptions={editorsCountOptions} openCommunityFull={openCommunityFull}
                    onSubmitTabChange={() => handleTabChange('Submitted')} modifyOpenCommunityEntryId={modifyOpenCommunityEntryId}
                    updateChapterComment={updateChapterComment} updateFilterByField_work={updateFilterByField_work}
                    updateFilterDefaultFlag_work={updateFilterDefaultFlag_work} clearFilters_work={clearFilters_work}
                    saveNewSavedSearch_work={saveNewSavedSearch_work} updateSavedSearch_work={updateSavedSearch_work}
                    deleteSavedSearch_work={deleteSavedSearch_work} chooseSavedSearch_work={chooseSavedSearch_work}
                    workFilterScratch={workFilterScratch} savedWorkFilterIdCurrent={savedWorkFilterIdCurrent}
                    workFilterOptions={workFilterOptions} />
            </Accordion >
            <Accordion title={`Submitted`}>
                {/* //count={tabEntryCounts[3]} isOpenCommunity={true}> */}
                <OpenCommunitySubmitted personId={personId} openCommunityFull={openCommunityFull} removeOpenCommunityEntry={removeOpenCommunityEntry}
                    setToModifyRecord={handleSetToModifyRecord} updateChapterComment={updateChapterComment}/>
            </Accordion >
            <OneFJefFooter />
        </section>
    );
}

//                <TabPage tabsData={tabsData} counts={tabEntryCounts} onClick={handleTabChange} className={styles.tabPageMargin} showZeroCount={true}/>

export default OpenCommunityView