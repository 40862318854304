import React from 'react';
import styles from './DocumentSectionControl.module.css';
import classes from "classnames";
import Icon from '../Icon'
import MenuDocumentRibbon from '../MenuDocumentRibbon'

function DocumentSectionControl(props) {
  const { label, expanded, onClick, workCount, subFolders, mineOrOthers} = props
    return (
      <div className={styles.container}>
        <div className={classes(styles.mainFolder, styles.row)}>
          <Icon pathName={expanded ? 'chevron_down' : 'chevron_right'} premium={true} className={styles.iconSmaller} cursor={'pointer'} onClick={onClick}/>
          <div className={classes(styles.backgroundFolder, styles.rowSpaceBetween)}>
            <div className={styles.row} onClick={onClick}>
              <Icon pathName={expanded ? 'folder_minus_inside' : 'folder_plus_inside'} premium={true} fillColor={'white'} className={styles.iconTree} cursor={'pointer'} />
              {label}
              <div className={styles.count}>{workCount}</div>
            </div>
            <MenuDocumentRibbon {...props}/>
          </div>
        </div>
      </div>
    )
}

export default DocumentSectionControl