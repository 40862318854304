import React, {useState} from 'react';
import {AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip} from 'recharts';
import styles from './ChartLineArea.module.css'
import ChartTooltip from '../ChartTooltip'
import MessageModal from '../MessageModal'


const ChartLineArea = ({data, workId, languageId, width=730, height=250, showLandingMessage}) => {
  const [showInstructions, setShowInstructions] = useState(false)

  let clickFunction = () => {}
  if (showLandingMessage) {
    clickFunction = () => setShowInstructions(true)
  } else {
    clickFunction = (event, {payload}) => window.location = `/draftReview/${workId}/${languageId}/${encodeURIComponent(payload.name)}/${encodeURIComponent(payload.date)}`
  }

  return (
    <div className={styles.container}>
      <AreaChart width={width} height={height} data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }} >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0f6078" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#0f6078" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#c28422" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#c28422" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<ChartTooltip />}/>
        <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" activeDot={{
          onClick: clickFunction }}/>
        <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" activeDot={{
          onClick: clickFunction }}/>
      </AreaChart>
      <MessageModal show={showInstructions} handleClose={() => setShowInstructions(false)} heading={`Editor Instructions`}
                    explain={`When you are logged in with access to a document, you would be taken to the document comparison view.`}
                    onClick={() => setShowInstructions(false)}/>
    </div>
  );
}

export default ChartLineArea;