import React, { useState, useRef, useEffect } from 'react'
import styles from './TextStyleChangeModal.module.css'
import TextStyleSettingInputControls from '../TextStyleSettingInputControls'
import TextStyleSettingGroupTable from '../TextStyleSettingGroupTable'
import ButtonWithIcon from '../ButtonWithIcon'
import MessageModal from '../MessageModal'
import RadioGroup from '../RadioGroup'
import { useMediaQuery } from "react-responsive"
import { createInfoToastAuto } from '../../services/queryClient'
import classes from 'classnames'
import * as editorService from '../../services/editor-dom'

const TextStyleChangeModal = (props) => {
	const { 
		isOpen, 
		onClose, 
		onCancel,
		textStyleGroups, 
		fontOptions, 
		fontSizes, 
		lineHeights, 
		textAlign, 
		bottomSpace, 
		updateChangeStyleEdit,
		changeStyleEditArray,
		editLanguageId,
		savedElementsForEdit,
		segments,
		textIndent,
		hasSelectedText,
		savedRange,
		savedSpansForStyleChange } = props

    const containerRef = useRef(null);

	const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
	const [settings, setSettings] = useState({})
	const [submitSuccess, setSubmitSuccess] = useState(false)
	const [applyStyle, setApplyStyle] = useState('')
	const [openSentenceRangeMessage, setOpenSentenceRangeMessage] = useState(false)
	const [applyOptions, setApplyOptions] = useState([])

	useEffect(() => {
		let newApplyOptions = [{ id: 'ENTIREDOCUMENT', label: 'Apply to the entire document' }]
		if (hasSelectedText) {
			newApplyOptions.push({ id: 'SELECTIONRANGE', label: 'Apply to the selected text' })
			setApplyStyle('SELECTIONRANGE')
		}
		if (props.changeStyle) {
			newApplyOptions.push({ id: 'SENTENCERANGE', label: 'Apply to one or more sentences' })
			setApplyStyle('SENTENCERANGE')
		}
		setApplyOptions(newApplyOptions)
	}, [savedSpansForStyleChange, hasSelectedText, props.changeStyle])

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (containerRef.current && !containerRef.current.contains(event.target) && isOpen) {
				onCancel()
			}
		}
		const handleKeyDown = (event) => {
			if (event.key === 'Escape' && containerRef.current && isOpen) {
				onCancel()
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('keydown', handleKeyDown);
		}
	}, [isOpen]);

	const handleSetApplyStyle = (value) => {
		if (value === 'SENTENCERANGE') {
			setOpenSentenceRangeMessage(true)
		} else {
			setApplyStyle(value)
		}
	}

	const processForm = (event) => {
		event.stopPropagation()
		event.preventDefault()
		let missingFields = ''

		if (!applyStyle) missingFields += "\nChoose how to apply these style changes"
		if (!settings.fontFamily) missingFields += "\nFont-family"
		if (!settings.fontSize) missingFields += "\nFont-size"
		if (!settings.lineHeight) missingFields += "\nLine-height"
		if (!settings.textAlign) missingFields += "\nText-align"
		if (!settings.marginBottom) missingFields += "\nParagragh-height"
		if (!settings.textIndent) missingFields += "\nParagraph-indent"

		if (missingFields) {
			createInfoToastAuto(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`)
		} else {
			setSubmitSuccess(true)

			const settingsArray = Object.entries(settings).map(([code, value]) => ({ code, value }))
			if (applyStyle === 'ENTIREDOCUMENT') {
				const tempSegments = editorService.updateChapterWithTextStyle_Segments(settingsArray, props.segments)
				if (props.isAuthor) {
					props.updateSegmentsLocal(tempSegments)
				} else {
					props.addOrUpdateEdit({
						editSegmentId: 0,
						editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
						personId: props.personId,
						chapterId: props.chapterId,
						elementId: 1, //This is for the entire document so it starts at the top
						languageId: editLanguageId,
						type: 'CHANGESTYLE',
						changeStyleEntireDoc: true,
						text: '',
						textStyleChangeEntries: settingsArray,
					}, () => {
						setTimeout(() => props.getWorkEditReviewFilled(), 500)
					})
				}
			} else if (applyStyle === 'SELECTIONRANGE') {
				const changeStyleEditArray = savedSpansForStyleChange.reduce((acc, m) => acc?.length > 0 ? acc.concat(m.id) : [m.id], [])
				editorService.setChangeStylesForEditor_DOM({
					addOrUpdateEdit: props.addOrUpdateEdit,
					changeStyleEditArray,
					chapterId: props.chapterId,
					divDisplayId: 'editorDiv',
					editLanguageId,
					getWorkEditReviewFilled: props.getWorkEditReviewFilled,
					isAuthor: props.isAuthor,
					personId: props.personId,
					savedElementsForEdit, //This will be holding the partial text of a single sentence selection
					savedRange,
					segments,
					textStyleChangeEntries: settingsArray,
				})

				if (props.isAuthor) {
					let saveSegments = editorService.gatherSegmentsToSave(segments, props.chapterId, props.editLanguageId, props.getNextId)
					props.addOrUpdateSegments(props.personId, saveSegments)
					props.setSaveWorkSpaceTime(new Date())
					props.setChangeCounts(0)
				}
			} else if (applyStyle === 'SENTENCERANGE' || (props.changeStyleEditArray.length > 0 && props.changeStyle)) {
				updateChangeStyleEdit('ChoseSettings', null, editLanguageId, settingsArray	, '') //moveEndParagraph ???
			}
			setSettings()
			setSubmitSuccess(false)
			onClose()
		}
	}

	const resetSettings = () => {
		setSettings()
	}

	const handleSetEditSettings = (settings) => {
		let convertSettings = settings.entries.reduce((acc, m) => {
			let code = m.code.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())
			acc = { ...acc, [code]: m.value }
			return acc
		}, {})
		setSettings(convertSettings)
		setSubmitSuccess(false)
	}

	const handleSetting = (event) => {
		const value = event.target.value
		const name = event.target.id
		setSettings({ ...settings, [name]: value })
	}

	return (
		<div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
			<div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
				<div className={styles.background} ref={containerRef}>
					<div className={styles.titleWhite}>
						Text Style Settings
					</div>
					<RadioGroup
						data={applyOptions || [{id: '', label: ''}]}
						name={`applyStyle`}
						labelClass={styles.textWhite}
						initialValue={applyStyle || ''}
						personId={props.personId}
						onClick={(event) => handleSetApplyStyle(event)}/>
					<TextStyleSettingGroupTable
						isMobile={isMobile}
						isOpen={isOpen}
						hideEdit={true}
						fillControlsWithChoice={true}
						settings={settings}
						textStyleGroups={textStyleGroups}
						handleSetEditSettings={handleSetEditSettings} />
					<hr />
					<div className={styles.titleCustomStyle}>Add a custom text style group</div>
					<div className={styles.centered}>
						<TextStyleSettingInputControls
							isOpen={isOpen}
							settings={settings}
							setSettings={setSettings}
							setStyleByCurrentElement={true}
							changeStyleEditArray={props.changeStyleEditArray}
							handleSetting={handleSetting}
							fontOptions={fontOptions}
							fontSizes={fontSizes}
							lineHeights={lineHeights}
							textAlign={textAlign}
							bottomSpace={bottomSpace}
							textIndent={textIndent} {...props} />
					</div>
					<div className={styles.buttonsCenter}>
						<div className={styles.buttonPosition}>
							<span className={styles.cancelButton} onClick={resetSettings}>
								Clear
							</span>
							<span className={styles.cancelButton} onClick={onCancel}>
								Cancel
							</span>
								<ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={processForm} submitSuccess={submitSuccess} />
						</div>
					</div>
				</div>
			</div>
			<MessageModal
				show={openSentenceRangeMessage} handleClose={() => setOpenSentenceRangeMessage(false)}
				explain={`In order to set styles for one or more sentences, I will turn on the 'Change Style' edit option and return you to the editing page so you can click on the first sentence. Then click on the end icon that is the range you want for the last sentence.`}
				isConfirmType={true}
				onClick={() => { setOpenSentenceRangeMessage(false); props.setChangeStyle(true); onClose() }} />
		</div>	
	)
}

export default TextStyleChangeModal
