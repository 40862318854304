import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useNavigate} from "react-router";
import styles from './MobileHeader.module.css';
import Logo from '../../assets/penspring_large.png';
import MainMenu from '../MainMenu';
import MenuHeaderIcons from '../MenuHeaderIcons';
import Idle from 'react-idle';

function MobileHeader(props) {
  const {workSummary, setWorkCurrentSelected, isNewUserOnFirstWorkAdd, personId, personName, firstName, deleteWork,
    editorScore, groupSummary, setGroupCurrentSelected,
    deleteGroup, groupSummaries, updatePersonConfig, personConfig, editorInvitePending, deleteInvite, acceptInvite,
    resendInvite} = props;

  const navigate = useNavigate();

  const [isShowingOffline, setIsShowingOffline] = useState(false);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
  }, []);

  const handleLogout = () => {
    navigate("/login")
    props.logout();
  }

  const handleOffline = () => {
      setIsShowingOffline(true)
  }

  const handleOnline = () => {
      setIsShowingOffline(false)
  }

  return (
      <div className={styles.container}>
          <div className={styles.container}>
            <Link to="/" tabIndex='-1'><img src={Logo} className={styles.logo} alt={`penspring logo`} tabIndex='-1'/></Link>
            <MenuHeaderIcons firstName={firstName}/>
            {!isNewUserOnFirstWorkAdd &&
                <MainMenu className={styles.nav} workSummary={workSummary} setWorkCurrentSelected={setWorkCurrentSelected}
                    personId={personId} personName={personName} deleteWork={deleteWork} 
                    editorScore={editorScore} groupSummary={groupSummary} setGroupCurrentSelected={setGroupCurrentSelected}
                    deleteGroup={deleteGroup} groupSummaries={groupSummaries} updatePersonConfig={updatePersonConfig} personConfig={personConfig}
                    editorInvitePending={editorInvitePending} deleteInvite={deleteInvite} acceptInvite={acceptInvite} resendInvite={resendInvite}/>
             }
          </div>
          {isShowingOffline && <div className={styles.offlineText}>You appear to be offline.</div>}
          <Idle
              className={styles.highZIndex}
              timeout={1200000}
              onChange={({ idle}) => {
                  if (idle) {
                      handleLogout();
                  }
                }}
              render={({ idle }) =>
                  <div>
                      {idle
                        ? <div className={styles.expiredText}>It appears that your session may have expired.</div>
                        : <div></div>
                      }
                  </div>
              }
            />
      </div>
  )
}

export default MobileHeader;
