import * as types from '../actions/actionTypes';

export default function(state = [], action) {
    switch(action.type) {
      case types.WORK_EDIT_REPORT_INIT:
            return action.payload;

        default:
            return state;
    }
}

 export const selectWorkEditReport = (state) => state;
