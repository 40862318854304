import * as types from './actionTypes'
import {apiHost} from '../api_host.js'

export const getWorkAddSettings = () => {
	return dispatch => {
		return fetch(`${apiHost}ebi/workAddSetting/get`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({type: types.WORK_ADD_SUMMARY_INIT, payload: response})
			})
	}
}


export const saveWorkAddSetting = (settings) => {
	return dispatch => {
		fetch(`${apiHost}ebi/workAddSetting/save`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
			body: JSON.stringify(settings),
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({ type: types.WORK_ADD_SUMMARY_INIT, payload: response })
			})
	}
}

export const deleteWorkAddSetting = (workAddSettingId) => {
	return dispatch => {
		return fetch(`${apiHost}ebi/workAddSetting/delete/${workAddSettingId}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Credentials': 'true',
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
				"Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
				"Authorization": "Bearer " + localStorage.getItem("authToken"),
			},
		})
			.then(response => {
				if (response.status >= 200 && response.status < 300) {
					return response.json()
				} else {
					const error = new Error(response.statusText)
					error.response = response
					throw error
				}
			})
			.then(response => {
				dispatch({ type: types.WORK_ADD_SUMMARY_INIT, payload: response })
			})
	}
}

