const backgroundColors = {
	normal: "white",
	editPending: "#e0effa", //f3f9fd
	editPending_RGB: "rgb(230, 244, 255)",
	currentFocus: "yellow",
	editHistory: "#FDFADC",
	fullVersionView: "#F7FAF8",
	moveSentence: "#e6f4ff",
	deletedSentence: "#f57580",
  translatedFinal: "#fcf8f3",
	currentEditorColor: "ff6600", //Notice the lack of the # for the dynamic urlFile name
	addTabGradient: "linear-gradient(90deg, rgba(255,102,0,1) 0%, rgba(255,255,255,1) 44%)"
}

export default backgroundColors