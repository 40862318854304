import * as types from '../actions/actionTypes'

export default function(state=[], action) {
    switch(action.type) {
        case types.PEOPLE_BY_GROUP_INIT: {
            return action.payload
        }
        default:
            return state
    }
}

export const selectPeopleByGroupIds = (state) => state
