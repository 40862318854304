import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import styles from './AssessmentCorrectView.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import EditTable from '../../../components/EditTable'
import SelectSingleDropDown from '../../../components/SelectSingleDropDown'
import TextDisplay from '../../../components/TextDisplay'
import RadioGroup from '../../../components/RadioGroup'
import LinkDisplay from '../../../components/LinkDisplay'
import Icon from '../../../components/Icon'
import AssessmentTrueFalse from '../../../components/Assessment/AssessmentTrueFalse'
import AssessmentMultipleChoice from '../../../components/Assessment/AssessmentMultipleChoice'
import AssessmentMultipleAnswer from '../../../components/Assessment/AssessmentMultipleAnswer'
import AssessmentEssay from '../../../components/Assessment/AssessmentEssay'
import AssessmentPassage from '../../../components/Assessment/AssessmentPassage'
import AssessmentSingleEntry from '../../../components/Assessment/AssessmentSingleEntry'
import AssessmentFillBlank from '../../../components/Assessment/AssessmentFillBlank'
import AssessmentMatching from '../../../components/Assessment/AssessmentMatching'
import InputText from '../../../components/InputText'
import OneFJefFooter from '../../../components/OneFJefFooter'
import classes from 'classnames'
import { createConfirmToastAuto } from '../../../services/queryClient.js'
import { useMediaQuery } from "react-responsive"
import PointsDisplay from '../../../components/PointsDisplay/PointsDisplay.js'

function AssessmentCorrectView(props) {
  const {
    assessment,
    assessmentCorrect = {},
    assessmentId,
    assessmentQuestionsInit, courseScheduledId,
    assignmentId,
    clearAssessmentCorrect,
    clearAssessmentQuestion,
    correctionTypeFilter,
    getCorrectedAssessment,
    isAuthor,
    personId,
    questionTypeFilter,
    retakeTest,
    studentFullName,
    students,
    teacherEssayResponse,
    updateTeacherAssessmentLearnerAnswer,
    listLevelGeneral,
  } = props

  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  const [correctionTypes, setCorrectionTypes] = useState('all')
  const [isSearch, setIsSearch] = useState(false)
  const [localQuestionView, setLocalQuestionView] = useState([])
  const [questionTypes, setQuestionTypes] = useState('all')
  const [score, setScore] = useState(false)
  const [studentPersonId, setStudentPersonId] = useState()

  useEffect(() => {
    setStudentPersonId(props.studentPersonId)
  }, [props.studentPersonId])

  useEffect(() => {
    debugger
    localStorage.setItem('preEditReviewPage', location.pathname)
    return () => {
      clearAssessmentQuestion()
      clearAssessmentCorrect()
    }
  }, [])

  const submitEssayResponse = (assessmentQuestionId, teacherResponse) => {
    teacherResponse.studentPersonId = studentPersonId
    teacherResponse.assessmentQuestionId = assessmentQuestionId
    teacherResponse.assessmentId = assessmentId
    teacherEssayResponse(personId, teacherResponse)
  }

  const toggleSearch = () => {
    setIsSearch(!isSearch)
  }

  const changeCorrectionTypes = (correctionTypes) => {
    setCorrectionTypes(correctionTypes)
  }

  const changeQuestionTypes = (questionTypes) => {
    setQuestionTypes(questionTypes)
  }

  const handleLearnerChange = (event) => {
    setStudentPersonId(event.target.value)
    setScore([])
    getCorrectedAssessment(personId, event.target.value, assessmentId, assignmentId)
    assessmentQuestionsInit(personId, event.target.value, assessmentId, assignmentId)
    navigate(`/assessmentCorrect/${assignmentId}/${assessmentId}/${event.target.value}/${courseScheduledId}`)
  }

  const handleRetakeTestOpen = () => createConfirmToastAuto('<div>Are you sure you want to retake this assignment?</div>', handleRetakeTest)
  const handleRetakeTest = () => {
    const runFunction = () => navigate(`/assessmentLearner/${assignmentId}/${assessmentId}`)
    retakeTest(personId, assignmentId, assessmentId, runFunction)
  }

  const onBlurScore = (assessmentQuestionId, event) => {
    if (isAuthor) {
      updateTeacherAssessmentLearnerAnswer(personId, studentPersonId, assessmentQuestionId, event.target.value, assignmentId)
    }
  }

  const handleScore = (assessmentQuestionId, event) => {
    props.setLocalScore(assessmentQuestionId, event.target.value)
  }


  let canViewAnswers = isAuthor
    ? !assessment.doNotShowAnswersImmediately
    : true

  let headings = [
    { label: '%', tightText: true },
    { label: 'Score', tightText: true },
    { label: 'Score Pending', tightText: true },
    { label: 'Essays pending', tightText: true },
    { label: 'Total', tightText: true },
    { label: 'Questions', tightText: true }
  ]

  let data = []

  let details = assessmentCorrect.details

  if (details && details.length > 0) {
    let score = Math.round(details.reduce((acc, m) => acc + m.score, 0) * 10) / 10
    let scorePending = details.reduce((acc, m) => m.pendingCorrection ? acc + m.pointsPossible : acc, 0)
    let essaysPending = details.reduce((acc, m) => m.pendingCorrection ? ++acc : acc, 0)
    let totalPoints = details.reduce((acc, m) => acc + m.pointsPossible, 0)
    let questionsCount = details.length
    let scorePercent = (totalPoints - scorePending) > 0 ? Math.round((score / (totalPoints - scorePending)) * 100) : 0

    data = [[
      { value: scorePending ? '--' : scorePercent + '%', boldText: true },
      { value: Number(score), boldText: true },
      { value: <div className={styles.red}>{!!scorePending ? scorePending : ''}</div>, boldText: true },
      { value: <div className={styles.red}>{!!essaysPending ? essaysPending : ''}</div>, boldText: true },
      { value: totalPoints, boldText: true },
      { value: questionsCount, boldText: true },
    ]]
  }

  let filteredQuestions = Object.assign([], props.assessmentQuestions)

  if (correctionTypes !== "all" && details && details.length > 0) {
    let questionIds = []
    if (correctionTypes === "pendingEssays") {
      questionIds = details.reduce((acc, m) => m.pendingCorrection ? acc.concat(m.assessmentQuestionId) : acc, [])
    } else if (correctionTypes === "correctAnswers") {
      questionIds = details.reduce((acc, m) => m.isCorrect ? acc.concat(m.assessmentQuestionId) : acc, [])
    } else if (correctionTypes === "wrongAnswers") {
      questionIds = details.reduce((acc, m) => !m.isCorrect && !m.pendingCorrection ? acc.concat(m.assessmentQuestionId) : acc, [])
    }
    filteredQuestions = filteredQuestions.filter(m => questionIds.indexOf(m.assessmentQuestionId) > -1)
  }

  if (questionTypes !== "all" && filteredQuestions && filteredQuestions.length > 0) {
    filteredQuestions = filteredQuestions.filter(m => m.questionTypeCode === questionTypes)
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={globalStyles.pageTitle}>
          Assessment Result
        </div>
        <div className={(styles.moreTop, styles.rowWrap)}>
          <TextDisplay label={'Student'} className={styles.textDisplay} text={studentFullName} />
          {assessmentCorrect.courseName &&
            <TextDisplay label={'Course'} className={styles.textDisplay}
              text={<Link to={isAuthor ? `/gradebookEntry/${courseScheduledId}` : `/studentAssignments/${courseScheduledId}`} className={classes(globalStyles.link, styles.bold)}>
                {assessmentCorrect.courseName}
              </Link>} />
          }
          {/* <TextDisplay label={benchmarkTestId ? `Benchmark assessment` : `Assessment`} className={styles.textDisplay} text={assessment && assessment.name} /> */}
          {!isAuthor && assessmentCorrect.canRetakeQuiz &&
            <a onClick={handleRetakeTestOpen} className={styles.link}>
              Re-take
            </a>
          }
          {/* {isAuthor &&
          <Link to={`/gradebookEntry/${courseScheduledId}`} className={classes(styles.link, styles.row)}>
            <Icon pathName={'medal_empty'} premium={true} />'Go to Gradebook'
          </Link>
        } */}
        </div>
        {isAuthor &&
          <div>
            <SelectSingleDropDown
              id={'studentPersonId'}
              value={studentPersonId || ''}
              label={'Student'}
              options={students}
              height={`medium`}
              noBlank={false}
              className={styles.singleDropDown}
              onChange={handleLearnerChange} />
          </div>
        }
        <hr />
        <div className={styles.rowWrap}>
          <div className={styles.backgroundBlue}>
            <EditTable labelClass={styles.tableLabelClass} headings={headings} data={data} noCount={true} firstColumnClass={styles.firstColumnClass} />
          </div>
          {/* {assessmentCorrect.gradingType === 'STANDARDSRATING' && standardsRatings && standardsRatings.length > 0 &&
          <StandardsAssignmentResult scores={details} standardsRatings={standardsRatings} horizontal={false} showRightCode={true} />
        } */}
        </div>
        {canViewAnswers &&
          <a onClick={toggleSearch} className={classes(styles.row, styles.search)}>
            <Icon pathName={'magnifier'} premium={true} className={styles.icon} />
            {isSearch ? 'Hide filter controls' : 'Show filter choices for question types?'}
          </a>
        }
        <hr />
        {isSearch && canViewAnswers &&
          <div className={styles.backgroundBlue}>
            <RadioGroup
              title={'View correction types'}
              data={correctionTypeFilter}
              horizontal={true}
              className={styles.radio}
              initialValue={correctionTypes || 'all'}
              onClick={changeCorrectionTypes} />
            <hr />
            <RadioGroup
              title={'View question types'}
              data={questionTypeFilter}
              horizontal={true}
              className={styles.radio}
              initialValue={questionTypes || 'all'}
              onClick={changeQuestionTypes} />
            <hr />
          </div>
        }
        {canViewAnswers && filteredQuestions && filteredQuestions.length > 0 && filteredQuestions.map((m, i) => {
          //let correct = (details && details.length > 0 && details.filter(m => m.assessmentQuestionId === m.assessmentQuestionId)[0]) || {}
          let correctControls = ''
          //let scoreRecord = details && details.length > 0 && details.filter(f => f.assessmentQuestionId === m.assessmentQuestionId)[0]
          //let score = scoreRecord && scoreRecord.assessmentQuestionId ? scoreRecord.score : ''
          let score = m.learnerAnswer && m.learnerAnswer.score

          if (isAuthor) {
            correctControls =
              <div className={styles.lessTop}>
                <InputText size={'super-short'}
                  label={''}
                  value={score === 0
                    ? '0'
                    : score || ''
                  }
                  numberOnly={true}
                  maxLength={6}
                  name={m.assessmentQuestionId}
                  onChange={(event) => handleScore(m.assessmentQuestionId, event)}
                  onBlur={(event) => onBlurScore(m.assessmentQuestionId, event)} />
              </div>
          }

          if (m.questionTypeCode === 'TRUEFALSE') {
            return (
              <div key={i}>
                <AssessmentTrueFalse
                  assessmentCorrect={details}
                  isAuthor={isAuthor}
                  nameKey={i}
                  personId={personId}
                  question={m} 
                  correctControls={correctControls}
                  score={score} />
                <div className={styles.linkDisplay}>
                  {m.websiteLinks && m.websiteLinks.length > 0 && m.websiteLinks.map((w, i) =>
                    <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                  )}
                </div>
              </div>
            )
          } else if (m.questionTypeCode === 'MULTIPLECHOICE') {
            return (
              <div key={i}>
                <AssessmentMultipleChoice
                  assessmentCorrect={details}
                  isAuthor={isAuthor}
                  nameKey={i}
                  personId={personId}
                  question={m}
                  viewMode={'CorrectedView'}
                  correctControls={correctControls}
                  score={score} />
              </div>
            )
          } else if (m.questionTypeCode === 'MULTIPLEANSWER') {
            return (
              <div key={i}>
                <AssessmentMultipleAnswer
                  answers={m.answers}
                  assessmentCorrect={details}
                  isAuthor={isAuthor}
                  nameKey={i}
                  personId={personId}
                  question={m}
                  viewMode={'CorrectedView'}
                  correctControls={correctControls}
                  score={score} />
              </div>
            )
          } else if (m.questionTypeCode === 'ESSAY') {
            let correct = details && details.length > 0 && details.filter(a => a.assessmentQuestionId === m.assessmentQuestionId)[0]
            let score = correct && correct.score ? correct.score : 0

            return (
              <div key={i}>
                <AssessmentEssay
                  assessmentCorrect={details}
                  assessmentTitle={assessment && assessment.name}
                  assignmentId={assignmentId}
                  bigTextDisplay={assessment.bigTextDisplay}
                  isAuthor={isAuthor}
                  isMobile={isMobile}
                  nameKey={i}
                  personId={personId}
                  question={m}
                  listLevelGeneral={listLevelGeneral}
                  isSubmitted={true}
                  isTeacher={isAuthor}
                  correctControls={correctControls}
                  score={score} />
                {/*<TextDisplay text={m.keywordCountAccuracy || score ? `${score} out of ${m.pointsPossible}` : `pending teacher review`} label={'Points'} />*/}
                <div className={styles.linkDisplay}>
                  {m.websiteLinks && m.websiteLinks.length > 0 && m.websiteLinks.map((w, i) =>
                    <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                  )}
                </div>
              </div>
            )
          } else if (m.questionTypeCode === 'PASSAGE') {
            return (
              <div key={i}>
                <AssessmentPassage
                  isAuthor={isAuthor}
                  nameKey={i}
                  question={m}
                />
                <div className={styles.linkDisplay}>
                  {m.websiteLinks && m.websiteLinks.length > 0 && m.websiteLinks.map((w, i) =>
                    <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                  )}
                </div>
              </div>
            )
          } else if (m.questionTypeCode === 'SINGLEENTRY') {
            return (
              <div key={i}>
                <AssessmentSingleEntry
                  answers={m.answers}
                  assessmentCorrect={details}
                  isAuthor={isAuthor}
                  learnerAnswer={m.learnerAnswer}
                  nameKey={i}
                  personId={personId}
                  question={m}
                  correctControls={correctControls}
                  score={score} />
                <div className={styles.linkDisplay}>
                  {m.websiteLinks && m.websiteLinks.length > 0 && m.websiteLinks.map((w, i) =>
                    <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                  )}
                </div>
              </div>
            )
          } else if (m.questionTypeCode === 'FILLBLANK') {
            return (
              <div key={i}>
                <AssessmentFillBlank
                  assessmentCorrect={details}
                  isAuthor={isAuthor}
                  learnerAnswer={m.learnerAnswer}
                  nameKey={i}
                  personId={personId}
                  question={m}
                  correctControls={correctControls}
                  score={score} />
                <div className={styles.linkDisplay}>
                  {m.websiteLinks && m.websiteLinks.length > 0 && m.websiteLinks.map((w, i) =>
                    <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                  )}
                </div>
              </div>
            )
          } else if (m.questionTypeCode === 'MATCHING') {
            return (
              <div key={i}>
                <AssessmentMatching
                  nameKey={i}
                  question={m}
                  learnerAnswer={m.learnerAnswer}
                  personId={personId}
                  isAuthor={isAuthor}
                  assessmentCorrect={details}
                  viewMode={'CorrectedView'}
                  correctControls={correctControls}
                  score={score} />
                <div className={styles.linkDisplay}>
                  {m.websiteLinks && m.websiteLinks.length > 0 && m.websiteLinks.map((w, i) =>
                    <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                  )}
                </div>
              </div>
            )
          }
          return m
        })}
      </div>
      <OneFJefFooter />
    </div>
  )
}

export default AssessmentCorrectView