import React from 'react';
import {Link} from 'react-router-dom';
import Icon from '../Icon';
import styles from './MenuHeaderIcons.module.css';
import FolderMagnifyingGlass from '../../assets/folder/FolderMagnifyingGlass.png'
import classes from 'classnames'
import { useMediaQuery } from 'react-responsive';

function MenuHeaderIcons(props) {
  const {firstName} = props;
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  let workId = window.location.pathname.indexOf('/editReview/') > -1 ? window.location.pathname.replace('/editReview/','') : ''
  if (workId) workId = workId.substring(0, workId.indexOf('/'))

  return (
    <div className={styles.container}>
      <Link to={`/myWorks`} className={classes(styles.menuItem, styles.folderMagnifier)} data-rh={'My documents'} title={'My documents'} tabIndex='-1'>
        <img src={FolderMagnifyingGlass} width={30}/>
      </Link>
      <Link to={`/myGroups`} className={styles.menuItem} data-rh={'My groups and contacts'} title={'My groups and contacts'} tabIndex='-1'>
        <Icon pathName={'users2'} premium={true} fillColor={'white'} tabIndex='-1' larger/>
      </Link>
      {/*<Link to={'/giveAccessToWorks'} className={styles.menuItem} data-rh={'Give access by document'}*/}
      {/*      title={'Give access by document'} tabIndex='-1'>*/}
      {/*  <Icon pathName={'share'} premium={true} fillColor={'white'} tabIndex='-1'/>*/}
      {/*</Link>*/}
      {/*<Link to={'/workEditReport'} className={styles.menuItem} data-rh={'Editing and draft reports'}*/}
      {/*      title={'Editing and drafts reports'} tabIndex='-1'>*/}
      {/*  <Icon pathName={'graph_report'} premium={true} fillColor={'white'} tabIndex='-1'/>*/}
      {/*</Link>*/}
      {/*<Link to={'/draftReview'} className={styles.menuItem} data-rh={'Compare drafts'} title={'Compare drafts'} tabIndex='-1'>*/}
      {/*  <Icon pathName={'clipboard_check'} premium={true} fillColor={'white'} tabIndex='-1'/>*/}
      {/*</Link>*/}
      <Link to={'/workAddNew'} className={styles.menuItem} data-rh={'Add new document'} title={'Add new document'}
            tabIndex='-1'>
        <Icon pathName={'document0'} premium={true} superscript={'plus'} supFillColor={'#fbd56f'} fillColor={'white'}
              tabIndex='-1'/>
      </Link>
      <Link to={'/editorInviteOptions'} className={styles.menuItem} data-rh={'Invite new editor'}
            title={'Invite new editor'} tabIndex='-1'>
        <Icon pathName={'user'} premium={true} superscript={'plus'} supFillColor={'#fbd56f'} fillColor={'white'}
              tabIndex='-1'/>
      </Link>
      {/* {workId &&  //I took out this option since the access might be given as a group assignment to an upper folder hwich is hard to calculate and is already taken care of on My Works View
        <div title={'Invite new editor'} className={styles.menuItem}>
          <Link to={`/giveAccessToWorks/work/${workId}`} title={'Give access to editors for this document'}
              tabIndex='-1'>
            <Icon pathName={'share'} premium={true} fillColor={'white'}/>
          </Link>
        </div>
      } */}
      {workId &&
        <div title={'Editor reports'} className={styles.menuItemWider}>
          <Link to={`/workEditReport/work/${workId}`} title={'Editor reports for this document'}
                tabIndex='-1'>
            <Icon pathName={'graph_report'} premium={true} fillColor={'white'}/>
          </Link>
        </div>
      }
      {/*<Link to={`/report/e/edit`} className={styles.menuItem} data-rh={'Editing reports'} title={'Editing reports'} tabIndex='-1'>*/}
      {/*		<Icon pathName={'graph_report'} premium={true} fillColor={'white'} tabIndex='-1'/>*/}
      {/*</Link>*/}
      {!isMobile &&
        <div className={styles.firstName}>{firstName}</div>
      }
    </div>
  )
}

export default MenuHeaderIcons;