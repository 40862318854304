import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { setHttpResponseCode } from '../actions/system';
import NotFound from '../components/Error';

const mapStateToProps = state => ({
  //propName: state.data.specificData
});

// binds the result of action creators to redux dispatch, wrapped in callable functions
const bindActionsToDispatch = dispatch => ({
  setHttpResponseCode: (code) => dispatch(setHttpResponseCode(code)),
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
);

function NotFoundContainer(props) {

  useEffect(() => {
    props.setHttpResponseCode(404);
  }, []);

  return <NotFound {...props} />
}

export default storeConnector(NotFoundContainer);
