import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import styles from './OpenCommunityToSubmit.module.css';
import MultiSelect from '../MultiSelect';
import Checkbox from '../Checkbox';
import SelectSingleDropDown from '../../components/SelectSingleDropDown';
import DateTimePicker from '../../components/DateTimePicker';
import MessageModal from '../../components/MessageModal';
import WorkFilter from '../../components/WorkFilter';
import Accordion from '../../components/Accordion';
import moment from 'moment';

function OpenCommunityToSubmit(props) {
  const {
    workSummaries,
    declineIdleOptions,
    editSeverityOptions,
    modifyOpenCommunityEntryId,
    openCommunityFull,
    languageOptions,
    saveOpenCommunityEntry,
    personId,
    onSubmitTabChange,
    workOptions,
    genreOptions,
    editorsCountOptions,
    updateFilterByField_work,
    updateFilterDefaultFlag_work,
    clearFilters_work,
    saveNewSavedSearch_work,
    updateSavedSearch_work,
    deleteSavedSearch_work,
    chooseSavedSearch_work,
    workFilterScratch,
    savedWorkFilterIdCurrent,
    workFilterOptions
  } = props;

  const [selectedChapters, setSelectedChapters] = useState()
  const [selectedLanguages, setSelectedLanguages] = useState()
  const [selectedGenres, setSelectedGenres] = useState()
  const [chosenWork, setChosenWork] = useState({})
  const [chapterOptions, setChapterOptions] = useState([])
  const [dueDate, setDueDate] = useState('')
  const [editNativeLanguage, setEditNativeLanguage] = useState(false)
  const [nativeLanguageName, setNativeLanguageName] = useState('')
  const [editorsCount, setEditorsCount] = useState(5)
  const [declineIdleId, setDeclineIdleId] = useState()
  const [editSeverityId, setEditSeverityId] = useState()
  const [errorDueDate, setErrorDueDate] = useState('')
  const [errorGenres, setErrorGenres] = useState('')
  const [errorChapters, setErrorChapters] = useState('')
  const [errorNoEntries, setErrorNoEntries] = useState('')
  const [isShowingModal_saved, setIsShowingModal_saved] = useState(false)
  const [isShowingModal_editTranslate, setIsShowingModal_editTranslate] = useState(false)
  const [localWorkSummaries, setLocalWorkSummaries] = useState([])

  useEffect(() => {
    setLocalWorkSummaries(workSummaries)
    setDeclineIdleId(declineIdleOptions && declineIdleOptions.length > 0 && declineIdleOptions[0].id)
    setEditSeverityId(editSeverityOptions && editSeverityOptions.length > 0 && editSeverityOptions[0].id)
  }, [])

  useEffect(() => {
    //If this is a modify record, force in the modify details into the edit controls without having to call the handleWorkChoices function.
    //Then let the record be modified and updated.  If the record is not saved and the user moves onto another, then clear out this record with DidUnmount.
    if (modifyOpenCommunityEntryId && modifyOpenCommunityEntryId !== chosenWork.openCommunityEntryId) {
      let modifyWork = openCommunityFull && openCommunityFull.length > 0 && openCommunityFull.filter(m => m.openCommunityEntryId === modifyOpenCommunityEntryId)[0];
      setChosenWork(modifyWork)
      setSelectedChapters(modifyWork.chapterIds || [])
      setSelectedLanguages(modifyWork.translateLanguageIds || [])
      setSelectedGenres(modifyWork.genreIds || [])
      setChapterOptions(modifyWork.chapterOptions)
      setDueDate(moment(modifyWork.dueDate).format("D MMM YYYY"))
      setNativeLanguageName(modifyWork.nativeLanguageName)
      setEditorsCount(modifyWork.openCommEditorsCount)
      setDeclineIdleId(modifyWork.declineIdleId)
      setEditSeverityId(modifyWork.editSeverityId)
    } else if (!modifyOpenCommunityEntryId && workSummaries && workSummaries.length > 0 && !chosenWork.workId) {
      let firstWork = workSummaries[0];
      handleWorkChoice(firstWork.workId);
    }
  }, [chosenWork, workSummaries])

  const alertNativeAndTranslate = (paramSelectedLanguage, paramChecked) => {
    //The parameters are sent in since this function could be evaluated before the local state is updated.
    if (paramChecked && paramSelectedLanguage.length > 0) {
      handleEditTranslateMessageOpen();
    }
  }

  const handleEditNativeLanguage = (value) => {
    setEditNativeLanguage(value)
    alertNativeAndTranslate(selectedLanguages, value);
  }

  const handleDueDateChange = (dueDate) => {
    setDueDate(dueDate)
    setErrorDueDate("")
  }

  const handleWorkChoice = (value) => {
    let chosenWork = workSummaries && workSummaries.length > 0 && workSummaries.filter(m => m.workId === value)[0]
    let chapterOptions = chosenWork && chosenWork.chapterOptions ? chosenWork.chapterOptions : [];
    let nativeLanguageName = languageOptions && languageOptions.length > 0 && languageOptions.filter(m => m.value === chosenWork.nativeLanguageId)[0];
    nativeLanguageName = nativeLanguageName && nativeLanguageName.label;
    const selectedChapters = chosenWork.chapterOptions.map(o => o.value);
    setSelectedChapters(selectedChapters)
    setChosenWork(chosenWork)
    setChapterOptions(chapterOptions)
    setNativeLanguageName(nativeLanguageName)
  }

  const handleDeclineIdle = (event) => {
    setDeclineIdleId(event.target.value)
  }

  const handleEditSeverity = (event) => {
    setEditSeverityId(event.target.value)
  }

  const handleEditorsCount = (event) => {
    setEditorsCount(event.target.value)
  }

  const processForm = (event) => {
    event && event.preventDefault();
    let hasError = false;

    if (!workSummaries || workSummaries.length === 0) {
      setErrorNoEntries("There are not any available documents to submit")
      hasError = true;
    }

    if (selectedChapters.length === 0) {
      setErrorChapters("Please choose at least one chapter")
      hasError = true;
    }

    if (selectedGenres.length === 0) {
      setErrorGenres("Please choose at least one genre")
      hasError = true;
    }

    if (!dueDate) {
      setErrorDueDate("Please enter a due date")
      hasError = true;
    }

    if (!hasError) {
      let finalDeclineIdleId = declineIdleId ? declineIdleId : declineIdleOptions[0].id;
      let finalEditSeverityId = editSeverityId ? editSeverityId : editSeverityOptions[0].id;
      saveOpenCommunityEntry(personId, chosenWork.workId, selectedChapters, selectedLanguages, editNativeLanguage,
        selectedGenres, dueDate, editorsCount, finalDeclineIdleId, finalEditSeverityId, chosenWork.openCommunityEntryId);
      handleAlertOpen();
      // let withoutRecord = workOptions.filter(m => m.id !== chosenWork.workId);
      // let nextWorkId = withoutRecord[0] ? withoutRecord[0].id : 0;
      // nextWorkId && handleWorkChoice(nextWorkId);

      setSelectedChapters()
      setEditNativeLanguage(false)
      setSelectedLanguages([])
      setSelectedGenres([])
      setChosenWork({})
      setChapterOptions([])
      setDueDate('')
      setNativeLanguageName('')
      setEditorsCount(5)
      setDeclineIdleId(0)
      setEditSeverityId(0)

      onSubmitTabChange();
    }
  }

  const handleSelectedChapters = (selectedChapters) => {
    setSelectedChapters(selectedChapters)
    setErrorChapters('')
  }

  const handleSelectedLanguages = (selectedLanguages) => {
    setSelectedLanguages(selectedLanguages)
    alertNativeAndTranslate(selectedLanguages, editNativeLanguage);
  }

  const handleSelectedGenres = (selectedGenres) => {
    setSelectedGenres(selectedGenres)
    setErrorGenres('')
  }

  const chapterValueRenderer = (selected, options) => {
    if (selected.length === 0) {
      return "Select sections...";
    }
    if (selected.length === options.length) {
      return "All sections/chapters are selected";
    }
    return `Sections:  ${selected.length} of ${options.length}`;
  }

  const languageValueRenderer = (selected, options) => {
    if (!selected || selected.length === 0) {
      return "Select languages for translation (optional)";
    }

    if (selected.length === options.length) {
      return "All languages are selected";
    }
    return `Languages: ${selected.length} of ${options.length}`;
  }

  const genreValueRenderer = (selected, options) => {
    if (selected.length === 0) {
      return "Select genres...";
    }
    if (selected.length === options.length) {
      return "All genres are selected";
    }
    return `Genres:  ${selected.length} of ${options.length}`;
  }

  const handleAlertClose = () => setIsShowingModal_saved(false)
  const handleAlertOpen = () => setIsShowingModal_saved(true)
  const handleEditTranslateMessageClose = () => setIsShowingModal_editTranslate(false)
  const handleEditTranslateMessageOpen = () => setIsShowingModal_editTranslate(true)


  return (
    <div className={styles.container}>
      <Accordion title={'workFilter'}>
        {/*<AccordionItem expanded={false} filterScratch={workFilterScratch} filterOptions={workFilterOptions} savedFilterIdCurrent={savedWorkFilterIdCurrent}*/}
        {/*        updateSavedSearch={updateSavedSearch_work} deleteSavedSearch={deleteSavedSearch_work} chooseSavedSearch={chooseSavedSearch_work}*/}
        {/*        updateFilterByField={updateFilterByField_work} updateFilterDefaultFlag={updateFilterDefaultFlag_work} personId={personId}*/}
        {/*        clearFilters={clearFilters_work}>*/}
        <WorkFilter personId={personId} workFilter={workFilterScratch} className={styles.workFilter}
                    updateFilterByField={updateFilterByField_work} hideSourceStatus={true}
                    clearFilters={clearFilters_work} saveNewSavedSearch={saveNewSavedSearch_work}
                    savedSearchOptions={workFilterOptions}/>
      </Accordion>
      <div className={styles.marginLeft}>
        {(!workOptions || workOptions.length === 0) &&
          <div>
            <hr/>
            <Link to={'/workAddNew'} className={styles.newWork}>Add new document</Link>
            <hr/>
          </div>
        }
        {!modifyOpenCommunityEntryId &&
          <div>
            <SelectSingleDropDown
              value={chosenWork && chosenWork.workId ? chosenWork.workId : workSummaries && workSummaries.length > 0 && workSummaries[0].workId}
              options={workOptions && workOptions.length > 0 ? workOptions : [{id: 0, label: 'no entries'}]}
              error={''}
              label={'Documents'}
              id={'Documents'}
              height={`medium`}
              noBlank={true}
              labelClass={styles.selectLabelClass}
              selectClass={styles.selectListClass}
              onChange={(event) => handleWorkChoice(event.target.value)}/>
            <span className={styles.errorMessage}>{errorNoEntries}</span>
          </div>
        }
        {modifyOpenCommunityEntryId ?
          <div className={styles.stackedText}>
            <span className={styles.nativeLanguage}>Modify Open Community Entry</span>
            <span className={styles.textBig}>{chosenWork.title}</span>
          </div>
          : ''
        }
        {workOptions && workOptions.length > 0 &&
          <div>
            <div className={styles.row}>
              <div className={styles.stackedText}>
                <span className={styles.nativeLanguage}>Native Language</span>
                <span className={styles.textBig}>{nativeLanguageName}</span>
              </div>
              <div className={styles.checkbox}>
                <Checkbox
                  id={`editNativeLanguage`}
                  label={`Edit in the native language`}
                  labelClass={styles.labelCheckbox}
                  position={`before`}
                  checked={editNativeLanguage}
                  onClick={() => handleEditNativeLanguage(!editNativeLanguage)}/>
              </div>
            </div>
            <hr/>
            {chosenWork.sectionCount > 1 &&
              <div className={styles.multiSelect}>
                <MultiSelect
                  options={chapterOptions && chapterOptions.length > 0 ? chapterOptions : [{
                    value: 0,
                    label: 'Please choose a document'
                  }]}
                  onSelectedChanged={handleSelectedChapters}
                  valueRenderer={chapterValueRenderer}
                  selected={selectedChapters}/>
                <span className={styles.errorMessage}>{errorChapters}</span>
              </div>
            }
            <div className={styles.multiSelect}>
              <MultiSelect
                options={languageOptions && languageOptions.length > 0 ? languageOptions : [{
                  value: 0,
                  label: 'no entries'
                }]}
                onSelectedChanged={handleSelectedLanguages}
                valueRenderer={languageValueRenderer}
                selected={selectedLanguages}/>
            </div>
            <div className={styles.multiSelect}>
              <MultiSelect
                options={genreOptions && genreOptions.length > 0 ? genreOptions : [{value: 0, label: 'no entries'}]}
                onSelectedChanged={handleSelectedGenres}
                valueRenderer={genreValueRenderer}
                selected={selectedGenres}/>
              <span className={styles.errorMessage}>{errorGenres}</span>
            </div>
            <hr/>
            <div>
              <SelectSingleDropDown
                value={editorsCount}
                options={editorsCountOptions}
                error={''}
                label={'Allow Quantity of Editors'}
                height={`short`}
                noBlank={true}
                labelClass={styles.selectLabelClass}
                selectClass={styles.selectListClass}
                onChange={handleEditorsCount}/>
            </div>
            <div>
              <div className={styles.dateRow}>
                <span className={styles.textAbove}>Due date</span>
                <DateTimePicker id={`dueDateFrom`} value={dueDate}
                                onChange={(event) => handleDueDateChange(event.target.value)}/>
                <span className={styles.errorMessage}>{errorDueDate}</span>
              </div>
            </div>
            <div>
              <SelectSingleDropDown
                value={declineIdleId}
                options={declineIdleOptions && declineIdleOptions.length > 0 ? declineIdleOptions : [{
                  id: 0,
                  label: 'no entries'
                }]}
                label={'Decline Idle Editors In'}
                height={`medium`}
                noBlank={true}
                labelClass={styles.selectLabelClass}
                selectClass={styles.selectListClass}
                onChange={handleDeclineIdle}/>
            </div>
            <div className={styles.extraMarginTop}>
              <SelectSingleDropDown
                value={editSeverityId}
                options={editSeverityOptions && editSeverityOptions.length > 0 ? editSeverityOptions : [{
                  id: 0,
                  label: 'no entries'
                }]}
                label={'Level of Edit Intensity (native language editing only)'}
                height={`medium`}
                noBlank={true}
                labelClass={styles.selectLabelClass}
                selectClass={styles.selectListClass}
                onChange={handleEditSeverity}/>
            </div>
            <div className={styles.buttonPlace}>
              <a onClick={processForm} className={styles.submitButton}>
                {`Submit`}
              </a>
            </div>
          </div>
        }
        <MessageModal show={isShowingModal_saved} handleClose={handleAlertClose} heading={`New Record Saved`}
                      explain={`The new Open Community entry has been saved.  It can now be seen by editors and translators so that they can volunteer to edit or translate for you.`}
                      onClick={handleAlertClose}/>
        <MessageModal show={isShowingModal_editTranslate} handleClose={handleEditTranslateMessageClose}
                      heading={`Edit and Translate Error!`}
                      explain={`It is not recommended that you request editors for both editing and translating.  The editing process should be complete before translation is started.`}
                      onClick={handleEditTranslateMessageClose}/>
      </div>
    </div>
  );
}

export default OpenCommunityToSubmit