import React, {useState, useEffect} from 'react';
import styles from './EditorInviteGoogleContacts.module.css'
import Checkbox from '../../components/Checkbox'
import InputText from '../../components/InputText'
import ContactSummaryLine from '../../components/ContactSummaryLine'
import classes from "classnames";
import jwtDecode from 'jwt-decode'
import { doSort } from '../../utils/sort'

function EditorInviteGoogleContacts(props) {
	const {
		googleContactsOpen,
		onClick,
		isMobile,
	} = props;

	const [filterContactName, setFilterContactName] = useState('')
	const [contactsFiltered, setContactsFiltered] = useState()
	const [contacts, setContacts] = useState()
	const [chosenContact, setChosenContact] = useState()

	useEffect(() => {
	  let filtered = contacts && contacts.length > 0 ? [...contacts] : []
	  if (filterContactName) {
			filtered = filtered && filtered.length > 0 && filtered.filter(m => m.firstName.toLowerCase().indexOf(filterContactName.toLowerCase()) > -1 || m.lastName.toLowerCase().indexOf(filterContactName.toLowerCase()) > -1 || m.emailAddress.toLowerCase().indexOf(filterContactName.toLowerCase()) > -1)
		}
		filtered = doSort(filtered, { sortField: 'firstName', isAsc: true, isNumber: false })
	  setContactsFiltered(filtered)
	}, [contacts, filterContactName])

	function handleGoogleCallbackResponse(response) {
		const userJwt = jwtDecode(response.credential)
		localStorage.setItem("googleToken", JSON.stringify(response.credential))
		if (response) props.login({
			isNewAccount: false,
			googleId: userJwt.sub,
			socialMediaToken: response.credential,
			firstName: userJwt.given_name,
			lastName: userJwt.family_name,
			emailAddress: userJwt.email,
			emailAddressConfirm: userJwt.email,
			username: userJwt.email,
			clave: '',
			claveConfirm: '',
			recaptchaResponse: '',
			inviteCode: props.inviteCodeShort,
		}, props.inviteCodeShort, false);
	}

	useEffect(() => {
		if (googleContactsOpen) {
			/* global google */
			google.accounts.id.initialize({
				client_id: "612044266489-9jesmmipkgj4c6fqjnso5muo12ikg3cm.apps.googleusercontent.com",
				callback: handleGoogleCallbackResponse,
			})

			google.accounts.id.renderButton(
				document.getElementById('signInDiv'),
				{ theme: "outline", size: "large" }
			)

			const getAndSetAccessTokenApi = (accessTokenApi) => {
				accessTokenApi.requestAccessToken()
			}

			const callForAllContacts = async (accessToken) => {
				const baseUrl = 'https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses,photos&pageSize=2000';
				let contactList = [];
				let nextPageToken = null;

				do {
					const response = await fetch(`${baseUrl}${nextPageToken ? `&pageToken=${nextPageToken}` : ''}`, {
						method: 'GET',
						headers: {
							'content-type': 'application/json',
							'Authorization': `Bearer ${accessToken}`,
						},
					});

					if(!response.ok) {
						throw new Error(`HTTP error! Status: ${response.status}`);
					}

					const data = await response.json();
					data && data.connections && data.connections.length > 0 && data.connections.forEach(m => {
						if (m.emailAddresses && m.emailAddresses.length > 0 && m.names && m.names.length > 0) {
							contactList.push({
								firstName: m.names[0].givenName || '',
								lastName: m.names[0].familyName || '',
								emailAddress: m.emailAddresses[0].value,
								photo: m.photos && m.photos.length > 0 ? m.photos[0].url : ''
							})
						}
					})
					nextPageToken = data.nextPageToken;					
				} while (nextPageToken)

				const emailAddresses = contactList && contactList.length > 0 && contactList.reduce((acc, m) => acc = acc && acc.length > 0 ? acc.concat(m.emailAddress) : [m.emailAddress], [])
				setContacts(contactList)
			}

			getAndSetAccessTokenApi(google.accounts.oauth2.initTokenClient({
				client_id: "612044266489-9jesmmipkgj4c6fqjnso5muo12ikg3cm.apps.googleusercontent.com",
				scope: "https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly",
				callback: (tokenResponse) => {
					if (tokenResponse && tokenResponse.access_token) {
						callForAllContacts(tokenResponse.access_token)
					}
				}
			}))
		}
	}, [googleContactsOpen])

	const isChecked = (contact) => {
		return contact === chosenContact
	}

	return (
		<div className={styles.container}>
      <div className={styles.mainDiv}>
				<div className={classes(styles.row, styles.moreBottom)}>
					<InputText
						size={"medium"}
						name={"filterContactName"}
						label={`Filter contact name or email address`}
						value={filterContactName}
						onChange={(event) => setFilterContactName(event.target.value)} />
				</div>
				<div className={isMobile ? styles.listBoxMobile : styles.listBox}>
					{contactsFiltered && contactsFiltered.length > 0 && contactsFiltered.map((w, i) =>
						<div key={i + 'summary'}>
							<div className={styles.rowCheckbox} >
								<Checkbox
									label={``}
									checked={isChecked(w)}
									onClick={() => {onClick(w); setChosenContact(w)}}/>
								<div onClick={() => onClick(w)}>
									<ContactSummaryLine 
										contact={w} 
										nameLength={150} 
										keyIndex={i + 'line'} 
										LighterBackground 
										showPhoto 
										showEmailAddress={false}
										showNumbersPenspringMember />
								</div>
							</div>
						</div>
					)}
					{!(contacts && contacts.length > 0) && 
						<div className={styles.noContacts}>There are not any contacts found.</div>
					}
				</div>
      </div>
		</div>
	);
}

export default EditorInviteGoogleContacts;

