import React from 'react';
import styles from './EditorSaveButton.module.css';
import ButtonWithIcon from '../ButtonWithIcon'
import moment from 'moment';
import classes from 'classnames'

function EditorSaveButton(props) {
	const {
		addClassName,
		changeCounts,
		keyIndex,
		label = "Save",
		saveByButtonPress,
		saveWorkSpaceTime,
	} = props

	return (
		<div className={classes(styles.column, addClassName)} key={keyIndex}>
			<ButtonWithIcon label={label} icon={changeCounts ? 'register' : 'checkmark0'} onClick={saveByButtonPress} submitSuccess={false} disabled={!changeCounts} smaller/>
			<div className={styles.timeSave}>
				{moment(saveWorkSpaceTime).format("h:mm")}
				<span className={styles.smallSeconds}>{moment(saveWorkSpaceTime).format(":ss")}</span>
			</div>
		</div>
	)
};

export default EditorSaveButton;