import React, { useState } from 'react';
import Checkbox from '../Checkbox'
import styles from './TextOptIn.module.css';
import classes from 'classnames';

const TextOptin = () => {
	const [optIn, setOptIn] = useState()



	return (
		<div>
			<Checkbox
				label={`Opt-in for system texts`}
				labelClass={styles.checkboxLabel}
				checked={optIn}
				onClick={() => setOptIn(!optIn)} />
			<div className={styles.smallText}>
				<div>By choosing to opt-in, you understand that you will receive mobile phone messages related to document editing between you and your friend editors and authors. Message frequency depends on how active you and your people are involved.</div>
				<div className={styles.topSpace}>Message and data rates may apply from your provider. Reply STOP to unsubscribe, HELP for assistance, or START to continue to receive messages again.</div>
				<div className={styles.topSpace}>By signing up and logging into Penspring you agree to our policies found in the links below:</div>
				<div className={styles.topSpace}><a href="https://penspring.com/service" target="_blank" className={styles.penspringLink}>Terms & Conditions</a></div>
				<div className={styles.topSpace}><a href="https://penspring.com/privacy" target="_blank" className={styles.penspringLink}>Privacy Policy</a></div>
			</div>
		</div>
	)
};


export default TextOptin