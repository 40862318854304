import React, {useState, useEffect} from 'react';
import styles from './FileFolderAddUpdateView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import OneFJefFooter from '../../components/OneFJefFooter';
import InputText from '../../components/InputText';
import SelectSingleDropDown from '../../components/SelectSingleDropDown';
import Button from '../../components/Button';
import {emptyGuid} from '../../utils/GuidValidate.js';

function FileFolderAddUpdateView(props) {
	const {workFolderId, workFolder, fileFolderList} = props;

	const [folderName, setFolderName] = useState('')
	const [parentWorkFolderId, setParentWorkFolderId] = useState('')
	const [isUpdated, setIsUpdated] = useState(false)
	const [errorFolderName, setErrorFolderName] = useState(false)


	useEffect(() => {
		const {workFolder} = props;
		if (!isUpdated && workFolder) {
			setFolderName(workFolder.folderName)
			setParentWorkFolderId(workFolder.parentWorkFolderId)
			setIsUpdated(true)
		}
	}, [])

	const processForm = () => {
      const {addOrUpdateFolder, personId, workFolderId, parentWorkFolderId, mineOrOthers}  = props;
      let hasError = false;

			if (!folderName) {
          hasError = true;
          setErrorFolderName('Please enter a folder name')
      }

      if (!hasError) {
          addOrUpdateFolder({personId, mineOrOthers, workFolderId, parentWorkFolderId, folderName: folderName })
      }
  }


  return (
    <div className={styles.container}>
        <div className={globalStyles.pageTitle}>
            {!workFolderId && workFolderId !== emptyGuid() ? `Edit Existing Folder` : 'Add New Folder'}
        </div>
				<div className={styles.marginLeft}>
						<div>
								<InputText
										size={"medium-long"}
										name={"folderName"}
										label={`Folder name`}
										inputClassName={styles.input}
										value={folderName || ''}
										onChange={event => setFolderName(event.target.value)}
										error={errorFolderName}/>
						</div>
						<div>
								<SelectSingleDropDown
										label={`Assign to a parent folder?`}
										value={parentWorkFolderId || ''}
										options={fileFolderList || []}
										error={''}
										height={`medium`}
										className={styles.singleDropDown}
										id={`parentListChoice`}
										onChange={event => setParentWorkFolderId(event.target.value)} />
						</div>
				</div>
				<div className={styles.rowRight}>
            <Button label={'Submit'} onClick={processForm}/>
        </div>
        <OneFJefFooter />
    </div>
  )
};

//    djsConfig={djsConfig} />

export default FileFolderAddUpdateView