import React from 'react';
import styles from './DateTimePicker.module.css';
import classes from 'classnames';
import Required from '../Required'

export default ({id, className = "", label, onChange, value='', error, minDate, required, whenFilled, labelClass}) => {
	return (
		<div className={styles.container}>
			<div className={styles.row}>
				{label && <span htmlFor={id} className={classes(styles.label, required ? styles.lower : '', labelClass)}>{label}</span>}
				<Required setIf={required} setWhen={whenFilled}/>
			</div>
			<div className={classes(className, styles.dateComponent)}>
				<input type="date"
				       value={(!value || value.length > 10 || value === 'Nan' || value === '') ? '' : value}
				       className={classes(styles.dateInput, (value ? '' : styles.placeholder), className)}
				       onChange={onChange}
				       min={minDate}
				/>
				{error && <div className={styles.alertMessage}>{error}</div>}
			</div>
		</div>
	)
};
