import React, { useEffect } from 'react';
import styles from './TextStyleSettingInputControls.module.css';
import SelectSingleDropDown from '../../components/SelectSingleDropDown'
import classes from 'classnames';

export default ({ 
	isOpen,
	settings,
	setSettings,
	handleSetting,
	fontOptions,
	fontSizes,
	lineHeights,
	textAlign,
	bottomSpace,
	textIndent,
	setStyleByCurrentElement,
	changeStyleEditArray,
	segments }) => {

		let newSettings = {}

		useEffect(() => {
			if (isOpen && setStyleByCurrentElement && changeStyleEditArray && changeStyleEditArray.length > 0 && segments && segments.length > 0) {
				const bodySegment = segments.filter(m => m.elementId === Number(1))[0]
				if (bodySegment) {
					setStyle(bodySegment.styleInline, 'fontFamily', 'font-family')
					setStyle(bodySegment.styleInline, 'fontSize', 'font-size')
				}
				const paragraphSegment = segments.filter(m => m.type === 'PARAGRAPH' && m.styleInline)[0]
				if (paragraphSegment) {
					setStyle(paragraphSegment.styleInline, 'lineHeight', 'line-height')
					setStyle(paragraphSegment.styleInline, 'textAlign', 'text-align')
					setStyle(paragraphSegment.styleInline, 'marginBottom', 'margin-bottom')
					setStyle(paragraphSegment.styleInline, 'textIndent', 'text-indent')
				}
				setSettings(newSettings)
			}
		}, [isOpen, setStyleByCurrentElement, changeStyleEditArray, segments])

		const setStyle = (styleInline, fieldName, styleName) => {
			if (styleInline && styleName && styleInline.indexOf(styleName) > -1) {
				let remainingText = styleInline.substring(styleInline.indexOf(styleName) + Number(styleName.length) + 1 * 1) // *1 makes it a number for sure rather than concatenating strings of numbers. PLus 1 to get it past the colon.
				if (remainingText.indexOf(';') > -1) {
					remainingText = remainingText.substring(0, remainingText.indexOf(';')).replace(/'/g, '').replace(/ characters/, '').replace(/px/, '').replace(/em/, '').trim()
					if (remainingText) newSettings = { ...newSettings, [fieldName]: remainingText}
				} 
			}
		}

	return (
		<div>
			<div className={classes(styles.row, styles.topSpace)}>
			<div className={styles.subLabel}>
				BODY
			</div>
			<div>
				<div className={styles.row}>
					<div className={styles.settingText}>
						Font-family
					</div>
					<div>
						<SelectSingleDropDown
							label={``}
							id={'fontFamily'}
							isFontName
							value={settings?.fontFamily || ''}
							optgroups={fontOptions || []}
							height={`medium`}
							onChange={handleSetting} />
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.settingText}>
						Font-size
					</div>
					<div>
						<SelectSingleDropDown
							label={``}
							id={'fontSize'}
							isFontSize
							value={settings?.fontSize || ''}
							options={fontSizes || []}
							height={`medium`}
							onChange={handleSetting} />
					</div>
				</div>
			</div>
		</div>
		<div className={classes(styles.row, styles.topSpace)}>
			<div className={styles.subLabel}>
				PARAGRAPH
			</div>
			<div>
				<div className={styles.row}>
					<div className={styles.settingText}>
						Line-height
					</div>
					<div>
						<SelectSingleDropDown
							label={``}
							id={'lineHeight'}
							value={settings?.lineHeight || ''}
							options={lineHeights || []}
							height={`medium`}
							onChange={handleSetting} />
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.settingText}>
						Text-align
					</div>
					<div>
						<SelectSingleDropDown
							label={``}
							id={'textAlign'}
							value={settings?.textAlign || ''}
							options={textAlign || []}
							height={`medium`}
							onChange={handleSetting} />
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.settingTextWider}>
						Paragraph space
					</div>
					<div>
						<SelectSingleDropDown
							label={``}
							id={'marginBottom'}
							noBlank
							value={settings?.marginBottom || ''}
							options={bottomSpace || []}
							height={`medium`}
							onChange={handleSetting} />
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.settingText}>
						Indentation
					</div>
					<div>
						<SelectSingleDropDown
							label={``}
							id={'textIndent'}
							noBlank
							value={settings?.textIndent || ''}
							options={textIndent || []}
							height={`medium`}
							onChange={handleSetting} />
					</div>
				</div>
			</div>
			</div>
		</div>
	)
};
