import React, {useState} from 'react'
import {useNavigate} from 'react-router'
import styles from './AccessReportView.module.css'
import globalStyles from '../../utils/globalStyles.module.css'
import EditTable from '../../components/EditTable'
import Checkbox from '../../components/Checkbox'
import Icon from '../../components/Icon'
import MessageModal from '../../components/MessageModal'
import Loading from '../../components/Loading'
import OneFJefFooter from '../../components/OneFJefFooter'
import classes from 'classnames'

function AccessReportView(props) {
  const {group, fetchingRecord} = props;
  const navigate = useNavigate()

  const [masterWorkId, setMasterWorkId] = useState()
  const [localHeadings, setLocalHeadings] = useState([])
  const [localData, setLocalData] = useState([])
  const [peerGroupId, setPeerGroupId] = useState('')
  const [peerGroup_workId, setPeerGroup_workId] = useState('')
  const [errorPeerGroup, setErrorPeerGroup] = useState('')
  const [groupChosen, setGroupChosen] = useState('')
  const [isShowingPeerGroupInfo, setIsShowingPeerGroupInfo] = useState(false)
  const [isShowingJumpToAssign, setIsShowingJumpToAssign] = useState(false)

  //ToDo: I don't know if prevProps is a thing. This comes from componentDidUpdate
  // useEffect((prevProps) => {
  //   const {reportTable} = props;
  //   if (prevProps.reportTable.data !== reportTable.data) {
  //     reportTable && reportTable.data && reportTable.data.length > 0 && setData();
  //   }
  // }, [])

  const onChangePeerGroupAssignment = (event) => {
    setPeerGroup_workId(event.target.value)
    setErrorPeerGroup('')
  }

  const onChangePeerGroup = (event) => {
    setPeerGroupId(event.target.value)
    setErrorPeerGroup('')
  }

  const processForm = (stayOrFinish) => {
    //Loop through the records to look for the difference created by handleCompareChanges
    //Send back the records with the directives to 'deleteAccess' or 'addAccess'
    const {personId, groupModifyWorkAccess, groupChosen} = props;
    let workAssign = [];
    let newAssign = {};
    let hasError = false;

    if (peerGroupId && peerGroupId !== '0' && !peerGroup_workId) {
      setErrorPeerGroup('Peer group is chosen but assignment not specified')
      hasError = true;
    }
    localData.forEach(row => row.forEach(cell => {
      if (cell.cellColor === 'green') {
        newAssign = {
          modify: 'addAccess',
          personId: cell.id,
          workId: cell.headingId,
        }
        workAssign = workAssign ? workAssign.concat(newAssign) : [newAssign];
      } else if (cell.cellColor === 'red') {
        newAssign = {
          modify: 'removeAccess',
          personId: cell.id,
          workId: cell.headingId,
        }
        workAssign = workAssign ? workAssign.concat(newAssign) : [newAssign];
      }
    }));

    if (!hasError && !!workAssign) {
      groupModifyWorkAccess(personId, workAssign, groupChosen, peerGroupId, peerGroup_workId);
      setPeerGroupId('')
      setPeerGroup_workId('')
    }
    handleCompareChanges(localData, true);

    if (!hasError && stayOrFinish === "FINISH") {
      navigate("/assignmentDashboard");
    }
  }

  const handleCompareChanges = (paramData, forceClear = false) => {
    //Using the original reportTable.data, look at the current localData to see what has changed.
    //Red: If the student had access before and now it is marked as 'no access'
    //Green: If the student is getting access now and didn't have it before.
    const {reportTable} = props;
    let cellColor = '';
    let newData = paramData.map(localRow => localRow.map(localCell => {
      cellColor = '';
      reportTable.data.forEach(origRow => {
        origRow.forEach(origCell => {
          if (!forceClear && localCell.id === origCell.id && localCell.headingId === origCell.headingId) {
            if (localCell.boolValue && origCell.value === "0") cellColor = 'green';
            if (!localCell.boolValue && origCell.value === "1") cellColor = 'red';
          }
        });
      });
      return {
        ...localCell,
        cellColor,
        id: localCell.id,
        headingId: localCell.headingId,
        value: cellColor === 'red' ? createLockedAccessIcon(localCell.id, localCell.headingId) : localCell.value,
      }
    }));
    setLocalData(newData)
  }

  const handleCellClick = (personId, workId) => {
    //Find the cell that matches the personId and workId (id and headingId, respectively)
    //  and toggle the value from whatever it currently is.
    let newData = localData.map(row => row.map((cell, index) =>
      cell.id === personId && cell.headingId === workId
        ? {
          ...cell,
          id: cell.id,
          headingId: cell.headingId,
          value: !cell.boolValue ? createCheckmarkIcon(cell.id, cell.headingId) : createNoAccessIcon(cell.id, cell.headingId),
          boolValue: !cell.boolValue,

        }
        : cell));
    setLocalData(newData)
    handleCompareChanges(newData);
  }

  const handleJumpToAssign = (masterWorkId, groupChosen) => {
    navigate("/groupWorkAssign/" + groupChosen + "/" + masterWorkId);
  }

  const handleColumnClick = (workId) => {
    //Force the jump to the GroupWorkAssign page instead where the peer group can be assigned.
    const {groupChosen} = props;
    handleJumpToAssignOpen();
    setMasterWorkId(workId)
    setGroupChosen(groupChosen)
  }

  const handleRowClick = (personId) => {
    //1. Find the row that matches the personId (id)
    //2. First, find out if any of the boxes are checked.  Uncheck them all if there is even just one.
    //   Otherwise, check them all if not one of them is set.
    let isOneSet = false;
    localData.forEach(row => row.forEach((cell, index) => {
      if (cell.id === personId && cell.boolValue && index >= 2) isOneSet = true;
    }));

    let newData = localData.map(row => row.map((cell, index) =>
      cell.id && cell.id === personId && index >= 2
        ? {
          ...cell,
          id: cell.id,
          headingId: cell.headingId,
          value: !isOneSet ? createCheckmarkIcon(cell.id, cell.headingId) : createNoAccessIcon(cell.id, cell.headingId),
          boolValue: !isOneSet,
          clickFunction: () => handleCellClick(cell.id, cell.headingId),
          cellColor: !isOneSet ? 'green' : 'red',
        }
        : cell));
    setLocalData(newData)
    handleCompareChanges(newData);
  }

  const createCheckmarkIcon = (personId, workId) => {
    return <a onClick={() => handleCellClick(personId, workId)}><Icon pathName={`checkmark`}/></a>;
  }

  const createNoAccessIcon = (workId, personId) => {
    return <a onClick={() => handleCellClick(personId, workId)}><Icon pathName={``}/></a>;
  }

  const createLockedAccessIcon = (workId, personId, fillColor) => {
    return <a onClick={() => handleCellClick(personId, workId)}><Icon pathName={`locked0`} fillColor={fillColor}
                                                                      premium={true}/></a>;
  }

  const setData = () => {
    const {reportTable, group} = props;
    //Provide the handleCellClick for the cells
    //Include the InputText for the first row across which will toggle that assignment or document to be all on for everyone or all off.
    //Include the InputText for the second column after the names which will toggle the controls to be all on or all off for a person.
    let colIndex = 0;
    let firstRow = reportTable.headings.reduce((acc, m) => {
      if (colIndex === 0) {
        acc = [{
          ...m,
          value: group.groupTypeName === 'FACILITATORLEARNER' ? 'LEARNERS' : 'MEMBERS'
        }];
      } else {
        if (colIndex === 1) acc = acc.concat({id: 0, value: ''});
        acc = acc.concat({
          ...m,
          value: <Checkbox className={styles.checkbox} onClick={() => handleColumnClick(m.headingId)}/>,
        });
      }
      colIndex++;
      return acc;
    }, []);

    let newData = reportTable.data.map(row => {
      colIndex = 0;
      return row && row.length > 0 && row.reduce((acc, cell) => {
        //When it is the second column, inject a blank column.
        if (colIndex === 0) {
          acc = [cell]; //Take the first cell without any changes since it is the name of the student or group member.
        } else {
          if (colIndex === 1) acc = acc.concat({id: 0, value: <Checkbox onClick={() => handleRowClick(cell.id)}/>});

          acc = acc.concat({
            ...cell,
            id: cell.id,
            headingId: cell.headingId,
            value: cell.value === "1" && !cell.locked
              ? createCheckmarkIcon(cell.id, cell.headingId)
              : cell.locked
                ? createLockedAccessIcon(cell.id, cell.headingId, 'maroon')
                : createNoAccessIcon(cell.id, cell.headingId),
            boolValue: cell.value === "1",
            clickFunction: () => handleCellClick(cell.id, cell.headingId)
          });
        }
        colIndex++;
        return acc;
      }, []);
    });

    colIndex = 0;
    let newHeadings = reportTable.headings.reduce((acc, heading) => {
      if (colIndex === 1) acc = acc.concat({id: 0, label: ''}); //This is the column for the checkboxes in the second column for the editors/members of the group.
      let newHeading = {
        ...heading,
        headingId: heading.headingId,
        clickFunction: () => handleColumnClick(heading.headingId)
      };
      acc = acc ? acc.concat(newHeading) : [newHeading];
      colIndex++;
      return acc;
    }, []);
    //Add the first row of data that is the row of checkboxes to use to 'set all' or 'clear all' of the records of a singld column with a single click.
    newData.unshift(firstRow);
    setLocalData(newData)
    setLocalHeadings(newHeadings)
  }

  const handlePathLink = (pathLink) => {
    pathLink && navigate(pathLink);
  }

  const handlePeerGroupInfoClose = () => setIsShowingPeerGroupInfo(false)
  const handlePeerGroupInfoOpen = () => setIsShowingPeerGroupInfo(true)
  const handleJumpToAssignClose = () => setIsShowingJumpToAssign(false)
  const handleJumpToAssignOpen = () => setIsShowingJumpToAssign(true)


  return (
    <div className={styles.container}>
      <div className={globalStyles.pageTitle}>
        {'Access Report'}
      </div>
      <div className={styles.subTitle}>
        {`For group:`} {group && group.groupName}
      </div>
      <hr/>
      <Loading loadingText={`Loading`} isLoading={fetchingRecord && fetchingRecord.accessReport}/>
      {fetchingRecord && !fetchingRecord.accessReport && localData &&
        <div className={classes(styles.row)}>
          <button className={styles.button} onClick={(event) => processForm("STAY", event)}>
            Save & Stay
          </button>
          <button className={styles.button} onClick={(event) => processForm("FINISH", event)}>
            Save & Finish
          </button>
        </div>
      }
      {fetchingRecord && !fetchingRecord.accessReport &&
        <EditTable labelClass={styles.tableLabelClass} headings={localHeadings}
                   data={localData} noCount={true} firstColumnClass={styles.firstColumnClass}
                   sendToReport={handlePathLink}/>
      }
      <br/>
      <br/>
      <OneFJefFooter/>
      <MessageModal show={isShowingPeerGroupInfo} handleClose={handlePeerGroupInfoClose}
                    heading={`How to use peer groups with an assignment`} showPeerGroupInfo={true}
                    explain={`Peer groups will allow members of each sub group to view and edit each other's assignments.<br/><br/>
                  If you have not yet created a peer group for this group, choose 'Add a new peer group.'
                  Once you have at least one peer group and you have returned to this access report, click on an assignment in the report heading.
                  You will be taken to a page to decide which peer group to apply to the chosen assignment.`}
                    onClick={handlePeerGroupInfoClose}/>
      <MessageModal show={isShowingJumpToAssign} handleClose={handleJumpToAssignClose}
                    heading={`I'm taking you to the single assignment page`} showPeerGroupInfo={true}
                    explain={`We found that it is better to grant access to an assignment to everyone on a different page.  In that page you can choose to split up your class into peer groups, but that is just an option. I'm going to take you there now, is that okay?`}
                    isConfirmType={true}
                    onClick={() => handleJumpToAssign(masterWorkId, groupChosen)}/>
    </div>
  );
}

export default AccessReportView