import {useEffect} from 'react'
import { useNavigate } from 'react-router'
import {connect} from 'react-redux'
import * as actionFileTreeSubContents from '../actions/work-file-tree-sub-contents.js'
import { selectMe, selectWorkFileTree } from '../store.js'

const mapStateToProps = (state, props) => {
	//1. Filter the works list, if any filters are chosen.
	//2. Loop through the work records, marking the currently chosen Work (so that it might be designated with some background color in a list later).
	let me = selectMe(state)
	return {
		personId: me.personId,
		fileTreeExplorer: selectWorkFileTree(state),
	}
}

const bindActionsToDispatch = dispatch => ({
	getMyWorks: (personId, includeOriginatingEditor) => dispatch(actionFileTreeSubContents.getMyWorks(personId, includeOriginatingEditor)),
})

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
)

function Container(props) {
	const { personId, getMyWorks, fileTreeExplorer } = props
	const navigate = useNavigate()

	useEffect(() => {
		getMyWorks(personId)
	}, [personId])

	useEffect(() => {
		if (fileTreeExplorer?.length > 0) {
			const fileCount = countFiles(fileTreeExplorer)
			if (fileCount > 0) {
				navigate('/myWorks')
			} else {
				navigate('/workAddNew')
			}
		}
	}, [fileTreeExplorer])

	function countFiles(folders) {
		let count = 0
		folders.map(folder => {
			count += folder?.files?.length;
			folder.subFolders?.length > 0 && folder.subFolders.forEach(subFolder => {
				count += countFiles(subFolder);
			});
		})
		return count;
	}

	return null
}

export default storeConnector(Container)
