import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom'
import WorkEditReportView from '../views/WorkEditReportView'
import { connect } from 'react-redux'
import * as actionContributorReport from '../actions/contributor-report'
import debounce from 'lodash.debounce';
import {guidEmpty} from '../utils/GuidValidate'

import { selectMe, selectWorkEditReport } from '../store.js'

// takes values from the redux store and maps them to props
const mapStateToProps = (state, props) => {
  let me = selectMe(state)

  const headings = [{
    id: "editors",
    label: 'Editors',
    tightText: true,
  },
    {
      id: "edits",
      label: 'Edits Finished',
      verticalText: true,
    },
    {
      id: "pendingEdits",
      label: 'Pending Edits',
      verticalText: true,
    },
    {
      id: "acceptedEdits",
      label: 'Accepted',
      verticalText: true,
    },
    {
      id: "nonAcceptedEdits",
      label: 'Not Accepted',
      verticalText: true,
    },
    {
      id: "upVotes",
      label: 'Up Vote',
      verticalText: true,
    },
    {
      id: "downVotes",
      label: 'Down Vote',
      verticalText: true,
    },
    {
      id: "trollVotes",
      label: 'Troll Vote',
      verticalText: true,
    },
    {
      id: "votesCast",
      label: 'Votes Cast',
      verticalText: true,
    }]

    return {
      personId: me.personId,
      headings,
      workEditReports: selectWorkEditReport(state),
    }
}

// binds the result of action creators to redux dispatch, wrapped in callable functions
const bindActionsToDispatch = dispatch => ({
  getWorkEditReport: (personId, workSearch, id) => dispatch(actionContributorReport.getWorkEditReport(personId, workSearch, id)),
})

const mergeAllProps = (store, actions) => ({
    ...store, ...actions,
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
  mergeAllProps
)

function Container(props) {
  const { personId, getWorkEditReport } = props
  const { workSearch, id } = useParams()

  useEffect(() => {
    if (personId && personId !== guidEmpty) {
      //debounce(() => getWorkEditReport(personId, workSearch, id), 300)
      getWorkEditReport(personId, workSearch, id)
      //isFirstTime = true
    }
  },[personId])

  return <WorkEditReportView {...props} workSearch={workSearch} id={id}/>
}

export default storeConnector(Container)
