import React, {useState} from 'react';
import styles from './OpenCommunityFilter.module.css';
import classes from 'classnames';
import DateTimePicker from '../DateTimePicker';
import MultiSelect from '../MultiSelect';
import SelectSingleDropDown from '../SelectSingleDropDown';
import InputText from '../InputText';
import Icon from '../Icon';

let orderByOptions = [
    {
        label: "Due date",
        id: "chapterDueDate"
    },
    {
        label: "Title",
        id: "title"
    },
    {
        label: "Modified Most Recently",
        id: "lastUpdate"
    },
    {
        label: "Project",
        id: "project"
    },
];
let orderSortOptions = [
    {
        label: "Ascending",
        id: "asc"
    },
    {
        label: "Descending",
        id: "desc"
    },
];


//The open community filter has the capacity to provide saved and named versions of a search to be used in the future.
//Since the openCommunityFilter record is saved persistently with any movement, that gives us the advantage to update an existing saved searchText
//  or to name the current search.  However, that means that a "scratch" record needs to be kept at all times.  We'll keep track of that
//  with a flag called ScratchFlag. That record will probably never have a name associated with it and it won't be included in the savedSearch
//  list.  When a record is chosen, however, it will be overwritten so that that Scratch record can be used to update an existing savedSearch
//  but keep that original savedSearch in tact until the user wants to update criteria, rename it or even delete it.
//The savedSearch list will be kept track of locally.
//There is the option for one of the savedSearch-es to be the default search when the page comes up for the first time.

function OpenCommunityFilter(props) {
    const {personId, openCommunityFilter, clearFilters, savedSearchOptions, updateFilterByField, editSeverityOptions,
        wordCountOptions, genreOptions, languageOptions} = props;

    const [savedSearchName, setSavedSearchName] = useState('')
    const [errorSearchName, setErrorSearchName] = useState('')
    const [checkedDefault, setCheckedDefault] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [savedFilterIdCurrent, setSavedFilterIdCurrent] = useState(0)  //We only use the savedFilterIdCurrent in the componentDidUpdate function when this id doesn't match the savedFilterIdCurrent being sent in.  //  so that we can update the multiSelect Ids without going into an infinite loop of updating
    const [selectedNativeLanguageIds, setSelectedNativeLanguageIds] = useState([])
    const [selectedTranslateLanguageIds, setSelectedTranslateLanguageIds] = useState([])
    const [selectedGenreIds, setSelectedGenreIds] = useState([])
    const [selectedEditSeverityIds, setSelectedEditSeverityIds] = useState([])

  const handleClearFilter = () => {
      setSelectedNativeLanguageIds([])
      setSelectedTranslateLanguageIds([])
      setSelectedGenreIds([])
      setSelectedEditSeverityIds([])
      clearFilters(openCommunityFilter.personId);
  }

    const avoidDupicateSearchName = () => {
      let noDuplicate = true;
      if (!savedSearchName) return true;

      savedSearchOptions && savedSearchOptions.length > 0 && savedSearchOptions.forEach(m => {
          if (m.label.toLowerCase() === savedSearchName.toLowerCase()) {
              noDuplicate = false;
              setErrorSearchName('Duplicate name.')
          }
      })
      return noDuplicate;
     }

  const handleSearchTitleEnterKey = (event) => {
      event.key === "Enter" && handleSearchTextSubmit();
  }

    const handleSaveSearchEnterKey = (event) => {
      if (event.key === "Enter" && avoidDupicateSearchName()) {
          handleSearchNameSubmit();
      }
  }

  //In order to avoid very slow performance of the multiSelect component, it was essential to keep a local state on the selected Ids,
  //    and then (with the code placed in the MultiSelect's dropDown control when it collapses ), then we save off the accumulated
  //    selected Ids.
    const handleNativeLanguageSelected = (selectedNativeLanguageIds) => {
      setSelectedNativeLanguageIds(selectedNativeLanguageIds)
    }

    const handleTranslateLanguageSelected = (selectedTranslateLanguageIds) => {
      setSelectedTranslateLanguageIds(selectedTranslateLanguageIds)
    }

    const handleGenreSelected = (selectedGenreIds) => {
      setSelectedGenreIds(selectedGenreIds)
    }

    const handleEditSeveritySelected = (selectedEditSeverityIds) => {
      setSelectedEditSeverityIds(selectedEditSeverityIds)
    }

  const getJustCollapsed_nativeLanguage = () => {
      updateFilterByField(personId, "nativeLanguageIds", selectedNativeLanguageIds);
  }

  const getJustCollapsed_translateLanguage = () => {
      updateFilterByField(personId, "translateLanguageIds", selectedTranslateLanguageIds);
  }

  const getJustCollapsed_genre = () => {
      updateFilterByField(personId, "genreIds", selectedGenreIds);
  }

  const getJustCollapsed_editSeverity = () => {
      updateFilterByField(personId, "editSeverityIds", selectedEditSeverityIds);
  }

  const componentDidUpdate = () => {
      document.getElementById('searchText').value = props.openCommunityFilter.searchText
      if (savedFilterIdCurrent !== openCommunityFilter.savedFilterIdCurrent) {
          setSavedFilterIdCurrent(openCommunityFilter.savedFilterIdCurrent)
          setSelectedNativeLanguageIds(openCommunityFilter.nativeLanguageIds)
          setSelectedTranslateLanguageIds(openCommunityFilter.translateLanguageIds)
          setSelectedGenreIds(openCommunityFilter.genreIds)
          setSelectedEditSeverityIds(openCommunityFilter.editSeverityIds)
      }
  }

  const handleSearchNameChange = (event) => {
      setSavedSearchName(event.target.value)
      setErrorSearchName('')
  }

  const handleSearchTextChange = (event) => {
      setSearchText(document.getElementById('searchText').value )
  }

  const handleSearchNameSubmit = () => {
      const {saveNewSavedSearch, personId} = props;

      if (savedSearchName && avoidDupicateSearchName()) {
          saveNewSavedSearch(personId, savedSearchName)
          setSavedSearchName('' )
      } else if (!savedSearchName) {
          setErrorSearchName('Search name is missing.')
      }
  }

  const handleSearchTextSubmit = () => {
      updateFilterByField(personId, "searchText", searchText)
  }

  const nativeLanguageValueRenderer = (selected, options) => {
      if (selected.length === 0) {
          return "Select native language..."
      }

      if (selected.length === options.length) {
          return "All native languages are selected"
      }

      if (selected.length < 5) {
          let comma = ""
          let languageNames = ""
          selected && selected.length > 0 && selected.forEach(value => {
              languageNames += comma + options.filter(o => o.value === value)[0].label
              comma = ", "
          })
          languageNames = languageNames === 'en' ? 'English' : languageNames
          if (selected.length === 1) {
              return `Native language:  ${languageNames}`
          } else {
              return `Native languages:  ${languageNames}`
          }
      } else {
          return `Native language:  ${selected.length} of ${options.length}`
      }
  }

  const translateLanguageValueRenderer = (selected, options) => {
      if (selected.length === 0) {
          return "Select language to translate..."
      }

      if (selected.length === options.length) {
          return "All languages to translate are selected";
      }

      if (selected.length < 5) {
          let comma = "";
          let languageNames = "";
          selected && selected.length > 0 && selected.forEach(value => {
              languageNames += comma + options.filter(o => o.value === value)[0].label;
              comma = ", ";
          });
          languageNames = languageNames === 'en' ? 'English' : languageNames;
          if (selected.length === 1) {
              return `Language to translate:  ${languageNames}`;
          } else {
              return `Languages to translate:  ${languageNames}`;
          }
      } else {
          return `Languages to translate:  ${selected.length} of ${options.length}`;
      }
  }

  const genreValueRenderer = (selected, options) => {
      if (selected.length === 0) {
          return "Select genres...";
      }

      if (selected.length === options.length) {
          return "All genres are selected";
      }

      return `Genres:  ${selected.length} of ${options.length}`;
  }

  const editSeverityValueRenderer = (selected, options) => {
      if (selected.length === 0) {
          return "Select edit intensity...";
      }

      if (selected.length === options.length) {
          return "All edit intensities are selected";
      }

      let comma = "";
      let editSeverityNames = "";
      selected && selected.length > 0 && selected.forEach(value => {
          editSeverityNames += comma + options.filter(o => o.value === value)[0].label;
          comma = ", ";
      });
      if (selected.length === 1) {
          return `Edit severity:  ${editSeverityNames}`;
      } else {
          return `Edit severities:  ${editSeverityNames}`;
      }
  }


    return (
        <div className={styles.container}>
            <div>
                <div className={styles.row}>
                    <span className={styles.textSave}>Save search</span>
                    <InputText
                        size={"medium"}
                        name={"name"}
                        value={savedSearchName ? savedSearchName : ''}
                        onChange={handleSearchNameChange}
                        onEnterKey={handleSaveSearchEnterKey}
                        error={errorSearchName} />
                    <a onClick={handleSearchNameSubmit} className={styles.linkStyle}>
                        <Icon pathName={`plus`} className={styles.image}/>
                    </a>
                    <a onClick={handleClearFilter} className={classes(styles.linkStyle, styles.marginLeft)}>
                        <Icon pathName={`document_refresh`} className={styles.image}/>
                    </a>
                </div>
                <hr/>
                <div className={styles.multiSelect}>
                    <MultiSelect
                        options={languageOptions}
                        onSelectedChanged={handleNativeLanguageSelected}
                        getJustCollapsed={getJustCollapsed_nativeLanguage}
                        valueRenderer={nativeLanguageValueRenderer}
                        selected={selectedNativeLanguageIds}/>
                </div>
                <div className={styles.multiSelect}>
                    <MultiSelect
                        options={languageOptions}
                        onSelectedChanged={handleTranslateLanguageSelected}
                        getJustCollapsed={getJustCollapsed_translateLanguage}
                        valueRenderer={translateLanguageValueRenderer}
                        selected={selectedTranslateLanguageIds}/>
                </div>
                <div className={styles.multiSelect}>
                    <MultiSelect
                        options={genreOptions}
                        onSelectedChanged={handleGenreSelected}
                        getJustCollapsed={getJustCollapsed_genre}
                        valueRenderer={genreValueRenderer}
                        selected={selectedGenreIds}/>
                </div>
                <hr />
                <div className={styles.row}>
                    <span className={styles.text}>Due date</span>
                    <div>
                        <div className={styles.dateRow}>
                            <span className={styles.text}>from:</span>
                            <DateTimePicker id={`dueDateFrom`} value={openCommunityFilter.dueDateFrom}
                                onChange={(event) => updateFilterByField(openCommunityFilter.personId, "dueDateFrom", event.target.value)}/>
                        </div>
                        <div className={styles.dateRow}>
                            <span className={styles.text}>to:</span>
                            <DateTimePicker id={`dueDateTo`} value={openCommunityFilter.dueDateTo} minDate={openCommunityFilter.dueDateFrom ? openCommunityFilter.dueDateFrom : ''}
                                onChange={(event) => updateFilterByField(openCommunityFilter.personId, "dueDateTo", event.target.value)}/>
                        </div>
                    </div>
                </div>
                <hr className={styles.divider}/>
                <div className={styles.row}>
                    <span className={styles.text}>Word Count</span>
                    <div className={styles.row}>
                        <div className={styles.dateRow}>
                            <SelectSingleDropDown
                                value={openCommunityFilter.wordCountFrom ? openCommunityFilter.wordCountFrom : ''}
                                options={wordCountOptions}
                                error={''}
                                label={'From'}
                                height={`short`}
                                noBlank={true}
                                labelClass={styles.text}
                                selectClass={styles.selectListClass}
                                onChange={(event) => updateFilterByField(openCommunityFilter.personId, "wordCountFrom", event.target.value)} />
                        </div>
                        <div className={classes(styles.dateRow, styles.leftMargin)}>
                            <SelectSingleDropDown
                                value={openCommunityFilter.wordCountTo ? openCommunityFilter.wordCountTo : ''}
                                options={wordCountOptions}
                                error={''}
                                label={'To'}
                                height={`short`}
                                noBlank={true}
                                labelClass={styles.text}
                                selectClass={styles.selectListClass}
                                onChange={(event) => updateFilterByField(openCommunityFilter.personId, "wordCountTo", event.target.value)} />
                        </div>
                    </div>
                </div>
                <hr className={styles.divider}/>
                <div className={styles.row}>
                    <span className={styles.textSave}>Search title</span>
                    <InputText
                        size={"medium"}
                        name={"searchText"}
                        value={searchText ? searchText : ''}
                        onChange={handleSearchTextChange}
                        inputClassName={styles.inputClassName}
                        onEnterKey={handleSearchTitleEnterKey}
                        labelClass={styles.labelClass} />
                    <a onClick={handleSearchTextSubmit} className={styles.linkStyle}>
                        <Icon pathName={`checkmark`} className={styles.image}/>
                    </a>
                </div>
                <hr className={styles.divider}/>
                <div className={styles.multiSelect}>
                    <MultiSelect
                        options={editSeverityOptions}
                        onSelectedChanged={handleEditSeveritySelected}
                        getJustCollapsed={getJustCollapsed_editSeverity}
                        valueRenderer={editSeverityValueRenderer}
                        selected={selectedEditSeverityIds}/>
                </div>
                <hr className={styles.divider}/>
                <div className={styles.row}>
                    <div>
                        <SelectSingleDropDown
                            value={openCommunityFilter.orderByChosen ? openCommunityFilter.orderByChosen : ''}
                            options={orderByOptions}
                            labe={`Order by`}
                            error={''}
                            height={`medium`}
                            noBlank={true}
                            className={styles.singleDropDown}
                            onChange={(event) => updateFilterByField(openCommunityFilter.personId, "orderByChosen", event.target.value)} />
                    </div>
                    <div>
                        <SelectSingleDropDown
                            value={openCommunityFilter.orderSortChosen ? openCommunityFilter.orderSortChosen : ''}
                            options={orderSortOptions}
                            labe={`Sort direction`}
                            error={''}
                            noBlank={true}
                            height={`medium`}
                            className={styles.singleDropDown}
                            onChange={(event) => updateFilterByField(openCommunityFilter.personId, "orderSortChosen", event.target.value)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpenCommunityFilter