import React, { useState, useEffect } from 'react'
import styles from './ToggleBoard.module.css'
import ToggleSwitch from '../ToggleSwitch'
import PushButton from '../PushButton'
import OneFJefFooter from '../OneFJefFooter'

import Divider from "../../assets/ToggleSwitch/Divider.png"
import TextPanelMove from "../../assets/ToggleSwitch/TextPanelMove.png"
import MoveStart from "../../assets/Inline Icons/move-start.svg"
import MoveEnd from "../../assets/Inline Icons/move-end.svg"
import MoveTarget from "../../assets/Inline Icons/move-target.svg"
import ChangeStyleStart from "../../assets/Inline Icons/change-style-question-start.png"
import ChangeStyleEnd from "../../assets/Inline Icons/change-style-question-end.png"
import ConvertAddListStart from "../../assets/Inline Icons/convert-add-list-question-start.png"
import ConvertAddListEnd from "../../assets/Inline Icons/convert-add-list-question-end.png"

import classes from 'classnames'

import OnKeepCommentOn from "../../assets/ToggleSwitch/OnToggle_KeepCommentOn.png";
import OffKeepCommentOn from "../../assets/ToggleSwitch/OffToggle_KeepCommentOn.png";
import OnShowTrackChanges from "../../assets/ToggleSwitch/OnToggle_ShowTrackChanges.png";
import OffShowTrackChanges from "../../assets/ToggleSwitch/OffToggle_ShowTrackChanges.png";
import OnShowEditorsFullText from "../../assets/ToggleSwitch/OnToggle_ShowEditorsFullText.png";
import OffShowEditorsFullText from "../../assets/ToggleSwitch/OffToggle_ShowEditorsFullText.png";
// import OnDisableEditorPopup from "../../assets/ToggleSwitch/OnToggle_DisableEditorPopup.png";
// import OffDisableEditorPopup from "../../assets/ToggleSwitch/OffToggle_DisableEditorPopup.png";
import OnMoveTranslationToEditor from "../../assets/ToggleSwitch/OnToggle_MoveTranslationToEditor.png";
import OffMoveTranslationToEditor from "../../assets/ToggleSwitch/OffToggle_MoveTranslationToEditor.png";
import OnGoToNextSentence from "../../assets/ToggleSwitch/OnToggle_GoToNextSentence.png";
import OffGoToNextSentence from "../../assets/ToggleSwitch/OffToggle_GoToNextSentence.png";
import OnShowEditIcons from "../../assets/ToggleSwitch/OnToggle_ShowEditIcons.png";
import OffShowEditIcons from "../../assets/ToggleSwitch/OffToggle_ShowEditIcons.png";

import OnAddParagraphBreak from "../../assets/Buttons/PushButtonOn_AddParagraphBreak.png";
import OffAddParagraphBreak from "../../assets/Buttons/PushButtonOff_AddParagraphBreak.png";
import OnDeleteParagraphBreak from "../../assets/Buttons/PushButtonOn_DeleteParagraphBreak.png";
import OffDeleteParagraphBreak from "../../assets/Buttons/PushButtonOff_DeleteParagraphBreak.png";
import OnAddParagraphSentence from "../../assets/Buttons/PushButtonOn_AddParagraphSentence.png";
import OffAddParagraphSentence from "../../assets/Buttons/PushButtonOff_AddParagraphSentence.png";
import OnAddSentence from "../../assets/Buttons/PushButtonOn_AddSentence.png";
import OffAddSentence from "../../assets/Buttons/PushButtonOff_AddSentence.png";
import OnDeleteSentence from "../../assets/Buttons/PushButtonOn_DeleteSentence.png";
import OffDeleteSentence from "../../assets/Buttons/PushButtonOff_DeleteSentence.png";
import OnAddListItem from "../../assets/Buttons/PushButtonOn_AddListItem.png";
import OffAddListItem from "../../assets/Buttons/PushButtonOff_AddListItem.png";
import OnAddList from "../../assets/Buttons/PushButtonOn_AddList.png";
import OffAddList from "../../assets/Buttons/PushButtonOff_AddList.png";
import OnChangeList from "../../assets/Buttons/PushButtonOn_ChangeList.png";
import OffChangeList from "../../assets/Buttons/PushButtonOff_ChangeList.png";
import DisabledChangeList from "../../assets/Buttons/PushButtonDisabled_ChangeList.png";
import OnDeleteListItem from "../../assets/Buttons/PushButtonOn_DeleteListItem.png";
import OffDeleteListItem from "../../assets/Buttons/PushButtonOff_DeleteListItem.png";
import OnMoveSentences from "../../assets/Buttons/PushButtonOn_MoveSentences.png";
import OffMoveSentences from "../../assets/Buttons/PushButtonOff_MoveSentences.png";
import OnReorderListItems from "../../assets/Buttons/PushButtonOn_ReorderListItems.png";
import OffReorderListItems from "../../assets/Buttons/PushButtonOff_ReorderListItems.png";
import OnChangeStyle from "../../assets/Buttons/PushButtonOn_ChangeStyle.png";
import OffChangeStyle from "../../assets/Buttons/PushButtonOff_ChangeStyle.png";

function ToggleBoard(props) {
  const { 
    addList, 
    addListItem, 
    addParagraphBreak,
    addParagraphSentence, 
    addSentence, 
    changeList, 
    changeStyle, 
    changeStyleSequence = 1, 
    convertAddListSequence, 
    currentElement, 
    deleteListItem, 
    deleteParagraphBreak, 
    deleteSentence, 
    editTrackChanges, 
    goToNextSentence, 
    hasListStructure,
    isAuthor,
    isTranslation, 
    keepCommentOn,
    moveSentences, 
    moveSequence = 1, 
    moveTranslationToEditor, 
    reorderListItems, 
    setAddList, 
    setAddListItem, 
    setAddParagraphBreak, 
    setAddParagraphSentence, 
    setAddSentence, 
    setChangeList, 
    setChangeStyle, 
    setDeleteListItem, 
    setDeleteParagraphBreak, 
    setDeleteSentence, 
    setEditTrackChanges, 
    setGoToNextSentence, 
    setKeepCommentOn, 
    setMoveSentences, 
    setMoveTranslationToEditor, 
    setReorderListItems, 
    setShowClickInstruction, 
    setShowEditIcons,
    setShowEditorFullText, 
    showEditIcons, 
    showEditorFullText,
  } = props

  const [isInList, setIsInList] = useState()

  useEffect(() => {
    if (currentElement) {
      setIsInList(currentElement.nodeName === 'LI' 
            || currentElement.nodeName === 'OL' 
            || currentElement.nodeName === 'UL' 
            || currentElement.parentElement.nodeName === 'LI' 
            || currentElement.parentElement.nodeName === 'OL' 
            || currentElement.parentElement.nodeName === 'UL')
    }
  }, [currentElement])

  return (
    <div className={styles.footerPanel}>
      <div className={isAuthor ? styles.toggleSwitchesAuthor : styles.toggleSwitchesEditor}>
        <div className={classes(styles.rowToggle, (moveSentences || changeStyle) && !isAuthor ? styles.hide : styles.show)} >
          <img src={Divider} alt={'toggle'} className={styles.divider}/>
        </div>
        <div className={(moveSentences || changeStyle) && !isAuthor ? styles.hide : styles.show}>
          <ToggleSwitch value={keepCommentOn} setValue={setKeepCommentOn}  onImage={OnKeepCommentOn} offImage={OffKeepCommentOn}
                        title={`On the side panel, keep the comment edit control open.`} />
        </div>
        {!isTranslation &&
          <div className={(moveSentences || changeStyle) && !isAuthor ? styles.hide : styles.show}>
            <ToggleSwitch value={editTrackChanges} setValue={setEditTrackChanges}  onImage={OnShowTrackChanges} offImage={OffShowTrackChanges}
                          title={`On the side panel, the text changes will show red and green indicators of deleted and new text.`} />
          </div>
        }
        {!isAuthor &&
          <div className={(moveSentences || changeStyle) && !isAuthor ? styles.hide : styles.show}>
            <ToggleSwitch value={showEditIcons} setValue={setShowEditIcons} onImage={OnShowEditIcons} offImage={OffShowEditIcons}
              title={`Show the edit icons for the editor.`} />
          </div>
        }
        <ToggleSwitch value={showEditorFullText} setValue={setShowEditorFullText} onImage={OnShowEditorsFullText} offImage={OffShowEditorsFullText}
                      title={`Display the full text of other editors on the right side of the main editor.`}/>
        {isTranslation &&
          <div className={moveSentences || changeStyle ? styles.hide : styles.show}>
            <ToggleSwitch value={moveTranslationToEditor} setValue={setMoveTranslationToEditor} onImage={OnMoveTranslationToEditor} offImage={OffMoveTranslationToEditor} />
          </div>
        }
        {isTranslation &&
          <div className={moveSentences || changeStyle ? styles.hide : styles.show}>
            <ToggleSwitch value={goToNextSentence} setValue={setGoToNextSentence} onImage={OnGoToNextSentence} offImage={OffGoToNextSentence}
                          title={`After submitting a translation, call up the next sentence automatically.`}/>
          </div>
        }
        <div className={classes(styles.rowToggle, moveSentences || changeStyle ? styles.hide : styles.show)} >
          <img src={Divider} alt={'toggle'} className={styles.divider}/>
        </div>
        {/*<ToggleSwitch label={<div>Go To<br/>Next<br/>Sentence</div>} value={goToNextSentence} setValue={setGoToNextSentence} wideText={true} />*/}
        {!isTranslation &&
          <PushButton value={changeStyle} setValue={setChangeStyle} onImage={OnChangeStyle} offImage={OffChangeStyle}
            setShowClickInstruction={setShowClickInstruction} title={`Change text style - Ctrl+Alt+S`} />
        }
        {!isTranslation &&
          <div className={changeStyle ? styles.showPanel : styles.hidePanel}>
            <img src={TextPanelMove} alt={'toggle'} className={styles.textPanelMove} />
            <div className={styles.moveText}>
              <div className={changeStyleSequence <= 1 ? styles.moveRow : styles.moveRowOpacity}>
                <img src={ChangeStyleStart} height={14} className={styles.image} />
                <div className={styles.instructionText}>1. Click on the beginning sentence.</div>
              </div>
              <div className={changeStyleSequence <= 2 ? styles.moveRow : styles.moveRowOpacity}>
                <img src={ChangeStyleEnd} height={14} className={styles.imageSmaller} />
                <div className={styles.instructionText}>2. Choose the last sentence (or its icon).</div>
              </div>
              <div className={changeStyleSequence <= 3 ? styles.moveRow : styles.moveRowOpacity}>
                <div className={classes(styles.missingIcon, styles.instructionText)}>3. A pop-up will give you style options.</div>
              </div>
            </div>
          </div>
        }
        {!isTranslation && (!isAuthor || false) &&
          <PushButton value={deleteParagraphBreak} setValue={setDeleteParagraphBreak} onImage={OnDeleteParagraphBreak} offImage={OffDeleteParagraphBreak}
                      setShowClickInstruction={setShowClickInstruction} title={`Delete a paragraph break - Ctrl+Alt+B`}/>
        }
        {!isTranslation && (!isAuthor || false) &&
          <PushButton value={addParagraphBreak} setValue={setAddParagraphBreak} onImage={OnAddParagraphBreak} offImage={OffAddParagraphBreak}
            setShowClickInstruction={setShowClickInstruction} title={`Add a new paragraph break - Ctrl+Alt+A`} />
        }
        {!isTranslation && (!isAuthor || false) &&
          <PushButton value={addParagraphSentence} setValue={setAddParagraphSentence} onImage={OnAddParagraphSentence} offImage={OffAddParagraphSentence}
            setShowClickInstruction={setShowClickInstruction} title={`Add both a new paragraph and sentence - Ctrl+Alt+P`} />
        }
        {!isTranslation && (!isAuthor || false) &&
          <PushButton value={addSentence} setValue={setAddSentence} onImage={OnAddSentence} offImage={OffAddSentence}
                      setShowClickInstruction={setShowClickInstruction} title={`Add a new sentence - Ctrl+Alt+C`}/>
        }
        {!isTranslation && 
          <PushButton value={deleteSentence} setValue={setDeleteSentence} onImage={OnDeleteSentence} offImage={OffDeleteSentence}
                      setShowClickInstruction={setShowClickInstruction} title={`Delete a sentence - Ctrl+Alt+D`}/>
        }
        {!isTranslation && 
          <PushButton value={addList} setValue={setAddList} onImage={OnAddList} offImage={OffAddList}
                      setShowClickInstruction={setShowClickInstruction} title={`Add a new list - Ctrl+Alt+L`}/>
        }
        {!isTranslation &&
          <div className={addList && convertAddListSequence === 1 ? styles.showPanel : styles.hidePanel}>
            <img src={TextPanelMove} alt={'toggle'} className={styles.textPanelMove} />
            <div className={styles.moveText}>
              <div className={convertAddListSequence <= 1 ? styles.moveRow : styles.moveRowOpacity}>
                <img src={ConvertAddListStart} height={14} className={styles.image} />
                <div className={styles.instructionText}>1. Click on the beginning sentence.</div>
              </div>
              <div className={convertAddListSequence <= 2 ? styles.moveRow : styles.moveRowOpacity}>
                <img src={ConvertAddListEnd} height={14} className={styles.imageSmaller} />
                <div className={styles.instructionText}>2. Choose the last sentence (or its icon).</div>
              </div>
              <div className={convertAddListSequence <= 3 ? styles.moveRow : styles.moveRowOpacity}>
                <div className={classes(styles.missingIcon, styles.instructionText)}>3. A pop-up will give you list types.</div>
              </div>
            </div>
          </div>
        }
        {!isTranslation && hasListStructure &&
          <PushButton value={changeList} setValue={setChangeList} onImage={OnChangeList} offImage={OffChangeList} isDisabled={!isInList} disabledImage={DisabledChangeList}
            setShowClickInstruction={setShowClickInstruction} title={`Change a list type - Ctrl+Alt+T`} />
        }
        {!isTranslation && hasListStructure &&
          <PushButton value={addListItem} setValue={setAddListItem} onImage={OnAddListItem} offImage={OffAddListItem}
            setShowClickInstruction={setShowClickInstruction} title={`Add a new list item - Ctrl+Alt+N`} />
        }
        {!isTranslation && hasListStructure &&
          <PushButton value={deleteListItem} setValue={setDeleteListItem} onImage={OnDeleteListItem} offImage={OffDeleteListItem}
                      setShowClickInstruction={setShowClickInstruction} title={`Delete a list item - Ctrl+Alt+I`}/>
        }
        {hasListStructure &&
          <PushButton value={reorderListItems} setValue={setReorderListItems} onImage={OnReorderListItems} offImage={OffReorderListItems}
                      setShowClickInstruction={setShowClickInstruction} title={`Reorder a list level - Ctrl+Alt+R`}/>
        }
        {!isTranslation && 
          <PushButton value={moveSentences} setValue={setMoveSentences} onImage={OnMoveSentences} offImage={OffMoveSentences}
                      setShowClickInstruction={setShowClickInstruction} title={`Move one or more sentences - Ctrl+Alt+M`}/>
        }
        {!isTranslation && 
          <div className={moveSentences ? styles.showPanel : styles.hidePanel}>
            <img src={TextPanelMove} alt={'toggle'} className={styles.textPanelMove} />
            <div className={styles.moveText}>
              <div className={moveSequence <= 1 ? styles.moveRow : styles.moveRowOpacity}>
                <img src={MoveStart} height={14} className={styles.image} />
                <div className={styles.instructionText}>1. Choose beginning sentence and icon.</div>
              </div>
              <div className={moveSequence <= 2 ? styles.moveRow : styles.moveRowOpacity}>
                <img src={MoveEnd} height={14} className={styles.imageSmaller} />
                <div className={styles.instructionText}>2. Choose icon at end of the last sentence.</div>
              </div>
              <div className={moveSequence <= 3 ? styles.moveRow : styles.moveRowOpacity}>
                <img src={MoveTarget} height={18} className={classes(styles.image, styles.imageWidth)} />
                <div className={styles.instructionText}>3. Choose where you want to move to.</div>
              </div>
            </div>
          </div>
        }
        <div className={classes(styles.rowToggle, moveSentences || changeStyle ? styles.hide : styles.show)} >
          <img src={Divider} alt={'toggle'} className={styles.divider}/>
        </div>
        <OneFJefFooter pushRight/>
      </div>
    </div>
  )
}

export default ToggleBoard
