import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router'
import globalStyles from '../../utils/globalStyles.module.css';
import styles from './EditorInviteByBulkView.module.css';
import OneFJefFooter from '../../components/OneFJefFooter';
import GroupMemberAdd from '../../components/GroupMemberAdd/GroupMemberAdd';

function EditorInviteByBulkView(props) {
	const {
		personId,
		groupId,
		groupOptions,
		getGroupContactsWorks 
	} = props;

  const params = useParams()
	const [pendingGroupInvites, setPendingGroupInvites] = useState([])
	const [groupChosen, setGroupChosen] = useState()

	useEffect(() => {
		setGroupChosen(params.groupChosen)
	}, [params.groupChosen])


	return (
		<div className={styles.container}>
			<div className={styles.mainDiv}>
				<div className={styles.titleLine}>
					<span className={globalStyles.pageTitle}>Invite New Editors By Bulk Entry</span>
				</div>
				<GroupMemberAdd groupId={groupId}
												personId={personId}
												fromGroupId={groupChosen}
												bulkDelimiterOptions={props.bulkDelimiterOptions}
												groupOptions={groupOptions}
												fieldOptions={props.fieldOptions}
												setGroupMembers={props.setGroupMembers}
												getInvitesPending={props.getInvitesPending}
												getGroupContactsWorks={getGroupContactsWorks}
												pendingGroupInvites={pendingGroupInvites}/>
        <OneFJefFooter/>
			</div>
		</div>
	);
}

export default EditorInviteByBulkView;
