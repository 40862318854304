import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom'
import GroupChooseExistingView from '../views/GroupChooseExistingView';
import {connect} from 'react-redux';
import * as actionGroups from '../actions/groups.js';
import * as actionLanguageList from '../actions/language-list.js';
import * as actionFileTreeSubContents from "../actions/work-file-tree-sub-contents";
import {selectGroups, selectMe, selectWorkFileTree} from '../store.js';

const mapStateToProps = (state, props) => {
	let me = selectMe(state);
  let workFolders = selectWorkFileTree(state)
  workFolders = workFolders && workFolders.length> 0 && workFolders.filter(m => m.mineOrOthers === 'Mine')
  const groups = selectGroups(state)

  const groupOptions = groups && groups.length > 0 && groups.reduce((acc, m) => {
    let exists = m.groupId && acc && acc.length > 0 && acc.filter(f => f.id === m.groupId)[0]
    if (!exists && !!m.groupId) {
      let option = {
        id: m.groupId,
        label: m.groupName
      }
      return acc && acc.length > 0 ? acc.concat(option) : [option]
    }
  }, [])

  return {
		personId: me.personId,
    workFolders,
    groups,
    groupOptions,
	}
};

const bindActionsToDispatch = dispatch => ({
  getGroups: (personId) => dispatch(actionGroups.getGroups(personId)),
  addOrUpdateGroup: (groupData, sendTo) => dispatch(actionGroups.addOrUpdateGroup(groupData, sendTo)),
  deleteGroupWorkFolderAssignment: (personId, workFolderId, sendTo) => dispatch(actionGroups.deleteGroupWorkFolderAssignment(personId, workFolderId, sendTo)),
  getMyWorks: (personId) => dispatch(actionFileTreeSubContents.getMyWorks(personId)),
  getLanguages: () => dispatch(actionLanguageList.getLanguages()),
});

const mergeAllProps = (store, actions) => ({
	...store, ...actions,
});

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
	mergeAllProps
);

function Container(props) {
  const { workFolderId, groupId } = useParams()
  const {getMyWorks, personId, getGroups} = props

  useEffect(() => {
    if (personId) {
      getMyWorks(personId)
      getGroups(personId)
    }
  }, [personId])

  return <GroupChooseExistingView paramWorkFolderId={workFolderId} groupId={groupId} {...props} />
}

export default storeConnector(Container);
