/* Initial Data */
export const LOGGED_IN_SET = "LOGGED_IN_SET"
export const PEOPLE_INIT = "PEOPLE_INIT"
export const PERSON_CONFIG_INIT = "PERSON_CONFIG_INIT"
export const MEMBERS_WITH_CALLINGS_INIT = "MEMBERS_WITH_CALLINGS_INIT"

/* TRANSLATIONS  */
export const TRANSLATIONS_INIT = "TRANSLATIONS_INIT"
export const TRANSLATED_SENTENCE_SET = "TRANSLATED_SENTENCE_SET"

/* LOGIN */
export const LOGIN_ATTEMPT = "LOGIN_ATTEMPT"
export const LOGGED_FAILED = "LOGGED_FAILED"
export const LOGGED_SUCCESSFULLY = "LOGGED_SUCCESSFULLY"
export const LOGGED_OUT = "LOGGED_OUT"
export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST"
export const PASSWORD_RESET_COMPLETE = "PASSWORD_RESET_COMPLETE"
export const LOGIN_MATCHING_RECORD = "LOGIN_MATCHING_RECORD"
export const LANDING_STEPS_INIT = "LANDING_STEPS_INIT"

/* LISTS */
export const LANGUAGE_LIST_INIT = "LANGUAGE_LIST_INIT"
export const WORK_STATUS_LIST_INIT = "WORK_STATUS_LIST_INIT"
export const EDIT_SEVERITY_LIST_INIT = "EDIT_SEVERITY_LIST_INIT"
export const DECLINE_IDLE_LIST_INIT = "DECLINE_IDLE_LIST_INIT"
export const GENRE_LIST_INIT = "GENRE_LIST_INIT"
export const COLORS_EDITOR_INIT = "COLORS_EDITOR_INIT"

/* Logged in */
export const LOGGEDIN_SET = "LOGGEDIN_SET"

/* Tabs Editors */
export const TABS_EDITORS_INIT = "TABS_EDITORS_INIT"
export const TABS_EDITORS_SET_SELECTED = "TABS_EDITORS_SET_SELECTED"
export const TABS_EDITORS_SET_COUNTS = "TABS_EDITORS_SET_COUNTS"

/*  Works */
export const WORKS_INIT = "WORKS_INIT"
export const WORK_EDIT_REVIEW = "WORK_EDIT_REVIEW"
export const WORKS_TREE_DATA_INIT = "WORKS_TREE_DATA_INIT"
export const WORK_NEW_ADD = "WORK_NEW_ADD"
export const WORK_NEW_UPDATE = "WORK_NEW_UPDATE"
export const WORK_CURRENT_SENTENCE_UPDATE = "WORK_CURRENT_SENTENCE_UPDATE"
export const WORK_SET_VISITED_HREFID = "WORK_SET_VISITED_HREFID"
export const WORK_DELETE = "WORK_DELETE"
export const WORK_CURRENT_SET_SELECTED = "WORK_CURRENT_SET_SELECTED"  //Sets workId_current which is at the root of the store.
export const WORK_EDITOR_ASSIGN_UPDATE = "WORK_EDITOR_ASSIGN_UPDATE"
export const WORK_CHAPTER_DUEDATE_UPDATE = "WORK_CHAPTER_DUEDATE_UPDATE"
export const WORK_CHAPTER_DELETE = "WORK_CHAPTER_DELETE"
export const WORK_CHAPTEROPTIONS_UPDATE = "WORK_CHAPTEROPTIONS_UPDATE"
export const WORK_CHAPTER_RESEQUENCE = "WORK_CHAPTER_RESEQUENCE"
export const WORK_UPDATE_CHAPTERS = "WORK_UPDATE_CHAPTERS"
export const WORKS_MINE = "WORKS_MINE"
export const WORKS_SHARED_WITH_ME = "WORKS_SHARED_WITH_ME"
export const WORK_PENSPRING_SUBMITTED = "WORK_PENSPRING_SUBMITTED"
export const WORK_PENSPRING_DISTRIBUTED = "WORK_PENSPRING_DISTRIBUTED"
export const WORK_FOLDER_TOGGLE_EXPANDED = "WORK_FOLDER_TOGGLE_EXPANDED"

/* Chapters */
export const CHAPTERS_LIST_INIT = "CHAPTERS_LIST_INIT"
export const CHAPTER_ORIGINAL_TEXT_UPDATE = "CHAPTER_ORIGINAL_TEXT_UPDATE"
export const CHAPTER_TEXT_INIT = "CHAPTER_TEXT_INIT"
export const CHAPTER_TEXT_UPDATE_SENTENCE = "CHAPTER_TEXT_UPDATE_SENTENCE"
export const CHAPTER_SENTENCE_ORIGINAL = "CHAPTER_SENTENCE_ORIGINAL"
export const CHAPTER_DUEDATE_UPDATE = "CHAPTER_DUEDATE_UPDATE"
export const CHAPTER_CURRENT_SET_SELECTED = "CHAPTER_CURRENT_SET_SELECTED"
export const CONTACT_EDITOR_ASSIGN_UPDATE = "CONTACT_EDITOR_ASSIGN_UPDATE"
export const AUTHOR_WORKSPACE_INIT = "AUTHOR_WORKSPACE_INIT"

/* Language */
export const LANGUAGE_CURRENT_SET_SELECTED = "LANGUAGE_CURRENT_SET_SELECTED"

/* Work Filter */
export const WORK_FILTERS_INIT = "WORK_FILTERS_INIT"

/* Contact Filter */
export const CONTACT_FILTERS_INIT = "CONTACT_FILTERS_INIT"

/* Me Choices */
export const ME_INIT = "ME_INIT"
export const ME_UPDATE = "ME_UPDATE"
export const ME_GENRE_UPDATE = "ME_GENRE_UPDATE"
export const ME_WORK_UPDATE = "ME_WORK_UPDATE"

/* Contacts */
export const CONTACTS_INIT = "CONTACTS_INIT"
export const CONTACT_CURRENT_SET_SELECTED = "CONTACT_CURRENT_SET_SELECTED"  //Sets personId_current which is at the root of the store.

/* Left Side Panel */
export const LEFT_SIDE_PANEL_TOGGLE = "LEFT_SIDE_PANEL_TOGGLE"

/* Bookmarks */
export const BOOKMARKS_INIT = "BOOKMARKS_INIT"

/* Editor Report - this is the second attempt after the participation report which not as involved.*/
/* And now we add the group edit report which is different enough that we ought to keep it separate, particularly for future enhancements */
export const CONTRIBUTOR_REPORT_INIT = "CONTRIBUTOR_REPORT_INIT"
export const GROUP_EDIT_REPORT_INIT = "GROUP_EDIT_REPORT_INIT"

/* Draft Settings and Draft Comparison */
export const DRAFT_SETTINGS_INIT = "DRAFT_SETTINGS_INIT"
export const DRAFT_COMPARISON_INIT = "DRAFT_COMPARISON_INIT"
export const DRAFT_COMPARISON_TAB_SET = "DRAFT_COMPARISON_TAB_SET"
export const DRAFT_VIEW_TOGGLE = "DRAFT_VIEW_TOGGLE"

/* Edit Filter */
export const EDIT_FILTER_INIT = "EDIT_FILTER_INIT"
export const EDIT_FILTER_UPDATE = "EDIT_FILTER_UPDATE"

/* Editor invite */
export const EDITOR_INVITE_NAME_EMAIL = "EDITOR_INVITE_NAME_EMAIL"
export const EDITOR_INVITE_WORK_CHAPTERS = "EDITOR_INVITE_WORK_CHAPTERS"
export const EDITOR_INVITE_WORK_CHAPTERS_INIT = "EDITOR_INVITE_WORK_CHAPTERS_INIT"
export const EDITOR_INVITE_RESPONSE = "EDITOR_INVITE_RESPONSE"
export const EDITOR_INVITE_PENDING = "EDITOR_INVITE_PENDING"

/* Fetching record */
export const FETCHING_RECORD = "FETCHING_RECORD"

/* Open Community */
export const OPEN_COMMUNITY_INIT = "OPEN_COMMUNITY_INIT"
export const OPEN_COMMUNITY_UPDATE = "OPEN_COMMUNITY_UPDATE"
export const OPEN_COMMUNITY_FILTERS_INIT = "OPEN_COMMUNITY_FILTERS_INIT"

/* Report Filter */
export const REPORT_FILTERS_INIT = "REPORT_FILTERS_INIT"
export const REPORT_FILTERS_OPTIONS_INIT = "REPORT_FILTERS_OPTIONS_INIT"
export const REPORT_FILTERS_OPTIONS_SECTIONS = "REPORT_FILTERS_OPTIONS_SECTIONS"

/* Text Processing Progress */
export const TEXT_PROCESSING_PROGRESS_GET = "TEXT_PROCESSING_PROGRESS_GET"

/*  My Profile */
export const MY_PROFILE_INIT = "MY_PROFILE_INIT"
export const MY_PROFILE_UPDATE = "MY_PROFILE_UPDATE"

/* Edit Details */
export const EDIT_DETAILS_INIT = "EDIT_DETAILS_INIT"
export const EDIT_DETAILS_UPDATE = "EDIT_DETAILS_UPDATE"
export const EDIT_DETAILS_ACCEPTED_UPDATE = "EDIT_DETAILS_ACCEPTED_UPDATE"
export const EDIT_DETAILS_ADD_VOTE = "EDIT_DETAILS_ADD_VOTE"
export const EDIT_DOM_MICRO_REPLACE = "EDIT_DOM_MICRO_REPLACE"
export const EDIT_DETAIL_TEMP_NEW = "EDIT_DETAIL_TEMP_NEW"

/* Edit Review */
export const EDIT_MODE_CHOSEN_SET = "EDIT_MODE_CHOSEN_SET"
export const EDITOR_TAB_CHOSEN_SET = "EDITOR_TAB_CHOSEN_SET"
export const SENTENCE_CHOSEN_SET = "SENTENCE_CHOSEN_SET"
export const EDIT_CHOSEN_SET = "EDIT_CHOSEN_SET"
export const EDIT_ICON_POSITION_SET = "EDIT_ICON_POSITION_SET"
export const PARAGRAPH_NEW_BREAK_CHOSEN_SET = "PARAGRAPH_NEW_BREAK_CHOSEN_SET"
export const PARAGRAPH_DELETE_BREAK_CHOSEN_SET = "PARAGRAPH_DELETE_BREAK_CHOSEN_SET"
export const SENTENCE_MOVE_CHOSEN_SET = "SENTENCE_MOVE_CHOSEN_SET"
export const IMAGE_NEW_CHOSEN_SET = "IMAGE_NEW_CHOSEN_SET"
export const CHAPTER_CHANGED = "CHAPTER_CHANGED"
export const EDIT_REVIEW_EDITOR_TAB_DIFF = "EDIT_REVIEW_EDITOR_TAB_DIFF"

/* Groups */
export const GROUP_TYPES_INIT = "GROUP_TYPES_INIT"
export const GROUPS_INIT = "GROUPS_INIT"
export const GROUPS_CURRENT_SET_SELECTED = "GROUPS_CURRENT_SET_SELECTED"
export const GROUPS_DELETE = "GROUPS_DELETE"

/* Access Report */
export const ACCESS_REPORT_INIT = "ACCESS_REPORT_INIT"

/* Group Work Assign Access */
export const GROUP_WORK_ASSIGN_ACCESS_INIT = "GROUP_WORK_ASSIGN_ACCESS_INIT"

export const WORK_SEGMENTS_INIT = "WORK_SEGMENTS_INIT"
export const PEER_GROUP_INIT = "PEER_GROUP_INIT"
export const PEER_GROUP_DELETE = "PEER_GROUP_DELETE"

/* Miscellaneous laziness */
export const IS_ACTIVE_INVITE = "IS_ACTIVE_INVITE"
export const WORK_EDITS_INIT = "WORK_EDITS_INIT"
export const WORK_EDIT_UPDATE = "WORK_EDIT_UPDATE"
export const WORK_EDIT_VOTE_UPDATE = "WORK_EDIT_VOTE_UPDATE"
export const WORK_EDIT_RESPONSE = "WORK_EDIT_RESPONSE"
export const WORK_COMMENT_UPDATE = "WORK_COMMENT_UPDATE"
export const WORK_EDIT_COMMENT = "WORK_EDIT_COMMENT"
export const CONTACTS_EDITOR_ASSIGN_UPDATE = "CONTACTS_EDITOR_ASSIGN_UPDATE"
export const WORK_EDIT_REPORT_INIT = "WORK_EDIT_REPORT_INIT"
export const WORK_SUMMARY_INIT = "WORK_SUMMARY_INIT"
export const WORK_FILE_TREE_INIT = "WORK_FILE_TREE_INIT"
export const WORK_FILE_DELETE = "WORK_FILE_DELETE"
export const WORK_FILE_TREE_TOGGLE_EXPANDED = "WORK_FILE_TREE_TOGGLE_EXPANDED"
export const GROUP_FILE_TREE_TOGGLE_EXPANDED = "GROUP_FILE_TREE_TOGGLE_EXPANDED"
export const WORK_EDITOR_ACCESS_INIT = "WORK_EDITOR_ACCESS_INIT"
export const VERIFY_USERNAME = "VERIFY_USERNAME"
export const EDIT_SEGMENT_HISTORY_INIT = "EDIT_SEGMENT_HISTORY_INIT"
export const GROUP_FILE_TREE_INIT = "GROUP_FILE_TREE_INIT"
export const PEOPLE_BY_GROUP_INIT = "PEOPLE_BY_GROUP_INIT"
export const WORK_BY_GROUP_INIT = "WORK_BY_GROUP_INIT"
export const WORK_TYPES_INIT = "WORK_TYPES_INIT"
export const GROUP_CONTACTS_WORKS_INIT = "GROUP_CONTACTS_WORKS_INIT"
export const CHAPTER_LIST_LEVELS_INIT = "CHAPTER_LIST_LEVELS_INIT"
export const LIST_LEVELS_INIT = "LIST_LEVELS_INIT"
export const DRAFT_REPORT_INIT = "DRAFT_REPORT_INIT"
export const DRAFT_REVIEW_INIT = "DRAFT_REVIEW_INIT"
export const GROUP_CONTACTS_UPDATE = "GROUP_CONTACTS_UPDATE"
export const CONTACT_MEMBERS_INIT = "CONTACT_MEMBERS_INIT"
export const FRIEND_INVITATION = "FRIEND_INVITATION"
export const GROUP_BY_NAME_INIT = "GROUP_BY_NAME_INIT"
export const TEXT_IMAGE_GROUPS_INIT = "TEXT_IMAGE_GROUPS_INIT"
export const TEXT_IMAGE_UPLOADS_INIT = "TEXT_IMAGE_UPLOADS_INIT"
export const WORK_ADD_SUMMARY_INIT = "WORK_ADD_SUMMARY_INIT"
export const TEXT_STYLE_GROUPS_INIT = "TEXT_STYLE_GROUPS_INIT"
export const TEXT_STYLE_FONTS_INIT = "TEXT_STYLE_FONTS_INIT"
export const WORK_SEGMENT_UPLOAD_PROGRESS_INIT = "WORK_SEGMENT_UPLOAD_PROGRESS_INIT"
export const HTML_CHARACTER_ENTITIES = "HTML_CHARACTER_ENTITIES"

/* Assessment */
export const ASSESSMENT_CORRECT_CLEAR = "ASSESSMENT_CORRECT_CLEAR"
export const ASSESSMENT_CORRECT_INIT = "ASSESSMENT_CORRECT_INIT"
export const ASSESSMENT_CORRECT_SAME_ALL_STUDENTS = "ASSESSMENT_CORRECT_SAME_ALL_STUDENTS"
export const ASSESSMENT_CORRECT_SCORE_UPDATE = "ASSESSMENT_CORRECT_SCORE_UPDATE"
export const ASSESSMENT_CORRECT_SUMMARY_INIT = "ASSESSMENT_CORRECT_SUMMARY_INIT"
export const ASSESSMENT_INIT = "ASSESSMENT_INIT"
export const ASSESSMENTS_INIT = "ASSESSMENTS_INIT"
export const ASSESSMENT_PENDING_ESSAY = "ASSESSMENT_PENDING_ESSAY"
export const ASSESSMENT_PUBLISH_TOGGLE = "ASSESSMENT_PUBLISH_TOGGLE"
export const ASSESSMENT_QUESTION_ANSWER_SET = "ASSESSMENT_QUESTION_ANSWER_SET"
export const ASSESSMENT_QUESTION_CLEAR = "ASSESSMENT_QUESTION_CLEAR"
export const ASSESSMENT_QUESTIONS_INIT = "ASSESSMENT_QUESTIONS_INIT"
export const ASSESSMENT_SETTING = "ASSESSMENT_SETTING"
export const QUESTION_TYPES_INIT = "QUESTION_TYPES_INIT"
export const VOICE_RECORDING_INIT = "VOICE_RECORDING_INIT"
export const ASSESSMENT_QUESTION = "ASSESSMENT_QUESTION"
