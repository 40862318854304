import React from 'react';
import styles from './GoogleDrive.module.css';
import WorkListGoogleDrive from '../WorkListGoogleDrive'
import GoogleDriveLogo from '../../assets/GoogleDriveLogo.png'

export default (props) => {
	const { 
		fileGoogle, 
		handleSetFileGoogle, 
		setGoogleDriveOpen, 
		googleDriveOpen, 
		isMobile, 
		includeGoogleOr 
	} = props

	return (
		<div className={isMobile ? styles.googleDriveMobile : styles.googleDrive}>
			{includeGoogleOr &&
				<div className={styles.positionOr}>- OR -</div>
			}
			<div className={isMobile ? styles.rowGoogleMobile : styles.rowGoogle} onClick={() => { handleSetFileGoogle(null); setGoogleDriveOpen(!googleDriveOpen); }}>
				<img src={GoogleDriveLogo} height={25} />
				<div className={isMobile ? styles.googleTextMobile : styles.googleText}>
					Google drive
				</div>
			</div>
			{fileGoogle && fileGoogle.file &&
				<div key={'chosen'} onClick={() => handleSetFileGoogle(null)} className={styles.fileGoogleChosen}>
					<input type='radio' checked={true} onChange={() => { }} />
					{fileGoogle?.file?.name}
				</div>
			}
			<div className={googleDriveOpen && !(fileGoogle && fileGoogle.file) ? styles.showGoogleDrive : styles.hide}>
				<WorkListGoogleDrive openList={googleDriveOpen} setFileGoogle={handleSetFileGoogle} fileGoogle={fileGoogle} {...props} />
			</div>
		</div>	)
};
