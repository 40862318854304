import React from 'react';
import styles from './PrivacyPolicyView.module.css';
import OneFJefFooter from '../../components/OneFJefFooter'
import LogoSlogan from '../../components/LogoSlogan'

function PrivacyPolicyView() {

	return (
		<div className={styles.container}>
      <div className={styles.mainDiv}>
				<LogoSlogan larger includeOneFJef/>
				<p className={styles.MsoNormal} style={{fontSize: '16pt', marginTop: '40px'}}><b>Privacy Policy</b></p>
				<br />
				<br />
				<p className={styles.MsoNormal}><b>Last Updated: October 2, 2024</b></p>

				<p className={styles.MsoNormal}><b>1. Introduction</b></p>

				<p className={styles.MsoNormal}>Welcome to one-f Jef, Inc. ('we', 'our', 'us'). We are
					committed to protecting your privacy and ensuring a secure experience while
					using our web application ('Service'). This Privacy Policy explains how we
					collect, use, disclose, and safeguard your information when you use our
					Service. Please read this policy carefully.</p>

				<p className={styles.MsoNormal}><b>2. Information We Collect</b></p>

				<p className={styles.MsoNormal}><b>2.1 Personal Information</b></p>

				<p className={styles.MsoNormal}>We may collect personal information that you provide to us
					directly, such as:</p>

				<ul style={{marginTop: '0in'}} type={'disc'}>
					<li className={styles.MsoNormal}><b>Name</b></li>
					<li className={styles.MsoNormal}><b>Email address</b></li>
					<li className={styles.MsoNormal}><b>Phone number</b></li>
					<li className={styles.MsoNormal}><b>Profile picture</b></li>
					<li className={styles.MsoNormal}><b>Social media account details</b></li>
				</ul>

				<p className={styles.MsoNormal}><b>2.2 Usage Data</b></p>

				<p className={styles.MsoNormal}>We collect information about your interactions with our
					Service, including:</p>

				<ul style={{marginTop: '0in'}} type={'disc'}>
					<li className={styles.MsoNormal}><b>IP address</b></li>
					<li className={styles.MsoNormal}><b>Browser type and version</b></li>
					<li className={styles.MsoNormal}><b>Pages visited</b></li>
					<li className={styles.MsoNormal}><b>Time spent on pages</b></li>
					<li className={styles.MsoNormal}><b>Device information</b></li>
				</ul>

				<p className={styles.MsoNormal}><b>2.3 Cookies and Tracking Technologies</b></p>

				<p className={styles.MsoNormal}>We use cookies and similar tracking technologies to enhance
					your experience. These technologies help us remember your preferences and track
					your usage patterns. You can control cookies through your browser settings.</p>

				<p className={styles.MsoNormal}><b>3. How We Use Your Information</b></p>

				<p className={styles.MsoNormal}>We use the collected information for various purposes,
					including:</p>

				<ul style={{marginTop: '0in'}} type={'disc'}>
					<li className={styles.MsoNormal}><b>Providing and maintaining our Service</b></li>
					<li className={styles.MsoNormal}><b>Improving our Service</b></li>
					<li className={styles.MsoNormal}><b>Personalizing your experience</b></li>
					<li className={styles.MsoNormal}><b>Communicating with you, including sending updates and
						promotional materials</b></li>
					<li className={styles.MsoNormal}><b>Responding to customer service requests</b></li>
					<li className={styles.MsoNormal}><b>Analyzing usage trends to improve our Service</b></li>
				</ul>

				<p className={styles.MsoNormal}><b>4. Sharing Your Information</b></p>

				<p className={styles.MsoNormal}>We do not sell or rent your personal information to third
					parties. No mobile information will be shared with third parties for marketing purposes. 
					All categories exclude text messaging originator opt-in data and consent; this information 
					will not be shared with any third parties. We may share your information in the following circumstances:</p>

				<ul style={{marginTop: '0in'}} type={'disc'}>
					<li className={styles.MsoNormal}><b>With Service Providers:</b> We may share information
						with third-party vendors who perform services on our behalf, such as
						payment processing, data analysis, and marketing support.</li>
					<li className={styles.MsoNormal}><b>For Legal Reasons:</b> We may disclose information if
						required to do so by law, or in response to valid requests by public
						authorities.</li>
					<li className={styles.MsoNormal}><b>Business Transfers:</b> In the event of a merger,
						acquisition, or sale of assets, your information may be transferred as
						part of the transaction.</li>
				</ul>

				<p className={styles.MsoNormal}><b>5. Security of Your Information</b></p>

				<p className={styles.MsoNormal}>We implement reasonable security measures to protect your
					information from unauthorized access, alteration, disclosure, or destruction.
					However, no method of transmission over the Internet or electronic storage is
					completely secure, and we cannot guarantee absolute security.</p>

				<p className={styles.MsoNormal}><b>6. Your Rights and Choices</b></p>

				<p className={styles.MsoNormal}>Depending on your jurisdiction, you may have certain rights
					regarding your personal information, including:</p>

				<ul style={{marginTop: '0in'}} type={'disc'}>
					<li className={styles.MsoNormal}><b>Access:</b> You can request access to the personal
						information we hold about you.</li>
					<li className={styles.MsoNormal}><b>Correction:</b> You can request that we correct any
						inaccurate or incomplete information.</li>
					<li className={styles.MsoNormal}><b>Deletion:</b> You can request the deletion of your
						personal information, subject to certain conditions.</li>
					<li className={styles.MsoNormal}><b>Opt-Out:</b> You can opt-out of receiving promotional
						communications from us.</li>
				</ul>

				<p className={styles.MsoNormal}>To exercise these rights, please contact us using the
					information provided below.</p>

				<p className={styles.MsoNormal}><b>7. Third-Party Links</b></p>

				<p className={styles.MsoNormal}>Our Service may contain links to third-party websites. We
					are not responsible for the privacy practices or content of these third-party
					sites. We encourage you to review their privacy policies before providing any
					personal information.</p>

				<p className={styles.MsoNormal}><b>8. Changes to This Privacy Policy</b></p>

				<p className={styles.MsoNormal}>We may update this Privacy Policy from time to time. Any
					changes will be posted on this page with an updated effective date. We
					encourage you to review this policy periodically to stay informed about how we
					are protecting your information.</p>

				<p className={styles.MsoNormal}><b>9. Contact Us</b></p>

				<p className={styles.MsoNormal}>If you have any questions or concerns about this Privacy
					Policy or our practices, please contact us at:</p>

				<ul style={{marginTop: '0in'}} type={'disc'}>
					<li className={styles.MsoNormal}><b>Email:</b> jef@penspring.com</li>
					<li className={styles.MsoNormal}><b>Address:</b> 1920 W. 100 S., Manti, UT  84642</li>
				</ul>
        <OneFJefFooter/>
      </div>
		</div>
	);
}

export default PrivacyPolicyView;
