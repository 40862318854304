import * as types  from '../actions/actionTypes';

export default function(state = {}, action) {
  switch (action.type) {
    case types.LOGIN_ATTEMPT:
      return Object.assign({}, {
        isLoggingIn: true,
        isLoggedIn: false,
        email: action.emailAddress,
      });
     case types.LOGIN_MATCHING_RECORD:
        return Object.assign({}, {
          error: action.payload,
          isLoggingIn: false,
          isLoggedIn: false
      });

    case types.LOGGED_FAILED:
      return Object.assign({}, {
        error: 'Invalid Login',
        isLoggingIn: false,
        isLoggedIn: false
      });
    case types.LOGGED_SUCCESSFULLY: {
        return action.payload ? action.payload : state
    }
    case types.LOGGED_OUT:
        return Object.assign({}, {
          error: null,
          isLoggingIn: false,
          isLoggedIn: false,
          email: '',
          personId: null,
        });
    case types.PASSWORD_RESET_REQUEST:
        return Object.assign({}, {
            passwordResetRequest: action.payload,
        });

    case types.PASSWORD_RESET_COMPLETE:
        return Object.assign({}, {
            passwordResetComplete: action.payload,
        });

    default:
        return state;
  }
}

export const selectMe = (state) => state;
