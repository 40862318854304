import * as types from '../actions/actionTypes';

export default function (state = [], action) {
	switch (action.type) {
		case types.WORK_SEGMENT_UPLOAD_PROGRESS_INIT: {
			return action.payload;
		}
		default:
			return state;
	}
}

export const selectWorkSegmentUploadProgress = (state) => state;
