import React from 'react';
import styles from './ContactSummaryLine.module.css';
import classes from 'classnames'

function ContactSummaryLine(props) {
	const {
		contact = {},
    nameLength = 75,
		lightColorText,
		LighterBackground,
    addClassName,
    keyIndex,
		showPhoto,
		showEmailAddress,
		showNumbersPenspringMember,
		subText,
		showGroupNames
	} = props
	let fullname = contact.firstName + ' ' + contact.lastName
	fullname = fullname.length > nameLength ? fullname.substring(0, nameLength) + '...' : fullname

	const showGroupNameList = () => {
		const groups = contact.groups
		return (
			<div className={styles.rowGroups}>
				<div className={styles.groupLabel}>Groups:</div>
				{groups && groups.length > 0 && groups.map(m => {
					let displayName = m.groupName.length > 30 ? m.groupName.substring(0,30) + '...' : m.groupName
					return <div title={m.groupName} className={styles.maroon}>{displayName}</div>
				})}
				{!(groups && groups.length > 0) &&
					<div className={styles.none}><i>None</i></div>
				}
			</div>
		)
	}

	return (
		<div className={classes(styles.container, LighterBackground ? styles.backgroundLighter : styles.background, addClassName)} key={keyIndex}>
			<div className={styles.rowNoWrap}>
				{showPhoto && 
					<div style={{marginRight: '5px'}}> 
						<img src={contact.photo} height={45} className={styles.roundedImage}/>
					</div>
				}
				<div style={{minWidth: '250px', width: '100%'}}>
					<div className={styles.container}>
						<div title={fullname.length > nameLength ? fullname + ' - ' + contact.emailAddress : contact.emailAddress} className={lightColorText ? styles.whiteText : ''}>
							{fullname}
						</div>
						<div title={fullname.length > nameLength ? fullname : null} className={lightColorText ? styles.whiteText : ''}>
							{showEmailAddress ? ' - ' + contact.emailAddress : ''}
						</div>
					</div>
					{(!showNumbersPenspringMember || (showNumbersPenspringMember && contact.isPenspringMember)) && 
						<div className={styles.rowTight}>
							<div title={'Assigned to work'} className={styles.row}>
								<div className={classes(styles.label, lightColorText ? styles.whiteText : '')}>assigned</div>
								<div
									className={lightColorText ? styles.countWhite : styles.count}>{contact.wordCount || contact.worksAssigned || '0'}</div>
							</div>
							<div title={'Edits processed'} className={styles.row}>
								<div className={classes(styles.label, lightColorText ? styles.whiteText : '')}>edits</div>
								<div
									className={lightColorText ? styles.countWhite : styles.count}>{contact.sentenceCount || contact.edits || '0'}</div>
							</div>
							<div title={'Edits pending'} className={styles.row}>
								<div className={classes(styles.label, lightColorText ? styles.whiteText : '')}>pending</div>
								<div className={lightColorText ? styles.countWhite : styles.count}>{contact.editsPending || '0'}</div>
							</div>

							<div title={'Accepted edits by the author'} className={styles.row}>
								<div className={classes(styles.label, lightColorText ? styles.whiteText : '')}>accepted</div>
								<div className={lightColorText ? styles.countWhite : styles.count}>{contact.acceptedEdits || '0'}</div>
							</div>
							<div title={'Non accepted edits by the author'} className={styles.row}>
								<div className={classes(styles.label, lightColorText ? styles.whiteText : '')}>unaccepted</div>
								<div className={lightColorText ? styles.countWhite : styles.count}>{contact.nonAcceptedEdits || '0'}</div>
							</div>
							<div title={`Up votes for this editor's edits`} className={styles.row}>
								<div className={classes(styles.label, lightColorText ? styles.whiteText : '')}>up-votes</div>
								<div className={lightColorText ? styles.countWhite : styles.count}>{contact.upVotes || '0'}</div>
							</div>
							<div title={`Down votes for this editor's edits`} className={styles.row}>
								<div className={classes(styles.label, lightColorText ? styles.whiteText : '')}>down-votes</div>
								<div className={lightColorText ? styles.countWhite : styles.count}>{contact.downVotes || '0'}</div>
							</div>
							<div title={`Troll votes for this editor's edits`} className={styles.row}>
								<div className={classes(styles.label, lightColorText ? styles.whiteText : '')}>troll-votes</div>
								<div className={lightColorText ? styles.countWhite : styles.count}>{contact.trollVotes || '0'}</div>
							</div>
							<div title={'Votes cast for others by this editor'} className={styles.row}>
								<div className={classes(styles.label, lightColorText ? styles.whiteText : '')}>votes cast</div>
								<div className={lightColorText ? styles.countWhite : styles.count}>{contact.votesCast || '0'}</div>
							</div>
						</div>
					}
					<div>{subText ? subText : ''}</div>
				</div>
			</div>
			{showGroupNames && showGroupNameList()}
		</div>
	)
}

export default ContactSummaryLine