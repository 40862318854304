import React, {useState} from 'react';
import styles from './OpenCommunitySubmitted.module.css';
import MessageModal from '../../components/MessageModal';
// import WorkSummary from '../../components/WorkSummary';
import Accordion from '../../components/Accordion';

function OpenCommunitySubmitted(props) {
  const {
    personId,
    removeOpenCommunityEntry,
    setWorkCurrentSelected,
    updateChapterComment,
    openCommunityFull,
    setToModifyRecord
  } = props;

  let localOpenCommunity = openCommunityFull && openCommunityFull.length > 0 && openCommunityFull.filter(m => m.personId === personId);
  const [isShowingModal, setIsShowingModal] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(0)

  const handleRemoveSubmitted = (workId, openCommunityEntryId) => {
    handleAlertClose();
    removeOpenCommunityEntry(personId, workId, openCommunityEntryId)
    setIsShowingModal(false)
  }

  const handleAlertClose = () => setIsShowingModal(false)
  const handleAlertOpen = (deleteIndex) => {
    setIsShowingModal(true)
    setDeleteIndex(deleteIndex)
  }

  return (
    <div className={styles.container}>
      {!localOpenCommunity || localOpenCommunity.length === 0 ?
        <span className={styles.noListMessage}>{`empty list`}<br/><br/></span> : ''}
      {localOpenCommunity && localOpenCommunity.length > 0 && localOpenCommunity.map((s, i) => {
        return (
          <Accordion title={s.title} key={i}>
            {/*isCurrentTitle={s.isCurrentWork} expanded={s.isExpanded}*/}
            {/*      className={styles.accordionTitle}*/}
            {/*      onTitleClick={() => setWorkCurrentSelected(personId, s.workId, s.chapterId_current, s.languageId_current, 'editReview')}*/}
            {/*      removeOpenCommunity={() => handleAlertOpen(i)} modifyOpenCommunity={() => setToModifyRecord(s.openCommunityEntryId)}>*/}
            {/* <WorkSummary summary={s} className={styles.workSummary} showIcons={true} personId={personId}
                         setWorkCurrentSelected={setWorkCurrentSelected} showTitle={false} noShowCurrent={true}
                         labelCurrentClass={styles.labelCurrentClass} indexKey={i}
                         updateChapterComment={updateChapterComment}/> */}
            <MessageModal show={isShowingModal && deleteIndex === i} key={i * 1000} handleClose={handleAlertClose}
                          heading={`Remove this Open Community Entry?`}
                          explain={`Are you sure you want to remove this open community entry?  You will not lose the edits or translations that have been completed.`}
                          isConfirmType={true} onClick={() => handleRemoveSubmitted(s.workId, s.openCommunityEntryId)}/>
          </Accordion>
        );
      })}
    </div>
  );
}

export default OpenCommunitySubmitted