import React from 'react';
import styles from './Checkbox.module.css';
import classes from 'classnames';

function Checkbox(props) {
	const {
    label="", 
    id, 
    disabled=false, 
    position="before", 
    checked=false, 
    onClick, 
    checkboxClass="",
    labelClass="", 
    className="", 
    defaultValue, 
    keyIndex
  } = props;

  return (
    <div className={classes(styles.container, className)} key={keyIndex}>
        {position === "before" ?
            <input 
              type="checkbox" 
              checked={checked} 
              disabled={disabled} 
              onChange={() => {}} 
              defaultValue={defaultValue} 
              id={id} 
              key={keyIndex}
              className={classes(styles.checkboxLeft, checkboxClass)} 
              onClick={disabled ? () => {} : onClick}/> 
            : ''
        }
				<a className={classes(styles.label, labelClass, (disabled ? styles.lowOpacity : styles.labelHover))} onClick={disabled ? () => {} : onClick}>{label}</a>
        {position !== "before" ?
            <input 
              type="checkbox" 
              checked={checked} 
              disabled={disabled} 
              onChange={() => {}} 
              defaultValue={defaultValue} 
              id={id} 
              key={keyIndex}
              className={classes(styles.checkboxRight, checkboxClass)} 
              onClick={disabled ? () => {} : onClick}/> 
            : ''
        }
    </div>
  )
};

export default Checkbox;

