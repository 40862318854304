import React, {useState} from 'react';
import {Link} from 'react-router-dom'
import globalStyles from '../../utils/globalStyles.module.css';
import styles from './GroupWorksView.module.css';
import Accordion from '../../components/Accordion';
import TextDisplay from '../../components/TextDisplay';
import Icon from '../../components/Icon';
import DateMoment from '../../components/DateMoment';
import SelectSingleDropDown from '../../components/SelectSingleDropDown';
import Checkbox from '../../components/Checkbox';
import InputText from '../../components/InputText';
import WorkSummaryLine from '../../components/WorkSummaryLine';
import classes from 'classnames'
import OneFJefFooter from '../../components/OneFJefFooter';

function GroupWorksView(props) {
	const {personId, workSummaries=[], workTypeList=[], group={contacts: [], workSummaries: []} } = props;
	const [chosenWorkType, setChosenWorkType] = useState()
	const [filterWorkName, setFilterWorkName] = useState('')

	let workSummariesFiltered = workSummaries && workSummaries.length > 0 ? [...workSummaries] : []
	if (filterWorkName)
		workSummariesFiltered = workSummariesFiltered && workSummariesFiltered.length > 0 && workSummariesFiltered.filter(m => m.workName.toLowerCase().indexOf(filterWorkName.toLowerCase()) > -1)
	if (chosenWorkType && chosenWorkType !== '0') {
		workSummariesFiltered = workSummariesFiltered && workSummariesFiltered.length > 0 && workSummariesFiltered.filter(m => m.workTypeId === Number(chosenWorkType))
	}

	return (
		<section className={styles.container}>
			<div className={styles.titleLine}>
				<span className={globalStyles.pageTitle}>Group Documents</span>
			</div>
			<div className={styles.formLeft}>
				<TextDisplay label={`Group name`} text={group.groupName} />
				{group.description && <TextDisplay label={`Description`} text={group.description}/>}
				<DateMoment date={group.entryDate} label={'Entry date'} format={'D MMM YYYY'} className={styles.date}/>
			</div>
			<hr/>
			<Link to={`/workAddNew/group/${group.groupId}`} className={styles.menuItem} data-rh={'Add new document'} title={'Add new document'} tabIndex='-1'>
				<Icon pathName={'document0'} premium={true} superscript={'plus'} supFillColor={'forestgreen'} fillColor={'black'} tabIndex='-1'/>
				<div className={styles.link}>Add new document</div>
			</Link>
			<div className={styles.link}>
				- OR - Choose from your current documents
			</div>
			<div className={styles.accordionLimit}>
				<Accordion title={'My documents'}>
					<div className={styles.verticalScroll}>
						<div className={styles.centered}>
							<div className={styles.row}>
								<InputText
									size={"medium"}
									name={"filterWorkName"}
									label={`Filter work name`}
									value={filterWorkName}
									onChange={(event) => setFilterWorkName(event.target.value)} />
								<div>
									<SelectSingleDropDown
										label={`Work type`}
										value={chosenWorkType}
										options={workTypeList || []}
										height={`medium`}
										className={styles.singleDropDown}
										onChange={(event) => setChosenWorkType(event.target.value)}/>
								</div>
							</div>
						</div>
						<div className={styles.works}>
							{workSummariesFiltered && workSummariesFiltered.length > 0 && workSummariesFiltered.map((w, i) =>
								<div className={styles.row} key={i + 'summary'}>
									<Checkbox
										label={``}
										checked={(group.workSummaries && group.workSummaries.length > 0 && group.workSummaries.filter(f => f.workId === w.workId)[0]) || ''}
										onClick={() => props.assignWorkToGroup(personId, w.workId, group.groupId)}/>
									<WorkSummaryLine work={w} nameLength={150} lightColorText lighterBackground keyIndex={i} includeEditors includeDates/>
								</div>
							)}
						</div>
					</div>
				</Accordion>
				<div className={styles.bottomBar}></div>
			</div>
			{!(group.workSummaries && group.workSummaries.length > 0) &&
				<div className={classes(styles.topSpace, styles.noDocuments)}>
					<hr/>
					This group does not have any documents assigned.
				</div>
			}
			<div className={styles.topSpace}>
				{group.workSummaries && group.workSummaries.length > 0 &&
					<div>Documents for this group:</div>
				}
				<hr/>
				{group.workSummaries && group.workSummaries.length > 0 && group.workSummaries.map((m, i) =>
					<div className={styles.row} key={i + 'chosen'}>
						<Checkbox
							label={``}
							checked={true}
							onClick={() => props.assignWorkToGroup(personId, m.workId, group.groupId, true)}/>
						<WorkSummaryLine work={m} nameLength={150} lightColorText keyIndex={i} includeEditors includeDates/>
					</div>
				)}
			</div>
			<OneFJefFooter/>
		</section>
	);
}

export default GroupWorksView;
