import React, {Component} from 'react';
import DataDeletionPolicyView from '../views/DataDeletionPolicyView';
import { connect } from 'react-redux';

const mapStateToProps = (state, props) => {
    return {
    }
};

const bindActionsToDispatch = dispatch => ({
});

const mergeAllProps = (store, actions) => ({
    ...store, ...actions,
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
  mergeAllProps
);

class Container extends Component {

  render() {
    return <DataDeletionPolicyView {...this.props} />
  }
}

export default storeConnector(Container);
