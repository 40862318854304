
import React, {useEffect} from 'react';
import EditorInviteByBulkView from '../views/EditorInviteByBulkView';
import { connect } from 'react-redux';
import * as fromContacts from '../reducers/contacts.js';
import * as actionContacts from '../actions/contacts.js';
import * as actionGroups from '../actions/groups.js';
import * as actionEditorInvitePending from "../actions/editor-invite-pending";

import { selectMe, selectPersonConfig, selectGroups, selectGroupContactsWorks } from '../store.js';
import { useParams } from 'react-router';

// takes values from the redux store and maps them to props
const mapStateToProps = state => {
    let contacts = fromContacts.selectContactsArray(state.contacts);
    let me = selectMe(state);
    const groups = selectGroups(state)

    const groupOptions = groups && groups.length > 0 && groups.reduce((acc, m) => {
      let exists = m.groupId && acc && acc.length > 0 && acc.filter(f => f.id === m.groupId)[0]
      if (!exists && !!m.groupId) {
        let option = {
          id: m.groupId,
          label: m.groupName
        }
        return acc && acc.length > 0 ? acc.concat(option) : [option]
      }
    }, [])

    return {
      group: selectGroupContactsWorks(state),
      personId: me.personId,
      contacts,
      owner_personId: me.personId,
      personConfig: selectPersonConfig(state),
      groupOptions,
      bulkDelimiterOptions: [
        { id: 'tab', label: 'tab' },
        { id: 'comma', label: ', comma' },
        { id: 'semicolon', label: '; semicolon' },
        { id: 'hyphen', label: '- hyphen' },
      ],
      fieldOptions: [
        { id: 'firstName', label: 'first name' },
        { id: 'lastName', label: 'last name' },
        { id: 'fullNameLastFirst', label: 'full name (last name first)' },
        { id: 'fullNameFirstFirst', label: 'full name (first name first)' },
        { id: 'memberId', label: 'internal member id' },
        { id: 'emailAddress', label: 'email address' },
        { id: 'phone', label: 'phone' },
      ],
    }
}

const bindActionsToDispatch = dispatch => ({
  getGroups: (personId) => dispatch(actionGroups.getGroups(personId)),
  setContactCurrentSelected: (personId, contactPersonId, href) => dispatch(actionContacts.setContactCurrentSelected(personId, contactPersonId, href)),
  getContacts: (personId) => dispatch(actionContacts.getContacts(personId)),
  setGroupMembers: (personId, groupId, members) => dispatch(actionGroups.setGroupMembers(personId, groupId, members)),
  getGroupContactsWorks: (personId, groupId) => dispatch(actionGroups.getGroupContactsWorks(personId, groupId)),
  getInvitesPending: (personId) => dispatch(actionEditorInvitePending.getInvitesPending(personId)),
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
);

function Container(props) {
  const { personId, getGroups, getGroupContactsWorks, getContacts } = props
  const params = useParams()

  useEffect(() => {
    if (personId) {
      {
        getGroups(personId)
        getGroupContactsWorks(personId, params.groupChosen)
        getContacts(personId);
      }
    }
  },[personId])

  return <EditorInviteByBulkView {...props} />
}

export default storeConnector(Container);
