import React from 'react'
import styles from './AssessmentPassage.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import ImageDisplay from '../../ImageDisplay'
import AudioDisplay from '../../AudioDisplay'
import classes from 'classnames'
import QuestionLabel from '../../QuestionLabel'

function AssessmentPassage(props) {
  const {
    bigTextDisplay,
    className = "",
    isAuthor,
    question,
    removeQuestionFileOpen,
    removeQuestionRecordingOpen,
  } = props

  return (
    <div className={classes(className, styles.container)}>
      <QuestionLabel label={'Passage'}/>
      <div className={classes(styles.row, styles.questionLine)}>
        <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.sequence)}>{question.sequence}.
        </div>
        <div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.question)}>{question.questionText}</div>
      </div>
      {question.questionRecordingFileUrl &&
        <AudioDisplay src={question.questionRecordingFileUrl} preload={'auto'}
                      controls="controls" className={styles.audioLeftQuestion}
                      isSmall={true} isOwner={question.isOwner}
                      deleteFunction={(event) => removeQuestionRecordingOpen(event, question.assessmentQuestionId, question.questionRecordingUploadId)}/>
      }
      {question.questionFileUploadId &&
        <ImageDisplay linkText={''} url={question.questionFileUrl}
                      isOwner={question.isOwner}
                      deleteFunction={() => removeQuestionFileOpen(question.assessmentQuestionId, question.questionFileUploadId)}/>
      }
      {!isAuthor &&
        <div className={styles.answerLeft}>
          <span className={styles.text}>
            This is intended to be a reading or example of a problem which will be followed by two or more questions.
          </span><br/>
        </div>
      }
    </div>
  )
}

export default AssessmentPassage