import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.css';

import {
  arrayify,
  getChildrenActiveItems,
  getActiveItems,
  isSame
} from './utils';

function Accordion(props) {
  const { className, style, rootTag: Root, noShowExpandAll, children, duration, easing, allowMultiple } = props;
  const [activeItems, setActiveItems] = useState(getActiveItems(children, allowMultiple))

  useEffect(() => {
    if (
      !isSame(
        getChildrenActiveItems(props.children),
        getChildrenActiveItems(children)
      )
    ) {
      setActiveItems(getActiveItems(children, allowMultiple))
    }
  }, [])

  // componentWillReceiveProps({ children, allowMultiple }) {
  //   if (
  //     !isSame(
  //       getChildrenActiveItems(props.children),
  //       getChildrenActiveItems(children)
  //     )
  //   ) {
  //     setState({
  //       activeItems: getActiveItems(children, allowMultiple)
  //     });
  //   }
  // }

  const handleClick = (index) => {
    const {
      allowMultiple,
      children,
      onChange,
      openNextAccordionItem
    } = props;

    // clone active items state array
    let newActiveItems = activeItems.slice(0);

    const position = newActiveItems.indexOf(index);

    if (position !== -1) {
      newActiveItems.splice(position, 1);

      if (openNextAccordionItem && index !== children.length - 1) {
        newActiveItems.push(index + 1);
      }
    } else if (allowMultiple) {
      newActiveItems.push(index);
    } else {
      newActiveItems = [index];
    }
    //setState(newState);
    if (onChange) {
      //onChange(newState);
      setActiveItems(newActiveItems)
    }
  }

  const handleSelectAll = () => {
    const newActiveItems = [];

    arrayify(children).filter((c) => c).forEach((children, index) => {
      newActiveItems.push(index);
    });
    setActiveItems(newActiveItems)
  }

  const handleClearAll = () => {
      setActiveItems([])
  }

  const renderItems = () => {
    if (!children) {
      return null;
    }

    return arrayify(children)
      .filter(c => c)
      .map((item, index) => {
        const {
          props: { disabled, duration: itemDuration, easing: itemEasing }
        } = item;

        const isExpanded = !disabled && activeItems.indexOf(index) !== -1;

        return React.cloneElement(item, {
          duration: itemDuration || duration,
          easing: itemEasing || easing,
          expanded: isExpanded,
          key: index,
          index,
          onClick: handleClick,
          //ref: `item-${index}`
        });
      });
  }


  return (
      <Root>
          {!noShowExpandAll &&
              <div className={styles.selectOrClearAll}>
                  <a onClick={handleSelectAll} className={styles.textLabel}> expand all </a> |
                  <a onClick={handleClearAll} className={styles.textLabel}> collapse all </a>
              </div>
          }
          <div
              className={cx(styles.react_sanfona, className)}
              role="tablist"
              style={style}
            >
              {renderItems()}
          </div>
      </Root>
  );
}

Accordion.defaultProps = {
  activeItems: [0],
  allowMultiple: false,
  duration: 300,
  easing: 'ease',
  rootTag: 'div'
};

Accordion.propTypes = {
  allowMultiple: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  duration: PropTypes.number,
  easing: PropTypes.string,
  onChange: PropTypes.func,
  openNextAccordionItem: PropTypes.bool,
  style: PropTypes.object,
  rootTag: PropTypes.string
};

export default Accordion