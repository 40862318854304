import * as types from '../actions/actionTypes';

export default function (state = [], action) {
	switch (action.type) {
		case types.HTML_CHARACTER_ENTITIES: {
			return action.payload;
		}

		default:
			return state;
	}
}

export const selectHtmlCharacterEntities = (state) => state;

export const selectHtmlCharacterOptions = (state) => {
	let result = []
	state?.forEach(m => {
		let characterCode = m.entityName || m.entityNumber
		let option = {
			id: characterCode,
			label: <span dangerouslySetInnerHTML={{ __html: characterCode + " " + m.description }}></span>,
			entityNumber: characterCode,
			description: m.description,
			tag: m.tag
		}
		result.push(option)
	})
	return result
}
