import React from 'react'
import styles from './QuestionLabel.module.css'

export default ({label}) => {
  return (
    <div>
      <hr className={styles.hrHeight}/>
      <div className={styles.questionType}>{label}</div>
    </div>
  )
}
