import React from 'react';
import styles from './ServicePolicyView.module.css';
import OneFJefFooter from '../../components/OneFJefFooter'
import LogoSlogan from '../../components/LogoSlogan'

function ServicePolicyView() {

	return (
		<div className={styles.container}>
      <div className={styles.mainDiv}>
				<LogoSlogan larger includeOneFJef/>
				<p className={styles.MsoNormal} style={{fontSize: '16pt', marginTop: '40px'}}><b>Service Policy</b></p>
				<br />
				<br />
				<p className={styles.MsoNormal}><b>Last Updated: October 2, 2024</b></p>

				<p clasNames={styles.MsoNormal}><b>1. Introduction</b></p>

				<p clasNames={styles.MsoNormal}>Welcome to one-f Jef, Inc. ('we', 'our', 'us'). This
					Service Policy outlines the terms and conditions under which you may use our
					website and application ('Service'). By accessing or using our Service, you
					agree to comply with and be bound by this Service Policy. If you do not agree
					with these terms, please do not use our Service.</p>

				<p clasNames={styles.MsoNormal}><b>2. Use of Our Service</b></p>

				<p clasNames={styles.MsoNormal}><b>2.1 Eligibility</b></p>

				<p clasNames={styles.MsoNormal}>To use our Service, you must be at least 13 years old. By
					using our Service, you represent and warrant that you meet this age
					requirement.</p>

				<p clasNames={styles.MsoNormal}><b>2.2 Account Registration</b></p>

				<p clasNames={styles.MsoNormal}>Some features of our Service may require you to create an
					account. You agree to provide accurate and complete information during the
					registration process and to update your information as necessary to keep it
					accurate.</p>

				<p clasNames={styles.MsoNormal}><b>2.3 Prohibited Activities</b></p>

				<p clasNames={styles.MsoNormal}>You agree not to engage in any of the following prohibited
					activities:</p>

				<ul style={{ marginTop: '0in' }} type={'disc'}>
					<li clasNames={styles.MsoNormal}><b>Illegal Activities:</b> Using our Service for any
						unlawful purpose or in violation of any applicable laws or regulations.</li>
					<li clasNames={styles.MsoNormal}><b>Unauthorized Access:</b> Attempting to gain
						unauthorized access to our Service, accounts, or networks.</li>
					<li clasNames={styles.MsoNormal}><b>Disruptive Activities:</b> Interfering with or
						disrupting the integrity or performance of our Service.</li>
					<li clasNames={styles.MsoNormal}><b>Abusive Behavior:</b> Harassing, threatening, or
						otherwise causing harm to other users of our Service.</li>
					<li clasNames={styles.MsoNormal}><b>Malware:</b> Uploading or distributing any malware,
						viruses, or harmful code through our Service.</li>
				</ul>

				<p clasNames={styles.MsoNormal}><b>3. Intellectual Property</b></p>

				<p clasNames={styles.MsoNormal}>All content, trademarks, and other intellectual property
					related to our Service are owned by [Your Company Name] or its licensors. You
					may not use, copy, reproduce, modify, distribute, or create derivative works
					based on our content without our prior written permission.</p>

				<p clasNames={styles.MsoNormal}><b>4. Privacy and Data Protection</b></p>

				<p clasNames={styles.MsoNormal}>Your use of our Service is subject to our Privacy Policy,
					which outlines how we collect, use, and protect your personal information.
					Please review our Privacy Policy to understand our practices.</p>

				<p clasNames={styles.MsoNormal}><b>5. Termination</b></p>

				<p clasNames={styles.MsoNormal}>We reserve the right to suspend or terminate your access to
					our Service at our sole discretion, without notice, if we believe you have
					violated this Service Policy or engaged in any prohibited activities.</p>

				<p clasNames={styles.MsoNormal}><b>6. Disclaimers and Limitation of Liability</b></p>

				<p clasNames={styles.MsoNormal}><b>6.1 Disclaimers</b></p>

				<p clasNames={styles.MsoNormal}>Our Service is provided 'as is' and 'as available,' without
					warranties of any kind, either express or implied. We do not warrant that our
					Service will be uninterrupted, error-free, or free from harmful components.</p>

				<p clasNames={styles.MsoNormal}><b>6.2 Limitation of Liability</b></p>

				<p clasNames={styles.MsoNormal}>To the fullest extent permitted by law, [Your Company Name]
					shall not be liable for any indirect, incidental, special, consequential, or
					punitive damages, or any loss of profits or data, arising out of or in
					connection with your use of our Service.</p>

				<p clasNames={styles.MsoNormal}><b>7. Indemnification</b></p>

				<p clasNames={styles.MsoNormal}>You agree to indemnify and hold harmless [Your Company
					Name], its affiliates, officers, directors, employees, and agents from any
					claims, liabilities, damages, losses, or expenses (including reasonable
					attorneys' fees) arising out of or in connection with your use of our Service,
					your violation of this Service Policy, or your infringement of any rights of
					another party.</p>

				<p clasNames={styles.MsoNormal}><b>8. Changes to This Service Policy</b></p>

				<p clasNames={styles.MsoNormal}>We may update this Service Policy from time to time. Any
					changes will be posted on this page with an updated effective date. Your
					continued use of our Service after any changes constitutes your acceptance of
					the revised terms.</p>

				<p clasNames={styles.MsoNormal}><b>9. Governing Law</b></p>

				<p clasNames={styles.MsoNormal}>This Service Policy is governed by and construed in
					accordance with the laws of [Your Jurisdiction], without regard to its conflict
					of law principles.</p>

				<p clasNames={styles.MsoNormal}><b>10. Contact Us</b></p>

				<p clasNames={styles.MsoNormal}>If you have any questions or concerns about this Service
					Policy, please contact us at:</p>

				<ul style={{marginTop: '0in'}} type={'disc'}>
					<li className={styles.MsoNormal}><b>Email:</b> jef@penspring.com</li>
					<li className={styles.MsoNormal}><b>Address:</b> 1920 W. 100 S., Manti, UT  84642</li>
				</ul>
        <OneFJefFooter/>
      </div>
		</div>
	);
}

export default ServicePolicyView;
