import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router'
import {apiHost} from '../../api_host.js'
import axios from "axios"
import styles from './WorkAddView.module.css'
import OneFJefFooter from '../../components/OneFJefFooter'
import WorkAddEditControls from '../../components/WorkAddEditControls'
import MessageModal from '../../components/MessageModal'
import ButtonWithIcon from '../../components/ButtonWithIcon'
import WorkAddDecisions from '../../components/WorkAddDecisions'
import TextImageUpload from '../../components/TextImageUpload'
import TextImageCamera from '../../components/TextImageCamera'
import { guidEmpty, generateGuid } from "../../utils/GuidValidate"
import { useMediaQuery } from "react-responsive"
import WorkAddHasChapters from '../../components/WorkAddHasChapters'
import WorkAddOriginatingEditor from '../../components/WorkAddOriginatingEditor';
import WorkAddExistingFile from '../../components/WorkAddExistingFile';
import { Line } from "rc-progress"

let intervalProgressId

function WorkAddView(props) {
	const {
    personId,
		isNewUser,
		languageList = [],
		workTypeList = [],
		groupChosen,
    contacts,
    incomingFilePenspringChapter, //If this is a call from workSettings to add another chapter with a known workId sent in to pick up the entire workSummary
    setTextImageUpload,
    textImageUploads,
    textImageGroups,
    getWorkSegmentProgress,
    workSegmentUploadProgress
	} = props;

	const params = useParams()
	const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

	const [isShowingSectionInfo, setIsShowingSectionInfo] = useState(false)
  const [fileLocal, setFileLocal] = useState()
  const [fileGoogle, setFileGoogle] = useState({})
  const [filePenspringExisting, setFilePenspringExisting] = useState() //This is just a work record
  const [filePenspringChapter, setFilePenspringChapter] = useState() //This is just a work record
	const [submitSuccess, setSubmitSuccess] = useState(false)
	const [isShowingChooseEntry, setIsShowingChooseEntry] = useState(false)
  const [work, setWork] = useState({workTypeId: 1, languageId: 1, internalId: ''})
  const [entryError, setEntryError] = useState('')
  const [workNameError, setWorkNameError] = useState('')
  const [workTypeIdError, setWorkTypeIdError] = useState('')
  const [languageIdError, setLanguageIdError] = useState('')
  const [chapterNbrError, setChapterNbrError] = useState('')
  const [chapterNameError, setChapterNameError] = useState('')
  const [authorFirstNameError, setAuthorFirstNameError] = useState('')
  const [authorPhoneError, setAuthorPhoneError] = useState('')
  const [authorEmailAddressError, setAuthorEmailAddressError] = useState('')
  const [authorTextError, setAuthorTextError] = useState('')
  const [textImageGroupId, setTextImageGroupId] = useState()
  const [disableSubmitButton, setDisableSubmitButton] = useState()
  const [progressInitial, setProgressInitial] = useState()
  const [progressSplit, setProgressSplit] = useState()
  const [tooLargeError, setTooLargeError] = useState()

  //Main sections to show or hide.
  const [hasChapters, setHasChapters] = useState()
  const [hasOriginatingEditor, setHasOriginatingEditor] = useState(false)

  //Sub sections to show or hide
  const [googleDriveOpen, setGoogleDriveOpen] = useState(false)
  const [penspringDriveChaptersOpen, setPenspringDriveChaptersOpen] = useState(false)
  const [penspringDriveExistingOpen, setPenspringDriveExistingOpen] = useState(false)
  const [moreInfo, setMoreInfo] = useState(false)
  const [showWorkOwners, setShowWorkOwners] = useState(false)

  //Decision options
  const [fileType, setFileType] = useState('WORDPROCESSOR')
  const [authorEditor, setAuthorEditor] = useState('AUTHOR')
  const [chapters, setChapters] = useState('SINGLEFILE')
  const [documentType, setDocumentType] = useState('WRITING')
  const [editOrTranslation, setEditOrTranslation] = useState('NATIVEEDIT')
  const [textStyleSettings, setTextStyleSettings] = useState('')  

  useEffect(() => {
    return () => {
      clearState()
    }
  }, [])

  useEffect(() => {
    if (workSegmentUploadProgress) {
      const warningTooLarge = workSegmentUploadProgress.warningTooLarge
      if (warningTooLarge && !tooLargeError) setTooLargeError('The file is too large.\n Please consider smaller sections or chapters in order to manage the content for editing.\nYour text has been limited to 200 paragraphs.')
      let progressValue = Math.round(workSegmentUploadProgress.initialProgress / (workSegmentUploadProgress.initialTotal || 0) * 100)
      progressValue = progressValue > 100 ? 100 : progressValue
      setProgressInitial(progressValue)
      let splitProgress = Math.round(workSegmentUploadProgress.splitProgress / (workSegmentUploadProgress.initialTotal || 0) * 100)
      if (splitProgress >= 100) {
        splitProgress = 100
        clearInterval(intervalProgressId);
        intervalProgressId = null
      }
      setProgressSplit(splitProgress)
    }
  }, [workSegmentUploadProgress])

  useEffect(() => {
    if (fileLocal && !filePenspringChapter) { //Don't replace the name if it was chosen that chapters would be entered for an existing penspring file. We want the penspring file to remain the same with different chapter names.
      setWork({ ...work, workName: fileLocal?.path.lastIndexOf('.') > -1 ? fileLocal?.path.substring(0, fileLocal?.path.lastIndexOf('.')) : fileLocal?.path })
    }
  }, [fileLocal])

  useEffect(() => {
    if (incomingFilePenspringChapter && incomingFilePenspringChapter.workId !== guidEmpty && Object.keys(incomingFilePenspringChapter).length > 0 && !(filePenspringChapter && filePenspringChapter.workName)) {
      handleSetFilePenspringChapter(incomingFilePenspringChapter)
      setPenspringDriveChaptersOpen(true)
      setHasChapters(true)
    }
  }, [incomingFilePenspringChapter])

  useEffect(() => {
    if (fileType === 'BLANKFILE') handleSetStartBlankFile(true)
  }, [fileType])
  
  useEffect(() => {
    const newWorkType = workTypeList?.length > 0 && workTypeList.filter(m => m.code === documentType)[0]
    if (newWorkType) {
      setWork({ ...work, workTypeId: newWorkType.id })
    }
  }, [documentType, workTypeList, authorEditor])

  useEffect(() => {
    if (editOrTranslation === 'TRANSLATION') setMoreInfo(true)
  }, [editOrTranslation])

  useEffect(() => {
    if (chapters === 'EXISTINGPENSPRING') {
      setPenspringDriveChaptersOpen(true);
    } else {
      setPenspringDriveChaptersOpen(false);
    }
  }, [chapters])

  const clearState = () => {
    setTextImageGroupId(null)
    setIsShowingSectionInfo(false)
    setFileLocal(null)
    setFileGoogle({})
    setFilePenspringExisting(null)
    setFilePenspringChapter(null)
    setSubmitSuccess(false)
    setIsShowingChooseEntry(false)
    setWork({ workTypeId: 1, languageId: 1, internalId: '' })
    setEntryError('')
    setWorkNameError('')
    setWorkTypeIdError('')
    setLanguageIdError('')
    setChapterNbrError('')
    setChapterNameError('')
    setAuthorFirstNameError('')
    setAuthorPhoneError('')
    setAuthorEmailAddressError('')
    setAuthorTextError('')
    setDisableSubmitButton(null)
    setProgressInitial(null)
    setProgressSplit(null)
    setTooLargeError(null)

    //Main sections to show or hide.
    setHasChapters(null)
    setHasOriginatingEditor(false)

    //Sub sections to show or hide
    setGoogleDriveOpen(false)
    setPenspringDriveChaptersOpen(false)
    setPenspringDriveExistingOpen(false)
    setMoreInfo(false)
    setShowWorkOwners(false)
  }

  const showFileChoiceSection = () => {
    return fileType === 'WORDPROCESSOR'
  }

  const showTextImageChoiceSection = () => {
    return fileType === 'UPLOADTEXTIMAGE'
  }

  const showCameraImageChoiceSection = () => {
    return fileType === 'CAMERATEXTIMAGE'
  }

  const showOriginatingEditorSection = () => {
    return authorEditor === 'EDITOR'
  }

  const showChapterChoiceSection = () => {
    return chapters === 'NEWPENSPRING' || chapters === 'EXISTINGPENSPRING'
  }

	const handleSectionInfoClose = () => setIsShowingSectionInfo(false)
	const handleChooseEntryClose = () => setIsShowingChooseEntry(false)

  const handleSetFileLocal = (incomingFile) => {
    //Check the file extension. If this is a code-like file, set the workTypeId.
    if (!fileLocal) {
      const extension = incomingFile.name && incomingFile.name.length > 0 && incomingFile.name.substring(incomingFile.name.lastIndexOf('.'))
      if (['.html','.css','.js','.jsx','.py','.java','.cs','.c','.cpp','.php','.rb','.swift','.ts','.tsx','.xml','.json','.sql','.md'].indexOf(extension) > -1) {
        let workType = workTypeList.filter(m => m.code === 'PROGRAMMING')[0]
        const workTypeId = workType ? workType.id : 0
        if (workTypeId) setWork({...work, workTypeId})
      }
      setFileLocal(incomingFile)
      setFileGoogle({})
      setFilePenspringExisting()
    }
  }

  const handleSetFileGoogle = (incomingFile) => {
    if (!incomingFile) {  //Then the intention here is to unchoose the previously chosen Google file to start again.
      setFileGoogle({})  
      setWork({ ...work, workName: '' })
    } else {
      const { blob, file } = incomingFile
      setFileGoogle({ blob, file })
      //setTimeout(() => setWork({ ...work, workName: file.name.lastIndexOf('.') > -1 ? file.name.substring(0, file.name.lastIndexOf('.')) : file.name }), 500)
      setWork({ ...work, workName: file.name.lastIndexOf('.') > -1 ? file.name.substring(0, file.name.lastIndexOf('.')) : file.name })
    }
    setFileLocal(null)
    setFilePenspringExisting(null)
    setPenspringDriveExistingOpen(false)
  }

  const handleSetFilePenspringChapter = (incomingFilePenspringChapter) => {
    setFileGoogle({})
    setFileLocal(null)
    setGoogleDriveOpen(false)
    setPenspringDriveChaptersOpen(false)

    if (incomingFilePenspringChapter && incomingFilePenspringChapter !== guidEmpty) {
      let newWork = incomingFilePenspringChapter ? {...incomingFilePenspringChapter} : {}
      const newChapter = filePenspringChapter ? {...filePenspringChapter.newChapter} : {} //Preserve this previous setting.
      const originatingEditor = filePenspringChapter ? {...filePenspringChapter.originatingEditor} : {} //Preserve this previous setting.
      newWork.newChapter = newChapter
      newWork.originatingEditor = originatingEditor
      setFilePenspringChapter(newWork)
      setWork(newWork)
    } else {
      setFilePenspringChapter(null)
      setWork({  //How does this differ from filePenspingChapter?  Or how did this get confused with the same?
        workId: '',
        personId,
        workTypeId: 1,
        workName: '',
        languageId: 1,
        chapters: [],
        description: '',
        dueDate: '',
        activeDate: '',
        internalId: '',
        workOwners: '',
        newChapter: filePenspringChapter ? { ...filePenspringChapter.newChapter} : {}, //Preserve this previous setting.
        originatingEditor: filePenspringChapter ? { ...filePenspringChapter.originatingEditor} : {}, //Preserve this previous setting.
      })
    }
  }

  const handleSetFilePenspringExisting = (filePenspringExisting) => {
    setFileGoogle({})
    setFileLocal(null)
    setFilePenspringExisting(filePenspringExisting)
    setGoogleDriveOpen(false)
    setPenspringDriveExistingOpen(false)
  }

  const handleSetStartBlankFile = (value, isHasOriginatingEditor) => {
    if (!isHasOriginatingEditor) {
      setFileGoogle(null)
      setFilePenspringChapter(null)
      setFileLocal(null)
      setGoogleDriveOpen(false)
      setPenspringDriveChaptersOpen(false)
      setWork({
        workId: '',
        personId,
        workTypeId: 1,
        workName: '',
        languageId: 1,
        chapters: [],
        description: '',
        dueDate: '',
        activeDate: '',
        internalId: '',
        workOwners: '',
        newChapter: {},
        originatingEditor: {}
      })
    }
  }

  const processForm = (event) => {
		event.stopPropagation()
		event.preventDefault()
    const authorTextDiv = document.getElementById('authorText')
		let fileData = new FormData();
		let newEntryError = ''
    if (fileLocal) fileData.append("file", fileLocal);
    if (fileGoogle && fileGoogle.file) fileData.append("file", fileGoogle.blob, fileGoogle.file.name);
		if (!work.workName) {
			setWorkNameError("Please enter a document name")
			newEntryError += "Please enter a document name</br>"
		}

    if (!work.workTypeId || work.workTypeId === '0') {
			setWorkTypeIdError("Please choose a document Type")
      newEntryError += "Please choose a document Type</br>"
		}

    if (!work.languageId || work.languageId === '0') {
			setLanguageIdError("Please choose a native text language")
			newEntryError += "Please choose a native text language</br>"
		}

    if (hasChapters || (filePenspringChapter && filePenspringChapter.workId !== guidEmpty)) {
      if (!(filePenspringChapter.newChapter && filePenspringChapter.newChapter.chapterNbr)) {
        setChapterNbrError("Please choose a chapter number")
        newEntryError += "Please choose a chapter number</br>"
      }

      if (!(filePenspringChapter.newChapter && filePenspringChapter.newChapter.name)) {
        setChapterNameError("Please choose a chapter name")
        newEntryError += "Please choose a chapter name</br>"
      }
    }

    if (hasOriginatingEditor) {
      if (!work.originatingEditor.authorFirstName) {
        setAuthorFirstNameError("Please enter the author's first name")
        newEntryError += "Please enter the author's first name</br>"
      }
      if (!work.originatingEditor.authorEmailAddress) {
        setAuthorEmailAddressError("Please enter the author's email address")
        newEntryError += "Please enter the author's email address</br>"
      }

      if (!(authorTextDiv && authorTextDiv.innerHTML) && (!fileLocal || Object.keys(fileLocal).length === 0) && (!fileGoogle || Object.keys(fileGoogle).length === 0)
            && !(textImageUploads && textImageUploads.length > 0) && !(textImageGroupId && textImageGroupId !== guidEmpty)) {
        setAuthorTextError("Please choose a file or enter the author's text")
        newEntryError += "Please choose a file or enter the author's text</br>"
      }
    } else {
      if (!fileLocal && !(fileGoogle && fileGoogle.file && fileGoogle.file.id) && fileType !== 'BLANKFILE' 
          && !(textImageUploads && textImageUploads.length > 0) && !(textImageGroupId && textImageGroupId !== guidEmpty)
          && !filePenspringExisting) {

        newEntryError += "Please choose a file to upload or to start blank</br>"
      }
    }

		if (newEntryError) {
			setEntryError(newEntryError)
		} else {
      setSubmitSuccess(true)
      fileData.append('personId', personId);
      fileData.append('workId', filePenspringChapter && work.workId ? work.workId : props.workId ? props.workId : guidEmpty);
      fileData.append('workName', work.workName);
      fileData.append('workTypeId', work.workTypeId);
      fileData.append('languageId', work.languageId || 1);
      fileData.append('description', work.description || '');
      fileData.append('dueDate', work.dueDate || '');
      fileData.append('activeDate', work.activeDate || '');
      fileData.append('internalId', work.internalId || '');
      fileData.append('workOwners', work.workOwners && work.workOwners.lenth > 0 ? work.workOwners.join(',') : '');
      fileData.append('workFolderId', params.workFolderId || guidEmpty);
      fileData.append('groupId', params.groupId || guidEmpty);
      fileData.append('hasChapter', hasChapters);
      fileData.append('chapterNbr', work?.newChapter?.chapterNbr || '');
      fileData.append('chapterName', work?.newChapter?.name || '');
      fileData.append('hasOriginatingEditor', hasOriginatingEditor || authorEditor === 'EDITOR');
      fileData.append('authorPersonId', work?.originatingEditor?.authorPersonId || guidEmpty);
      fileData.append('authorFirstName', work?.originatingEditor?.authorFirstName || '');
      fileData.append('authorLastName', work?.originatingEditor?.authorLastName || '');
      fileData.append('authorEmailAddress', work?.originatingEditor?.authorEmailAddress || '');
      fileData.append('authorPhone', work?.originatingEditor?.authorPhone || '');
      fileData.append('authorText', authorTextDiv ? authorTextDiv.innerHTML : ''); //work?.originatingEditor?.authorText || '');
      fileData.append('textImageGroupId', textImageGroupId);
      fileData.append('filePenspringExistingWorkId', filePenspringExisting?.workId);
      fileData.append('textStyleGroupId', textStyleSettings);
      fileData.append('discussionMinPost', work.discussionMinPost);
      fileData.append('discussionMinComment', work.discussionMinComment);
      fileData.append('discussionWordCount', work.discussionWordCount);
      const randomGuid = generateGuid()
      fileData.append('progressIdentifierId', randomGuid);

      intervalProgressId = setInterval(() => getWorkSegmentProgress(randomGuid), 1000)

      axios.post(`${apiHost}ebi/work/upload`,  //Help ToDo: This could be secure with a token bearer.
        fileData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem("authToken")}`
          }
        })
        .then(response => {
          clearInterval(intervalProgressId)
          clearState()
          navigate(`/editReview/${response.data.workId}/${response.data.chapterId_current}/${response.data.languageId_current}`) //ToDo the languageId needs to be sent into editReview but I don't think that this page is used.
        })
      //.catch(error => { console.l og('request failed', error); });
      }
	}

	const handleWorkName = (value) => {
		setWork({...work, workName: value});
		setWorkNameError('')
	}

	const handleWorkTypeId = (value) => {
    setWork({ ...work, workTypeId: value });
		setWorkTypeIdError('')
	}

	const handleLanguageId = (value) => {
    setWork({ ...work, languageId: value });
		setLanguageIdError('')
	}

  const handleNewChapter = (event) => {
    const value = event.target.value
    const name = event.target.id
    const newWork = filePenspringChapter ? { ...filePenspringChapter } : {}
    const newChapter = newWork && newWork.newChapter ? { ...newWork.newChapter, [name]: value } :  {[name]: value} 
    setFilePenspringChapter({...newWork, newChapter})
  }

  const handleAuthor = (event) => {
    let value = event.target.value
    const name = event.target.id
    if (name === 'authorEmailAddress') value = value.replace(/ /g, '')
    const newAuthor = { ...work.originatingEditor, [name]: value }
    setWork({ ...work, originatingEditor: newAuthor })
  }

  const handleSetHasChapters = (value) => {
    if (!value) {  //If we turn off the 'hasChapters' checkbox, then clear the chapter sequence and name.
      setWork({ ...work, newChapter: {chapterNbr: '', name: ''} })  
      setFilePenspringChapter(null)
    }
    setHasChapters(value)
  }

  const handleSetHasOriginatingEditor = (value) => {
    handleSetStartBlankFile(false, true)
    setHasOriginatingEditor(value)
    if (!value) setWork({ ...work, originatingEditor: {} })
  }

	return (
    <div className={styles.backgroundImage}>
      <div className={styles.container}>
        <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
          <div className={styles.pageTitle}>
            {isNewUser ? `Add Your First Document` : groupChosen ? 'Add a New Assignment' : 'Add a New Document'}
          </div>
          <div className={isMobile ? styles.column : styles.row}>
            <WorkAddDecisions 
              authorEditor={authorEditor} setAuthorEditor={setAuthorEditor}
              chapters={chapters} setChapters={setChapters}
              documentType={documentType} setDocumentType={setDocumentType}
              editOrTranslation={editOrTranslation} setEditOrTranslation={setEditOrTranslation} 
              fileType={fileType} setFileType={setFileType}
              handleSetHasOriginatingEditor={handleSetHasOriginatingEditor}
              isMobile={isMobile}
              textStyleSettings={textStyleSettings} setTextStyleSettings={setTextStyleSettings} {...props}/>
            <div>
              {showTextImageChoiceSection() &&
                <div className={styles.background}>
                  <TextImageUpload 
                    setDisableSubmitButton={setDisableSubmitButton}
                    setTextImageGroupId={setTextImageGroupId}
                    setTextImageUpload={setTextImageUpload} 
                    textImageGroupId={textImageGroupId} 
                    textImageGroups={textImageGroups} 
                    textImageUploads={textImageUploads} 
                    {...props} />
                </div>
              }
              {showCameraImageChoiceSection() &&
                <div className={styles.background}>
                  <TextImageCamera
                    setDisableSubmitButton={setDisableSubmitButton}
                    setTextImageGroupId={setTextImageGroupId}
                    setTextImageUpload={setTextImageUpload}
                    textImageGroupId={textImageGroupId}
                    textImageGroups={textImageGroups}
                    textImageUploads={textImageUploads}
                    {...props} />
                  </div>
              }
              <form method="post" encType="multipart/form-data">
                {showFileChoiceSection() && 
                  <div className={styles.background}>
                    <WorkAddExistingFile
                      fileGoogle={fileGoogle}
                      fileLocal={fileLocal}
                      filePenspring={filePenspringExisting}
                      googleDriveOpen={googleDriveOpen}
                      handleSetFileGoogle={handleSetFileGoogle}
                      handleSetFileLocal={handleSetFileLocal}
                      handleSetPenspringFile={handleSetFilePenspringExisting}
                      includeGoogleOr
                      includePenspringOr
                      isMobile={isMobile}
                      penspringDriveOpen={penspringDriveExistingOpen}
                      setGoogleDriveOpen={setGoogleDriveOpen}
                      setPenspringDriveOpen={setPenspringDriveExistingOpen}
                      {...props}
                    />
                  </div>
                }
                {showOriginatingEditorSection() && 
                  <div className={styles.background}>
                    <WorkAddOriginatingEditor
                      authorEmailAddressError={authorEmailAddressError} 
                      authorFirstNameError={authorFirstNameError} 
                      authorPhoneError={authorPhoneError} 
                      authorTextError={authorTextError}
                      contacts={contacts}
                      file={fileLocal}
                      fileGoogle={fileGoogle}                
                      fileType={fileType}
                      handleAuthor={handleAuthor}
                      handleSetHasOriginatingEditor={handleSetHasOriginatingEditor}
                      hasOriginatingEditor={hasOriginatingEditor}
                      isMobile={isMobile}
                      personId={personId}
                      setAuthorEmailAddressError={setAuthorEmailAddressError}
                      setAuthorPhoneError={setAuthorPhoneError} 
                      setWork={setWork}
                      work={work}
                    />
                  </div>
                }
                {showChapterChoiceSection() && 
                  <div className={styles.background}>
                    <WorkAddHasChapters
                      chapterNameError={chapterNameError}
                      chapterNbrError={chapterNbrError}
                      chapters={chapters}
                      filePenspring={filePenspringChapter} 
                      handleNewChapter={handleNewChapter} 
                      handleSetHasChapters={handleSetHasChapters} 
                      hasChapters={hasChapters} 
                      isMobile={isMobile}
                      penspringDriveOpen={penspringDriveChaptersOpen} 
                      setPenspringDriveOpen={setPenspringDriveChaptersOpen}
                      setWork={handleSetFilePenspringChapter} 
                      work={filePenspringChapter} 
                      {...props} />
                  </div>
                }
                <div className={styles.background}>
                  <WorkAddEditControls
                    chapters={chapters}
                    documentType={documentType}
                    filePenspringChapter={filePenspringChapter}
                    handleLanguageId={handleLanguageId}
                    handleWorkName={handleWorkName}
                    handleWorkTypeId={handleWorkTypeId}
                    languageIdError={languageIdError}
                    languageList={languageList}
                    moreInfo={moreInfo}
                    setMoreInfo={setMoreInfo}
                    setShowWorkOwners={setShowWorkOwners}
                    setWork={setWork}
                    showWorkOwners={showWorkOwners}
                    work={work}
                    workNameError={workNameError}
                    workTypeIdError={workTypeIdError}
                    workTypeList={workTypeList}
                  />
                </div>
              </form>
              <div className={styles.buttonsCenter}>
                <div className={styles.buttonPosition}>
                  <span className={styles.cancelButton} onClick={() => { clearInterval(intervalProgressId); clearState(); navigate(-1); }}>
                    Cancel
                  </span>
                  <ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={processForm} submitSuccess={submitSuccess} disabled={disableSubmitButton} />
                </div>
              </div>
              {tooLargeError &&
                <div className={styles.tooLargeError}>
                  {tooLargeError}
                </div>
              }
              {!!progressInitial &&
                <div>
                  <div className={styles.rowLow}>
                    File upload:
                    <div className={styles.percentSpace}>{Math.round(progressInitial || 0)}<sup>%</sup></div>
                  </div>
                  <div>
                    <Line percent={progressInitial} strokeWidth="1" strokeColor="#ecb86a" className={isMobile ? styles.mobileLine : ''} />
                  </div>
                </div>
              }
              {!!progressSplit &&
                <div>
                  <div className={styles.rowLow}>
                    File processing
                    <div className={styles.percentSpace}>{Math.round(progressSplit || 0)}<sup>%</sup></div>
                  </div>
                  <div>
                    <Line percent={progressSplit} strokeWidth="1" strokeColor="#ecb86a" className={isMobile ? styles.mobileLine : ''} />
                  </div>
                </div>
              }
            </div>
          </div>
          <OneFJefFooter/>
          <MessageModal show={isShowingSectionInfo} handleClose={handleSectionInfoClose} heading={`Do you have Sections or Chapters?`}
                        showSectionInfo={true}
                        explain={`You can either load your entire document and then choose to split it up by section.
                                  Or, you can upload your first section/chapter here and add additional sections or chapters with the section menu options.
                                  You can always reorder the sequence of your sections and chapters.`}
                        onClick={handleSectionInfoClose}/>
          <MessageModal show={isShowingChooseEntry} handleClose={handleChooseEntryClose} heading={`Choose Entry Type`}
                        explain={`Please choose how you want to enter your data. You can choose to start writing or you can upload a file.`}
                        onClick={handleChooseEntryClose}/>
          <MessageModal displayTempMessage
                        show={entryError}
                        setEntryError={setEntryError}
                        explain={entryError}
                        handleClose={() => setEntryError('')}
                        heading={`Entry Error!`}
                        onClick={() => setEntryError('')}/>
        </div>
      </div>
    </div>
	)
}

export default WorkAddView
