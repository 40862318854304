import React from 'react'
import './CheckboxToggle.css'
import styles from './CheckboxToggle.module.css';
import classes from 'classnames';

function CheckboxToggle(props) {
  const {
    label = "",
    id,
    disabled = false,
    position = "before",
    checked = false,
    onClick,
    checkboxClass = "",
    labelClass = "",
    className = "",
    defaultValue,
    keyIndex
  } = props;

  return (
    <div className="checkbox-wrapper-55" key={keyIndex}>
      {position === "before" ?
        <label className="rocker rocker-small">
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={() => { }}
            defaultValue={defaultValue}
            id={id}
            key={keyIndex}
            onClick={disabled ? () => { } : onClick} />
          <span className="switch-left">Yes</span>
          <span className="switch-right">No</span>
        </label>
        : ''
      }
      <a className={classes(styles.label, labelClass, (disabled ? styles.lowOpacity : styles.labelHover))} onClick={disabled ? () => { } : onClick}>{label}</a>
      {position !== "before" ?
        <label className="rocker rocker-small">
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={() => { }}
            defaultValue={defaultValue}
            id={id}
            key={keyIndex}
            onClick={disabled ? () => { } : onClick} />
          <span className="switch-left">Yes</span>
          <span className="switch-right">No</span>
        </label>
        : ''
      }
    </div>
  )
}

export default CheckboxToggle
