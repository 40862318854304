import * as types from '../actions/actionTypes';

export default function (state = [], action) {
	switch (action.type) {
		case types.GROUPS_INIT: {
			return action.payload;
		}

		case types.GROUPS_DELETE: {
			const groupId = action.payload
			let newState = state && state.length > 0 ? [...state] : []
			return newState.filter(m => m.groupId !== groupId);
		}

		default:
			return state;
	}
}

export const selectGroups = (state) => state;
