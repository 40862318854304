import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { apiHost } from '../../../api_host.js'
import styles from './AssessmentLearnerView.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import AssessIncompleteModal from '../../../components/Assessment/AssessIncompleteModal'
import MessageModal from '../../../components/MessageModal'
import TextDisplay from '../../../components/TextDisplay'
import LinkDisplay from '../../../components/LinkDisplay'
import FileUploadModal from '../../../components/FileUploadModal'
import AssessmentTrueFalse from '../../../components/Assessment/AssessmentTrueFalse'
import AssessmentMultipleChoice from '../../../components/Assessment/AssessmentMultipleChoice'
import AssessmentMultipleAnswer from '../../../components/Assessment/AssessmentMultipleAnswer'
import AssessmentEssay from '../../../components/Assessment/AssessmentEssay'
import AssessmentPassage from '../../../components/Assessment/AssessmentPassage'
import AssessmentPictureAudio from '../../../components/Assessment/AssessmentPictureAudio'
import AssessmentSingleEntry from '../../../components/Assessment/AssessmentSingleEntry'
import AssessmentFillBlank from '../../../components/Assessment/AssessmentFillBlank'
import AssessmentMatching from '../../../components/Assessment/AssessmentMatching'
import ButtonWithIcon from '../../../components/ButtonWithIcon'
import BreadCrumb from '../../../components/BreadCrumb'
import OneFJefFooter from '../../../components/OneFJefFooter'
import classes from 'classnames'
import { useMediaQuery } from "react-responsive"

function AssessmentLearnerView(props) {
  const {
    addOrUpdateAssessmentAnswer,
    assessment,
    assessmentId,
    assessmentQuestions,
    assessmentQuestionsInit,
    assignmentId,
    correctAssessment,
    createWorkInAssessment,
    isAuthor,
    personId,
    removeAssessmentQuestionFileUpload,
    removeLearnerAnswerFile,
    removeLearnerRecordingOpen,
    reorderAssessmentQuestions,
    listLevelGeneral,
    updateAssessmentLocalAnswer,
  } = props

  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  
  const [assessmentQuestionId, setAssessmentQuestionId] = useState()
  const [breadCrumb, setBreadCrumb] = useState()
  const [currentIndex, setCurrentIndex] = useState()
  const [deleted_fileUploadId, setDeleted_fileUploadId] = useState()
  const [fileUploadId, setFileUploadId] = useState()
  const [isInit, setIsInit] = useState()
  const [isRecordComplete, setIsRecordComplete] = useState()
  const [isShowingFileUpload, setIsShowingFileUpload] = useState()
  const [isShowingModal_incomplete, setIsShowingModal_incomplete] = useState()
  const [isShowingModal_removeFile, setIsShowingModal_removeFile] = useState()
  const [isShowingModal_removeFileUpload, setIsShowingModal_removeFileUpload] = useState()
  const [notAnswered, setNotAnswered] = useState()

  useEffect(() => {
    localStorage.setItem('preEditReviewPage', location.pathname)
  }, [])

  useEffect(() => {
    if (assessment?.assessmentId && assessment?.oneAtAtimeView && !isInit && assessmentQuestions?.length > 0) {
      setIsInit(true)
      setCurrentIndex(1)
      showOneAtATimeView(1)
      handlSetBreadCrumb()
    }
  }, [assessment, assessmentQuestions])

  const processForm = () => {
    //Check to see if all of the answers are complete.  If not, send a message that will explain which ones are not answered yet.
    let notAnswered = []
    assessmentQuestions?.length > 0 && assessmentQuestions.forEach(m => {
      if (!m.learnerAnswer && m.questionTypeCode !== 'PASSAGE') {
        let incomplete = m.sequence + ' - ' + (m.questionText.length > 35 ? m.questionText.substring(0, 35) + '...' : m.questionText)
        notAnswered = notAnswered ? notAnswered.concat(incomplete) : [incomplete]
      }
    })

    if (notAnswered && notAnswered.length > 0) {
      setNotAnswered(notAnswered)
      handleIncompleteOpen()
    } else {
      const runFunction = () => navigate(`/assessmentCorrect/${assignmentId}/${assessment.assessmentId}/${personId}`)
      correctAssessment(personId, personId, assessment.assessmentId, assignmentId, runFunction) //In this case, the personId and studentPersonId are the same.
    }
  }

  const handleFileUploadOpen = (assessmentQuestionId) => {
    setIsShowingFileUpload(true)
    setAssessmentQuestionId(assessmentQuestionId)
  }

  const handleFileUploadClose = () => setIsShowingFileUpload(false)
  const handleSubmitFile = () => {
    handleFileUploadClose()
    assessmentQuestionsInit(personId, personId, assessmentId, assignmentId)
  }

  const recallAfterFileUpload = () => {
    assessmentQuestionsInit(personId, personId, assessmentId, assignmentId)
  }

  const fileUploadBuildUrl = (title) => {
    return `${apiHost}ebi/assessmentQuestion/fileUpload/` + personId + `/` + assessmentId + `/` + assessmentQuestionId + `/` + encodeURIComponent(title)
  }

  const handleRemoveFileUploadOpen = (assessmentQuestionId, fileUpload) => {
    setIsShowingModal_removeFileUpload(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setFileUploadId(fileUpload)
  }
  const handleRemoveFileUploadClose = () => setIsShowingModal_removeFileUpload(false)
  const handleRemoveFileUpload = () => {
    removeAssessmentQuestionFileUpload(personId, assessmentQuestionId, fileUploadId)
    handleRemoveFileUploadClose()
  }

  const handleIncompleteOpen = () => setIsShowingModal_incomplete(true)
  const handleIncompleteClose = () => setIsShowingModal_incomplete(false)
  const handleIncompleteContinue = () => {
    let runFunction = () => navigate(`/assessmentCorrect/${assignmentId}/${assessmentId}/${personId}`)
    correctAssessment(personId, personId, assessmentId, assignmentId, runFunction) //In this case, the personId and studentPersonId are the same.
    handleIncompleteClose()
  }

  const reorderSequence = (assessmentQuestionId, event) => {
    reorderAssessmentQuestions(personId, assessmentQuestionId, event.target.value)
  }

  const handleRemoveFileOpen = (assessmentQuestionId, fileUploadId) => {
    setIsShowingModal_removeFile(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setFileUploadId(fileUploadId)
  }
  const handleRemoveFileClose = () => setIsShowingModal_removeFile(false)
  const handleRemoveFile = () => {
    removeLearnerAnswerFile(personId, assessmentQuestionId, fileUploadId)
    handleRemoveFileClose()
    setDeleted_fileUploadId(fileUploadId)
  }

  const showOneAtATimeView = (incomingIndex) => {
    hideAllViews()
    let nextIndex = incomingIndex ? incomingIndex : currentIndex
    if (document.getElementById(`question${nextIndex}`)) {
      document.getElementById(`question${nextIndex}`).style.display = 'inline'
      document.getElementById(`question${nextIndex}`).style.visibility = 'visible'
    }
  }

  const hideAllViews = () => {
    assessmentQuestions?.length > 1 && assessmentQuestions.forEach((m, i) => {
      if (document.getElementById(`question${i + 1 * 1}`)) {
        document.getElementById(`question${i + 1 * 1}`).style.display = 'none'
        document.getElementById(`question${i + 1 * 1}`).style.visibility = 'hidden'
      }
    })
  }

  const setNextIndexUpOrDown = (upOrDown) => {
    let nextIndex = currentIndex + upOrDown * 1
    setCurrentIndex(nextIndex)
    showOneAtATimeView(nextIndex)
    handlSetBreadCrumb()
  }

  const setNextIndexJump = (index) => {
    setCurrentIndex(index)
    showOneAtATimeView(index)
    handlSetBreadCrumb(index)
  }

  const handlSetBreadCrumb = (incomingIndex) => {
    let breadCrumb = []
    assessmentQuestions?.length > 0 && assessmentQuestions.forEach((m, i) => {
      let index = i + 1 * 1
      let option = {
        id: index,
        label: index,
        required: true,
        isAnswered: m.learnerAnswer || m.questionTypeCode === 'PASSAGE',
      }
      breadCrumb = breadCrumb && breadCrumb.length > 0 ? breadCrumb.concat(option) : [option]
    })
    setBreadCrumb(breadCrumb)
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={globalStyles.pageTitle}>
          Assessment
        </div>
        <div className={classes(styles.moreTop, styles.rowWrap)}>
          {/* {course && course.courseName &&
            <TextDisplay label={'Course'}
              text={<Link to={isAuthor ? `/gradebookEntry/${courseScheduledId}` : `/studentAssignments/${courseScheduledId}`} className={globalStyles.link}>
                {course && course.courseName}
              </Link>} />
          } */}
          <TextDisplay label='Assessment' text={assessment && assessment.name} bigger/>
        </div>
        <hr />
        {assessmentQuestions && assessmentQuestions.length > 0 && assessmentQuestions.map((m, i) =>
          <div id={`question${i + 1 * 1}`} key={i + 1 * 1}>
            {assessment.oneAtAtimeView &&
              <div>
                <div className={styles.row}>
                  <div className={styles.breadCrumbLabel}>'questions:'</div>
                  <BreadCrumb options={breadCrumb} currentIndex={currentIndex} onClick={setNextIndexJump} />
                </div>
                <hr />
              </div>
            }
            {m.questionTypeCode === 'TRUEFALSE' &&
              <div key={i}>
                <AssessmentTrueFalse 
                  assignmentId={assignmentId}
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isAuthor={isAuthor}
                  nameKey={i} 
                  onClick={addOrUpdateAssessmentAnswer} 
                  personId={personId} 
                  question={m}  />
                <div className={classes(styles.moreLeft, styles.moreTop)}>
                  {m.websiteLinks.length > 0 &&
                    <div>
                      <span className={styles.label}>{'Website Link'}</span>
                      {m.websiteLinks.map((w, i) =>
                        <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                      )}
                    </div>
                  }
                </div>
              </div>
            }
            {m.questionTypeCode === 'MULTIPLECHOICE' &&
              <div key={i}>
                <AssessmentMultipleChoice 
                  assignmentId={assignmentId}
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isAuthor={isAuthor}
                  nameKey={i} 
                  onClick={addOrUpdateAssessmentAnswer} 
                  personId={personId} 
                  question={m} />
                <div className={classes(styles.moreLeft, styles.moreTop)}>
                  {m.websiteLinks.length > 0 &&
                    <div>
                      <span className={styles.label}>{'Website Link'}</span>
                      {m.websiteLinks.map((w, i) =>
                        <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                      )}
                    </div>
                  }
                </div>
              </div>
            }
            {m.questionTypeCode === 'MULTIPLEANSWER' &&
              <div key={i}>
                <AssessmentMultipleAnswer 
                  answers={m.answers} 
                  assignmentId={assignmentId} 
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isAuthor={isAuthor}
                  learnerAnswer={m.learnerAnswer} 
                  nameKey={i} 
                  onClick={addOrUpdateAssessmentAnswer} 
                  personId={personId}
                  question={m} />
              </div>
            }
            {m.questionTypeCode === 'ESSAY' &&
              <div key={i}>
                <AssessmentEssay 
                  assessmentTitle={assessment && assessment.name} 
                  assignmentId={assignmentId}
                  bigTextDisplay={assessment.bigTextDisplay} 
                  createWorkInAssessment={createWorkInAssessment}
                  isAuthor={isAuthor}
                  isMobile={isMobile}
                  nameKey={i} 
                  onChange={addOrUpdateAssessmentAnswer}
                  personId={personId} 
                  question={m} 
                  listLevelGeneral={listLevelGeneral} />
                <div className={classes(styles.moreLeft, styles.moreTop)}>
                  {m.websiteLinks.length > 0 &&
                    <div>
                      <span className={styles.label}>{'Website Link'}</span>
                      {m.websiteLinks.map((w, i) =>
                        <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                      )}
                    </div>
                  }
                </div>
              </div>
            }
            {m.questionTypeCode === 'PICTUREORAUDIO' &&
              <div key={i}>
                <AssessmentPictureAudio 
                  assessmentTitle={assessment && assessment.name} 
                  assignmentId={assignmentId} 
                  bigTextDisplay={assessment.bigTextDisplay} 
                  handleRemoveFileOpen={handleRemoveFileOpen} 
                  isAuthor={isAuthor}
                  isStudent={true}
                  removeLearnerRecordingOpen={removeLearnerRecordingOpen}
                  nameKey={i} 
                  onChange={addOrUpdateAssessmentAnswer}
                  personId={personId} 
                  question={m} />
                <div className={classes(styles.moreLeft, styles.moreTop)}>
                  {m.websiteLinks.length > 0 &&
                    <div>
                      <span className={styles.label}>{'Website Link'}</span>
                      {m.websiteLinks.map((w, i) =>
                        <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                      )}
                    </div>
                  }
                </div>
              </div>
            }
            {m.questionTypeCode === 'PASSAGE' &&
              <div key={i}>
                <AssessmentPassage 
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isAuthor={isAuthor } 
                  nameKey={i} 
                  question={m}  />
                <div className={classes(styles.moreLeft, styles.moreTop)}>
                  {m.websiteLinks.length > 0 &&
                    <div>
                      <span className={styles.label}>{'Website Link'}</span>
                      {m.websiteLinks.map((w, i) =>
                        <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                      )}
                    </div>
                  }
                </div>
              </div>
            }
            {m.questionTypeCode === 'SINGLEENTRY' &&
              <div key={i}>
                <AssessmentSingleEntry 
                  answers={m.answers} 
                  assessmentTitle={assessment && assessment.name} 
                  assignmentId={assignmentId} 
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isAuthor={isAuthor}
                  learnerAnswer={m.learnerAnswer}
                  nameKey={i} 
                  onClick={addOrUpdateAssessmentAnswer} 
                  personId={personId}
                  question={m} 
                  updateAssessmentLocalAnswer={updateAssessmentLocalAnswer} 
                  />
                <div className={classes(styles.moreLeft, styles.moreTop)}>
                  {m.websiteLinks.length > 0 &&
                    <div>
                      <span className={styles.label}>{'Website Link'}</span>
                      {m.websiteLinks.map((w, i) =>
                        <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                      )}
                    </div>
                  }
                </div>
              </div>
            }
            {m.questionTypeCode === 'FILLBLANK' &&
              <div key={i}>
                <AssessmentFillBlank 
                  assessmentTitle={assessment && assessment.name} 
                  assignmentId={assignmentId} 
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isAuthor={isAuthor}
                  learnerAnswer={m.learnerAnswer}
                  nameKey={i} 
                  onClick={addOrUpdateAssessmentAnswer} 
                  personId={personId}
                  question={m} 
                  updateAssessmentLocalAnswer={updateAssessmentLocalAnswer} />
                <div className={classes(styles.moreLeft, styles.moreTop)}>
                  {m.websiteLinks.length > 0 &&
                    <div>
                      <span className={styles.label}>{'Website Link'}</span>
                      {m.websiteLinks.map((w, i) =>
                        <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                      )}
                    </div>
                  }
                </div>
              </div>
            }
            {m.questionTypeCode === 'MATCHING' &&
              <div key={i}>
                <AssessmentMatching 
                  addOrUpdateAssessmentAnswer={addOrUpdateAssessmentAnswer} 
                  assignmentId={assignmentId} 
                  bigTextDisplay={assessment.bigTextDisplay} 
                  isAuthor={isAuthor}
                  learnerAnswer={m.learnerAnswer}
                  nameKey={i} 
                  onClick={addOrUpdateAssessmentAnswer} 
                  personId={personId} 
                  question={m} 
                  viewMode={'StudentView'} />
                <div className={classes(styles.moreLeft, styles.moreTop)}>
                  {m.websiteLinks.length > 0 &&
                    <div>
                      <span className={styles.label}>{'Website Link'}</span>
                      {m.websiteLinks.map((w, i) =>
                        <LinkDisplay key={i} linkText={w} isWebsiteLink={true} />
                      )}
                    </div>
                  }
                </div>
              </div>
            }
            {assessment.oneAtAtimeView &&
              <div className={styles.buttonPair}>
                {currentIndex !== 1 &&
                  <a className={styles.prevButton} onClick={() => setNextIndexUpOrDown(-1)}>{`< Previous`}</a>
                }
                {currentIndex !== assessmentQuestions.length &&
                  <ButtonWithIcon label={'Next >'} icon={'checkmark_circle'} onClick={() => setNextIndexUpOrDown(1)} />
                }
                {currentIndex === assessmentQuestions.length &&
                  <div className={styles.rowRight}>
                    <ButtonWithIcon label={'Finish'} icon={'checkmark_circle'} onClick={processForm} />
                  </div>
                }
              </div>
            }
          </div>
        )}
        {assessmentQuestions && assessmentQuestions.length > 0 && !assessment.oneAtAtimeView &&
          <div className={styles.rowRight}>
            <ButtonWithIcon label={'Finish'} icon={'checkmark_circle'} onClick={processForm} />
          </div>
        }
        {isShowingModal_removeFileUpload &&
          <MessageModal handleClose={handleRemoveFileUploadClose} heading={'Remove this file upload?'}
            explainJSX={'Are you sure you want to delete this file upload?'} isConfirmType={true}
            onClick={handleRemoveFileUpload} />
        }
        <AssessIncompleteModal 
          handleClose={handleIncompleteClose} 
          isAuthor={isAuthor}
          isOpen={isShowingModal_incomplete}
          isMobile={isMobile}
          heading={'Incomplete'} 
          notAnswered={notAnswered}
          onClick={handleIncompleteContinue} 
          forceAllAnswers={assessment.forceAllAnswers} />

        <FileUploadModal 
          acceptedFiles={".jpg, .jpeg, .tiff, .gif, .png, .bmp, .doc, .docx, .xls, .xlsx, .ppt, .odt, .wpd, .rtf, .txt, .dat, .pdf, .ppt, .pptx, .pptm, .m4a"}
          handleClose={handleFileUploadClose} 
          handleRecordRecall={recallAfterFileUpload} 
          isOpen={isShowingFileUpload}
          isMobile={isMobile}
          iconFiletypes={['.jpg', '.jpeg', '.tiff', '.gif', '.png', '.bmp', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.odt', '.wpd', '.rtf', '.txt', '.dat', '.pdf', '.ppt', '.pptx', '.pptm', '.m4a']}
          label={'File for'}
          personId={personId} 
          sendInBuildUrl={fileUploadBuildUrl}
          showTitleEntry={true}
          submitFileUpload={handleSubmitFile} 
          title={'Assessment Question'} />

        {isShowingModal_removeFile &&
          <MessageModal handleClose={handleRemoveFileClose} heading={'Remove this picture?'}
            explainJSX={'Are you sure you want to delete this picture?'} isConfirmType={true}
            onClick={handleRemoveFile} />
        }
      </div>
      <OneFJefFooter />
    </div>
  )
}


export default AssessmentLearnerView