import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router'
import styles from './WorkFileExplorerOptions.module.css';
import MessageModal from '../MessageModal';
import Icon from '../Icon';
import MoveWorkWhite from '../../assets/Inline Icons/move-white-m.png'
import classes from 'classnames'
import {guidEmpty} from '../../utils/GuidValidate'

function WorkFileExplorerOptions(props) {
  const { personId, deleteWorkFromFolder, moveWorkToFolder, isCopyWorkId, copyWorkToFolder, work, chosenWork, isMoveWorkId, mineOrOthers, thisWorkFolderId } = props;
  const navigate = useNavigate()
  const [isShowingDelete, setIsShowingDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('')
  const [groupAccessMessage, setGroupAccessMessage] = useState(false);
  const [hasFolderGroup, setHasFolderGroup] = useState(false);

  useEffect(() => {
    setHasFolderGroup(findHasFolderGroupHierarchy(props.fullFileTreeExplorer, false))
  }, [props.fullFileTreeExplorer])

  const findHasFolderGroupHierarchy = (folders, foundGroupInHierarchy = false) => {
    return folders && folders.length > 0 && folders.some(folder => {
      const currentFolderHasGroup = folder.group && folder.group.groupId && folder.group.groupId !== guidEmpty;
      if (currentFolderHasGroup || foundGroupInHierarchy) {
        if (folder.workFolderId === thisWorkFolderId) return true;
        if (folder.subFolders) {
          return findHasFolderGroupHierarchy(folder.subFolders, true);
        }
      } else {
        if (folder.subFolders) {
          return findHasFolderGroupHierarchy(folder.subFolders, false);
        }
      }
    });
  };

  const sendToEditWork = () => {
    navigate(`/workSettings/${work.workId}`)
  }

  const sendToAccessWorks = () => {
    //if (group && group.groupName) {
    if (hasFolderGroup) {
      setGroupAccessMessage(true)
    } else if (chosenWork && chosenWork.workId === work.workId) {
      navigate(`/giveAccessToWorks/work/${work.workId}`)
    }
  }

  const handleDelete = () => {
    deleteWorkFromFolder(mineOrOthers, personId, 'work', work.workId)
    setIsShowingDelete(false)
    moveWorkToFolder('')
  }

  const handleShowDeleteMessage = (event) => {
    if (chosenWork && chosenWork.workId === work.workId) {
      event.stopPropagation() //Don't let the work record be unselected while waiting for the decision to delete this work or not.
      event.preventDefault()
      setDeleteMessage(mineOrOthers === 'Others' ? `Are you sure you want to remove<br/>your editor assignment from this document?` : `Are you sure you want to delete this document?`)
      setIsShowingDelete(true)
    }
  }

  const handleMoveWork = (event) => {
    event.stopPropagation() //Don't let the work record be unselected while waiting for the decision to move this record or not.
    //event.preventDefault()
    if (!isMoveWorkId) {
      moveWorkToFolder('start')
    } else {
      moveWorkToFolder('')
    }
  }

  const handleCopyWork = (event) => {
    event.stopPropagation() //Don't let the work record be unselected while waiting for the decision to move this record or not.
    if (!isMoveWorkId) {
      copyWorkToFolder('start')
    } else {
      copyWorkToFolder('')
    }
  }

  const sendToEditReview = () => {
    if (chosenWork && chosenWork.workId === work.workId) {
      navigate(`/editReview/${work.workId}/${chosenWork.chapterId}/${chosenWork.languageIdAssign}`)
    }
  }

  const sendToEditReport = () => {
    if (chosenWork && chosenWork.workId === work.workId) {
      navigate(`/workEditReport/work/${work.workId}`)
    }
  }

  return (
    <div className={styles.container}>
      <a onClick={sendToEditReview}
         title={'Review and edit document'} className={styles.addNewFolderIcon}>
        <Icon pathName={'register'} premium={true} className={classes(styles.imageEditReview, styles.moreTopMargin)} fillColor={'white'} />
      </a>
      {mineOrOthers === 'Mine' &&
        <a onClick={hasFolderGroup ? () => setGroupAccessMessage(true) : sendToAccessWorks}
           title={`Give access to editors for this document`} className={styles.menuItem}>
          <Icon pathName={'share'} premium={true} fillColor={'white'} />
        </a>
      }
      <a onClick={sendToEditReport} title={`Editor reports for this document`} className={styles.menuItem}>
        <Icon pathName={'graph_report'} premium={true} fillColor={'white'} />
      </a>
      {mineOrOthers === 'Mine' &&
        <a onClick={sendToEditWork} title={`Modify document settings and owners`}  className={styles.menuItem}>
          <Icon pathName={'equalizer'} premium={true} fillColor={'white'} />
        </a>
      }
      {mineOrOthers === 'Mine' &&
        <a onClick={handleCopyWork} className={classes(styles.menuItem, styles.moreRight)} title={'Copy document to another folder'}>
          <Icon pathName={'compare_docs'} premium={true} fillColor={'white'} larger />
        </a>
      }
      {mineOrOthers === 'Mine' &&
        <a onClick={handleMoveWork} className={styles.menuItemLess} title={'Move document to another folder'}>
          <img src={MoveWorkWhite} alt={'Move document'} height={19} width={25}
            className={styles.moveImage} />
        </a>
      }
      <a onClick={handleShowDeleteMessage} title={`Delete this document`} className={styles.moreRight}>
        <Icon pathName={`trash2`} premium={true} fillColor={'white'}/>
      </a>
      <MessageModal 
        show={isShowingDelete} 
        handleClose={() => setIsShowingDelete(false)} 
        heading={mineOrOthers === 'Others' ? `Remove your editor assignment?` : `Delete this document?`}
        explain={deleteMessage}
        isConfirmType={true} onClick={handleDelete}/>
      <MessageModal show={groupAccessMessage} handleClose={() => setGroupAccessMessage(false)} heading={`Group Document Access`}
                    explain={`Access to documents in a group folder are already<br/>available to members of the group.<br/><br/>However, documents in a non-group folder must be<br/>assigned directly to an individual.`}
                    onClick={() => setGroupAccessMessage(false)}/>
    </div>
  )
}

export default WorkFileExplorerOptions;