import React from 'react';
import styles from './OriginalTextAndComment.module.css';
import * as editorService from '../../services/editor-dom'

function OriginalTextAndComment(props) {
	const {workSummary, originalSentenceText, authorComment, flexOriginalTextHeight} = props

    return (
			<div className={styles.container}>
				<div>
					<div className={styles.row}>
						{!editorService.isMobile() && originalSentenceText && <span className={styles.textSmall}>{`Original Text:`}</span>}
						{/*{workSummary && workSummary.languageId_current === workSummary.nativeLanguageId &&*/}
						{/*    <a onClick={handleToggle} className={styles.link}>*/}
						{/*        {origTextExpanded ? '(hide)' : '(show)'}*/}
						{/*    </a>*/}
						{/*}*/}
						<div className={styles.languageName}>{workSummary.languageName}</div>
					</div>
					{/*{(origTextExpanded || (workSummary && workSummary.languageId_current !== workSummary.nativeLanguageId)) &&*/}
					<div className={styles.authorSentence}>
						<div  dangerouslySetInnerHTML={{__html: originalSentenceText}}/>
						{authorComment &&
							<div className={styles.sentenceComment}><div className={styles.textSmall}>Author's comment:</div> {authorComment}</div>
						}
					</div>
				</div>
			<hr/>
		</div>
  )
}

export default OriginalTextAndComment