import * as types from './actionTypes';
import {apiHost} from '../api_host.js';
import {guidEmpty} from "../utils/GuidValidate";

export const getChapterText = (personId, workId, chapterId, languageId) => {
  chapterId = chapterId ? chapterId : 0;
  return dispatch => {
    dispatch({type: types.FETCHING_RECORD, payload: {chapterText: true}}); //We'll let this resolve in the container using the componentDidUpdate to set it to false.
    return fetch(`${apiHost}ebi/chapter/content/` + personId + `/` + workId + `/` + chapterId + `/` + languageId, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({type: types.FETCHING_RECORD, payload: {chapterText: 'ready'}});
        dispatch({type: types.CHAPTER_TEXT_INIT, payload: response});
      })
  }
}

export const addOrUpdateSegments = (personId, segments, runFunction = () => {}) => {
  return dispatch => {
    return fetch(`${apiHost}ebi/work/segments/addOrUpdate/${personId}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify(segments),
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then((response) => {
        dispatch({ type: types.WORK_SEGMENTS_INIT, payload: response })
        runFunction()
      })
  }
}

export const updateSegmentsLocal = (segments) => {
  return dispatch => {
    dispatch({ type: types.WORK_SEGMENTS_INIT, payload: segments })
  }
}

export const getWorkSegments = (personId, workId, chapterId, languageId) => {
  return dispatch => {
    return fetch(`${apiHost}ebi/work/segments/${personId}/${workId}/${chapterId}/${languageId}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({type: types.WORK_SEGMENTS_INIT, payload: response})
      })
  }
}

export const addOrUpdateEdit = (edit, runFunction = () => {}) => {
  return dispatch => {
    // if (edit.type !== 'ADDPARAGRAPHSENTENCE') 
    dispatch({ type: types.WORK_EDIT_UPDATE, payload: edit })
    return fetch(`${apiHost}ebi/work/edit/addOrUpdate`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify(edit),
    })
      .then(() => {
        runFunction()
      })
  }
}

export const addOrUpdateEditAddList = (addListEntriesReceive) => {
  return dispatch => {
    return fetch(`${apiHost}ebi/work/editAddList/addOrUpdate`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify(addListEntriesReceive),
    })
  }
}

export const acceptAllAddParagraphSentence = (editsAddParagraphSentence, runFunction = () => { }) => {
  return dispatch => {
    return fetch(`${apiHost}ebi/work/edit/acceptAllAddParagraphSentence`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify(editsAddParagraphSentence),
    })
      .then(() => {
        runFunction()
      })
  }
}

export const acceptAllAddListItem = (editsAddListItem, addListItemNextParentElementId, runFunction = () => { }) => {
  return dispatch => {
    return fetch(`${apiHost}ebi/work/edit/acceptAllAddListItem/${addListItemNextParentElementId}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify(editsAddListItem),
    })
      .then(() => {
        runFunction()
      })
  }
}

export const deleteAllAddParagraphSentence = (editsAddParagraphSentence, runFunction = () => { }) => {
  return dispatch => {
    return fetch(`${apiHost}ebi/work/edit/deleteAllAddParagraphSentence`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify(editsAddParagraphSentence),
    })
      .then(() => {
        runFunction()
      })
  }
}

export const getEditSegments = (personId, workId, chapterId, languageId) => {
  if (!chapterId) chapterId = guidEmpty
  if (!workId) workId = guidEmpty
  return dispatch => {
    return fetch(`${apiHost}ebi/work/edits/${personId}/${workId}/${chapterId}/${languageId}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({type: types.WORK_EDITS_INIT, payload: response})
      })
  }
}

export const responseEdit = (edit, responseType, comment, listItemElementIds, runFunction = () => {}, addListItemNextParentElementId) => {
  // responseTypes:
  //   voteUp
  //     voteDown
  //     voteTroll
  //     acceptEdit (including editor - but this means replace and voteUp)
  //     rejectEdit (not including editor)
  //     deleteEdit (because it belongs to you or this is the author)
  //     commentOnly
  return dispatch => {
    if (responseType === 'VoteUp' || responseType === 'VoteDown' || responseType === 'VoteTroll') {
      dispatch({
        type: types.WORK_EDIT_VOTE_UPDATE,
        payload: { edit, responseType, comment}
      })
      //I don't think that this is necessary because getEditSegments is being called (most if not all?) of the time through run Function.
    // } else if (responseType === 'AcceptEdit' || responseType === 'RejectEdit' || responseType === 'DeleteEdit') {
    //   dispatch({
    //     type: types.WORK_EDIT_RESPONSE,
    //     payload: {edit, responseType}
    //   })
    }

    return fetch(`${apiHost}ebi/work/edit/response`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify({ //We will pick up the user's personId as the JW token personIdClaim on the server side.
        editorPersonId: edit.personId,
        elementId: edit.elementId,
        chapterId: edit.chapterId,
        languageId: edit.languageId,
        editType: edit.type,
        responseType,
        editSegmentId: edit.editSegmentId,
        comment,
        listItemElementIds: listItemElementIds && listItemElementIds.toString(),
        subSequence: edit.subSequence,
        addListItemSequence: edit.addListItemSequence,
        text: edit.text,
        addListItemNextParentElementId
        }),
    })
      .then(runFunction)
  }
}

export const addOrUpdateCommentOrSentence = (personId, comment, workId, chapterId, elementId, languageId, isAuthor, editSegmentId = 0, editType = '', newSentence='') => {
  //If this is a translation, then the sentence and comment is going to be rerouted to TranslatedSegment instead of WorkSegment
  return dispatch => {
    return fetch(`${apiHost}ebi/work/update/commentOrSentence`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify({personId, comment, workId, chapterId, elementId, languageId, isAuthor, editSegmentId, editType, newSentence}),
    })
      .then(() => {
        if (isAuthor) {
          dispatch({type: types.WORK_COMMENT_UPDATE, payload: {editType, comment, elementId, newSentence}})
        } else {
          dispatch({
            type: types.WORK_EDIT_COMMENT,
            payload: {personId, comment, chapterId, elementId, editSegmentId, editType}
          })
        }
      })
  }
}

export const restoreEditSegment = (editSegmentId, languageId) => {
  return dispatch => {
    return fetch(`${apiHost}ebi/work/editHistory/restore/${editSegmentId}/${languageId}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({type: types.WORK_EDITS_INIT, payload: response})
      })
  }
}

export const getEditSegmentHistory = (personId, chapterId, elementId, languageId = 1, runFunction = () => {
}) => {
  return dispatch => {
    return fetch(`${apiHost}ebi/work/editHistory/${personId}/${chapterId}/${elementId}/${languageId}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({type: types.EDIT_SEGMENT_HISTORY_INIT, payload: response})
        //setTimeout(() => runFunction(), 1000)
      })
  }
}

export const getHtmlCharacterEntities = () => {
  return dispatch => {
    return fetch(`${apiHost}ebi/htmlCharacters`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({ type: types.HTML_CHARACTER_ENTITIES, payload: response })
      })
  }
}



export const addDiscussionReply = (assignmentId, languageId, discussionEntryId, title, isFirstLevelPost='false', runFunction=()=>{}) => {
  return dispatch => {
    return fetch(`${apiHost}ebi/discussion/entry/add/${assignmentId}/${languageId}/${discussionEntryId}/${encodeURIComponent(title || 'EMPTY')}/${isFirstLevelPost}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({ type: types.WORK_EDIT_REVIEW, payload: response });
        const latestWorkSummary = response?.length > 0 && response.reduce((latest, current) => {
          return !latest || new Date(current.entryDate) > new Date(latest.entryDate) ? current : latest;
        }, null)
        runFunction(latestWorkSummary && latestWorkSummary.chapterId_current)
      })
  }
}


export const deleteDiscussionReply = (assignmentId, languageId, discussionEntryId) => {
  return dispatch => {
    return fetch(`${apiHost}ebi/discussion/reply/delete/${assignmentId}/${languageId}/${discussionEntryId}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({ type: types.WORK_SEGMENTS_INIT, payload: response })
      })
  }
}

export const voteDiscussionReply = (voteEntry) => {
  return dispatch => {
    return fetch(`${apiHost}ebi/discussionEntries/vote`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify(voteEntry)
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
        dispatch({ type: types.WORK_EDIT_REVIEW, payload: response });
      })
  }
}
