import React, {useEffect, useRef} from 'react';  //PropTypes
import styles from './MessageModal.module.css';
import ButtonWithIcon from '../ButtonWithIcon'
import {Alert, AlertTitle} from '@mui/material';
import EditReviewToolLegend from '../EditReviewToolLegend'; //This is the main edit Review page icon explanation.
import EditReviewEditIconLegend from '../EditReviewEditIconLegend'; //This goes to the left panel to explain icons for choosing between edits.

function MessageModal(props) {
  const {show, onClick, handleClose, heading, explain, explainJsx, isYesNoCancelType, yesText, noText, cancelText, handleNo, showToolLegend, isConfirmType,
    showEditIconLegend, isAuthor, displayTempMessage, setEntryError, downloadFilePath } = props;
  let {currentSentenceText} = props;
  const containerRef = useRef(null);
  const clickOnDownload = useRef(null)

  let regex = "/<(.|\n)*?>/";
  currentSentenceText = currentSentenceText &&
      currentSentenceText.replace(regex, "")
          .replace(/<br>/g, "")
          .replace(/<[^>]*>/g, ' ')
          .replace(/\s{2,}/g, ' ')
          .trim();

  useEffect(() => {
    const handleClickOutside = (event) => {
      let isMessage = false
      let element = event.target
      let loop = 0
      while (element && loop < 10 && !isMessage) {
        isMessage = element.classList && Array.from(element.classList).some(className => className.includes('dialogButtons'))
        element = element.nextSibling
        loop++
      }
      if (containerRef.current && (!containerRef.current.contains(event.target) || isMessage)) {
        handleClose()
      }
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && containerRef.current) { // ESC key
        handleClose()
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [show]);


  useEffect(() => {
    if (displayTempMessage && explain) {
      setTimeout(() => removeTempMessage(''), 3000)
    }
  },[explain])

  const removeTempMessage = () => {
    setEntryError('')
  }

  return !show ? null : (
    <div className={styles.container} ref={containerRef}>
      {(!displayTempMessage || (displayTempMessage && explain)) &&
        <Alert severity='info' style={{borderRadius: '0 25px 0 25px', backgroundColor: 'white', boxShadow: '-9px 16px 69px 2px rgba(118,122,122,1)', zIndex: 999}}>
          <AlertTitle>{displayTempMessage ? '' : heading}</AlertTitle>
          <div style={{width: '350px !important'}} dangerouslySetInnerHTML={{__html: explain}}/>
          {explainJsx || ''}
          <div className={styles.currentSentence}>{currentSentenceText && currentSentenceText.replace(regex, "").substring(0,500)}</div>
          {showToolLegend &&
              <div>
                  <EditReviewToolLegend />
              </div>
          }
          {showEditIconLegend &&
              <div>
                  <EditReviewEditIconLegend isAuthor={isAuthor} />
              </div>
          }
          {downloadFilePath &&
            <a ref={clickOnDownload} href={downloadFilePath} download/>
          }
          <div className={styles.centered}>
            <div className={styles.dialogButtons}>
              {!isConfirmType && !isYesNoCancelType &&
                <ButtonWithIcon icon={'checkmark0'} label={'OK'} onClick={onClick} addClassName={styles.buttonWidth} smaller/>
              }
              {isConfirmType && <a className={styles.noButton}  onClick={handleClose}>No</a>}
              {isConfirmType &&
                <ButtonWithIcon 
                  icon={'checkmark0'} label={'Yes'}
                  onClick={downloadFilePath ? () => { onClick() } : onClick}
                  addClassName={styles.buttonWidth} smaller/>
              }
              {isYesNoCancelType && <a className={styles.noButton}  onClick={handleClose}>{cancelText}</a>}
              {isYesNoCancelType && <a className={styles.yesButton}  onClick={handleNo}>{noText}</a>}
              {isYesNoCancelType &&
                <ButtonWithIcon 
                  icon={'checkmark0'} label={yesText}
                    onClick={onClick}
                    addClassName={styles.buttonWidth} smaller/>
              }
            </div>
          </div>
        </Alert>
      }
    </div>
  )
}

export default MessageModal