import React from 'react';
import styles from './ChartTooltip.module.css'
function CustomTooltip({ payload, label, active }) {

  if (active) {
    return !(payload && payload.length > 0) ? null : (
      <div className={styles.container}>
        <p className={styles.label}>{`${label}`}</p>
        <p className={styles.intro}>{`words: ${payload[0].payload.uv}`}</p>
        <p className={styles.intro}>{`edits: ${payload[0].payload.pv}`}</p>
        <p className={styles.desc}>Click on dot to see draft</p>
      </div>
    );
  }
  return null;
}

export default CustomTooltip;