import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import styles from './ContributorReportView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import EditTable from '../../components/EditTable';
import ReportFilter from '../../components/ReportFilter';
import Accordion from '../../components/ListAccordion/Accordion/Accordion.js';
import SelectSingleDropDown from '../../components/SelectSingleDropDown';
import OneFJefFooter from '../../components/OneFJefFooter';

function ContributorReportView(props) {
  const {personId, reportTable, updateFilterByField, clearFilters, filterScratch, savedFilterIdCurrent,
    updateSavedSearch, updateFilterDefaultFlag, deleteSavedSearch, chooseSavedSearch, saveNewSavedSearch, workOptions,
    nativeLanguageOptions, translateLanguageOptions, editorOptions, sectionOptions, filterOptions, editTypeOptions,
    editType, workId, languageId, sections, editTypeCount, langOrEditorCount } = props;

  const [chosenTab, setChosenTab] = useState("edit")
  const [tabs, setTabs] = useState(props.tabs)
  const [tabsData, setTabsData] = useState()
  const [reportChoice, setReportChoice] = useState('editWorksEditors')

  const navigate = useNavigate()

  const handleRerouteReport = (workIds, nativeLanguageIds, translateLanguageIds, editorIds, sectionIds) => {
      let pathName = props.location.pathname;
      let editOrTranslate = pathName && pathName.indexOf('report/e') > -1 ? 'edit' : 'translate';
      workIds = workIds ? workIds : [];
      editorIds = editorIds ? editorIds : [];
      nativeLanguageIds = nativeLanguageIds ? nativeLanguageIds : [];
      translateLanguageIds = translateLanguageIds ? translateLanguageIds : [];
      sectionIds = sectionIds ? sectionIds : [];

      if (editOrTranslate === 'edit') {
          if ((workIds.length === 0 || workIds.length > 1) && (editorIds.length === 0 || editorIds.length > 1)) {
              navigate('/report/e/edit/works/editors');
          } else if ((workIds.length === 0 || workIds.length > 1) && editorIds.length === 1) {
              navigate('/report/e/edit/works/' + editorIds[0]);
          } else if (workIds.length === 1 && (editorIds.length === 0 || editorIds.length > 1)) {
              navigate('/report/e/edit/' + workIds[0] + '/editors');
          } else if (workIds.length === 1 && editorIds.length === 1 && (sectionIds.length === 0 || sectionIds.length > 1)) {
              navigate('/report/e/edit/' + workIds[0] + '/' + editorIds[0] + '/sections');
          } else if ((workIds.length === 0 || workIds.length > 1)) {
              navigate('/report/e/edit/works');
          } else if (workIds.length === 1) {
              navigate('/report/e/edit/' + workIds[0]);
          } else {
              navigate('/report/e/edit');
          }
      } else { //Or it is translation.
          //fill in the translation reports here.

      }
  }

  const handleReportReturnIcon = () => {
      const {reportOptGroup} = props;
      let choice = reportChoice ? reportChoice : 'editWorksEditors';

      let pathLink = '';
      reportOptGroup && reportOptGroup.length > 0 && reportOptGroup.forEach(r => {
          r.options?.forEach(o => {
              if (o.id === choice) {
                  pathLink = o.pathLink;
              }
          })
      })
      if (pathLink) {
          navigate(`/report/` + pathLink);
      }
  }

  const handleReportChange = (event) => {
      const {reportOptGroup} = props;
      setReportChoice(event.target.value)

      let pathLink = '';
      reportOptGroup?.forEach(r => {
          r.options?.forEach(o => {
              if (o.id === event.target.value) {
                  pathLink = o.pathLink;
                  setTabsData({...tabsData, chosenTab: r.editOrTranslate})
              }
          })
      })
      if (pathLink) {
          navigate(`/report/` + pathLink);
      }
  }

  const handleEditTypeChange = (event) => {
      let pathName = props.location.pathname;
      pathName = pathName.indexOf('/editCount') > -1 ? pathName.substring(0, pathName.indexOf('/editCount')) : pathName;
      navigate(pathName + '/editCount/' + event.target.value);
  }

  const handlePathLink = (pathLink) => {
      pathLink && navigate(pathLink);
  }

  return (
      <div className={styles.container}>
          <div className={globalStyles.pageTitle}>
              {'Editor Report'}
          </div>
          {filterOptions && filterOptions.length > 0
              ?
              <Accordion title={filterScratch}>
                {/*expanded={false} filterScratch={filterScratch} filterOptions={filterOptions} savedFilterIdCurrent={savedFilterIdCurrent}*/}
                {/*  updateSavedSearch={updateSavedSearch} deleteSavedSearch={deleteSavedSearch} chooseSavedSearch={chooseSavedSearch}*/}
                {/*  updateFilterByField={updateFilterByField} updateFilterDefaultFlag={updateFilterDefaultFlag} personId={personId}*/}
                {/*  clearFilters={clearFilters}>*/}
                <ReportFilter personId={personId} reportFilter={filterScratch} updateFilterByField={updateFilterByField}
                    clearFilters={clearFilters} saveNewSavedSearch={saveNewSavedSearch} workOptions={workOptions}
                    incomingParams={props} handleRerouteReport={handleRerouteReport}
                    nativeLanguageOptions={nativeLanguageOptions} translateLanguageOptions={translateLanguageOptions}
                    editorOptions={editorOptions} sectionOptions={sectionOptions} savedSearchOptions={filterOptions}/>
              </Accordion>
              :
              <ReportFilter personId={personId} reportFilter={filterScratch} updateFilterByField={updateFilterByField}
                  clearFilters={clearFilters} saveNewSavedSearch={saveNewSavedSearch} workOptions={workOptions}
                  incomingParams={props} handleRerouteReport={handleRerouteReport}
                  nativeLanguageOptions={nativeLanguageOptions} translateLanguageOptions={translateLanguageOptions}
                  editorOptions={editorOptions} sectionOptions={sectionOptions} savedSearchOptions={filterOptions}/>
          }
          {editTypeOptions && editTypeOptions.length > 0 &&
              <div>
                  <SelectSingleDropDown
                      value={editTypeCount ? editTypeCount : tabsData.chosenTab === 'edit' ? 'edits' : 'transCompletePercent'}
                      label={`Edit Type Count`}
                      options={editTypeOptions}
                      height={`medium`}
                      noBlank={true}
                      className={styles.singleDropDown}
                      onChange={handleEditTypeChange} />
              </div>
          }
          {(!editTypeOptions || editTypeOptions.length === 0) &&
              <div className={styles.marginSpace}>
              </div>
          }
          <EditTable labelClass={styles.tableLabelClass} headings={reportTable.headings}
              data={reportTable.data} noCount={true} firstColumnClass={styles.firstColumnClass}
              sendToReport={handlePathLink}/>
          <br/>
          <br/>
          <OneFJefFooter />
    </div>
  );
}
export default ContributorReportView
// <EditorEditList returnToSummary={handleFlip}
//                              personId={personId}
//                              workId={workSummary.workId}
//                              chapterId={workSummary.chapterId_current}
//                              languageId={workSummary.languageId}
//                              authorPersonId={authorPersonId}
//                              editDetailsPerson={editDetailsPerson}
//                              editDetails={editDetails}
//                              setEditDetail={setEditDetail}
//                              setAcceptedEdit={setAcceptedEdit}
//                              setEditVoteBack={setEditVoteBack}
//                              deleteEditDetail={deleteEditDetail}
//                              restoreEditDetail={restoreEditDetail}
//                              getOriginalSentence={getOriginalSentence}
//                              updateFilter={handleFilterKeyValue}
//                              localFilter={localFilter}
//                              setEditorTabSelected={setEditorTabSelected}
//                              setVisitedHrefId={setVisitedHrefId}
//                              getTabsEditors={getTabsEditors}/>
