import * as types from '../actions/actionTypes'

export default function (state = [], action) {
  switch (action.type) {
    case types.WORK_FILE_TREE_INIT: {
      return action.payload
    }
    case types.WORK_FILE_DELETE: {
      const { levelType, id } = action.payload
      if (levelType === 'work') {
        const newState = state.map(m => {
          const newFiles = m.files?.filter(f => f.workId !== id) || []
          return { ...m, files: newFiles }
        });
        return newState
      }
      return state
    }
    case types.WORK_FILE_TREE_TOGGLE_EXPANDED: {  //This is mutating state and I can't figure out why since I was doing a clean copy of state.
      let workFolderId = action.payload
      let workFolder = state.slice(0)
      workFolder = workFolder.filter(m => m.workFolderId === workFolderId)[0]
      let setting = !workFolder.isExpanded
      workFolder.isExpanded = setting
      let newState = state.slice(0)
      newState = newState.filter(m => m.workFolderId !== workFolderId)
      //if (workFolder.subFolders && workFolder.subFolders.length > 0) workFolder.subFolders = [...findAndSetExpandedFolder(workFolderId, workFolder.subFolders)]
      newState = newState && newState.length > 0 ? newState.concat(workFolder) : [workFolder]
      return newState
    }
    default:
      return state
  }
}

const findAndSetExpandedFolder = (workFolderId, workFolders) => {
  return workFolders && workFolders.length > 0 && workFolders.map(m => {
    if (m.workFolderId === workFolderId) m.isExpanded = !m.isExpanded
    if (m.subFolders && m.subFolders.length > 0) m.subFolders = [...findAndSetExpandedFolder(workFolderId, [...m.subFolders])]
    return m
  })
}

export const selectWorkFileTree = (state) => state

