import * as types from './actionTypes';
import {apiHost} from '../api_host.js';
import { guidEmpty } from '../utils/GuidValidate.js'

//This just saves off the FriendWorkAssign record to the database,
//But it needs to update the Works.editorAssign object before it saves off to the database.
//If isAdd is set to "skip" that would infer that this is an update to the language or chapters selection.
export const setEditorAssign = (isAdd, workId, personId, owner_personId, chapters, languages, directChapterId = guidEmpty, runFunction=()=>{}) => {
    if (!languages || (languages && languages.length === 0)) {
        languages = "1";
    }
    //isAdd = isAdd === "skip" ? true : isAdd; //This needs to be converted back to boolean for the webApi object that expects boolean.
    return dispatch => {
        return fetch(`${apiHost}ebi/editorWorkAssign`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
            body: JSON.stringify({
                isAdd: isAdd,
                workId: workId,
                personId: personId,
                owner_personId: owner_personId,
                chapters: chapters.toString(),
                languages: languages.toString(),
                directChapterId,
            }),
        })
        //   .then(() => {
        //         dispatch({ type: types.CONTACTS_EDITOR_ASSIGN_UPDATE, payload: {isAdd, workId, personId, chapters, languages} })
        //         runFunction()
        //     }
        //   )
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json()
            } else {
                const error = new Error(response.statusText)
                error.response = response
                throw error
            }
        })
        .then(response => {
            dispatch({ type: types.WORK_EDITOR_ACCESS_INIT, payload: response })
        })
    }
}


export const removeEditorByAuthor = (editorPersonId, authorPersonId, workFolderId) => {
    return dispatch =>
        fetch(`${apiHost}ebi/editorWorkAssign/remove/editor/${editorPersonId}/${authorPersonId}/${workFolderId}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json()
                } else {
                    const error = new Error(response.statusText)
                    error.response = response
                    throw error
                }
            })
            .then(response => {
                dispatch({ type: types.WORK_FILE_TREE_INIT, payload: response });
                localStorage.setItem("workFileTreeExplorer", JSON.stringify(response));
            })
    //.catch(error => { console.l og('request failed', error) })
}

