import * as types from '../actions/actionTypes';

export default function(state = {}, action) {
    switch(action.type) {
      case types.ASSESSMENT_QUESTION:
        return action.payload;

        default:
            return state;
    }
}

export const selectAssessmentQuestion = (state) => state;
