import React from 'react';
import styles from './RadioGroupToggle.module.css';
import './RadioGroupToggle.css'
import classes from 'classnames';
import Required from '../Required';

//to do: Make the radio label a link to set the value of this radio group choice.
export default ({
  className = "",
  data = [],
  disabled,
  horizontal = false,
  initialValue,
  label,
  labelClass = "",
  name,
  onClick,
  pageTab,
  personId,
  position = "before",
  radioClass = "",
  required = false,
  title,
  titleClass,
  whenFilled,
}) => {

  if (!name && !data) {
    name = data.replace(/ /g, "");
  }

  return (
    // <div className={classes(styles.container, className)}>
    <div className="checkbox-wrapper-55">
      {(title || label) &&
        <div className={styles.row}>
          {(title || label) &&
            <span className={classes(styles.titleClass, titleClass, required ? styles.lower : '', disabled ? styles.lowOpacity : '')}>
              {title || label}
            </span>
          }
          <div className={styles.leftDown}>
            <Required setIf={required} setWhen={whenFilled}/>
          </div>
        </div>
      }
      <div className={(horizontal ? styles.horizontal : styles.radio)}>
        {data.map((d, index) => {
          return (
            <div key={index} className={classes(styles.row, className, (horizontal ? styles.spaceBetween : styles.spaceBelow))}>
              {position === "before" ?
                <label className="rocker rocker-small">
                  <input 
                    type="checkbox" 
                    checked={initialValue === d.id} 
                    disabled={disabled} 
                    onChange={() => { }}
                    name={name} 
                    id={name} 
                    onClick={disabled ? () => { } : () => onClick(initialValue === d.id ? '' : d.id, personId)} /> 
                  <span className="switch-left">Yes</span>
                  <span className="switch-right">No</span>
                </label>
                : ''}
              <a className={classes(styles.label, (labelClass ? labelClass : ''), (d.correction === 'wrong' ? styles.wrong : d.correction === 'correct' ? styles.correct : ''))}
                 onClick={() => !disabled && onClick(d.id, personId)}>{d.label}</a>
              {position !== "before" ?
                <label className="rocker rocker-small">
                  <input
                    type="checkbox"
                    checked={initialValue === d.id}
                    disabled={disabled}
                    onChange={() => { }}
                    name={name}
                    id={name}
                    onClick={disabled ? () => { } : () => onClick(initialValue === d.id ? '' : d.id, personId)} />
                  <span className="switch-left">Yes</span>
                  <span className="switch-right">No</span>
                </label>
                : ''}
            </div>
          )
        })}
      </div>
    </div>
  )
};
