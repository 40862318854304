import React from 'react';
import styles from './FlexTabularScroll.module.css';

function FlexTabularScroll(props) {
	const {headings = [], data = [], sendToReport, chosenIndex = -1, chosenColor = '', chosenRowColor = ''} = props

	return (
		<div className={styles.container}>
			<div className={styles.row}>
				<div className={styles.firstColumn}>&nbsp;</div>
				{headings.map((heading, i) => {
					return (
						<div key={i}
						     data-rh={heading.reactHint} title={heading.reactHint}
						     onClick={heading.pathLink
							     ? () => sendToReport(heading.pathLink)
							     : heading.clickFunction
								     ? heading.clickFunction
								     : () => {
								     }
						     }
						     className={styles.headerText}>
							{heading.label}
						</div>
					)
				})
				}
			</div>
			{data.map((row, dataIndex) => {
					return (
						<div style={{
							display: 'flex',
							flexDirection: 'row',
							backgroundColor: chosenIndex === dataIndex ? chosenColor : chosenRowColor ? chosenRowColor : ''
						}} key={dataIndex}>
							{row && row.length > 0 && row.map((cell, rowIndex) => {
								return (
									<div className={cell.clickFunction ? styles.cellLink : ''} key={rowIndex} style={{ backgroundColor: cell.cellColor ? cell.cellColor : ''}}
											title={cell.title}
									    onClick={cell.pathLink
										     ? () => sendToReport(cell.pathLink)
										     : cell.clickFunction
											     ? cell.clickFunction
											     : () => {
											     }
									     }>
										<div className={rowIndex === 0 ? styles.firstColumn : styles.innerColumn}>
											{cell.value === 0 ? '' : cell.value}
										</div>
									</div>
								)
							})
							}
						</div>
					)
				}
			)}
		</div>
	)
};

export default FlexTabularScroll
