import React from 'react';
import styles from './PushButton.module.css';
import classes from 'classnames'

function PushButton(props) {
  const {value, setValue, onImage, offImage, disabledImage, setShowClickInstruction, title, isDisabled} = props;

  return (
    <div title={title} className={styles.container}>
      {setValue &&
        <div className={styles.rowToggle} onClick={isDisabled ? () => {} : () => {
          setShowClickInstruction(true)
          setValue(!value)
        }}>
          <img src={isDisabled ? disabledImage : value ? onImage : offImage} alt={'toggle'} height={50}/>
        </div>
      }
    </div>
  )
}

export default PushButton;
