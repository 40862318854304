import React, {useState} from 'react'
import styles from './MobileSelectListFloat.module.css'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export default function MobileSelectListFloat({ 
	onSelect = () => {}, 
	open,
	header,
	setOpen,
	listOptions, 
	divDisplayId, 
	children, 
	addClassName, 
	keyIndex,
}) {

	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const checkForClose = (event) => {
		event.stopPropagation()
		const element = event && event.target
		if (element && element.innerText === 'Next') handleClose()
	}

	return (
		<div onClick={checkForClose} style={{ height: '10px' }} key={keyIndex} className={styles.container}>
			<Menu
				anchorEl={anchorEl}
				id="drop-down-list"
				open={open}
				onClose={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						fontSize: '11px',
						maxHeight: '90vh',
						overflowY: 'auto',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						backgroundColor: '#92dbf1',
						mt: 1,
						'& .MuiAvatar-root': {
							width: 32,
							height: 22,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 4,
							backgroundColor: '#92dbf1',
							transform: 'translateY(-50%) rotate(45deg)',
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<div className={addClassName}>
					{header &&
						<div className={styles.header} onClick={() => setOpen(false)}>
							{header}
						</div>
					}
					{children}
					{listOptions?.length > 0 && listOptions.map((m, i) =>
						<MenuItem 
							key={i} 
							onClick={(event) => {
								event.stopPropagation()
								onSelect(m.id, event, divDisplayId)
								setOpen(false)
							}
						}>
							<span>{m.label}</span>
						</MenuItem>
					)}
				</div>
			</Menu>
		</div>
	)
}