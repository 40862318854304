import React, {useEffect} from 'react';
import EditorInviteOptionsView from '../views/EditorInviteOptionsView';
import { connect } from 'react-redux';
import * as actionEditorInvitePending from '../actions/editor-invite-pending.js';

import { selectMe, selectEditorInvitePending } from '../store.js';

const mapStateToProps = state => {
  let me = selectMe(state);

    return {
      personId: me.personId,
      editorInvitePending: selectEditorInvitePending(state),
    }
}

// binds the result of action creators to redux dispatch, wrapped in callable functions
const bindActionsToDispatch = dispatch => ({
  getInvitesPending: (personId) => dispatch(actionEditorInvitePending.getInvitesPending(personId)),
  deleteInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.deleteInvite(personId, friendInvitationId)),
  acceptInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.acceptInvite(personId, friendInvitationId)),
  resendInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.resendInvite(personId, friendInvitationId)),
  sendFirstNotifications: (personId, groupId) => dispatch(actionEditorInvitePending.sendFirstNotifications(personId, groupId)),
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
);

function Container(props) {
  const { personId, getInvitesPending } = props
    useEffect(() => {
      if (personId) {
        getInvitesPending(personId)
      }
    },[personId])

    return <EditorInviteOptionsView {...props} />
}

export default storeConnector(Container);
