import React, {useEffect} from 'react';
import TextStyleSettingView from '../views/TextStyleSettingView';
import {connect} from 'react-redux';
import * as actionTextStyles from '../actions/text-styles.js';
import { selectMe, selectTextStyleGroups, selectTextStyleFonts } from '../store.js';

const mapStateToProps = (state, props) => {
	let me = selectMe(state);
  const textStyleFonts = selectTextStyleFonts(state)
  let fontOptions = []
  let optGroup = {id: '', label: '', options: []}
  textStyleFonts?.forEach(m => {
    if (m.groupName !== optGroup.label) {
      optGroup = {id: m.groupIndex, label: m.groupName, options: []}
      fontOptions.push(optGroup)
    }
    optGroup.options.push({id: m.name, label: m.name})
  })

  const fontSizes = []
  for(let i = 6; i <= 72; i++) {
    fontSizes.push({id: i, label: i + 'px'})
  }

  const lineHeights = []
  for (let i = 6; i <= 30; i++) {
    lineHeights.push({ id: i/10, label: i/10 })
  }

  const textAlign = [
    { id: 'left', label: 'left' },
    { id: 'center', label: 'center' },
    { id: 'right', label: 'right' },
    { id: 'justify', label: 'full-justify'},
  ]

  const bottomSpace = []
  for (let i = 0; i <= 30; i++) {
    bottomSpace.push({ id: i, label: i + 'px' })
  }

  const textIndent = []
  for (let i = 0; i <= 12; i++) {
    textIndent.push({ id: i, label: i + ' characters' })
  }

  return {
		personId: me.personId,
    textStyleGroups: selectTextStyleGroups(state),
    fontOptions,
    fontSizes,
    lineHeights,
    textAlign,
    bottomSpace,
    textIndent,
	}
};

const bindActionsToDispatch = dispatch => ({
  setTextStyleGroup: (textStyleGroup, name, editTextStyleGroupId) => dispatch(actionTextStyles.setTextStyleGroup(textStyleGroup, name, editTextStyleGroupId)),
  getTextStyleGroups: () => dispatch(actionTextStyles.getTextStyleGroups()),
  getTextStyleFonts: () => dispatch(actionTextStyles.getTextStyleFonts()),
  deleteTextStyleGroup: (textStyleGroupId) => dispatch(actionTextStyles.deleteTextStyleGroup(textStyleGroupId))
});

const mergeAllProps = (store, actions) => ({
	...store, ...actions,
});

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
	mergeAllProps
);

function Container(props) {
  const { getTextStyleGroups, getTextStyleFonts } = props

  useEffect(() => {
    getTextStyleGroups()
    getTextStyleFonts()
  }, [])

  return <TextStyleSettingView {...props} />
}

export default storeConnector(Container);
