import React from 'react';
import styles from './Required.module.css';
import Icon from '../Icon';
import classes from 'classnames';

export default ({setIf=false, setWhen=false, className}) => {
    return (
        setIf
						? setWhen
	            ? <Icon pathName={'checkmark'} className={classes(styles.warning, className)} fillColor={'green'} title={'This required field has been entered.'}/>
	            : <Icon pathName={'warning'} className={classes(styles.warning, className)} fillColor={'red'} title={'This field is required.'}/>
						: null
    )
};
