import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom'
import GroupWorksView from '../views/GroupWorksView';
import {connect} from 'react-redux';
import * as actionWorks from '../actions/works.js';
import * as actionGroups from '../actions/groups.js';
import * as fromWorks from '../reducers/works.js';
import {guidEmpty} from '../utils/GuidValidate'
import {selectWorkSummary, selectMe, selectWorkTypes, selectGroupContactsWorks} from '../store.js';

const mapStateToProps = state => {
	let works = fromWorks.selectWorks(state.works);
	let me = selectMe(state);
	let workSummaries = works && works.length > 0 && works.map(({workId}) => selectWorkSummary(state, workId));
	const workTypes = selectWorkTypes(state)

	let workTypeList = workTypes && workTypes.length > 0 && workTypes.reduce((acc, m) => {
			let option = {id: m.workTypeId, label: m.name}
			return acc = acc && acc.length > 0 ? acc.concat(option) : [option]
		},[])

	return {
		personId: me.personId,
		group: selectGroupContactsWorks(state),
		works,
		workTypes,
		workTypeList,
		workSummaries,
	}
}

const bindActionsToDispatch = dispatch => ({
	getWorks: (personId) => dispatch(actionWorks.init(personId)),
	getWorkTypes: () => dispatch(actionWorks.getWorkTypes()),
	deleteWork: (personId, workId) => dispatch(actionWorks.deleteWork(personId, workId)),
	getGroupContactsWorks: (personId, groupId) => dispatch(actionGroups.getGroupContactsWorks(personId, groupId)),
	assignWorkToGroup: (personId, workId, groupId, isDelete) => dispatch(actionGroups.assignWorkToGroup(personId, workId, groupId, isDelete)),
});

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
);

function Container(props) {
	const {personId, getWorks, getGroupContactsWorks, getWorkTypes} = props
	const params = useParams()

	useEffect(() => {
		if (personId && personId !== guidEmpty) {
			getWorks(personId)
			getGroupContactsWorks(personId, params.groupId)
		}
		getWorkTypes()
	}, [personId])

	return <GroupWorksView {...props} />
}

export default storeConnector(Container);
