import * as types from '../actions/actionTypes';

export default function(state={}, action) {
    switch(action.type) {
        case types.FETCHING_RECORD: {
            let newFetch = Object.assign({}, state, action.payload);
            return newFetch;
        }
        default:
            return state;
    }
}

export const selectFetchingRecord = (state) => state;
