import React from 'react';
import globalStyles from '../../utils/globalStyles.module.css';
import styles from './EditorInviteOptionsView.module.css';
import InvitesPending from '../../components/InvitesPending';
import OneFJefFooter from '../../components/OneFJefFooter';
import ContactsAddChoice from '../../components/ContactsAddChoice'

function EditorInviteOptionsView(props) {
  const {
    personId,
    editorInvitePending,
    deleteInvite,
    acceptInvite,
    resendInvite,
    sendFirstNotifications,
    getInvitesPending
  } = props;

  return (
		<div className={styles.container}>
      <div className={styles.mainDiv}>
        <span className={globalStyles.pageTitle}>Invite New Editor</span>
        <div style={{marginTop: '20px', marginBottom: '40px'}}>
          <ContactsAddChoice label={' '} />
        </div>
        <InvitesPending 
          invites={editorInvitePending} 
          personId={personId} 
          deleteInvite={deleteInvite}
          sendFirstNotifications={sendFirstNotifications}
          getInvitesPending={getInvitesPending}
          acceptInvite={acceptInvite}
          resendInvite={resendInvite}/>
        <OneFJefFooter/>
      </div>
		</div>
	);
}

export default EditorInviteOptionsView;
