import React, {useState, useEffect} from 'react';
import classes from 'classnames';
import styles from './WorkFilter.module.css';
import RadioGroup from '../RadioGroup';
import DateTimePicker from '../DateTimePicker';
import SelectSingleDropDown from '../SelectSingleDropDown';
import InputText from '../InputText';
import Icon from '../Icon';

//The work filter has the capacity to provide saved and named versions of a search to be used in the future.
//Since the workFilter record is saved persistently with any movement, that gives us the advantage to update an existing saved searchText
//  or to name the current search.  However, that means that a "scratch" record needs to be kept at all times.  We'll keep track of that
//  with a flag called ScratchFlag. That record will probably never have a name associated with it and it won't be included in the savedSearch
//  list.  When a record is chosen, however, it will be overwritten so that that Scratch record can be used to update an existing savedSearch
//  but keep that original savedSearch in tact until the user wants to update criteria, rename it or even delete it.
//The savedSearch list will be kept track of locally.
//There is the option for one of the savedSearch-es to be the default search when the page comes up for the first time.
function WorkFilter(props) {
  const {workFilter, hideSourceStatus, updateFilterByField} = props;

  const [errorSearchName, setErrorSearchName] = useState('');
  const [searchText, setSearchText] = useState('');
  const [savedSearchName, setSavedSearchName] = useState();

  useEffect(() => {
      document.getElementById('searchText').value = props.workFilter.searchText
  }, []);

  const avoidDuplicateSearchName = () => {
      const {savedSearchOptions} = props;
      let noDuplicate = true;

      if (!savedSearchName) return true;

      savedSearchOptions && savedSearchOptions.length > 0 && savedSearchOptions.forEach(m => {
          if (m.label.toLowerCase() === String(savedSearchName).toLowerCase()) {
              noDuplicate = false;
              setErrorSearchName('Duplicate name.')
          }
      })
      return noDuplicate;
  }

  const handleSearchTitleEnterKey = (event) => {
      event.key === "Enter" && handleSearchTextSubmit();
  }

  const handleSaveSearchEnterKey = (event) => {
      if (event.key === "Enter" && avoidDuplicateSearchName()) {
          handleSearchNameSubmit();
      }
  }

  const handleSearchNameChange = (event) => {
    setSavedSearchName(event.target.value)
    setErrorSearchName('');
  }

  const handleSearchTextChange = (event) => {
      setSearchText(document.getElementById('searchText').value);
  }

  const handleSearchNameSubmit = () => {
      const {saveNewSavedSearch, personId} = props;

      if (savedSearchName && avoidDuplicateSearchName()) {
          saveNewSavedSearch(personId, savedSearchName)
          setSavedSearchName('');
      } else if (!savedSearchName) {
          setErrorSearchName('Search name is missing.')
      }
  }

  const handleSearchTextSubmit = () => {
      const {updateFilterByField, personId} = props;
      updateFilterByField(personId, "searchText", searchText);
  }

  const handleOwnerType = (value) => {
      const {updateFilterByField, personId} = props;
      updateFilterByField(personId, "sourceChosen", value);
      if (value === "all") {
          updateFilterByField(personId, "mine", true);
          updateFilterByField(personId, "others", true);
      } else if (value === "mine") {
          updateFilterByField(personId, "mine", true);
          updateFilterByField(personId, "others", false);
      } else if (value === "others") {
          updateFilterByField(personId, "mine", false);
          updateFilterByField(personId, "others", true);
      }
  }

  const handleStatus = (value) => {
    const {updateFilterByField, personId} = props;
    updateFilterByField(personId, "statusChosen", value);
    if (value === "all") {
        updateFilterByField(personId, "active", true);
        updateFilterByField(personId, "completed", true);
    } else if (value === "active") {
        updateFilterByField(personId, "active", true);
        updateFilterByField(personId, "completed", false);
    } else if (value === "completed") {
        updateFilterByField(personId, "active", false);
        updateFilterByField(personId, "completed", true);
    }
  }

  let sourceOptions = [
    {
      label: "All",
      id: "all"
    },
    {
      label: "Mine",
      id: "mine"
    },
    {
      label: "Others'",
      id: "others"
    },
  ];
  let statusOptions = [
    {
      label: "All",
      id: "all"
    },
    {
      label: "Active",
      id: "active"
    },
    {
      label: "Completed",
      id: "completed"
    },
  ];
  let orderByOptions = [
    {
      label: "Due date",
      id: "chapterDueDate"
    },
    {
      label: "Title",
      id: "title"
    },
    {
      label: "Modified Most Recently",
      id: "lastUpdate"
    },
    {
      label: "Project",
      id: "project"
    },
  ];
  let orderSortOptions = [
    {
      label: "Ascending",
      id: "asc"
    },
    {
      label: "Descending",
      id: "desc"
    },
  ];


  return (
      <div className={styles.container}>
          <div>
              <div className={styles.row}>
                  <span className={styles.textSave}>Save search</span>
                  <InputText
                      size={"medium"}
                      name={"name"}
                      value={savedSearchName}
                      onChange={handleSearchNameChange}
                      onEnterKey={handleSaveSearchEnterKey}
                      error={errorSearchName} />
                  <a onClick={handleSearchNameSubmit} className={styles.linkStyle}>
                      <Icon pathName={`plus`} className={styles.image}/>
                  </a>
              </div>
              <hr/>
              <div className={!hideSourceStatus ? styles.row : styles.hidden}>
                  <span className={styles.text}>Owner-type</span>
                  <RadioGroup
                      data={sourceOptions}
                      name={`sourceFilter`}
                      horizontal={true}
                      className={classes(styles.radio, (hideSourceStatus ? styles.hidden : ''))}
                      labelClass={styles.radioLabels}
                      radioClass={styles.radioClass}
                      initialValue={workFilter.sourceChosen ? workFilter.sourceChosen : ''}
                      onClick={(event) => handleOwnerType(event)}
                      personId={workFilter.personId}/>
              </div>
              <hr className={!hideSourceStatus ? styles.divider : styles.hidden}/>
              <div className={!hideSourceStatus ? styles.row : styles.hidden}>
                  <span className={styles.text}>Status</span>
                  <RadioGroup
                      data={statusOptions}
                      name={`statusFilter`}
                      horizontal={true}
                      className={classes(styles.radio, (hideSourceStatus ? styles.hidden : ''))}
                      labelClass={styles.radioLabels}
                      radioClass={styles.radioClass}
                      initialValue={workFilter.statusChosen ? workFilter.statusChosen : ''}
                      onClick={(event) => handleStatus(event)}
                      personId={workFilter.personId}/>
              </div>
              <hr className={!hideSourceStatus ? styles.divider : styles.hidden}/>
              <div className={styles.row}>
                  <span className={styles.text}>Due date</span>
                  <div>
                      <div className={styles.dateRow}>
                          <span className={styles.text}>from:</span>
                          <DateTimePicker id={`dueDateFrom`} value={workFilter.dueDateFrom} maxDate={workFilter.dueDateTo}
                              onChange={(event) => updateFilterByField(workFilter.personId, "dueDateFrom", event.target.value)}/>
                      </div>
                      <div className={styles.dateRow}>
                          <span className={styles.text}>to:</span>
                          <DateTimePicker id={`dueDateTo`} value={workFilter.dueDateTo} minDate={workFilter.dueDateFrom ? workFilter.dueDateFrom : ''}
                              onChange={(event) => updateFilterByField(workFilter.personId, "dueDateTo", event.target.value)}/>
                      </div>
                  </div>
              </div>
              <hr className={styles.divider}/>
              <div className={styles.row}>
                  <span className={styles.textSave}>Search title</span>
                  <InputText
                      size={"medium"}
                      name={"searchText"}
                      value={searchText}
                      onChange={handleSearchTextChange}
                      onEnterKey={handleSearchTitleEnterKey}
                      inputClassName={styles.inputClassName}
                      labelClass={styles.labelClass} />
                  <a onClick={handleSearchTextSubmit} className={styles.linkStyle}>
                      <Icon pathName={`checkmark`} className={styles.image}/>
                  </a>
              </div>
              <hr className={styles.divider}/>
              <div className={styles.row}>
                  <div>
                      <SelectSingleDropDown
                          value={workFilter.orderByChosen ? workFilter.orderByChosen : ''}
                          options={orderByOptions}
                          label={`Order by`}
                          error={''}
                          height={`medium`}
                          noBlank={true}
                          className={styles.singleDropDown}
                          onChange={(event) => updateFilterByField(workFilter.personId, "orderByChosen", event.target.value)} />
                  </div>
                  <div>
                      <SelectSingleDropDown
                          value={workFilter.orderSortChosen ? workFilter.orderSortChosen : ''}
                          options={orderSortOptions}
                          label={`Sort direction`}
                          error={''}
                          noBlank={true}
                          height={`medium`}
                          className={styles.singleDropDown}
                          onChange={(event) => updateFilterByField(workFilter.personId, "orderSortChosen", event.target.value)} />
                  </div>
              </div>
          </div>
      </div>
  )
}


// <Checkbox
//     id={`filterDefault`}
//     label={`Default`}
//     labelClass={styles.labelCheckbox}
//     position={`before`}
//     defaultValue={workFilter && workFilter.defaultFlag}
//     checked={workFilter && workFilter.defaultFlag}
//     {...tapOrClick(() => updateFilterByField(personId, "defaultFlag", event.target.value))}
//     className={styles.checkbox} />

export default WorkFilter;