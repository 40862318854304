import React, { useState, useRef, useEffect } from 'react';  //PropTypes
import styles from './TextareaModal.module.css';
import {useMediaQuery} from 'react-responsive'
import {Alert, AlertTitle} from '@mui/material';
import ButtonWithIcon from '../ButtonWithIcon'
import classes from 'classnames';

function TextareaModal(props) {
  const {show, onClick, onDelete, handleClose, className, explainClass, heading, explain, placeholder} = props;
  let {currentSentenceText} = props;
  const isMobile = useMediaQuery({query: '(max-width: 500px)'})
  const containerRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     let isMessage = false
  //     let element = event.target
  //     let loop = 0
  //     while (element && loop < 10 && !isMessage) {
  //       isMessage = Array.from(element.classList).some(className => className.includes('ButtonWithIcon'))
  //       element = element.nextSibling
  //       loop++
  //     }
  //     if (containerRef.current && (!containerRef.current.contains(event.target) || isMessage)) {
  //       handleClose()
  //     }
  //   }
  //   const handleKeyDown = (event) => {
  //     if (event.key === 'Escape' && containerRef.current) { // ESC key
  //       handleClose()
  //     }
  //   };
  //   document.addEventListener('mousedown', handleClickOutside);
  //   document.addEventListener('keydown', handleKeyDown);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //     document.removeEventListener('keydown', handleKeyDown);
  //   }
  // }, [show]);

  let regex = "/<(.|\n)*?>/"
  currentSentenceText = currentSentenceText &&
    currentSentenceText.replace(regex, "")
      .replace(/<br>/g, "")
      .replace(/<[^>]*>/g, ' ')
      .replace(/\s{2,}/g, ' ')
      .trim();

  let newComment = props.commentText ? props.commentText : '';
  newComment = newComment && newComment.replace(regex, "")
    .replace(/<br>/g, "")
    .replace(/<[^>]*>/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim();

  const [comment, setComment] = useState(newComment || '');

  const handleChange = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setComment(event.target.value);
  }

  return !show ? null : (
    <div className={className ? className : isMobile ? styles.containerMobile : styles.container} ref={containerRef}>
      <Alert severity='info' style={{
        borderRadius: '0 25px 0 25px',
        backgroundColor: 'white',
        boxShadow: '-9px 16px 69px 2px rgba(118,122,122,1)',
      }}>
        <div className={styles.currentSentence}>{currentSentenceText && currentSentenceText.replace(regex, "").substring(0, 100) + `...`}</div>
        <AlertTitle>{heading}</AlertTitle>
        <p className={classes(styles.dialogExplain, explainClass)}>{explain}</p>
        <textarea rows={5} cols={30} value={comment} onChange={handleChange} placeholder={placeholder} className={styles.commentBox} />
        <div className={styles.rowButtons}>
          {onDelete && <a className={styles.noButton} onClick={onDelete}>Delete</a>}
          <a className={styles.noButton} onClick={handleClose}>Cancel</a>
          <ButtonWithIcon icon={'checkmark0'} label={'Save'} onClick={() => { onClick(comment); setComment('')}} addClassName={styles.buttonWidth} smaller/>
        </div>
      </Alert>
    </div>
  )
}

export default TextareaModal;