import React, {useState} from 'react';
import styles from './SelectBetweenLists.module.css';
import classes from 'classnames';
import Required from '../Required'
import ButtonWithIcon from '../ButtonWithIcon'

export default ({
                  leftLabel,
                  rightLabel,
	                id,
	                onChange,
                  work,
                  options,
	                values,
	                error,
	                height,
	                className = "",
	                labelClass = "",
	                selectClass = "",
	                indexName,
									required,
									whenFilled,
                }) => {

  const [chosenLeft, setChosenLeft] = useState([])
  const [chosenRight, setChosenRight] = useState([])

  const onLeftChange = (selectElement) => {
    let newLeft = [];
    for (let i=0; i < selectElement.options.length; i++) {
      if (selectElement.options[i].selected) newLeft.push(selectElement.options[i].value);
    }
    setChosenLeft(newLeft)
  }

  const onRightChange = (selectElement) => {
    let newRight = [];
    for (let i=0; i < selectElement.options.length; i++) {
      if (selectElement.options[i].selected) newRight.push(selectElement.options[i].value);
    }
    setChosenRight(newRight)
  }

  const moveChosen = () => {
    let newValues = values && values.length > 0 && values.reduce((acc, id) => {
      acc = acc && acc.length > 0 ? acc.concat(id) : [id]
      return acc
    }, []) || []
    chosenLeft && chosenLeft.length > 0 && chosenLeft.forEach(value => {
      if (newValues.indexOf(value) === -1) newValues.push(value)
    })
    onChange({...work, workOwners: newValues})
  }

  const removeChosen = () => {
    let newValues = values && values.length > 0 && values.reduce((acc, id) => {
      acc = acc && acc.length > 0 ? acc.concat(id) : [id]
      return acc
    }, []) || []
    chosenRight && chosenRight.length > 0 && chosenRight.forEach(id => {
      if (newValues.indexOf(id) > -1) newValues.splice(newValues.indexOf(id), 1)
    })
    onChange({ ...work, workOwners: newValues })
  }


  return (
		<div className={classes(styles.container, className)}>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.row}>
            {leftLabel && <span htmlFor={id} className={classes(required ? styles.requiredLabelText : styles.labelText, labelClass)}>{leftLabel}</span>}
            <Required setIf={required} setWhen={whenFilled}/>
          </div>
          <div>
            <select
              multiple
              id={'leftSide'}
              //value={chosenLeft}
              onChange={(event) => onLeftChange(event.target)}
              className={classes(styles.editControl, selectClass)}
            >
              {options && options.length > 0 && options.map((option, index) => {
                  //let selected = chosenLeft && chosenLeft.length > 0 && chosenLeft.indexOf(option.id) > -1 ? 'selected' : ''
                  return <option key={index} value={option.id} dangerouslySetInnerHTML={{__html: option.label}} ></option>
                // selected={selected}
                })
              }
            </select>
          </div>
        </div>
        <div className={styles.buttons}>
          <ButtonWithIcon icon={'play3'} isPremiumIcon={false} label={'Add'} onClick={moveChosen} />
          <ButtonWithIcon icon={'trash2'} label={'Remove'} onClick={removeChosen} />
        </div>
        <div className={styles.column}>
          {rightLabel && <span htmlFor={id} className={classes(required ? styles.requiredLabelText : styles.labelText, labelClass)}>{rightLabel}</span>}
          <div>
            <select
              multiple
              id={'rightSide'}
              onChange={(event) => onRightChange(event.target)}
              className={classes(styles.editControl, selectClass)}
            >
              {values && values.length > 0 && values.map((id, index) => {
                  let option = options && options.length > 0 && options.filter(m => m.id === id)[0]
                  if (option) return <option key={index} value={option.id} dangerouslySetInnerHTML={{__html: option.label}}></option>
                })
              }
              {!(values && values.length > 0) &&
                <option value={''}>{`                  `}</option>
              }
            </select>
          </div>
        </div>
      </div>
			{error && <div className={styles.alertMessage}>{error}</div>}
		</div>
	)
};
