import * as types from '../actions/actionTypes';

export default function (state = [], action) {
	switch (action.type) {
		case types.LIST_LEVELS_INIT:
			return action.payload

		default:
			return state;
	}
}

export const selectListLevelGeneral = (state) => state;
