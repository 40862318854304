import React, {useState} from 'react';
import classes from 'classnames';
import styles from './SearchTextTool.module.css';
import InputText from '../InputText/InputText.js';
import Icon from '../Icon';

function SearchTextTools(props) {
    let {className="", pointer, totalCount, jumpToSearch, submitSearchText, setEditOptionTools, hideLeftArrow} = props;
    pointer = pointer ? pointer : 0;
    totalCount = totalCount ? totalCount : 0;

    const [searchText, setSearchText] = useState('');

    const handleTextEntry = (evt) => {
        setSearchText(evt.target.value);
    }

    return (
        <div className={classes(className, styles.container)}>
            {!hideLeftArrow &&
                <a className={styles.closeButton} onClick={setEditOptionTools}>
                    <Icon pathName={`left_arrow`} />
                </a>
            }
            <InputText
                value={searchText}
                size={"medium"}
                height={"medium"}
                noShadow={true}
                name={"searchText"}
                onChange={handleTextEntry}
                placeholder={"Search text"}/>
            <a onClick={() => submitSearchText(searchText)}>
                <Icon pathName={`checkmark`} className={styles.submitButton}/>
            </a>
            <div className={styles.navRow}>
                <a onClick={() => jumpToSearch('NEXT')}>
                    <Icon pathName={`arrow_down`} className={styles.downArrow} />
                </a>
                <span className={styles.counts}>
                    {pointer + ` of ` + totalCount}
                </span>
                <a onClick={() => jumpToSearch('PREV')}>
                    <Icon pathName={`arrow_up`} className={styles.upArrow} />
                </a>
            </div>
        </div>
    )
}

export default SearchTextTools;