import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router'
import styles from './AssessmentQuestionsView.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import MessageModal from '../../../components/MessageModal'
import AssessmentItemModal from '../../../components/Assessment/AssessmentItemModal'
import TextDisplay from '../../../components/TextDisplay'
import LinkDisplay from '../../../components/LinkDisplay'
import TextareaModal from '../../../components/TextareaModal'
import ButtonWithIcon from '../../../components/ButtonWithIcon'
import SelectSingleDropDown from '../../../components/SelectSingleDropDown'
import Checkbox from '../../../components/Checkbox'
import Icon from '../../../components/Icon'
import classes from 'classnames'
import AssessmentTrueFalse from '../../../components/Assessment/AssessmentTrueFalse'
import AssessmentMultipleChoice from '../../../components/Assessment/AssessmentMultipleChoice'
import AssessmentMultipleAnswer from '../../../components/Assessment/AssessmentMultipleAnswer'
import AssessmentSingleEntry from '../../../components/Assessment/AssessmentSingleEntry'
import AssessmentFillBlank from '../../../components/Assessment/AssessmentFillBlank'
import AssessmentMatching from '../../../components/Assessment/AssessmentMatching'
import AssessmentEssay from '../../../components/Assessment/AssessmentEssay'
import AssessmentPassage from '../../../components/Assessment/AssessmentPassage'
import AssessmentPictureAudio from '../../../components/Assessment/AssessmentPictureAudio'
import OneFJefFooter from '../../../components/OneFJefFooter'
import ReactToPrint from "react-to-print"
import { createConfirmToastAuto } from '../../../services/queryClient.js'

function AssessmentQuestionsView(props) {
  const {
    addOrUpdateAssessmentItem,
    addOrUpdateAssessmentItemMatching,
    assessment = {},
    assessmentId,
    assessmentQuestions,
    assessmentQuestionsInit,
    assignmentId,
    benchmarkTestId,
    companyConfig = {},
    courseEntryName,
    gradingType,
    isAuthor,
    personId,
    questionTypes,
    removeAssessmentQuestion,
    removeAssessmentQuestionAnswerFile,
    removeAssessmentQuestionAnswerOption,
    removeAssessmentQuestionAnswerRecording,
    removeAssessmentQuestionFileUpload,
    removeAssessmentQuestionQuestionFile,
    removeAssessmentQuestionQuestionRecording,
    removeAssessmentQuestionSolutionFile,
    removeAssessmentQuestionSolutionRecording,
    removeAssessmentQuestionToMatchFile,
    removeAssessmentQuestionWebsiteLink,
    reorderAssessmentQuestions,
    retakeOptions,
    saveAssessmentQuestionWebsiteLink,
    sequences,
    togglePublishedAssessment,
    updateAssessmentSettings,
    updateAssessmentTotalPoints,
    workSummary,
    listLevelGeneral,
    chapterListLevels,
  } = props

  const navigate = useNavigate()
  let componentRef = useRef(null)

  const [answerIndex, setAnswerIndex] = useState()
  const [assessmentQuestionId, setAssessmentQuestionId] = useState()
  const [deleted_fileUploadId, setDeleted_fileUploadId] = useState()
  const [deleted_recordingFileUploadId, setDeleted_recordingFileUploadId] = useState()
  const [fileUploadId, setFileUploadId] = useState()
  const [isEditMode, setIsEditMode] = useState()
  const [isShowingFileUpload, setIsShowingFileUpload] = useState(false)
  const [isShowingModal_addOrUpdate, setIsShowingModal_addOrUpdate] = useState(false)
  const [isShowingModal_cannotChange, setIsShowingModal_cannotChange] = useState()
  const [isShowingModal_pointsError, setIsShowingModal_pointsError] = useState(false)
  const [isShowingModal_removeAnswerFile, setIsShowingModal_removeAnswerFile] = useState(false)
  const [isShowingModal_removeAnswerOption, setIsShowingModal_removeAnswerOption] = useState(false)
  const [isShowingModal_removeAnswerRecording, setIsShowingModal_removeAnswerRecording] = useState(false)
  const [isShowingModal_removeFileUpload, setIsShowingModal_removeFileUpload] = useState(false)
  const [isShowingModal_removeQuestion, setIsShowingModal_removeQuestion] = useState(false)
  const [isShowingModal_removeQuestionFile, setIsShowingModal_removeQuestionFile] = useState(false)
  const [isShowingModal_removeQuestionRecording, setIsShowingModal_removeQuestionRecording] = useState(false)
  const [isShowingModal_removeSolutionFile, setIsShowingModal_removeSolutionFile] = useState(false)
  const [isShowingModal_removeSolutionRecording, setIsShowingModal_removeSolutionRecording] = useState(false)
  const [isShowingModal_removeToMatchFile, setIsShowingModal_removeToMatchFile] = useState()
  const [isShowingModal_removeWebsiteLink, setIsShowingModal_removeWebsiteLink] = useState(false)
  const [isShowingModal_websiteLink, setIsShowingModal_websiteLink] = useState(false)
  const [multipleAnswerType, setMultipleAnswerType] = useState(false)
  const [recordingFileUploadId, setRecordingFileUploadId] = useState()
  const [replacedTotalPoints, setReplacedTotalPoints] = useState()
  const [subTotalPoints, setSubTotalPoints] = useState()
  const [websiteLink, setWebsiteLink] = useState()

  const handleFileUploadOpen = (assessmentQuestionId) => {
    setIsShowingFileUpload(true)
    setAssessmentQuestionId(assessmentQuestionId)
  }
  const handleFileUploadClose = () => setIsShowingFileUpload(false)
  const handleSubmitFile = () => {
    assessmentQuestionsInit(personId, personId, assessmentId, assignmentId)
    handleFileUploadClose()
  }

  const recallAfterFileUpload = () => {
    assessmentQuestionsInit(personId, personId, assessmentId, assignmentId)
  }

  const handleAddOrUpdateQuestionOpen = (assessmentQuestionId, isEditMode = false) => {
    setIsShowingModal_addOrUpdate(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setIsEditMode(isEditMode)
  }

  const handleAddOrUpdateQuestionClose = () => {
    setIsShowingModal_addOrUpdate(false)
    setIsEditMode(false)
  }

  const handleAddOrUpdateQuestionSave = (assessmentQuestion) => {
    if (assessmentQuestion.questionTypeCode === 'MATCHING') {
      addOrUpdateAssessmentItemMatching(personId, assessmentId, assessmentQuestion)
    } else {
      addOrUpdateAssessmentItem(personId, assessmentId, assessmentQuestion)
    }
  }

  const handleRemoveQuestionOpen = (assessmentQuestionId) => {
    setIsShowingModal_removeQuestion(true)
    setAssessmentQuestionId(assessmentQuestionId)
  }
  const handleRemoveQuestionClose = () => setIsShowingModal_removeQuestion(false)
  const handleRemoveQuestion = () => {
    removeAssessmentQuestion(personId, assessmentId, assessmentQuestionId)
    handleRemoveQuestionClose()
  }

  const handleRemoveWebsiteLinkOpen = (assessmentQuestionId, websiteLink) => {
    setIsShowingModal_removeWebsiteLink(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setWebsiteLink(websiteLink)
  }
  const handleRemoveWebsiteLinkClose = () => setIsShowingModal_removeWebsiteLink(false)
  const handleRemoveWebsiteLink = () => {
    removeAssessmentQuestionWebsiteLink(personId, assessmentQuestionId, websiteLink)
    handleRemoveWebsiteLinkClose()
  }

  const handleRemoveFileUploadOpen = (assessmentQuestionId, fileUploadId) => {
    setIsShowingModal_removeFileUpload(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setFileUploadId(fileUploadId)
  }
  const handleRemoveFileUploadClose = () => setIsShowingModal_removeFileUpload(false)
  const handleRemoveFileUpload = () => {
    removeAssessmentQuestionFileUpload(personId, assessmentQuestionId, fileUploadId)
    handleRemoveFileUploadClose()
  }

  const handleRemoveQuestionFileOpen = (assessmentQuestionId, fileUploadId) => {
    setIsShowingModal_removeQuestionFile(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setFileUploadId(fileUploadId)
  }
  const handleRemoveQuestionFileClose = () => setIsShowingModal_removeQuestionFile(false)
  const handleRemoveQuestionFile = () => {
    removeAssessmentQuestionQuestionFile(personId, assessmentQuestionId, fileUploadId)
    handleRemoveQuestionFileClose()
    setDeleted_fileUploadId(fileUploadId)
  }

  const handleRemoveQuestionRecordingOpen = (event, assessmentQuestionId, recordingFileUploadId) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    setIsShowingModal_removeQuestionRecording(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setRecordingFileUploadId(recordingFileUploadId)
  }
  const handleRemoveQuestionRecordingClose = () => setIsShowingModal_removeQuestionRecording(false)
  const handleRemoveQuestionRecording = () => {
    removeAssessmentQuestionQuestionRecording(personId, assessmentQuestionId, recordingFileUploadId)
    handleRemoveQuestionRecordingClose()
    setDeleted_recordingFileUploadId(recordingFileUploadId)
  }

  const handleRemoveAnswerFileOpen = (assessmentQuestionId, fileUploadId) => {
    setIsShowingModal_removeAnswerFile(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setFileUploadId(fileUploadId)
  }
  const handleRemoveAnswerFileClose = () => setIsShowingModal_removeAnswerFile(false)
  const handleRemoveAnswerFile = () => {
    removeAssessmentQuestionAnswerFile(personId, assessmentQuestionId, fileUploadId)
    handleRemoveAnswerFileClose()
    setDeleted_fileUploadId(fileUploadId)
  }

  const handleRemoveToMatchFileOpen = (assessmentQuestionId, fileUploadId) => {
    setIsShowingModal_removeToMatchFile(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setFileUploadId(fileUploadId)
  }
  const handleRemoveToMatchFileClose = () => setIsShowingModal_removeToMatchFile(false)
  const handleRemoveToMatchFile = () => {
    removeAssessmentQuestionToMatchFile(personId, assessmentQuestionId, fileUploadId)
    handleRemoveToMatchFileClose()
    setDeleted_fileUploadId(fileUploadId)
  }

  const handleRemoveAnswerOptionOpen = (assessmentQuestionId, answerIndex) => {
    setIsShowingModal_removeAnswerOption(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setAnswerIndex(answerIndex)
  }

  const handleRemoveAnswerOptionClose = () => {
    setIsShowingModal_removeAnswerOption(false)
    setAssessmentQuestionId('')
    setAnswerIndex('')
  }

  const handleRemoveAnswerOption = () => {
    removeAssessmentQuestionAnswerOption(personId, assessmentQuestionId, answerIndex) //, () => handleAddOrUpdateQuestionOpen(assessmentQuestionId, true)
    handleRemoveAnswerOptionClose()
    handleAddOrUpdateQuestionClose()
  }

  const handleRemoveAnswerRecordingOpen = (assessmentQuestionId, fileUploadId, answerIndex, multipleAnswerType) => {
    setIsShowingModal_removeAnswerRecording(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setFileUploadId(fileUploadId)
    setAnswerIndex(answerIndex)
    setMultipleAnswerType(multipleAnswerType)
  }

  const handleRemoveAnswerRecordingClose = () => {
    setIsShowingModal_removeAnswerRecording(false)
    setAnswerIndex('')
    setMultipleAnswerType('')
  }

  const handleRemoveAnswerRecording = () => {
    removeAssessmentQuestionAnswerRecording(personId, assessmentQuestionId, fileUploadId)
    handleRemoveAnswerRecordingClose()
    setDeleted_fileUploadId(fileUploadId)
  }

  const handleRemoveSolutionFileOpen = (assessmentQuestionId, fileUploadId) => {
    setIsShowingModal_removeSolutionFile(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setFileUploadId(fileUploadId)
  }

  const handleRemoveSolutionFileClose = () => setIsShowingModal_removeSolutionFile(false)
  const handleRemoveSolutionFile = () => {
    removeAssessmentQuestionSolutionFile(personId, assessmentQuestionId, fileUploadId)
    handleRemoveSolutionFileClose()
    setDeleted_fileUploadId(fileUploadId)
  }

  const handleRemoveSolutionRecordingOpen = (assessmentQuestionId, fileUploadId) => {
    setIsShowingModal_removeSolutionRecording(true)
    setAssessmentQuestionId(assessmentQuestionId)
    setFileUploadId(fileUploadId)
  }

  const handleRemoveSolutionRecordingClose = () => setIsShowingModal_removeSolutionRecording(false)
  const handleRemoveSolutionRecording = () => {
    removeAssessmentQuestionSolutionRecording(personId, assessmentQuestionId, fileUploadId)
    handleRemoveSolutionRecordingClose()
    setDeleted_fileUploadId(fileUploadId)
  }

  const handleWebsiteLinkOpen = (assessmentQuestionId) => {
    setIsShowingModal_websiteLink(true)
    setAssessmentQuestionId(assessmentQuestionId)
  }

  const handleWebsiteLinkClose = () => setIsShowingModal_websiteLink(false)
  const handleWebsiteLinkSave = (websiteLink) => {
    saveAssessmentQuestionWebsiteLink(personId, assessmentQuestionId, websiteLink)
    handleWebsiteLinkClose()
  }

  const handlePointsErrorClose = () => {
    setIsShowingModal_pointsError(false)
    togglePublishedAssessment(personId, assessment.assessmentId)
  }

  const handlePointsErrorSave = () => {
    handlePointsErrorClose()
    updateAssessmentTotalPoints(personId, assessmentId, subTotalPoints)
    togglePublishedAssessment(personId, assessment.assessmentId)
    setReplacedTotalPoints(subTotalPoints)
  }

  const reorderSequence = (assessmentQuestionId, event) => {
    reorderAssessmentQuestions(personId, assessmentQuestionId, event.target.value)
  }

  const handlePublish = () => {
    let pointsIntended = assessment && assessment.totalPoints
    let subTotalPoints = (assessmentQuestions && assessmentQuestions.length > 0 && assessmentQuestions.reduce((acc, m) => acc += m.pointsPossible, 0)) || 0
    if (pointsIntended !== subTotalPoints && (!assessment || !assessment.isPublished)) {
      let subTotalPoints = (assessmentQuestions && assessmentQuestions.length > 0 && assessmentQuestions.reduce((acc, m) => acc += m.pointsPossible, 0)) || 0
      setIsShowingModal_pointsError(true)
      setSubTotalPoints(subTotalPoints)
    }
    togglePublishedAssessment(personId, assessment.assessmentId)
  }

  const showViewOnlyMessage = () => {
    createConfirmToastAuto('<div>The controls are for view only to show the correct answers.</div>')
  }

  const handleCannotChangeOpen = () => setIsShowingModal_cannotChange(true)
  const handleCannotChangeClose = () => setIsShowingModal_cannotChange(false)

  const toggleCheckbox = (field, event) => {
    updateAssessmentSettings(personId, assessmentId, field, !assessment[field])
  }

  const handleChange = (event) => {
    updateAssessmentSettings(personId, assessmentId, event.target.name, event.target.value)
  }

  let assessmentItem = assessmentQuestionId
    ? assessmentQuestions?.length > 0
      ? assessmentQuestions.filter(m => m.assessmentQuestionId === assessmentQuestionId)[0]
      : {}
    : {}
    
  return (
    <div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={globalStyles.pageTitle}>
          Assessment Questions
        </div>
        <div className={classes(styles.moreTop, styles.rowWrap)}>
          {courseEntryName &&
          <TextDisplay label={`Course`} text={courseEntryName} clickFunction={() => navigate(-1)} />}
          <TextDisplay label={benchmarkTestId ? 'Benchmark assessment' : 'Assessment'} text={assessment && assessment.name} />
          <TextDisplay label={'Points intended'} text={replacedTotalPoints || (assessment && assessment.totalPoints)} />
          <TextDisplay label={'Points sub total'} text={assessmentQuestions.reduce((acc, m) => acc += m.pointsPossible, 0)} />
          <div className={styles.printPosition}>
            <ReactToPrint trigger={() => <a href="#"
              className={classes(styles.moveDownRight, styles.link, styles.row)}><Icon
                pathName={'printer'} premium={true} className={styles.icon} />Print</a>} content={() => componentRef} />
          </div>
        </div>
        <div className={classes(styles.moreTop, styles.rowWrap)}>
          <Checkbox
            id={'bigTextDisplay'}
            name={'bigTextDisplay'}
            label={'Big text display'}
            labelClass={styles.checkboxLabel}
            checked={assessment.bigTextDisplay || false}
            onClick={(event) => toggleCheckbox('bigTextDisplay', event)}
            className={styles.checkbox} />
          <Checkbox
            id={'oneAtAtimeView'}
            name={'oneAtAtimeView'}
            label={'One question at a time'}
            labelClass={styles.checkboxLabel}
            checked={assessment.oneAtAtimeView || false}
            onClick={(event) => toggleCheckbox('oneAtAtimeView', event)}
            className={styles.checkbox} />
          <Checkbox
            id={'doNotShowAnswersImmediately'}
            name={'doNotShowAnswersImmediately'}
            label={'Do not show answers immediately after correction'}
            labelClass={styles.checkboxLabel}
            checked={assessment.doNotShowAnswersImmediately || false}
            onClick={(event) => toggleCheckbox('doNotShowAnswersImmediately', event)}
            className={styles.checkbox} />
          <Checkbox
            id={'forceAllAnswers'}
            name={'forceAllAnswers'}
            label={'Student must answer all questions'}
            labelClass={styles.checkboxLabel}
            checked={assessment.forceAllAnswers || false}
            onClick={(event) => toggleCheckbox('forceAllAnswers', event)}
            className={styles.checkbox} />

          <div className={styles.littleLeft}>
            <SelectSingleDropDown
              id={`retakeCount`}
              name={`retakeCount`}
              label={'Re-take attempts'}
              value={assessment.retakeCount || ''}
              options={retakeOptions || []}
              className={styles.moreBottomMargin}
              noBlank={true}
              height={`medium`}
              onChange={handleChange} />
          </div>
        </div>
        <div className={styles.row}>
          <ButtonWithIcon icon={'earth'} label={assessment.isPublished ?
            'Unpublish' : 'Publish'}
            changeRed={assessment.isPublished}
            onClick={handlePublish}
            disabled={!assessmentQuestions || !assessmentQuestions.length} />
          <div className={styles.muchLeft}>
            <Checkbox
              id={'doNotShowAnswersImmediately'}
              name={'doNotShowAnswersImmediately'}
              label={'Hide answers until I say'} //This is the same checkbox as the "Do not show" but it is an interface trick to be sur that the teacher understands that they can turn this on and off ... but just like the student it not allowed to see answers after they finish the test, this is the same value that doesn't allow the student to see the answers until the teacher wants them to see it on the assessment correct view.
              labelClass={styles.checkboxLabel}
              checked={assessment.doNotShowAnswersImmediately || false}
              onClick={(event) => toggleCheckbox('doNotShowAnswersImmediately', event)}
              className={styles.checkbox} />
          </div>
        </div>
        <hr />
        <a
          onClick={assessment.isPublished ? handleCannotChangeOpen : handleAddOrUpdateQuestionOpen}
          className={classes(styles.row, styles.addNew)}>
          <Icon pathName={'plus'}
            className={classes(styles.icon, (assessment.isPublished ? styles.lowOpacity : ''))}
            fillColor={'green'} />
          <span
            className={classes(styles.addAnother, (assessment.isPublished ? styles.lowOpacity : ''))}>Add Another Question</span>
        </a>
        <div ref={componentRef} className={classes(styles.center, styles.componentPrint, styles.maxWidth)}>
          {assessmentQuestions && assessmentQuestions.length > 0 && assessmentQuestions.map((m, i) => {
            if (m.questionTypeCode === 'TRUEFALSE') {
              return (
                <div key={i}>
                  <AssessmentTrueFalse 
                    initialValue={m.correctAnswer}
                    isAuthor={isAuthor}
                    nameKey={i} 
                    onClick={showViewOnlyMessage}
                    personId={personId}
                    question={m}
                    removeQuestionFileOpen={handleRemoveQuestionFileOpen}
                    removeQuestionRecordingOpen={handleRemoveQuestionRecordingOpen} 
                    removeSolutionFileOpen={handleRemoveSolutionFileOpen}
                    removeSolutionRecordingOpen={handleRemoveSolutionRecordingOpen} />
                  <div className={classes(styles.moreLeft, styles.moreTop)}>
                    {m.websiteLinks.length > 0 &&
                      <div>
                        <hr />
                        <span className={styles.label}>Website Link</span>
                        {m.websiteLinks.map((w, i) =>
                          <LinkDisplay key={i} linkText={w} isWebsiteLink={true}
                            deleteFunction={handleRemoveWebsiteLinkOpen}
                            deleteId={m.assessmentQuestionId} />
                        )}
                        <hr />
                      </div>
                    }
                  </div>
                  {isAuthor &&
                    <div className={classes(styles.row, styles.linkRow)}>
                      <div className={styles.littleRight}>
                        <TextDisplay text={m.pointsPossible}
                          label={'Points'} />
                      </div>
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleRemoveQuestionOpen(m.assessmentQuestionId)}
                        className={styles.link}>remove</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleAddOrUpdateQuestionOpen(m.assessmentQuestionId, true)}
                        className={styles.link}>edit</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleWebsiteLinkOpen(m.assessmentQuestionId)}
                        className={styles.link}>add link</a> |
                      {!assessment.isPublished &&
                        <div>
                          <SelectSingleDropDown
                            id={m.assessmentQuestionId}
                            name={m.assessmentQuestionId}
                            label={'Sequence'}
                            labelLeft={true}
                            value={m.sequence}
                            noBlank={true}
                            options={sequences}
                            className={styles.dropdown}
                            onChange={(event) => reorderSequence(m.assessmentQuestionId, event)} />
                        </div>
                      }
                    </div>
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'MULTIPLECHOICE') {
              return (
                <div key={i}>
                  <AssessmentMultipleChoice 
                    isAuthor={isAuthor}
                    isOwner={m.isOwner}
                    nameKey={i} 
                    onClick={showViewOnlyMessage}
                    question={m}
                    removeAnswerFileOpen={handleRemoveAnswerFileOpen}
                    removeAnswerRecordingOpen={handleRemoveAnswerRecordingOpen}
                    removeQuestionFileOpen={handleRemoveQuestionFileOpen}
                    removeQuestionRecordingOpen={handleRemoveQuestionRecordingOpen}
                    removeSolutionFileOpen={handleRemoveSolutionFileOpen}
                    removeSolutionRecordingOpen={handleRemoveSolutionRecordingOpen}
                    removeWebsiteLinkOpen={handleRemoveWebsiteLinkOpen}  />
                  {isAuthor &&
                    <div className={classes(styles.row, styles.linkRow)}>
                      <div className={styles.littleRight}>
                        <TextDisplay text={m.pointsPossible}
                          label={'Points'} />
                      </div>
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleRemoveQuestionOpen(m.assessmentQuestionId)}
                        className={styles.link}>remove</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleAddOrUpdateQuestionOpen(m.assessmentQuestionId, true)}
                        className={styles.link}>edit</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleWebsiteLinkOpen(m.assessmentQuestionId)}
                        className={styles.link}>add link</a> |
                      {!assessment.isPublished &&
                        <div>
                          <SelectSingleDropDown
                            id={m.assessmentQuestionId}
                            name={m.assessmentQuestionId}
                            label={'Sequence'}
                            labelLeft={true}
                            value={m.sequence}
                            noBlank={true}
                            options={sequences}
                            className={styles.dropdown}
                            onChange={(event) => reorderSequence(m.assessmentQuestionId, event)} />
                        </div>
                      }
                    </div>
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'MULTIPLEANSWER') {
              return (
                <div key={i}>
                  <AssessmentMultipleAnswer 
                    isAuthor={isAuthor}
                    isOwnerSetup={showViewOnlyMessage}
                    isSetupList={true} question={m}
                    nameKey={i}
                    removeAnswerFileOpen={handleRemoveAnswerFileOpen}
                    removeAnswerRecordingOpen={handleRemoveAnswerRecordingOpen}
                    removeQuestionFileOpen={handleRemoveQuestionFileOpen}
                    removeQuestionRecordingOpen={handleRemoveQuestionRecordingOpen}
                    removeSolutionFileOpen={handleRemoveSolutionFileOpen}
                    removeSolutionRecordingOpen={handleRemoveSolutionRecordingOpen}
                    removeWebsiteLinkOpen={handleRemoveWebsiteLinkOpen} />
                  {isAuthor &&
                    <div className={classes(styles.row, styles.linkRow)}>
                      <div className={styles.littleRight}>
                        <TextDisplay text={m.pointsPossible}
                          label={'Points'} />
                      </div>
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleRemoveQuestionOpen(m.assessmentQuestionId)}
                        className={styles.link}>remove</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleAddOrUpdateQuestionOpen(m.assessmentQuestionId, true)}
                        className={styles.link}>edit</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleWebsiteLinkOpen(m.assessmentQuestionId)}
                        className={styles.link}>add link</a> |
                      {!assessment.isPublished &&
                        <div>
                          <SelectSingleDropDown
                            id={m.assessmentQuestionId}
                            name={m.assessmentQuestionId}
                            label={'Sequence'}
                            labelLeft={true}
                            value={m.sequence}
                            noBlank={true}
                            options={sequences}
                            className={styles.dropdown}
                            onChange={(event) => reorderSequence(m.assessmentQuestionId, event)} />
                        </div>
                      }
                    </div>
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'ESSAY') {
              return (
                <div key={i}>
                  <AssessmentEssay 
                    isAuthor={isAuthor}
                    keywords={m.keywordPhrases}
                    nameKey={i} 
                    question={m}
                    listLevelGeneral={listLevelGeneral}
                    removeQuestionFileOpen={handleRemoveQuestionFileOpen}
                    removeQuestionRecordingOpen={handleRemoveQuestionRecordingOpen}
                    removeSolutionFileOpen={handleRemoveSolutionFileOpen}
                    removeSolutionRecordingOpen={handleRemoveSolutionRecordingOpen} />
                  <div className={styles.littleLeft}>
                    <div className={styles.instructions}>
                      {m.keywordPhrases && m.keywordPhrases.length > 0
                        ? m.keywordCountAccuracy
                          ? `These keywords and/or phrases are used for automated scoring.`
                          : `These keywords and/or phrases may be used for reference, but automated scoring will not occur without setting the keyword accuracy count.`
                        : `Automated scoring will not occur because keywords and/or phrases are not set.`
                      }
                    </div>
                    <div className={styles.rowWrap}>
                      {isAuthor &&
                        <TextDisplay
                          label={'Keywords or phrases'}
                          text={m.keywordPhrases && m.keywordPhrases.length > 0
                            ? m.keywordPhrases.map((keywordPhrase, i) =>
                              <div key={i} className={classes(styles.labelBold, styles.littleLeft)}>{keywordPhrase}</div>)
                            : <div key={i} className={classes(styles.labelItalicsGray, styles.littleLeft)}>none</div>
                          }
                        />
                      }

                      {isAuthor &&
                        <TextDisplay
                          label={'Keyword accuracy count'}
                          text={m.keywordCountAccuracy === 0 || !!m.keywordCountAccuracy
                            ? m.keywordCountAccuracy
                            : <div key={i}
                              className={classes(styles.labelItalicsGray, styles.littleLeft)}>{'n/a'}</div>
                          }
                        />
                      }
                    </div>
                    <div className={classes(styles.moreLeft, styles.moretop)}>
                      {m.websiteLinks.length > 0 &&
                        <div>
                          <hr />
                          <span className={styles.label}>Website Link</span>
                          {m.websiteLinks.map((w, i) =>
                            <LinkDisplay key={i} linkText={w}
                              isWebsiteLink={true}
                              deleteFunction={handleRemoveWebsiteLinkOpen}
                              deleteId={m.assessmentQuestionId} />
                          )}
                          <hr />
                        </div>
                      }
                    </div>
                  </div>
                  {isAuthor &&
                    <div className={classes(styles.row, styles.linkRow)}>
                      <div className={styles.littleRight}>
                        <TextDisplay text={m.pointsPossible}
                          label={'Points'} />
                      </div>
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleRemoveQuestionOpen(m.assessmentQuestionId)}
                        className={styles.link}>remove</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleAddOrUpdateQuestionOpen(m.assessmentQuestionId, true)}
                        className={styles.link}>edit</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleWebsiteLinkOpen(m.assessmentQuestionId)}
                        className={styles.link}>add link</a> |
                      {!assessment.isPublished &&
                        <div>
                          <SelectSingleDropDown
                            id={m.assessmentQuestionId}
                            name={m.assessmentQuestionId}
                            label={'Sequence'}
                            labelLeft={true}
                            value={m.sequence}
                            noBlank={true}
                            options={sequences}
                            className={styles.dropdown}
                            onChange={(event) => reorderSequence(m.assessmentQuestionId, event)} />
                        </div>
                      }
                    </div>
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'SINGLEENTRY') {
              return (
                <div key={i}>
                  <AssessmentSingleEntry 
                    answerVariations={m.answerVariations}
                    isAuthor={isAuthor}
                    nameKey={i} 
                    question={m}
                    removeQuestionFileOpen={handleRemoveQuestionFileOpen}
                    removeQuestionRecordingOpen={handleRemoveQuestionRecordingOpen}
                    removeSolutionFileOpen={handleRemoveSolutionFileOpen}
                    removeSolutionRecordingOpen={handleRemoveSolutionRecordingOpen} />
                  <div className={classes(styles.muchLeft, styles.moreTop)}>
                    {m.websiteLinks.length > 0 &&
                      <div>
                        <hr />
                        <span className={styles.label}>Website Link</span>
                        {m.websiteLinks.map((w, i) =>
                          <LinkDisplay key={i} linkText={w} isWebsiteLink={true}
                            deleteFunction={handleRemoveWebsiteLinkOpen}
                            deleteId={m.assessmentQuestionId} />
                        )}
                        <hr />
                      </div>
                    }
                  </div>
                  {isAuthor &&
                    <div className={classes(styles.row, styles.linkRow)}>
                      <div className={styles.littleRight}>
                        <TextDisplay text={m.pointsPossible}
                          label={'Points'} />
                      </div>
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleRemoveQuestionOpen(m.assessmentQuestionId)}
                        className={styles.link}>remove</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleAddOrUpdateQuestionOpen(m.assessmentQuestionId, true)}
                        className={styles.link}>edit</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleWebsiteLinkOpen(m.assessmentQuestionId)}
                        className={styles.link}>add link</a> |
                      {!assessment.isPublished &&
                        <div>
                          <SelectSingleDropDown
                            id={m.assessmentQuestionId}
                            name={m.assessmentQuestionId}
                            label={'Sequence'}
                            labelLeft={true}
                            value={m.sequence}
                            noBlank={true}
                            options={sequences}
                            className={styles.dropdown}
                            onChange={(event) => reorderSequence(m.assessmentQuestionId, event)} />
                        </div>
                      }
                    </div>
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'FILLBLANK') {
              return (
                <div key={i}>
                  <AssessmentFillBlank 
                    answerVariations={m.answerVariations}
                    isAuthor={isAuthor}
                    nameKey={i} 
                    question={m}
                    removeQuestionFileOpen={handleRemoveQuestionFileOpen}
                    removeQuestionRecordingOpen={handleRemoveQuestionRecordingOpen}
                    removeSolutionFileOpen={handleRemoveSolutionFileOpen}
                    removeSolutionRecordingOpen={handleRemoveSolutionRecordingOpen} />
                  <div className={classes(styles.muchLeft, styles.moreTop)}>
                    {m.websiteLinks.length > 0 &&
                      <div>
                        <hr />
                        <span className={styles.label}>Website Link</span>
                        {m.websiteLinks.map((w, i) =>
                          <LinkDisplay key={i} linkText={w} isWebsiteLink={true}
                            deleteFunction={handleRemoveWebsiteLinkOpen}
                            deleteId={m.assessmentQuestionId} />
                        )}
                        <hr />
                      </div>
                    }
                  </div>
                  {isAuthor &&
                    <div className={classes(styles.row, styles.linkRow)}>
                      <div className={styles.littleRight}>
                        <TextDisplay text={m.pointsPossible}
                          label={'Points'} />
                      </div>
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleRemoveQuestionOpen(m.assessmentQuestionId)}
                        className={styles.link}>remove</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleAddOrUpdateQuestionOpen(m.assessmentQuestionId, true)}
                        className={styles.link}>edit</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleWebsiteLinkOpen(m.assessmentQuestionId)}
                        className={styles.link}>add link</a> |
                      {!assessment.isPublished &&
                        <div>
                          <SelectSingleDropDown
                            id={m.assessmentQuestionId}
                            name={m.assessmentQuestionId}
                            label={'Sequence'}
                            labelLeft={true}
                            value={m.sequence}
                            noBlank={true}
                            options={sequences}
                            className={styles.dropdown}
                            onChange={(event) => reorderSequence(m.assessmentQuestionId, event)} />
                        </div>
                      }
                    </div>
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'MATCHING') {
              return (
                <div key={i}>
                  <AssessmentMatching 
                    isAuthor={isAuthor}
                    nameKey={i} 
                    question={m} 
                    removeAnswerFileOpen={handleRemoveAnswerFileOpen}
                    removeAnswerRecordingOpen={handleRemoveAnswerRecordingOpen}
                    removeQuestionFileOpen={handleRemoveQuestionFileOpen}
                    removeQuestionRecordingOpen={handleRemoveQuestionRecordingOpen}
                    removeSolutionFileOpen={handleRemoveSolutionFileOpen}
                    removeSolutionRecordingOpen={handleRemoveSolutionRecordingOpen}
                    removeToMatchFileOpen={handleRemoveToMatchFileOpen}
                    viewMode={'TeacherView'} />
                  <div className={classes(styles.muchLeft, styles.moreTop)}>
                    {m.websiteLinks.length > 0 &&
                      <div>
                        <hr />
                        <span className={styles.label}>Website Link</span>
                        {m.websiteLinks.map((w, i) =>
                          <LinkDisplay key={i} linkText={w} isWebsiteLink={true}
                            deleteFunction={handleRemoveWebsiteLinkOpen}
                            deleteId={m.assessmentQuestionId} />
                        )}
                        <hr />
                      </div>
                    }
                  </div>
                  {isAuthor &&
                    <div className={classes(styles.row, styles.linkRow)}>
                      <div className={styles.littleRight}>
                        <TextDisplay text={m.pointsPossible}
                          label={'Points'} />
                      </div>
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleRemoveQuestionOpen(m.assessmentQuestionId)}
                        className={styles.link}>remove</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleAddOrUpdateQuestionOpen(m.assessmentQuestionId, true)}
                        className={styles.link}>edit</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleWebsiteLinkOpen(m.assessmentQuestionId)}
                        className={styles.link}>add link</a> |
                      {!assessment.isPublished &&
                        <div>
                          <SelectSingleDropDown
                            id={m.assessmentQuestionId}
                            name={m.assessmentQuestionId}
                            label={'Sequence'}
                            labelLeft={true}
                            value={m.sequence}
                            noBlank={true}
                            options={sequences}
                            className={styles.dropdown}
                            onChange={(event) => reorderSequence(m.assessmentQuestionId, event)} />
                        </div>
                      }
                    </div>
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'PICTUREORAUDIO') {
              return (
                <div key={i}>
                  <AssessmentPictureAudio 
                    isAuthor={isAuthor}
                    keywords={m.answerVariations}
                    nameKey={i} 
                    question={m}
                    removeQuestionFileOpen={handleRemoveQuestionFileOpen}
                    removeQuestionRecordingOpen={handleRemoveQuestionRecordingOpen}
                    removeSolutionFileOpen={handleRemoveSolutionFileOpen}
                    removeSolutionRecordingOpen={handleRemoveSolutionRecordingOpen} />
                  <div className={classes(styles.muchLeft, styles.moreTop)}>
                    <div className={styles.instructions}>
                      The student can respond with a picture, an audio recording or both.
                    </div>
                    {m.websiteLinks.length > 0 &&
                      <div>
                        <hr />
                        <span className={styles.label}>Website Link</span>
                        {m.websiteLinks.map((w, i) =>
                          <LinkDisplay key={i} linkText={w} isWebsiteLink={true}
                            deleteFunction={handleRemoveWebsiteLinkOpen}
                            deleteId={m.assessmentQuestionId} />
                        )}
                        <hr />
                      </div>
                    }
                  </div>
                  {isAuthor &&
                    <div className={classes(styles.row, styles.linkRow)}>
                      <div className={styles.littleRight}>
                        <TextDisplay text={m.pointsPossible}
                          label={'Points'} />
                      </div>
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleRemoveQuestionOpen(m.assessmentQuestionId)}
                        className={styles.link}>remove</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleAddOrUpdateQuestionOpen(m.assessmentQuestionId, true)}
                        className={styles.link}>edit</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleWebsiteLinkOpen(m.assessmentQuestionId)}
                        className={styles.link}>add link</a> |
                      {!assessment.isPublished &&
                        <div>
                          <SelectSingleDropDown
                            id={m.assessmentQuestionId}
                            name={m.assessmentQuestionId}
                            label={'Sequence'}
                            labelLeft={true}
                            value={m.sequence}
                            noBlank={true}
                            options={sequences}
                            className={styles.dropdown}
                            onChange={(event) => reorderSequence(m.assessmentQuestionId, event)} />
                        </div>
                      }
                    </div>
                  }
                </div>
              )
            } else if (m.questionTypeCode === 'PASSAGE') {
              return (
                <div key={i}>
                  <AssessmentPassage 
                    nameKey={i} 
                    question={m}
                    isAuthor={isAuthor}
                    isOwner={m.isOwner}
                    removeQuestionFileOpen={handleRemoveQuestionFileOpen}
                    removeQuestionRecordingOpen={handleRemoveQuestionRecordingOpen} />
                  <div className={classes(styles.muchLeft, styles.moreTop)}>
                    {m.websiteLinks.length > 0 &&
                      <div>
                        <hr />
                        <span className={styles.label}>Website Link</span>
                        {m.websiteLinks.map((w, i) =>
                          <LinkDisplay key={i} linkText={w} isWebsiteLink={true}
                            deleteFunction={handleRemoveWebsiteLinkOpen}
                            deleteId={m.assessmentQuestionId} />
                        )}
                        <hr />
                      </div>
                    }
                  </div>
                  {isAuthor &&
                    <div className={classes(styles.row, styles.linkRow)}>
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleRemoveQuestionOpen(m.assessmentQuestionId)}
                        className={styles.link}>remove</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleAddOrUpdateQuestionOpen(m.assessmentQuestionId, true)}
                        className={styles.link}>edit</a> |
                      <a
                        onClick={assessment.isPublished ? handleCannotChangeOpen : () => handleWebsiteLinkOpen(m.assessmentQuestionId)}
                        className={styles.link}>add link</a> |
                      {!assessment.isPublished &&
                        <div>
                          <SelectSingleDropDown
                            id={m.assessmentQuestionId}
                            name={m.assessmentQuestionId}
                            label={'Sequence'}
                            labelLeft={true}
                            value={m.sequence}
                            noBlank={true}
                            options={sequences}
                            className={styles.dropdown}
                            onChange={(event) => reorderSequence(m.assessmentQuestionId, event)} />
                        </div>
                      }
                    </div>
                  }
                </div>
              )
            }
            return null
          })}
        </div>
        <hr className={styles.hrHeight} />
        {assessmentQuestions && assessmentQuestions.length >= 4 &&
          <a onClick={handleAddOrUpdateQuestionOpen}
            className={classes(styles.row, styles.addNew)}>
            <Icon pathName={'plus'} className={styles.icon}
              fillColor={'green'} />
            <span className={styles.addAnother}>Add Another Question</span>
          </a>
        }
        <AssessmentItemModal 
          assessment={assessment}
          assessmentItem={assessmentItem}
          assessmentQuestionId={assessmentQuestionId}
          companyConfig={companyConfig}
          deleted_fileUploadId={deleted_fileUploadId}
          gradingType={gradingType}
          handleClose={handleAddOrUpdateQuestionClose}
          handleRemoveFileOpen={handleRemoveQuestionFileOpen}
          handleRemoveSolutionFileOpen={handleRemoveSolutionFileOpen}
          handleSubmit={handleAddOrUpdateQuestionSave}
          isAuthor={isAuthor}
          isOpen={isShowingModal_addOrUpdate}
          personId={personId} assessmentId={assessmentId}
          questionTypes={questionTypes}
          removeAnswerFileOpen={handleRemoveAnswerFileOpen}
          removeAnswerOption={handleRemoveAnswerOptionOpen}
          removeAssessmentQuestionAnswerRecording={removeAssessmentQuestionAnswerRecording}
          removeAssessmentQuestionQuestionRecording={removeAssessmentQuestionQuestionRecording}
          removeAssessmentQuestionSolutionRecording={removeAssessmentQuestionSolutionRecording} 
          showTitle={true} />
          
        {isShowingModal_removeQuestion &&
          <MessageModal handleClose={handleRemoveQuestionClose}
            heading={'Remove this question from this assessment content?'}
            explainJSX={'Are you sure you want to delete this question from this assessment content?'}
            isConfirmType={true}
            onClick={handleRemoveQuestion} />
        }
        {isShowingModal_removeWebsiteLink &&
          <MessageModal handleClose={handleRemoveWebsiteLinkClose}
            heading={'Remove this website link?'}
            explainJSX={'Are you sure you want to delete this website link?'}
            isConfirmType={true}
            onClick={handleRemoveWebsiteLink} />
        }
        {isShowingModal_removeFileUpload &&
          <MessageModal handleClose={handleRemoveFileUploadClose}
            heading={'Remove this file upload?'}
            explainJSX={'Are you sure you want to delete this file upload?'}
            isConfirmType={true}
            onClick={handleRemoveFileUpload} />
        }
        {isShowingModal_removeQuestionFile &&
          <MessageModal handleClose={handleRemoveQuestionFileClose}
            heading={'Remove this question picture?'}
            explainJSX={'Are you sure you want to delete this question picture?'}
            isConfirmType={true}
            onClick={handleRemoveQuestionFile} />
        }
        {isShowingModal_removeQuestionRecording &&
          <MessageModal handleClose={handleRemoveQuestionRecordingClose}
            heading={'Remove this question recording?'}
            explainJSX={'Are you sure you want to delete this question recording?'}
            isConfirmType={true}
            onClick={handleRemoveQuestionRecording} />
        }
        {isShowingModal_removeAnswerOption &&
          <MessageModal handleClose={handleRemoveAnswerOptionClose}
            heading={'Remove this answer option?'}
            explainJSX={'Are you sure you want to delete this answer option?'}
            isConfirmType={true}
            onClick={handleRemoveAnswerOption} />
        }
        {isShowingModal_removeAnswerFile &&
          <MessageModal handleClose={handleRemoveAnswerFileClose}
            heading={'Remove this answer picture?'}
            explainJSX={'Are you sure you want to delete this answer picture?'}
            isConfirmType={true}
            onClick={handleRemoveAnswerFile} />
        }
        {isShowingModal_removeToMatchFile &&
          <MessageModal handleClose={handleRemoveToMatchFileClose}
            heading={'Remove this matching picture?'}
            explainJSX={'Are you sure you want to delete this matching picture?'}
            isConfirmType={true}
            onClick={handleRemoveToMatchFile} />
        }
        {isShowingModal_removeAnswerRecording &&
          <MessageModal handleClose={handleRemoveAnswerRecordingClose}
            heading={'Remove this answer recording?'}
            explainJSX={'Are you sure you want to delete this answer recording?'}
            isConfirmType={true}
            onClick={handleRemoveAnswerRecording} />
        }
        {isShowingModal_removeSolutionFile &&
          <MessageModal handleClose={handleRemoveSolutionFileClose}
            heading={'Remove this solution picture?'}
            explainJSX={'Are you sure you want to delete this solution picture?'}
            isConfirmType={true}
            onClick={handleRemoveSolutionFile} />
        }
        {isShowingModal_removeSolutionRecording &&
          <MessageModal handleClose={handleRemoveSolutionRecordingClose}
            heading={'Remove this solution recording?'}
            explainJSX={'Are you sure you want to delete this solution recording?'}
            isConfirmType={true}
            onClick={handleRemoveSolutionRecording} />
        }
        {isShowingModal_pointsError &&
          <MessageModal handleClose={handlePointsErrorClose}
            heading={'Points intended do not match!'}
            explainJSX={'The points intended for this quiz do not match the total points entered for the assessment questions. Do you want to reset the total points possible to ${subTotalPoints}?'}
            isConfirmType={true}
            onClick={handlePointsErrorSave} />
        }
        {isShowingModal_cannotChange &&
          <MessageModal handleClose={handleCannotChangeClose}
            heading={'Cannot Change Assignment'}
            explainJSX={'This assessment is published.  In order to make a change to this assessment, you can choose to unpublish the assessment in order to make the change.  Remember to publish it again when you are ready.'}
            onClick={handleCannotChangeClose} />
        }
        {isShowingModal_websiteLink &&
          <TextareaModal key={'all'} handleClose={handleWebsiteLinkClose}
            heading={'Website Link'}
            explainJSX={'Choose a website link for an assessment question.'}
            onClick={handleWebsiteLinkSave}
            placeholder={'Website URL?'} />
        }
      </div>
      <OneFJefFooter />
    </div>
  )
}

export default AssessmentQuestionsView
