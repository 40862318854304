 const downloadOptions = [
  {
    id: 'docx',
    label: <span style={{fontSize: '12px'}}>Word &reg;</span>,
    title: 'Microsoft Word&reg;',
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    id: "odt",
    label: <span style={{ fontSize: '12px' }}>Open Office</span>,
    title: 'Open Office&reg;',
    mimeType: 'application/vnd.oasis.opendocument.text',
  },
  {
    id: "pdf",
    label: <span style={{ fontSize: '12px' }}>Pdf</span>,
    title: 'Pdf',
    mimeType: 'application/pdf',
  },
  {
    id: "html",
    label: <span style={{ fontSize: '12px' }}>Html</span>,
    title: 'Html',
    mimeType: 'text/html',
  },
  {
    id: "text",
    label: <span style={{ fontSize: '12px' }}>Text</span>,
    title: 'Text',
    mimeType: 'text/plain',
  },
  // {
  //   id: "bmp",
  //   label: <span style={{fontSize: '12px'}}>Bmp</span>,
  //   title: 'Bmp',
  //   mimeType: 'image/bmp',
  // },
  {
    id: "doc",
    label: <span style={{fontSize: '12px'}}>Doc</span>,
    title: 'Doc',
    mimeType: 'application/msword',
  },
  {
    id: "epub",
    label: <span style={{fontSize: '12px'}}>Epub</span>,
    title: 'Epub',
    mimeType: 'application/epub+zip',
  },
  // {
  //   id: "jpeg",
  //   label: <span style={{fontSize: '12px'}}>Jpeg</span>,
  //   title: 'Jpeg',
  //   mimeType: 'image/jpeg',
  // },
  {
    id: "md",
    label: <span style={{fontSize: '12px'}}>Markdown</span>,
    title: 'Markdown',
     mimeType: 'text/markdown',
  },
  {
    id: "mhtml",
    label: <span style={{fontSize: '12px'}}>Mhtml</span>,
    title: 'Mhtml',
    mimeType: 'multipart/related',
  },
  {
    id: "mobi",
    label: <span style={{fontSize: '12px'}}>Mobi</span>,
    title: 'Mobi',
    mimeType: 'application/x-mobipocket-ebook',
  },
  {
    id: "openXps",
    label: <span style={{fontSize: '12px'}}>OpenXps</span>,
    title: 'OpenXps',
    mimeType: 'application/oxps',
  },
  // {
  //   id: "png",
  //   label: <span style={{fontSize: '12px'}}>Png</span>,
  //   title: 'Png',
  //   mimeType: 'image/png',
  // },
  {
    id: "rtf",
    label: <span style={{fontSize: '12px'}}>Rtf</span>,
    title: 'Rtf',
    mimeType: 'application/rtf',
  },
  // {
  //   id: "tiff",
  //   label: <span style={{fontSize: '12px'}}>Tiff</span>,
  //   title: 'Tiff',
  //   mimeType: 'image/tiff',
  // },
  {
    id: "wordML",
    label: <span style={{fontSize: '12px'}}>WordML</span>,
    title: 'WordML',
    mimeType: 'application/vnd.ms-word.document.xml',
  },
  {
    id: "xps",
    label: <span style={{fontSize: '12px'}}>Xps</span>,
    title: 'Xps',
    mimeType: 'application/vnd.ms-xpsdocument',
  },
]

export default downloadOptions