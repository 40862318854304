import React from 'react';
import styles from './EditActionOptions.module.css';
import classes from "classnames";
import Icon from '../Icon'

function EditActionOptions(props) {
	const {
		edit,
		index,
		personId,
		handleAcceptEdit,
		isAuthor,
		sendResponseEdit,
		getVoteUpCount,
		getVoteDownCount,
		getVoteTrollCount,
		confirmDeleteEdit,
		handleFullTextView,
    isDraftReview,
    isTranslation,
    setTranslation,
	} = props

	return (
		<div className={styles.toolOptions} key={index}>
      {isAuthor && //edit.personId !== personId && !(!isAuthor && edit.type !== 'TEXT') && !isDraftReview &&  I might have had some reason for doing this additional stuff, but I don't know why an editor would be rejecting someone else's edit.
        <span className={styles.iconRow}>
					<a onClick={() => isTranslation ? setTranslation(edit.text) : sendResponseEdit(edit, 'RejectEdit')} title={'Reject this edit'}>
							<Icon pathName={`trash2`} smaller premium={true} fillColor={`#EF5555FF`} className={styles.choiceIcons}/>
					</a>
        </span>
      }
			{edit.personId !== personId && !(!isAuthor && edit.type !== 'TEXT') && !isDraftReview &&
				<span className={styles.iconRow}>
            <a onClick={() => isTranslation ? setTranslation(edit.text) : handleAcceptEdit(edit)} title={'Accept this edit'}>
                <Icon pathName={isAuthor ? `checkmark` : `pencil0`} smaller premium={!isAuthor}
                      fillColor={isAuthor ? `green` : ``} className={styles.choiceIcons}/>
            </a>
        </span>
			}
      {edit.personId !== personId && !(!isAuthor && edit.type !== 'TEXT') && !isDraftReview &&
        <span className={styles.iconDivider}>|</span>
      }
			{edit.personId !== personId &&
				<span className={styles.iconRow}>
					<a onClick={edit.personId === personId || isDraftReview ? () => {} : () => sendResponseEdit(edit, 'VoteUp')} title={'Vote up +1'}>
							<Icon pathName={`thumbs_up0`} premium={true} className={classes(styles.choiceIcons, isDraftReview ? styles.lowOpacity : '')}/>
					</a>
					<span className={styles.voteCount}>{getVoteUpCount(edit)}</span>
	      </span>
			}
			{edit.personId !== personId &&
				<span className={styles.iconRow}>
					<a onClick={edit.personId === personId || isDraftReview ? () => {} : () => sendResponseEdit(edit, 'VoteDown')} title={'Vote down -1'}>
							<Icon pathName={`thumbs_down0`} premium={true} className={classes(styles.choiceIcons, isDraftReview ? styles.lowOpacity : '')}/>
					</a>
					<span className={styles.voteCount}>{getVoteDownCount(edit)}</span>
	      </span>
			}
			{edit.personId !== personId &&
				<span className={styles.row}>
	          <a onClick={isDraftReview
              ? () => {}
              : isAuthor
                ? () => {
									sendResponseEdit(edit, 'RejectEdit')
									sendResponseEdit(edit, 'VoteTroll')
                }
                : edit.personId === personId
                  ? () => {}
								: () => sendResponseEdit(edit, 'VoteTroll')
	          } className={classes(styles.row, styles.moreLeft)} title={'Vote troll (obnoxious) +1'}>
	              <Icon pathName={`blocked`} fillColor={'red'} className={classes(styles.imageBlocked, isDraftReview ? styles.lowOpacity : '')}/>
	              <Icon pathName={`user_minus0`} premium={true} className={classes(styles.imageOverlay, isDraftReview ? styles.lowOpacity : '')}/>
	          </a>
	          <span className={styles.trollCount}>{getVoteTrollCount(edit)}</span>
	      </span>
			}
			{edit.personId === personId && !isDraftReview &&
				<a onClick={() => confirmDeleteEdit(edit.editSegmentId)} className={styles.trashCan} title={'Reverse this edit (undo)'}>
					<Icon pathName={`undo2`} premium={true} smaller fillColor={'#EF5555FF'} className={styles.choiceIcons}/>
				</a>
			}
			{edit.personId !== personId && !isDraftReview &&
				<a onClick={(event) => handleFullTextView(event, edit.personId)} className={styles.iconRow}
				   title={`See the editor's full text`}>
					<Icon pathName={`document0`} premium={true} className={styles.imageDocument}/>
					<Icon pathName={`magnifier`} premium={true} className={styles.imageMagnifier}/>
				</a>
			}
		</div>
	)
}

export default EditActionOptions