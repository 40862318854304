import React from 'react';
import styles from './AuthorHistoryOriginal.module.css';
import classes from "classnames";

function AuthorHistoryOriginal(props) {
	const {history} = props;

	let authorTextSnapshot = history.authorTextSnapshot
	if (authorTextSnapshot && authorTextSnapshot.indexOf('&nbsp;') === authorTextSnapshot.length-6) authorTextSnapshot.substring(0, authorTextSnapshot.length-6)

	return (
		<div className={styles.container}>
			<div>
	      <span className={styles.authorDateLine}>
	        Authors original sentence
	      </span>
				<span className={classes(styles.editText, (history.isComment ? styles.isCommentText : ''))} id={history.personId}>
					{authorTextSnapshot}
					{history.comment &&
						<div>
							<div className={styles.textSmallGray}>Comment:</div>
							<div className={styles.editorComment}>{history.comment}</div>
						</div>
					}
	      </span>
			</div>
		</div>
  )
};

export default AuthorHistoryOriginal