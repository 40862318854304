import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import styles from './HeadlessSelectList.module.css';

export default function HeadlessSelectList({ 
	iconAvatar, 
	label, 
	header,
	onSelect = () => {}, 
	onClickForMessage = () => {}, 
	listOptions, 
	listWidth,
	divDisplayId, 
	children, 
	id, 
	addClassName, 
	showChoiceLabel, 
	choiceLabel, 
	labelLeftpx='-17px',
	keyIndex,
}) {

	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)

	// useEffect(() => {
	// 	setRenewedListOptions(listOptions)
	// }, [listOptions])

	const handleClick = (event) => {
		event.stopPropagation()
		setAnchorEl(anchorEl ? null : event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const checkForClose = (event) => {
		event.stopPropagation()
		const element = event && event.target
		if (element && element.innerText === 'Next') handleClose()
	}

	return (
		<div onClick={checkForClose} style={{ height: '20px' }} key={keyIndex} className={styles.container}>
			<IconButton
				id={id}
				onClick={handleClick}
				size="small"
				sx={{ width: label ? '150px' :'25px', position: 'relative', top: '-3px', left: label ? '-15px' : '' }}
				aria-controls={open ? 'drop-down-list' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			>
				{iconAvatar}
				{label}
			</IconButton>	
			{showChoiceLabel && 
				<div style={{ fontSize: '9px', position: 'relative', top: '-6px', left: labelLeftpx, whiteSpace: 'nowrap' }} onClick={handleClose}> 
					{choiceLabel}
				</div>
			}
			<Menu
				anchorEl={anchorEl}
				id="drop-down-list"
				open={open}
				className={styles.zIndex}
				onClose={handleClose}
				PaperProps={{
					elevation: 0,
					sx: {
						// overflow: 'visible',
						fontSize: '11px',
						maxHeight: '90vh',
						overflowY: 'auto',
						position: 'relative',
						zIndex: '1007',
						width: listWidth || '150px',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						backgroundColor: '#92dbf1',
						mt: 1,
						'& .MuiAvatar-root': {
							width: 32,
							height: 22,
							ml: -0.5,
							mr: 1,
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 4,
							backgroundColor: '#92dbf1',
							transform: 'translateY(-50%) rotate(45deg)',
						},
					},
				}}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
			>
				<div className={addClassName}>
					{header &&
						<div className={styles.header} onClick={handleClose}>{header}</div>
					}
					{children}
					{listOptions?.length > 0 && listOptions.map((m, i) => {
						if (m.isInstruction) {
							return <div key={i}>{m.instructions}</div>
						} else {
							return (
								<MenuItem 
									className={styles.menuItem}
									key={i} 
									onClick={(event) => {
										event.stopPropagation()
										onSelect(m.id, event, divDisplayId)
										handleClose() 
										onClickForMessage(m.id)
									}}>
									<span>{m.label}</span>
								</MenuItem>
							)
						}
					})}
				</div>
			</Menu>
		</div>
	)
}