import React from 'react';
import styles from './AssessmentStats.module.css';
import classes from 'classnames'
import DateMoment from '../DateMoment'

function AssessmentStats(props) {
	const {
    darkerLabel,
    includeDates,
    includeEditors,
    isTranslation,
    labelWhite,
    maroonBackground,
    work = {assessmentStats: {}},
	} = props

	return (
    <div className={styles.rowTight}>
      <div title={'Questions'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Questions</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.assessmentStats.questions || '0'}</div>
      </div>
      {/* <div title={'Assigned'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Assigned</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.assessmentStats.assigned || '0'}</div>
      </div>
      <div title={'Completed'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Completed</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.assessmentStats.completed || '0'}</div>
      </div> */}
      <div title={'Essay'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Essay</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.assessmentStats.essay || '0'}</div>
      </div>
      <div title={'Fill'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Fill</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.assessmentStats.fillBlank || '0'}</div>
      </div>
      <div title={'Matching'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Matching</div>
        <div className={darkerLabel ? styles.countDark : styles.countWhite}>{work.assessmentStats.matching || '0'}</div>
      </div>
      <div title={'Media'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Media</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.assessmentStats.media || '0'}</div>
      </div>
      <div title={'Mult Answer'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Mult Answer</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.assessmentStats.multipleAnswer || '0'}</div>
      </div>
      <div title={'Mult Choice'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Mult Choice</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.assessmentStats.multipleChoice || '0'}</div>
      </div>
      <div title={'Passage'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Passage</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.assessmentStats.passage || '0'}</div>
      </div>
      <div title={'Single'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>Single</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.assessmentStats.singleEntry || '0'}</div>
      </div>
      <div title={'True/False'} className={styles.row}>
        <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>T/F</div>
        <div
          className={darkerLabel ? styles.countDark : styles.countWhite}>{work.assessmentStats.trueFalse || '0'}</div>
      </div>
      {work.activeDate && includeDates &&
        <div title={'Active date'} className={styles.row}>
          <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>active</div>
          <div className={darkerLabel ? styles.countDark : styles.countWhite}>
            {!work.activeDate ? '- -' :
              <DateMoment date={work.activeDate} label={''} format={'D MMM YYYY'} className={styles.date}
                dateTextClass={styles.dateTextClass} />}
          </div>
        </div>
      }
      {work.dueDate && includeDates &&
        <div title={'Due date'} className={styles.row}>
          <div className={maroonBackground || labelWhite ? styles.labelWhite : styles.label}>due</div>
          <div className={darkerLabel ? styles.countDark : styles.countWhite}>
            {!work.dueDate ? '- -' :
              <DateMoment date={work.dueDate} label={''} format={'D MMM YYYY'} className={styles.date}
                dateTextClass={styles.dateTextClass} />}
          </div>
        </div>
      }
    </div>	)
};

export default AssessmentStats;