import {useState, useContext, createContext } from 'react'
import {setLoggedSuccessfully, setLoggedIn} from '../actions/login';

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null)

	const login = (user) => {
		setUser(user)
		setLoggedSuccessfully(user);
		setLoggedIn(true);
	}

	const logout = () => {
		setUser(null)
	}

	return (
		<AuthContext.Provider value={{ user, login, logout }}>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuth = () => {
	return useContext(AuthContext)
}