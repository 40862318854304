import * as types from '../actions/actionTypes';

export default function (state = [], action) {
  switch (action.type) {
    case types.ASSESSMENT_QUESTIONS_INIT:
      return action.payload;

    case types.ASSESSMENT_QUESTION_ANSWER_SET: {
      const { assessmentQuestionId, answer } = action.payload;
      return state.map(item => {
        if (item.assessmentQuestionId === assessmentQuestionId) {
          return {
            ...item,
            learnerAnswer: { ...item.learnerAnswer, learnerAnswer: answer }
          };
        }
        return item;
      });
    }

    case types.ASSESSMENT_CORRECT_SCORE_UPDATE: {
      const { assessmentQuestionId, score } = action.payload;
      return state.map(item => {
        if (item.assessmentQuestionId === assessmentQuestionId) {
          return {
            ...item,
            learnerAnswer: { ...item.learnerAnswer, score: score }
          };
        }
        return item;
      });
    }

    case types.ASSESSMENT_QUESTION_CLEAR:
      return [];

    default:
      return state;
  }
}

export const selectAssessmentQuestions = (state) => state;
