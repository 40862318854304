import React from 'react';
import styles from './WorkEditReportView.module.css';
import globalStyles from '../../utils/globalStyles.module.css';
import EditTable from '../../components/EditTable';
import ChartLineArea from '../../components/ChartLineArea';
import WorkSummaryLine from '../../components/WorkSummaryLine';
import OneFJefFooter from '../../components/OneFJefFooter';
import classes from 'classnames'

function WorkEditReportView(props) {
  const {workEditReports, headings, getDraftReview} = props;
  let result = []
  let languageName

  workEditReports.forEach((report, index) => {
    let data = []
    languageName = report.languageName
    const editLanguageId = report.languageId
    const reportData = [...report.data]
    reportData && reportData.length > 0 && reportData.forEach(m => {
      if (m.row[0].languageId === report.languageId) {
        let newRow = []
        if (m.row[0].languageId === editLanguageId) {
          m.row && m.row.length > 0 && m.row.forEach((cell, i) => {
            let newCell = {}
            if (i === 0 && typeof cell.value === 'string') {
              newCell.value = <div className={styles.row}>{cell.value === 0 || cell.value === '0' ? '' : cell.value}
                <div className={styles.editorScore}>{cell.extraNumber || '0'}</div>
              </div>
            } else {
              newCell.value = <div className={styles.row}>{cell.value === 0 || cell.value === '0' ? '' : cell.value}</div>
            }
            newRow.push(newCell)
          })
        }
        data.push(newRow);
      }
    })

    result.push(
      <div key={index} className={styles.reportContainer}>
        <div className={styles.border} key={index}>
          <div className={styles.workBackground}>
            <div className={styles.workPadding}>
              <WorkSummaryLine work={report.work} nameLength={150} includeDates maroonBackground showDocumentDetails/>
            </div>
          </div>
          <div className={styles.whiteBackground}>
            <div className={styles.languageName}>{languageName}</div>
            <div className={styles.row}>
              <div className={styles.editTable}>
                <EditTable labelClass={styles.tableLabelClass} headings={headings} data={data} noCount={true} firstColumnClass={styles.firstColumnClass}/>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.moreLeft}>
          <div className={styles.languageName}>{languageName}</div>
          <ChartLineArea data={report.draftReport} workId={report.workId} languageId={report.languageId}/>
        </div>
        <hr className={styles.horizontalLine}/>
      </div>
    )
  })

  return (
    <div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={classes(globalStyles.pageTitle, styles.title)}>
          Editing Reports
        </div>
        {result && result.length > 0 && result.map((m, i) => {
          return m
        })}
        <OneFJefFooter/>
      </div>
    </div>
  )
}

export default WorkEditReportView

