import * as types from '../actions/actionTypes';
import {doSort} from '../utils/sort'

//This has changed to handle discussion entries, test/quizzes, and general multiple homework with essays. It can return one workSummary, 
//    but now it returns many so it will be an array in every case.
export default function(state = [], action) {
    switch(action.type) {
        case types.WORK_EDIT_REVIEW:
            return action.payload;

        case types.WORK_EDIT_UPDATE: {
            let incomingEdit = action.payload
            const newState = state.map(workSummary => {
                if (workSummary.chapterId_current === incomingEdit.chapterId) {
                    let newEditSegments = [...workSummary.editSegments]
                    //If not an ADDLISTITEM
                    //  Take the edit away in order to add it again with new data
                    //else
                    //  Take the edit away if there is a matching addListItemSequence
                    //  If the edit comes without an addListItemSequence
                    //    set the addListItemSequence to the next greatest sequence
                    //  end if
                    //end if
                    //Add the edit to state
                    if (incomingEdit.type.indexOf('ADDLISTITEM') === -1) {
                        newEditSegments = newEditSegments && newEditSegments.length > 0 && newEditSegments.filter(m => !(m.personId === incomingEdit.personId && Number(m.elementId) === Number(incomingEdit.elementId) && m.type === incomingEdit.type))
                    } else {
                        let existEdit = newEditSegments && newEditSegments.length > 0 && newEditSegments.filter(m => m.personId === incomingEdit.personId && Number(m.elementId) === Number(incomingEdit.elementId) && m.type === incomingEdit.type && Number(m.addListItemSequence) === Number(incomingEdit.addListItemSequence))[0]
                        //Notice the negative, !, difference in the newEditSegments filter compared to the existEdit query above.
                        newEditSegments = newEditSegments && newEditSegments.length > 0 && newEditSegments.filter(m => !(m.personId === incomingEdit.personId && Number(m.elementId) === Number(incomingEdit.elementId) && m.type === incomingEdit.type && Number(m.addListItemSequence) === Number(incomingEdit.addListItemSequence)))
                        if (existEdit) {
                            incomingEdit.addListItemSequence = existEdit.addListItemSequence
                        }
                    }
                    newEditSegments = newEditSegments && newEditSegments.length > 0 ? newEditSegments.concat(incomingEdit) : [incomingEdit]
                    newEditSegments = doSort(newEditSegments, { sortField: 'addListItemSequence', isAsc: true, isNumber: true }) //We need to sort by sequence descending because they are put in upside down in the DOM.
                    // Return the updated workSummary with updated editSegments
                    return {
                        ...workSummary,
                        editSegments: newEditSegments,
                    };
                }
                // Return workSummary as-is if it doesn't match
                return workSummary;
            })
            return newState
        }

        default:
            return state;
    }
}

export const selectWorkEditReview = (state) => state;
