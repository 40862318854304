import React from 'react';
import Paths from './Paths.js';
import styles from './Icon.module.css';
import classes from 'classnames';

function Icon(props) {
  const {pathName='', className='', superscript='', premium, fillColor="#000000", supFillColor="#000000", smaller, onClick, superScriptClass, title, larger} = props;

  return (
    <div className={classes(styles.container, superscript ? styles.row : '')} onClick={onClick} title={title}>
      <svg className={classes(styles.icon, (className ? className : premium ? larger ? styles.iconLarger : styles.premiumSize : styles.icon16px))}
              viewBox={premium ? smaller ? "0 0 22 22" : larger ? "0 0 17 17" : "0 0 20 20" : "0 0 32 32"}>
        <g>
            {Paths[pathName] && Paths[pathName].map((path, i) =>
                <path key={i} d={path} fill={fillColor}/>
            )}
        </g>
      </svg>
      {superscript &&
        <svg className={classes(superScriptClass, styles.superscript)} viewBox={"0 0 32 32"}>
            <g>
                {Paths[superscript] && Paths[superscript].map((path, i) =>
                    <path key={i} d={path} fill={supFillColor}/>
                )}
            </g>
        </svg>
      }
    </div>
  )
}

export default Icon;
