import React, { useState, useRef } from 'react'
import styles from './FileUploadModal.module.css'
import globalStyles from '../../utils/globalStyles.module.css'
import TextDisplay from '../TextDisplay'
import InputText from '../InputText'
import DropZone from 'react-dropzone-component'
import classes from 'classnames'

function FileUploadModal(props) {
  const {
    handleClose,
    handleRecordRecall,
    isOpen,
    isMobile,
    label,
    recallAfterFileUpload,
    sendInBuildUrl,
    showTitleEntry,
    skipRecordRecall,
    submitFileUpload,
    title,
  } = props

  let containerRef = useRef(null)

  let dropzone = null
  const [file, setFile] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isFileChosen, setIsFileChosen] = useState(false)
  const [fileTitle, setFileTitle] = useState('')

  const djsConfig = {
    addRemoveLinks: true,
    acceptedFiles: props.acceptedFiles,
    autoProcessQueue: false,
    maxFiles: 1,
    paramName: 'ebiFile',
    success: function (file, done) {
      submitFileUpload()
      //not doing anything here
    },
  }

  const buildURL = () => {
    return sendInBuildUrl(fileTitle)
  }

  const componentConfig = {
    iconFiletypes: props.iconFiletypes,
    showFiletypeIcon: false,
    postUrl: buildURL,
    method: 'post',
    paramName: 'file', // The name that will be used to transfer the file
    success: function (file, done) {
      skipRecordRecall && recallAfterFileUpload()
      submitFileUpload()
    },
    accept: function (file, done) {
      if (file.name === "justinbieber.jpg") {
        done("Naha, you don't.")
      }
      else { done() }
    },
    uploadMultiple: false,
  }


  const changeTitle = (event) => {
    setFileTitle(event.target.value)
  }

  const handleFileAdded = (file) => {
    setFile(file)
    setIsFileChosen(true)
  }

  const handlePost = () => {
    dropzone.processQueue()
  }

  const handleSubmit = () => {
    setIsSubmitted(true)
    dropzone.processQueue()
    props.handleClose()
  }

  //const config = componentConfig
  //const djsConfig = djsConfig

  const eventHandlers = {
    init: dz => dropzone = dz,
    addedfile: handleFileAdded,
    success: skipRecordRecall
      ? () => { }
      : () => {
        handleRecordRecall()
        submitFileUpload(fileTitle)
      },
  }

  return (
    <div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
      <div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
        <div className={styles.background} ref={containerRef}>
          <form method='post' encType='multipart/form-data' id='my-awesome-dropzone' className={styles.form}>
            <div className={globalStyles.pageTitle}>
              Upload a File
            </div>
            <div className={styles.containerName}>
              <TextDisplay label={label} text={title} />
            </div>
            {showTitleEntry &&
              <InputText
                id={'fileTitle'}
                name={'fileTitle'}
                size={'long'}
                label={'Title'}
                value={fileTitle || ''}
                onChange={changeTitle} />
            }
            <hr />
            <div className={styles.explanation}>
              Click on the box below to browse for a file, or drag-and-drop a file into the box:
            </div>
            <DropZone config={componentConfig} eventHandlers={eventHandlers} djsConfig={djsConfig} className={styles.dropZone}>
              Click here to upload or
            </DropZone>
            <div className={styles.buttonDiv}>
              <a onClick={handleClose} className={styles.cancelLink}>Cancel</a>
              {isFileChosen &&
                <a onClick={handleSubmit} className={styles.buttonStyle}>Upload</a>
              }
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FileUploadModal