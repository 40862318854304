import React, { useState } from 'react'
import styles from './AssessmentEssayKeyword.module.css'
import classes from 'classnames'
import InputText from '../../InputText'
import EditTable from '../../EditTable'
import MessageModal from '../../MessageModal'
import SelectSingleDropDown from '../../SelectSingleDropDown'
import Icon from '../../Icon'

function AssessmentEssayKeyword(props) {
  const {
    addKeywordPhrase,
    className = "",
    keywordCountAccuracy,
    keywordPhrases,
    removeKeywordPhrase,
    updateKeywordCountAccuracy,
  } = props

  const [keywordIndex, setKeywordIndex] = useState()
  const [keywordPhrase, setKeywordPhrase] = useState()
  const [isShowingModal_remove, setIsShowingModal_remove] = useState()

  const handleAddKeywordPhrase = () => {
    if (keywordPhrase) {
      addKeywordPhrase(keywordPhrase)
      setKeywordPhrase('')
    }
  }

  const handleEnterKey = (event) => {
    event.key === "Enter" && handleAddKeywordPhrase()
  }

  const handleRemoveOpen = (keywordIndex) => { 
    setIsShowingModal_remove(true)
    setKeywordIndex(keywordIndex)
  }
  const handleRemoveClose = () => setIsShowingModal_remove(false)
  const handleRemoveSave = () => {
    removeKeywordPhrase(keywordIndex)
    handleRemoveClose()
  }

  let headings = [{}, {}]

  let data = keywordPhrases && keywordPhrases.length > 0 && keywordPhrases.map((keyPhrase, i) =>
    [
      {
        value: <div onClick={() => handleRemoveOpen(i)}>
          <Icon pathName={'cross_circle'} premium={true} fillColor={'maroon'} className={styles.icon} />
        </div>
      },
      { value: <div className={styles.label}>{keyPhrase}</div> },
    ]
  )

  data = data && data.length > 0 ? data : [[{ value: '' }, { value: <div className={styles.noRecords}>No keywords or phrases entered</div>, colSpan: 4 }]]

  let keywordCounts = keywordPhrases && keywordPhrases.length > 0 && keywordPhrases.map((acc, i) => (
    { id: i + 1, label: i + 1 }
  ))

  return (
    <div className={classes(className, styles.container)}>
      <div className={styles.instructions}>
        If you enter at least one keyword or phrase and choose at least one keyword count for grading, then this essay will be graded automatically.  Otherwise, grading will need to be done manually.
      </div>
      <div className={styles.row}>
        <InputText
          id={`keywordPhrase`}
          name={`keywordPhrase`}
          size={"medium-long"}
          onEnterKey={handleEnterKey}
          label={"Keyword"}
          instructionsBelow={true}
          value={keywordPhrase || ''}
          onChange={(event) => setKeywordPhrase(event.target.value)} />
        <div className={classes(styles.link, styles.row, styles.topPosition)} onClick={handleAddKeywordPhrase}>
          <Icon pathName={'plus'} className={styles.iconSmall} fillColor={'green'} />
          <div className={styles.moreTop}>{"Add"}</div>
        </div>
      </div>
      <EditTable labelClass={styles.tableLabelClass} headings={headings} data={data} noCount={true} />
      {data && data.length > 0 &&
        <div>
          <SelectSingleDropDown
            id={`keywordCountAccuracy`}
            name={`keywordCountAccuracy`}
            label={"How many keywords does the student need to enter to get full credit?"}
            value={keywordCountAccuracy || ''}
            options={keywordCounts}
            className={styles.moreBottomMargin}
            height={`short`}
            onChange={updateKeywordCountAccuracy} />
        </div>
      }
      {isShowingModal_remove &&
        <MessageModal handleClose={handleRemoveClose} heading={"Remove this keyword or phrase?"}
          explainJSX={"Are you sure you want to remove this keyword or phrase?"} isConfirmType={true}
          onClick={handleRemoveSave} />
      }
    </div>
  )
}

export default AssessmentEssayKeyword