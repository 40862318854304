import * as types from './actionTypes';
import {apiHost} from '../api_host.js';

export const setBlankTextProcessingProgress = (personId) => {
    //When the process is done, set the data to blank so that the next time that it is called up the previous work doesn't show up until the
    //   database responds with the new information.
    return dispatch => {
        return fetch(`${apiHost}ebi/textProcessing/delete/` + personId, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .then(response => {
            dispatch({ type: types.TEXT_PROCESSING_PROGRESS_GET, payload: {} });
        });
    }
}

export const getTextProcessingProgress = (personId) => {
    return dispatch => {
        return fetch(`${apiHost}ebi/textProcessing/progress/` + personId, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .then(response => {
            dispatch({ type: types.TEXT_PROCESSING_PROGRESS_GET, payload: response });
        });
    }
}
