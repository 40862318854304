import * as types from './actionTypes';
import getChapters from '../services/chapters.js';
import * as actionWorks from './works.js';
import * as guid from '../utils/GuidValidate.js';
import {apiHost} from '../api_host.js';

function setChapters( chapters=[] ) {
    return { type: types.CHAPTERS_LIST_INIT, payload: chapters };
}

export const init = (personId, workId) => dispatch => {
    return getChapters(personId, workId).then( n => dispatch( setChapters(n)))
};

export const setEditVote = (voterPersonId, chapterId, languageId, editDetailId, voteType, trollEditText, voterComment, isComment, includeHistory=false) => {
    return dispatch =>
    fetch(`${apiHost}ebi/chapter/edit/vote/` + includeHistory, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials' : 'true',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
            "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
            "Authorization": "Bearer " + localStorage.getItem("authToken"),
        },
        body: JSON.stringify({
            editDetailId,
            agreeFlag: voteType === 'AGREE' ? true : false,
            disagreeFlag: voteType === 'DISAGREE' ? true : false,
            trollFlag: voteType === 'TROLL' ? true : false,
            voterPersonId,
            trollEditText,
            chapterId,
            languageId,
            isComment,
            voterComment
        }),
    })
    .then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    })
    .then(response => {
        dispatch({ type: types.EDIT_DETAILS_INIT, payload: response });
    })
    //.catch(error => { console.l og('request failed', error); });
}

export const setAcceptedEdit = (personId, workId, chapterId, languageId, acceptedEditDetailId, isAuthorAcceptedEdit, includeHistory=false) => {
    return dispatch =>
    fetch(`${apiHost}ebi/chapter/edit/accepted/` + personId + `/` + acceptedEditDetailId + `/` + isAuthorAcceptedEdit, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials' : 'true',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
            "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
            "Authorization": "Bearer " + localStorage.getItem("authToken"),
        },
        body: JSON.stringify({
        }),
    })
    .then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    })
    .then(response => {
        isAuthorAcceptedEdit && dispatch({ type: types.EDIT_DETAILS_ACCEPTED_UPDATE, payload: {personId, acceptedEditDetailId, isAuthorAcceptedEdit} });
        //isAuthorAcceptedEdit && dispatch({ type: types.CHAPTER_TEXT_INIT, payload: response });
        //dispatch(actionEditReview.getChapterText(personId, workId, chapterId, languageId));
    })
    //.catch(error => { console.l og('request failed', error); });  //DELETE DON'T
}

export const updateChapterDueDate = (personId, workId, chapterId, languageId, dueDate) => {
    return dispatch =>
        fetch(`${apiHost}ebi/chapter/dueDate/` + personId + `/` + dueDate, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
            body: JSON.stringify({
                workId,
                chapterId,
                languageId,
            }),
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .then(response => {
            dispatch({ type: types.WORK_CHAPTER_DUEDATE_UPDATE, payload: {workId, chapterId, languageId, dueDate} });
        })
}

export const addOrUpdateChapter = (personId, workId, chapter, isFileUpload, isUpdate) => {
  //languageId needs to be sent in here.
    return dispatch =>
        fetch(`${apiHost}ebi/chapter/addorupdate`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
            body: JSON.stringify({
                personId,
                workId,
                chapterId: chapter.id || chapter.chapterId || guid.emptyGuid,
                name: chapter.name,
                sequence: chapter.chapterNbr,
                workStatusId: chapter.workStatusId,
                editSeverityId: chapter.editSeverityId,
                comment: chapter.comment,
            }),
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .then(response => {
            const chapterOptions = response;
            let newChapterid = response && response.length > 0 && !!response.filter(m => m.name === chapter.name)[0] && response.filter(m => m.name === chapter.name)[0].chapterId;
            //The entire chapterOptions will be returned for the given work.  I also need to get the current (new) chapterId back..
            dispatch({ type: types.WORK_UPDATE_CHAPTERS, payload: {workId, chapters: chapterOptions}});
            dispatch({ type: types.CHAPTER_TEXT_INIT, payload: ''});  //Update the current ChapterText to blank to start a new section in case the chapterText is populaed from another section, which could feasibly be the case.
            dispatch({ type: types.EDIT_DETAILS_INIT, payload: ''});  //Same as above.
            dispatch({ type: types.CHAPTER_CURRENT_SET_SELECTED, payload: newChapterid});
            dispatch({ type: types.WORK_CURRENT_SET_SELECTED, payload: {workId, chapterId: newChapterid, languageId: '', languageName: ''} }); //The blank languageIds will be filled in by the reducer with the actual current chosen langaugeid and name.
            dispatch(actionWorks.setWorkCurrentSelected(personId, workId, newChapterid, '', "STAY"));
            window.location = isFileUpload ? "/chapterUploadFile" : (isUpdate ? "/workSections" : `/editReview/${workId}`); ///${languageId}
          //languageId needs to be sent in here for editReview
        })
}

// export const deleteChapter = (personId, workId, chapterId) => {
//     return dispatch =>
//     fetch(`${apiHost}ebi/chapter/delete/` + personId + '/' + workId + '/' + chapterId, {
//         method: 'post',
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'Access-Control-Allow-Credentials' : 'true',
//             "Access-Control-Allow-Origin": "*",
//             "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
//             "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
//             "Authorization": "Bearer " + localStorage.getItem("authToken"),
//         },
//     })
//     .then(response => {
//         if (response.status >= 200 && response.status < 300) {
//             return response.json();
//         } else {
//             const error = new Error(response.statusText);
//             error.response = response;
//             throw error;
//         }
//     })
//     .then(response => {
//         dispatch({ type: types.WORK_CHAPTER_DELETE, payload: { workId, chapterOptions: response} });
//     })
// }


export const mergeChapters = (personId, workId, fromChapterId, toChapterId) => {
    return dispatch =>
    fetch(`${apiHost}ebi/chapter/merge/` + personId + '/' + workId + '/' + fromChapterId + '/' + toChapterId, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials' : 'true',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
            "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
            "Authorization": "Bearer " + localStorage.getItem("authToken"),
        },
    })
    .then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    })
    .then(response => {
        //The entire chapterOptions will be returned for the given work.
        dispatch({ type: types.WORK_CHAPTEROPTIONS_UPDATE, payload: { workId, chapterOptions: response} });
    })
}

export const onChangeSequence = (personId, workId, chapterId, sequence) => {
    return dispatch =>
    fetch(`${apiHost}ebi/chapter/sequence/` + personId + '/' + workId + '/' + chapterId + '/' + sequence, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials' : 'true',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
            "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
            "Authorization": "Bearer " + localStorage.getItem("authToken"),
        },
    })
    .then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    })
    .then(response => {
        //The entire chapterOptions will be returned for the given work.
        dispatch({ type: types.WORK_CHAPTER_RESEQUENCE, payload: { workId, chapterOptions: response} });
    })
}

export const splitChapter = (personId, workId, chapterId, newSections) => {
    return dispatch =>
    fetch(`${apiHost}ebi/chapter/splitSection`, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials' : 'true',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
            "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
            "Authorization": "Bearer " + localStorage.getItem("authToken"),
        },
        body: JSON.stringify({
            personId,
            workId,
            chapterId,
            newSplits: newSections,
        }),
    })
    .then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    })
    .then(response => {
        //The entire chapterOptions will be returned for the given work.
        dispatch({ type: types.WORK_CHAPTEROPTIONS_UPDATE, payload: { workId, chapterOptions: response} });
        window.location = "/workSections";
    })
}


export const updateChapterComment = (personId, workId, chapterId, comment) => {
    comment = !comment ? 'EMPTY' : comment;

    return dispatch =>
    fetch(`${apiHost}ebi/chapter/comment/` + personId + '/' + workId + '/' + chapterId + '/' + comment, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials' : 'true',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
            "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
            "Authorization": "Bearer " + localStorage.getItem("authToken"),
        },
    })
    .then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
            const error = new Error(response.statusText);
            error.response = response;
            throw error;
        }
    })
    .then(response => {
        //The entire chapterOptions will be returned for the given work.
        dispatch({ type: types.WORK_CHAPTEROPTIONS_UPDATE, payload: { workId, chapterOptions: response} });
    })
}
