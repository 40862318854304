import React, {Component} from 'react';
import VideoYouTubeView from '../views/LoginView/VideoYouTubeView.js';
import { connect } from 'react-redux';

const mapStateToProps = state => {
    return {
    }
};

const bindActionsToDispatch = dispatch => ({
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
);

class Container extends Component {
    render() {
        return <VideoYouTubeView {...this.props} />
    }
}

export default storeConnector(Container)