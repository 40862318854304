import * as types from './actionTypes';
import getPersonConfig from '../services/person-config.js';
import {apiHost} from '../api_host.js';

function storePersonConfig( personConfig={}) {
    return { type: types.PERSON_CONFIG_INIT, payload: personConfig };
}

export const init = (personId) => dispatch => {
  return getPersonConfig(personId).then( n => dispatch(storePersonConfig(n)))
};

export const updatePersonConfig = (personId, field, value) => {
    return dispatch =>
      fetch(`${apiHost}ebi/personConfig/${personId}/${field}/${value}`, {
        method: 'get',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials' : 'true',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
            "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
            "Authorization": "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
          dispatch({ type: types.PERSON_CONFIG_INIT, payload: response });
      })
      //.catch(error => { console.l og('request failed', error); });
}

export const updatePersonConfigNotPersist = (personId) => {
    //This is to turn off any configurations that were not intended to be kept persistently, but one of the pairs of fields were kept in order
    //  to temporarily control the interface, like NextSentenceAuto and NextSentenceAutoKeepON
    return dispatch =>
      fetch(`${apiHost}ebi/personConfig/notPersist/` + personId , {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials' : 'true',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
            "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
            "Authorization": "Bearer " + localStorage.getItem("authToken"),
        },
        body: JSON.stringify({
        }),
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
            return response.json();
        } else {
          const error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .then(response => {
          dispatch({ type: types.PERSON_CONFIG_INIT, payload: response });
      })
      //.catch(error => { console.l og('request failed', error); });
}
