import React, {useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux';
import * as actionMyProfile from '../actions/my-profile.js';
import * as actionGroups from '../actions/groups.js';
import * as loginUser from "../actions/login";
import * as actionEditorInvite from "../actions/editor-invite-response.js";
import jwtDecode from 'jwt-decode'
import Logo from "../assets/logos/penspring_reg.png";

import { selectMe, selectMyProfile, selectEditorInviteIsActive, selectUsernameToVerify, selectFriendInvitation } from '../store.js';

const mapStateToProps = (state) => {
	let me = selectMe(state);

	return {
		personId: me.personId,
		langCode: me.langCode,
		userProfile: selectMyProfile(state),
		isActiveInvite: selectEditorInviteIsActive(state),
		loginData: selectMe(state),
		usernameToVerify: selectUsernameToVerify(state),
		friendInvitation: selectFriendInvitation(state),
	}
};

const bindActionsToDispatch = dispatch => ({
	getMyProfile: (personId) => dispatch(actionMyProfile.getMyProfile(personId)),
	setMyProfileNew: (user, isNew) => dispatch(actionMyProfile.setMyProfileNew(user, isNew)),
	updateMyProfile: (user) => dispatch(actionMyProfile.updateMyProfile(user)),
	//removeProfilePicture: (personId, profilePictureId) => dispatch(actionMyProfile.removeProfilePicture(personId, profilePictureId)),
	login: (userData, inviteResponse, salta, sendto, authLogin) => dispatch(loginUser.login(userData, inviteResponse, salta, sendto, authLogin)),
	logout: ()  => dispatch(loginUser.logout()),
	addUserToGroupInvite: (inviteCodeShort) => dispatch(actionGroups.addUserToGroupInvite(inviteCodeShort)),
	getInviteFromShortCode: (inviteCodeShort) => dispatch(actionEditorInvite.getInviteFromShortCode(inviteCodeShort)),
	isEditorInviteActive: (inviteCode) => dispatch(actionEditorInvite.isEditorInviteActive(inviteCode)),
});

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
);

function HomeContainer(props) {
	const { personId, getMyProfile, isEditorInviteActive, isActiveInvite, getInviteFromShortCode, friendInvitation, addUserToGroupInvite } = props;
	const params = useParams()
	const navigate = useNavigate()
	let storage
	let isValidToken = false

	const authTokenStorage = window.localStorage.getItem('authToken')
	if (authTokenStorage) {
		const token = jwtDecode(authTokenStorage);
		isValidToken = new Date().getTime() < (token * 1000) && token.emailAddress.toLowerCase() === friendInvitation.email.toLowerCase() 
	}

	useEffect(() => {
		if (friendInvitation && friendInvitation.friendInvitationId && params.createNew !== 'reset') {
			personId && getMyProfile(personId)
			isEditorInviteActive(friendInvitation.friendInvitationId)
			if (window) storage = window.localStorage.getItem('loglevel') 
		}
		getInviteFromShortCode(params.inviteCodeShort)
	}, [friendInvitation])

	useEffect(() => {
		//If the user is logged in, then let the user be a part of the group and go to the MyWorksView page
		if (isValidToken && isActiveInvite && params.createNew === 'grp') {
			addUserToGroupInvite(params.inviteCodeShort) //The personId will be picked up on the server side.
			setTimeout(() => navigate('/myWorks'), 500)
		}
	}, [isValidToken, isActiveInvite])

	if (window && !storage) {
		storage = window.localStorage.getItem('workFileTreeExplorer')
		window.localStorage.setItem('inviteCodeShort', params.inviteCodeShort)
	}

	const guideTraffic = () => {
		let result = null
		const defaultResult = (
			<div style={{ marginLeft: '100px', marginTop: '40px', alignItems: 'center' }}>
				This is an invalid or expired invitation<br /><br />
				Go to<br /> <a href={'/'} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'none' }}>
					the <img src={Logo} alt={'penspring'} style={{ height: '18px', position: 'relative', top: '3px' }} /> landing page</a>
			</div>
		)

		//We are using terse createNew entries in order to keep the invite link short and to the point. The user might have to copy or otherwise type it in directly to a browser.
		//To process an invite, there are a few codes to consider
		//	reset  (which I can't remember what this one is for since I haven't traced it)
		//	c (create - indicating that this user does not exist by emailAddress in the database currently)
		//	p (update with a missing password but the record does exist by emailAddress in the database currently - so it will fall on the person login process to match up the emailAddress, if that is the one that the user uses to create their profile.)
		//	e  (existing with an emailAddress and what appears to be a valid but encrypted password in the databsae)

		if (!(friendInvitation && friendInvitation.friendInvitationId) || (!isActiveInvite && params.createNew !== 'reset')) {
			return defaultResult
		} else if (isActiveInvite === 'VALID' || params.createNew === 'reset') {
			if (params.createNew === 'e') { //existing
				if (isValidToken) { //If they are logged in, take them to the MyWorksView directly
					navigate('/myWorks')
				} else {
					navigate('/login')
				}
			} else {
				navigate(`/myProfile/${friendInvitation.friendInvitationId}/${friendInvitation.fname}/${friendInvitation.lname || 'EMPTY'}/${friendInvitation.email}/${friendInvitation.phone || 'EMPTY'}/${params.createNew}`)
			}
		} else {
			result = defaultResult
		}
		return result
	}

	return guideTraffic()
}

export default storeConnector(HomeContainer)
