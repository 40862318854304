import * as types  from '../actions/actionTypes';

export default function(state = {}, action) {
  switch (action.type) {
    case types.MY_PROFILE_INIT:
      return action.payload;

    default:
        return state;
  }
}

export const selectMyProfile = (state) => state;
