import React, { useState, useEffect } from 'react'
import styles from './AssessmentFillBlank.module.css'
import globalStyles from '../../../utils/globalStyles.module.css'
import classes from 'classnames'
import TextDisplay from '../../TextDisplay'
import ImageDisplay from '../../ImageDisplay'
import AudioDisplay from '../../AudioDisplay'
import InputText from '../../InputText'
import QuestionLabel from '../../QuestionLabel'
import PointsDisplay from '../../PointsDisplay'
import Icon from '../../Icon'

function AssessmentFillBlank(props) {
  const {
    assignmentId,
    bigTextDisplay,
    className = "",
    correctControls,
    isAuthor,
    learnerAnswer,
    nameKey,
    onClick,
    personId,
    removeQuestionFileOpen,
    removeQuestionRecordingOpen,
    removeSolutionFileOpen,
    removeSolutionRecordingOpen,
    score,
    submitAction,
    submitEssayResponse,
  } = props


  //This can be confusing, but we are taking the learnerAnswer which could be split up by ~^ which we do in order to preserve commas in the answer rather that delimiting by a comma.
  const [answersFillBlank, setAnswersFillBlank] = useState([])
  const [assessmentCorrect, setAssessmentCorrect] = useState()
  const [correct, setCorrect] = useState()
  const [entry, setEntry] = useState({
    essayResponse: '',
    score: '',
    isCorrect: '',
  })
  const [errorScore, setErrorScore] = useState()
  const [fillInTheBlankPhrases, setFillInTheBlankPhrases] = useState()
  const [question, setQuestion] = useState({})

  useEffect(() => {
    setQuestion(props.question)
  }, [props.question])

  useEffect(() => {
    let correct = (assessmentCorrect && assessmentCorrect.length > 0 && assessmentCorrect.filter(m => m.assessmentQuestionId === question.assessmentQuestionId)[0]) || {}

    setAssessmentCorrect(props.assessmentCorrect)
    setAnswersFillBlank(learnerAnswer?.learnerAnswer ? learnerAnswer.learnerAnswer.split('~^') : [])
    setEntry({
      essayResponse: isAuthor && assessmentCorrect ? assessmentCorrect.teacherEssayResponse : assessmentCorrect ? assessmentCorrect.learnerAnswer : '',
      score: correct && correct.score,
      isCorrect: correct && correct.isCorrect,
    })
  }, [props.assessmentCorrect, learnerAnswer, correct])

  useEffect(() => {
    setFillInTheBlankPhrases(getFillInTheBlankPhrases())
    setCorrect((assessmentCorrect?.length > 0 && assessmentCorrect.filter(m => m.assessmentQuestionId === question.assessmentQuestionId)[0]) || [])
    setAnswersFillBlank(question.learnerAnswer && question.learnerAnswer.learnerAnswer && typeof question.learnerAnswer.learnerAnswer === 'string'
      ? question.learnerAnswer.learnerAnswer.split('~^')
      : question.learnerAnswer && question.learnerAnswer.learnerAnswer)
  }, [assessmentCorrect, question])

  const handleChange = ({ target }) => {
    let newEntry = { ...entry }
    newEntry['score'] = target.value === '' ? 'EMPTY' : target.value
    setEntry(newEntry)
    submitEssayResponse(question.assessmentQuestionId, entry)
  }

  const toggleCheckbox = () => {
    let newEntry = { ...entry }
    newEntry['isCorrect'] = !newEntry['isCorrect']
    setEntry(newEntry)
    submitEssayResponse(question.assessmentQuestionId, entry)
  }

  const handleSubmit = () => {
    var hasError = false

    if (!(entry.score === 0 || entry.score > 0)) {
      hasError = true
      setErrorScore(`A score is required`)
    }

    if (!hasError) {
      submitAction(question.assessmentQuestionId, entry)
    }
  }

  const handleAnswerChange = (event, index) => {
    //This particular answer type can have more than one answer.  So we are going to preserve the back-end structure and just create a stopRecording
    //		of answers separated by ~^ so that we just keep moving them out of an array and back into an array on the client-side.
    // answersFillBlank is an array - not an object:  {0: 'thing', 1: "another"} so just an array ['thing', 'another'] but the indexes need to be accurate for the answers.
    // First, create a copy of the array to maintain immutability.
    const newAnswers = [...answersFillBlank]
    // Update the value at the specified index.
    newAnswers[index] = event.target.value
    setAnswersFillBlank(newAnswers)
  };


  const sendAssessmentAnswer = (event) => {
    //This particular answer type can have more than one answer.  So we are going to preserve the back-end structure and just create a stopRecording
    //		of answers separated by ~^ so that we just keep moving them out of an array and back into an array on the client-side.
    if (onClick) onClick(personId, question.assessmentQuestionId, (answersFillBlank?.length > 0 && answersFillBlank.join('~^')) ?? '', assignmentId)
  }

  const fillInTheBlankDisplay = () => {
    let arrayWords = question.questionText && question.questionText.split(' ')
    let result = <div className={styles.row}>
      {arrayWords && arrayWords.length > 0 && arrayWords.map((word, index) => {
        if (question.correctAnswer && question.correctAnswer.indexOf(index) > -1) {
          return <input
            key={index}
            type={'text'}
            className={classes(styles.wordSpace, (bigTextDisplay ? styles.longerTextInput : styles.shortTextInput))}
            disabled={true} />
        } else {
          return <div key={index} className={styles.wordSpace}>{word}</div>
        }
      })}
    </div>
    return result
  }

  const blankOutWord = (index, assessIncoming) => {
    const newQuestion = assessIncoming ? assessIncoming : {...question}

    let fillBlanksChosen = typeof newQuestion.correctAnswer === 'string'
      ? newQuestion.correctAnswer.split(',')
      : newQuestion.correctAnswer

    //If this index exists, then delete it.
    //Otherwise add it.
    if (fillBlanksChosen && fillBlanksChosen.length > 0 && fillBlanksChosen.indexOf(index) > -1) {
      fillBlanksChosen = fillBlanksChosen.filter(m => m !== index)
    } else {
      fillBlanksChosen = fillBlanksChosen && fillBlanksChosen.length > 0 ? fillBlanksChosen.concat(index) : [index]
    }
    fillBlanksChosen = fillBlanksChosen && fillBlanksChosen.length > 0 && fillBlanksChosen.map(m => !m ? null : Number(m))
    setQuestion({...question, correctAnswer: fillBlanksChosen })
  }

  const getFillInTheBlankPhrases = () => {
    let arrayWords = (question?.questionText?.length > 0 && question.questionText.split(' ')) ?? []
    let fillBlanksChosen = typeof question.correctAnswer === 'string'
      ? question.correctAnswer.split(',')
      : question.correctAnswer

    let fillInTheBlankPhrases = []

    //Reset the consecutive phrases
    if (fillBlanksChosen && fillBlanksChosen.length > 0) {
      fillBlanksChosen = fillBlanksChosen.sort()
      let phraseCount = ''
      let prevIndex = ''
      let space = ''
      fillBlanksChosen.forEach(m => {
        if (((prevIndex || prevIndex === 0) && m === prevIndex + 1 * 1) || (!prevIndex && prevIndex !== 0)) {
          phraseCount = phraseCount || 0
          fillInTheBlankPhrases[phraseCount] = fillInTheBlankPhrases[phraseCount]
            ? fillInTheBlankPhrases[phraseCount] += space + arrayWords[m]
            : arrayWords[m]
          space = ' '
        } else {
          fillInTheBlankPhrases[++phraseCount] = arrayWords[m]
        }
        prevIndex = m
      })
    }
    return fillInTheBlankPhrases
  }

  const getKeywords = (question) => {
    let answers = (question?.correctAnswer?.length > 0 && question.correctAnswer.split(',')) ?? []
    let arrayWords = question.questionText && question.questionText.split(' ')
    let result = ''
    let comma = ''
    for (let i = 0; i < answers.length; i++) {
      result += comma + arrayWords[Number(answers[i])]
      comma = ', '
    }
    return result
  }

  return !question ? null : (
    <div className={classes(className, styles.container)} key={nameKey}>
      <QuestionLabel label={'Fill in the Blank'} />
      <div className={classes(styles.row, styles.questionLine)}>
        {assessmentCorrect && assessmentCorrect.length > 0
          ? correct.isCorrect
            ? <Icon pathName={'checkmark0'} fillColor={'green'} premium={true}
              className={styles.icon} />
            : <Icon pathName={'cross_circle'} fillColor={'red'} premium={true}
              className={styles.icon} />
          : ''
        }
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.sequence)}>{question?.sequence}.
        </div>
        <div
          className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.question)}>{!isAuthor ? fillInTheBlankDisplay() : question?.questionText}</div>
      </div>
      <PointsDisplay className={styles.littleRight} correctControls={correctControls} pointsPossible={question.pointsPossible} score={score} />
      {question.questionRecordingFileUrl &&
        <AudioDisplay src={question.questionRecordingFileUrl} preload={'auto'}
          controls="controls"
          isSmall={true} isOwner={question.isOwner}
          className={styles.audioLeftQuestion}
          deleteFunction={(event) => removeQuestionRecordingOpen(event, question.assessmentQuestionId, question.questionRecordingUploadId)} />
      }
      {question.questionFileUploadId &&
        <ImageDisplay linkText={''} url={question.questionFileUrl}
          isOwner={question.isOwner}
          deleteFunction={() => removeQuestionFileOpen(question.assessmentQuestionId, question.questionFileUploadId)} />
      }
      <div className={styles.answerLeft}>
        <div className={classes(styles.row, styles.moreLeft)}>
          <div className={styles.text}>
            {isAuthor
              ? question.learnerAnswer && question.learnerAnswer.isSubmitted
                ? <div>
                  {fillInTheBlankPhrases && fillInTheBlankPhrases.length > 0 && fillInTheBlankPhrases.map((m, i) => {
                    let learnerAnswer = answersFillBlank[i]

                    return !m
                      ? null
                      : <div className={styles.row} key={i}>
                        <TextDisplay label={"Student's answer"}
                          text={
                            <div
                              className={classes((bigTextDisplay ? globalStyles.bigText : ''), (question.learnerAnswer && (question.learnerAnswer.isCorrect || learnerAnswer === m) ? styles.correctText : styles.wrongText))}>
                              {learnerAnswer}
                            </div>}
                        />
                        {!(question.learnerAnswer && question.learnerAnswer.isCorrect) &&
                          <TextDisplay label={"Correct answer"}
                            text={<div
                              className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.correctText)}>{m}</div>}
                            className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.correctText)} />
                        }
                      </div>
                  })}
                </div>
                : <TextDisplay label={'Blank-out phrase'} text={fillInTheBlankDisplay()} />
              : question && question.learnerAnswer && question.learnerAnswer.learnerAnswer && question.learnerAnswer.isSubmitted
                ? <div>
                  {fillInTheBlankPhrases && fillInTheBlankPhrases.length > 0 && fillInTheBlankPhrases.map((m, i) => {
                    let learnerAnswer = answersFillBlank[i]

                    return !m
                      ? null
                      : <div className={styles.row} key={i}>
                        <TextDisplay label={"Student's answer"}
                          text={
                            <div
                              className={classes((bigTextDisplay ? globalStyles.bigText : ''), (question.learnerAnswer && (question.learnerAnswer.isCorrect || learnerAnswer === m) ? styles.correctText : styles.wrongText))}>
                              {learnerAnswer}
                            </div>}
                        />
                        {!(question.learnerAnswer && question.learnerAnswer.isCorrect) &&
                          <TextDisplay label={"Correct answer"}
                            text={<div
                              className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.correctText)}>{m}</div>}
                            className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.correctText)} />
                        }
                      </div>
                  })}
                </div>
                : <div>
                  {fillInTheBlankPhrases?.length > 0 && fillInTheBlankPhrases.map((m, i) =>
                    !m
                      ? null
                      : <InputText key={i}
                          id={`learnerAnswer`}
                          name={`learnerAnswer`}
                          size={bigTextDisplay ? 'bigtext' : 'medium'}
                          height={bigTextDisplay ? 'bigtext' : ''}
                          label={fillInTheBlankPhrases && fillInTheBlankPhrases.length > 1 ? `Answer #${i + 1 * 1}` : "Answer"}
                          value={(answersFillBlank?.length > 0 && answersFillBlank[i]) || ''}
                          onChange={(event) => handleAnswerChange(event, i)}
                          onBlur={sendAssessmentAnswer}
                          required={true}
                          whenFilled={answersFillBlank?.length > 0 && answersFillBlank[i]}
                          inputClassName={bigTextDisplay ? globalStyles.bigText : ''}
                          labelClass={bigTextDisplay ? globalStyles.bigText : ''}
                          autoComplete={'dontdoit'} />
                  )}
                </div>
            }
          </div>
        </div>
        {question.answerVariations && question.answerVariations.length > 0 && isAuthor &&
          <div className={globalStyles.instructionsBig}>
            The student's answer will be accepted if it is the given correct answer or an acceptable variation.
          </div>
        }
        <div className={styles.rowWrap}>
          {isAuthor &&
            <div className={styles.row}>
              <TextDisplay label={"Correct answer"} text={<div className={classes((bigTextDisplay ? globalStyles.bigText : ''), styles.correctText)}>{getKeywords(question)}</div>} />
              <TextDisplay label={"Acceptable answer variations"}
                text={question.answerVariations && question.answerVariations.length > 0
                  ? question.answerVariations.map((variation, i) =>
                    <div key={i}
                      className={classes(styles.labelBold, styles.littleLeft)}>{variation}</div>)
                  : <div key={'none'}
                    className={classes(styles.labelItalicsGray, styles.littleLeft)}>none</div>
                } />
            </div>
          }
        </div>
        {(question.solutionText || question.solutionFileUrl || question.solutionRecordingFileUrl) && (question.isOwner || (correct && correct.assessmentId)) &&
          <div>
            {!(correct && correct.assessmentId) &&
              <div className={globalStyles.instructions}>
                After the quiz is corrected, this explanation or picture will be displayed.
              </div>
            }
            <div className={styles.row}>
              <div
                className={classes(styles.marginRight, styles.text)}>Solution:
              </div>
              <div className={styles.text}>{question.solutionText}</div>
            </div>
            {question.solutionRecordingFileUrl &&
              <AudioDisplay src={question.solutionRecordingFileUrl}
                preload={'auto'} controls="controls"
                className={styles.audioLeftQuestion}
                browserMessage={'This browser does not support this audio control'}
                isSmall={true} isOwner={question.isOwner}
                deleteFunction={() => removeSolutionRecordingOpen(question.assessmentQuestionId, question.questionRecordingUploadId)} />
            }
            {question.solutionFileUrl && question.solutionFileUploadId &&
              <ImageDisplay linkText={''} url={question.solutionFileUrl}
                isOwner={question.isOwner}
                deleteFunction={() => removeSolutionFileOpen(question.assessmentQuestionId, question.solutionFileUploadId)} />
            }
          </div>
        }
      </div>
    </div>
  )
}


export default AssessmentFillBlank