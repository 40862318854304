import React from 'react';
import styles from './Button.module.css';
import classes from 'classnames';

export default ({ label = "", id, disabled = false, onClick, replaceClassName, addClassName, keyIndex, changeRed }) => {
	return (
		<button id={id} name={id} key={keyIndex} disabled={disabled} tabIndex={0}
			className={classes(addClassName, (replaceClassName ? replaceClassName : styles.button), (changeRed ? styles.redButton : ''))}
			onClick={onClick}>
			{label}
		</button>
	)
};
