import * as types from '../actions/actionTypes';

export default function(state = {}, action) {
    switch(action.type) {
        case types.ASSESSMENTS_INIT:
            return action.payload;

        default:
            return state;
    }
}

export const selectAssessments = (state) => state;
