import * as types  from '../actions/actionTypes'

export default function(state = '', action) {
  switch (action.type) {
    case types.VERIFY_USERNAME:
      return action.payload

    default:
        return state
  }
}

export const selectUsernameToVerify = (state) => state
