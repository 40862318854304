import React, {useEffect} from 'react';
import EditorInviteNameView from '../views/EditorInviteNameView';
import { connect } from 'react-redux';
import {doSort} from '../utils/sort.js';
import * as editorInviteName from '../actions/editor-invite-name.js';
import * as fromEditorInviteWorkAssign from '../actions/editor-invite-work';
import * as fromContacts from '../reducers/contacts.js';
import * as actionContacts from '../actions/contacts.js';
import * as actionWorkFilter from '../actions/work-filter.js';
import * as actionWorks from '../actions/works.js';
import * as actionChapters from '../actions/chapters.js';
import * as actionEditorInvitePending from '../actions/editor-invite-pending.js';
import * as fromWorks from '../reducers/works.js';
import * as editorInviteWork from '../actions/editor-invite-work.js';
import * as actionPersonConfig from '../actions/person-config.js';
import * as actionGroups from '../actions/groups.js';

import { selectEditorInvitePending, selectEditorInviteName, selectWorkSummary, selectWorkSummaryCurrent, selectMe, selectWorkFilter, selectLanguages,
    selectEditorInviteWorkAssign, selectPersonConfig, 
    selectGroups} from '../store.js';

// takes values from the redux store and maps them to props
const mapStateToProps = state => {
    let contacts = fromContacts.selectContactsArray(state.contacts);
    const editorInviteName = selectEditorInviteName(state);
    const editorInviteWorkAssign = selectEditorInviteWorkAssign(state);  //These work assign records are strictly on hold for this person being invited.
    let works = fromWorks.selectWorks(state.works);
    let workFilterList = selectWorkFilter(state);
    let me = selectMe(state);
    let currentWorkSummary = selectWorkSummaryCurrent(state);
    let workFilterOptions = workFilterList && workFilterList.length > 0 && workFilterList.filter(m => !m.scratchFlag);
    workFilterOptions = workFilterOptions && workFilterOptions.length > 0
      && workFilterOptions.map(m => ({id: m.workFilterId, label: m.savedSearchName.length > 25 ? m.savedSearchName.substring(0,25) + '...' : m.savedSearchName}));
    let filterScratch = workFilterList && workFilterList.length > 0 && workFilterList.filter(m => m.scratchFlag)[0];

    if (works && filterScratch.searchText) {
        works = works.filter(w => w.title && w.title.toLowerCase().indexOf(filterScratch.searchText.toLowerCase()) > -1);
    }

    //We are forcing the owner-type to be "mine" and the status to be "active".  This might be confusing if a user has a savedSearch that varies
    //  from those two settings. It will not be managing their expectations properly.  We may have to add a note to the page. Those controls are hidden on this view.
    works = works && works.filter(w => w.personId === me.personId && w.active);

    if (works && filterScratch.dueDateFrom && filterScratch.dueDateTo) {
        works = works.filter(w => w.dueDate >= filterScratch.dueDateFrom && w.dueDate <= filterScratch.dueDateTo);
    } else if (works && filterScratch.dueDateFrom) {
        works = works.filter(w => w.dueDate >= filterScratch.dueDateFrom);
    } else if (works && filterScratch.dueDateTo) {
        works = works.filter(w => w.dueDate <= filterScratch.dueDateTo);
    }

    let sortByHeadings = {
        sortField: filterScratch.orderByChosen,
        isAsc: filterScratch.orderSortChosen === 'asc',
        isNumber: false //None of the options are numbers in this case
    }

    works = doSort(works, sortByHeadings);
    let workSummaries = works && works.length > 0 && works.map(({workId}) => selectWorkSummary(state, workId));

    const groups = selectGroups(state)

    const groupOptions = groups && groups.length > 0 && groups.reduce((acc, m) => {
      let exists = m.groupId && acc && acc.length > 0 && acc.filter(f => f.id === m.groupId)[0]
      if (!exists && !!m.groupId) {
        let option = {
          id: m.groupId,
          label: m.groupName
        }
        return acc && acc.length > 0 ? acc.concat(option) : [option]
      }
    }, [])

    return {
      personId: me.personId,
      editorInvitePending: selectEditorInvitePending(state),
      editorInviteName, //??? Now that we combined pages, this might not be necessary
      contacts,
      works,
      workFilterOptions,
      filterScratch,
      savedFilterIdCurrent: filterScratch && filterScratch.savedFilterIdCurrent,
      owner_personId: me.personId,
      currentWorkSummary,
      workSummaries,
      languageOptions: selectLanguages(state),
      editorInviteWorkAssign,
      personConfig: selectPersonConfig(state),
      groupOptions,
    }
}

// binds the result of action creators to redux dispatch, wrapped in callable functions
const bindActionsToDispatch = dispatch => ({
  initWorks: (personId) => dispatch(actionWorks.init(personId)),
  initWorkFilter: (personId) => dispatch(actionWorkFilter.init(personId)),
  setWorkCurrentSelected: (personId, workId, chapterId, languageId, goToPage) => dispatch(actionWorks.setWorkCurrentSelected(personId, workId, chapterId, languageId, goToPage)),
  deleteWork: (personId, workId) => dispatch(actionWorks.deleteWork(personId, workId)),
  setWorkAssign: (workId, chapterIdList, languageIdList, deleteChoice) => dispatch(editorInviteWork.setWorkAssign(workId, chapterIdList, languageIdList, deleteChoice)),
  updateChapterDueDate: (personId, workId, chapterId, languageId, dueDate) => dispatch(actionChapters.updateChapterDueDate(personId, workId, chapterId, languageId, dueDate)),
  updateChapterComment: (personId, workId, chapterId, comment) => dispatch(actionChapters.updateChapterComment(personId, workId, chapterId, comment)),
  clearFilters: (personId) => dispatch(actionWorkFilter.clearFilters(personId)),
  saveNewSavedSearch: (personId, savedSearchName) => dispatch(actionWorkFilter.saveNewSavedSearch(personId, savedSearchName)),
  updateSavedSearch: (personId, workFilterId) => dispatch(actionWorkFilter.updateSavedSearch(personId, workFilterId)),
  deleteSavedSearch: (personId, workFilterId) => dispatch(actionWorkFilter.deleteSavedSearch(personId, workFilterId)),
  chooseSavedSearch: (personId, workFilterId) => dispatch(actionWorkFilter.chooseSavedSearch(personId, workFilterId)),
  updateFilterByField: (personId, field, value) => dispatch(actionWorkFilter.updateFilterByField(personId, field, value)),
  updateFilterDefaultFlag: (personId, savedFilterIdCurrent, setValue) => dispatch(actionWorkFilter.updateFilterDefaultFlag(personId, savedFilterIdCurrent, setValue)),
  updatePersonConfig: (personId, field, value)  => dispatch(actionPersonConfig.updatePersonConfig(personId, field, value)),

  setNameAndEmail: (firstName, lastName, emailAddress, phone, inviteMessage) => dispatch(editorInviteName.setNameAndEmail(firstName, lastName, emailAddress, phone, inviteMessage)),
  blankOutWorkAssign: () => dispatch(fromEditorInviteWorkAssign.init()),
  deleteInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.deleteInvite(personId, friendInvitationId)),
  acceptInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.acceptInvite(personId, friendInvitationId)),
  resendInvite: (personId, friendInvitationId) => dispatch(actionEditorInvitePending.resendInvite(personId, friendInvitationId)),
  setContactCurrentSelected: (personId, contactPersonId, href) => dispatch(actionContacts.setContactCurrentSelected(personId, contactPersonId, href)),
  sendEditorInvite: (user_PersonId, editorInviteName, editorInviteWorkAssign, sendTo) => dispatch(editorInviteWork.sendEditorInvite(user_PersonId, editorInviteName, editorInviteWorkAssign, sendTo)),
  getInvitesPending: (personId) => dispatch(actionEditorInvitePending.getInvitesPending(personId)),
  sendFirstNotifications: (personId, groupId) => dispatch(actionEditorInvitePending.sendFirstNotifications(personId, groupId)),
  getGroups: (personId) => dispatch(actionGroups.getGroups(personId)),
});

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
);

function Container(props) {
  const { personId, getInvitesPending, blankOutWorkAssign, initWorkFilter, owner_personId, initWorks, getGroups } = props
    useEffect(() => {
      if (personId) {
        blankOutWorkAssign()
        getInvitesPending(personId)
        initWorkFilter(owner_personId);
        initWorks(personId)
        getGroups(personId)
      }
    },[personId])

    return <EditorInviteNameView {...props} />
}

export default storeConnector(Container);
