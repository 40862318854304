import React, {useState} from 'react';
import styles from './SplitterOptionsBar.module.css';
import classes from 'classnames';
import Icon from '../Icon';
import MessageModal from '../MessageModal';
import tapOrClick from 'react-tap-or-click';

function SplitterOptionsBar(props) {
  const {className, toggleLeftSidePanelOpen, jumpToEdit} = props;

  const [isShowingExplain, setIsShowingExplain] = useState(false)
  let {pointer, totalCount} = props;
  pointer = pointer ? pointer : 0;
  totalCount = totalCount ? totalCount : 0;

  const handleNextEdit = () => {
    jumpToEdit('NEXT');
  }

  const handlePrevEdit = () => {
    jumpToEdit('PREV');
  }

  const handleSplitExplainClose = () => setIsShowingExplain(false)
  const handleSplitExplainOpen = () => setIsShowingExplain(true)


  return (
    <div className={classes(className, styles.container)}>
      <div className={styles.row}>
        <div className={styles.sectionView}>
          <button className={styles.editButton} {...tapOrClick(toggleLeftSidePanelOpen)}>
            Confirm Section List
          </button>
        </div>
        <div>
          <a onClick={handleNextEdit}>
            <Icon pathName={`arrow_down`} className={styles.imageLeft}/>
          </a>
        </div>
        <span className={styles.column}>
                    <span className={styles.text}>sections</span>
                    <span className={styles.counts}>
                        {pointer + ` of ` + totalCount}
                    </span>
                </span>
        <div>
          <a onClick={handlePrevEdit}>
            <Icon pathName={`arrow_up`} className={styles.downArrow}/>
          </a>
        </div>
        <div>
          <a onClick={handleSplitExplainOpen}>
            <Icon pathName={`info`} className={styles.image}/>
          </a>
        </div>
      </div>
      <MessageModal show={isShowingExplain} handleClose={handleSplitExplainClose}
                    heading={`How to split a section/chapter?`}
                    explain={`1. Click on any text where you want the new section to begin. The text you chose will be included in that new section.<br/><br/>2. In the text box that appears, either accept the text as the section name or change it.<br/><br/>3. Choose OK<br/><br/>4. When you are ready to accept the section or sections you have indicated, click on the 'Confirm Section List' button to view the result.<br/><br/>5. If you are ready to accept, click on the 'Submit' button at the top of the section list.`}
                    onClick={handleSplitExplainClose}/>
    </div>
  )
}

export default SplitterOptionsBar