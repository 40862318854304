import React, {useState, useEffect} from 'react'
import styles from './GiveAccessToWorksView.module.css'
import globalStyles from '../../utils/globalStyles.module.css'
import OneFJefFooter from '../../components/OneFJefFooter'
import ButtonWithIcon from '../../components/ButtonWithIcon'
import FlexTabularScroll from '../../components/FlexTabularScroll'
import WorkSummaryLine from '../../components/WorkSummaryLine'
import TextDisplay from '../../components/TextDisplay'
import Checkbox from '../../components/Checkbox'
import SelectSingleDropDown from '../../components/SelectSingleDropDown'
import Icon from '../../components/Icon'
import CheckboxCheckmark from '../../assets/checkbox/CheckboxCheckmark.png'
import CheckboxPartial from '../../assets/checkbox/CheckboxPartial.png'
import CheckboxEmpty from '../../assets/checkbox/CheckboxEmpty.png'
import {Link, useNavigate} from "react-router-dom"
import {guidEmpty} from '../../utils/GuidValidate'

function GiveAccessToWorksView(props) {
	const { personId, workEditorAccess = { works: [], editors: [], folder: {} }, params, getWorkEditorAccess, workSummaries, languageOptions } = props
	const navigate = useNavigate()
	const works = workEditorAccess.works || []

	const [showLanguages, setShowLanguages] = useState()
	const [chaptersDisplay, setChaptersDisplay] = useState([])

	useEffect(() => {
		const storage = localStorage.getItem('chaptersDisplay')
		let newChaptersDisplay = storage ? JSON.parse(storage) : []
		setChaptersDisplay(newChaptersDisplay)
	}, [])

	useEffect(() => {
		let hasTranslationAssign = false
		workEditorAccess && workEditorAccess.editors && workEditorAccess.editors.length > 0 && workEditorAccess.editors.forEach(e => {
			e.editorAssign && e.editorAssign.length > 0 && e.editorAssign.forEach(l => {
				if (l.languageId && l.languageId > 1) hasTranslationAssign = true
			})
		})
		if (hasTranslationAssign) setShowLanguages(true)

	}, [workEditorAccess])

	const toggleChaptersDisplay = (work) => {
		const storage = localStorage.getItem('chaptersDisplay')
		let chaptersDisplay = storage ? JSON.parse(storage) : []
		const hasChapter = chaptersDisplay && chaptersDisplay.length > 0 && chaptersDisplay.filter(m => m.workId === work.workId)[0]
		if (hasChapter && hasChapter.workId) {
			chaptersDisplay = chaptersDisplay && chaptersDisplay.length > 0 ? chaptersDisplay.filter(m => m.workId !== work.workId) : []
		} else {
			chaptersDisplay = chaptersDisplay && chaptersDisplay.length > 0 ? chaptersDisplay.concat(work) : [work]
		}
		localStorage.setItem('chaptersDisplay', JSON.stringify(chaptersDisplay))
		setChaptersDisplay(chaptersDisplay)
	}

	const areChaptersDisplayed = (work) => {
		const storage = localStorage.getItem('chaptersDisplay')
		const chaptersDisplay = storage ? JSON.parse(storage) : []
		const hasChapter = chaptersDisplay && chaptersDisplay.length > 0 && chaptersDisplay.filter(m => m.workId === work.workId)[0]
		if (hasChapter && hasChapter.workId) return true
		//if (work.chapters && work.chapters.length > 1) return true
		return false
	}

  const handleSetWorkEditorLanguage = (event, friendPersonId, workId, chapterId, accessChapters) => {
    event.stopPropagation()
    event.preventDefault()
    const languageId = event.target.value
    //Notice that we are going to set isAdd as true because we need to be able to get through to the logic on the backend in order to get the language updated.
    let isAdd = true
    props.setEditorAssign(isAdd, workId, friendPersonId, props.personId, accessChapters.toString(), languageId, guidEmpty, () => getWorkEditorAccess(personId, params.workSearch, params.id))
  }

	const handleSetEditorAssign = (event, isAdd, workId, friendPersonId, languageId, accessChapters, directChapterId=guidEmpty) => {
		event.stopPropagation()
		event.preventDefault()
    const sendLanguageId = Array.isArray(languageId) ? languageId.join(",") : languageId
		props.setEditorAssign(isAdd, workId, friendPersonId, props.personId, accessChapters.toString(), sendLanguageId || '1', directChapterId, () => getWorkEditorAccess(personId, params.workSearch, params.id))
	}

	const includeChaptersRows = (work) => {
		let row = []
		work.chapters.forEach(chapter => {
			const chapterLabel = String(chapter.chapterNbr + ' - ' + chapter.name).length > 32 ? String(chapter.chapterNbr + ' - ' + chapter.name).substring(0, 32) + '...' : chapter.chapterNbr + ' - ' + chapter.name
			row = []
			row.push({
				cellColor: '#eea63a',
				value: <div className={styles.chapterLabel}>{chapterLabel}</div>,
				title: chapter.chapterNbr + ' - ' + chapter.name
			},
			// {
			// 	clickFunction: (event) => setAllClearAllByChapterId(event, work, chapter.chapterId),
			// 	value: <Icon pathName={'clipboard_check'} premium={true} fillColor={'white'} className={styles.setAllIconWork} onClick={(event) => setAllClearAllByChapterId(event, work, chapter.chapterId)}
			// 		title={'Set or clear all assignments for this chapter'} />
			// }
			)
			workEditorAccess.editors.forEach((contact) => {
				let hasChapterAccess = contact.editorAssign.filter(m => m.chapterId === chapter.chapterId)[0]
				let image
				let clickFunction
				let isAdd
				let assignLanguageId = hasChapterAccess && hasChapterAccess && hasChapterAccess.languageId ? hasChapterAccess.languageId : 1
				if (hasChapterAccess) {
					image = CheckboxCheckmark
					isAdd = false
				} else {
					image = CheckboxEmpty
					isAdd = true
				}
				clickFunction = (event) => handleSetEditorAssign(event, isAdd, work.workId, contact.personId, assignLanguageId, [chapter.chapterId], chapter.chapterId)
				row.push({
					value:
						<div className={styles.link}>
							<img src={image} height={18} onClick={clickFunction} className={styles.clickableArea} />
						</div>
				})
			})
			data.push(row)
		})
	}

	let data = []
	let headings = [{
		label: '',
		cellColor: 'white'
	}, {}]

	const setAllClearAll = (event, setType, workId, editorPersonId) => {
		event.stopPropagation()
		event.preventDefault()
		if (!setType) {
			//If there is one that is not set, then it is SetAll, otherwise it is clearAll
			let hasOneUnassigned
			workEditorAccess.works && workEditorAccess.works.length > 0 && workEditorAccess.works.forEach(work => {
				let continueWork = workId ? work.workId === workId : true
				if (continueWork) {
					workEditorAccess.editors && workEditorAccess.editors.length > 0 && workEditorAccess.editors.forEach(editor => {
						let continueEditor = editorPersonId ? editor.personId === editorPersonId : true
						if (continueEditor) {
							let hasWork = editor.editorAssign && editor.editorAssign.length > 0 && editor.editorAssign.filter(assign => assign.workId === work.workId)[0]
							if (!hasWork) hasOneUnassigned = true
						}
					})
				}
			})
			setType = hasOneUnassigned ? 'SetAll' : 'ClearAll'
		}

		workEditorAccess.works && workEditorAccess.works.length > 0 && workEditorAccess.works.forEach(work => {
			let continueWork = workId ? work.workId === workId : true
			if (continueWork) {
				let accessChapters = work.chapters && work.chapters.length > 0 && work.chapters.reduce((acc, work) => {
					acc = acc && acc.length > 0 ? acc.concat(work.chapterId) : [work.chapterId]
					return acc
				}, [])

				workEditorAccess.editors && workEditorAccess.editors.length > 0 && workEditorAccess.editors.forEach(editor => {
					let continueEditor = editorPersonId ? editor.personId === editorPersonId : true
					if (continueEditor) {
						let hasWork = editor.editorAssign && editor.editorAssign.length > 0 && editor.editorAssign.filter(assign => assign.workId === work.workId)[0]
						if (setType === 'ClearAll') {
							let isAdd = false
							if (hasWork) props.setEditorAssign(isAdd, work.workId, editor.personId, props.personId, accessChapters.toString(), '1')
						} else if (setType === 'SetAll') {
							let isAdd = true
							if (!hasWork) props.setEditorAssign(isAdd, work.workId, editor.personId, props.personId, accessChapters.toString(), '1')
						}
					}
				})
			}
		})
		setTimeout(() => getWorkEditorAccess(personId, params.workSearch, params.id), 500)
	}

	// const setAllClearAllByChapterId = (event, work, chapterId) => {
	// 	event.stopPropagation()
	// 	event.preventDefault()
	// 	//If there is one that is not set, then it is SetAll, otherwise it is clearAll
	// 	//But if just got deeper. If there is one that is partial due to not having all chapters assigned to them, don't to anything but open up those list of chapters.
	// 	//	Otherwise, set it to all chapters. Same on clear
	// 	//Hmmm. I think that we are going to hide these set-all/clear-all icons for now.
	// 	let hasOneUnassigned
	// 	workEditorAccess.works && workEditorAccess.works.length > 0 && workEditorAccess.works.filter(m => m.workId === work.workId).chapterOptions.forEach(chapter => {
	// 		workEditorAccess.editors && workEditorAccess.editors.length > 0 && workEditorAccess.editors.forEach(editor => {
	// 			let hasWork = editor.editorAssign && editor.editorAssign.length > 0 && editor.editorAssign.filter(assign => assign.chapterId === chapter.chapterId)[0]
	// 			if (!hasWork) hasOneUnassigned = true
	// 		})
	// 	})
	// 	const setType = hasOneUnassigned ? 'SetAll' : 'ClearAll'

	// 	workEditorAccess.works && workEditorAccess.works.length > 0 && workEditorAccess.works.filter(m => m.workId === work.workId).chapterOptions.forEach(chapter => {
	// 		workEditorAccess.editors && workEditorAccess.editors.length > 0 && workEditorAccess.editors.forEach(editor => {
	// 			let hasWork = editor.editorAssign && editor.editorAssign.length > 0 && editor.editorAssign.filter(assign => assign.workId === work.workId)[0]
	// 			if (setType === 'ClearAll') {
	// 				let isAdd = false
	// 				if (hasWork) props.setEditorAssign(isAdd, work.workId, editor.personId, props.personId, [chapter.chapterId], '1', chapter.chapterId)
	// 			} else if (setType === 'SetAll') {
	// 				let isAdd = true
	// 				if (!hasWork) props.setEditorAssign(isAdd, work.workId, editor.personId, props.personId, [chapter.chapterId], '1', chapter.chapterId)
	// 			}
	// 		})
	// 	})
	// 	setTimeout(() => getWorkEditorAccess(personId, params.workSearch, params.id), 500)
	// }

	workEditorAccess.editors && workEditorAccess.editors.length > 0 && workEditorAccess.editors.forEach(contact => {
		const fullName = `${contact.firstName} ${contact.lastName}`
		const headerTextLength = 25

		headings.push({
			verticalText: true,
			label:
        <div className={styles.narrowLine}>
          {/* {works && works.length > 1 &&
            <Icon pathName={'clipboard_check'} premium={true} fillColor={'white'} className={styles.setAllIcon} 
							onClick={(event) => setAllClearAll(event,'', '', contact.personId)}
							title={'Set or clear all assignments for this editor'}/>
          } */}
          {fullName.length > headerTextLength ? fullName.substring(0, headerTextLength) + '...' : fullName}
          <span className={styles.editorScore}>{contact.editorScore}</span>
        </div>
		})
	})

	if (!works || works.length === 0) {
		let row = [{
			cellColor: 'background',
			value: <div onClick={() => {
			}} className={styles.workNameRed}>
				No documents according to the criteria given
			</div>
		}]
		data.push(row)
	}

	works.forEach((work, i) => {
		let row = [{
			cellColor: 'maroon',
			value: <WorkSummaryLine work={work} nameLength={30} addClassName={styles.workSummaryLine} titleWhite chapterCountLinkClick={() => toggleChaptersDisplay(work)}	/>
		},
		// {
		// 	clickFunction: (event) => setAllClearAll(event, '', work.workId),
		// 	value: <Icon pathName={'clipboard_check'} premium={true} fillColor={'white'} className={styles.setAllIconWork} onClick={(event) => setAllClearAll(event, '', work.workId)}
		// 	             title={'Set or clear all assignments for this document'}/>
		// }
		]

		workEditorAccess.editors.forEach((contact, i) => {
			//If there is more than one chapter and the editor doesn't have access to at least one chapter, then her access is partial (the checkbox with the vertical line)
			let hasAccess = false
			let hasNotAChapterAccess = false
			let accessChapters = []
			let hasOneChapter = work.chapters.length === 1

			if (hasOneChapter) {
				hasAccess = contact.editorAssign.filter(c => c.workId === work.workId)[0]
				accessChapters = contact.editorAssign.reduce((acc, m) => {
					acc = acc && acc.length > 0 ? acc.concat(m.chapterId) : [m.chapterId]
					return acc
				}, [])

			} else {
				work.chapters.filter(m => m.workId === work.workId).forEach(chapter => {
					contact.editorAssign.forEach(assign => {
						accessChapters = contact.editorAssign.filter(m => m.workId === work.workId).reduce((acc, m) => {
							acc = acc && acc.length > 0 ? acc.concat(m.chapterId) : [m.chapterId]
							return acc
						}, [])

						if (accessChapters.indexOf(chapter.chapterId) > -1) {
							hasAccess = true
						} else {
							hasNotAChapterAccess = true
						}
					})
				})
			}
			let image
			let clickFunction
			let title
      let isAdd
      let editorAssign = contact.editorAssign.filter(c => c.workId === work.workId)[0]
      let assignLanguageId = editorAssign && editorAssign.languageId ? editorAssign.languageId : 1
      let chapterAssign

			if (hasAccess) {
				if (hasNotAChapterAccess) {
					image = CheckboxPartial
					title = "This editor has partial access to the chapters"
          isAdd = false
          chapterAssign = accessChapters
					clickFunction = areChaptersDisplayed(work) //If the chapters are displayed, give access to all chapters when clicking on the work-level checkbox. Otherwise, just open up the chapters to see which chapters this user has access to before toggling the chapters on or off.
						? (event) => handleSetEditorAssign(event, isAdd, work.workId, contact.personId, assignLanguageId, chapterAssign)
						: () => toggleChaptersDisplay(work)
				} else {
					image = CheckboxCheckmark
					title = "This editor has access to this complete document"
          isAdd = false
          chapterAssign = accessChapters
					clickFunction = (event) => handleSetEditorAssign(event, isAdd, work.workId, contact.personId, assignLanguageId, chapterAssign)
				}
			} else {
				image = CheckboxEmpty
				title = "This editor doesn't have any access to this document"
        isAdd = true
				let chapterList = work.chapters.reduce((acc, m) => acc && acc.length > 0 ? acc.concat(m.chapterId) : [m.chapterId], [])
        chapterAssign = chapterList
        clickFunction = (event) => handleSetEditorAssign(event, isAdd, work.workId, contact.personId, assignLanguageId, chapterList)
			}
			row.push({
				value:
          <div className={styles.link}>
            <img src={image} height={18} onClick={clickFunction} className={styles.clickableArea}/>
            {showLanguages &&
              <div key={i}>
                <SelectSingleDropDown
                  id={`grantLanguage`}
                  label={''}
                  value={assignLanguageId || ''}
                  options={languageOptions}
                  height={`medium-short`}
                  selectClass={styles.selectList}
                  onChange={(event) => handleSetWorkEditorLanguage(event, contact.personId, work.workId, work.chapterId_current, chapterAssign)}/>
            </div>
            }
          </div>
			})
		})
		data.push(row)
		if (areChaptersDisplayed(work)) {
			includeChaptersRows(work)
		}
	})

	return (
    <div className={styles.container}>
      <div className={styles.mainDiv}>
        <div className={globalStyles.pageTitle}>Give Access by Document</div>
        <div className={styles.borderBackground}>
          {workEditorAccess.works && workEditorAccess.works.length > 0 &&
            <div className={styles.setAllClearall}><div onClick={(event) => setAllClearAll(event, 'SetAll')}>set all</div><div className={styles.divider}>|</div><div onClick={(event) => setAllClearAll(event, 'ClearAll')}>clear all</div></div>
          }
          {workEditorAccess.works && workEditorAccess.works.length > 0 &&
            <div className={styles.assignTranslation}>
              <Checkbox
                id={`languageTranslation`}
                label={`Assign language for translation`}
                position={`before`}
                labelClass={styles.textWhite}
                checked={showLanguages}
                onClick={() => setShowLanguages(!showLanguages)}
                className={styles.checkbox} />
            </div>
          }
          {workEditorAccess.folder && workEditorAccess.folder.folderName &&
            <TextDisplay text={workEditorAccess.folder.folderName} label={'Folder'} noDisplayIfBlank className={styles.folderName}/>
          }
          {!(workEditorAccess.works && workEditorAccess.works.length > 0) &&
            <div className={styles.rowSpace}>
              <div className={styles.textWhite}>No documents that you own are found in this search.<br/>Do you want to add a new document?</div>
              <Link to={'/workAddNew'} className={styles.menuItem} data-rh={'Add new document'} title={'Add new document'}
                    tabIndex='-1'>
                <br/>
                <Icon pathName={'document0'} premium={true} superscript={'plus'} supFillColor={'#fbd56f'}
                      fillColor={'white'} tabIndex='-1'/>
              </Link>
            </div>
          }
          {!(workEditorAccess.editors && workEditorAccess.editors.length > 0) && !(workSummaries && workSummaries.length > 0) &&
            <div className={styles.rowSpace}>
              <div className={styles.textWhite}>No editor found. Do you want to invite an editor?</div>
							<Link to={'/editorInviteOptions'} className={styles.menuItem} data-rh={'Invite new editor'}
                    title={'Invite new editor'} tabIndex='-1'>
                <Icon pathName={'user'} premium={true} superscript={'plus'} supFillColor={'#fbd56f'} fillColor={'white'}
                      tabIndex='-1'/>
              </Link>
            </div>
          }
          {workEditorAccess.works && workEditorAccess.works.length > 0 && workEditorAccess.editors && workEditorAccess.editors.length > 0 &&
            <div className={styles.scroll}>
              <FlexTabularScroll headings={headings} data={data} noCount={true} chosenColor={'#f8ead7'} chosenRowColor={'maroon'}/>
            </div>
          }
        </div>
				<div className={styles.displayRight}>
					<ButtonWithIcon label={'Done'} icon={'checkmark0'} onClick={() => navigate(-1)} submitSuccess={false} />
				</div>
        <OneFJefFooter/>
      </div>
		</div>
	)
}

export default GiveAccessToWorksView