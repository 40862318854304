import * as types from './actionTypes.js';
import {apiHost} from '../api_host.js';
import {guidEmpty} from '../utils/GuidValidate'

export function init() {
  return {type: types.EDITOR_INVITE_WORK_CHAPTERS_INIT, payload: []};
}

export function setWorkAssign(workId = "", chapterIdList = [], languageIdList = [], deleteChoice = false) {
  return {type: types.EDITOR_INVITE_WORK_CHAPTERS, payload: {workId, chapterIdList, languageIdList, deleteChoice}};
}

export function sendEditorInvite(owner_personId, editorInviteName, editorInviteWorkAssign = [], sendTo, doNotSend = false) {  //The work assign object can be empty.
  var test = editorInviteName.groupId == "undefined" || !editorInviteName.groupId ? guidEmpty : editorInviteName.groupId
  return dispatch =>
    fetch(`${apiHost}ebi/inviteNewEditor/${doNotSend}`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        "Access-Control-Allow-Origin": "*",
        'Access-Control-Allow-Origins': '*',
        "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
        "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
        "Authorization": "Bearer " + localStorage.getItem("authToken"),
      },
      body: JSON.stringify({
        from_PersonId: owner_personId,
        //to_EmailAddress: editorInviteName.emailAddress,
        to_FirstName: editorInviteName.firstName,
        to_LastName: editorInviteName.lastName,
        to_PersonId: editorInviteName.to_PersonId || guidEmpty, //this is for the existing contact record when it is detected that they are not yet a Penspring user.
        to_Phone: editorInviteName.phone,
        to_EmailAddress: editorInviteName.emailAddress,
        inviteMessage: editorInviteName.inviteMessage,
        groupId: editorInviteName.groupId == "undefined" || !editorInviteName.groupId ? guidEmpty : editorInviteName.groupId,
        editorInviteWorkAssign,
      }),
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
          //dispatch(loginSuccess(response.json()));
        } else {
          const error = new Error(response.statusText);
          throw error;
        }
      })
      .then(response => {
        dispatch({type: types.EDITOR_INVITE_PENDING, payload: response});
        dispatch({type: types.EDITOR_INVITE_NAME_EMAIL, payload: {}}); //Blank out the invite name and email so that the controls will not fill in next time.
        dispatch({type: types.EDITOR_INVITE_WORK_CHAPTERS, payload: {}}); //Blank out the invite name and email so that the controls will not fill in next time.
        window.history.back()
      })
  //.catch(error => { console.l og('request failed', error); });
}
