import * as types from '../actions/actionTypes';
import {doSort} from '../utils/sort.js';

export default function (state = [], action) {
	switch (action.type) {
		case types.WORK_EDITS_INIT:
			return action.payload

		// case types.WORK_EDIT_UPDATE: {
		// 	let edit = action.payload
		// 	let newState = state && state.length > 0 ? [...state] : []
		// 	//If not an ADDLISTITEM
		// 	//  Take the edit away in order to add it again with new data
		// 	//else
		// 	//  Take the edit away if there is a matching addListItemSequence
		// 	//  If the edit comes without an addListItemSequence
		// 	//    set the addListItemSequence to the next greatest sequence
		// 	//  end if
		// 	//end if
		// 	//Add the edit to state
		// 	if (edit.type.indexOf('ADDLISTITEM') === -1) {
		// 		newState = newState && newState.length > 0 && newState.filter(m => !(m.personId === edit.personId && Number(m.elementId) === Number(edit.elementId) && m.type === edit.type))
		// 	} else {
		// 		let existEdit = newState && newState.length > 0 && newState.filter(m => m.personId === edit.personId && Number(m.elementId) === Number(edit.elementId) && m.type === edit.type && Number(m.addListItemSequence) === Number(edit.addListItemSequence))[0]
		// 		//Notice the negative, !, difference in the newState filter compared to the existEdit query above.
		// 		newState = newState && newState.length > 0 && newState.filter(m => !(m.personId === edit.personId && Number(m.elementId) === Number(edit.elementId) && m.type === edit.type && Number(m.addListItemSequence) === Number(edit.addListItemSequence)))
		// 		if (existEdit) {
		// 			edit.addListItemSequence = existEdit.addListItemSequence
		// 		}
		// 	}
		// 	newState = newState && newState.length > 0 ? newState.concat(edit) : [edit]
		// 	newState = doSort(newState, {sortField: 'addListItemSequence', isAsc: true, isNumber: true}) //We need to sort by sequence descending because they are put in upside down in the DOM.
		// 	return newState
		// }

		case types.WORK_EDIT_VOTE_UPDATE: {
			const {edit, responseType, comment} = action.payload
			let newState = state && state.length > 0 ? [...state] : []
			let editSegment = { ...newState && newState.length > 0 && newState.filter(m => m.editSegmentId === edit.editSegmentId)[0]}
			newState = (newState && newState.length > 0 && newState.filter(m => m.editSegmentId !== edit.editSegmentId)) || []
			if (editSegment) {
				let votes = (editSegment.editVotes && editSegment.editVotes.length > 0 && editSegment.editVotes.filter(m => m.voterPersonId !== edit.personId)) || []
				let vote = {
					editSegmentId: edit.editSegmentId,
					voterPersonId: edit.personId,
					voteUpFlag: responseType === 'VoteUp',
					voteDownFlag: responseType === 'VoteDown',
					voteTrollFlag: responseType === 'VoteTroll',
					voterComment: comment,
				}
				votes = votes && votes.length > 0 ? votes.concat(vote) : [vote]
				editSegment.editVotes = [...votes]
				newState = newState && newState.length > 0 ? newState.concat(editSegment) : [editSegment]
			}
			return newState
		}

		case types.WORK_EDIT_COMMENT: {
			const {personId, comment, chapterId, elementId, editSegmentId, editType} = action.payload
			let newState = state && state.length > 0 ? [...state] : []
			let foundEdit = false
			newState = newState && newState.length > 0 && newState.map(m => {
				if (m.editSegmentId === editSegmentId) {
					m.comment = comment
					foundEdit = true
				}
				return m
			})
			if (!foundEdit) {
				let edit = {
					personId,
					chapterId,
					elementId,
					editType,
					comment,
				}
				newState = newState && newState.length > 0 ? newState.concat(edit) : [edit]
			}
			return newState
		}

		case types.WORK_EDIT_RESPONSE: {
			const {responseType, edit} = action.payload
			let newState
			if (responseType === 'DeleteEdit') {
				if (edit.editSegmentId) {
					newState = state && state.length > 0 && state.filter(m => Number(m.editSegmentId) !== Number(edit.editSegmentId))
				} else {
					newState = state && state.length > 0 && state.filter(m => !(m.personId === edit.personId && Number(m.elementId) === Number(edit.elementId) && m.type === edit.type))  //Just the deleted edit is removed. This could be the editor who just created the edit but we don't yet have an editSegmentId
				}
			} else if (responseType === 'RejectEdit') {
				newState = state && state.length > 0 && state.filter(m => Number(m.editSegmentId) !== Number(edit.editSegmentId))  //Just the rejected is removed.
			} else if (responseType === 'AcceptEdit') {
				const element = state && state.length > 0 && state.filter(m => Number(m.editSegmentId) === Number(edit.editSegmentId))[0]
				let elementId
				if (element) {
					elementId = element.elementId
					//Delete all of the edits that have to do with this element - not just the editSegment accepted.
					if (edit.type === 'ADDTAB') {
						//Only delete the edit directly related to this editSegmentId since Tabs are accumulative
						newState = state && state.length > 0 && state.filter(m => Number(m.editSegmentId) !== Number(edit.editSegmentId))
					} else if (edit.type === 'TEXT') {
						newState = state && state.length > 0 && state.filter(m => !(Number(m.elementId) === Number(edit.elementId) && m.type === 'TEXT'))
					} else if (edit.addListItemSequence) {
						newState = state && state.length > 0 && state.filter(m => !(Number(m.elementId) === Number(edit.elementId) && Number(m.addListItemSequence) === Number(edit.addListItemSequence)))
					} else if (edit.subSequence) { //This is for ADDPARAGRAPHSENTENCE
						newState = state && state.length > 0 && state.filter(m => !(Number(m.elementId) === Number(edit.elementId) && Number(m.subSequence) === Number(edit.subSequence)))
					} else {
						newState = state && state.length > 0 && state.filter(m => !(Number(m.elementId) === Number(edit.elementId) && m.type === edit.type))
					}
				}
			}
			return newState
		}

		default:
			return state;
	}
}

export const selectEditSegments = (state) => state;
