import React, { useEffect } from 'react'
import AssessmentQuestionsView from '../views/Assessment/AssessmentQuestionsView'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import * as actionAssessmentQuestions from '../actions/assessment-questions.js'
import * as actionAssessment from '../actions/assessment.js'
import * as actionVoiceRecording from '../actions/voice-recording.js'
import * as actionQuestionTypes from '../actions/question-types.js'
import * as actionWorks from '../actions/works.js'
import {
  selectMe,
  selectAssessmentQuestions,
  selectAssessment,
  selectListLevelGeneral,
  selectQuestionTypes,
  selectVoiceRecording,
} from '../store.js'

// takes values from the redux store and maps them to props
const mapStateToProps = (state, props) => {
  let me = selectMe(state)
  let assessment = selectAssessment(state)
  let assessmentQuestions = selectAssessmentQuestions(state)
  let sequenceCount = assessmentQuestions && assessmentQuestions.length
  let sequences = []
  for (let i = 1; i <= sequenceCount; i++) {
    let option = { id: String(i), value: String(i), label: String(i) }
    sequences = sequences ? sequences.concat(option) : [option]
  }
  let retakeOptions = []
  for (let i = 0; i <= 10; i++) {
    let option = { id: String(i), value: String(i), label: String(i) }
    retakeOptions = retakeOptions ? retakeOptions.concat(option) : [option]
  }

  return {
    assessment,
    assessmentQuestions,
    isAuthor: assessment.personId === me.personId,
    langCode: me.langCode,
    listLevelGeneral: selectListLevelGeneral(state),
    personId: me.personId,
    questionTypes: selectQuestionTypes(state),
    retakeOptions,
    sequences,
    voiceRecording: selectVoiceRecording(state),
  }
}

const bindActionsToDispatch = dispatch => ({
  getAssessment: (personId, assessmentId) => dispatch(actionAssessment.getAssessment(personId, assessmentId)),
  assessmentQuestionsInit: (personId, studentPersonId, assessmentId, assignmentId) => dispatch(actionAssessmentQuestions.init(personId, studentPersonId, assessmentId, assignmentId)),
  addOrUpdateAssessmentItem: (personId, assessmentId, assessmentQuestion) => dispatch(actionAssessmentQuestions.addOrUpdateAssessmentItem(personId, assessmentId, assessmentQuestion)),
  addOrUpdateAssessmentItemMatching: (personId, assessmentId, assessmentQuestion) => dispatch(actionAssessmentQuestions.addOrUpdateAssessmentItemMatching(personId, assessmentId, assessmentQuestion)),
  removeAssessmentQuestion: (personId, assessmentId, assessmentQuestionId) => dispatch(actionAssessmentQuestions.removeAssessmentQuestion(personId, assessmentId, assessmentQuestionId)),
  saveAssessmentQuestionWebsiteLink: (personId, assessmentQuestionId, websiteLink) => dispatch(actionAssessmentQuestions.saveAssessmentQuestionWebsiteLink(personId, assessmentQuestionId, websiteLink)),
  removeAssessmentQuestionWebsiteLink: (personId, assessmentQuestionId, websiteLink) => dispatch(actionAssessmentQuestions.removeAssessmentQuestionWebsiteLink(personId, assessmentQuestionId, websiteLink)),
  removeAssessmentQuestionFileUpload: (personId, assessmentQuestionId, fileUploadId) => dispatch(actionAssessmentQuestions.removeAssessmentQuestionFileUpload(personId, assessmentQuestionId, fileUploadId)),
  removeAssessmentQuestionAnswerOption: (personId, assessmentQuestionId, answerIndex, runFunction) => dispatch(actionAssessmentQuestions.removeAssessmentQuestionAnswerOption(personId, assessmentQuestionId, answerIndex, runFunction)),
  removeAssessmentQuestionQuestionFile: (personId, assessmentQuestionId, fileUploadId) => dispatch(actionAssessmentQuestions.removeAssessmentQuestionQuestionFile(personId, assessmentQuestionId, fileUploadId)),
  removeAssessmentQuestionAnswerFile: (personId, assessmentQuestionId, fileUploadId) => dispatch(actionAssessmentQuestions.removeAssessmentQuestionAnswerFile(personId, assessmentQuestionId, fileUploadId)),
  removeAssessmentQuestionToMatchFile: (personId, assessmentQuestionId, fileUploadId) => dispatch(actionAssessmentQuestions.removeAssessmentQuestionToMatchFile(personId, assessmentQuestionId, fileUploadId)),
  removeAssessmentQuestionSolutionFile: (personId, assessmentQuestionId, fileUploadId) => dispatch(actionAssessmentQuestions.removeAssessmentQuestionSolutionFile(personId, assessmentQuestionId, fileUploadId)),

  removeAssessmentQuestionQuestionRecording: (personId, assessmentQuestionId, recordingFileUploadId) => dispatch(actionAssessmentQuestions.removeAssessmentQuestionQuestionRecording(personId, assessmentQuestionId, recordingFileUploadId)),
  removeAssessmentQuestionAnswerRecording: (personId, assessmentQuestionId, recordingFileUploadId) => dispatch(actionAssessmentQuestions.removeAssessmentQuestionAnswerRecording(personId, assessmentQuestionId, recordingFileUploadId)),
  removeAssessmentQuestionSolutionRecording: (personId, assessmentQuestionId, recordingFileUploadId) => dispatch(actionAssessmentQuestions.removeAssessmentQuestionSolutionRecording(personId, assessmentQuestionId, recordingFileUploadId)),

  reorderAssessmentQuestions: (personId, assessmentQuestionId, newSequence) => dispatch(actionAssessmentQuestions.reorderAssessmentQuestions(personId, assessmentQuestionId, newSequence)),
  togglePublishedAssessment: (personId, assessmentId) => dispatch(actionAssessment.togglePublishedAssessment(personId, assessmentId)),
  addVoiceRecording: (personId, assessmentQuestionId, blobThing) => dispatch(actionVoiceRecording.addVoiceRecording(personId, assessmentQuestionId, blobThing)),
  getVoiceRecording: (personId, assessmentQuestionId) => dispatch(actionVoiceRecording.getVoiceRecording(personId, assessmentQuestionId)),
  updateAssessmentTotalPoints: (personId, assessmentQuestionId, subTotalPoints) => dispatch(actionAssessment.updateAssessmentTotalPoints(personId, assessmentQuestionId, subTotalPoints)),
  getQuestionTypes: (personId) => dispatch(actionQuestionTypes.init(personId)),
  updateAssessmentSettings: (personId, assessmentId, field, value) => dispatch(actionAssessment.updateAssessmentSettings(personId, assessmentId, field, value)),
  getListLevelGeneral: () => dispatch(actionWorks.getListLevelGeneral()),
})

const storeConnector = connect(
  mapStateToProps,
  bindActionsToDispatch,
)

function Container(props) {
  const {
    assessmentQuestionsInit,
    assignmentId,
    getAssessment,
    getListLevelGeneral,
    getQuestionTypes,
    personId,
  } = props

  const params = useParams()

  useEffect(() => {
    if (personId, params.assessmentId) {
      assessmentQuestionsInit(personId, personId, params.assessmentId, assignmentId)
      getAssessment(personId, params.assessmentId)
      getQuestionTypes(personId)
      getListLevelGeneral()
    }
  }, [personId, params.assessmentId, assignmentId])

  return <AssessmentQuestionsView {...props} assessmentId={params.assessmentId} />
}

export default storeConnector(Container)
