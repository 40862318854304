import React from 'react'
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';
import styles from './VideoYouTubeView.module.css'

function VideoYouTubeView(props) {
  const params = useParams()
  const isMobile = useMediaQuery({ query: '(max-width: 840px)' })

  const videoSrc = {
    'MultipleEditors': 'https://www.youtube.com/embed/TmqXubz72VI?si=6txcLEiaAEYHKx30',
    'EditControls': 'https://www.youtube.com/embed/jwYd6XKDrU8?si=KIZg_kcwtt7KiyB8',
    'EditReport': 'https://www.youtube.com/embed/K8UMQNi04IY?si=P2SclS7aacO0EsRg',
    'MoveSentences': 'https://www.youtube.com/embed/rai3_7dQEKY?si=XLtSWhqCR80ytkzx',
    'AIvsCreativeWriting': 'https://www.youtube.com/embed/oS2lbNVwnFw?si=TgGbidtJ2OMYPrEZ',
    'OutlineOrderedList': 'https://www.youtube.com/embed/PYkuSg0NkQ4?si=03bE1cqD_QhP5ccO',
    'OutlineEdits': 'https://www.youtube.com/embed/BZ6A2wwgpGc?si=kkPg7SKd-70MMf9b',
    'LanguageVersions': 'https://www.youtube.com/embed/1xkFsZ-TZoo?si=Ba-IOjTuirHvfJcd',
  }

  return (
    <div className={styles.centered}>
      <iframe style={{
        width: 'auto',  // Makes the iframe responsive by occupying 100% of its container's width
        height: '100vh',  // Adjust height automatically
        aspectRatio: '16 / 9',  // Maintains a 16:9 aspect ratio
        maxWidth: '2000px',
      }}
        src={videoSrc[params && params.videoName]} title="YouTube video player"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen></iframe>

    </div>
  )
}

export default VideoYouTubeView;
