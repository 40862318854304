import React, {useEffect} from 'react';
import styles from './TabPage.module.css';
import classes from 'classnames';
import DropDownFloatCustom from '../DropDownFloatCustom'
import ButtonDropDown from '../ButtonDropDown'
import EditListChoice from '../EditListChoice'
import {useMediaQuery} from "react-responsive";

function TabPage(props) {
	//If this is a new document, then include the Save button for the author, but if the author leaves the page with or without
	//  saving, then save the chapterText and process it for hrefId-s anyway.
	const {
		chosenTab,
		className,
		keyIndex,
		navClose,
		navText,
		onClick,
		showListAfterQuantity,
		showZeroCount,
		tabsData,
    viewSide,
	} = props;

  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  const getOptions = () => {
    let newOptions = []
    tabsData.filter(m => isMobile || (!isMobile && m.id !== tabsData[0].id)).forEach((m, i) => { //When it is mobile, we want all editors and the author to be in the drop down list. Otherwize cut out the first Tab person out for the tabView tabs
      if (isMobile || i >= showListAfterQuantity-1) {
        let option = {
          id: m.id,
          label: m.label + ' (' + m.count + ')'
        }
        newOptions.push(option)
      }
    })
    return newOptions
  }

	const getChoiceLabel = () => {
		if (chosenTab && chosenTab !== '0') {
			const options = getOptions(tabsData)
			let choiceOption = options.filter(m => m.id === chosenTab)[0]
			return choiceOption ? choiceOption.label.length > 13 ? choiceOption.label.substring(0, 13) + '...' : choiceOption.label : ''
		}
	}



	//If the showListAfterQuantity is greater than the tabsData count plus 1, then show the author in a tab and the rest in a single select list.
	return (
		<div className={styles.container} key={keyIndex}>
			{tabsData && !isMobile && tabsData.length > 0 &&
				<div className={classes(styles.tabs, styles.row, className)}>
					{tabsData.map((tab, i) => {
            if (showListAfterQuantity > i) {
              return (
                <div key={i} className={styles.columns}>
                  {tab.isAuthor ? <span className={styles.aboveTab}>AUTHOR</span> :
                    <span className={styles.aboveTab}>&nbsp;</span>}
                  <a onClick={() => onClick(tab.id, viewSide)}
                     className={classes(styles.row, styles.tabLink, (tab.id === chosenTab ? styles.chosen : styles.notChosen)) //eslint-disable-line
                     }>
                    {tab.editorColor && <div className={styles.colorBox} style={{backgroundColor: tab.editorColor}}/>}
                    {tab.label}
                    {(showZeroCount || tab.count) &&
                      <span className={styles.editCount}>
                                    {tab.count
                                      ? tab.count
                                        ? tab.count
                                        : (showZeroCount ? 0 : '')
                                      : showZeroCount
                                        ? 0
                                        : ''
                                    }
                                    </span>
								    }
                  </a>
                </div>
					  )}}
          )}
					{navClose && <span className={styles.closeText} onClick={navClose}>{navText}</span>}
				</div>
			}
			{tabsData && (isMobile || (showListAfterQuantity && showListAfterQuantity < tabsData.length-1)) &&
				<div className={styles.container} title={'Author and editors'}>
					<DropDownFloatCustom key={'Writers'}
						iconAvatar={<ButtonDropDown label={'Writers'} />}
						header={<div>Author and editors</div>}
						onSelect={onClick}
						listOptions={getOptions(tabsData)}
						showChoiceLabel
						choiceLabel={getChoiceLabel()} 
						labelLeftpx={'-14px'}/>
				</div>
				// <div className={classes(isMobile ? '' : styles.authorTabAndList, className)}>
				// 	<div>
				// 		<SelectSingleDropDown
				// 			value={chosenTab}
				// 			options={getOptions(tabsData)}
				// 			noBlank
				// 			includeDownArrow
				// 			height={`medium`}
				// 			className={styles.narrowList}
				// 			onChange={(event) => onClick(event.target.value)}/>
				// 	</div>
				// 	{navClose && <span className={styles.closeText} onClick={navClose}>{navText}</span>}
				// </div>
			}
			{props.setEditChosen &&
				<div className={styles.editList}>
					<EditListChoice editOptions={props.editOptions} setEditChosen={props.setEditChosen}
					                editChosen={props.editChosen}/>
				</div>
			}
		</div>
	)
}

export default TabPage;