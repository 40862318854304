let g_strHTMLResult = ""
let g_strRemaining = ""
let g_boolIsAcronym = false
let strIsNewOrExistingPSTag
let boolReadyToEndPS
let arrSpanAccumulator = []
let g_strCurrentSentenceHrefId
let g_boolIsFileCompare
let g_lastNewId
let g_previousElementId

export const delineateSentences = (previousElementId, htmlContents, getNextId, spanStyleInline) => {
	g_strRemaining = htmlContents
	g_strHTMLResult = ""
	g_previousElementId = previousElementId

	let boolToLowerCase = true
	let numLoop = 0

	let spanStyle = spanStyleInline ? `style="${spanStyleInline}"` : ''

	//If the text coming in new does not have a span tag, start it and set strIsNewOrExistingPSTag to "NEW"
	if (getNextChars(10).toLowerCase().indexOf('<span') === -1) {
		g_lastNewId = getNextId()
		addText(`&nbsp;<span id="${g_lastNewId}" ${spanStyle}>`)
		strIsNewOrExistingPSTag = "NEW"
		//boolReadyToEndPS = true
	}

	while (g_strRemaining.length > 0)
	{
		//If the next character is "<"
		if (getNextChars(1) === " " && g_boolIsAcronym)
		{
			g_boolIsAcronym = false;
		}

		if (getNextChars(1) === " " && strIsNewOrExistingPSTag === "")
		{
			addResultCharacter(1);
		}
		else if (getNextChars(6) === "&nbsp;" && strIsNewOrExistingPSTag === "")
		{
			addResultCharacter(6);
		}
		else if (getNextChars(1) === "<")
		{
			g_boolIsAcronym = false;

			//If there is a font tag, eradicate it!  it is deprecated in HTML 5 and this is most likely coming from a paste or upload from another file.
			if (getNextChars(5, boolToLowerCase) === "<font" || getNextChars(6, boolToLowerCase) === "</font")
			{
				let fontTag = getNextCharsInsideTag(500);
				if (fontTag.indexOf(">") > -1)
				{
					fontTag = fontTag.substring(0, fontTag.indexOf(">") + 1);
					doNotIncludeResultText(fontTag);
				}
			}
			else if (getNextChars(2, boolToLowerCase) === "<a")
			{
				includeToResultText(">");

			}
			else if (getNextChars(4, boolToLowerCase) === "<img")
			{
				//if there is an image tag, take the entire tag to the end.
				//We are depending on a ">" at the end that is not confused with any matching ">" in between.
				includeToResultText(">");

			}
			else if ((getNextChars(2, boolToLowerCase) === "<p" && getNextChars(4, boolToLowerCase) !== "<path")
				|| getNextChars(4, boolToLowerCase) === "<div"
				|| getNextChars(4, boolToLowerCase) === "<section")
			{
				if (strIsNewOrExistingPSTag !== "")
				{
					addText("</span>");
				}

				//		if it does not have a PSid
				if (getPSidIndex(getNextCharsInsideTag(100)) === -1)
				{
					//			give it a PSid
					if (getNextChars(2, boolToLowerCase) === "<p")
					{
						addResultCharacter(2);
					}
					else if (getNextChars(4, boolToLowerCase) === "<div")
					{
						addResultCharacter(4);
					}
					else if (getNextChars(8, boolToLowerCase) === "<section")
					{
						addResultCharacter(8);
					}

					g_lastNewId = getNextId()
					addText(" id='" + g_lastNewId + "'");

				}
				//		skip to the end of that beginning tag
				includeToResultText(">");
				strIsNewOrExistingPSTag = "";
				boolReadyToEndPS = false;

				//	else if it is a SPAN PS-tag (with a PSid)
			}
			else if (getNextChars(5, boolToLowerCase) === "<span") {
				if (getPSidIndex(getNextCharsInsideTag(100)) > -1) {
					//I've had trouble with this area.  Recently I changed the code to the commented-out section far below.  Then I
					//  restored the logic back to the pseudocode found at the beginning of this function above which I have copied
					//  here:
					//if strIsNewOrExistingPSTag is set to "NEW" or "EXISTING" //Because if it still says that we are in a PS-tag, then there is an END span tag missing.
					//    Enter as many End span tags as there are arrSpanAccumulator elements(although at this point there should have been span tags to match up to the BEGIN span tags but they could be missing here.)
					//    Enter an End PS-tag
					//end if

					//Set strIsNewOrExistingPSTag to "EXISTING"
					//Set boolReadyToEndPS to false
					//skip to the end of that beginning tag
					//(We are assuming that this existing PS-tag should have the corresponding span - format tags already, if there are any previously existing.)

					//		if strIsNewOrExistingPSTag is set to "NEW" or "EXISTING" //Because if it still says that we are in a PS-tag, then there is an END span tag missing.
					if (strIsNewOrExistingPSTag === "NEW" || strIsNewOrExistingPSTag === "EXISTING") {
						//			Enter as many End span tags as there are arrSpanAccumulator elements (although at this point there should have been span tags to match up to the BEGIN span tags but they could be missing here.)
						for (let index = 0; index < arrSpanAccumulator.length; index++) {
							addText("</span>");
						}
						//			Enter an End PS-tag
						addText("</span>");
						strIsNewOrExistingPSTag = "EXISTING";
					}

					strIsNewOrExistingPSTag = "EXISTING";
				} else {
					addResultCharacter(5); //This is the 5 characters of the <span characters.
					g_lastNewId = getNextId()
					addText(" id='" + g_lastNewId + "'");
					g_previousElementId = g_lastNewId
					strIsNewOrExistingPSTag = "NEW";
				}

				boolReadyToEndPS = false;

				//		skip to the end of that beginning tag
				includeToResultText(">");

			}
			else if (getNextChars(3, boolToLowerCase) === "<br")
			{
				g_lastNewId = getNextId()
				addText("</p><p id='" + g_lastNewId + "'>");
				g_previousElementId = g_lastNewId

				g_lastNewId = getNextId()
				addText(`<span id='${g_lastNewId}' ${spanStyle}>`);
				g_previousElementId = g_lastNewId

				strIsNewOrExistingPSTag = "NEW";
				boolReadyToEndPS = false;
				arrSpanAccumulator = [];

				//skip past to the end of the tag
				doNotIncludeResultText(">");

			}
			else if (getNextChars(3, boolToLowerCase) === "</p" || getNextChars(5, boolToLowerCase) === "</div")
			{

				//		if strIsNewOrExistingPSTag is set to "NEW" or "EXISTING" //I suppose that it is possible that the end span tag was not created for an Existing PS-tag
				if (strIsNewOrExistingPSTag === "NEW" || strIsNewOrExistingPSTag === "EXISTING")
				{
					//			Enter as many End span tags as there are arrSpanAccumulator elements (although at this point there should have been span tags to match up to the BEGIN span tags but they could be missing here.)
					for (let index = 0; index < arrSpanAccumulator.length; index++)
					{
						addText("</span>");
					}
					//			Enter an End PS-tag
					addText("</span>");
				}

				strIsNewOrExistingPSTag = "EXISTING";
				boolReadyToEndPS = false;
				arrSpanAccumulator = [];

				//		skip to the end of the tag
				includeToResultText(">");

				//	else if it is an END Span tag
			}
			else if (getNextChars(6, boolToLowerCase) === "</span")
			{
				//		if strIsNewOrExistingPSTag is set to "NEW"  //But this may be an orphan span end tag, but it is not an ending of the new PS-tag yet.
				if (strIsNewOrExistingPSTag === "NEW")
				{
					//			If arrSpanAccumulator is greater than 0
					if (arrSpanAccumulator.length > 0)
					{
						//arrSpanAccumulator.RemoveAt(arrSpanAccumulator.length - 1);
						arrSpanAccumulator.pop();
						//		skip to the end of the tag
						includeToResultText(">");

					}
					else
					{
						includeToResultText(">");
						strIsNewOrExistingPSTag = "";
					}

					//		else if strIsNewOrExistingPSTag is set to "EXISTING"
				}
				else if (strIsNewOrExistingPSTag === "EXISTING")
				{
					//			if arrSpanAccumulator is greater than 0
					if (arrSpanAccumulator.length > 0)
					{
						//arrSpanAccumulator.RemoveAt(arrSpanAccumulator.length - 1);
						arrSpanAccumulator.pop();
						//		skip to the end of the tag
						includeToResultText(">");

						//			else if arrSpanAccumulator is 0  //This is the end of the PS-tag sentence, which is this is the loose way of tracking the end of the PS-tag by the depth of the spans
					}
					else if (arrSpanAccumulator.length === 0)
					{
						includeToResultText(">");
						strIsNewOrExistingPSTag = "";
					}
				}
				else
				{
					doNotIncludeResultText(">");  //This should be clearing out any extra END span tags.
					strIsNewOrExistingPSTag = "";
				}

				//	else if this is a table-related tag (table, tr, td, tbody, /table, /tr, /td, /tbody)
			}
			else if (isATagNotToInclude())
			{

				//		if strIsNewOrExistingPSTag is set to "NEW" or "EXISTING"
				if (strIsNewOrExistingPSTag === "NEW" || strIsNewOrExistingPSTag === "EXISTING")
				{
					//			Enter as many End span tags as there are arrSpanAccumulator elements
					for (let index = 0; index < arrSpanAccumulator.length; index++)
					{
						addText("</span>");
					}
					//			Enter an End PS-tag
					addText("</span>");
				}

				strIsNewOrExistingPSTag = "";
				arrSpanAccumulator = [];

				//		skip to the end of the tag
				includeToResultText(">");


				//	else if strIsNewOrExistingPSTag is set to blank
			}
			else if (strIsNewOrExistingPSTag === "")
			{
				//		Enter a new PS-tag
				g_lastNewId = getNextId()
				addText(`<span id='${g_lastNewId}' ${spanStyle}>`);
				g_previousElementId = g_lastNewId

				//		Enter the tags found in the arrSpanAccumulator array
				for (let index = 0; index < arrSpanAccumulator.length; index++)
				{
					addText(arrSpanAccumulator[index]);
				}

				//		set strIsNewOrExistingPSTag to "NEW"
				strIsNewOrExistingPSTag = "NEW";

				//		if this is a Span tag
				if (getNextChars(5, boolToLowerCase) === "<span")
				{
					//		save the span tag in the arrSpanAccumulator (accumulating any other ones which exist for the given sentence)
					arrSpanAccumulator.push(getNextCharsInsideTag(400));
				}

				//		skip to the end of the tag
				includeToResultText(">");

				//	else
			}
			else
			{                           //		if this is a Span tag
				//			save the span tag in the arrSpanAccumulator (accumulating any other ones which exist for the given sentence)
				if (getNextChars(5, boolToLowerCase) === "<span")
				{
					//		save the span tag in the arrSpanAccumulator (accumulating any other ones which exist for the given sentence)
					arrSpanAccumulator.push(getNextCharsInsideTag(400));
				}
				//		skip to the end of the tag and include it in the current PS-tag
				includeToResultText(">");

			}


			//else if it is a sentence ending character (including control characters) and not an Abbreviation Period
		}
		else if (isSentenceEndingPunctuation(getNextId, spanStyle))
		{

			//	if strIsNewOrExistingPSTag is set to blank
			if (strIsNewOrExistingPSTag === "")
			{
				//		Enter a new PS-tag
				g_lastNewId = getNextId()
				addText(`<span id='${g_lastNewId}' ${spanStyle}>`);
				g_previousElementId = g_lastNewId

				//		Enter the tags found in the arrSpanAccumulator array
				for (let index = 0; index < arrSpanAccumulator.length; index++)
				{
					addText(arrSpanAccumulator[index]);
				}

				strIsNewOrExistingPSTag = "NEW";
			}

			addResultCharacter(); //Add the sentence-ending character before gathering the rest of the characters.

			let boolIsEndSentenceCharacter = true;

			while (boolIsEndSentenceCharacter)
			{
				if (getNextChars(2) === "\n" || getNextChars(2) === "\r" || getNextChars(2) === "\t" || getNextChars(2) === "\0")
				{
					addResultCharacter(2);

				}
				else if (getNextChars(1) === "]" || getNextChars(1) === "}" || getNextChars(1) === ")")
				{
					addResultCharacter(1);

				}
				else if (getNextChars(1) === "'" || getNextChars(1) === "\"")
				{
					addResultCharacter(1);
				}
				else if (getNextChars(1) === "”" || getNextChars(1) === "“")
				{
					addResultCharacter(1);

				}
				else if (getNextChars(6) === "&quot;")
				{
					addResultCharacter(6);

				}
				else if (getNextChars(7) === "&lsquo;")
				{
					addResultCharacter(7);

				}
				else if (getNextChars(7) === "&rsquo;")
				{
					addResultCharacter(7);

				}
				else if (getNextChars(7) === "&sbquo;")
				{
					addResultCharacter(7);

				}
				else if (getNextChars(7) === "&ldquo;")
				{
					addResultCharacter(7);

				}
				else if (getNextChars(7) === "&rdquo;")
				{
					addResultCharacter(7);

				}
				else if (getNextChars(7) === "&bdquo;")
				{
					addResultCharacter(7);

				}
				else if (getNextChars(8) === "&lsaquo;")
				{
					addResultCharacter(8);

				}
				else if (getNextChars(8) === "&rsaquo;")
				{
					addResultCharacter(8);

				}
				else if (getNextChars(7) === "&#8221;") {
					addResultCharacter(7);
				}
				else if (getNextChars(1) === " ")   //Skip the next blank spaces of the four if conditions here.
				{
					//skip the single space at the end of the sentence.
					addResultCharacter(1);
					boolIsEndSentenceCharacter = false;
				}
				else if (getNextChars(2) === "  ")
				{
					//skip the double space at the end of the sentence.
					addResultCharacter(2)
					boolIsEndSentenceCharacter = false;
				}
				else if (getNextChars(6) === "&nbsp;")
				{
					//skip the non-breaking space at the end of the sentence.
					addResultCharacter(6)
					boolIsEndSentenceCharacter = false;
				}
				else if (getNextChars(7) === " &nbsp;")
				{
					//skip the non-breaking space at the end of the sentence.
					addResultCharacter(7)
					boolIsEndSentenceCharacter = false;
				}
				else if (getNextChars(7) === "&nbsp; ")
				{
					//skip the non-breaking space at the end of the sentence.
					addResultCharacter(7)
					boolIsEndSentenceCharacter = false;
				}
				else if (getNextChars(6, boolToLowerCase) === "</span")
				{
					//		If arrSpanAccumulator is greater than 0
					if (arrSpanAccumulator.length > 0)
					{
						//arrSpanAccumulator.RemoveAt(arrSpanAccumulator.length - 1);
						arrSpanAccumulator.pop();
						includeToResultText(">");

						//		else if strIsNewOrExistingPSTag is set to "EXISTING"
					}
					else if (arrSpanAccumulator.length === 0)
					{
						if (strIsNewOrExistingPSTag === "EXISTING" || strIsNewOrExistingPSTag === "NEW")
						{
							includeToResultText(">");
							strIsNewOrExistingPSTag = "";
						}
						else
						{
							doNotIncludeResultText(">");
						}
					}
				}
				else if (isSentenceEndingPunctuation(getNextId, spanStyle))
				{
					addResultCharacter(1);
				}
				else if (isSentenceTypeEndTag())
				{
					includeToResultText(">");
				}
				else
				{
					boolIsEndSentenceCharacter = false;
				}
			}

			//		Enter as many End span tags as there are arrSpanAccumulator elements
			for (let index = 0; index < arrSpanAccumulator.length; index++)
			{
				addText("</span>");
			}

			//	if strIsNewOrExistingPSTag is set to "NEW" or "EXISTING"
			//  NOTE (OCT 9, 2017):  I FOUND THAT IF THERE ARE NEW SENTENCES IN AN EXISTING HREFID THAT THE FIRST SENTENCE WILL BE MISSING AN END TAG ENTIRELY WHICH CAUSES THE ENTIRE DOCUMENT OR PARAGRPAH AT LEAST TO BE CHOSEN IN THE EDITOR
			//      SO UNDER THIS CIRCUMSTANCES, WE ARE GOING TO IGNORE IF IT IS AN EXISTING OR NEW SENTENCE, WE WILL PUT AN END TAG (WITH THE RISK OF ORPHAN END TAGS)
			//if (strIsNewOrExistingPSTag === "NEW")
			//{
			//Take off an extra space at the end of the sentence, if there is one.
			if (g_strHTMLResult.lastIndexOf(' ') === g_strHTMLResult.length-1) {
				g_strHTMLResult = g_strHTMLResult.substring(0, g_strHTMLResult.length-1)
			}
			if (g_strHTMLResult.lastIndexOf('&nbsp;') === g_strHTMLResult.length-6) {
				g_strHTMLResult = g_strHTMLResult.substring(0, g_strHTMLResult.length-6)
			}

			//		Enter an End PS-tag
			addText("</span>&nbsp;"); //We work at keeping the extra space out of the end of the sentence and then adding one that won't be included in the database segment
			//}
			strIsNewOrExistingPSTag = "";

			//else if strIsNewOrExistingPSTag is set to "NEW" or "EXISTING"
		}
		else if (strIsNewOrExistingPSTag === "NEW" || strIsNewOrExistingPSTag === "EXISTING")
		{
			//	just include the character ( if it is "&nbsp;", take all of the characters)
			if (getNextChars(6) === "&nbsp;")
			{
				addResultCharacter(6);
			}
			else
			{
				addResultCharacter(1);
			}

			// two conditions below are used specifically to allow no-man's-land after a paragraph so that the space that
			//   Word oftens gives between blocks of paragraphs has some extra space in them.  If we put one of our HrefId spans
			//   to pick up the line breaks or extra space, then the paragraph spacing is exagerated.  So, allow these options when
			//   an existing sentence is not identified.
		}
		else if (getNextChars(6) === "&nbsp;")
		{
			addResultCharacter(6);
		}
		else if (getNextChars(1) === "\\r" || getNextChars(1) === "\\n" || getNextChars(1) === "\r" || getNextChars(1) === "\n" || getNextChars(1) === " ")
		{
			addResultCharacter(1);
		}
		else
		{
			//		//strIsNewOrExistingPSTag must be set to ""
			//	Enter a new PS-tag
			g_lastNewId = getNextId()
			addText(`<span id='${g_lastNewId}' ${spanStyle}>`);
			g_previousElementId = g_lastNewId

			//	Enter the span tags found in the arrSpanAccumulator array
			for (let index = 0; index < arrSpanAccumulator.length; index++)
			{
				addText(arrSpanAccumulator[index]);
			}

			strIsNewOrExistingPSTag = "NEW";

			//	include the character (if it is "&nbsp;", take all of the characters)
			if (getNextChars(6) === "&nbsp;")
			{
				addResultCharacter(6);
			}
			else
			{
				addResultCharacter(1);
			}
		}

		numLoop++;

	}   //End of looping

	g_strHTMLResult += g_strRemaining;

	//Is the ending </span> missing?
	let ending = g_strHTMLResult.substring(g_strHTMLResult.length - 15)
	if (ending.toLowerCase().indexOf('</span') === -1) {
		g_strHTMLResult = g_strHTMLResult + '</span>&nbsp;'
	}

	return {
		newOuterHtml: g_strHTMLResult,
		lastNewId: g_lastNewId
	};
}

const getNextChars = (numChars, boolLowerCase = false) => {
	if (g_strRemaining.length === 0)
	{
		return "";
	}
	if (numChars > g_strRemaining.length)
	{
		numChars = g_strRemaining.length;
	}

	let strText = g_strRemaining.substring(0, numChars);
	if (boolLowerCase)
	{
		strText = strText.toLowerCase();
	}
	return strText;
}

const addResultCharacter = (numCount = 1) => {
	g_strHTMLResult = g_strHTMLResult + g_strRemaining.substring(0, numCount);
	g_strRemaining = g_strRemaining.substring(numCount);
}

const doNotIncludeResultText = (strText) => {
	let numPlusLength = strText.length;
	g_strRemaining = g_strRemaining.substring(g_strRemaining.indexOf(strText) + numPlusLength);
}

const getNextCharsInsideTag = (numChars, boolLowerCase = false) =>{
	if (numChars > g_strRemaining.length)
	{
		numChars = g_strRemaining.length;
	}
	let strText = g_strRemaining.substring(0, numChars);
	if (strText.indexOf(">") > -1)
	{
		strText = strText.substring(0, strText.indexOf(">") + 1);
	}
	if (boolLowerCase)
	{
		strText = strText.toLowerCase();
	}
	return strText;
}

const addText = (strText) => {
	g_strHTMLResult = g_strHTMLResult + strText;
}

const includeToResultText = (strText, boolDoNotIncludeSkippedToText = false) => {
	let numPlusIncludeLength = 0;
	if (boolDoNotIncludeSkippedToText !== true)
	{
		numPlusIncludeLength = strText.length;
	}
	g_strHTMLResult = g_strHTMLResult + g_strRemaining.substring(0, g_strRemaining.indexOf(strText) + numPlusIncludeLength);
	g_strRemaining = g_strRemaining.substring(g_strRemaining.indexOf(strText) + numPlusIncludeLength);
}

const getPSidIndex = (strSubContents, strId = "") => {
	let numReturnIndex = -1;
	if (strSubContents.indexOf("id=\"" + strId) > -1)
	{
		numReturnIndex = strSubContents.indexOf("id=\"" + strId);
	}
	else if (strSubContents.indexOf("id='" + strId) > -1)
	{
		numReturnIndex = strSubContents.indexOf("id='" + strId);
	}
	else if (strSubContents.indexOf("id=" + strId) > -1)
	{
		numReturnIndex = strSubContents.indexOf("id=" + strId + " ");  //Be sure to include the space so that a first part of an ID that might match partially doesn't get picked up.
	}
	return numReturnIndex;
}

const isATagNotToInclude = () => {
	let nextText = getNextChars(12, true)
	if (nextText.indexOf("<table") === 0 || nextText.indexOf("</table") === 0
		|| nextText.indexOf("<tr") === 0 || nextText.indexOf("</tr") === 0
		|| nextText.indexOf("<td") === 0 || nextText.indexOf("</td") === 0
		|| nextText.indexOf("<tbody") === 0 || nextText.indexOf("</tbody") === 0
		|| nextText.indexOf("<address") === 0 || nextText.indexOf("</address") === 0
		|| nextText.indexOf("<applet") === 0 || nextText.indexOf("</applet") === 0
		|| nextText.indexOf("<area") === 0 || nextText.indexOf("</area") === 0
		|| nextText.indexOf("<article") === 0 || nextText.indexOf("</article") === 0
		|| nextText.indexOf("<aside") === 0 || nextText.indexOf("</aside") === 0
		|| nextText.indexOf("<audio") === 0 || nextText.indexOf("</audio") === 0
		|| nextText.indexOf("<base") === 0 || nextText.indexOf("</base") === 0
		|| nextText.indexOf("<basefont") === 0 || nextText.indexOf("</basefont") === 0
		|| nextText.indexOf("<bdi") === 0 || nextText.indexOf("</bdi") === 0
		|| nextText.indexOf("<bdo") === 0 || nextText.indexOf("</bdo") === 0
		|| nextText.indexOf("<big") === 0 || nextText.indexOf("</big") === 0
		|| nextText.indexOf("<blockquote") === 0 || nextText.indexOf("</blockquote") === 0
		|| nextText.indexOf("<body") === 0 || nextText.indexOf("</body") === 0
		|| nextText.indexOf("<canvas") === 0 || nextText.indexOf("</canvas") === 0
		|| nextText.indexOf("<center") === 0 || nextText.indexOf("</center") === 0
		|| nextText.indexOf("<cite") === 0 || nextText.indexOf("</cite") === 0
		|| nextText.indexOf("<code") === 0 || nextText.indexOf("</code") === 0
		|| nextText.indexOf("<col") === 0 || nextText.indexOf("</col") === 0
		|| nextText.indexOf("<colgroup") === 0 || nextText.indexOf("</colgroup") === 0
		|| nextText.indexOf("<command") === 0 || nextText.indexOf("</command") === 0
		|| nextText.indexOf("<datalist") === 0 || nextText.indexOf("</datalist") === 0
		|| nextText.indexOf("<dd") === 0 || nextText.indexOf("</dd") === 0
		|| nextText.indexOf("<details") === 0 || nextText.indexOf("</details") === 0
		|| nextText.indexOf("<dfn") === 0 || nextText.indexOf("</dfn") === 0
		|| nextText.indexOf("<dir") === 0 || nextText.indexOf("</dir") === 0
		|| nextText.indexOf("<dl") === 0 || nextText.indexOf("</dl") === 0
		|| nextText.indexOf("<dt") === 0 || nextText.indexOf("</dt") === 0
		|| nextText.indexOf("<em") === 0 || nextText.indexOf("</em") === 0
		|| nextText.indexOf("<embed") === 0 || nextText.indexOf("</embed") === 0
		|| nextText.indexOf("<fieldset") === 0 || nextText.indexOf("</fieldset") === 0
		|| nextText.indexOf("<figcaption") === 0 || nextText.indexOf("</figcaption") === 0
		|| nextText.indexOf("<figure") === 0 || nextText.indexOf("</figure") === 0
		|| nextText.indexOf("<footer") === 0 || nextText.indexOf("</footer") === 0
		|| nextText.indexOf("<form") === 0 || nextText.indexOf("</form") === 0
		|| nextText.indexOf("<frame") === 0 || nextText.indexOf("</frame") === 0
		|| nextText.indexOf("<frameset") === 0 || nextText.indexOf("</frameset") === 0
		|| nextText.indexOf("<h1") === 0 || nextText.indexOf("</h1") === 0
		|| nextText.indexOf("<h2") === 0 || nextText.indexOf("</h2") === 0
		|| nextText.indexOf("<h3") === 0 || nextText.indexOf("</h3") === 0
		|| nextText.indexOf("<h4") === 0 || nextText.indexOf("</h4") === 0
		|| nextText.indexOf("<h5") === 0 || nextText.indexOf("</h5") === 0
		|| nextText.indexOf("<h6") === 0 || nextText.indexOf("</h6") === 0
		|| nextText.indexOf("<head") === 0 || nextText.indexOf("</head") === 0
		|| nextText.indexOf("<header") === 0 || nextText.indexOf("</header") === 0
		|| nextText.indexOf("<hgroup") === 0 || nextText.indexOf("</hgroup") === 0
		|| nextText.indexOf("<html") === 0 || nextText.indexOf("</html") === 0
		|| nextText.indexOf("<iframe") === 0 || nextText.indexOf("</iframe") === 0
		//The iframe causes a lot of trouble since it translates tags inside with character entities gt lt and then those
		//  are processed as text so that hrefId-s go on and on.  But I'm doubting that iframes are going to be included
		//  in text to be edited.
		|| nextText.indexOf("<ins") === 0 || nextText.indexOf("</ins") === 0
		|| nextText.indexOf("<kbd") === 0 || nextText.indexOf("</kbd") === 0
		|| nextText.indexOf("<legend") === 0 || nextText.indexOf("</legend") === 0
		|| nextText.indexOf("<li") === 0 || nextText.indexOf("</li") === 0
		|| nextText.indexOf("<main") === 0 || nextText.indexOf("</main") === 0
		|| nextText.indexOf("<map") === 0 || nextText.indexOf("</map") === 0
		|| nextText.indexOf("<menu") === 0 || nextText.indexOf("</menu") === 0
		|| nextText.indexOf("<menuitem") === 0 || nextText.indexOf("</menuitem") === 0
		|| nextText.indexOf("<object") === 0 || nextText.indexOf("</object") === 0
		|| nextText.indexOf("<ol") === 0 || nextText.indexOf("</ol") === 0
		|| nextText.indexOf("<output") === 0 || nextText.indexOf("</output") === 0
		|| nextText.indexOf("<picture") === 0 || nextText.indexOf("</picture") === 0
		|| nextText.indexOf("<section") === 0 || nextText.indexOf("</section") === 0
		|| nextText.indexOf("<summary") === 0 || nextText.indexOf("</summary") === 0
		|| nextText.indexOf("<tfoot") === 0 || nextText.indexOf("</tfoot") === 0
		|| nextText.indexOf("<th") === 0 || nextText.indexOf("</th") === 0
		|| nextText.indexOf("<thead") === 0 || nextText.indexOf("</thead") === 0
		|| nextText.indexOf("<track") === 0 || nextText.indexOf("</track") === 0
		|| nextText.indexOf("<ul") === 0 || nextText.indexOf("</ul") === 0)
	{
		return true;
	}
	else
	{
		return false;
	}
}

export const isSentenceEndingPunctuation = (getNextId, spanStyle) => {
	//If a valid letter follows a period, then don't let the sentence end.  It could be a URL or other code-like structure that is still in a running sentence.
	//If it is a period that is part of an initial of a name, don't end. (Space two character before, a capital letter, a period, and then another space afterwards)
	let strPrevChar
	let strPrevSecondChar
	let strSecondPrevIsNonBreakSpace

	if (g_strHTMLResult.length > 2) {
		let getChar = g_strHTMLResult.substring(g_strHTMLResult.length - 1);
		strPrevChar = getChar.length > 0 ? getChar[0] : '';
		strPrevSecondChar = g_strHTMLResult.substring(g_strHTMLResult.length - 2).substring(0, 1);
	}
	if (g_strHTMLResult.length > 8) {
		strSecondPrevIsNonBreakSpace = g_strHTMLResult.substring(g_strHTMLResult.length - 8).substring(0, 5);
	}

	let strCurrentChar = getNextChars(1);
	let strSecondChar = getNextChars(2);
	if (strSecondChar.length > 1) {
		strSecondChar = getNextChars(2).substring(1);
	}
	let strNextIsNonBreakSpace = getNextChars(7);
	if (strNextIsNonBreakSpace.length > 1) {
		strNextIsNonBreakSpace = getNextChars(7).substring(1);
	}

	let strThirdChar = getNextChars(3);
	if (strThirdChar.length > 2) {
		strThirdChar = getNextChars(3).substring(2);
	}

	let strBreakSearch = getNextChars(7);
	if (strBreakSearch.length > 0) {
		strBreakSearch = strBreakSearch.substring(1); //Cut off the first character so we can see if there is any variation of a break after it.
	}

	let strNonBreakSpaceSearch = getNextChars(7);
	if (strNonBreakSpaceSearch.length > 0) {
		strNonBreakSpaceSearch = strNonBreakSpaceSearch.substring(1); //Cut off the first character so we can see if "&nbsp;" is after it.
	}

	if (strSecondChar.length === 0) {
		return false;
	}

	let strIsCommentTag = getNextChars(5);
	if (strIsCommentTag.length > 0) {
		strIsCommentTag = strIsCommentTag.substring(1); //Cut off the first character so we can see if there is a comment after it.
	}

	if (g_boolIsAcronym) {
		return false;

	} else if (strCurrentChar === "." && strSecondChar !== " " && strSecondChar !== "." && strThirdChar === ".") {
		g_boolIsAcronym = true; //This will be cleared when a space is encountered.  this may mean that two sentences may run together if this acronym
	                          //is at the end of a sentence.  But that is better than maing many hrefs out of an acronym in the middle of a sentence.
		return false;

		//If a valid letter follows a period, then don't let the sentence end.  It could be a URL or other code-like structure that is still in a running sentence.
	} else if (strCurrentChar === "." && strSecondChar !== " " && strSecondChar !== "\u00a0" && strSecondChar !== "\xA0" && strNextIsNonBreakSpace !== "&nbsp;" && strNextIsNonBreakSpace !== " " && !isQuoteOrBracketOrParenthesisAfter()) {
		return false;

		//If it is a period that is part of an initial of a name, don't end. (Space two character before, a capital letter, a period, and then another space afterwards)
	} else if (strCurrentChar === "." && (strSecondChar === " " || strNextIsNonBreakSpace === "&nbsp;")
		&& containsUppercase(strPrevChar) && (strPrevSecondChar === " " || strSecondPrevIsNonBreakSpace === "&nbsp;")) {

		return false;

	// } else if (strNonBreakSpaceSearch === "&nbsp;") {  I'm not so sure what this is for. Besides it was changed up above where it cut out the first character as it was looking for this non-breaking-space after a punctuation (or anything for that matter.
	// 	return true;

	} else if ((strCurrentChar === "." && isNotAbbreviationPeriod(getNextId, spanStyle) && IsNotDecimalNumber()) || strCurrentChar === "!" || strCurrentChar === "?" || strIsCommentTag === "<!--"
		|| strBreakSearch.indexOf("<BR>") > -1 || strBreakSearch.indexOf("<br>") > -1 || strBreakSearch.indexOf("</br>") > -1 || strBreakSearch.indexOf("</BR>") > -1 || strBreakSearch.indexOf("</ BR>") > -1 || strBreakSearch.indexOf("</ br>") > -1) {
		return true;
	} else {
		return false;
	}
}

const containsUppercase = (str) => {
	return /[A-Z]/.test(str);
}

const isNotAbbreviationPeriod = (getNextId, spanStyle) => {
	//There are quite a few valid abbreviation forms that are going to involve a period, which would otherwise be considered sentence-ending punctuation.
	//Most abbreviations we are looking for are before the period, such as "dept.".  Some are before and after the period, such as "m.p.h."
	//	so we will jump ahead and take the rest of the "m.p.h." but leave the last character to be picked up in the logic to follow.  But we will
	//	certainly not end the sentence on it.  The worst case that this will cause if we really did pass up the end of the sentence is that the
	//	editor will pick up two sentences in their single selection so that their work may include the change against the two sentences.  That's not so bad.
	//The abbreviation table looks for the various lengths of abbreviations before the period and the length after the period.

	//Help - To do:  finish this.

	//if the period matches the pattern of any of the table entries in WordAbbreviation, then consider it a non-abbreviation period and return false.
	//1.  Make an array out of the various PreLength-s (1,2,3,4,5) in order to compare to the WordAbbreviation entries.
	//2.  Create a second array for the PostLengths (0,1,2,3) even though 2 doesn't exist in the tables now.  But it can remain blank and not used.
	//3.  Then loop through the table to compare what we have.  If there is a match, return true.  If there is not a match in the end, return false.

	let strWord = ""
	let strPreWord = ""
	let strPostWord = ""
	let arrPreLength = []
	let arrPostLength = []

	let subAmount = g_strHTMLResult.length - 5;
	if (subAmount < 0)
	{
		subAmount = g_strHTMLResult.length;
	}

	let strPreLengthTemp = g_strHTMLResult.substring(subAmount);
	strPreLengthTemp = strPreLengthTemp.toLowerCase(); //Compare everything in lower case to ensure that it matches.

	let strPostLengthTemp = getNextChars(5, true);

	let foundAbbrev = false
	for(let i = strPreLengthTemp.length-1; i >= 0; i--) {
		foundAbbrev = periodAbbrev.filter(m => m.word.toLowerCase() === strPreLengthTemp.substring(i) + '.')[0];
		if (!!foundAbbrev) {
			return false
		}
	}

	//I DON'T KNOW WHAT THIS IS BELOW HERE. I REDID THE arrPreLength CODE ABOVE WITH A WHILE LOOP AND A FILTER. THAT SEEMED TO WORK FOR THE ABBREVIATIONS. I'LL LEAVE THE CODE BELOW IN CASE THERE IS SOME SCENARIO THAT I HAVE FORGOTTEN ABOUT.
	arrPreLength.push("");
	if (strPreLengthTemp.length >= 4)
	{
		arrPreLength.push(strPreLengthTemp.substring(3, 1));
	}
	if (strPreLengthTemp.length >= 3)
	{
		arrPreLength.push(strPreLengthTemp.substring(2, 2));
	}
	if (strPreLengthTemp.length >= 2)
	{
		arrPreLength.push(strPreLengthTemp.substring(1, 3));
	}
	if (strPreLengthTemp.length >= 1)
	{
		arrPreLength.push(strPreLengthTemp.substring(0, 4));
	}
	arrPreLength.push(strPreLengthTemp);


	arrPostLength.push("");
	if (strPostLengthTemp.length > 1)
	{
		arrPostLength.push(strPostLengthTemp.substring(1, 1));  //Notice that we are skipping the period that we started with as we don't use 0 position.
	}
	if (strPostLengthTemp.length > 2)
	{
		arrPostLength.push(strPostLengthTemp.substring(1, 2));
	}
	if (strPostLengthTemp.length > 3)
	{
		arrPostLength.push(strPostLengthTemp.substring(1, 3));
	}
	if (strPostLengthTemp.length > 4)
	{
		arrPostLength.push(strPostLengthTemp.substring(1, 4));
	}

	periodAbbrev.forEach(m =>
	{
		strWord = m.word
		strPreWord = strWord.substring(0, strWord.indexOf("."))
		strPostWord = strWord.substring(strWord.indexOf(".") + 1)

		if (strPostWord.length === 0)
		{
			return true
		}

		if (strPreWord === arrPreLength[m.preLength] && strPostWord === arrPostLength[m.postLength])
		{
			if (m.postLength > 0)
			{
				addResult("", getNextId, getNextChars(m.postLength), '', false, spanStyle) //But we are leaving one left over (since the period is still considered unused) so that the function calling this will take the next single character and then move on.
			}
			return false
		}
	})
	return true
}


const IsNotDecimalNumber = () => {
	let strChar = getNextChars(1);

	return !(strChar === "0"
		|| strChar === "1"
		|| strChar === "2"
		|| strChar === "3"
		|| strChar === "4"
		|| strChar === "5"
		|| strChar === "6"
		|| strChar === "7"
		|| strChar === "8"
		|| strChar === "9")
}

const periodAbbrev = [
	{word: 'Mr.', preLength: 2, postLength: 0},
	{word: 'Mrs.', preLength: 3, postLength: 0},
	{word: 'Ms.', preLength: 2, postLength: 0},
	{word: 'Jan.', preLength: 3, postLength: 0},
	{word: 'Feb.', preLength: 3, postLength: 0},
	{word: 'Mar.', preLength: 3, postLength: 0},
	{word: 'Apr.', preLength: 3, postLength: 0},
	{word: 'Jun.', preLength: 3, postLength: 0},
	{word: 'Jul.', preLength: 3, postLength: 0},
	{word: 'Aug.', preLength: 3, postLength: 0},
	{word: 'Sep.', preLength: 3, postLength: 0},
	{word: 'Sept.', preLength: 4, postLength: 0},
	{word: 'Oct.', preLength: 3, postLength: 0},
	{word: 'Nov.', preLength: 3, postLength: 0},
	{word: 'Dec.', preLength: 3, postLength: 0},
	{word: 'Dr.', preLength: 2, postLength: 0},
	{word: 'Capt.', preLength: 4, postLength: 0},
	{word: 'dept.', preLength: 4, postLength: 0},
	{word: 'dist.', preLength: 4, postLength: 0},
	{word: 'div.', preLength: 3, postLength: 0},
	{word: 'est.', preLength: 3, postLength: 0},
	{word: 'fl.', preLength: 2, postLength: 0},
	{word: 'oz.', preLength: 2, postLength: 0},
	{word: 'gal.', preLength: 3, postLength: 0},
	{word: 'cm.', preLength: 2, postLength: 0},
	{word: 'Jr.', preLength: 2, postLength: 0},
	{word: 'Sr.', preLength: 2, postLength: 0},
	{word: 'Ltd.', preLength: 3, postLength: 0},
	{word: 'Lt.', preLength: 2, postLength: 0},
	{word: 'Mt.', preLength: 2, postLength: 0},
	{word: 'St.', preLength: 2, postLength: 0},
	{word: 'Rev.', preLength: 3, postLength: 0},
	{word: 'Univ.', preLength: 4, postLength: 0},
	{word: 'Blvd.', preLength: 4, postLength: 0},
	{word: 'maj.', preLength: 3, postLength: 0},
	{word: 'col.', preLength: 3, postLength: 0},
	{word: 'mfg.', preLength: 3, postLength: 0},
	{word: 'natl.', preLength: 4, postLength: 0},
	{word: 'pg.', preLength: 2, postLength: 0},
	{word: 'pgs.', preLength: 3, postLength: 0},
	{word: 'serg.', preLength: 4, postLength: 0},
	{word: 'sergt.', preLength: 5, postLength: 0},
	{word: 'sgt.', preLength: 3, postLength: 0},
	{word: 'supt.', preLength: 4, postLength: 0},
	{word: 'vers.', preLength: 4, postLength: 0},
	{word: 'vol.', preLength: 3, postLength: 0},
	{word: 'pres.', preLength: 4, postLength: 0},
	{word: 'vs.', preLength: 2, postLength: 0},
	{word: 'doz.', preLength: 3, postLength: 0},
	{word: 'etc.', preLength: 3, postLength: 0},
	{word: 'inc.', preLength: 3, postLength: 0},
	{word: 'intl.', preLength: 4, postLength: 0},
	{word: 'lat.', preLength: 3, postLength: 0},
	{word: ' no.', preLength: 3, postLength: 0},
	{word: ' kilo.', preLength: 5, postLength: 0},
	{word: ' long.', preLength: 5, postLength: 0},
	{word: ' p.', preLength: 2, postLength: 0},
	{word: 'Ph.D.', preLength: 2, postLength: 2},
	{word: 'B.A.', preLength: 1, postLength: 2},
	{word: 'B.S.', preLength: 1, postLength: 2},
	{word: 'D.C.', preLength: 1, postLength: 2},
	{word: 'i.e.', preLength: 1, postLength: 2},
	{word: 'I.R.S.', preLength: 1, postLength: 4},
	{word: 'M.D.', preLength: 1, postLength: 2},
	{word: 'm.p.h.', preLength: 1, postLength: 4},
	{word: 'C.O.D.', preLength: 1, postLength: 4},
	{word: 'r.p.m.', preLength: 1, postLength: 4},
	{word: 'm.b.a.', preLength: 1, postLength: 4},
	{word: 'm.s.', preLength: 1, postLength: 2},
	{word: 'm.a.', preLength: 1, postLength: 2},
	{word: 'v.p.', preLength: 1, postLength: 2},
	{word: 'R.N.', preLength: 1, postLength: 2},
	{word: 'Ph.D', preLength: 2, postLength: 1},
	{word: 'e.g.', preLength: 1, postLength: 2},
]

const addResult = (strText, getNextId, strSkipToThisText = "", strNextHREFNbr = "", boolDoNotIncludeSkippedToText = false, spanStyle='') =>{
	//strText will add the given text without changing the g_strRemaining (it won't cut anything off of the front of g_strRemaining)
	//strSkipToThisText will save the text and jump g_strRemaining past that text as well.
	//strNextHREFNbr add a PenSpring anchor on the front.
	if (strNextHREFNbr !== "")
	{
		g_strCurrentSentenceHrefId = strNextHREFNbr;
	}

	if (!strText)
	{
		g_strHTMLResult = g_strHTMLResult + strText;

	}
	else if (!strSkipToThisText)
	{
		let numPlusIncludeLength = 0;
		if (!boolDoNotIncludeSkippedToText)
		{
			numPlusIncludeLength = strSkipToThisText.length;
		}
		g_strHTMLResult = g_strHTMLResult + g_strRemaining.substring(0, g_strRemaining.indexOf(strSkipToThisText) + numPlusIncludeLength);
		g_strRemaining = g_strRemaining.substring(g_strRemaining.indexOf(strSkipToThisText) + numPlusIncludeLength);

	}
	else if (!strNextHREFNbr)
	{
		if (g_boolIsFileCompare)
		{
			//This option just adds two new attributes  match_hrefid='' and ismatch=0 for the purpose of the CompareTwoUploadFile.asp process.
			g_lastNewId = getNextId()
			g_strHTMLResult = g_strHTMLResult + `<span id='${g_lastNewId}' ${spanStyle}>`; // draggable=true>";  //match_hrefid='' ismatch=0
			g_previousElementId = g_lastNewId
		}
		else
		{
			g_lastNewId = getNextId()
			g_strHTMLResult = g_strHTMLResult + `<span id='${g_lastNewId}' ${spanStyle}>`; // draggable=true>";
			g_previousElementId = g_lastNewId

		}
	}
}

const isSentenceTypeEndTag = () => {
	// </span is not included here since the END span tag is watched and counted to represent the end of a PS-tag.
	let strText = "";
	let boolToLowerCase = true;

	strText = getNextChars(3, boolToLowerCase);

	if (strText === "</b" || strText === "</i" || strText === "</u" || strText === "</s" || strText === "</q" || strText === "</o")
	{
		return true;
	}
	strText = getNextChars(4, boolToLowerCase);

	if (strText === "</tt" || strText === "</em")
	{
		return true;
	}
	strText = getNextChars(5, boolToLowerCase);

	if (strText === "</del" || strText === "</sub" || strText === "</sup" || strText === "</kbd" || strText === "</var")
	{
		return true;
	}
	strText = getNextChars(6, boolToLowerCase);

	if (strText === "</font" || strText === "</samp")
	{
		return true;
	}
	strText = getNextChars(7, boolToLowerCase);

	if (strText === "</small")
	{
		return true;
	}

	strText = getNextChars(8, boolToLowerCase);

	if (strText === "</strong" || strText === "</strike")
	{
		return true;
	}

	return false;
}

const isQuoteOrBracketOrParenthesisAfter = () => {
	//Get an extra character and then take off the first character in order to make this comparison because we are trying to see what follows the period.

	return (getNextCharsSkipFirst(2) === "\n" || getNextCharsSkipFirst(2) === "\r" || getNextCharsSkipFirst(2) === "\t" || getNextCharsSkipFirst(2) === "\0"
		|| getNextCharsSkipFirst(1) === "]" || getNextCharsSkipFirst(1) === "}" || getNextCharsSkipFirst(1) === ")"
		|| getNextCharsSkipFirst(1) === "'" || getNextCharsSkipFirst(1) === "\""
		|| getNextCharsSkipFirst(1) === "”" || getNextCharsSkipFirst(1) === "“"
		|| getNextCharsSkipFirst(6) === "&quot;"
		|| getNextCharsSkipFirst(7) === "&lsquo;"
		|| getNextCharsSkipFirst(7) === "&rsquo;"
		|| getNextCharsSkipFirst(7) === "&sbquo;"
		|| getNextCharsSkipFirst(7) === "&ldquo;"
		|| getNextCharsSkipFirst(7) === "&rdquo;"
		|| getNextCharsSkipFirst(7) === "&bdquo;"
		|| getNextCharsSkipFirst(8) === "&lsaquo;"
		|| getNextCharsSkipFirst(8) === "&rsaquo;"
		|| getNextCharsSkipFirst(7) === "&#8221;"
		|| getNextCharsSkipFirst(1) === " "
		|| getNextCharsSkipFirst(6) === "&nbsp;")
}

const getNextCharsSkipFirst = (numChars) => {
	numChars++
	let charSet = getNextChars(numChars)
	charSet = charSet.substring(1)
	return charSet
}